import React, { useEffect, useState } from "react";
import { all_pages } from "../api/apiHandler";

export default function DeletionPage() {
  const [content, setContent] = useState();
  const getContent = () => {
    all_pages().then((response) => {
      setContent(
        response.data?.find((item) => item.page_name == "account_deletion_instructions")
          .contents
      );
    });
  };

  useEffect(() => {
    getContent();
  });
  return (
    <>
      {/* {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile />: <Header />} */}
      <section className="terms_use">
        <div className="container">
          <div className="row">
            <div className="col-md-12 mt-5">
              <div className="information_box">
                <div className="information_box_heading">
                  <h3 className="blue_29 font_500 font_25 mb-0">
                  </h3>
                </div>
                <div
                  className="information_box_body ck-content"
                  dangerouslySetInnerHTML={{ __html: content }}
                ></div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
