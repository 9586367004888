/* eslint-disable jsx-a11y/anchor-is-valid */
import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getTimeDifference } from "../../pages/HomeFeed/components/PostUserDetails";

const Comments = (props) => {
  const {
    item,
    likeUnlike,
    setSingleSubComment,
    retweet,
    undoRetweet,
    setSinglePost,
    setPostPermission,
  } = props;
  console.log("item: ", item);
  const navigate = useNavigate();
  return (
    <>
      {item?.status == "Deleted" ? (
        <>
          <li>
            <div className="all_comment_listing">
              <div className="comment_user">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/user/image.png"}
                  alt={item?.profile_image}
                />
              </div>
              <div className="comment_text_maain">
                <div className="grey_section_comment">
                  <div className="user_time_name_option"></div>
                  <p>This content was deleted by the Content author</p>
                </div>
              </div>
            </div>
          </li>
        </>
      ) : item?.media?.length ? (
        <li>
          <div className="all_comment_listing">
            <Link to={"/profile"} state={item?.user_id}>
              <div className="comment_user">
                <img src={item?.profile_image} alt={item?.profile_image} />
              </div>
            </Link>
            <div className="comment_text_maain">
              <div className="grey_section_comment">
                <div className="user_time_name_option">
                  <h6>
                    <Link to={"/profile"} state={item?.user_id}>
                      {item?.name}
                    </Link>
                  </h6>
                  <span>
                    {item?.insertdate && getTimeDifference(item?.insertdate)}
                  </span>
                  <div className="dropdown">
                    <a
                      // className="dropdown-toggle"
                      role="button"
                      // id="dropdownMenuLink"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => {
                        setSinglePost(item);
                        setPostPermission(true);
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/video/more_horiz.svg"
                        }
                        alt=""
                      />
                    </a>
                    {/* <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <p
                  onClick={() => {
                    navigate(`/comments`, {
                      state: { comment: item },
                    });
                  }}
                >
                  <img
                    src={item?.media?.[0]?.name}
                    className="img-fluid inner_comment_img"
                  />
                </p>
              </div>
              <div className="like_dislike d-flex align-items-center ">
                <a
                  //   href="#"
                  className="like "
                >
                  <img
                    onClick={() => {
                      console.log("item:======================>>>>");
                      likeUnlike(item);
                    }}
                    src={
                      item?.is_like
                        ? process.env.PUBLIC_URL + "/assets/video/unfill.svg"
                        : process.env.PUBLIC_URL +
                        "/assets/video/unfill_black_border.svg"
                    }
                    alt=""
                    className="me-1"
                  />
                  {item?.likes}
                </a>
                <a
                  className="cursor-pointer"
                  id="comment_show"
                  data-bs-target="#subCommentModal"
                  data-bs-toggle="modal"
                  onClick={() => setSingleSubComment(item)}
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/video/comment_brown.svg"
                    }
                    className="me-1"
                  />
                  {item?.comment_count}
                </a>
                <a className="dislke">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/video/arrows-clockwise-light.svg"
                    }
                    className="me-1"
                  />
                  {item?.retweet_count}
                </a>
                <a className="cursor-pointer">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/video/plane_brown.svg"
                    }
                    className="me-1"
                  />
                  {item?.share_count?.length}
                </a>
              </div>
            </div>
          </div>
        </li>
      ) : (
        <li>
          {console.log('item1: ', item)}

          <div className="all_comment_listing">
            <div className="comment_user">
              <Link to={"/profile"} state={item?.user_id}>
                <div className="comment_user">
                  <img src={item?.profile_image} alt={item?.profile_image} />
                </div>
              </Link>
            </div>
            <div className="comment_text_maain">
              <div className="grey_section_comment">
                <div className="user_time_name_option">
                  <h6>
                    <Link to={"/profile"} state={item?.user_id}>
                      {item?.name}
                    </Link>
                  </h6>
                  <span>
                    {item?.insertdate && (
                      <ReactTimeAgo date={item?.insertdate} locale="en-US" />
                    )}
                  </span>
                  <div className="dropdown">
                    <a
                      // className="dropdown-toggle"
                      role="button"
                      // id="dropdownMenuLink"
                      // data-bs-toggle="dropdown"
                      // aria-expanded="false"
                      onClick={() => {
                        setSinglePost(item);
                        setPostPermission(true);
                      }}
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/video/more_horiz.svg"
                        }
                        alt=""
                      />
                    </a>
                    {/* <ul
                      className="dropdown-menu"
                      aria-labelledby="dropdownMenuLink"
                    >
                      <li>
                        <a className="dropdown-item" href="#">
                          Action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Another action
                        </a>
                      </li>
                      <li>
                        <a className="dropdown-item" href="#">
                          Something else here
                        </a>
                      </li>
                    </ul> */}
                  </div>
                </div>
                <p
                  onClick={() => {
                    navigate(`/comments`, {
                      state: { comment: item },
                    });
                  }}
                  className="text-break "
                >
                  {item?.description}
                </p>
              </div>
              <div className="like_dislike d-flex align-items-center ">
                <a className="like ">
                  <img
                    alt=""
                    onClick={() => {
                      console.log("item:======================>>>>");
                      likeUnlike(item);
                    }}
                    src={
                      item?.is_like
                        ? process.env.PUBLIC_URL + "/assets/video/unfill.svg"
                        : process.env.PUBLIC_URL +
                        "/assets/video/unfill_black_border.svg"
                    }
                    className="me-1"
                  />
                  {item?.likes}
                </a>
                <a
                  className="cursor-pointer"
                  data-bs-target="#subCommentModal"
                  data-bs-toggle="modal"
                  onClick={() => setSingleSubComment(item)}
                >
                  <img
                    alt=""
                    src={
                      process.env.PUBLIC_URL + "/assets/video/comment_brown.svg"
                    }
                    className="me-1"
                  />
                  {item?.comment_count}
                </a>
                <a
                  className="dislke cursor-pointer"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    alt=""
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/video/arrows-clockwise-light.svg"
                    }
                    className="me-1"
                  />
                  <div
                    className="dropdown-menu"
                    aria-labelledby="dropdownMenuLink"
                  >
                    <li
                      onClick={() => {
                        if (item?.is_retweet === 0) {
                          retweet(item);
                        } else {
                          undoRetweet(item?._id);
                        }
                      }}
                      style={{ cursor: "pointer" }}
                    >
                      <a className="dropdown-item">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/post-repost.svg"
                          }
                          alt={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/post-repost.svg"
                          }
                          className="me-2"
                        />
                        {item?.is_retweet === 0 ? "Repost" : "Undo Repost"}
                      </a>
                    </li>
                    <li onClick={() => setSingleSubComment(item)}>
                      <a
                        className="dropdown-item"
                        data-bs-target="#retweetCommentModalForSubComments"
                        data-bs-toggle="modal"
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/video/pencil-simple-line.svg"
                          }
                          alt={
                            process.env.PUBLIC_URL +
                            "/assets/video/pencil-simple-line.svg"
                          }
                          className="me-2"
                        />
                        Quote
                      </a>
                    </li>
                  </div>
                  {item?.retweet_count}
                </a>
                <a className="cursor-pointer">
                  <img
                    alt=""
                    src={
                      process.env.PUBLIC_URL + "/assets/video/plane_brown.svg"
                    }
                    className="me-1"
                  />
                  {item?.share_count?.length}
                </a>
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default Comments;
