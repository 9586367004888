import React, { useCallback, useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { home_post, share_link } from "../../api/apiHandler";
import { Link } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import BestColleges from "../../common/BestColleges";
import ShareLink from "../pages/HomeFeed/components/ShareLink";

export default function NewsMain() {
  const [news, setNews] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });
  console.log("news: ", news);
  useEffect(() => {
    home_post({ module_type: "news" }).then((response) => {
      console.log("News Listing", response);
      if (response?.code === 1 || response?.code === "1") {
        setNews(response?.data);
      }
    });
  }, []);
  const handleShare = useCallback((data) => {
    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data?.description;

    const text = tempDiv.innerText || tempDiv.textContent;
    const first150Chars = text.substring(0, 150);

    share_link({
      id: data._id,
      module_type: "News",
      description: first150Chars || "stulink",
    }).then((response) => {
      console.log("response: ", response);
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);
  return (
    <>
      {localStorage.getItem("isSignedUp") ||
      localStorage.getItem("isLoggedIn") ? (
        <>
          <HeaderProfile />
          <ShareLink
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      ) : (
        <Header />
      )}
      {localStorage.getItem("isSignedUp") ||
      localStorage.getItem("isLoggedIn") ? (
        <section className="main_news">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <ul
                  className="nav nav-justified nav-pills mb-3 custom-list"
                  id="pills-tab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link active"
                      id="pills-home-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-home"
                      type="button"
                      role="tab"
                      aria-controls="pills-home"
                      aria-selected="true"
                    >
                      All
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-profile-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-profile"
                      type="button"
                      role="tab"
                      aria-controls="pills-profile"
                      aria-selected="false"
                    >
                      College News
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-contact-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-contact"
                      type="button"
                      role="tab"
                      aria-controls="pills-contact"
                      aria-selected="false"
                    >
                      Result News
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-general-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-general"
                      type="button"
                      role="tab"
                      aria-controls="pills-general"
                      aria-selected="false"
                    >
                      Jobs News
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-education-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-education"
                      type="button"
                      role="tab"
                      aria-controls="pills-education"
                      aria-selected="false"
                    >
                      Exam News
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="nav-link"
                      id="pills-student-tab"
                      data-bs-toggle="pill"
                      data-bs-target="#pills-student"
                      type="button"
                      role="tab"
                      aria-controls="pills-student"
                      aria-selected="false"
                    >
                      Student Life News
                    </button>
                  </li>
                </ul>
                <div className="tab-content" id="pills-tabContent">
                  <div
                    className="tab-pane fade show active"
                    id="pills-home"
                    role="tabpanel"
                    aria-labelledby="pills-home-tab"
                  >
                    {news?.length > 0 ? (
                      news?.map((item) => {
                        return (
                          <>
                            <div className="main_box_ahdow" key={item?._id}>
                              <div className="row">
                                <div className="col-md-12 col-12 mb-3">
                                  <h4>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/video/news.svg"
                                      }
                                      className="me-2"
                                      alt=""
                                    />
                                    {item?.type} News
                                  </h4>
                                </div>
                                <div className="col-md-12 col-12 mb-3">
                                  <div className="news_main">
                                    {item?.media?.map((i) => {
                                      return (
                                        <>
                                          <div
                                            className="news_img"
                                            key={i?._id}
                                          >
                                            <Link
                                              to={`/newsdetails/${item?._id}`}
                                            >
                                              {i?.name !== "" ? (
                                                <img src={i?.name} alt="" />
                                              ) : (
                                                <img
                                                  alt=""
                                                  src="https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                                />
                                              )}
                                            </Link>
                                          </div>
                                        </>
                                      );
                                    })}

                                    <div className="new_detail">
                                      <h5>
                                        <Link to={`/newsdetails/${item?._id}`}>
                                          {item?.title?.length < 100
                                            ? item?.title
                                            : item?.title?.slice(0, 100) +
                                              "..."}
                                        </Link>
                                      </h5>
                                      <div className="news_option my-3">
                                        <span className="blue_text me-3">
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/male.svg"
                                            }
                                          />
                                          By {item?.name}
                                        </span>
                                        <span>
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/planner.svg"
                                            }
                                          />
                                          {item?.insertdate ? (
                                            <ReactTimeAgo
                                              date={item?.insertdate}
                                              locale="en-US"
                                            />
                                          ) : (
                                            <></>
                                          )}{" "}
                                        </span>
                                      </div>
                                      <div
                                        dangerouslySetInnerHTML={{
                                          __html: item?.description,
                                        }}
                                        className="my-3 ck-content text-truncate truncate-2-lines"
                                      ></div>
                                      <span
                                        className="share cursor-pointer"
                                        onClick={() => {
                                          handleShare(item);
                                        }}
                                      >
                                        <img
                                          alt=""
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/video/share.svg"
                                          }
                                        />{" "}
                                        Share
                                      </span>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </>
                        );
                      })
                    ) : (
                      <h3 className="blue_29"> No News Found</h3>
                    )}
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="pills-profile"
                    role="tabpanel"
                    aria-labelledby="pills-profile-tab"
                  >
                    <div className="row">
                      <div className="main_box_ahdow">
                        <div className="row">
                          <div className="col-md-12 col-12 mb-3">
                            <h4>
                              <img
                                alt=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/news.svg"
                                }
                                className="me-2"
                              />
                              College News
                            </h4>
                          </div>
                          {news?.length > 0 ? (
                            news?.map((item) => {
                              return item?.type === "College" ? (
                                <>
                                  <div className="col-md-12 col-12 mb-3">
                                    <div className="news_main">
                                      {item?.media?.map((i) => {
                                        return (
                                          <>
                                            <div
                                              className="news_img"
                                              key={i?._id}
                                            >
                                              <Link
                                                to={`/newsdetails/${item?._id}`}
                                              >
                                                {i?.name !== "" ? (
                                                  <img alt="" src={i?.name} />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    src="https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                                  />
                                                )}
                                              </Link>
                                            </div>
                                          </>
                                        );
                                      })}

                                      <div className="new_detail">
                                        <h5>
                                          <Link
                                            to={`/newsdetails/${item?._id}`}
                                          >
                                            {item?.title?.length < 100
                                              ? item?.title
                                              : item?.title?.slice(0, 100) +
                                                "..."}
                                          </Link>
                                        </h5>
                                        <div className="news_option my-1">
                                          <span className="blue_text me-3">
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/male.svg"
                                              }
                                            />
                                            By {item?.name}
                                          </span>
                                          <span>
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/planner.svg"
                                              }
                                            />
                                            {item?.insertdate ? (
                                              <ReactTimeAgo
                                                date={item?.insertdate}
                                                locale="en-US"
                                              />
                                            ) : (
                                              <></>
                                            )}{" "}
                                          </span>
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.description,
                                          }}
                                          className="my-3 ck-content text-truncate truncate-2-lines"
                                        ></div>
                                        <span
                                          className="share"
                                          onClick={() => {
                                            handleShare(item);
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/share.svg"
                                            }
                                          />{" "}
                                          Share
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              );
                            })
                          ) : (
                            <h2 className="blue_29">No college news found</h2>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-contact"
                    role="tabpanel"
                    aria-labelledby="pills-contact-tab"
                  >
                    <div className="row">
                      <div className="main_box_ahdow">
                        <div className="row">
                          <div className="col-md-12 col-12 mb-3">
                            <h4>
                              <img
                                alt=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/news.svg"
                                }
                                className="me-2"
                              />
                              Result News
                            </h4>
                          </div>
                          {news?.length > 0 ? (
                            news?.map((item) => {
                              return item?.type === "Results" ? (
                                <>
                                  <div className="col-md-12 col-12 mb-3">
                                    <div className="news_main">
                                      {item?.media?.map((i) => {
                                        return (
                                          <>
                                            <div
                                              className="news_img"
                                              key={i?._id}
                                            >
                                              <Link
                                                to={`/newsdetails/${item?._id}`}
                                              >
                                                {i?.name !== "" ? (
                                                  <img alt="" src={i?.name} />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    src="https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                                  />
                                                )}
                                              </Link>
                                            </div>
                                          </>
                                        );
                                      })}

                                      <div className="new_detail">
                                        <h5>
                                          <Link
                                            to={`/newsdetails/${item?._id}`}
                                          >
                                            {item?.title?.length < 100
                                              ? item?.title
                                              : item?.title?.slice(0, 100) +
                                                "..."}
                                          </Link>
                                        </h5>
                                        <div className="news_option my-1">
                                          <span className="blue_text me-3">
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/male.svg"
                                              }
                                            />
                                            By {item?.name}
                                          </span>
                                          <span>
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/planner.svg"
                                              }
                                            />
                                            {item?.insertdate ? (
                                              <ReactTimeAgo
                                                date={item?.insertdate}
                                                locale="en-US"
                                              />
                                            ) : (
                                              <></>
                                            )}{" "}
                                          </span>
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.description,
                                          }}
                                          className="my-3 ck-content text-truncate truncate-2-lines"
                                        ></div>
                                        <span
                                          className="share"
                                          onClick={() => {
                                            handleShare(item);
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/share.svg"
                                            }
                                          />{" "}
                                          Share
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              );
                            })
                          ) : (
                            <h2 className="blue_29">No result news found</h2>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-general"
                    role="tabpanel"
                    aria-labelledby="pills-general-tab"
                  >
                    <div className="row">
                      <div className="main_box_ahdow">
                        <div className="row">
                          <div className="col-md-12 col-12 mb-3">
                            <h4>
                              <img
                                alt=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/news.svg"
                                }
                                className="me-2"
                              />
                              Job News
                            </h4>
                          </div>
                          {news?.length > 0 ? (
                            news?.map((item) => {
                              return item?.type === "Jobs" ? (
                                <>
                                  <div className="col-md-12 col-12 mb-3">
                                    <div className="news_main">
                                      {item?.media?.map((i) => {
                                        return (
                                          <>
                                            <div
                                              className="news_img"
                                              key={i?._id}
                                            >
                                              <Link
                                                to={`/newsdetails/${item?._id}`}
                                              >
                                                {i?.name !== "" ? (
                                                  <img src={i?.name} alt="" />
                                                ) : (
                                                  <img
                                                    src="https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                                    alt=""
                                                  />
                                                )}
                                              </Link>
                                            </div>
                                          </>
                                        );
                                      })}

                                      <div className="new_detail">
                                        <h5>
                                          <Link
                                            to={`/newsdetails/${item?._id}`}
                                          >
                                            {item?.title?.length < 100
                                              ? item?.title
                                              : item?.title?.slice(0, 100) +
                                                "..."}
                                          </Link>
                                        </h5>
                                        <div className="news_option my-1">
                                          <span className="blue_text me-3">
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/male.svg"
                                              }
                                            />
                                            By {item?.name}
                                          </span>
                                          <span>
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/planner.svg"
                                              }
                                            />
                                            {item?.insertdate ? (
                                              <ReactTimeAgo
                                                date={item?.insertdate}
                                                locale="en-US"
                                              />
                                            ) : (
                                              <></>
                                            )}{" "}
                                          </span>
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.description,
                                          }}
                                          className="my-3 ck-content text-truncate truncate-2-lines"
                                        ></div>
                                        <span
                                          className="share"
                                          onClick={() => {
                                            handleShare(item);
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/share.svg"
                                            }
                                          />{" "}
                                          Share
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              );
                            })
                          ) : (
                            <h2 className="blue_29">No jobs news found</h2>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-education"
                    role="tabpanel"
                    aria-labelledby="pills-education-tab"
                  >
                    <div className="row">
                      <div className="main_box_ahdow">
                        <div className="row">
                          <div className="col-md-12 col-12 mb-3">
                            <h4>
                              <img
                                alt=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/news.svg"
                                }
                                className="me-2"
                              />
                              Exam News
                            </h4>
                          </div>
                          {news?.length > 0 ? (
                            news?.map((item) => {
                              return item?.type === "Exams" ? (
                                <>
                                  <div className="col-md-12 col-12 mb-3">
                                    <div className="news_main">
                                      {item?.media?.map((i) => {
                                        return (
                                          <>
                                            <div
                                              className="news_img"
                                              key={i?._id}
                                            >
                                              <Link
                                                to={`/newsdetails/${item?._id}`}
                                              >
                                                {i?.name !== "" ? (
                                                  <img alt="" src={i?.name} />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    src="https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                                  />
                                                )}
                                              </Link>
                                            </div>
                                          </>
                                        );
                                      })}

                                      <div className="new_detail">
                                        <h5>
                                          <Link
                                            to={`/newsdetails/${item?._id}`}
                                          >
                                            {item?.title?.length < 100
                                              ? item?.title
                                              : item?.title?.slice(0, 100) +
                                                "..."}
                                          </Link>
                                        </h5>
                                        <div className="news_option my-1">
                                          <span className="blue_text me-3">
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/male.svg"
                                              }
                                            />
                                            By {item?.name}
                                          </span>
                                          <span>
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/planner.svg"
                                              }
                                            />
                                            {item?.insertdate ? (
                                              <ReactTimeAgo
                                                date={item?.insertdate}
                                                locale="en-US"
                                              />
                                            ) : (
                                              <></>
                                            )}{" "}
                                          </span>
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.description,
                                          }}
                                          className="my-3 ck-content text-truncate truncate-2-lines"
                                        ></div>
                                        <span
                                          className="share"
                                          onClick={() => {
                                            handleShare(item);
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/share.svg"
                                            }
                                          />{" "}
                                          Share
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              );
                            })
                          ) : (
                            <h2 className="blue_29">No exams news found</h2>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade"
                    id="pills-student"
                    role="tabpanel"
                    aria-labelledby="pills-student-tab"
                  >
                    <div className="row">
                      <div className="main_box_ahdow">
                        <div className="row">
                          <div className="col-md-12 col-12 mb-3">
                            <h4>
                              <img
                                alt=""
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/news.svg"
                                }
                                className="me-2"
                              />
                              Student Life News
                            </h4>
                          </div>
                          {news?.length > 0 ? (
                            news?.map((item) => {
                              return item?.type === "Students" ? (
                                <>
                                  <div className="col-md-12 col-12 mb-3">
                                    <div className="news_main">
                                      {item?.media?.map((i) => {
                                        return (
                                          <>
                                            <div
                                              className="news_img"
                                              key={i?._id}
                                            >
                                              <Link
                                                to={`/newsdetails/${item?._id}`}
                                              >
                                                {i?.name !== "" ? (
                                                  <img alt="" src={i?.name} />
                                                ) : (
                                                  <img
                                                    alt=""
                                                    src="https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                                  />
                                                )}
                                              </Link>
                                            </div>
                                          </>
                                        );
                                      })}

                                      <div className="new_detail">
                                        <h5>
                                          <Link
                                            to={`/newsdetails/${item?._id}`}
                                          >
                                            {item?.title?.length < 100
                                              ? item?.title
                                              : item?.title?.slice(0, 100) +
                                                "..."}
                                          </Link>
                                        </h5>
                                        <div className="news_option my-1">
                                          <span className="blue_text me-3">
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/male.svg"
                                              }
                                            />
                                            By {item?.name}
                                          </span>
                                          <span>
                                            <img
                                              alt=""
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/planner.svg"
                                              }
                                            />
                                            {item?.insertdate ? (
                                              <ReactTimeAgo
                                                date={item?.insertdate}
                                                locale="en-US"
                                              />
                                            ) : (
                                              <></>
                                            )}{" "}
                                          </span>
                                        </div>
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item?.description,
                                          }}
                                          className="my-3 ck-content text-truncate truncate-2-lines"
                                        ></div>
                                        <span
                                          className="share"
                                          onClick={() => {
                                            handleShare(item);
                                          }}
                                        >
                                          <img
                                            alt=""
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/share.svg"
                                            }
                                          />{" "}
                                          Share
                                        </span>
                                      </div>
                                    </div>
                                  </div>
                                </>
                              ) : (
                                <></>
                              );
                            })
                          ) : (
                            <h2 className="blue_29">No students news found</h2>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              {/*---------side bar news --------*/}
              <BestColleges />
            </div>
          </div>
        </section>
      ) : (
        <></>
      )}
    </>
  );
}
