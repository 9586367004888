import React, { useEffect, useState } from "react";
import {
  get_college_list,
  get_home_college_exam,
  get_home_trending_college,
  get_home_course,
  get_home_exam,
  get_topic_list,
  group_list,
  home_jobs_list,
  home_post,
  home_state_jobs,
  type_wise_post,
  get_people_list,
} from "../../api/apiHandler";
import Header from "../Header";
import HeaderProfile from "../HeaderProfile";
// import HomeFeed from "./HomeFeed";
import { Link, Navigate, useNavigate } from "react-router-dom";
// import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import dayjs from "dayjs";
import Slider from "react-slick";
import TrendingTopics from "../../common/TrendingTopics";
import Rating from "@mui/material/Rating";

function SampleNextArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={process.env.PUBLIC_URL + "/assets/images/icons/owl-next.svg"}
      className={className}
      style={{ ...style, display: "block", marginRight: "0px" }}
      onClick={onClick}
    />
  );
}

function SamplePrevArrow(props) {
  const { className, style, onClick } = props;
  return (
    <img
      src={process.env.PUBLIC_URL + "/assets/images/icons/owl-prev.svg"}
      className={className}
      style={{ ...style, display: "block", marginLeft: "0px" }}
      onClick={onClick}
    />
  );
}

const truncateDescription = (htmlString, maxLines) => {
  const plainText = new DOMParser().parseFromString(htmlString, "text/html")
    .body.textContent;
  const lines = plainText.split("\n");
  const truncatedText = lines.slice(0, maxLines).join("\n");
  const truncatedHtml = new DOMParser().parseFromString(
    `<div>${truncatedText}</div>`,
    "text/html"
  ).body.firstChild.innerHTML;

  return truncatedHtml;
};

export default function Home() {
  const [jobList, setJobList] = useState([]);

  const [collegeExamList, setCollegeExamList] = useState([]);
  const [courseList, setCourseList] = useState([]);
  const [examsList, setExamsList] = useState([]);
  const [collegeExamActive, setCollegeExamActive] = useState("college");
  const [courseActive, setCourseActive] = useState("all");
  const [groupList, setGroupList] = useState([]);
  const [newsList, setNewsList] = useState([]);
  const [singleExam, setSingleExam] = useState();
  const [seeMore, setSeeMore] = useState(false);
  const [trendingColleges, setTrendingColleges] = useState([]);
  const navigate = useNavigate();
  const newsSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    arrows: true,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 3,
          infinite: true,
          dots: true,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 2,
          initialSlide: 2,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const collegeSettings = {
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <SampleNextArrow />,
    prevArrow: <SamplePrevArrow />,
    responsive: [
      {
        breakpoint: 1024,
        settings: {
          slidesToShow: 2,
          slidesToScroll: 1,
        },
      },
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const getHomeCourse = (courseType) => {
    get_home_course({ type: courseType }).then((response) => {
      console.log("Home course", response);
      if (response?.code === "1" || response?.code === 1) {
        setCourseList(response?.data);
      }
    });
  };

  const CourseButton = ({ type, label, onClick, active }) => (
    <li
      className="nav-item"
      role="presentation"
      onClick={() => handleCourseTabClick(type)}
    >
      <button
        className={`nav-link black_14 font_20 font_500 ${
          active ? "active" : ""
        }`}
        id={`${type}-tab`}
        data-bs-toggle="tab"
        data-bs-target={`#${type}`}
        type="button"
        role="tab"
        aria-controls={type}
        aria-selected="false"
      >
        {label}
      </button>
    </li>
  );

  const CourseSection = ({ id, items }) => (
    <div
      className={`tab-pane fade show ${courseActive === id ? "active" : ""}`}
      id={id}
      role="tabpanel"
      aria-labelledby={`${id}-tab`}
    >
      {itemRows?.map((i, k) => (
        <div className="row justify-content-center" key={k}>
          {i?.map((item) => (
            <div
              className="col-6 col-xl-2 col-lg-2 col-md-3 col-sm-3"
              key={item?._id}
              onClick={() => handleCourseItemClick(item)}
            >
              <div className="degree">
                <button className="degree_button text-truncate font_600">
                  {item?.name}
                </button>
              </div>
            </div>
          ))}
        </div>
      ))}
    </div>
  );

  const courseTabs = [
    { type: "all", label: "All" },
    { type: "Diploma", label: "Diploma" },
    { type: "Bachelors", label: "Under Graduate" },
    { type: "Masters", label: "Post Graduate" },
    { type: "PHD", label: "PhD" },
  ];

  const CourseTabs = () => (
    <ul className="nav nav-tabs" id="myTab" role="tablist">
      {courseTabs.map(({ type, label, active }) => (
        <CourseButton
          key={type}
          type={type}
          label={label}
          active={type === courseActive}
        />
      ))}
    </ul>
  );

  const handleCourseTabClick = (courseType) => {
    setCourseActive(courseType);
    getHomeCourse(courseType === "all" ? "" : courseType);
    const filteredCourses =
      courseType === "all"
        ? courseList
        : courseList?.filter((item) => item?.type === courseType);
    itemRows = splitItemsIntoRows(filteredCourses);
  };

  const handleCourseItemClick = (item) => {
    const isLoggedIn = localStorage.getItem("isLoggedIn");
    const isSignedUp = localStorage.getItem("isSignedUp");
    isLoggedIn || isSignedUp ? navigate("/courses") : navigate("/login");
  };

  useEffect(() => {
    get_home_college_exam({}).then((response) => {
      console.log("Home college exams", response);
      if (response?.code === "1" || response?.code === 1) {
        setCollegeExamList(response?.data);
      }
    });
    getHomeCourse("");
    get_home_exam({}).then((response) => {
      console.log("Home exam", response);
      if (response?.code === 1 || response?.code === "1") {
        setExamsList(response?.data);
        setSingleExam(response?.data[0]);
      }
    });
    home_state_jobs({}).then((response) => {
      console.log("Home Jobs", response);
      if (response?.code === 1 || response?.code === "1") {
        setJobList(response?.data?.job_state_cities_result);
      }
    });
    group_list({}).then((response) => {
      console.log("Group list", response);
      if (response?.code === 1 || response?.code === "1") {
        setGroupList(response?.data);
      }
    });
    type_wise_post({ module_type: "news" }).then((response) => {
      console.log("News", response);
      if (response?.code === 1 || response?.code === "1") {
        setNewsList(response?.data);
      }
    });
    get_home_trending_college({}).then((response) => {
      console.log("Trending college", response);
      if (response?.code === 1 || response?.code === "1") {
        setTrendingColleges(response?.data);
      }
    });
  }, []);

  const splitItemsIntoRows = (items) => {
    const rows = [];
    let startIndex = 0;
    while (startIndex < items.length) {
      let row = items.slice(startIndex, startIndex + 6);
      rows.push(row);
      startIndex += 6;
      if (startIndex < items.length) {
        row = items.slice(startIndex, startIndex + 5);
        rows.push(row);
        startIndex += 5;
      }
    }
    return rows;
  };

  let itemRows = splitItemsIntoRows(courseList);
  console.log(itemRows);

  return (
    <>
      <Header />
      <section className="dream_college">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <p className="welcome_heading blue_29 font_500 font_20">
                WELCOME TO STULINK
              </p>
              <h1 className="inter black_00 font_55 font_800">
                The Smarter Way <br /> to Find{" "}
                <span className="blue_29">
                  Dream <br /> College
                </span>
              </h1>
              <div className="main_heading_text">
                <span className="heading_text gray_5a">
                  Lorem ipsum dolor sit amet, consectetuer adipiscing elit.
                  Aenean commodo dolor Aenean massa Cum sociis .
                </span>
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="clg_find_img">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "assets/images/page-image/college-find.svg"
                  }
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="find_best">
        <div className="container">
          <p className="find_latter_spacing blue_29 font_22 font_600 text-center">
            Find Your Best
          </p>
          <h3 className="black_14 font_46 font_500 text-center">
            Colleges & Exams
          </h3>
          <div className="row justify-content-center">
            <div className="col-12 col-xl-6 col-lg-6 col-md-7 col-sm-8">
              <ul className="exam_tabs nav nav-tabs" id="myTab" role="tablist">
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setCollegeExamActive("collge")}
                >
                  <button
                    className={`nav-link ${
                      collegeExamActive === "college" ? "active" : ""
                    } font_20 font_600`}
                    id="colleges-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#colleges"
                    type="button"
                    role="tab"
                    aria-controls="colleges"
                    aria-selected="true"
                  >
                    Colleges
                  </button>
                </li>
                <li
                  className="nav-item"
                  role="presentation"
                  onClick={() => setCollegeExamActive("exam")}
                >
                  <button
                    className={`nav-link ${
                      collegeExamActive === "exam" ? "active" : ""
                    } font_20 font_600`}
                    id="exams-tab"
                    data-bs-toggle="tab"
                    data-bs-target="#exams"
                    type="button"
                    role="tab"
                    aria-controls="exams"
                    aria-selected="false"
                  >
                    Exams
                  </button>
                </li>
              </ul>
            </div>
            <div className="col-md-12">
              <div
                className="tab-content colleges_courses_margin"
                id="myTabContent"
              >
                <div
                  className="tab-pane fade show active"
                  id="colleges"
                  role="tabpanel"
                  aria-labelledby="colleges-tab"
                >
                  <div className="colleges_courses">
                    {collegeExamList?.map((item) => {
                      return (
                        <>
                          <div
                            className="course_box"
                            key={item?._id}
                            onClick={() => {
                              localStorage.getItem("isLoggedIn") ||
                              localStorage.getItem("isSignedUp")
                                ? navigate("/colleges")
                                : navigate("/login");
                            }}
                          >
                            <div className="courses">
                              <div className="course_icon">
                                <img
                                  src={item?.icon}
                                  className="img-fluid"
                                  alt="icon"
                                />
                              </div>
                              <p className="course_name font_600 font_15 black_14 mb-0">
                                {item?.name}
                              </p>
                              <span className="course_name font_300 black_14">
                                {item?.total_stream_clg > 0
                                  ? "+" + item?.total_stream_clg
                                  : item?.total_stream_clg}{" "}
                                Colleges
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
                <div
                  className="tab-pane fade"
                  id="exams"
                  role="tabpanel"
                  aria-labelledby="exams-tab"
                >
                  <div className="colleges_courses">
                    {collegeExamList?.map((item) => {
                      return (
                        <>
                          <div
                            className="course_box"
                            key={item?._id}
                            onClick={() => {
                              localStorage.getItem("isLoggedIn") ||
                              localStorage.getItem("isSignedUp")
                                ? navigate("/exams")
                                : navigate("/login");
                            }}
                          >
                            <div className="courses">
                              <div className="course_icon">
                                <img
                                  src={item?.icon}
                                  className="img-fluid"
                                  alt="icon"
                                />
                              </div>
                              <p className="course_name font_600 font_15 black_14 mb-0">
                                {item?.name}
                              </p>
                              <span className="course_name font_300 black_14">
                                {item?.total_exam_clg > 0 ? "+" : ""}{" "}
                                {item?.total_exam_clg === 0
                                  ? "0"
                                  : item?.total_exam_clg}{" "}
                                {item?.total_exam_clg > 1 ? "Exams" : "Exam"}
                              </span>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="main_colleges">
        <div className="container">
          <div className="trending_heading d-flex align-items-center">
            <p className="find_latter_spacing blue_29 font_22 font_600 mb-0 me-1">
              Trending
            </p>
            <h3 className="black_14 font_46 font_500 mb-0">Colleges</h3>
          </div>
          <Slider {...collegeSettings}>
            {trendingColleges?.map((item) => {
              let avg = 0;
              item?.college_rating?.map((r) => {
                avg += r?.rate / item?.college_rating?.length;
              });
              return (
                <div className="college_item my-2" key={item?._id}>
                  <div className="college_card mx-1">
                    <div className="college_card_image">
                      <div className="college_image">
                        <img
                          src={item?.banner_image}
                          className="img-fluid"
                          alt="college"
                        />
                      </div>
                      <div className="card_tag">
                        <span className="blue_29 font_12 font_600">
                          Featured college
                        </span>
                      </div>
                      <div className="card_link align-items-center">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/link.svg"
                          }
                          className="img-fluid"
                          alt="link"
                        />
                      </div>
                    </div>
                    <div className="college_card_text">
                      <div className="card_text_review">
                        <p className="card_text_review_number font_500 black_14">
                          ({parseFloat(avg).toFixed("1")})
                        </p>
                        <div className="star-rating mx-1">
                          <Rating value={avg} readOnly />
                        </div>
                      </div>
                      <Link
                        to={
                          localStorage?.getItem("isLoggedIn") ||
                          localStorage?.getItem("isSignedUp")
                            ? "/college-info"
                            : "/login"
                        }
                        state={item._id}
                        className="card_text_college_name d-block"
                      >
                        <h6 className="college_name font_18 font_600 black_14 inter mb-0">
                          {item?.name}
                        </h6>
                      </Link>
                      <div className="card_text_college_location align-items-start">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/location.svg"
                          }
                          className="img-fluid"
                          alt="location"
                        />
                        <span className="city_name font_500 black_14 inter mb-0">
                          {item?.location}
                        </span>
                      </div>
                      <div
                        onClick={() => {
                          localStorage?.getItem("isLoggedIn") ||
                          localStorage?.getItem("isSignedUp")
                            ? navigate(`/apply-now/college/${item?._id}`)
                            : navigate("/login");
                        }}
                        className="my-1 card_text_buttons"
                      >
                        <button
                          type="button"
                          className="white_button d-flex align-items-center"
                        >
                          Apply Now{" "}
                          <span className="icon-blue-arrow font_10 ms-1"></span>
                        </button>
                      </div>
                    </div>
                  </div>
                </div>
              );
            })}
          </Slider>
        </div>
      </section>

      <section className="find_best">
        <div className="container">
          <p className="find_latter_spacing blue_29 font_22 font_600 text-center">
            POPULAR
          </p>
          <h3 className="black_14 font_46 font_500 text-center">Courses</h3>
          <div className="popular_tab">
            <CourseTabs />
            <div className="popular_tab_content tab-content" id="myTabContent">
              {courseTabs.map(({ type }) => (
                <CourseSection key={type} id={type} />
              ))}
            </div>
          </div>
        </div>
      </section>

      <section className="exam_updates">
        <div className="container">
          <p className="find_latter_spacing blue_29 font_22 font_600 text-center">
            LATEST
          </p>
          <h3 className="black_14 font_46 font_500 text-center">
            Exam Updates
          </h3>
          <div className="net_cat">
            <div className="row">
              <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div
                  className="net_exams_tab nav flex-column nav-pills"
                  id="v-pills-tab"
                  role="tablist"
                  aria-orientation="vertical"
                >
                  {examsList?.slice(0, 6)?.map((item) => {
                    return item?.is_active === 1 ? (
                      <>
                        <button
                          className={`nav-link ${
                            item?._id === singleExam?._id ? "active" : ""
                          }`}
                          id="v-pills-india-tab"
                          data-bs-toggle="pill"
                          data-bs-target="#v-pills-india"
                          type="button"
                          role="tab"
                          aria-controls="v-pills-india"
                          aria-selected="true"
                          onClick={() => {
                            setSingleExam(item);
                          }}
                          key={item?._id}
                        >
                          <div className="exam_date">
                            <p className="font_600 font_18 white_ff mb-0">
                              {dayjs(item?.created_at).format("DD")}
                            </p>
                            <span className="font_12 white_ff">
                              {dayjs(item?.created_at).format("MMM")},
                              {dayjs(item?.created_at).format("YYYY")}
                            </span>
                          </div>
                          <div className="exam_name">
                            <h6 className="blue_29 font_18 font_600 net_exam">
                              {item?.name} {item?.exam_year}
                            </h6>
                            <div className="d-flex justify-content-between align-items-center">
                              <span className="black_14">
                                Location: All India
                              </span>
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "assets/images/icons/black-arrow.svg"
                                }
                                className="img-fluid"
                                alt="arrow"
                              />
                            </div>
                          </div>
                        </button>
                      </>
                    ) : (
                      <></>
                    );
                  })}
                  <span
                    className="blue_29 font_18 font_500 view_more_exams"
                    onClick={() =>
                      localStorage?.getItem("isLoggedIn") ||
                      localStorage?.getItem("isSignedUp")
                        ? navigate("/trending-topics")
                        : navigate("/login")
                    }
                  >
                    View More{" "}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/view-more.svg"
                      }
                      className="ms-1 img-fluid"
                      alt="arrow"
                    />
                  </span>
                </div>
              </div>
              <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                <div
                  className="net_exams_content tab-content"
                  id="v-pills-tabContent"
                >
                  <div
                    className="tab-pane fade show active"
                    id="v-pills-india"
                    role="tabpanel"
                    aria-labelledby="v-pills-india-tab"
                  >
                    <div className="exam_details">
                      <div className="cat_exams">
                        <h4 className="blue_29 font_600 font_26">
                          {singleExam?.name} {singleExam?.exam_year}
                        </h4>
                        <div className="august">
                          <div className="august_calendar">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/calendar.svg"
                              }
                              className="img-fluid"
                              alt="calendar"
                            />
                          </div>
                          <p className="mb-0 blue_29 font_500 font_15">
                            {dayjs(singleExam?.created_at).format("DD")} -{" "}
                            {dayjs(singleExam?.created_at).format("MMMM YYYY")}
                          </p>
                        </div>
                      </div>
                      <p
                        className="gray_5a font_20 inter"
                        dangerouslySetInnerHTML={{
                          __html: singleExam?.overview,
                        }}
                      ></p>

                      <button
                        type="button"
                        className="blue_button d-flex align-items-center"
                        onClick={() => {
                          localStorage.getItem("isLoggedIn") ||
                          localStorage.getItem("isSignedUp")
                            ? navigate("/apply-now")
                            : navigate("/login");
                        }}
                      >
                        Apply Now
                        <span className="icon-white-arrow ms-3 font_12"></span>
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="find_best">
        <div className="container">
          <h3 className="black_14 font_46 font_500 text-center">Jobs</h3>
          <div className="job_states">
            {jobList?.map((item) => {
              if (!item?.vacancies) return;
              return (
                <>
                  <div
                    className="state_box"
                    key={item?._id}
                    onClick={() => {
                      localStorage.getItem("isLoggedIn") ||
                      localStorage.getItem("isSignedUp")
                        ? navigate("/jobs")
                        : navigate("/login");
                    }}
                  >
                    <div className="states">
                      <div className="state_graph">
                        <img
                          src={item?.icon}
                          className="img-fluid"
                          alt="icon"
                        />
                      </div>
                      <div className="state_gray">
                        <p className="course_name font_600 font_18 black_14 mb-0 gujarat_capital">
                          {item?.state}
                        </p>
                        <span className="course_name font_300 gray_5a">
                          {item?.vacancies > 0 ? "+" : ""} {item?.vacancies}{" "}
                          {item?.vacancies > 1 ? "Jobs" : "Job"}
                        </span>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </div>
        </div>
      </section>

      <section className="main_colleges">
        <div className="container">
          <div className="trending_heading d-flex align-items-center">
            <p className="find_latter_spacing blue_29 font_22 font_600 mb-0 me-1 mx-1">
              Lastest
            </p>
            <h3 className="black_14 font_46 font_500 mb-0 mx-1">News</h3>
          </div>
          <Slider {...newsSettings}>
            {newsList?.map((item) => {
              return (
                <>
                  <div className="news_card mx-3 my-2" key={item?._id}>
                    <div className="college_card_image ">
                      {item?.media?.map((i) => {
                        return (
                          <>
                            <div
                              className="news_image college_image"
                              key={i?._id}
                            >
                              <img
                                src={
                                  i?.name === ""
                                    ? "https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                    : i?.name
                                }
                                className="img-fluid"
                                alt="college"
                              />
                            </div>
                          </>
                        );
                      })}
                    </div>
                    <div className="test_center">
                      <div className="mb-3 d-flex justify-content-between">
                        <div className="news_reporter d-flex align-items-center">
                          <div className="news_reportner_logo">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/user.svg"
                              }
                              className="img-fluid"
                              alt="user"
                            />
                          </div>
                          <div className="news_reportner_name">
                            <span className="font_300 black_00">
                              By {item?.name}
                            </span>
                          </div>
                        </div>
                        <div className="news_reporter d-flex align-items-center">
                          <div className="news_reportner_logo">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/calendar.svg"
                              }
                              className="img-fluid"
                              alt="calendar"
                            />
                          </div>
                          <div className="news_reportner_name">
                            <span className="font_300 black_00">
                              {dayjs(item?.insertdate).format("DD-MM-YYYY")}{" "}
                            </span>
                          </div>
                        </div>
                      </div>
                      <h5 className="black_00 font_18 font_600 news_description_2_line">
                        {item?.title}
                      </h5>
                      <div
                        className="gray_5a font_15 news_description_3_line inter"
                        dangerouslySetInnerHTML={{
                          __html: truncateDescription(item?.description, 6),
                        }}
                      ></div>
                      <div
                        className="card_text_buttons"
                        onClick={() => {
                          localStorage.getItem("isLoggedIn") ||
                          localStorage.getItem("isSignedUp")
                            ? navigate("/news")
                            : navigate("/login");
                        }}
                      >
                        <button
                          type="button"
                          className="white_button d-flex align-items-center mt-3"
                        >
                          Read More
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              );
            })}
          </Slider>
        </div>
        <div className="border_e1"></div>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="latest_news">
                <div className="latest_news_heading d-flex align-items-center justify-content-center">
                  <p className="find_latter_spacing blue_29 font_20 font_600 mb-0 me-1">
                    TRENDING
                  </p>
                  <h3 className="black_14 font_30 font_500 mb-0">Topics</h3>
                </div>

                <TrendingTopics />
                <p
                  className="blue_29 font_18 font_500 view_more_exams justify-content-center mt-5 pb-3"
                  onClick={() => {
                    localStorage?.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp")
                      ? navigate("/trending-topics")
                      : navigate("/login");
                  }}
                >
                  View More{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/icons/view-more.svg"
                    }
                    className="ms-1 img-fluid"
                    alt="arrow"
                  />
                </p>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="latest_news  mt-4 mt-sm-0">
                <div className="latest_news_heading d-flex align-items-center justify-content-center">
                  <p className="find_latter_spacing blue_29 font_20 font_600 mb-0 me-1">
                    add
                  </p>
                  <h3 className="black_14 font_30 font_500 mb-0">Members</h3>
                </div>
                <div className="latest_news_list align-items-start">
                  <div className="latest_news_list_profile">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/member-1.svg"
                      }
                      className="img-fluid"
                      alt="hash"
                    />
                  </div>
                  <div className="latest_news_list_name">
                    <h6 className="blue_29 font_500 font_18 mb-0">
                      Sandeep Singh Randhawa
                    </h6>
                    <p className="light_gray_8b font_12">
                      Stulink@Sandeep Singh Randhawa
                    </p>
                    <div className="university">
                      <div className="university_logo">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/university.svg"
                          }
                          className="img-fluid"
                          alt="university"
                        />
                      </div>
                      <div className="university_name">
                        <span className="black_14 font_500">
                          Chandigarh University
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="blue_button d-flex align-items-center font_12 font_500"
                      onClick={() => {
                        localStorage?.getItem("isLoggedIn") ||
                        localStorage.getItem("isSignedUp")
                          ? navigate("/trending-topics")
                          : navigate("/login");
                      }}
                    >
                      <span className="icon-link font_14 me-2 font_12"></span>
                      Link
                    </button>
                  </div>
                </div>
                <div className="latest_news_list align-items-start">
                  <div className="latest_news_list_profile">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/member-2.svg"
                      }
                      className="img-fluid"
                      alt="hash"
                    />
                  </div>
                  <div className="latest_news_list_name">
                    <h6 className="blue_29 font_500 font_18 mb-0">
                      Deepak Sharma
                    </h6>
                    <p className="light_gray_8b font_12">
                      Stulink@Deepak Sharma
                    </p>
                    <div className="university">
                      <div className="university_logo">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/university.svg"
                          }
                          className="img-fluid"
                          alt="university"
                        />
                      </div>
                      <div className="university_name">
                        <span className="black_14 font_500">
                          Lady Shri Ram College
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="blue_button d-flex align-items-center font_12 font_500"
                      onClick={() => {
                        localStorage?.getItem("isLoggedIn") ||
                        localStorage.getItem("isSignedUp")
                          ? navigate("/trending-topics")
                          : navigate("/login");
                      }}
                    >
                      <span className="icon-link font_14 me-2 font_12"></span>
                      Link
                    </button>
                  </div>
                </div>
                <div className="latest_news_list align-items-start">
                  <div className="latest_news_list_profile">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/member-3.svg"
                      }
                      className="img-fluid"
                      alt="hash"
                    />
                  </div>
                  <div className="latest_news_list_name">
                    <h6 className="blue_29 font_500 font_18 mb-0">
                      Shubham Rathora
                    </h6>
                    <p className="light_gray_8b font_12">
                      Stulink@Shubham Rathora
                    </p>
                    <div className="university">
                      <div className="university_logo">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/university.svg"
                          }
                          className="img-fluid"
                          alt="university"
                        />
                      </div>
                      <div className="university_name">
                        <span className="black_14 font_500">
                          Lovely Professional University
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="blue_button d-flex align-items-center font_12 font_500"
                      onClick={() => {
                        localStorage?.getItem("isLoggedIn") ||
                        localStorage.getItem("isSignedUp")
                          ? navigate("/trending-topics")
                          : navigate("/login");
                      }}
                    >
                      <span className="icon-link font_14 me-2 font_12"></span>
                      Link
                    </button>
                  </div>
                </div>
                <div className="latest_news_list align-items-start">
                  <div className="latest_news_list_profile">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/member-3.svg"
                      }
                      className="img-fluid"
                      alt="hash"
                    />
                  </div>
                  <div className="latest_news_list_name">
                    <h6 className="blue_29 font_500 font_18 mb-0">
                      Sanjay Gupta
                    </h6>
                    <p className="light_gray_8b font_12">
                      Stulink@Sanjay Gupta
                    </p>
                    <div className="university">
                      <div className="university_logo">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/university.svg"
                          }
                          className="img-fluid"
                          alt="university"
                        />
                      </div>
                      <div className="university_name">
                        <span className="black_14 font_500">
                          Indian Institute of Technology
                        </span>
                      </div>
                    </div>
                    <button
                      type="button"
                      className="blue_button d-flex align-items-center font_12 font_500"
                      onClick={() => {
                        localStorage?.getItem("isLoggedIn") ||
                        localStorage.getItem("isSignedUp")
                          ? navigate("/trending-topics")
                          : navigate("/login");
                      }}
                    >
                      <span className="icon-link font_14 me-2 font_12"></span>
                      Link
                    </button>
                  </div>
                </div>
                <p
                  className="blue_29 font_18 font_500 view_more_exams justify-content-center pb-2"
                  onClick={() => {
                    localStorage?.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp")
                      ? navigate("/trending-topics")
                      : navigate("/login");
                  }}
                >
                  View More
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/icons/view-more.svg"
                    }
                    className="ms-1 img-fluid"
                    alt="arrow"
                  />
                </p>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-12">
              <div className="latest_news">
                <div className="latest_news_heading d-flex align-items-center justify-content-center">
                  <p className="find_latter_spacing blue_29 font_20 font_600 mb-0 me-1">
                    chat
                  </p>
                  <h3 className="black_14 font_30 font_500 mb-0">Room</h3>
                </div>
                {groupList?.slice(0, 4)?.map((item) => {
                  return (
                    <>
                      <div
                        className="latest_news_list align-items-start"
                        key={item?._id}
                        onClick={() => {
                          localStorage?.getItem("isLoggedIn") ||
                          localStorage.getItem("isSignedUp")
                            ? navigate("/trending-topics")
                            : navigate("/login");
                        }}
                      >
                        <div className="latest_news_list_logo">
                          <img
                            src={
                              !item?.icon
                                ? process.env.PUBLIC_URL +
                                  "/assets/images/user/image.png"
                                : process.env.PUBLIC_URL +
                                  "/assets/images/user/image.png"
                            }
                            className="img-fluid"
                            alt="hash"
                          />
                        </div>
                        <div className="latest_news_list_name">
                          <div className="d-flex justify-content-between w-100">
                            <h6 className="black_14 font_500 font_18 mb-0">
                              {item?.name}
                            </h6>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "assets/images/icons/3-dots.svg"
                              }
                              className="img-fluid cursor-pointer"
                              alt="dots"
                            />
                          </div>
                          <p className="gray_5a">
                            {item?.member_count} Members
                          </p>
                          <button
                            type="button"
                            className="blue_button d-flex align-items-center font_12 font_500"
                          >
                            {" "}
                            Join Now{" "}
                            <span className="icon-white-arrow ms-2 font_10"></span>
                          </button>
                        </div>
                      </div>
                    </>
                  );
                })}

                <p
                  className="blue_29 font_18 font_500 view_more_exams justify-content-center pb-2"
                  onClick={() => {
                    localStorage?.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp")
                      ? navigate("/trending-topics")
                      : navigate("/login");
                  }}
                >
                  View More
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "assets/images/icons/view-more.svg"
                    }
                    className="ms-1 img-fluid"
                    alt="arrow"
                  />
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>

      <section className="find_best">
        <div className="container">
          <h2 className="black_14 font_46 font_500 text-center margin_bottom_35">
            Help Center
          </h2>
          <div className="row">
            <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
              <div className="ask_question">
                <div className="question_logo">
                  <img
                    src={process.env.PUBLIC_URL + "assets/images/icons/ask.svg"}
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
                <div className="question_text">
                  <h5 className="font_600 font_24 black_00">Ask a question</h5>
                  <p className="news_description_2_line light_gray_8b font_16">
                    Lorem ipsum dolor sit dolor sit amet, consectetuer
                    adipiscing....
                  </p>
                </div>
              </div>
              <div className="ask_question">
                <div className="question_logo">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/icons/answer.svg"
                    }
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
                <div className="question_text">
                  <h5 className="font_600 font_24 black_00">Get a Answer</h5>
                  <p className="news_description_2_line light_gray_8b font_16">
                    Lorem ipsum dolor sit dolor sit amet, consectetuer
                    adipiscing....
                  </p>
                </div>
              </div>
              <div className="ask_question">
                <div className="question_logo">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/icons/rate.svg"
                    }
                    className="img-fluid"
                    alt="icon"
                  />
                </div>
                <div className="question_text">
                  <h5 className="font_600 font_24 black_00">
                    Rate your answer
                  </h5>
                  <p className="news_description_2_line light_gray_8b font_16">
                    Lorem ipsum dolor sit dolor sit amet, consectetuer
                    adipiscing....
                  </p>
                </div>
              </div>
              <button
                type="button"
                className="margin_left_43 blue_button d-flex align-items-center font_18 font_500 mb-3"
              >
                ASK QUESTION
              </button>
            </div>
            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
              <div className="question_box">
                <div className="question_box_image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/icons/help-1.svg"
                    }
                    className="img-fluid"
                    alt="course"
                  />
                </div>
                <div className="question_box_text">
                  <h4 className="font_600 font_26 black_00">Courses</h4>
                  <p className="news_description_1_line light_gray_8b font_16">
                    Lorem ipsum dolor sit amet, cr......
                  </p>
                  <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      localStorage.getItem("isLoggedIn") ||
                      localStorage.getItem("isSignedup")
                        ? navigate("/courses")
                        : navigate("/login");
                    }}
                  >
                    <span className="blue_29 font_600 font_15">
                      View ALL Questions
                    </span>
                    <span className="icon-blue-arrow ms-3"></span>
                  </div>
                </div>
              </div>
              <div className="question_box">
                <div className="question_box_image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/icons/help-2.svg"
                    }
                    className="img-fluid"
                    alt="jobs"
                  />
                </div>
                <div className="question_box_text">
                  <h4 className="font_600 font_26 black_00">Jobs</h4>
                  <p className="news_description_1_line light_gray_8b font_16">
                    Lorem ipsum dolor sit amet, cr......
                  </p>
                  <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      localStorage.getItem("isLoggedIn") ||
                      localStorage.getItem("isSignedup")
                        ? navigate("/jobs")
                        : navigate("/login");
                    }}
                  >
                    <span className="blue_29 font_600 font_15">
                      View ALL Questions
                    </span>
                    <span className="icon-blue-arrow ms-3"></span>
                  </div>
                </div>
              </div>
              <div className="question_box">
                <div className="question_box_image">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/icons/help-3.svg"
                    }
                    className="img-fluid"
                    alt="College"
                  />
                </div>
                <div className="question_box_text">
                  <h4 className="font_600 font_26 black_00">College</h4>
                  <p className="news_description_1_line light_gray_8b font_16">
                    Lorem ipsum dolor sit amet, cr......
                  </p>
                  <div
                    className="d-flex align-items-center cursor-pointer"
                    onClick={() => {
                      localStorage.getItem("isLoggedIn") ||
                      localStorage.getItem("isSignedup")
                        ? navigate("/colleges")
                        : navigate("/login");
                    }}
                  >
                    <span className="blue_29 font_600 font_15">
                      View ALL Questions
                    </span>
                    <span className="icon-blue-arrow ms-3"></span>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
