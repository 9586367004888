import React, { useEffect, useState } from "react";
import { get_home_trending_college } from "../api/apiHandler";
import { useNavigate } from "react-router-dom";

export default function BestColleges() {
  const [bestColleges, setBestCollegs] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    get_home_trending_college({}).then((response) => {
      console.log("Best colleges", response);
      if (response?.code === 1 || response?.code === "1") {
        setBestCollegs(response?.data);
      }
    });
  }, []);
  return (
    <>
      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
        <div className="trending_admission_box">
          <div className="trending_admission_box_heading">
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/icons/cutoff-school.svg"
              }
              className="img-fluid me-2"
              alt="icon"
            />
            <h6 className="white_ff font_16 font_500 mb-0">Best Colleges</h6>
          </div>
          <div className="trending_admission_box_body">
            <ul className="list-unstyled">
              {bestColleges?.slice(0, 4)?.map((item) => {
                return (
                  <>
                    <li>
                      <div className="chandi_admission">
                        <div className="uni_profile_image">
                          <img
                            src={item?.college_logo}
                            className="img-fluid"
                            alt=""
                          />
                        </div>
                        <div className="uni_profile_text">
                          <h6 className="inter font_400 font_15">
                            {item?.name}
                          </h6>
                          <div className="yellow_location d-flex align-items-center">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/map-blue.svg"
                              }
                              className="img-fluid me-1"
                              alt="icon"
                            />
                            <span className="font_10 inter blue_29">
                              {item?.city}
                            </span>
                          </div>
                        </div>
                      </div>

                      <span
                        className="gray_5a font_12 inter span_wrap"
                        style={{ cursor: "pointer" }}
                      // onClick={() =>
                      //   navigate("/college-info", {
                      //     state: item._id,
                      //   })
                      // }
                      >
                        <span onClick={() => { navigate('/college-info', { state: { id: item._id, type: 'Admission' } }) }}>Admission</span>|
                        <span onClick={() => { navigate('/college-info', { state: { id: item._id, type: 'Placement' } }) }}>Placement</span>|
                        <span onClick={() => { navigate('/college-info', { state: { id: item._id, type: 'Course' } }) }}>Courses</span>|
                        <span onClick={() => { navigate('/college-info', { state: { id: item._id, type: 'Cutoff' } }) }}>CutOff</span>
                      </span>
                    </li>
                  </>
                );
              })}
            </ul>
          </div>
        </div>
      </div>
    </>
  );
}
