import React, { useEffect, useState } from 'react'
import { get_degree_list } from '../api/apiHandler';

export default function BestCourses() {
    const [bestCourses, setBestCourses] = useState([]);
    useEffect(()=>{
        get_degree_list({"page_token":""}).then((response)=>{
            console.log("Best courses", response);
            if(response?.code === 1 || response?.code === "1"){
                setBestCourses(response?.data?.degree_list_result)
            }
        })
    }, [])
  return (
    <>
        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 my-4">
                <div className="trending_admission_box">
                    <div className="trending_admission_box_heading">
                        <img
                            src={process.env.PUBLIC_URL + "/assets/images/icons/cutoff-school.svg"}
                            className="img-fluid me-2"
                            alt="icon"
                        />
                        <h6 className="white_ff font_16 font_500 mb-0">Best Courses</h6>
                    </div>
                    <div className="trending_admission_box_body">
                        <ul className="list-unstyled">
                            {
                                bestCourses?.slice(0, 4)?.map((item) => {
                                    return (
                                        <>
                                        <li>
                                            <div className="chandi_admission">
                                                
                                                <div className="uni_profile_text arrow_right">
                                                    <h6 className="inter font_400 font_15 blue_29">{item?.name} </h6>
                                                    
                                                </div>
                                            </div>
                                            <span class="gray_5a font_12 inter">{item?.duration} year | {item?.type}  </span>
                                        </li>
                                        
                                        </>
                                    )
                                })
                            }

                        </ul>
                    </div>
                </div>
            </div>
    </>
  )
}
