import axios from "axios";
import { logOutRedirectCall } from "../common/RedirectPathManage";
import CryptoJS from 'crypto-js';
import Swal from 'sweetalert2'

var key = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_KEY);
var iv = CryptoJS.enc.Utf8.parse(process.env.REACT_APP_IV);


export const showMessage = (msg) => {
  Swal.fire({
    title: 'Stulink',
    text: msg,
    confirmButtonText: 'OK'
  })
}

const axiosClient = axios.create({
  baseURL: process.env.REACT_APP_BASE_URL,
  headers: {
    "api-key": process.env.REACT_APP_API_KEY,
    "Accept-Language": "en",
    "Content-Type": "text/plain",
    "accept-tag": "web",
  }
});

// console.log("Line 27 Axios Client fff ===>>>", localStorage.getItem("token"))

axiosClient.interceptors.request.use(function (request) {

  // console.log("Line 31 Axios request", request)

  request.data = bodyEncryption(request.data, true)
  // console.log("Line 34", request.data);


  if (localStorage.getItem("token") !== undefined || localStorage.getItem("token") !== null) {
    //  console.log("Line 38 Pass Token",localStorage.getItem("token"))
    //  console.log("Line 39 Config 2 ==>",request.headers)

    request.headers['token'] = localStorage.getItem("token")
    //  console.log("Line 42 Encrypted Token ==>",request.headers.token)

  }
  //  console.log("Line 45 Final Config",request.data)
  return request;
});

axiosClient.interceptors.response.use(
  function (response) {
    // console.log("===========> Line 52 respData", response);
    response = bodyDecryption(response?.data);

    // console.log("Line 53 Decrypt Data of response", response)
    // var respData = JSON.stringify(response);
    // console.log("Line 57", respData);
    if (response?.code === "0") {
      // showMessage(response?.message)
    } else if (response?.code === "-1") {
      logOutRedirectCall();
      return;
    }
    return response;
  },
  function (error) {
    // console.log("Error", error)
    let res = error.response;
    // console.log("Line 70", res);
    if (res?.status === 401) {
      logOutRedirectCall();
    }
    // console.error("Looks like there was a problem. Status Code: " + res.status);
    return Promise.reject(error);
  }
);

// function authHeaders(token, body) {
//   // console.log("111")
//   // console.log("Token===>", token)
//   // console.log("Body", body)

//   if(token !== undefined)
//   {
//   //  console.log("222")

//       if(body !== null && body !== "")
//       {
//       // console.log("333")

//           return {
//               'api-key' : 'cQBodzEcGhBw78JUH2tVYg==',
//               'accept-language' : 'en',
//               'Content-Type' : 'text/plain',
//               'Access-Control-Allow-Origin' : '*',
//               'token' : bodyEncryption(token, false)
//           }   
//       }

//       return {
//           'api-key' : 'cQBodzEcGhBw78JUH2tVYg==',
//           'accept-language' : 'en',
//           'Access-Control-Allow-Origin' : '*',
//           'token' : bodyEncryption(token, false)
//       }   
//   }
//   else
//   {
//   //  console.log("No Token Im here")
//       return {
//           'api-key' : 'cQBodzEcGhBw78JUH2tVYg==',
//           'accept-language' : 'en',
//           'Content-Type' : 'text/plain',
//           'Access-Control-Allow-Origin' : '*',
//       }
//   }

// }  

export function bodyEncryption(request) {
  // console.log("Line 118 Encryption Request", request)

  // var request = (isStringify) ? JSON.stringify(request) : request;

  // var encrypted = CryptoJS.AES.encrypt(request,  process.env.REACT_APP_SECRET, { iv: process.env.REACT_APP_IV });
  // var encrypted = CryptoJS.AES.encrypt(request, process.env.REACT_APP_SECRET, { iv: process.env.REACT_APP_IV }).toString();

  var encrypted = CryptoJS.AES.encrypt(JSON.stringify(request), key, { iv: iv });
  // console.log("Line 133", encrypted);
  return encrypted.toString();
}

export function bodyDecryption(request) {
  // console.log("Line 133 Inside body decryption",request);
  // var decrypted = CryptoJS.AES.decrypt(request, process.env.REACT_APP_SECRET, { iv: process.env.REACT_APP_IV });
  var bytes = CryptoJS.AES.decrypt(request.toString(), key, { iv: iv });
  var decryptedData = JSON.parse(bytes.toString(CryptoJS.enc.Utf8));
  // authToken = decryptedData.data.token[0] === '"' ? decryptedData.data.token.slice(1,-1): decryptedData.data.token
  // var decrypted = CryptoJS.AES.decrypt(request.toString(), key);
  // console.log("Decrypt Data", decryptedData);

  return decryptedData
  // .toString(CryptoJS.enc.Utf8);
}

export { axiosClient };

