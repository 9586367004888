import Footer from "./components/Footer";
import { lazy, Suspense, useEffect, useState } from "react";
// import Header from './components/Header';
// import { Helmet, HelmetProvider } from "react-helmet-async";
import Home from "./components/pages/Home";
import TempHome from "./components/pages/TempHome";
import { BrowserRouter, Outlet, Route, Routes } from "react-router-dom";
import Signup from "./components/pages/Signup";
import Collegeinfo from "./components/Collegeinfo";
import Login from "./components/Signupforms/Login";
import PrivacyPolicy from "./components/PrivacyPolicy";
import TermsCondition from "./components/TermsCondition";
import ForgotPassword from "./components/Signupforms/ForgotPassword";
import Username from "./components/Signupforms/Username";
import OTPVerification from "./components/Signupforms/OTPVerification";
import Collegestream from "./components/Signupforms/Collegestream";
import Friends from "./components/Signupforms/Friends";
import Linkhashtag from "./components/Signupforms/Linkhashtag";
import NewPassword from "./components/Signupforms/NewPassword";
import Profile from "./components/pages/Profile/Profile";
import Colleges from "./components/pages/Colleges";
import EditProfile from "./components/pages/EditProfile";
import Supportus from "./components/Supportus";
import NotificationSettings from "./components/ProfileDropdown/NotificationSettings";
import ChangePassword from "./components/ProfileDropdown/ChangePassword";
import Education from "./components/ProfileDropdown/Education";
import AccountSettings from "./components/ProfileDropdown/AccountSettings";
import AccountPrivacy from "./components/ProfileDropdown/AccountPrivacy";
// import HomeFeed from './components/pages/HomeFeed';

import FriendRequestList from "./components/FriendRequestList";
import NotificationList from "./components/NotificationList";
import PostComment from "./components/posts/PostComment";
import NewsMain from "./components/news/NewsMain";
import HelpCenter from "./components/help/HelpCenter";
import ApplyNow from "./common/ApplyNow";
import Course from "./components/courses/Course";
import NewsDetails from "./components/news/NewsDetails";
import QuestionDetail from "./components/help/QuestionDetail";
import Jobs from "./components/jobs/Jobs";
import ChatRoom from "./components/chat-room/ChatRoom";
import Members from "./components/members/Members";
import JobList from "./components/jobs/JobList";
import JobDetails from "./components/jobs/JobDetails";
import ExamDetails from "./components/exams/ExamDetails";
import Exams from "./components/exams/Exams";
import ExamList from "./components/exams/ExamList";
import ChatModel from "./components/chat-room/ChatModel";
import CourseDetails from "./components/courses/CourseDetails";
import CourseOverview from "./components/courses/CourseOverview";
import GroupList from "./components/chat-room/GroupList";
import SubComments from "./components/posts/SubComments";
import Reels from "./components/pages/Reels/Reels";
import DeletionPage from "./components/DeletionPage";
// import { CometChat } from "@cometchat-pro/chat";
import ViewStoryList from "./components/story/ViewStoryList";
import { UIKitSettingsBuilder } from "@cometchat/uikit-shared";
import { CometChatUIKit } from "@cometchat/chat-uikit-react";
import { Helmet } from "react-helmet";
import UploadDocument from "./components/uploadDocument";
import Calling from "./components/chat-room/calling";
import FirebasePost from "./components/posts/FirebasePost";
import Redirection from "./components/pages/Redirect";
import Error404 from "./components/pages/404";
import SaveFeed from "./components/pages/SaveFeed/index";
// import DynamicLinkHandler from "./components/DynamicLink";

// import { GroupListManager } from "./cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Groups/CometChatGroupList/controller";

export const Loadable = (Components) => (props) => {
  return (
    <Suspense>
      <Components {...props} />
    </Suspense>
  );
};
const HomeFeed = Loadable(
  lazy(() => import("./components/pages/HomeFeed/HomeFeed"))
);
const saveFeed = Loadable(
  lazy(() => import("./components/pages/SaveFeed/index"))
);
const HomeSearchFeed = Loadable(
  lazy(() => import("./components/pages/HomeSearchFeed"))
);

const FooterLayout = () => {
  return (
    <>
      <Outlet />
      <Footer />
    </>
  );
};

const CometLayout = () => {
  const appID = process.env.REACT_APP_CHAT_APP_ID;
  const region = process.env.REACT_APP_CHAT_REGION;
  const authKey = process.env.REACT_APP_CHAT_AUTH_KEY;
  const uid = localStorage?.getItem("_id");

  // const appSetting = new CometChat.AppSettingsBuilder()
  // //   .subscribePresenceForAllUsers()
  //   .setRegion(region)
  //   .build();

  // CometChat.init(appID, appSetting).then(
  //   () => {
  //     if (uid && authKey) {
  //       CometChat.login(uid, authKey).then(
  //         (user) => {
  //
  //         },
  //         (error) => {
  //
  //         }
  //       );
  //     }
  //
  //   },
  //   (error) => {
  //
  //   }
  // );

  // const appID = process.env.REACT_APP_CHAT_APP_ID;
  // const region = process.env.REACT_APP_CHAT_REGION;
  // const authKey = process.env.REACT_APP_CHAT_AUTH_KEY;
  const COMETCHAT_CONSTANTS = {
    APP_ID: appID, // Replace with your App ID
    REGION: region, // Replace with your App Region ("eu" or "us")
    AUTH_KEY: authKey, // Replace with your Auth Key
    uid: localStorage?.getItem("_id"),
  };

  //Initialize CometChat
  const UIKitSettings = new UIKitSettingsBuilder()
    .setAppId(COMETCHAT_CONSTANTS.APP_ID)
    .setRegion(COMETCHAT_CONSTANTS.REGION)
    .setAuthKey(COMETCHAT_CONSTANTS.AUTH_KEY)
    .subscribePresenceForFriends()
    .build();

  //Initialize CometChat UIKit
  CometChatUIKit.init(UIKitSettings)
    .then(() => {
      // You can now call login function.

      if (uid && authKey) {
        CometChatUIKit.getLoggedinUser().then((user) => {
          if (!user) {
            //Login user
            CometChatUIKit.login(uid, authKey)
              .then((user) => {
                //mount your app
              })
              .catch((err) => {});
          } else {
            //mount your app
          }
        });
      }
    })
    .catch(console.log);

  return <Outlet />;
};

const ScrollToTop = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return <></>;
};

function App() {
  const [interaction, setInteraction] = useState(false);
  useEffect(() => {
    // Inside your useEffect hook
    const handleUserInteraction = () => {
      setInteraction(true);
    };

    // Attach event listener to the document for any user interaction
    document.addEventListener("click", handleUserInteraction);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleUserInteraction);
    };
  });

  return (
    <>
      {/* <DynamicLinkHandler /> */}
      <BrowserRouter>
        <main className="stulink">
          <ScrollToTop />
          <Calling />

          <Routes>
            <Route element={<CometLayout />}>
              <Route element={<FooterLayout />}>
                {/* <Route exact path="/" element={localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ?<HomeFeed />:<Home />} /> */}
                {/* <Route exact path='/home' element={<Home />} /> */}

                <Route
                  exact
                  path="/home"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <HomeFeed interaction={interaction} />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  exact
                  path="/home-search"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <HomeSearchFeed />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  exact
                  path="/saved-post"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <SaveFeed />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route exact path="/sign-up" element={<Signup />} />
                <Route
                  exact
                  path="/college-info"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <Collegeinfo />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  exact
                  path="/colleges"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <Colleges />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route exact path="/login" element={<Login />} />

                {/* localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ?  */}
                <Route
                  exact
                  path="/forgot-password"
                  element={<ForgotPassword />}
                />
                <Route exact path="/username" element={<Username />} />
                <Route
                  exact
                  path="/otp-verification"
                  element={<OTPVerification />}
                />
                <Route
                  exact
                  path="/college-stream"
                  element={<Collegestream />}
                />
                <Route exact path="/friends" element={<Friends />} />
                <Route exact path="/linkhashtags" element={<Linkhashtag />} />
                <Route exact path="/new-password" element={<NewPassword />} />
                {/* <Route exact path='/:id' element={localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <Profile /> : <Home />} /> */}
                <Route
                  exact
                  path="/profile"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <Profile />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  exact
                  path="/upload-document"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <UploadDocument />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route exact path="/edit-profile" element={<EditProfile />} />
                <Route exact path="/support-us" element={<Supportus />} />
                <Route
                  exact
                  path="/notification-settings"
                  element={<NotificationSettings />}
                />
                <Route
                  exact
                  path="/change-password"
                  element={<ChangePassword />}
                />
                <Route exact path="/education" element={<Education />} />
                <Route
                  exact
                  path="/account-settings"
                  element={<AccountSettings />}
                />
                <Route exact path="/privacy" element={<AccountPrivacy />} />
                <Route exact path="/post/" element={<FirebasePost />} />
                <Route
                  exact
                  path="/post/:id"
                  element={<PostComment interaction={interaction} />}
                />
                <Route
                  exact
                  path="/request-list"
                  element={<FriendRequestList />}
                />
                <Route
                  exact
                  path="/notifications-list"
                  element={<NotificationList />}
                />
                <Route exact path="/question" element={<QuestionDetail />} />
                <Route exact path="/news" element={<NewsMain />} />
                <Route exact path="/exams" element={<Exams />} />
                <Route exact path="/exam-list" element={<ExamList />} />
                <Route exact path="/exam-details" element={<ExamDetails />} />
                {/* <Route exact path='/profile/:id/linking-in' element={<LinkingIn />} />
                <Route exact path='/profile/:id/linking-out' element={<LinkingOut />} /> */}
                {/* <Route exact path="/help-center" element={<HelpCenter />} /> */}
                <Route
                  exact
                  path="/help-center"
                  element={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp") ? (
                      <HelpCenter />
                    ) : (
                      <Home />
                    )
                  }
                />
                <Route
                  exact
                  path="/apply-now/:type/:id"
                  element={<ApplyNow />}
                />
                <Route exact path="/courses" element={<Course />} />
                <Route
                  exact
                  path="/course-details"
                  element={<CourseDetails />}
                />
                <Route
                  exact
                  path="/course-overview"
                  element={<CourseOverview />}
                />
                <Route
                  exact
                  path="/newsdetails/:id"
                  element={<NewsDetails />}
                />
                <Route exact path="/jobs" element={<Jobs />} />
                <Route exact path="/job-list" element={<JobList />} />
                <Route exact path="/job-details" element={<JobDetails />} />

                <Route exact path="/members" element={<Members />} />
                <Route exact path="/comments" element={<SubComments />} />
                <Route
                  exact
                  path="/reels"
                  element={<Reels interaction={interaction} />}
                />
                <Route exact path="/chat-model" element={<ChatModel />} />
                <Route exact path="/redirect" element={<Redirection />} />
              </Route>
              <Route exact path="/group-chat-model" element={<GroupList />} />
              <Route exact path="/chat-room" element={<ChatRoom />} />
              <Route
                exact
                path="/stories/:id/:media"
                element={<ViewStoryList interaction={interaction} />}
              />
            </Route>
            <Route
              exact
              path="/"
              element={
                // localStorage.getItem("isLoggedIn") ||
                // localStorage.getItem("isSignedUp") ? (
                //   <HomeFeed />
                // ) : (
                <TempHome />
                // )
              }
            />
            <Route exact path="/privacy-policy" element={<PrivacyPolicy />} />
            <Route exact path="/terms-of-use" element={<TermsCondition />} />
            <Route exact path="/account-deletion" element={<DeletionPage />} />
            <Route path="*" element={<Error404 />} />
          </Routes>
        </main>
      </BrowserRouter>
    </>
  );
}

export default App;
