import React, { useState, useEffect, useCallback, useRef } from "react";
import Card from "react-bootstrap/Card";
import { useSwiper } from "swiper/react";
import "swiper/css";
import { Spinner } from "react-bootstrap";
import {
  like_stories_media,
  report_story,
  share_link,
  view_stories_media,
} from "../../api/apiHandler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import EmojiPicker from "emoji-picker-react";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { getTimeDifference } from "../pages/HomeFeed/components/PostUserDetails";
import { toast } from "react-toastify";

function StoryComponent({
  interaction = false,
  user = {},
  stories = [],
  index,
  isCentered,
  isLast,
  storyList,
}) {
  const { media } = useParams();
  const [currentStoryIndex, setCurrentStoryIndex] = useState(
    stories?.findIndex((item) => item?._id == media) > 0
      ? stories?.findIndex((item) => item?._id == media)
      : 0
  );
  console.log("currentStoryIndex: ", currentStoryIndex);
  const [progress, setProgress] = useState(0);
  const [isHolding, setIsHolding] = useState(true);
  const [loaded, setLoaded] = useState(false);
  const [isInputFocused, setIsInputFocused] = useState(false);
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [message, setMessage] = useState("");
  const [isLike, setLike] = useState(stories[currentStoryIndex].is_liked);
  // const [interaction, setInteraction] = useState(false);
  const swiper = useSwiper();
  console.log("swiper: ", swiper);
  const location = useLocation();
  const navigate = useNavigate();

  const videoRef = useRef(null);
  let mousedownId = useRef();
  const isInput = storyList[index].message_input;

  const handleStoryChange = useCallback(
    (direction = "next") => {
      if (
        currentStoryIndex === stories.length - 1 &&
        direction === "next" &&
        !isLast
      ) {
        swiper.slideNext();
      } else if (
        currentStoryIndex === stories.length - 1 &&
        direction === "next" &&
        isLast
      ) {
        navigate("/");
      } else {
        setCurrentStoryIndex((prevIndex) =>
          direction === "next"
            ? prevIndex + 1
            : prevIndex === 0
            ? 0
            : prevIndex - 1
        );
      }
      currentStoryIndex !== 0 && isCentered && setIsHolding(true);
      currentStoryIndex !== 0 && isCentered && setLoaded(false);
      setProgress(0); // Reset progress when changing stories
    },
    [currentStoryIndex, isCentered, isLast, navigate, stories.length, swiper]
  );

  useEffect(() => {
    let interval;
    let timeout;

    if (!isCentered && videoRef.current) {
      setProgress(0);
      videoRef.current.pause();
    }

    if (isCentered && progress < 100 && !isHolding) {
      interval = setInterval(() => {
        setProgress((prevProgress) => {
          if (stories[currentStoryIndex].type === "video") {
            const videoDuration =
              stories[currentStoryIndex].duration ||
              videoRef.current.duration * 1000;
            const increment = (100 / videoDuration) * 100;
            return Math.min(prevProgress + increment, 100);
          } else {
            const increment = (100 / 5000) * 100;
            return Math.min(prevProgress + increment, 100);
          }
        });
      }, 100);
      if (videoRef.current && !isHolding) {
        videoRef.current.play();
      } else if (videoRef.current && isHolding) {
        videoRef.current.pause();
      }
    } else {
      clearInterval(interval);
      clearTimeout(timeout);
    }

    if (progress === 100) {
      timeout = setTimeout(() => {
        handleStoryChange("next");
      }, 1000);
    }

    return () => {
      clearInterval(interval);
      clearTimeout(timeout);
    };
  }, [
    currentStoryIndex,
    handleStoryChange,
    isCentered,
    isHolding,
    progress,
    stories,
  ]);

  const debouncePause = (e) => {
    mousedownId.current = setTimeout(() => {
      setIsHolding(true);
      if (videoRef.current) {
        videoRef.current.pause();
      }
    }, 200);
  };

  const mouseUp = (type) => (e) => {
    mousedownId.current && clearTimeout(mousedownId.current);
    if (isHolding) {
      setIsHolding(false);
    } else {
      type === "next" ? handleStoryChange() : handleStoryChange("prev");
    }
  };

  const imageLoaded = (e) => {
    setLoaded(true);
    setIsHolding(false);
  };

  const handleRedirectToNextSlide = () => {
    !isCentered && swiper.slideTo(index, 1000, true);
  };
  const currentViewStory = stories[currentStoryIndex];
  // console.log("currentViewStory: ", currentViewStory);

  const handleLikeUnlikeStory = useCallback(() => {
    like_stories_media({
      stories_media_id: stories[currentStoryIndex]._id,
      type: stories[currentStoryIndex].is_liked == 1 ? "remove" : "like",
    }).then((response) => {
      console.log("response: new =====>", response);
      if (response.code == "1") {
        if (stories[currentStoryIndex].is_liked == 1) {
          stories[currentStoryIndex].is_liked = 0;
          setLike(false);
        } else {
          setLike(true);
          stories[currentStoryIndex].is_liked = 1;
        }
      }
    });
  }, [currentStoryIndex, stories]);

  const handleEmoji = (e) => {
    setMessage(message + e?.emoji);
  };

  const handleCopyLink = useCallback(() => {
    console.log("payloadddddddddddddddddddddddddddddddddd", {
      id: currentViewStory._id,
      module_type: "story",
      description: "stulink",
      image: currentViewStory?.thumb,
    });
    share_link({
      id: currentViewStory._id,
      module_type: "story",
      description: "stulink",
      image: currentViewStory?.thumb,
    }).then(async (response) => {
      console.log("response: ", response);
      try {
        await navigator.clipboard.writeText(response.data);
        console.log("Text copied to clipboard:", response.data);
        toast.success("Link Copied");
        // share_post({ id: shareModalOpen.id }).then((res) => {
        //   setShareModalOpen({ open: false, share_link: "", id: "" });
        // });
      } catch (err) {
        console.error("Unable to copy text to clipboard:", err);
      }
    });
  }, [currentViewStory._id, currentViewStory?.thumb]);

  const handleSendMessage = useCallback(() => {
    let receiverID = user._id;
    let receiverType = CometChat.RECEIVER_TYPE.USER;
    const storyData = {
      ...storyList[index],
      media: [storyList[index].media[currentStoryIndex]],
    };
    const customData = {
      text: message,
      story_media: JSON.stringify(storyData),
    };
    let customMessage = new CometChat.CustomMessage(
      receiverID,
      receiverType,
      "custom",
      customData
    );
    CometChat.sendCustomMessage(customMessage).then(
      (message) => {
        setMessage("");
        setEmojiPickerVisible(false);
        toast.success("Message send successfully");
      },
      (error) => {}
    );
  }, [currentStoryIndex, index, message, storyList, user._id]);

  const handleEnterKey = useCallback(
    (e) => {
      if (e.key === "Enter") {
        // Trigger your action here
        if (message.length) {
          handleSendMessage();
          setIsHolding(false);
        }
      }
    },
    [handleSendMessage, message.length]
  );

  const reportStory = useCallback(() => {
    report_story({ id: currentViewStory?._id }).then((response) => {
      if (response.code == 1) {
        toast.success(response.message);
      }
    });
  }, [currentViewStory?._id]);

  useEffect(() => {
    if (currentViewStory?._id && !currentViewStory?.is_viewed) {
      view_stories_media({ stories_media_id: currentViewStory._id });
    }
  }, [currentViewStory?._id, currentViewStory?.is_viewed]);

  useEffect(() => {
    setLike(stories[currentStoryIndex].is_liked);
  }, [currentStoryIndex, stories]);

  useEffect(() => {
    const handleClick = (event) => {
      if (
        event.target.classList.contains("mySwiper") ||
        event.target.classList.contains("swiper-wrapper")
      ) {
        // Your click event handling logic here
        console.log("Swiper clicked!");
        isHolding && setIsHolding(false);
      }
    };

    // Add event listener when component mounts
    document.addEventListener("click", handleClick);

    // Clean up: Remove event listener when component unmounts
    return () => {
      document.removeEventListener("click", handleClick);
    };
  }, [isHolding]);

  useEffect(() => {
    console.log(
      "stories?.findIndex((item) => item?._id == media: ",
      stories?.findIndex((item) => item?._id == media),
      stories,
      media,
      stories?.findIndex((item) => item?._id == media)
    );
    if (
      stories.length &&
      isCentered &&
      location.state == "isDeleted" &&
      stories?.findIndex((item) => item?._id == media) == -1
    ) {
      // toast.error("Author Deleted this story", {
      //   toastId: "Story Deleted",
      // });
      navigate("/home", {
        state: {
          errorMessage: "Author Deleted this story",
        },
      });
    }
  }, [isCentered, location.state, media, navigate, stories]);

  return (
    <div
      className="d-flex justify-content-center align-items-center"
      style={{
        width: isCentered ? "100%" : "50%",
        height: isCentered ? "100vh" : "30vh",
        backgroundColor: "#1a1a1a",
        transition: !isCentered ? "height 0.7s ease" : "",
      }}
    >
      <Card
        className="position-relative d-flex bg-black align-items-center overflow-hidden"
        style={{ width: "100%", height: "85%", borderRadius: "25px" }}
      >
        <div
          className={
            isCentered
              ? "d-none"
              : "d-flex align-items-center  w-100 position-absolute flex-column justify-content-center h-100 flex-grow-1"
          }
          style={
            isCentered
              ? { top: "20px", left: "10px" }
              : { zIndex: 50, backgroundColor: "#262626b3" }
          }
          onClick={() => {
            handleRedirectToNextSlide();
          }}
        >
          <div
            className=""
            style={
              isCentered
                ? { width: "40px", height: "40px" }
                : { width: "90px", height: "90px" }
            }
          >
            <img
              className="img-fluid rounded-circle"
              src={user?.profile_image}
              alt=""
            />
          </div>
          <div className={isCentered ? "ms-2" : ""}>
            <p
              className="text-light mb-0"
              style={{ fontSize: "14px", fontWeight: "500" }}
            >
              {user.name}
            </p>
          </div>
        </div>

        {isCentered && (
          <div class="storie_header">
            <div
              class="user_header"
              onClick={() => {
                navigate("/profile", {
                  state: user?._id,
                });
              }}
            >
              <img
                src={user?.profile_image}
                className="rounded-circle "
                alt=""
              />
            </div>
            <div
              class="user_header_name text-start "
              onClick={() => {
                navigate("/profile", {
                  state: user?._id,
                });
              }}
            >
              <h6>{user.name}</h6>
              <p className="text-start">
                {getTimeDifference(stories[currentStoryIndex].insertdate)}
              </p>
            </div>
            <div class="user_drop_header">
              <div class="dropdown">
                <a
                  class=" dropdown-toggle"
                  href="/"
                  role="button"
                  id="dropdownMenuLink"
                  data-bs-toggle="dropdown"
                  aria-expanded="false"
                >
                  <img
                    src={process.env.PUBLIC_URL + "/assets/video/more.svg"}
                    alt=""
                  />
                </a>
                <ul
                  class="dropdown-menu ps-2"
                  aria-labelledby="dropdownMenuLink"
                >
                  {user?._id !== localStorage.getItem("_id") && (
                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        reportStory();
                      }}
                    >
                      Report
                    </li>
                  )}
                  <li
                    className="cursor-pointer"
                    onClick={() => {
                      handleCopyLink();
                    }}
                  >
                    Copy Link
                  </li>
                  {videoRef.current && (
                    <li
                      className="cursor-pointer"
                      onClick={() => {
                        if (videoRef.current && !videoRef.current?.muted) {
                          videoRef.current.muted = true;
                        } else {
                          videoRef.current.muted = false;
                        }
                      }}
                    >
                      {videoRef.current?.muted ? "Unmute" : "Mute"}
                    </li>
                  )}
                </ul>
              </div>
            </div>
          </div>
        )}
        <div
          className="d-flex w-100 position-absolute z-3"
          style={{ top: "60px" }}
        >
          {isCentered &&
            stories.map((story, idx) => (
              <div
                key={idx}
                className="progress bg-gray-200 flex-grow-1 m-1"
                style={{
                  backgroundColor: "rgba(255, 255, 255, .35)",
                  height: "3px",
                }}
              >
                <div
                  className={`progress-bar bg-white ${
                    idx === currentStoryIndex && progress
                      ? "progress-bar-animated"
                      : "animation-none"
                  }`}
                  role="progressbar"
                  style={{
                    width: `${
                      idx === currentStoryIndex
                        ? progress
                        : idx < currentStoryIndex
                        ? 100
                        : 0
                    }%`,
                  }}
                  aria-valuenow={
                    idx === currentStoryIndex
                      ? progress
                      : idx < currentStoryIndex
                      ? 100
                      : 0
                  }
                  aria-valuemin="0"
                  aria-valuemax="100"
                />
              </div>
            ))}
        </div>
        <div className="d-flex h-100 align-items-center">
          {stories[currentStoryIndex].type === "video" ? (
            <video
              id="videoPlayer"
              className="img-fluid object-fit-cover h-100 "
              src={stories[currentStoryIndex].name}
              ref={videoRef}
              autoPlay
              muted={!interaction ? "muted" : ""}
              onLoadedData={imageLoaded}
              playsInline
              loop // Add loop attribute for continuous playback
            />
          ) : (
            <img
              className="img-fluid object-fit-cover"
              src={stories[currentStoryIndex].name}
              alt={`${currentStoryIndex + 1}`}
              onLoad={imageLoaded}
              onLoadedData={() => {}}
            />
          )}
          {!loaded && isCentered && (
            <div
              style={{
                position: "absolute",
                left: 0,
                top: 0,
                background: "rgba(0, 0, 0, 0.9)",
                display: "flex",
                height: "100%",
                width: "100%",
                justifyContent: "center",
                alignItems: "center",
                color: "#ccc",
              }}
            >
              <Spinner />
            </div>
          )}
        </div>
        {/* Remove the swiper-lazy-preloader */}
        {!isInputFocused && (
          <div className="d-flex h-100 position-absolute w-100">
            <div
              className="w-50"
              style={{ cursor: "pointer" }}
              onTouchStart={(e) => debouncePause(e)}
              onTouchEnd={(e) => mouseUp("previous")(e)}
              onPointerDown={(e) => debouncePause(e)}
              onPointerUp={(e) => mouseUp("previous")(e)}
            ></div>
            <div
              className="w-50"
              style={{ cursor: "pointer" }}
              onTouchStart={(e) => debouncePause(e)}
              onTouchEnd={(e) => mouseUp("next")(e)}
              onPointerDown={(e) => debouncePause(e)}
              onPointerUp={(e) => mouseUp("next")(e)}
            ></div>
          </div>
        )}
        {isCentered && (
          <div class="bottom_mesg d-flex flex-column ">
            {emojiPickerVisible ? (
              <EmojiPicker onEmojiClick={(e) => handleEmoji(e)} />
            ) : null}
            <div className="d-flex">
              <div class="msg_sender">
                {isInput == "on" && (
                  <>
                    <input
                      type="text"
                      name=""
                      value={message}
                      className="text-light "
                      onClick={() => {
                        setIsInputFocused(true);
                      }}
                      onChange={(e) => {
                        console.log("e:===============> ", e);
                        setMessage(e.target.value);
                      }}
                      onFocus={(e) => {
                        setIsInputFocused(true);
                        debouncePause(e);
                      }}
                      onBlur={() => {
                        setIsInputFocused(false);
                      }}
                      onKeyDown={(e) => {
                        handleEnterKey(e);
                      }}
                      placeholder="Message..."
                    />
                    <button
                      class="btn_smily"
                      onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
                    >
                      <img
                        src={process.env.PUBLIC_URL + "/assets/video/smily.svg"}
                        alt=""
                      />
                    </button>
                    <button class="btn_sendr" onClick={handleSendMessage}>
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/video/send_btn.svg"
                        }
                        alt=""
                      />
                    </button>
                  </>
                )}
              </div>
              <button
                style={{
                  width: "30px",
                  marginLeft: "5px",
                  background: "transparent",
                }}
                onClick={() => {
                  handleLikeUnlikeStory();
                }}
              >
                <img
                  className="object-fit-contain "
                  src={
                    isLike
                      ? process.env.PUBLIC_URL + "/assets/video/red_heart.svg"
                      : process.env.PUBLIC_URL +
                        "/assets/video/unfill_heart.svg"
                  }
                  alt=""
                />
              </button>
            </div>
          </div>
        )}
      </Card>
    </div>
  );
}

export default StoryComponent;
