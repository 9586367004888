import React, { useEffect, useState } from "react";
import { get_home_exam } from "../../api/apiHandler";
import moment from "moment";

const UpcommingExams = () => {
  const [examsList, setExamsList] = useState([]);
  const [singleExam, setSingleExam] = useState();

  useEffect(() => {
    get_home_exam({}).then((response) => {
      console.log("Home exam", response);
      if (response?.code === 1 || response?.code === "1") {
        setExamsList(response?.data);
        setSingleExam(response?.data[0]);
      }
    });
  }, []);

  return (
    <div className="trending_admission_box">
      <div className="trending_admission_box_heading">
        <img
          src={
            process.env.PUBLIC_URL + "/assets/images/icons/upcoming-exams.svg"
          }
          className="img-fluid me-2"
          alt="icon"
        />
        <h6 className="white_ff font_16 font_500 mb-0">Upcoming Exams</h6>
      </div>
      <div className="trending_admission_box_body">
        <ul className="list-unstyled">
          {examsList?.map((item) => {
            return (
              <li>
                <h6 className="inter font_500 font_16 black_14">{item.name}</h6>
                <p className="mb-0 blue_29 inter font_12 font_600">
                  {item?.degree?.map((item) => item?.name)?.join(", ")}
                </p>
                <p className="mb-0 green_1d inter font_12 font_500">
                  {moment(item?.exam_date).format("DD MMM YYYY")}
                </p>
              </li>
            );
          })}
        </ul>
      </div>
    </div>
  );
};

export default UpcommingExams;
