import { axiosClient } from "./apiClient";

export function get_topic_list(data) {
  return axiosClient.post("api/v1/user/get_topic_list", data);
}

export function signup(data) {
  return axiosClient.post("api/v1/user/signup", data);
}

export function login(data) {
  return axiosClient.post("api/v1/user/login", data);
}

export function forgotpassword(data) {
  return axiosClient.post("api/v1/user/forgotpassword", data);
}

export function check_unique(data) {
  return axiosClient.post("api/v1/user/check_unique", data);
}

export function send_otp(data) {
  return axiosClient.post("api/v1/user/send_otp", data);
}

export function verify_otp(data) {
  return axiosClient.post("api/v1/user/verify_otp", data);
}

export function logout(data) {
  return axiosClient.post("api/v1/user/logout", data);
}

export function all_pages() {
  return axiosClient.get("api/v1/user/all_pages");
}

export function user_details(data) {
  return axiosClient.post("api/v1/user/user_details", data);
}

export function add_other_details(data) {
  return axiosClient.post("api/v1/user/add_other_details", data);
}

export function get_user_post_list(data) {
  return axiosClient.post("api/v1/post/get_user_post_list", data);
}

export function media_post_list(data) {
  return axiosClient.post("api/v1/post/media_post_list", data);
}

export function get_people_list(data) {
  return axiosClient.post("api/v1/friend/get_people_list", data);
}

export function home_post(data) {
  console.log("home_post: =========>", data);
  return axiosClient.post("api/v1/post/home_post", data);
}
export function get_saved_post(data) {
  console.log("home_post: =========>", data);
  return axiosClient.post("api/v1/post/saved_post_list", data);
}

export function edit_profile(data) {
  return axiosClient.post("api/v1/user/edit_profile", data);
}

export function support_request(data) {
  return axiosClient.post("api/v1/user/support_request", data);
}

export function changepassword(data) {
  return axiosClient.post("api/v1/user/changepassword", data);
}

export function get_college_list(data) {
  console.log("data: ", data);
  return axiosClient.post("api/v1/colleges/get_college_list", data);
}

export function get_degree_list(data) {
  return axiosClient.post("api/v1/colleges/get_degree_list", data);
}

export function college_details(data) {
  return axiosClient.post("api/v1/colleges/college_details", data);
}

export function gallery(data) {
  return axiosClient.post("api/v1/colleges/gallery", data);
}

export function filter_data(data) {
  return axiosClient.post("api/v1/colleges/filter_data", data);
}

export function top_course(data) {
  return axiosClient.post("api/v1/colleges/top_course", data);
}

export function give_like_unlike(data) {
  return axiosClient.post("api/v1/post/give_like_unlike", data);
}

export function post_details(data) {
  return axiosClient.post("api/v1/post/post_details", data);
}

export function add_post(data) {
  return axiosClient.post("api/v1/post/add_post", data);
}

export function add_comments(data) {
  return axiosClient.post("api/v1/post/add_comments", data);
}
export function stories_list(data) {
  return axiosClient.post("api/v1/post/stories_list", data);
}
export function get_single_stories_media(data) {
  return axiosClient.post("api/v1/post/get_single_stories_media", data);
}
export function view_stories_media(data) {
  return axiosClient.post("api/v1/post/view_stories_media", data);
}
export function like_stories_media(data) {
  return axiosClient.post("api/v1/post/like_stories_media", data);
}

export function delete_post(data) {
  return axiosClient.post("api/v1/post/delete_post", data);
}

export function document_category(data) {
  return axiosClient.post("api/v1/post/document_category", data);
}

export function document_subject(data) {
  return axiosClient.post("api/v1/post/document_subject", data);
}

export function other_user_details(data) {
  return axiosClient.post("api/v1/friend/other_user_details", data);
}

export function add_friend(data) {
  return axiosClient.post("api/v1/friend/add_friend", data);
}

export function get_friend_request_list(data) {
  return axiosClient.post("api/v1/friend/get_friend_request_list", data);
}

export function linking_in_list(data) {
  return axiosClient.post("api/v1/friend/linking_in_list", data);
}

export function linking_out_list(data) {
  return axiosClient.post("api/v1/friend/linking_out_list", data);
}

export function other_linking_in_list(data) {
  return axiosClient.post("api/v1/friend/other_linking_in_list", data);
}

export function other_linking_out_list(data) {
  return axiosClient.post("api/v1/friend/other_linking_out_list", data);
}

export function get_comment_list(data) {
  return axiosClient.post("api/v1/post/get_comment_list", data);
}

export function get_reel_comment_list(data) {
  return axiosClient.post("api/v1/post/get_reel_comment_list", data);
}

export function get_comment_qa_list(data) {
  return axiosClient.post("api/v1/qa/get_comment_list", data);
}

export function give_comments_like_unlike(data) {
  return axiosClient.post("api/v1/post/give_comments_like_unlike", data);
}

export function get_qa_list(data) {
  return axiosClient.post("api/v1/qa/get_qa_list", data);
}

export function get_category_list(data) {
  return axiosClient.post("api/v1/qa/get_category_list", data);
}

export function add_qa(data) {
  return axiosClient.post("api/v1/qa/add_qa", data);
}

export function get_answer_list(data) {
  return axiosClient.post("api/v1/qa/get_comment_list", data);
}

export function add_answers(data) {
  return axiosClient.post("api/v1/qa/add_comments", data);
}

export function give_qa_like_unlike(data) {
  console.log("data: ", data);
  return axiosClient.post("api/v1/qa/give_like_unlike", data);
}

export function courses_list(data) {
  return axiosClient.post("api/v1/colleges/courses_list", data);
}

export function home_jobs_list(data) {
  return axiosClient.post("api/v1/job/home_jobs_list", data);
}

export function undo_post_retweet(data) {
  return axiosClient.post("api/v1/post/undo_post_retweet", data);
}

export function delete_comment(data) {
  return axiosClient.post("api/v1/post/delete_comment", data);
}

export function remove_follow(data) {
  return axiosClient.post("api/v1/friend/remove_follow", data);
}

export function block_unblock_friend(data) {
  return axiosClient.post("api/v1/friend/block_unblock_friend", data);
}

export function remove_friend(data) {
  return axiosClient.post("api/v1/friend/remove_friend", data);
}

export function mutual_friend_list(data) {
  return axiosClient.post("api/v1/friend/mutual_friend_list", data);
}

export function my_document_list(data) {
  return axiosClient.post("api/v1/post/my_document", data);
}

export function public_document(data) {
  return axiosClient.post("api/v1/post/public_document", data);
}

export function accept_reject_request(data) {
  return axiosClient.post("api/v1/friend/accept_reject_request", data);
}

// Exam APIs

export function get_home_college_exam(data) {
  return axiosClient.post("api/v1/colleges/get_home_college_exam", data);
}

export function get_home_course(data) {
  return axiosClient.post("api/v1/colleges/get_home_course", data);
}

export function get_home_exam(data) {
  return axiosClient.post("api/v1/exam/get_home_exam", data);
}

export function get_stream_exam_list(data) {
  return axiosClient.post("api/v1/exam/get_stream_exam_list", data);
}

export function stream_exam_details(data) {
  return axiosClient.post("api/v1/exam/stream_exam_details", data);
}

export function entrace_exam(data) {
  return axiosClient.post("api/v1/exam/entrace_exam", data);
}

// Job APIs

export function search_job(data) {
  return axiosClient.post("api/v1/job/search_job", data);
}

export function home_state_jobs(data) {
  return axiosClient.post("api/v1/job/home_state_jobs", data);
}

export function qualification_list(data) {
  return axiosClient.post("api/v1/job/qualification_list", data);
}

export function designations_list(data) {
  return axiosClient.post("api/v1/job/designations_list", data);
}

export function random_job_data(data) {
  return axiosClient.post("api/v1/job/web_random_designations_list", data);
}

export function detail_type_jobs(data) {
  return axiosClient.post("api/v1/job/detail_type_jobs", data);
}

export function single_job_detail(data) {
  return axiosClient.post("api/v1/job/single_job_detail", data);
}

export function job_details(data) {
  return axiosClient.post("api/v1/job/job_details", data);
}

// Notification API

export function notification_list(data) {
  return axiosClient.post("api/v1/notification/notification_list", data);
}

export function count_notification(data) {
  return axiosClient.post("api/v1/notification/count_notification", data);
}

export function faq_list(data) {
  return axiosClient.post("api/v1/user/faq_list", data);
}

export function group_list(data) {
  return axiosClient.post("api/v1/chat/group_list", data);
}

export function apply_now(data) {
  return axiosClient.post("api/v1/user/apply_now", data);
}

export function type_wise_post(data) {
  return axiosClient.post("api/v1/post/type_wise_post", data);
}

export function get_user_reel_list(data) {
  return axiosClient.post("api/v1/post/get_user_reel_list", data);
}

export function friend_suggestion_list(data) {
  return axiosClient.post("api/v1/friend/friend_suggestion_list", data);
}

export function get_home_trending_college(data) {
  return axiosClient.post("api/v1/colleges/get_home_trending_college", data);
}

export function get_qa_category_list(data) {
  return axiosClient?.post("api/v1/qa/get_category_list", data);
}

export function top_contribution(data) {
  return axiosClient?.post("api/v1/qa/top_contribution", data);
}

export function profile_colleges(data) {
  return axiosClient?.post("api/v1/user/profile_colleges", data);
}

export function notification_setting_list(data) {
  return axiosClient?.post("api/v1/user/get_setting_notification_list", data);
}

export function other_setting_list(data) {
  return axiosClient?.post("api/v1/user/other_setting_list", data);
}

export function update_other_setting(data) {
  return axiosClient?.post("api/v1/user/update_other_setting", data);
}

export function update_notification_setting(data) {
  return axiosClient?.post("api/v1/user/update_notification_setting", data);
}

// Stream APIs
export function get_stream_list(data) {
  console.log("data: ", data);
  return axiosClient?.post("api/v1/colleges/get_stream_list", data);
}

export function get_course_wise_degree_list(data) {
  return axiosClient.post("api/v1/colleges/get_course_wise_degree_list", data);
}

// Search APIs
export function main_search(data) {
  return axiosClient.post("api/v1/search/search", data);
}

export function main_details_search(data) {
  console.log("data: api/v1/search/search_result", data);
  return axiosClient.post("api/v1/search/search_result", data);
}

export function auto_suggestion(data) {
  return axiosClient.post("/api/v1/search/auto_suggestion", data);
}

export function not_interested(data) {
  return axiosClient.post("/api/v1/post/not_interested", data);
}

export function share_link(data) {
  return axiosClient.post("/api/v1/post/share_link", data);
}

export function share_post(data) {
  return axiosClient.post("/api/v1/post/share_post", data);
}

export function report_user(data) {
  return axiosClient.post("/api/v1/user/report_user", data);
}

export function get_privacy_setting_list(data) {
  return axiosClient.post("/api/v1/user/get_privacy_setting_list", data);
}

export function update_privacy_setting(data) {
  return axiosClient.post("/api/v1/user/update_privacy_setting", data);
}

export function report_post(data) {
  return axiosClient.post("/api/v1/post/report_post", data);
}

export function save_post(data) {
  return axiosClient.post("/api/v1/post/save_post", data);
}

export function add_muted_restricted_account(data) {
  return axiosClient.post("/api/v1/user/add_muted_restricted_account", data);
}

export function remove_muted_restricted_account(data) {
  return axiosClient.post("/api/v1/user/remove_muted_restricted_account", data);
}

export function report_story(data) {
  return axiosClient.post("/api/v1/post/report_story", data);
}
