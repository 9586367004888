import React from 'react';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { changepassword, logout } from '../../api/apiHandler';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import HeaderProfile from '../HeaderProfile';
import Header from '../Header';
import { useNavigate } from 'react-router-dom';

export default function ChangePassword() {
    const validationSchema = yup.object().shape({
        old_password: yup.string().required("Please enter current passsword"),
        new_password: yup.string().required("Please enter new password").matches("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$", "Password must contain at least eight characters, one capital letter, one small letter,one number and one special character").min(8),
        confirm_password: yup.string().required("Please enter confirm new password").oneOf([yup.ref("new_password"), null], "Password mismatch"),
    });
    const changePasswordOptions = { resolver: yupResolver(validationSchema) };
    const { register, formState, reset, handleSubmit } = useForm(changePasswordOptions);
    const { errors } = formState;
    const navigate = useNavigate();
    // Change password API
    const onSubmit = (data) => {
        const newData = { new_password: data.new_password, old_password: data.old_password }
        changepassword(newData).then((response)=>{
            // console.log("Line 123", response);
            if(response.code === 0 || response.code === "0"){
                toast.error(response.message, {
                    toastId:"passwordError",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }else if(response.code === 1 || response.code === "1"){
                toast.success(response.message, {
                    toastId:"passwordSuccess",
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,                    
                    progress: undefined,
                    theme: "colored",
                });
                setTimeout(()=>{
                    navigate("/");
                    reset();
                }, 3000)
            }   
        })
        
    }
    
    return (
        <>
            {/* {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile /> : <Header />} */}
            <div className="edit_text">
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                /> 
                <h4 className="font_25 font_600">Change Password</h4>
                <form className="row justify-content-center" onSubmit={handleSubmit(onSubmit)}>
                    <div className="col-12 col-xl-10 cl-lg-10 col-md-10 col-sm-12 mt-5">
                        <div className="edit_input mobile_number_input">
                            <h6 className="font_18 font_500">Current Password</h6> <span style={{ color: "red" }}>{errors.old_password?.message}</span>
                            <div className="input-group">
                                <input type="password" className="form-control" {...register("old_password")} required="" />
                                <label htmlFor="password">Current Password</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-10 cl-lg-10 col-md-10 col-sm-12 mt-2">
                        <div className="edit_input mobile_number_input">
                            <h6 className="font_18 font_500">New Password</h6> <span style={{ color: "red" }}>{errors.new_password?.message}</span>
                            <div className="input-group">
                                <input type="password" className="form-control" {...register("new_password")} required="" />
                                <label htmlFor="password">New Password</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 col-xl-10 cl-lg-10 col-md-10 col-sm-12 mt-2">
                        <div className="edit_input 
                        mobile_number_input">
                            <h6 className="font_18 font_500">Confirm New Password</h6> <span style={{ color: "red" }}>{errors.confirm_password?.message}</span>
                            <div className="input-group">
                                <input type="password" className="form-control" {...register("confirm_password")} required="" />
                                <label htmlFor="password">New Password</label>
                            </div>
                        </div>
                    </div>
                    <div className="mt-66">
                        <div className="row justify-content-center">
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6">
                                <button type="button" className="edit_cancel blue_apply_bg orange_btn font_14 inter font_600">
                                    Cancel
                                </button>
                            </div>
                            <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6 mt-5 mt-sm-0">
                                <button type="submit"
                                    className="blue_apply_bg orange_btn white_ff font_14 inter">
                                    Update Password
                                </button>
                            </div>
                        </div>
                    </div>
                </form>

            </div>
        </>
    )
}
