import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import moment from "moment";
import {
  get_college_list,
  get_course_wise_degree_list,
} from "../../api/apiHandler";
import ReactTimeAgo from "react-time-ago";
import UpdatedColleges from "../courses/UpdatedColleges";

export default function CourseDetails() {
  const location = useLocation();
  const navigate = useNavigate();
  const [isCollegeList, setIsCollegeList] = useState(null);
  const [isDegreeDiplomaList, setIsDegreeDiplomaList] = useState(null);
  const [isDegreeBachelorsList, setIsDegreeBachelorsList] = useState(null);
  const [isDegreeMastersList, setIsDegreeMastersList] = useState(null);
  const [isDegreePHDList, setIsDegreePHDList] = useState(null);

  useEffect(() => {
    getCollegeData();
    getDegreeCourseList();
  }, []);

  const getCollegeData = () => {
    get_college_list({}).then((response) => {
      if (response.code == 1) {
        setIsCollegeList(response.data.colleges_list_result);
      }
    });
  };

  const getDegreeCourseList = () => {
    get_course_wise_degree_list({
      stream_id: location.state?._id,
      type: "Diploma",
    }).then((response) => {
      if (response.code == 1) {
        setIsDegreeDiplomaList(response.data);
      } else {
        setIsDegreeDiplomaList("");
      }
    });

    get_course_wise_degree_list({
      stream_id: location.state?._id,
      type: "Bachelors",
    }).then((response) => {
      console.log('response: ', response);
      if (response.code == 1) {
        setIsDegreeBachelorsList(response.data);
      } else {
        setIsDegreeBachelorsList("");
      }
    });

    get_course_wise_degree_list({
      stream_id: location.state?._id,
      type: "Masters",
    }).then((response) => {
      if (response.code == 1) {
        setIsDegreeMastersList(response.data);
      } else {
        setIsDegreeMastersList("");
      }
    });

    get_course_wise_degree_list({
      stream_id: location.state?._id,
      type: "PHD",
    }).then((response) => {
      if (response.code == 1) {
        setIsDegreePHDList(response.data);
      } else {
        setIsDegreePHDList("");
      }
    });
  };

  return (
    <>
      <main class="stulink">
        {localStorage?.getItem("isLoggedIn") ||
          localStorage?.getItem("isSignedUp") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="one_college">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-2 col-lg-2 col-md-3 col-sm-12">
                <div className="college_logo d-flex">
                  <img
                    src={location?.state?.icon}
                    className="img-fluid m-auto"
                    alt="college-logo"
                  />
                </div>
              </div>
              <div className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12">
                <div className="college_short_details">
                  <h1 className="font_30 font_700">{location.state?.name}</h1>
                  {/* <p className="font_20 black_14">Joint Entrance Exam Main</p> */}
                  <p className="font_16 light_gray_8b d-flex align-items-center">
                    {/* <span className="green_online" /> Online */}
                  </p>
                  <p className="font_18 black_14">
                    Last Updated:{" "}
                    <span className="font_600">
                      {moment(location.state?.updated_at).format("DD MMM YYYY")}
                    </span>
                  </p>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                <div className="brochures_button">
                  <button
                    type="button"
                    className="blue_apply_bg orange_btn"
                    onClick={() =>
                      navigate(`/apply-now/courses/${location.state?._id}`, { state: { logo: location?.state?.icon, name: location.state?.name, path: '/course-details', pathState: location?.state } })
                    }
                  >
                    <img
                      src="assets/images/icons/apply_now.svg"
                      className="me-2 img-fluid"
                      alt="download"
                    />
                    <span className="white_ff font_16 font_600">Apply Now</span>
                  </button>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="college_tabs">
          <div className="college_nav">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul
                    className="college_nav_tab nav nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link active"
                        id="Overviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Overviews"
                        type="button"
                        role="tab"
                        aria-controls="Overviews"
                        aria-selected="true"
                      >
                        All
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="apply-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#apply"
                        type="button"
                        role="tab"
                        aria-controls="apply"
                        aria-selected="false"
                      >
                        Diploma
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="Syllabus-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Syllabus"
                        type="button"
                        role="tab"
                        aria-controls="Syllabus"
                        aria-selected="false"
                      >
                        Bachelors
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="mock-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#mock"
                        type="button"
                        role="tab"
                        aria-controls="mock"
                        aria-selected="false"
                      >
                        Masters
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="Registration-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Registration"
                        type="button"
                        role="tab"
                        aria-controls="Registration"
                        aria-selected="false"
                      >
                        PHD
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="college_nav_content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade active show"
                      id="Overviews"
                      role="tabpanel"
                      aria-labelledby="Overviews-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          {isDegreeDiplomaList != undefined &&
                            isDegreeDiplomaList != "" && (
                              <div className="information_box">
                                <div className="information_box_heading">
                                  <img
                                    src="assets/images/icons/jee-rank.svg"
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_25 mb-0">
                                    Diploma
                                  </h3>
                                </div>
                                <div className="row">
                                  {isDegreeDiplomaList != undefined &&
                                    isDegreeDiplomaList != "" ? (
                                    <>
                                      {isDegreeDiplomaList &&
                                        isDegreeDiplomaList.map((item, index) => {
                                          return (
                                            <div
                                              className="col-md-6 col-sm-12 col-12 mb-4"
                                              key={index}
                                            >
                                              <div className="info_shadow">
                                                <div className="icon_shadow">
                                                  <img
                                                    src={
                                                      item.icon != undefined &&
                                                        item.icon != ""
                                                        ? item.icon
                                                        : process.env.PUBLIC_URL +
                                                        "assets/video/c_icon.svg"
                                                    }
                                                  />
                                                </div>
                                                <div className="cource_text_1">
                                                  <h4>
                                                    {item.type} ({item.course.name})
                                                  </h4>
                                                  <span>
                                                    {item.duration} Years | Regular
                                                  </span>
                                                  <h6>
                                                    Criteria &amp; Eligibility
                                                  </h6>
                                                  <ul>
                                                    <li>
                                                      {item.criteria_eligibility}
                                                    </li>
                                                  </ul>
                                                  <div className="cource_btn">
                                                    <Link
                                                      to="/course-overview"
                                                      state={item}
                                                      className="btn btn-outline me-2"
                                                    >
                                                      Overview
                                                    </Link>
                                                    <Link
                                                      to="/colleges"
                                                      className="btn btn-outline"
                                                    >
                                                      College list
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ) : (
                                    <h4>
                                      Sorry, we don't have any courses for this
                                      Degree.
                                    </h4>
                                  )}
                                </div>
                              </div>
                            )
                          }
                          {isDegreeBachelorsList != undefined &&
                            isDegreeBachelorsList != "" && (
                              <div className="information_box">
                                <div className="information_box_heading">
                                  <img
                                    src="assets/images/icons/jee-rank.svg"
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_25 mb-0">
                                    Bachelors
                                  </h3>
                                </div>
                                <div className="row">
                                  {isDegreeBachelorsList != undefined &&
                                    isDegreeBachelorsList != "" ? (
                                    <>
                                      {isDegreeBachelorsList &&
                                        isDegreeBachelorsList.map((item, index) => {
                                          return (
                                            <div
                                              className="col-md-6 col-sm-12 col-12 mb-4"
                                              key={index}
                                            >
                                              <div className="info_shadow">
                                                <div className="icon_shadow">
                                                  <img
                                                    src={
                                                      item.icon != undefined &&
                                                        item.icon != ""
                                                        ? item.icon
                                                        : process.env.PUBLIC_URL +
                                                        "assets/video/c_icon.svg"
                                                    }
                                                  />
                                                </div>
                                                <div className="cource_text_1">
                                                  <h4>
                                                    {item.type} ({item.course.name})
                                                  </h4>
                                                  <span>
                                                    {item.duration} Years | Regular
                                                  </span>
                                                  <h6>
                                                    Criteria &amp; Eligibility
                                                  </h6>
                                                  <ul>
                                                    <li>
                                                      {item.criteria_eligibility}
                                                    </li>
                                                  </ul>
                                                  <div className="cource_btn">
                                                    <Link
                                                      to="/course-overview"
                                                      state={item}
                                                      className="btn btn-outline me-2"
                                                    >
                                                      Overview
                                                    </Link>
                                                    <Link
                                                      to="/colleges"
                                                      className="btn btn-outline"
                                                    >
                                                      College list
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ) : (
                                    <h4>
                                      Sorry, we don't have any courses for this
                                      Degree.
                                    </h4>
                                  )}
                                </div>
                              </div>
                            )
                          }
                          {isDegreeMastersList != undefined &&
                            isDegreeMastersList != "" && (
                              <div className="information_box">
                                <div className="information_box_heading">
                                  <img
                                    src="assets/images/icons/jee-rank.svg"
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_25 mb-0">
                                    Masters
                                  </h3>
                                </div>
                                <div className="row">
                                  {isDegreeMastersList != undefined &&
                                    isDegreeMastersList != "" ? (
                                    <>
                                      {isDegreeMastersList &&
                                        isDegreeMastersList.map((item, index) => {
                                          return (
                                            <div
                                              className="col-md-6 col-sm-12 col-12 mb-4"
                                              key={index}
                                            >
                                              <div className="info_shadow">
                                                <div className="icon_shadow">
                                                  <img
                                                    src={
                                                      item.icon != undefined &&
                                                        item.icon != ""
                                                        ? item.icon
                                                        : process.env.PUBLIC_URL +
                                                        "assets/video/c_icon.svg"
                                                    }
                                                  />
                                                </div>
                                                <div className="cource_text_1">
                                                  <h4>
                                                    {item.type} ({item.course.name})
                                                  </h4>
                                                  <span>
                                                    {item.duration} Years | Regular
                                                  </span>
                                                  <h6>
                                                    Criteria &amp; Eligibility
                                                  </h6>
                                                  <ul>
                                                    <li>
                                                      {item.criteria_eligibility}
                                                    </li>
                                                  </ul>
                                                  <div className="cource_btn">
                                                    <Link
                                                      to="/course-overview"
                                                      state={item}
                                                      className="btn btn-outline me-2"
                                                    >
                                                      Overview
                                                    </Link>
                                                    <Link
                                                      to="/colleges"
                                                      className="btn btn-outline"
                                                    >
                                                      College list
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ) : (
                                    <h4>
                                      Sorry, we don't have any courses for this
                                      Degree.
                                    </h4>
                                  )}
                                </div>
                              </div>
                            )
                          }
                          {isDegreePHDList != undefined &&
                            isDegreePHDList != "" && (
                              <div className="information_box">
                                <div className="information_box_heading">
                                  <img
                                    src="assets/images/icons/jee-rank.svg"
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_25 mb-0">
                                    PHD
                                  </h3>
                                </div>
                                <div className="row">
                                  {isDegreePHDList != undefined &&
                                    isDegreePHDList != "" ? (
                                    <>
                                      {isDegreePHDList &&
                                        isDegreePHDList.map((item, index) => {
                                          return (
                                            <div
                                              className="col-md-6 col-sm-12 col-12 mb-4"
                                              key={index}
                                            >
                                              <div className="info_shadow">
                                                <div className="icon_shadow">
                                                  <img
                                                    src={
                                                      item.icon != undefined &&
                                                        item.icon != ""
                                                        ? item.icon
                                                        : process.env.PUBLIC_URL +
                                                        "assets/video/c_icon.svg"
                                                    }
                                                  />
                                                </div>
                                                <div className="cource_text_1">
                                                  <h4>
                                                    {item.type} ({item.course.name})
                                                  </h4>
                                                  <span>
                                                    {item.duration} Years | Regular
                                                  </span>
                                                  <h6>
                                                    Criteria &amp; Eligibility
                                                  </h6>
                                                  <ul>
                                                    <li>
                                                      {item.criteria_eligibility}
                                                    </li>
                                                  </ul>
                                                  <div className="cource_btn">
                                                    <Link
                                                      to="/course-overview"
                                                      state={item}
                                                      className="btn btn-outline me-2"
                                                    >
                                                      Overview
                                                    </Link>
                                                    <Link
                                                      to="/colleges"
                                                      className="btn btn-outline"
                                                    >
                                                      College list
                                                    </Link>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </>
                                  ) : (
                                    <h4>
                                      Sorry, we don't have any courses for this
                                      Degree.
                                    </h4>
                                  )}
                                </div>
                              </div>
                            )
                          }
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="apply"
                      role="tabpanel"
                      aria-labelledby="apply-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <div className="information_box">
                            <div className="information_box_heading">
                              <img
                                src="assets/images/icons/jee-rank.svg"
                                className="img-fluid"
                                alt="icon"
                              />
                              <h3 className="blue_29 font_500 font_25 mb-0">
                                Diploma
                              </h3>
                            </div>
                            <div className="row">
                              {isDegreeDiplomaList != undefined &&
                                isDegreeDiplomaList != "" ? (
                                <>
                                  {isDegreeDiplomaList &&
                                    isDegreeDiplomaList.map((item, index) => {
                                      return (
                                        <div
                                          className="col-md-6 col-sm-12 col-12 mb-4"
                                          key={index}
                                        >
                                          <div className="info_shadow">
                                            <div className="icon_shadow">
                                              <img
                                                src={
                                                  item.icon != undefined &&
                                                    item.icon != ""
                                                    ? item.icon
                                                    : process.env.PUBLIC_URL +
                                                    "assets/video/c_icon.svg"
                                                }
                                              />
                                            </div>
                                            <div className="cource_text_1">
                                              <h4>
                                                {item.type} ({item.course.name})
                                              </h4>
                                              <span>
                                                {item.duration} Years | Regular
                                              </span>
                                              <h6>
                                                Criteria &amp; Eligibility
                                              </h6>
                                              <ul>
                                                <li>
                                                  {item.criteria_eligibility}
                                                </li>
                                              </ul>
                                              <div className="cource_btn">
                                                <Link
                                                  to="/course-overview"
                                                  state={item}
                                                  className="btn btn-outline me-2"
                                                >
                                                  Overview
                                                </Link>
                                                <Link
                                                  to="/colleges"
                                                  className="btn btn-outline"
                                                >
                                                  College list
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <h4>
                                  Sorry, we don't have any courses for this
                                  Degree.
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="Registration"
                      role="tabpanel"
                      aria-labelledby="Registration-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <div className="information_box">
                            <div className="information_box_heading">
                              <img
                                src="assets/images/icons/jee-rank.svg"
                                className="img-fluid"
                                alt="icon"
                              />
                              <h3 className="blue_29 font_500 font_25 mb-0">
                                PHD
                              </h3>
                            </div>
                            <div className="row">
                              {isDegreePHDList != undefined &&
                                isDegreePHDList != "" ? (
                                <>
                                  {isDegreePHDList &&
                                    isDegreePHDList.map((item, index) => {
                                      return (
                                        <div
                                          className="col-md-6 col-sm-12 col-12 mb-4"
                                          key={index}
                                        >
                                          <div className="info_shadow">
                                            <div className="icon_shadow">
                                              <img
                                                src={
                                                  item.icon != undefined &&
                                                    item.icon != ""
                                                    ? item.icon
                                                    : process.env.PUBLIC_URL +
                                                    "assets/video/c_icon.svg"
                                                }
                                              />
                                            </div>
                                            <div className="cource_text_1">
                                              <h4>
                                                {item.type} ({item.course.name})
                                              </h4>
                                              <span>
                                                {item.duration} Years | Regular
                                              </span>
                                              <h6>
                                                Criteria &amp; Eligibility
                                              </h6>
                                              <ul>
                                                <li>
                                                  {item.criteria_eligibility}
                                                </li>
                                              </ul>
                                              <div className="cource_btn">
                                                <Link
                                                  to="/course-overview"
                                                  state={item}
                                                  className="btn btn-outline me-2"
                                                >
                                                  Overview
                                                </Link>
                                                <Link
                                                  to="/colleges"
                                                  className="btn btn-outline"
                                                >
                                                  College list
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <h4>
                                  Sorry, we don't have any courses for this
                                  Degree.
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="Syllabus"
                      role="tabpanel"
                      aria-labelledby="Syllabus-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <div className="information_box">
                            <div className="information_box_heading">
                              <img
                                src="assets/images/icons/jee-rank.svg"
                                className="img-fluid"
                                alt="icon"
                              />
                              <h3 className="blue_29 font_500 font_25 mb-0">
                                Bachelors
                              </h3>
                            </div>
                            <div className="row">
                              {isDegreeBachelorsList != undefined &&
                                isDegreeBachelorsList != "" ? (
                                <>
                                  {isDegreeBachelorsList &&
                                    isDegreeBachelorsList.map((item, index) => {
                                      return (
                                        <div
                                          className="col-md-6 col-sm-12 col-12 mb-4"
                                          key={index}
                                        >
                                          <div className="info_shadow">
                                            <div className="icon_shadow">
                                              <img
                                                src={
                                                  item.icon != undefined &&
                                                    item.icon != ""
                                                    ? item.icon
                                                    : process.env.PUBLIC_URL +
                                                    "assets/video/c_icon.svg"
                                                }
                                              />
                                            </div>
                                            <div className="cource_text_1">
                                              <h4>
                                                {item.type} ({item.course.name})
                                              </h4>
                                              <span>
                                                {item.duration} Years | Regular
                                              </span>
                                              <h6>
                                                Criteria &amp; Eligibility
                                              </h6>
                                              <ul>
                                                <li>
                                                  {item.criteria_eligibility}
                                                </li>
                                              </ul>
                                              <div className="cource_btn d-flex">
                                                <Link
                                                  to="/course-overview"
                                                  state={item}
                                                  className="btn btn-outline me-2"
                                                >
                                                  Overview
                                                </Link>
                                                <Link
                                                  to="/colleges"
                                                  className="btn btn-outline"
                                                >
                                                  College list
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <h4>
                                  Sorry, we don't have any courses for this
                                  Degree.
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="mock"
                      role="tabpanel"
                      aria-labelledby="mock-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <div className="information_box">
                            <div className="information_box_heading">
                              <img
                                src="assets/images/icons/jee-rank.svg"
                                className="img-fluid"
                                alt="icon"
                              />
                              <h3 className="blue_29 font_500 font_25 mb-0">
                                Masters
                              </h3>
                            </div>
                            <div className="row">
                              {isDegreeMastersList != undefined &&
                                isDegreeMastersList != "" ? (
                                <>
                                  {isDegreeMastersList &&
                                    isDegreeMastersList.map((item, index) => {
                                      return (
                                        <div
                                          className="col-md-6 col-sm-12 col-12 mb-4"
                                          key={index}
                                        >
                                          <div className="info_shadow">
                                            <div className="icon_shadow">
                                              <img
                                                src={
                                                  item.icon != undefined &&
                                                    item.icon != ""
                                                    ? item.icon
                                                    : process.env.PUBLIC_URL +
                                                    "assets/video/c_icon.svg"
                                                }
                                              />
                                            </div>
                                            <div className="cource_text_1">
                                              <h4>
                                                {item.type} ({item.course.name})
                                              </h4>
                                              <span>
                                                {item.duration} Years | Regular
                                              </span>
                                              <h6>
                                                Criteria &amp; Eligibility
                                              </h6>
                                              <ul>
                                                <li>
                                                  {item.criteria_eligibility}
                                                </li>
                                              </ul>
                                              <div className="cource_btn">
                                                <Link
                                                  to="/course-overview"
                                                  state={item}
                                                  className="btn btn-outline me-2"
                                                >
                                                  Overview
                                                </Link>
                                                <Link
                                                  to="/colleges"
                                                  className="btn btn-outline"
                                                >
                                                  College list
                                                </Link>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      );
                                    })}
                                </>
                              ) : (
                                <h4>
                                  Sorry, we don't have any courses for this
                                  Degree.
                                </h4>
                              )}
                              {!isDegreeDiplomaList && !isDegreePHDList && !isDegreeBachelorsList && !isDegreeMastersList && (
                                <h4>
                                  Sorry, we don't have any courses for this
                                  Degree.
                                </h4>
                              )}
                            </div>
                          </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
