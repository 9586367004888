import React, { useEffect, useState, useRef, useCallback } from "react";
import InfiniteScroll from "react-infinite-scroll-component";
import { Link, useNavigate } from "react-router-dom";
import {
  home_post,
  give_like_unlike,
  post_details,
  add_comments,
  add_post,
  add_friend,
  accept_reject_request,
  remove_follow,
  undo_post_retweet,
  share_link,
} from "../../../api/apiHandler";
import Header from "../../Header";
import HeaderProfile from "../../HeaderProfile";
import ReactTimeAgo from "react-time-ago";
import EmojiPicker from "emoji-picker-react";
import {
  // ToastContainer,
  toast,
} from "react-toastify";
import S3 from "react-aws-s3";
// import { Tooltip as ReactTooltip } from "react-tooltip";
import { Tooltip } from "@material-ui/core";
import { showMessage } from "../../../common/RedirectPathManage";
import ShareLink from "../HomeFeed/components/ShareLink";
import ReelPlayer from "./components/ReelPlayer";
import { getTimeDifference } from "../HomeFeed/components/PostUserDetails";
import ReelAction from "./components/ReelAction";

export default function Reels({ interaction }) {
  const navigate = useNavigate();
  const [isReel, setIsReel] = useState([]);
  console.log("isReel:=======> ", isReel, isReel.length);
  const [isFetching, setIsFetching] = useState(false);
  const [pageToken, setPageToken] = useState(1);
  const [comment, setComment] = useState("");
  const [filesForComments, setFileForComments] = useState([]);
  const [selectedFileForComments, setSelectedFileForComments] = useState([]);
  const [heightWidthForComments, setHeightWidthForComments] = useState([]);
  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);
  // const [disableVideoInputForComments, setDisableVideoInputForComments] =
  //   useState(false);
  // const [disablePhotoInputForComments, setDisablePhotoInputForComments] =
  //   useState(false);
  const [thumbnailForComments, setThumbnailForComments] = useState("");
  const [singlePost, setSinglePost] = useState(false);
  const [preView, setPreview] = useState([]);
  const [media, setMedia] = useState([]);
  const [reelAction, setReelAction] = useState(false);
  const [quotePost, setQuotePost] = useState("");
  const [filesForRetweetQuote, setFileForRetweetQuote] = useState([]);
  const [selectedFileForRetweetQuote, setSelectedFileForRetweetQuote] =
    useState([]);
  const [heightWidthForQuotePost, setHeightWidthForQuotePost] = useState([]);
  const [emojiPickerForQuotePost, setEmojiPickerForQuotePost] = useState(false);
  const [disablePhotoInputForQuotePost, setDisablePhotoInputForQuotePost] =
    useState(false);
  const [disableVideoInputForQuotePost, setDisableVideoInputForQuotePost] =
    useState(false);
  const vidRef = useRef();
  const [thumbnailForQuotePost, setThumbnailForQuotePost] = useState("");
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });
  // const vidRef = useRef();
  var imageDimensions = [];
  var newPhotos = [];
  var newArr = [];

  const reelList = useCallback(() => {
    setIsFetching(true);
    home_post({ module_type: "reels", page_token: pageToken }).then((res) => {
      console.log("Reelllll res: ", res);
      if (res.code == 1) {
        setIsReel((prev) => {
          return [...prev, ...res.data];
        });
      } else if (res.code == 2) {
        setIsFetching(false);
      } else {
        setIsReel("");
        setIsFetching(false);
      }
    });
  }, [pageToken]);

  const addFriend = useCallback(
    (id) => {
      console.log(id, "friend_id");
      add_friend({ friend_id: id }).then((response) => {
        console.log("Data============>", response);
        if (response.code === 1 || response.code === "1") {
          showMessage(response.message);
          // reelList();
          setIsReel(
            isReel?.map((item) => {
              if (item?.user_id == response.data.friend_id) {
                item.has_friend = item?.account_type === "private" ? 0 : 1;
                item.action_id = response.data.action_id;
              }
              return item;
            })
          );
        }
      });
    },
    [isReel]
  );

  const actionFriend = (action, action_id, user_id) => {
    accept_reject_request({
      id: action_id,
      friend_id: user_id,
      type: action,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        showMessage(response.message);
        setIsReel(
          isReel?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = response.data.type === "reject" ? 3 : 1;
            }
            return item;
          })
        );
      }
    });
  };

  const RemoveFriend = (id) => {
    remove_follow({ friend_id: id }).then((res) => {
      if (res.code == 1) {
        showMessage(res.message);
        setIsReel(
          isReel?.map((item) => {
            if (item?.user_id == res.data.friend_id) {
              item.has_friend = 3;
            }
            return item;
          })
        );
      }
    });
  };

  const config = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
    dirName: "stulink/post/image",
  };

  const configVideo = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
    dirName: "stulink/post/video",
  };

  const configThumb = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
    dirName: "stulink/post/thumb",
  };

  const configComment = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
    dirName: "stulink/post/post_comment_media",
  };

  const configCommentThumb = {
    bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
    region: process.env.REACT_APP_S3_BUCKET_REGION,
    accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
    secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
    dirName: "stulink/post/post_comment_media/thumb",
  };

  const ReactS3Client = new S3(config);
  const ReactS3ClientVideo = new S3(configVideo);
  const ReactS3ClientComment = new S3(configComment);
  const ReactS3ClientThumb = new S3(configThumb);
  const ReactS3ClientCommentThumb = new S3(configCommentThumb);

  useEffect(() => {
    reelList();
  }, [reelList]);

  // Listng of Reels

  // Like and Unlike
  const likeUnlikeFunc = useCallback(
    (item) => {
      give_like_unlike({
        post_id: item._id,
        type: item.is_like == 1 ? "unlike" : "like",
        post_type: "reels",
        owner_user_id: item.user_id,
      }).then((resp) => {
        console.log("resp: --------->>>>>", resp);
        if (resp.code == 1) {
          setIsReel(
            isReel?.map((val) => {
              if (val._id == item._id) {
                val.likes = item.is_like ? val.likes - 1 : val.likes + 1;
                val.is_like = item.is_like ? 0 : 1;
              }
              return val;
            })
          );
        }
      });
    },
    [isReel]
  );

  // const playVideo = (id) => {
  //     console.log("------------------>>>id", id)

  //     setReelId(id == 0 ? "" : id - 1)
  //     var v = document.getElementById(`banner${id}`);
  //     v.play();
  //     // pauseVideo();
  // }

  // const pauseVideo = () => {
  //     if(reelId != ""){
  //         var p = document.getElementById(`banner${reelId}`);
  //         p.pause();
  //     }
  // }

  // Post Detail
  const postDetail = (postId) => {
    setSinglePost();
    post_details({
      post_id: postId,
    }).then((response) => {
      console.log("Line 113", response?.data);
      if (response.code === 1 || response.code === "1") {
        setSinglePost(response?.data);
      }
    });
  };

  const handleMouseEnter = (id) => {
    var v = document.getElementById(`banner${id}`);
    // console.log("::::::::::::Enter>>", v)
    v.play();
  };

  const handleMouseLeave = (id) => {
    var v = document.getElementById(`banner${id}`);
    // console.log("::::::::::::remove>>", id)
    v.pause();
  };

  const handleVideoUploadForComments = (event) => {
    // setDisablePhotoInputForComments(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;
        console.log("Line 440", videoElement.src);
        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForComments([
            ...heightWidthForComments,
            imageDimensions,
          ]);
          setSelectedFileForComments([...filesForComments, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            console.log("Blob created", blob);
            isImageValid(blob, (isValid) => {
              if (isValid) {
                console.log("Line 328: The image Blob is valid.");
              } else {
                console.log("Line 330: The image Blob is not valid.");
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
                console.log("Line 337: The image File is valid.");
              } else {
                console.log("Line 339: The image File is not valid.");
              }
            });
            setThumbnailForComments(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
          console.log("Line 302", thumbnailUrl);
        });
      };
      reader.readAsDataURL(videoFile);
      setFileForComments([...filesForComments, videoFile]);
    }
  };

  const removeImageForComments = (index) => {
    var x = [...filesForComments];
    var w = [...heightWidthForComments];
    console.log("above", x);
    x.splice(index, 1);
    w.splice(index, 1);
    console.log("below", x);
    setFileForComments(x);
    setSelectedFileForComments(x);
    setHeightWidthForComments(w);
    if (x?.length === 0 || w?.length === 0) {
      // setDisablePhotoInputForComments(false);
      // setDisableVideoInputForComments(false);
    }
  };

  const removeImageForQuotePost = (index) => {
    var x = [...filesForRetweetQuote];
    var w = [...heightWidthForQuotePost];

    x.splice(index, 1);
    w.splice(index, 1);

    setFileForRetweetQuote(x);
    setSelectedFileForRetweetQuote(x);
    setHeightWidthForQuotePost(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForQuotePost(false);
      setDisableVideoInputForQuotePost(false);
    }
  };

  const handleFileInputForRetweetQuotes = async (e) => {
    setDisableVideoInputForQuotePost(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForQuotePost([
          ...heightWidthForQuotePost,
          imageDimensions,
        ]);
      };
      render.readAsDataURL(file[0]);
    };
    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };

            imageDimensions.push(obj);

            // setWidth(imageDimensions?.width);
            setHeightWidthForQuotePost([
              ...heightWidthForQuotePost,
              imageDimensions,
            ]);
            setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
            setSelectedFileForRetweetQuote([
              ...filesForRetweetQuote,
              file[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);

        console.log("Line 219 ==========>", [
          ...filesForRetweetQuote,
          e.target.files[i],
        ]);
      }
    }
    console.log("==============>>>>>=======");
    setDisableVideoInputForQuotePost(true);
  };

  const handleVideoUploadForRetweetWithQuotes = (event) => {
    setDisablePhotoInputForQuotePost(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForQuotePost([
            ...heightWidthForQuotePost,
            imageDimensions,
          ]);
          setSelectedFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForQuotePost(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
    }
  };

  const retweetWithQuotes = (posts) => {
    console.log('posts: addddddddddddd', posts);
    add_post({
      description: quotePost,
      media: posts,
      post_type:
        (quotePost === "" || quotePost === undefined) && posts.length === 0
          ? "retweet"
          : "retweet_with_quote",
      shared_feed: singlePost,
      tag_users: [],
      hashtags: [],
      web_link: [],
      module_type: singlePost?.module_type,
    }).then((res) => {
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        // const updatedFeeds = feeds.map((post) => {
        //   if (post?._id === singlePost?._id) {
        //     return {
        //       ...post,
        //       is_retweet: 1,
        //       retweet_count: post?.retweet_count + 1,
        //     };
        //   }
        //   return post;
        // });
        // setFeeds(updatedFeeds);
        // myFeeds();
        setFileForRetweetQuote();
        setSelectedFileForRetweetQuote();
        setHeightWidthForQuotePost();
        setQuotePost("");

        setDisablePhotoInputForQuotePost(false);
        setDisableVideoInputForQuotePost(false);
      }
    });
  };

  console.log('selectedFileForRetweetQuote?.length: ', selectedFileForRetweetQuote);
  const uploadImagesForRetweetQuotes = async () => {
    if (selectedFileForRetweetQuote?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      console.log('selectedFileForRetweetQuote:====> ', selectedFileForRetweetQuote);
      for (var j = 0; j < selectedFileForRetweetQuote?.length; j++) {
        let upload;
        let uploadThumb;
        if (selectedFileForRetweetQuote[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFileForRetweetQuote[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForQuotePost
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFileForRetweetQuote[j]
          );
        }

        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForRetweetQuote[j]?.type?.split("/")[0],
          width: heightWidthForQuotePost[j][0]?.width,
          height: heightWidthForQuotePost[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        console.log('obj: ', obj);
        newArr.push(obj);
      }

      retweetWithQuotes(newArr);
    }
  };

  const handleShare = useCallback((data) => {
    share_link({
      id: data._id,
      module_type: data.module_type,
      description: data.description || "stulink",
    }).then((response) => {
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  const undoRetweet = useCallback(
    ({ _id }) => {
      undo_post_retweet({ id: _id }).then((response) => {
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const updatedFeeds = isReel.map((post) => {
            if (post?._id === _id) {
              return {
                ...post,
                is_retweet: 0,
                retweet_count: post?.retweet_count - 1,
              };
            }
            return post;
          });
          setIsReel(updatedFeeds);
        }
      });
    },
    [isReel]
  );

  function isImageValid(blobOrFile, callback) {
    const image = new Image();
    image.onload = function () {
      // Image dimensions can be checked to ensure it's a valid image
      if (image.width > 0 && image.height > 0) {
        callback(true); // It's a valid image
      } else {
        callback(false); // Invalid image (e.g., not an image or corrupted)
      }
    };
    image.onerror = function () {
      callback(false); // Error loading the image
    };

    // Set the src of the Image element with the Blob URL or Data URL
    const blobURL = URL.createObjectURL(blobOrFile);
    image.src = blobURL;
    console.log("Line 147", image.src);
  }

  // Add Comments
  const addComments = (postImages) => {
    if ((comment === "" || comment === undefined) && postImages.length === 0) {
      toast.info("Please enter some text before post or upload media", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      // var final_var = comment?.ltrim();
      var output_string = comment?.trim();
      console.log("Output Sting", output_string);
      add_comments({
        post_id: singlePost?._id,
        comments_type: "main",
        reply_comment_id: "",
        owner_user_id:
          singlePost?.post_type === "retweet"
            ? singlePost?.shared_feed?.user_id
            : singlePost?.user_id,
        media: postImages,
        hashtags: [],
        description: output_string,
      }).then((response) => {
        console.log("Line 258", response);
        if (response.code === 1 || response.code === "1") {
          toast.info(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const updatedFeeds = isReel?.map((post) => {
            if (post?._id === singlePost?._id) {
              return {
                ...post,
                comment_count: post?.comment_count + 1, // Increment comment count
              };
            }
            return post;
          });
          setIsReel(updatedFeeds);
          // myFeeds();
          setFileForComments([]);
          setComment("");
          setSelectedFileForComments();
          setHeightWidthForComments();
          setEmojiPickerForComments(false);
          // reelList();
        }
      });
    }
  };

  const uploadImagesForComments = async () => {
    if (selectedFileForComments?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForComments?.length; j++) {
        console.log("Line 123", selectedFileForComments[j]);
        let upload;
        let uploadThumb;
        if (selectedFileForComments[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForComments
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
        }
        console.log("Line 105", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForComments[j]?.type?.split("/")[0],
          width: heightWidthForComments[j][0]?.width,
          height: heightWidthForComments[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 107", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      addComments(newArr);
      console.log("Line 112", media, newArr);
    }
    setMedia(newArr);
  };

  // retweet
  const retweet = useCallback(
    ({ _id }) => {
      console.log("_id: ", _id);
      post_details({
        post_id: _id,
      }).then((response) => {
        console.log("response:++++653 ", response);
        if (response?.code === 1 || response?.code === "1") {
          add_post({
            description: response?.data?.description,
            media: [],
            retweet_id: response?.data?._id,
            module_type: response?.data?.module_type,
            post_type: "retweet",
            shared_feed: response?.data,
          }).then((res) => {
            if (res?.code === 1 || res?.code === "1") {
              toast.info(res?.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              const updatedFeeds = isReel.map((post) => {
                if (post?._id === _id) {
                  return {
                    ...post,
                    is_retweet: 1,
                    retweet_count: post?.retweet_count + 1,
                  };
                }
                return post;
              });
              setIsReel(updatedFeeds);
              // setPageToken(1);
              // myFeeds();
            }
          });
        }
      });
    },
    [isReel]
  );

  if (isReel == null) {
    return <></>;
  }
  return (
    <>
      <main class="stulink">
        <ShareLink
          shareModalOpen={shareModalOpen}
          setShareModalOpen={setShareModalOpen}
        />
        <ReelAction
          reelAction={reelAction}
          setReelAction={setReelAction}
          singlePost={singlePost}
          setSinglePost={setSinglePost}
          handleShare={handleShare}
          isReel={isReel}
          setIsReel={setIsReel}
        />
        {/* Comment Modal */}
        <div
          className="modal fade"
          id="commentModal"
          tabIndex={-1}
          aria-labelledby="comment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body position-relative">
                <button
                  type="button"
                  className="btn-close close_custom"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setComment("");
                    setFileForComments([]);
                    setSelectedFileForComments([]);
                    setHeightWidthForComments([]);
                  }}
                />
                <div className="comment_main">
                  <div className="user_comment">
                    <div className="comment_user_post">
                      <div className="main_comment_other border_0 comment_line">
                        <div className="other_user_comment">
                          <span>
                            <a href="#">
                              <img
                                src={singlePost?.profile_image}
                                className="inner_post"
                              />{" "}
                              {singlePost?.name}
                            </a>
                            @{singlePost?.username}
                            <p>
                              {singlePost?.insertdate ? (
                                <ReactTimeAgo
                                  date={singlePost?.insertdate}
                                  locale="en-US"
                                />
                              ) : (
                                <></>
                              )}
                            </p>
                          </span>
                        </div>
                        <div className="comment_image ms-5 mt-3 mb-3">
                          {singlePost?.post_type === "retweet" ? (
                            <>
                              <label>
                                {singlePost?.shared_feed?.description}
                              </label>
                              {singlePost?.shared_feed?.media?.length === 0 ? (
                                <></>
                              ) : (
                                <>
                                  {singlePost?.shared_feed?.media?.map(
                                    (item) => {
                                      return item?.name;
                                    }
                                  )}
                                </>
                              )}
                            </>
                          ) : singlePost?.post_type === "retweet_with_quote" ? (
                            <>
                              <label>{singlePost?.description}</label>

                              <div
                                className="tetweet_quote"
                                style={{ marginLeft: "0px" }}
                              >
                                <div className="post_person_mark">
                                  <Link to={"#"} className="post_person_name">
                                    <div className="post_person_image">
                                      <img
                                        src={
                                          singlePost?.shared_feed?.profile_image
                                        }
                                        className="img-fluid"
                                        alt="profile-pic"
                                      />
                                    </div>
                                    <div className="post_person_text">
                                      <h6 className="font_12 font_600 mb-0">
                                        {singlePost?.shared_feed?.name}
                                      </h6>
                                      <span className="light_gray_8b font_12 inter">
                                        @{singlePost?.shared_feed?.username}
                                      </span>
                                    </div>
                                  </Link>
                                  <div className="post_person_date">
                                    <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                      {singlePost?.shared_feed?.insertdate ? (
                                        <ReactTimeAgo
                                          date={
                                            singlePost?.shared_feed?.insertdate
                                          }
                                          locale="en-US"
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className="tetweet_quote_content">
                                  {singlePost?.shared_feed?.media?.length ===
                                  0 ? (
                                    <></>
                                  ) : singlePost?.shared_feed?.media[0]
                                      ?.type === "image" ? (
                                    <div className="tetweet_quote_image">
                                      <img
                                        src={
                                          singlePost?.shared_feed?.media[0]
                                            ?.name
                                        }
                                        alt="post"
                                      />
                                    </div>
                                  ) : (
                                    <div className="tetweet_quote_image">
                                      <img
                                        src={
                                          singlePost?.shared_feed?.media[0]
                                            ?.thumb
                                        }
                                        alt="post"
                                      />
                                    </div>
                                  )}
                                  {singlePost?.module_type === "news" ? (
                                    <div className="tetweet_quote_text">
                                      <p
                                        className="mb-0 gray_5a font_12"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            singlePost?.shared_feed
                                              ?.description,
                                        }}
                                      ></p>
                                    </div>
                                  ) : (
                                    <div className="tetweet_quote_text">
                                      <p className="mb-0 gray_5a font_12">
                                        {singlePost?.shared_feed?.description}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : singlePost?.module_type === "news" ? (
                            <label
                              dangerouslySetInnerHTML={{
                                __html: singlePost?.description,
                              }}
                            ></label>
                          ) : (
                            <label>{singlePost?.description}</label>
                          )}

                          <br />
                          {singlePost && singlePost?.media?.length === 0 ? (
                            <></>
                          ) : (
                            <label>
                              {singlePost?.media?.map((item) => {
                                return item?.name;
                              })}
                            </label>
                          )}
                          {/* <div className="commnt_replyr mt-2 mb-2">
                                                        <span className="text-blue_1">
                                                            <a href="#">Commenting to</a>{" "} @{singlePost?.username}
                                                        </span>
                                                    </div> */}
                        </div>
                        <div className="other_user_comment d-flex">
                          <span>
                            <a href="#">
                              <img
                                src={localStorage?.getItem("profile_image")}
                                className="inner_post"
                              />
                            </a>
                          </span>
                          <input
                            type="text"
                            name=""
                            value={comment}
                            onChange={(e) => {
                              setComment(e?.target?.value);
                              setEmojiPickerForComments(false);
                            }}
                            placeholder="Post your comment..!!"
                          />
                        </div>
                        {/* <div className="uploade_images">
                          {filesForComments?.length !== 0 ? (
                            filesForComments?.map((item, key) => {
                              switch (item.type.split("/")[0]) {
                                case "image": {
                                  console.log("Line 838", item);
                                  return (
                                    <div className="images_tab_1">
                                      <img src={URL.createObjectURL(item)} />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/cross.svg"
                                        }
                                        className="cross-icon"
                                        onClick={() =>
                                          removeImageForComments(key)
                                        }
                                      />
                                    </div>
                                  );
                                }
                                case "video": {
                                  return (
                                    <div className="images_tab_1">
                                      <video
                                        controls
                                        muted
                                        loop
                                        style={{ height: "200px" }}
                                      >
                                        <source
                                          src={URL.createObjectURL(item)}
                                        />
                                      </video>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/cross.svg"
                                        }
                                        className="cross-icon"
                                        onClick={() =>
                                          removeImageForComments(key)
                                        }
                                      />
                                    </div>
                                  );
                                }
                              }
                            })
                          ) : (
                            <></>
                          )}
                        </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <ul className="post_option ms-0">
                  {/* <li>
                    <label
                      style={
                        disablePhotoInputForComments === true ||
                        filesForComments?.length === 4 ||
                        selectedFileForComments?.length === 4 ||
                        heightWidthForComments?.length === 4
                          ? {}
                          : { cursor: "pointer" }
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept=".png, .jpeg, .jpg"
                        id="imageForPosts"
                        multiple
                        onChange={handleFileInputForComments}
                        disabled={
                          disablePhotoInputForComments === true ||
                          filesForComments?.length === 4 ||
                          selectedFileForComments?.length === 4 ||
                          heightWidthForComments?.length === 4
                        }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/gallery.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  </li>
                  <li>
                    <a className="mx-3">
                      <label
                        style={
                          disableVideoInputForComments === true ||
                          filesForComments?.length === 1 ||
                          selectedFileForComments?.length === 1 ||
                          heightWidthForComments?.length === 1
                            ? {}
                            : { cursor: "pointer" }
                        }
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          ref={vidRef}
                          accept=".mp4, .avi, .mov, .mkv, .wmv"
                          id="imageForPosts"
                          onChange={handleVideoUploadForComments}
                          disabled={
                            disableVideoInputForComments === true ||
                            filesForComments?.length === 1 ||
                            selectedFileForComments?.length === 1 ||
                            heightWidthForComments?.length === 1
                          }
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/video-icon.svg"
                          }
                          className="img-fluid"
                          alt="gallery"
                        />
                      </label>
                    </a>
                  </li> */}
                  {/* <li>
                                        <a href="javascript:void(0)">
                                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid"
                                                alt="gallery" />
                                        </a>
                                    </li> */}
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setEmojiPickerForComments(!emojiPickerForComments)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/emoji.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </a>
                  </li>
                </ul>
                <button
                  disabled={!comment && selectedFileForComments?.length < 0}
                  onClick={() => {
                    uploadImagesForComments();
                  }}
                  className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Comment
                </button>
              </div>
              {emojiPickerForComments ? (
                <div className="mx-5 mt-0 mb-2">
                  <EmojiPicker
                    onEmojiClick={(e) => setComment(comment + e?.emoji)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <div
          className="modal fade"
          id="retweetModal"
          tabIndex={-1}
          aria-labelledby="retweet"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body position-relative">
                <button
                  type="button"
                  className="btn-close close_custom"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setQuotePost("");
                    setFileForRetweetQuote([]);
                    setSelectedFileForRetweetQuote([]);
                    setHeightWidthForQuotePost([]);
                  }}
                />
                <div className="comment_main">
                  <div className="user_comment">
                    <div className="user_img_comment">
                      <a href="#">
                        <img src={localStorage?.getItem("profile_image")} />
                      </a>
                    </div>
                    <div className="comment_user_post">
                      <input
                        type="text"
                        name=""
                        placeholder="Add comment"
                        value={quotePost}
                        onChange={(e) => {
                          setQuotePost(e.target.value);
                          setEmojiPickerForQuotePost(false);
                        }}
                      />
                      <div className="uploade_images w-100">
                        {filesForRetweetQuote?.length !== 0 ? (
                          filesForRetweetQuote?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div
                                    className="images_tab_1 images_tab_custom"
                                    style={
                                      filesForRetweetQuote !== "" ||
                                      filesForRetweetQuote !== undefined ||
                                      filesForRetweetQuote !== null
                                        ? {}
                                        : { display: "none" }
                                    }
                                    key={key}
                                  >
                                    <img
                                      src={URL.createObjectURL(item)}
                                      style={{
                                        height: "100px",
                                        width: "100%",
                                        display:
                                          filesForRetweetQuote === ""
                                            ? "none"
                                            : "",
                                      }}
                                    />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForQuotePost(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    onMouseEnter={() => handleMouseEnter(key)}
                                    onMouseLeave={() => handleMouseLeave(key)}
                                    style={
                                      filesForRetweetQuote !== "" ||
                                      filesForRetweetQuote !== undefined ||
                                      filesForRetweetQuote !== null
                                        ? {}
                                        : { display: "none" }
                                    }
                                    key={key}
                                  >
                                    <video
                                      autoPlay={false}
                                      loop=""
                                      id={`banner${key}`}
                                      controls="false"
                                      controlslist="nodownload"
                                      style={{
                                        width: "120px",
                                        display:
                                          filesForRetweetQuote === ""
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    {/* <video
                                        controls
                                        muted
                                        loop
                                       style={{
                                          width: "120px",
                                          display:
                                            filesForRetweetQuote === ""
                                              ? "none"
                                              : "",
                                        }}
                                      >
                                        <source
                                          src={URL.createObjectURL(item)}
                                        />
                                      </video> */}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() => {
                                        removeImageForQuotePost(key);
                                      }}
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="main_comment_other">
                        <div className="other_user_comment">
                          <span>
                            <a href="#">
                              <img
                                src={singlePost?.profile_image}
                                className="inner_post"
                              />{" "}
                              {singlePost?.name}
                            </a>
                            @{singlePost?.username}{" "}
                            <p>{getTimeDifference(singlePost?.insertdate)}</p>
                          </span>
                          <br />
                          {/* <label>
                                                                {singlePost?.description}
                                                            </label> */}
                        </div>
                        <div className="tetweet_quote_content">
                          {singlePost?.media?.length > 0 ? (
                            singlePost?.media[0]?.type !== "image" ? (
                              <div className="tetweet_quote_image">
                                <img
                                  src={singlePost?.media[0]?.thumb}
                                  alt="post"
                                />
                              </div>
                            ) : (
                              <div className="tetweet_quote_image">
                                <img
                                  src={singlePost?.media[0]?.name}
                                  alt="post"
                                />
                              </div>
                            )
                          ) : (
                            <></>
                          )}
                          {singlePost?.module_type === "news" ? (
                            <div className="tetweet_quote_text">
                              <p
                                className="mb-0 gray_5a font_12"
                                dangerouslySetInnerHTML={{
                                  __html: singlePost?.description,
                                }}
                              ></p>
                            </div>
                          ) : (
                            <div className="tetweet_quote_text">
                              <p className="mb-0 gray_5a font_12">
                                {singlePost?.description}
                              </p>
                            </div>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div className="insert_images">
                  <label
                    style={
                      disablePhotoInputForQuotePost === true ||
                      filesForRetweetQuote?.length === 4 ||
                      selectedFileForRetweetQuote?.length === 4 ||
                      heightWidthForQuotePost === 4
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      multiple
                      accept=".png, .jpeg, .jpg"
                      onChange={handleFileInputForRetweetQuotes}
                      disabled={
                        disablePhotoInputForQuotePost === true ||
                        filesForRetweetQuote?.length === 4 ||
                        selectedFileForRetweetQuote?.length === 4 ||
                        heightWidthForQuotePost === 4
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/gallery.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                  <a className="mx-4">
                    <label
                      style={
                        disableVideoInputForQuotePost === true ||
                        filesForRetweetQuote?.length === 1 ||
                        selectedFileForRetweetQuote?.length === 1 ||
                        heightWidthForQuotePost === 1
                          ? {}
                          : { cursor: "pointer" }
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        ref={vidRef}
                        accept=".mp4, .avi, .mov, .mkv, .wmv"
                        id="imageForPosts"
                        onChange={handleVideoUploadForRetweetWithQuotes}
                        disabled={
                          disableVideoInputForQuotePost === true ||
                          filesForRetweetQuote?.length === 1 ||
                          selectedFileForRetweetQuote?.length === 1 ||
                          heightWidthForQuotePost === 1
                        }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/video-icon.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  </a>
                  {/* <a href="javascript:void(0)">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid mx-3"
                                                    alt="gallery" />
                                            </a> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmojiPickerForQuotePost(!emojiPickerForQuotePost)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid mx-3"
                      alt="gallery"
                    />
                  </a>
                </div>
                <button
                  onClick={() => {
                    uploadImagesForRetweetQuotes();
                  }}
                  style={{ cursor: "pointer" }}
                  className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Post
                </button>
              </div>
              {emojiPickerForQuotePost ? (
                <div className="mx-5 mt-0 mb-3">
                  <EmojiPicker
                    onEmojiClick={(e) => setQuotePost(quotePost + e?.emoji)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        {/* Main  */}
        {localStorage.getItem("isSignedUp") ||
        localStorage.getItem("isLoggedIn") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="home pt-5 pb-5">
          <div className="container">
            {isReel?.length > 0 && (
              <InfiniteScroll
                dataLength={isReel?.length}
                next={() => {
                  setPageToken(pageToken + 1);
                }}
                hasMore={isFetching}
              >
                {isReel?.map((item, index) => {
                  return (
                    <ReelPlayer
                      item={item}
                      index={index}
                      likeUnlikeFunc={likeUnlikeFunc}
                      postDetail={postDetail}
                      retweet={retweet}
                      undoRetweet={undoRetweet}
                      setSinglePost={setSinglePost}
                      setReelAction={setReelAction}
                      handleShare={handleShare}
                      actionFriend={actionFriend}
                      RemoveFriend={RemoveFriend}
                      addFriend={addFriend}
                      interaction={interaction}
                    />
                  );
                })}
              </InfiniteScroll>
            )}
            {/* <div className="row justify-content-center">
                            <div className="col-12 col-xl-5 col-lg-6 col-md-7 col-sm-10 mb-4">
                                <div className="post_reel">
                                <div className="reels">
                                    <div className="view_video">
                                    <video
                                        autoPlay="true"
                                        loop=""
                                        id="banner"
                                        controls="false"
                                        controlslist="nodownload"
                                    >
                                        <source src="assets/video/demo.mp4" type="video/mp4" />
                                    </video>
                                    </div>
                                    <div className="reels_option">
                                    <div className="reels_user">
                                        <div className="rel_desc">
                                        <div
                                            className="r_user"
                                            data-bs-toggle="modal"
                                            data-bs-target="#story_user"
                                        >
                                            <img src="assets/video/user_link.png" className="me-2" />
                                        </div>
                                        <div className="r_text">
                                            <h6 data-bs-toggle="modal" data-bs-target="#story_user">
                                            Jerome Bell <a href="#">@jorome123</a>
                                            </h6>
                                            <p className="r_song">
                                            <img src="assets/video/music.svg" />
                                            College Song
                                            </p>
                                        </div>
                                        </div>
                                        <p className="r_content">This is a great college</p>
                                    </div>
                                    <div className="reel_like_option">
                                        <ul>
                                        <li>
                                            <a href="#">
                                            <img
                                                src="assets/video/unfill_heart.svg"
                                                className="unfill"
                                            />
                                            <img src="assets/video/red_heart.svg" className="fill" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <img src="assets/video/comment.svg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <img src="assets/video/regresh.svg" />
                                            </a>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <img src="assets/video/plane.svg" />
                                            </a>
                                        </li>
                                        <li>
                                            <button className="reels_btn">
                                            <img src="assets/video/user.svg" className="me-2" />
                                            Link
                                            </button>
                                        </li>
                                        <li>
                                            <a href="#">
                                            <img src="assets/video/song.png" />
                                            </a>
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                </div>
                            </div>
                        </div> */}
          </div>
        </section>
      </main>
    </>
  );
}
