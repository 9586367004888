import React from "react";
import { useNavigate } from "react-router-dom";

const PostLikeComment = (props) => {
  const navigate = useNavigate();
  const {
    item,
    likeUnlike,
    retweet,
    setSinglePost,
    handleShare,
    undoRetweet,
    disableTags = true,
    handleSavePost,
    isComment = false,
    isDetailPage
  } = props;
  return (
    <div className="list_option">
      {!disableTags && (
        <div class="tag_list_here">
          <span>News</span>
          <span>College</span>
        </div>
      )}
      <ul>
        <li
          onClick={() => {
            likeUnlike(item);
          }}
        >
          <a>
            <img
              alt=""
              src={
                process.env.PUBLIC_URL +
                `/assets/images/${item?.is_like ? "red_heart.svg" : "heart.svg"
                }`
              }
            />
            {item?.like_count_show == "Y" && (
              item?.likes
            )}
          </a>
        </li>
        <li
          data-bs-target={`${isDetailPage == true ? '#commentModal' : ''}`}
          data-bs-toggle={`${isDetailPage == true ? 'modal' : ''}`}
          onClick={() => {
            setSinglePost(item);
            if (isDetailPage == false) {
              navigate('/post/' + item._id)
            }
          }}
          className="cursor-pointer"
        >
          <a>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/chat.svg"}
            />
            {item?.comment_count}
          </a>
        </li>
        <li
          // className="dropdown-toggle "
          role="button"
          id="dropdownMenuLink"
          data-bs-toggle="dropdown"
          aria-expanded="false"
        >
          <a>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/viral.svg"}
            />
            {item.retweet_count}
            <div className="dropdown-menu" aria-labelledby="dropdownMenuLink">
              <li
                onClick={() => {
                  if (item?.is_retweet === 0) {
                    retweet(item);
                  } else {
                    undoRetweet(item);
                  }
                }}
                style={{ cursor: "pointer" }}
              >
                <a className="dropdown-item">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/post-repost.svg"
                    }
                    alt={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/post-repost.svg"
                    }
                    className="me-2"
                  />
                  {item?.is_retweet === 0 ? "Viralled" : "Undo Viralled"}
                </a>
              </li>
              <li onClick={() => setSinglePost(item)}>
                <a
                  className="dropdown-item"
                  data-bs-target={
                    isComment ? "#retweetCommentModal" : "#retweetModal"
                  }
                  data-bs-toggle="modal"
                >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/video/pencil-simple-line.svg"
                    }
                    alt={
                      process.env.PUBLIC_URL +
                      "/assets/video/pencil-simple-line.svg"
                    }
                    className="me-2"
                  />
                  Viralled with Comments
                </a>
              </li>
            </div>
          </a>
        </li>
        <li
          onClick={() => {
            handleShare(item);
          }}
        >
          <a>
            <img
              alt=""
              src={process.env.PUBLIC_URL + "/assets/images/send-2.svg"}
            />
            {item?.share_count?.length}
          </a>
        </li>
        <li
          onClick={() => {
            handleSavePost(item);
          }}
        >
          {!isComment && (
            <a>
              <img
                alt=""
                src={
                  item?.is_saved == "N"
                    ? process.env.PUBLIC_URL + "/assets/images/save.svg"
                    : process.env.PUBLIC_URL + "/assets/images/save_black.svg"
                }
              />
            </a>
          )}
        </li>
      </ul>
    </div>
  );
};

export default PostLikeComment;
