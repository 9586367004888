import React from 'react'
import { Link } from "react-router-dom";
import { logout } from '../api/apiHandler';
import dayjs from 'dayjs';
var advancedFormat = require('dayjs/plugin/advancedFormat');

export default function Header() {
    dayjs.extend(advancedFormat)
    var date = new Date().toLocaleString("en-US", { day: '2-digit' });
    var month = new Date().toLocaleString("en-US", { month: "long" });
    var year = new Date().getFullYear();
    return (
        <>
            <header className="main_header">
                <div className="first_nav d-none d-md-block">
                    <div className="container">
                        <div className="first_nav_text d-flex justify-content-between align-items-center">
                            <p className="font_12 mb-0">DATE - {dayjs().format('Do', "MMMM", "YYYY")} {month}, {year}</p>
                            <div className="footer_links">
                                <Link to="/privacy-policy" className="font_12">Privacy Policy</Link>
                                <Link to="/terms-of-use" className="font_12">Terms and Conditions</Link>
                                <Link to={localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? "/support-us" : "/login"} className="font_12">Support Us</Link>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="main_nav">
                    <div className="middle_nav">
                        <div className="container">
                            <div className="row align-items-center">
                                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-4 col-4 ">
                                    <Link to="/" className="header_logo">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/stuLink-logo.png"} className="img-fluid" alt="logo" />
                                    </Link>
                                </div>
                                <div className="col-xl-6 col-lg-6 col-md-9 col-sm-8 col-8 ">
                                    <div className="header_input input-group">
                                        <input type="text" className="form-control" placeholder="SEARCH" aria-label="Username"
                                            aria-describedby="basic-addon1" />
                                        <Link className="input-group-text" id="basic-addon1" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/search" : "/login"}><img src="assets/images/icons/search.svg" className="img-fluid cursor-pointer" alt="search" /></Link>
                                    </div>
                                </div>

                                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                                    <div className="row">
                                        <div className="col-6 d-none d-lg-block">
                                            <Link to="/login" className="login_button"><span className="icon-login"></span>Log
                                                In</Link>
                                        </div>
                                        <div className="col-6 d-none d-lg-block">
                                            <Link to="/sign-up" className="login_button"><span className="icon-signup"></span>Sign
                                                Up</Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div className="last_nav">
                        <div className="container">
                            <nav className="navbar navbar-expand-lg">
                                <Link className="navbar-brand" to="/"><img src={process.env.PUBLIC_URL + "assets/images/icons/home.svg"}
                                    className="img-fluid" alt="home" /></Link>
                                <button className="navbar-toggler" type="button" data-bs-toggle="collapse"
                                    data-bs-target="#navbarSupportedContent" aria-controls="navbarSupportedContent"
                                    aria-expanded="false" aria-label="Toggle navigation">
                                    <span className="navbar-toggler-icon"></span>
                                </button>
                                <div className="collapse navbar-collapse" id="navbarSupportedContent">
                                    <ul className="navbar-nav ms-auto justify-content-around w-100">
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/reels" : "/login"}>Reels</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/college-info" : "/login"}>Colleges</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/exam" : "/login"}>Exam</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/courses" : "/login"}>Courses</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/jobs" : "/login"}>Jobs</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/news" : "/login"}>News</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/group-chat-model" : "/login"}>Chat Room</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to={localStorage.getItem("isSignedUp") || localStorage.getItem("isLoggedIn") ? "/members" : "/login"}>Members</Link>
                                        </li>
                                        <li className="nav-item">
                                            <Link className="nav-link white_ff font_16" to="/help-center">Help Center</Link>
                                        </li>
                                        <li className="nav-item d-lg-none">
                                            <Link to="/login" className="login_button"><span className="icon-login"></span>Log In</Link>
                                        </li>
                                        <li className="nav-item d-lg-none">
                                            <Link to="/sign-up" className="login_button"><span className="icon-signup"></span>Sign Up</Link>
                                        </li>
                                    </ul>
                                </div>
                            </nav>
                        </div>
                    </div>
                </div>
            </header>
        </>
    )
}
