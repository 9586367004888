import React from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { CometChat } from "@cometchat-pro/chat";
// import { CometChatUI } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/components";

export default function ChatModel() {
  const appID = process.env.REACT_APP_CHAT_APP_ID;
  const region = process.env.REACT_APP_CHAT_REGION;
  const appSetting = new CometChat.AppSettingsBuilder()
    .subscribePresenceForAllUsers()
    .setRegion(region)
    .build();

  CometChat.init(appID, appSetting).then(
    () => {
      console.log("success");
    },
    (error) => {
      console.log("error", error);
    }
  );

  //create user function
  const authKey = process.env.REACT_APP_CHAT_AUTH_KEY;
  const uid = localStorage?.getItem("_id");

  // const name = "Shikhar";

  // var user = new CometChat.User(uid);
  // user.setName(name);
  // CometChat.createUser(user, authKey).then(
  //     user => {
  //         console.log("user created",user)
  //     },
  //     error => {
  //         console.log("error",error)
  //     }
  // )

  // Login user function
  CometChat.login(uid, authKey).then(
    (user) => {
      console.log("login successfully", { user });
    },
    (error) => {
      console.log("login failed", { error });
    }
  );

  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
      localStorage?.getItem("isSignedup") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="one_college">
        {/* <h3 className='blue_29' style={{alignItems: "center"}}>Under Development right now</h3> */}
        <div style={{ width: "100%", height: "750px" ,marginTop:"-2%"}}>
          {/* <CometChatUI /> */}
        </div>
      </section>
    </>
  );
}
