import React, { useCallback, useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { useLocation, useNavigate } from "react-router-dom";

import {
  CometChatConversations,
  CometChatMessageTemplate,
  CometChatMessages,
  CometChatUIKit,
  CometChatUIKitConstants,
} from "@cometchat/chat-uikit-react";
import {
  ConversationsStyle,
  MessageComposerConfiguration,
  MessageHeaderConfiguration,
  MessageListConfiguration,
  ThreadedMessagesConfiguration,
} from "@cometchat/uikit-shared";
import { CometChat } from "@cometchat/chat-sdk-javascript";
import { ListItemStyle } from "@cometchat/uikit-elements";
import moment from "moment";

export default function ChatRoom() {
  const location = useLocation();
  const navigate = useNavigate();
  const [chatWithUser, setChatWithUser] = useState(null);
  const [templates, setTemplates] = useState([]);

  useEffect(() => {
    if (location?.state?.guid) {
      CometChat.getGroup(location?.state?.guid).then((group) => {
        setChatWithUser(group);
      });
    } else if (location?.state?.uid) {
      CometChat.getUser(location?.state?.uid).then((user) => {
        setChatWithUser(user);
      });
    }
  }, [location?.state?.guid, location?.state?.uid]);

  const CUSTOM_MESSAGE_TYPE = "custom";

  const getContentView = useCallback(
    (message) => {
      console.log("message:===> ", message);
      const media = JSON.parse(message.customData.story_media).media[0];
      console.log("media: ", media);
      let loggedInUser;

      CometChat.getLoggedInUser()
        .then((user) => {
          loggedInUser = user;
          console.log("User details:", loggedInUser);
        })
        .catch((error) => {
          // Handle any errors that occurred during the request
          console.error("Error getting logged-in user:", error);
        });

      // Target time
      const targetTime = moment(media?.insertdate);

      // Calculate 24 hours later
      const twentyFourHoursLater = targetTime.clone().add(24, "hours");

      // Get the current time
      const currentTime = moment();

      const isBetween = currentTime.isBetween(targetTime, twentyFourHoursLater);

      const handleStoryOpen = () => {
        navigate(
          `/stories/${JSON.parse(message.customData.story_media)._id}/${
            media._id
          }`,
          {
            state: "isDeleted",
          }
        );
      };

      return (
        // <div style={{ height: "100px", display: "flex" }}>

        <div
          key={message.id}
          style={{
            background: "transparent",
            display: "flex",
            flexDirection: "column",
            alignItems: "end",
          }}
        >
          <p
            style={{
              textAlign: "right",
              padding: "8px 12px",
              backgroundColor: "rgb(255,255,255)",
              color: "rgb(51, 153, 255)",
              fontSize: "12px",
              fontWeight: 400,
              maxWidth: "500px",
            }}
          >
            Replied to shot
          </p>

          {isBetween && (
            <div
              style={{
                width: "170px",
                height: "100%",
                display: "flex",
                background: "transparent",
                borderRadius: "8px",
                overflow: "hidden",
              }}
            >
              {media?.type == "image" ? (
                <img
                  className="w-100"
                  alt=""
                  src={media.name}
                  onClick={handleStoryOpen}
                />
              ) : (
                <img
                  className="w-100"
                  alt=""
                  src={media.thumb}
                  onClick={handleStoryOpen}
                />
              )}
            </div>
          )}
          {/* </div> */}
          <p
            style={{
              textAlign: "right",
              padding: "8px 12px",
              backgroundColor: "rgb(51, 153, 255)",
              color: "rgb(255,255,255)",
              fontSize: "14px",
              fontWeight: 400,
              maxWidth: "500px",
              borderRadius: "8px",
            }}
            onClick={handleStoryOpen}
          >
            {" "}
            {message.customData.text}
          </p>
        </div>
        // </div>
      );
    },
    [navigate]
  );

  useEffect(() => {
    let definedTemplates =
      CometChatUIKit?.getDataSource()?.getAllMessageTemplates();
    let customMessage = new CometChatMessageTemplate({
      type: CUSTOM_MESSAGE_TYPE,
      category: CometChatUIKitConstants.MessageCategory.custom,
      bubbleView: (message) => getContentView(message),
      contentView: (message) => getContentView(message),
    });
    definedTemplates?.push(customMessage);
    setTemplates(definedTemplates);
  }, [getContentView]);

  const getMessageRequestBuilder = (ee) => {
    let categories = CometChatUIKit?.getDataSource()?.getAllMessageCategories();
    categories?.push(CometChatUIKitConstants?.MessageCategory?.custom);

    let types = CometChatUIKit?.getDataSource()?.getAllMessageTypes();
    types?.push(CUSTOM_MESSAGE_TYPE);
    return chatWithUser?.uid
      ? new CometChat.MessagesRequestBuilder()
          .setCategories(categories)
          .setTypes(types)
          .setUID(chatWithUser.uid)
          .hideReplies(true)
          .setLimit(30)
      : chatWithUser?.guid
      ? new CometChat.MessagesRequestBuilder()
          .setCategories(categories)
          .setTypes(types)
          .setGUID(chatWithUser.guid)
          .hideReplies(true)
          .setLimit(30)
      : null;
  };

  const mlConfiguration = new MessageListConfiguration({
    templates: templates,
    onThreadRepliesClick: () => {},
    disableReceipt:true,
    messagesRequestBuilder: getMessageRequestBuilder(),
  });

  const mStyle = {
    width: "100%",
    height: "68vh",
    border: "1px solid #8f9491",
  };

  const conversationsStyle = new ConversationsStyle({
    // width: "500px",
    height: "68vh",
    border: "1px solid #8f9491",
  });

  const lStyle = new ListItemStyle({
    // background: "transparent",
    // border: "1px solid #8f9491",
  });

  const mhConfig = new MessageHeaderConfiguration({
    listItemStyle: lStyle,
  });

  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
      localStorage?.getItem("isSignedup") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="one_college">
        <div className="container">
          <div className="row">
            <div className="col-xxl-4 col-xl-4 col-lg-4 col-md-4 col-sm-6 col-12 p-0 ">
              <CometChatConversations
                title="Chats"
                // titleAlignment="center"
                // sentIcon="https://img.icons8.com/?size=24&id=44844&format=png"
                conversationsStyle={conversationsStyle}
                onItemClick={(conversation) => {
                  setChatWithUser(conversation.conversationWith);
                }}
              />
            </div>
            <div className="col-xxl-8 col-xl-8 col-lg-8 col-md-8 col-sm-6 col-12 p-0">
              {(chatWithUser?.uid || chatWithUser?.guid) && (
                <CometChatMessages
                  user={chatWithUser?.uid && chatWithUser}
                  group={chatWithUser?.guid && chatWithUser}
                  messagesStyle={mStyle}
                  messageListConfiguration={mlConfiguration}
                  messageHeaderConfiguration={mhConfig}
                  messageComposerConfiguration={
                    new MessageComposerConfiguration({ hideLiveReaction: true })
                  }
                />
              )}

              {!(chatWithUser?.uid || chatWithUser?.guid) && (
                <div
                  className="w-100 d-flex justify-content-center align-items-center "
                  style={{ height: "100%" }}
                >
                  <p className="text-secondary h4 fw-bold ">No Chat Selected</p>
                </div>
              )}
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
