import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { get_stream_list, filter_data } from "../../api/apiHandler";
import { Link, useNavigate } from "react-router-dom";

export default function Course() {
  const navigate = useNavigate();
  const [isCourseList, setIsCourseList] = useState(null);
  const [isCourseListData, setIsCourseListData] = useState(null);
  console.log('isCourseList: ', isCourseList);

  const [isCount, setIsCount] = useState(0);
  const [isState, setIsState] = useState("");
  const [categoryData, setCategoryData] = useState("");
  const [SortBy, setSortBy] = useState("L");
  const [getList, setList] = useState("");
  // const []
  useEffect(() => {
    StremList();
    GetFilterValues();
  }, []);

  const GetFilterValues = () => {
    filter_data({}).then((res) => {
      if (res.code == 1) {
        setList(res.data.course_list_result);
      }
      console.log(res, "Filter Res");
    });
  };

  const StremList = (s_data, f_data) => {
    let params;
    if (s_data != "" && s_data != undefined) {
      params = {
        search_course: s_data,
      };
    } else if (f_data != "" && f_data != undefined) {
      params = {
        page_token: "1",
        short_by: f_data,
      };
    } else {
      params = {
        page_token: "1",
        short_by: 'L'
      };
    }

    console.log('params: ', params);
    get_stream_list(params).then((response) => {
      if (response.code == 1) {
        setIsCourseList(response.data.colleges_list_result);
        setIsCourseListData(response.data);
      } else {
        setIsCourseList("");
        setIsCourseListData("");
      }
    });
  };

  const coursePageCount = () => {
    setIsCount(isCount + 1);
    get_stream_list({ page_token: isCount }).then((response) => {
      if (response.code == 1) {
        setIsCourseList(response.data.colleges_list_result);
        setIsCourseListData(response.data);
      } else {
        setIsCourseList("");
        setIsCourseListData("");
      }
    });
  };

  const HandleSubmit = () => {
    let request = {
      page_token: "1",
      short_by: SortBy
    }
    categoryData && (request.category_type = categoryData)
    isState && (request.search_course = isState)
    console.log('request: ', request);
    get_stream_list(request).then((response) => {
      if (response.code == 1) {
        setIsCourseList(response.data.colleges_list_result);

      } else {
        setIsCourseList("");
      }
    });
  }
  useEffect(() => {
    HandleSubmit();
  }, SortBy)
  if (isCourseList == null) {
    return <></>;
  }
  return (
    <>
      <main className="stulink">
        {localStorage?.getItem("isLoggedIn") ||
          localStorage?.getItem("isSignedUp") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="home_question_list section_1">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12">
                    <h1 className="main_section_title">
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/video/search.svg"
                        }
                        className="mr-2 me-2"
                      />
                      SEARCH OVER 10000 COURSE
                    </h1>
                  </div>
                  <div className="col-12">
                    <div className="white_section_1">
                      <div className="row">
                        <div className="col-md-6 col-sm-6 col-12 mb-3">
                          <div className="form-group">
                            <input
                              type="text"
                              name=""
                              className="grey_input"
                              placeholder="Enter Course"
                              value={isState}
                              onChange={(e) => setIsState(e.target.value)}
                            />
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12 mb-3">
                          <div className="form-group input_select">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/down.svg"
                              }
                            />
                            <select className="custom-select grey_input" onChange={(e) => setCategoryData(e.target.value)}>
                              <option value={""}>ALL Catagories</option>
                              <option value={"Diploma"}>Diploma</option>
                              <option value={"Bachelors"}>Bachelors</option>
                              <option value={"Masters"}>Masters</option>
                              <option value={"PHD"}>PHD</option>
                            </select>
                          </div>
                        </div>
                        <div className="col-md-3 col-sm-6 col-12 mb-3">
                          <div className="form-group input_select">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/down.svg"
                              }
                            />
                            <select
                              className="custom-select grey_input"
                              onChange={(e) => setIsState(e.target.value)}
                            >
                              <option value="">ALL COURSE</option>
                              {getList &&
                                getList.map((item, index) => {
                                  return (
                                    <option value={item?.course_name}>
                                      {item?.course_name}
                                    </option>
                                  );
                                })}
                            </select>
                          </div>
                        </div>
                        <div className="col-12">
                          <div className="form-group input_select text-center d-flex  justify-content-center  " >
                            <button className="btn btn-theme w-auto " onClick={() => HandleSubmit()}>
                              Search Course
                            </button>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {/* <section className="home_question_list section_3">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h1 className="main_section_title">Filter Course</h1>
                    <div className="row">
                      <Link
                        to="/job-list"
                        className="col-md-3 col-sm-6 col-12 mb-3"
                        
                      >
                        <div className="job_wise">
                         
                          <div className="box_icon mb-2">
                            <img src="assets/video/cap.svg" />
                          </div>
                          <div className="text-center">
                            <h6>DATA</h6>
                            <span>
                              
                              Vacancies
                            </span>
                            <p className="mt-4">
                              <img src="assets/video/map-pin.svg" />{" "}
                            
                              sdgfghjgsdf
                            </p>
                          </div>
                        </div>
                      </Link>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section> */}
        <section className="home_question_list section_cloud">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 text-center">
                    <h1 className="main_section_title">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/video/feature_cource.svg"
                        }
                        className="mr-2 me-2"
                      />
                      Feature Course
                    </h1>
                    <span>Currently Featured</span>
                  </div>
                  <div className="col-12 mb-5">
                    <div className="search_filter">
                      <div class="search_left">
                        <label>{isCourseList && isCourseList.length > 0 && (
                          `Showing ${isCourseListData?.college_count}-${isCourseListData?.college_count} of ${isCourseListData?.total_course} results`
                        )}
                        </label>
                      </div>
                      {/* <div className="search_left">
                        <label>Search</label>
                        <div className="search_bar">
                          <input
                            type="search"
                            name=""
                            placeholder="Search Course"
                            onChange={(e) => StremList(e.target.value)}
                          />
                          <button>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/search.svg"
                              }
                            />
                          </button>
                        </div>
                      </div> */}
                      <div className="search_right">
                        <label className="mr-2">SORT BY:</label>
                        <div className="filter_selection filter_r">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => {
                              setSortBy(e.target.value)
                            }}
                          >
                            {/* <option value="">Default Order</option> */}
                            <option value="L">Latest</option>
                            <option value="O">Oldest</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row">
                  {isCourseList != "" && isCourseList != undefined ? (
                    <>
                      {isCourseList &&
                        isCourseList.map((item, index) => {
                          return (
                            <div
                              className="col-lg-4 col-md-6 col-sm-12 col-12 mb-4"
                              key={index}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="card_cource">
                                <div className="icon_cource">
                                  <img src={item.icon} />
                                </div>
                                <div className="cource_text">
                                  <h4>{item.name}</h4>
                                  <div className="span_tag">
                                    {item.stream_course &&
                                      item.stream_course.map(
                                        (item1, index1) => {
                                          return (
                                            <span key={index1}>
                                              {item1.name}{" "}
                                            </span>
                                          );
                                        }
                                      )}
                                    {/* <span>Computer Science </span>
                                  <span>Mechanical </span>
                                  <span>Civil </span>
                                  <span>Electrical </span>
                                  <span>Aeronautical</span> */}
                                  </div>
                                  <p>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/video/new_collage.svg"
                                      }
                                    />
                                    20+ Colleges
                                  </p>
                                  <button
                                    className="btn btn_green"
                                    onClick={() =>
                                      navigate(
                                        { pathname: "/course-details" },
                                        { state: item }
                                      )
                                    }
                                  >
                                    All Courses{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/video/right_arrow_2.svg"
                                      }
                                    />
                                  </button>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                    </>
                  ) : (
                    <h4>Sorry, No data available!</h4>
                  )}

                  {isCourseList.length < 10 ? (
                    <></>
                  ) : (
                    <div
                      type="button"
                      className="col-12 text-center"
                      onClick={() => coursePageCount()}
                    >
                      <span className="text-theme">
                        See More{" "}
                        <img src="assets/video/caret.svg" className="ms-1" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
