import React, { useEffect, useState } from 'react'
import { all_pages } from '../api/apiHandler';
import HeaderProfile from './HeaderProfile';
import Header from './Header';

export default function TermsCondition() {
    const [tandc, setTandc] = useState();
    const termsAndCondition = ()=>{
        all_pages().then((response)=>{
            setTandc(response.data?.find(item=> item.page_name == "terms_and_conditions").contents);
        })
    }
    
    useEffect(()=>{
        termsAndCondition();
    })
    return (
        <>
            {/* {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile />: <Header />} */}
            <section className="terms_use">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12 mt-5">
                            <div className="information_box">
                                <div className="information_box_heading">
                                    <h3 className="blue_29 font_500 font_25 mb-0">Terms of Use</h3>
                                </div>
                                <div className="information_box_body" dangerouslySetInnerHTML={{__html: tandc}}>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
