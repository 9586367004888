/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { getTimeDifference } from "../../pages/HomeFeed/components/PostUserDetails";

const ReelComments = (props) => {
  const { item, likeUnlike, setSingleSubComment, deleteComment } = props;
  console.log("item: ", item);
  const navigate = useNavigate();

  const [openSubCommentId, setOpenSubCommentId] = useState([]);

  return (
    <>
      {item?.status == "Deleted" ? (
        <>
          <li>
            <div className="all_comment_listing">
              <div className="comment_user">
                <img
                  src={process.env.PUBLIC_URL + "/assets/images/user/image.png"}
                  alt={item?.profile_image}
                />
              </div>
              <div className="comment_text_maain">
                <div className="grey_section_comment">
                  <div className="user_time_name_option"></div>
                  <p>This content was deleted by the Content author</p>
                </div>
              </div>
            </div>
          </li>
        </>
      ) : (
        <li>
          <div className="all_comment_listing">
            <div className="comment_user">
              <Link to={"/profile"} state={item?.user_id}>
                <div className="comment_user">
                  <img src={item?.profile_image} alt={item?.profile_image} />
                </div>
              </Link>
            </div>
            <div className="w-100">
              <div className="comment_text_maain d-flex">
                <div className="grey_section_comment flex-grow-1 ">
                  <div>
                    <div className="user_time_name_option">
                      <h6>
                        <Link to={"/profile"} state={item?.user_id}>
                          {item?.name}
                        </Link>
                      </h6>
                      <span>
                        {item?.insertdate && (
                          <ReactTimeAgo
                            date={item?.insertdate}
                            locale="en-US"
                          />
                        )}
                      </span>
                    </div>

                    <p
                      onClick={() => {
                        navigate(`/comments`, {
                          state: { comment: item },
                        });
                      }}
                      className="text-break "
                    >
                      {item?.description}
                    </p>
                  </div>

                  <div className="like_dislike d-flex justify-content-start  ">
                    <a
                      className="cursor-pointer"
                      data-bs-target="#subCommentModal"
                      data-bs-toggle="modal"
                      onClick={() => setSingleSubComment(item)}
                    >
                      Reply
                    </a>
                    {item.user_id == localStorage.getItem("_id") && (
                      <p
                        className="text-danger cursor-pointer ms-2"
                        onClick={() => {
                          deleteComment(item._id);
                        }}
                      >
                        Delete
                      </p>
                    )}
                  </div>
                </div>

                <a className="like ">
                  <img
                    alt=""
                    onClick={() => {
                      console.log("item:======================>>>>");
                      likeUnlike(item);
                    }}
                    src={
                      item?.is_like
                        ? process.env.PUBLIC_URL + "/assets/video/unfill.svg"
                        : process.env.PUBLIC_URL +
                          "/assets/video/unfill_black_border.svg"
                    }
                    className="me-1"
                  />
                  {item?.likes}
                </a>
              </div>
              {item?.reply_data?.length > 0 && (
                <p>
                  {openSubCommentId.includes(item?.id) ? (
                    <a
                      class="p-0 cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${item?._id}_subcomment`}
                      aria-expanded="false"
                      aria-controls={`${item?._id}_subcomment`}
                      onClick={() => {
                        setOpenSubCommentId((prev) =>
                          prev.filter((x) => x != item.id)
                        );
                      }}
                    >
                      Hide replies ({item?.reply_data?.length})
                    </a>
                  ) : (
                    <a
                      class="p-0 cursor-pointer"
                      data-bs-toggle="collapse"
                      data-bs-target={`#${item?._id}_subcomment`}
                      aria-expanded="false"
                      aria-controls={`${item?._id}_subcomment`}
                      onClick={() => {
                        setOpenSubCommentId((prev) => [...prev, item?.id]);
                      }}
                    >
                      view replies ({item?.reply_data?.length})
                    </a>
                  )}
                </p>
              )}
              <div class="collapse mt-2" id={`${item?._id}_subcomment`}>
                {item?.reply_data?.map((val) => {
                  return (
                    <>
                      {val.status == "Deleted" ? (
                        <>
                          <li>
                            <div className="all_comment_listing">
                              <div className="comment_user">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/user/image.png"
                                  }
                                  alt={item?.profile_image}
                                />
                              </div>
                              <div className="comment_text_maain">
                                <div className="grey_section_comment">
                                  <div className="user_time_name_option"></div>
                                  <p>
                                    This content was deleted by the Content
                                    author
                                  </p>
                                </div>
                              </div>
                            </div>
                          </li>
                        </>
                      ) : (
                        <div className="all_comment_listing">
                          <div className="comment_user">
                            <Link to={"/profile"} state={val?.user_id}>
                              <div className="comment_user">
                                <img
                                  src={val?.profile_image}
                                  alt={val?.profile_image}
                                />
                              </div>
                            </Link>
                          </div>
                          <div className="comment_text_maain d-flex">
                            <div className="grey_section_comment flex-grow-1 ">
                              <div>
                                <div className="user_time_name_option">
                                  <h6>
                                    <Link to={"/profile"} state={val?.user_id}>
                                      {val?.name}
                                    </Link>
                                  </h6>
                                  <span>
                                    {val?.insertdate && (
                                      <ReactTimeAgo
                                        date={val?.insertdate}
                                        locale="en-US"
                                      />
                                    )}
                                  </span>
                                </div>

                                <p
                                  onClick={() => {
                                    navigate(`/comments`, {
                                      state: { comment: val },
                                    });
                                  }}
                                  className="text-break "
                                >
                                  {val?.description}
                                </p>
                                {val.user_id == localStorage.getItem("_id") && (
                                  <p
                                    className="text-danger cursor-pointer"
                                    onClick={() => {
                                      deleteComment(val._id, item._id);
                                    }}
                                  >
                                    Delete
                                  </p>
                                )}
                              </div>
                            </div>

                            <a className="like ">
                              <img
                                alt=""
                                onClick={() => {
                                  console.log("val:======================>>>>");
                                  likeUnlike(val, item._id);
                                }}
                                src={
                                  val?.is_like
                                    ? process.env.PUBLIC_URL +
                                      "/assets/video/unfill.svg"
                                    : process.env.PUBLIC_URL +
                                      "/assets/video/unfill_black_border.svg"
                                }
                                className="me-1"
                              />
                              {val?.likes}
                            </a>
                          </div>
                        </div>
                      )}
                    </>
                  );
                })}
              </div>
            </div>
          </div>
        </li>
      )}
    </>
  );
};

export default ReelComments;
