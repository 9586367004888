import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { useLocation, useNavigate } from "react-router-dom";
import { stream_exam_details } from "../../api/apiHandler";
import moment from "moment";
import UpdatedColleges from "../courses/UpdatedColleges";
import TrendingAdmission from "../TrendingAdmission";
import UpcommingExams from "./UpcomingExams";

export default function ExamDetails() {
  const navigate = useNavigate();
  const location = useLocation();

  const [detailedData, setDetailedData] = useState(null);

  useEffect(() => {
    stream_exam_details({ id: location.state }).then((response) => {
      if (response.code == 1) {
        console.log('response.data: stream', response.data);
        setDetailedData(response.data);
      }
    });
  }, [location.state]);

  if (detailedData == null) {
    return <></>;
  }
  return (
    <>
      {localStorage.getItem("isSignedUp") ||
        localStorage.getItem("isLoggedIn") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      {/* <section className="one_college">
                <h3 className='blue_29' style={{alignItems: "center"}}>Under Development right now</h3>
            </section> */}
      <section className="one_college">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-2 col-lg-2 col-md-3 col-sm-12 col-12">
              <div className="college_logo d-flex">
                <img
                  src={
                    detailedData.logo != undefined && detailedData.logo != ""
                      ? detailedData.logo
                      : process.env.PUBLIC_URL +
                      "/assets/images/icons/jee-main.svg"
                  }
                  //   style={{ width: "100%" }}
                  className="img-fluid m-auto"
                  alt="college-logo"
                />
              </div>
            </div>
            <div className="col-12 col-xl-7 col-lg-7 col-md-6 col-sm-12 col-12">
              <div className="college_short_details">
                <h1 className="font_30 font_700">{detailedData.name}</h1>
                {/* <p className="font_20 black_14">Joint Entrance Exam Main</p> */}
                {/* {detailedData.is_active == 1 ? 
                                        <p className="font_16 light_gray_8b d-flex align-items-center"> <span className="green_online"></span> Online</p>
                                    :
                                        <p className="font_16 light_gray_8b d-flex align-items-center"> <span className="red_offline"></span> Offline</p>
                                    } */}
                <p className="font_18 black_14">
                  Last Updated:{" "}
                  <span className="font_600">
                    {moment(detailedData.updated_at).format("DD-MM-YYYY")}
                  </span>
                </p>
              </div>
            </div>
            <div
              className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12 col-12"
              onClick={() => navigate(`/apply-now/exams/${detailedData._id}`, {
                state: {
                  logo: detailedData.logo != undefined && detailedData.logo != ""
                    ? detailedData.logo
                    : process.env.PUBLIC_URL +
                    "/assets/images/icons/jee-main.svg", name: detailedData?.name,
                  path: '/exam-details',
                  pathState: location?.state
                }
              })}
            >
              <div className="brochures_button">
                <button type="button" className="blue_apply_bg orange_btn" >
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/apply_now.svg"
                    }
                    className="me-2 img-fluid"
                    alt="download"
                  />
                  <span className="white_ff font_16 font_600">Apply Now</span>
                </button>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="college_tabs">
        <div className="college_nav">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="college_nav_tab nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link active"
                      id="Overviews-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Overviews"
                      type="button"
                      role="tab"
                      aria-controls="Overviews"
                      aria-selected="true"
                    >
                      Overviews
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="apply-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#apply"
                      type="button"
                      role="tab"
                      aria-controls="apply"
                      aria-selected="false"
                    >
                      How to apply
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="Registration-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Registration"
                      type="button"
                      role="tab"
                      aria-controls="Registration"
                      aria-selected="false"
                    >
                      Registration
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="Syllabus-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Syllabus"
                      type="button"
                      role="tab"
                      aria-controls="Syllabus"
                      aria-selected="false"
                    >
                      Syllabus
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="mock-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#mock"
                      type="button"
                      role="tab"
                      aria-controls="mock"
                      aria-selected="false"
                    >
                      Mock Test
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="Admit-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Admit"
                      type="button"
                      role="tab"
                      aria-controls="Admit"
                      aria-selected="false"
                    >
                      Admit Card
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="Predictor-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Predictor"
                      type="button"
                      role="tab"
                      aria-controls="Predictor"
                      aria-selected="false"
                    >
                      Rank Predictor
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="Cut-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#Cut"
                      type="button"
                      role="tab"
                      aria-controls="Cut"
                      aria-selected="false"
                    >
                      Cut off
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="college_nav_content">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                <div className="tab-content overflow-x-auto " id="myTabContent">
                  <div
                    className="tab-pane fade show active"
                    id="Overviews"
                    role="tabpanel"
                    aria-labelledby="Overviews-tab"
                  >
                    {detailedData.overview != undefined &&
                      detailedData.overview != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.overview,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="apply"
                    role="tabpanel"
                    aria-labelledby="apply-tab"
                  >
                    {detailedData.how_to_apply != undefined &&
                      detailedData.how_to_apply != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.how_to_apply,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Registration"
                    role="tabpanel"
                    aria-labelledby="Registration-tab"
                  >
                    {detailedData.registration != undefined &&
                      detailedData.registration != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.registration,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Syllabus"
                    role="tabpanel"
                    aria-labelledby="Syllabus-tab"
                  >
                    {detailedData.syllabus != undefined &&
                      detailedData.syllabus != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.syllabus,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="mock"
                    role="tabpanel"
                    aria-labelledby="mock-tab"
                  >
                    {detailedData.mock_test != undefined &&
                      detailedData.mock_test != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.mock_test,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Admit"
                    role="tabpanel"
                    aria-labelledby="Admit-tab"
                  >
                    {detailedData.admit_card != undefined &&
                      detailedData.admit_card != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.admit_card,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Predictor"
                    role="tabpanel"
                    aria-labelledby="Predictor-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <div className="rank_predictor">
                          <h6 className="rank_predictor_heading font_17 font_600">
                            JEE Mains{" "}
                            <span className="blue_29">Rank Predictor</span>
                          </h6>
                          <ul className="rank_predictor_ul list-unstyled">
                            <li className="active font_18 inter">
                              Rank Predictor
                            </li>
                            <li className="font_18 inter">
                              Steps to Calculate Your Estimated 2021 Rank
                            </li>
                            <li className="font_18 inter">
                              Registration Process
                            </li>
                            <li className="font_18 inter">Details Required</li>
                          </ul>
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/jee-rank.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              JEE Main 2021 Rank Predictor
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <form>
                              <div className="row">
                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                  <div className="jee_input">
                                    <label
                                      for="exampleInputEmail1"
                                      className="form-label inter font_15 black_14"
                                    >
                                      MCQ - Total (60 questions )
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control inter font_13"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter your rank"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                  <div className="jee_input">
                                    <label
                                      for="exampleInputEmail1"
                                      className="form-label inter font_15 black_14"
                                    >
                                      No of Correct Attempts
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control inter font_13"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter your rank"
                                    />
                                  </div>
                                </div>
                                <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                                  <div className="jee_input">
                                    <label
                                      for="exampleInputEmail1"
                                      className="form-label inter font_15 black_14"
                                    >
                                      Non MCQ - Total (15 questions )
                                    </label>
                                    <input
                                      type="text"
                                      className="form-control inter font_13"
                                      id="exampleInputEmail1"
                                      aria-describedby="emailHelp"
                                      placeholder="Enter your rank"
                                    />
                                  </div>
                                </div>
                                <div
                                  className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"
                                  onClick={() =>
                                    navigate(
                                      `/apply-now/exams/${detailedData._id}`,
                                      {
                                        state: {
                                          logo: '/assets/images/icons/jee-rank.svg',
                                          name: 'JEE Main 2021 Rank Predictor',
                                          path: '/exam-details',
                                          pathState: location?.state
                                        }
                                      }
                                    )
                                  }
                                >
                                  <div className="jee_input_btn">
                                    <button
                                      type="button"
                                      className="blue_apply_bg orange_btn"
                                    >
                                      <span className="white_ff font_16 font_600">
                                        Apply Now
                                      </span>
                                    </button>
                                  </div>
                                </div>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/calculate-step.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Steps to Calculate Your Estimated 2021 Rank
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <ul className="list-unstyled">
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src="assets/images/icons/curve-down-arrow.svg"
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  The first step is to get registered on the
                                  official website of CollegeDekho. Test takers
                                  can do this by providing details such as
                                  mobile number, email address, etc.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  To register, candidates must scroll up to the
                                  top right corner of the page and click on the
                                  'Sign Up' option.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  A new box will appear on the screen with
                                  credentials such as name, email address,
                                  mobile number, preferred stream and password,
                                  to fill in.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  After entering the details candidates must
                                  click on the 'Login' button. With this click,
                                  candidates will be permitted to access the
                                  rank predictor page.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  After successful registration, candidates will
                                  have to come back to rank predictor page to
                                  check their expected rank.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  Candidates must check for the Live status of
                                  the page before they start entering the asked
                                  details.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  After entering all the details, candidates
                                  will have to click on the 'Submit' button.
                                </span>
                              </li>
                              <li className="d-flex align-items-start mb-3">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/curve-down-arrow.svg"
                                  }
                                  className="img-fluid me-2"
                                  alt="icon"
                                />
                                <span className="inter font_15 black_14">
                                  The possible rank will then pop up on the
                                  screen. Candidates must note their rank for
                                  future references.
                                </span>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/instructions.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              JEE Main Rank Predictor Registration Process
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <p className="inter font_15 black_14">
                              To access the tool, candidates need to register on
                              Stulink.com by filling in all the relevant details
                              such as name, contact details, email address after
                              which they can use the rank predictor tool for JEE
                              Main.
                            </p>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/details.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Details Required for Rank Predictor
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <p className="inter font_15 black_14">
                              Once the registration process is complete,
                              candidates need to log in and go to the JEE Main
                              2021 Rank Predictor page. On the page, you will
                              see a box where you need to enter the total number
                              of attempts out of the total number of questions
                              as well as the total number of correct attempts.
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div
                    className="tab-pane fade"
                    id="Cut"
                    role="tabpanel"
                    aria-labelledby="Cut-tab"
                  >
                    {detailedData.cutoff != undefined &&
                      detailedData.cutoff != "" ? (
                      <div
                        className="ck-content"
                        dangerouslySetInnerHTML={{
                          __html: detailedData.cutoff,
                        }}
                      />
                    ) : (
                      "No data"
                    )}
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                <UpdatedColleges />
                <UpcommingExams />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
