import React, { useEffect, useState } from "react";
import { CallSettingsBuilder, CometChat } from "@cometchat/chat-sdk-javascript";
import {
  CometChatIncomingCall,
  CometChatOngoingCall,
} from "@cometchat/chat-uikit-react";
import { CometChatCalls } from "@cometchat/calls-sdk-javascript";
const Calling = () => {
  const [incomingCall, setIncomingCall] = useState();
  const [call, setCall] = useState();

  CometChat.addCallListener(
    localStorage?.getItem("_id"),
    new CometChat.CallListener({
      async onIncomingCallReceived(call) {
        setIncomingCall(call);
        console.log("Incoming call:", call);
        await CometChat.getLoggedInUser()
          .getAuthToken()
          .then((authToken) => {
            console.log("authToken: ", authToken);
            const sessionID = call?.sessionId;

            CometChatCalls.generateToken(sessionID, authToken).then(
              (res) => {
                console.log("Call token fetched: ", res.token);
              },
              (err) => {
                console.log("Generating call token failed with error: ", err);
              }
            );
          });
      },
      onOutgoingCallAccepted(call) {
        console.log("Outgoing call accepted:", call);
      },
      onOutgoingCallRejected(call) {
        console.log("Outgoing call rejected:", call);
      },
      onIncomingCallCancelled(call) {
        console.log("Incoming call calcelled:", call);
      },
      onCallEndedMessageReceived: (call) => {
        console.log("CallEnded Message:", call);
        // CometChatCalls.endSession();
      },
    })
  );

  const acceptTheCall = (sessionID) => {
    console.log("sessionID: ", sessionID);
    CometChat.acceptCall(sessionID).then(
      (call) => {
        console.log("Call accepted successfully:", call);
        CometChatCalls.startSession();
        setIncomingCall(call);
        setCall(call);
      },
      (error) => {
        console.log("Call acceptance failed with error", error);
      }
    );
  };

  useEffect(() => {
    CometChatCalls.addCallEventListener(localStorage?.getItem("_id"), {
      onUserJoined: (user) => {
        console.log("user joined:", user);
      },
      onUserLeft: (user) => {
        console.log("user left:", user);
      },
      onUserListUpdated: (userList) => {
        console.log("user list:", userList);
      },
      onCallEnded: () => {
        console.log("Call ended");
        CometChatCalls.endSession();
        setCall();
      },
      onCallEndButtonPressed: () => {
        console.log("End Call button pressed");
        CometChatCalls.endSession();
        setCall();
      },
      onError: (error) => {
        console.log("Call Error: ", error);
      },
      onAudioModesUpdated: (audioModes) => {
        console.log("audio modes:", audioModes);
      },
      onUserMuted: (event) => {
        console.log("user muted:", event);
      },
    });
    return () => CometChatCalls.removeCallEventListener("UNIQUE_ID");
  }, []);
  return (
    <div>
      {" "}
      {incomingCall?.status != "ongoing" && (
        <CometChatIncomingCall
          call={incomingCall}
          onAccept={(checkkkkkk) => {
            console.log("checkkkkkk: ", checkkkkkk);
            acceptTheCall(incomingCall?.sessionId);
          }}
        />
      )}
      {call ? (
        <div>
          <CometChatOngoingCall sessionID={call.getSessionId()} />
        </div>
      ) : null}
    </div>
  );
};

export default Calling;
