import React, { useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
import { Link } from "react-router-dom";
import { useTranslation } from "react-i18next";

export default function Footer() {
  var year = new Date().getFullYear();
  const { t, i18n } = useTranslation();
  const [language, setLanguage] = useState("en");
  const changeLanguageHandler = (e) => {
    var x = e.target.value;
    console.log("Changed Language", x);
    setLanguage(x);
    console.log("State changed language", language);
    // i18n.changeLanguage(x);
  };
  return (
    <HelmetProvider>
      <Helmet></Helmet>
      <footer className="footer">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer_features">
                <h6 className="footer_features_heading">best colleges</h6>
                <ul>
                  <li>
                    <Link to="#"> Request an offer</Link>
                  </li>
                  <li>
                    <Link to="#">How it works</Link>
                  </li>
                  <li>
                    <Link to="#">Pricing</Link>
                  </li>
                  <li>
                    <Link to="#">Reviews</Link>
                  </li>
                  <li>
                    <Link to="#">Stories</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer_features">
                <h6 className="footer_features_heading">OUR GROUPS</h6>
                <ul>
                  <li>
                    <Link to="#">Infoedge.in</Link>
                  </li>
                  <li>
                    <Link to="#">Naukri.com</Link>
                  </li>
                  <li>
                    <Link to="#">Firstnaukri.com</Link>
                  </li>
                  <li>
                    <Link to="#">{t("date")}</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer_features">
                <h6 className="footer_features_heading">About</h6>
                <ul>
                  <li>
                    <Link to="#">Company</Link>
                  </li>
                  <li>
                    <Link to="#">Career</Link>
                  </li>
                  <li>
                    <Link to="#">Press</Link>
                  </li>
                  <li>
                    <Link to="#">Contact</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="features_image footer_features">
                <h6 className="footer_features_heading">Download App</h6>
                <ul>
                  <li>
                    <Link
                      to={"https://apps.apple.com/in/app/stulink/id6470642364"}
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/app-store.svg"
                        }
                        className="app_store img-fluid"
                        alt="apple-store"
                      />
                    </Link>
                  </li>
                  <li>
                    <Link
                      to="https://play.google.com/store/apps/details?id=com.stulink.app"
                      target="_blank"
                      rel="noreferrer"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/play-store.svg"
                        }
                        className="app_store img-fluid"
                        alt="google-play"
                      />
                    </Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer_features">
                <h6 className="footer_features_heading">others</h6>
                <ul>
                  <li>
                    <Link to="#">FAQ</Link>
                  </li>
                  <li>
                    <Link to="#">Blog</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer_features">
                <h6 className="footer_features_heading">RESOURCES</h6>
                <ul>
                  <li>
                    <Link to="/">Guides</Link>
                  </li>
                  <li>
                    <Link to="/">Help Center</Link>
                  </li>
                  <li>
                    <Link to="/">Firstnaukri.com</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="footer_features">
                <h6 className="footer_features_heading">OFFERS</h6>
                <ul>
                  <li>
                    <Link to="/">Make an offer</Link>
                  </li>
                  <li>
                    <Link to="/">Request an offer</Link>
                  </li>
                </ul>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-6 col-sm-6">
              <div className="features_image footer_features">
                <h6 className="footer_features_heading">Social Media</h6>
                <ul className="social_apps d-flex">
                  <li>
                    <Link to="" className="twitter">
                      {" "}
                      <i className="fab fa-twitter" aria-hidden="true"></i>{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="" className="facebook">
                      {" "}
                      <i
                        className="fab fa-facebook"
                        aria-hidden="true"
                      ></i>{" "}
                    </Link>{" "}
                  </li>
                  <li>
                    <Link to="" className="linkedin">
                      {" "}
                      <i
                        className="fab fa-linkedin"
                        aria-hidden="true"
                      ></i>{" "}
                    </Link>{" "}
                  </li>
                </ul>
              </div>
            </div>
          </div>
          <div className="footer_line">
            <p className="mb-0 font_12 copyright_color">© {year} stulink</p>
            <div className="footer_links">
              <Link to="/terms-of-use" className="font_12">
                Terms of Service
              </Link>
              <Link to="/privacy-policy" className="font_12">
                Privacy Policy
              </Link>
              <Link to="#" className="font_12">
                Trust & Safety
              </Link>
            </div>
            <div className="languages">
              <span className="font_12 copyright_color">Language :</span>
              {/* <div className="dropdown">
                            <button className="btn dropdown-toggle font_14 font_600 copyright_color" type="button"
                                id="dropdownMenuButton1" data-bs-toggle="dropdown" aria-expanded="false">
                                EN
                            </button>
                            <option className="dropdown-menu" aria-labelledby="dropdownMenuButton1">
                                <select><Link className="dropdown-item font_14 font_400 copyright_color" to="#">English</Link></select>
                                <select><Link className="dropdown-item font_14 font_400 copyright_color" to="#">Hindi</Link></select>
                            </option>
                        </div> */}
              {/* <div> */}
              <select
                style={{
                  marginLeft: "10px",
                  border: "1px solid #E8E7E8",
                  fontSize: "14px !important",
                  borderColor: "none",
                  cursor: "pointer",
                  color: "#64626A",
                  padding: "5px",
                }}
                onChange={(e) => {
                  changeLanguageHandler(e);
                }}
                // value={language}
              >
                <option style={{ position: "absolute" }} value="en">
                  EN
                </option>
                <option style={{ position: "absolute" }} value="hi">
                  HI
                </option>
              </select>
              {/* </div> */}
              {/* <div class="mt-3" id="google_translate_element" > </div> */}
            </div>
          </div>
        </div>
      </footer>
    </HelmetProvider>
  );
}
