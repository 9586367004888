import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { RenderTextWithHashtags } from "../../HomeFeed/HomeFeed";

const ReelPlayer = (props) => {
  const navigate = useNavigate();
  const {
    index,
    item,
    likeUnlikeFunc,
    retweet,
    undoRetweet,
    setSinglePost,
    handleShare,
    actionFriend,
    RemoveFriend,
    addFriend,
    gridClass = "",
    interaction,
    setReelAction,
  } = props;
  const videoRef = useRef(null);
  // const [interaction, setInteraction] = useState(false);
  const [muted, setMuted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const hideImage = () => {
    setIsVisible(false);
  };

  const toggleMute = () => {
    setMuted(!muted);

    setIsVisible(true);

    if (videoRef.current) {
      videoRef.current.muted = !muted;
    }

    setTimeout(() => {
      hideImage();
    }, 2000);
  };

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Adjust the threshold as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current && entry.isIntersecting) {
          // Video is in view, autoplay
          videoRef.current.play();
        } else {
          // Video is not in view, pause
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }
  }, []);
  return (
    <div className="row justify-content-center w-100 ">
      <div
        className={
          gridClass.length
            ? gridClass
            : "col-12 col-xl-6 col-lg-7 col-md-7 col-sm-10 mb-4"
        }
        key={index}
      >
        <div
          className="post_reel"
          //   onMouseEnter={() => handleMouseEnter(index)}
          //   onMouseLeave={() => handleMouseLeave(index)}
        >
          {/* onMouseEnter={() => playVideo(index)} */}

          <div className="reels">
            <div
              className="view_video"
              onDoubleClick={() => navigate(`/post/${item._id}`)}
            >
              <video
                ref={videoRef}
                autoPlay={!interaction}
                muted={!interaction ? "muted" : ""}
                loop={true}
                id={`banner${index}`}
                controls={false}
                onClick={() => {
                  console.log("5466666666666666666666666666666");
                  toggleMute();
                }}
              >
                <source src={item.media[0].name} type="video/mp4" />
              </video>
              <img
                className={`reels_play_btn cursor-pointer ${
                  isVisible ? "" : "hide_mute_icon"
                }`}
                src={
                  muted
                    ? process.env.PUBLIC_URL +
                      "/assets/video/icons/volume_off.svg"
                    : process.env.PUBLIC_URL + "/assets/video/icons/volume.svg"
                }
                alt=""
              />
            </div>
            <div
              className="reels_option"
              onClick={(e) => {
                console.log("5466666666666666666666666666666");
                e.stopPropagation();
                toggleMute();
              }}
              onDoubleClick={(e) => {
                e.stopPropagation();
              }}
            >
              <div className="reels_user">
                <div
                  className="rel_desc"
                  style={{ cursor: "pointer" }}
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate("/profile", {
                      state: item?.user_id,
                    });
                  }}
                >
                  <div
                    className="r_user"
                    // data-bs-toggle="modal"
                    // data-bs-target="#story_user"
                  >
                    <img src={item.profile_image} className="me-2" />
                  </div>
                  <div className="r_text">
                    <Link
                      //   to={`/profile/${item.user_id}`}
                      to="/profile"
                      state={item._id}
                      className="ms-1"
                    >
                      <h6
                      // data-bs-toggle="modal"
                      // data-bs-target="#story_user"
                      >
                        {" "}
                        {item.name}
                        <Link
                          //   to={`/profile/${item.user_id}`}
                          to="/profile"
                          state={item._id}
                          className="ms-1"
                        >
                          @{item.username}
                        </Link>
                      </h6>
                    </Link>
                    <p className="r_song">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/video/music.svg"}
                      />{" "}
                      {item.music_name != "" && item.music_name != undefined
                        ? item.music_name
                        : ""}
                    </p>
                  </div>
                </div>
                {/* <Tooltip title={item?.description}>
                  <p className="r_content" id={`my-tooltip-${index}`}>
                    {item?.description
                      ? item?.description?.slice(0, 50) + "..."
                      : ""}
                  </p>

                </Tooltip> */}
                <p className="mt-2">
                  <RenderTextWithHashtags text={item?.description || ""} />
                </p>
              </div>
              <div className="reel_like_option">
                <ul style={{ minWidth: "112px" }}>
                  <li>
                    <a
                      type="button"
                      onClick={(e) => {
                        e.stopPropagation();
                        likeUnlikeFunc(item);
                      }}
                    >
                      {item.is_like == 0 ? (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/video/unfill_heart.svg"
                          }
                          className="unfill"
                        />
                      ) : (
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/video/red_heart.svg"
                          }
                        />
                      )}
                    </a>
                  </li>
                  <span className="text-white">
                    <b>{item.likes}</b>
                  </span>
                  <li>
                    <a
                      type="button"
                      onClick={async () => {
                        // postDetail(item?._id);
                        navigate(`/post/${item._id}`);
                      }}
                      // data-bs-target="#commentModal"
                      // data-bs-toggle="modal"
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL + "/assets/video/comment.svg"
                        }
                      />
                    </a>
                  </li>
                  <span className="text-white">
                    <b>{item.comment_count}</b>
                  </span>
                  <li
                    // className="dropdown-toggle "
                    role="button"
                    id="dropdownMenuLink"
                    data-bs-toggle="dropdown"
                    aria-expanded="false"
                    onClick={(e) => {
                      e.stopPropagation();
                    }}
                  >
                    <a>
                      <img
                        alt=""
                        src={
                          process.env.PUBLIC_URL + "/assets/video/regresh.svg"
                        }
                      />
                      {/* {item.retweet_count} */}
                      <div
                        className="dropdown-menu reel-repost-custom"
                        aria-labelledby="dropdownMenuLink"
                      >
                        <li
                          onClick={(e) => {
                            e.stopPropagation();
                            if (item?.is_retweet === 0) {
                              retweet(item);
                            } else {
                              undoRetweet(item);
                            }
                          }}
                          style={{ cursor: "pointer" }}
                        >
                          <a className="dropdown-item">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/post-repost.svg"
                              }
                              alt={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/post-repost.svg"
                              }
                              className="me-2"
                            />
                            {item?.is_retweet == 0
                              ? "Viralled"
                              : "Undo Viralled "}
                          </a>
                        </li>
                        <li onClick={() => setSinglePost(item)}>
                          <a
                            className="dropdown-item"
                            data-bs-target="#retweetModal"
                            data-bs-toggle="modal"
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/pencil-simple-line.svg"
                              }
                              alt={
                                process.env.PUBLIC_URL +
                                "/assets/video/pencil-simple-line.svg"
                              }
                              className="me-2"
                            />
                            Viralled with Comments
                          </a>
                        </li>
                      </div>
                    </a>
                  </li>
                  <span className="text-white">
                    <b>{item.retweet_count}</b>
                  </span>
                  <li
                    onClick={() => {
                      handleShare(item);
                    }}
                  >
                    <a type="button">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/video/plane.svg"}
                      />
                    </a>
                  </li>
                  <li
                    onClick={() => {
                      setSinglePost(item);
                      setReelAction(true);
                    }}
                  >
                    <a type="button">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/3-dots-horizontal.svg"
                        }
                        style={{
                          width: "21px",
                          filter:
                            "brightness(0) saturate(100%) invert(100%) sepia(100%) saturate(0%) hue-rotate(242deg) brightness(103%) contrast(102%)",
                        }}
                        alt=""
                      />
                    </a>
                  </li>
                  <li>
                    {localStorage?.getItem("_id") === item?.user_id ? (
                      <></>
                    ) : item?.has_friend === 0 ? (
                      <button
                        className="reels_btn"
                        onClick={() => {
                          actionFriend(
                            "reject",
                            item?.action_id,
                            item?.user_id
                          );
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/video/user.svg"
                          }
                          className="me-2"
                        />
                        Requested
                      </button>
                    ) : item?.has_friend === 1 ? (
                      <button
                        className="reels_btn"
                        onClick={() => {
                          RemoveFriend(item?.user_id);
                        }}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/video/user.svg"
                          }
                          className="me-2"
                        />
                        Linked
                      </button>
                    ) : item?.has_friend === 2 ? (
                      <button
                        className="reels_btn"
                        onClick={() => addFriend(item?.user_id)}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/video/user.svg"
                          }
                          className="me-2"
                        />
                        Link
                      </button>
                    ) : item?.has_friend === 3 ? (
                      <button
                        className="reels_btn"
                        onClick={() => addFriend(item?.user_id)}
                      >
                        <img
                          src={
                            process.env.PUBLIC_URL + "/assets/video/user.svg"
                          }
                          className="me-2"
                        />
                        Link
                      </button>
                    ) : (
                      <></>
                    )}
                    {/* <button className="reels_btn">
                                                                        <img src={process.env.PUBLIC_URL + "/assets/video/user.svg"} className="me-2" /> Link
                                                                    </button> */}
                  </li>
                  <li>
                    <a
                      className={
                        item.music_thumb != undefined && item.music_thumb != ""
                          ? "r_user1"
                          : ""
                      }
                    >
                      <img
                        src={
                          item.music_thumb != undefined &&
                          item.music_thumb != ""
                            ? item.music_thumb
                            : process.env.PUBLIC_URL +
                              "/assets/video/no-audio.svg"
                        }
                      />
                    </a>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default ReelPlayer;
