import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import InfiniteScroll from "react-infinite-scroll-component";
import { useMediaQuery } from "@mui/material";
import { useNavigate } from "react-router-dom";

const OtherLinkingIn = (props) => {
  const isLargeScreen = useMediaQuery("(min-width:800px)");
  const navigate = useNavigate();
  const customStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",

    height: "70vh",
    width: isLargeScreen ? "700px" : "90%",
    bgcolor: "background.paper",
    boxShadow: 24,
    p: 2,
  };

  const {
    linkingInModal,
    setLinkingInModal,
    othersLinkingInList,
    actionFriend,
    removeFollow,
    addFriend,
    setPageTokenOtherLinkingIn,
    isFetchingOtherLinkingIn,
  } = props;

  console.log("othersLinkingInList: ", othersLinkingInList);
  return (
    <>
      {/* <Modal
      open={linkingInModal}
      onClose={() => {
        setLinkingInModal(false);
      }}
    >
      <Box sx={customStyles}>
        <div id="scrollableDiv" style={{ overflow: "auto", height: "100%" }}>
          <InfiniteScroll
            dataLength={othersLinkingInList.length}
            next={() => {
              console.log("HEEEEEEEEEEEEEEEEEEEEEE++++++");
              setPageTokenOtherLinkingIn((prev) => prev + 1);
            }}
            loader={<h4>Loading...</h4>}
            hasMore={isFetchingOtherLinkingIn}
          >
            {othersLinkingInList?.map((item, key) => {
              return (
                <div
                  onClick={() => {}}
                  className="mb-3 me-2 chandi_admission align-items-center"
                  key={key}
                >
                  <div className="james_profile uni_profile_image me-4">
                    <img
                      src={item.profile_image}
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div>
                    <p className="mb-0">{item.name}</p>
                    <p className="mb-0 gray_5a">@{item.username}</p>
                  </div>
                  {localStorage.getItem("_id") === item?.user_id ? (
                    <></>
                  ) : (
                    <div
                      className="edit_profile_btn"
                      style={{ marginTop: "3px", cursor: "pointer" }}
                    >
                      <div
                        className="edit_p_button"
                        style={
                          item?.has_friend === 1
                            ? {
                                minWidth: "120px",
                                textAlign: "center",
                                border: "1px solid #29ABE2",
                                color: "#29ABE2",
                                backgroundColor: "#FFFFFF",
                              }
                            : { minWidth: "120px", textAlign: "center" }
                        }
                        onClick={() => {
                          item?.has_friend === 0
                            ? actionFriend(
                                "reject",
                                item?.action_id,
                                item?.user_id
                              )
                            : item?.has_friend === 1
                            ? removeFollow({ friend_id: item?.user_id })
                            : addFriend({ friend_id: item?.user_id });
                        }}
                      >
                        {item?.has_friend === 0
                          ? "Requested"
                          : item?.has_friend === 1
                          ? "Linked"
                          : item?.has_friend === 3
                          ? "Link"
                          : "Link"}
                      </div>
                    </div>
                  )}
                </div>
              );
            })}
          </InfiniteScroll>
        </div>
      </Box>
    </Modal> */}

      <div
        className="modal fade"
        id="other_friend_linkin"
        tabIndex={-1}
        aria-labelledby="other_friend_linkin"
        aria-hidden="true"
      >
        <div className="modal-dialog">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Linked In
              </h5>
              <button
                type="button"
                className="btn-close"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body">
              <ul className="friend_list_main" id="scrollableDiv">
                <InfiniteScroll
                  dataLength={othersLinkingInList?.length}
                  next={() => {
                    console.log("HEEEEEEEEEEEEEEEEEEEEEE");
                    setPageTokenOtherLinkingIn((prev) => prev + 1);
                    //   otherLinkingOutList();
                  }}
                  loader={<h4>Loading...</h4>}
                  hasMore={isFetchingOtherLinkingIn}
                  scrollableTarget="scrollableDiv"
                >
                  {othersLinkingInList.map((item, key) => {
                    return (
                      <>
                        {/* <div
                  className="mb-3 me-2 chandi_admission align-items-center"
                  key={key}
                >
                  <div className="james_profile uni_profile_image me-4">
                    <img
                      src={item?.profile_image}
                      className="img-fluid"
                      alt="image"
                    />
                  </div>
                  <div
                    style={{ cursor: "pointer" }}
                    onClick={() => {
                      setMyLinkingInModal(false);
                      // myProfile();
                      // navigate(`/profile/${item?.user_id}`);
                      // navigate("/profile", {
                      //   state: item?.user_id,
                      // });
                    }}
                  >
                    <p className="mb-0">{item?.name}</p>
                    <p className="mb-0 gray_5a">@{item?.username}</p>
                  </div>

                  <div
                    className="edit_profile_btn"
                    style={{ marginTop: "3px", cursor: "pointer" }}
                    onClick={() => {
                      removeFollower(item?.user_id);
                    }}
                  >
                    <div className="edit_p_button">Remove</div>
                  </div>
                </div> */}
                        <li style={{ cursor: 'pointer' }}>
                          <div className="friend_img"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              navigate('/profile', { state: item?.user_id });
                            }}>
                            <img src={item?.profile_image} alt="post" />
                          </div>
                          <div className="friend_text"
                            data-bs-dismiss="modal"
                            aria-label="Close"
                            onClick={() => {
                              navigate('/profile', { state: item?.user_id });
                            }}>
                            <h6>{item?.name}</h6>
                            <a>@{item?.username}</a>
                          </div>
                          <div
                            className="friend_button"
                            onClick={() => {
                              item?.has_friend === 0
                                ? actionFriend(
                                  "reject",
                                  item?.action_id,
                                  item?.user_id
                                )
                                : item?.has_friend === 1
                                  ? removeFollow({ friend_id: item?.user_id })
                                  : addFriend({ friend_id: item?.user_id });
                            }}
                          >
                            <a className="btn_linked ">
                              {" "}
                              {item?.has_friend === 0
                                ? "Requested"
                                : item?.has_friend === 1
                                  ? "Linked"
                                  : item?.has_friend === 3
                                    ? "Link"
                                    : "Link"}
                            </a>
                          </div>
                        </li>
                      </>
                    );
                  })}
                </InfiniteScroll>
                {/* <li>
                  <div className="friend_img">
                    <img
                      src="assets/images/page-image/post-image.png"
                      alt="post"
                    />
                  </div>
                  <div className="friend_text">
                    <h6>John Deo</h6>
                    <a href="#">@johndeo__</a>
                  </div>
                  <div className="friend_button">
                    <a href="#" className="btn_linked">
                      Linked
                    </a>
                  </div>
                </li>
                <li>
                  <div className="friend_img">
                    <img
                      src="assets/images/page-image/post-image.png"
                      alt="post"
                    />
                  </div>
                  <div className="friend_text">
                    <h6>John Deo</h6>
                    <a href="#">@johndeo__</a>
                  </div>
                  <div className="friend_button">
                    <a href="#" className="btn_link">
                      Link
                    </a>
                  </div>
                </li>
                <li>
                  <div className="friend_img">
                    <img
                      src="assets/images/page-image/post-image.png"
                      alt="post"
                    />
                  </div>
                  <div className="friend_text">
                    <h6>John Deo</h6>
                    <a href="#">@johndeo__</a>
                  </div>
                  <div className="friend_button">
                    <a href="#" className="btn_linked">
                      Linked
                    </a>
                  </div>
                </li>
                <li>
                  <div className="friend_img">
                    <img
                      src="assets/images/page-image/post-image.png"
                      alt="post"
                    />
                  </div>
                  <div className="friend_text">
                    <h6>John Deo</h6>
                    <a href="#">@johndeo__</a>
                  </div>
                  <div className="friend_button">
                    <a href="#" className="btn_link">
                      Link
                    </a>
                  </div>
                </li> */}
              </ul>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default OtherLinkingIn;
