import React, { useEffect, useState } from "react";
import HeaderProfile from "./HeaderProfile";
import Header from "./Header";
import { Link } from "react-router-dom";
import { notification_list, detail_type_jobs } from "../api/apiHandler";
import ReactTimeAgo from "react-time-ago";
import TrendingTopics from "../common/TrendingTopics";

export default function NotificationList() {
  const [notificationList, setNotificationList] = useState(null);
  const [isQualificationList, setIsQualificationList] = useState(null);

  useEffect(() => {
    notificationData();
    detailTypeJobData();
  }, []);
  console.log(notificationList, "notificationList");
  const notificationData = () => {
    notification_list({ page_token: "0" }).then((response) => {
      // console.log("{{{{{{{{{{{{{{{", response);
      if (response.code == 1) {
        setNotificationList(response.data.notification_list);
      } else {
        setNotificationList("");
      }
    });
  };

  const detailTypeJobData = () => {
    detail_type_jobs({}).then((response) => {
      if (response.code == 1) {
        setIsQualificationList(response.data[1].designation_wise_result);
      }
    });
  };

  if (notificationList == null) {
    return <></>;
  }
  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="main_notification">
        <div className="container">
          <h1 className="font_600 font_20 mb-0">
            {" "}
            <img
              src={
                process.env.PUBLIC_URL +
                "/assets/images/icons/notification-2.svg"
              }
              className="img-fluid"
              alt="notification"
            />{" "}
            Notifications
          </h1>
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
              {notificationList != "" && notificationList != undefined ? (
                <ul className="mathilda_ul mt-0">
                  {notificationList &&
                    notificationList?.map((item, index) => {
                      console.log("------------>>>", item);
                      return (
                        <li className="flex-column">
                          <div className="mathila_profile">
                            <div className="mathila_image me-2">
                              {/* <Link to={`/profile/${item.sender_id}`}> */}
                              <Link to="/profile" state={item.sender_id}>
                                <img
                                  src={item.profile_image}
                                  className="img-fluid"
                                  alt="user"
                                />
                              </Link>
                            </div>
                            <div className="mathila_text">
                              <p className="inter font_16 mb-0 font_300">
                                {/* <Link to={`/profile/${item.sender_id}`}> */}
                                <Link to="/profile" state={item.sender_id}>
                                  <span className="font_600">
                                    {item.message.split(" ")[0]}
                                  </span>
                                </Link>{" "}
                                {item.message.substr(
                                  item.message.indexOf(" ") + 1
                                )}
                              </p>
                              {/* {item.message.split(" ")[-1]} */}
                              {item.description != undefined &&
                              item.description != "" ? (
                                <>
                                  <br />
                                  <Link to={`/post/${item.action_id}`}>
                                    {item.description}
                                  </Link>
                                  <br />
                                  <br />
                                </>
                              ) : (
                                ""
                              )}
                              <span className="inter black_14 font_14 d-flex align-items-center">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/comment-blue-mathila.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="comment"
                                />
                                <ReactTimeAgo
                                  date={item.insertdate ? item.insertdate : ""}
                                  locale="en-US"
                                />
                              </span>
                              {/* {moment().diff(item.insertdate, 'days')} */}
                            </div>
                          </div>
                          <div className="mathila_dots1 cursor-pointer mt-2">
                            {item.post_image != undefined &&
                            item.post_image != "" ? (
                              <Link to={`/post/${item.action_id}`}>
                                <img
                                  src={item.post_image}
                                  className="img-fluid"
                                  alt={`img${index}`}
                                />
                              </Link>
                            ) : (
                              <></>
                            )}
                          </div>
                        </li>
                      );
                    })}
                </ul>
              ) : (
                <div className="mt-4">
                  <h5>Sorry, No Notifications available.</h5>
                </div>
              )}
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <div className="union_top">
                <div className="union_top_heading profile_trending">
                  <h2 className="font_20 font_600 text-center mb-2">
                    Trending <span className="blue_29">Topics</span>
                  </h2>
                </div>
                <TrendingTopics />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
