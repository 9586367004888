import React, { useState, useEffect, useCallback } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import {
  get_people_list,
  add_friend,
  remove_follow,
} from "../../api/apiHandler";
import { showMessage } from "../../common/RedirectPathManage";
import { useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";

export default function Members() {
  const [peopleList, setPeopleList] = useState([]);
  const [page_token, setPageToken] = useState(1);
  const [isFetching, setIsFetching] = useState(false);

  const [filter, setFilter] = useState({});

  const [filterValue, setFilterValue] = useState({
    search_friend: "",
    location: "",
    college_uni_name: "",
  });

  console.log("isFetching: ", isFetching);

  const navigate = useNavigate();

  const getPeople = useCallback((request) => {
    setIsFetching(true);
    console.log("request: ", request);
    get_people_list(request).then((response) => {
      if (response.code == 1) {
        if (request.page_token == 1) {
          setPeopleList(response.data.friend_list);
        } else {
          setPeopleList((prev) => [...prev, ...response.data.friend_list]);
        }
        // setIsFetching(false);
      } else {
        // setPeopleList("");
        setIsFetching(false);
      }
    });
  }, []);

  const addFrnd = (id) => {
    add_friend({ friend_id: id }).then((res) => {
      if (res.code == 1) {
        showMessage(res.message);
        getPeople({});
      }
    });
  };

  const RemoveFriend = (id) => {
    remove_follow({ friend_id: id }).then((res) => {
      if (res.code == 1) {
        showMessage(res.message);
        getPeople({});
      }
    });
  };

  const handleRedirectUserProfile = (id) => {
    navigate("/profile", {
      state: id,
    });
  };

  useEffect(() => {
    getPeople({ page_token: page_token, ...filter });
  }, [filter, getPeople, page_token]);

  const handleFilter = () => {
    setPageToken(1);
    setFilter(filterValue);
  };

  // if(peopleList == null){return <></>}
  return (
    <>
      <main class="stulink">
        {localStorage?.getItem("isLoggedIn") ||
        localStorage?.getItem("isSignedUp") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="one_college">
          <div className="container change_size">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <div className="message_title">
                  <h5>
                    <img
                      alt=""
                      src="assets/images/profile/chat.svg"
                      className="me-2"
                    />
                    Members
                  </h5>
                </div>
              </div>
            </div>
            <div className="row">
              <div className="col-12 mb-4">
                <div className="white_section_1 meber_search">
                  <div className="row">
                    <div className="col-md-4 col-sm-12 col-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          name=""
                          className="grey_input"
                          placeholder="Search Friends..."
                          onChange={(e) => {
                            setFilterValue((prev) => ({
                              ...prev,
                              search_friend: e.target.value,
                            }));
                          }}
                        />
                        <button>
                          <i className="fa fa-searc h main-search-icon" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          name=""
                          className="grey_input"
                          placeholder="Location"
                          onChange={(e) => {
                            setFilterValue((prev) => ({
                              ...prev,
                              location: e.target.value,
                            }));
                          }}
                        />
                        <button>
                          <i className="fa fa-map-marker" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="col-md-3 col-sm-6 col-12 mb-3">
                      <div className="form-group">
                        <input
                          type="text"
                          name=""
                          className="grey_input"
                          placeholder="College"
                          onChange={(e) => {
                            setFilterValue((prev) => ({
                              ...prev,
                              college_uni_name: e.target.value,
                            }));
                          }}
                        />
                        <button>
                          <i className="fa fa-building" aria-hidden="true" />
                        </button>
                      </div>
                    </div>
                    <div className="col-md-2 col-sm-6 col-12 mb-3">
                      <button
                        onClick={() => {
                          handleFilter();
                        }}
                        className="btn btn-theme w-auto"
                      >
                        Filter
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <InfiniteScroll
              dataLength={peopleList?.length}
              next={() => {
                setPageToken(page_token + 1);
              }}
              hasMore={isFetching}
              scrollableTarget="comment_list"
            >
              <div className="row w-100">
                {peopleList &&
                  peopleList.map((item, index) => {
                    return (
                      <div
                        className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mb-4"
                        key={index}
                      >
                        <div className="member_list_card h-100">
                          <div
                            className="member_img cursor-pointer"
                            onClick={() => {
                              handleRedirectUserProfile(item.user_id);
                            }}
                          >
                            <img src={item.profile_image} />
                          </div>
                          <div className="member_name_desc">
                            <h5
                              className="cursor-pointer"
                              onClick={() => {
                                handleRedirectUserProfile(item.user_id);
                              }}
                            >
                              {item.name}
                            </h5>
                            <span
                              className="cursor-pointer"
                              onClick={() => {
                                handleRedirectUserProfile(item.user_id);
                              }}
                            >
                              @{item.username}
                            </span>
                            <p className="location_text">
                              <i
                                className="fa fa-map-marker me-1"
                                aria-hidden="true"
                              />{" "}
                              {item?.location || "-"}
                            </p>
                            <p className="clg_name_member">
                              {<img src="assets/video/collage_icon.svg" />}
                              {item?.college_uni_name || "-"}
                            </p>
                            <ul>
                              {item?.mutual_list?.slice(0, 3)?.map((val) => (
                                <li>
                                  <img src={val?.profile_image} />
                                </li>
                              ))}

                              {/* <li>
                                <img src="assets/video/user_link.png" />
                              </li>
                              <li>
                                <img src="assets/video/user_link.png" />
                              </li>
                              <li>
                                <img src="assets/video/user_link.png" />
                              </li>
                              <li>
                                <img src="assets/video/user_link.png" />
                              </li>
                              <li>
                                <img src="assets/video/user_link.png" />
                              </li> */}

                              {item?.mutual_list?.length > 0 && (
                                <li>
                                  <span>
                                    {item?.mutual_list?.length > 4 &&
                                    item?.mutual_list?.length - 4
                                      ? item?.mutual_list?.length - 4
                                      : ""}
                                    Mutual Friends
                                  </span>
                                </li>
                              )}
                            </ul>
                            {item?.has_friend == 0 ? (
                              <span className="btn btn-outline">
                                <i
                                  className="fa fa-user me-2"
                                  aria-hidden="true"
                                />
                                Request
                              </span>
                            ) : item?.has_friend == 1 ? (
                              <span
                                className="btn btn-outline"
                                onClick={() => {
                                  RemoveFriend(item?.user_id);
                                }}
                              >
                                <i
                                  className="fa fa-user me-2"
                                  aria-hidden="true"
                                />
                                Linked
                              </span>
                            ) : item?.has_friend == 2 ? (
                              <span className="btn btn-outline">
                                <i
                                  className="fa fa-user me-2"
                                  aria-hidden="true"
                                />
                                Block
                              </span>
                            ) : (
                              <span
                                className="btn btn-outline"
                                onClick={() => addFrnd(item?.user_id)}
                              >
                                <i
                                  className="fa fa-user me-2"
                                  aria-hidden="true"
                                />
                                Link
                              </span>
                            )}
                          </div>
                        </div>
                      </div>
                    );
                  })}
              </div>
            </InfiniteScroll>
          </div>
        </section>
      </main>
    </>
  );
}
