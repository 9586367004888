import React, { useEffect, useState } from "react";
import { detail_type_jobs } from "../../api/apiHandler";

const LocationWiseJob = () => {
  const [isStateList, setIsStateList] = useState(null);

  useEffect(() => {
    detailTypeJobData();
  }, []);

  const detailTypeJobData = () => {
    detail_type_jobs({}).then((response) => {
      if (response.code == 1) {
        setIsStateList(response.data[0].job_state_cities_result);
      }
    });
  };

  return (
    <div className="col-12 mb-3">
      <div className="job_wise_detail">
        <div className="job_wise_list_1">
          <h4>
            <img src="assets/video/job.svg" />
            Location-wise Jobs
          </h4>
        </div>
        <div className="list_job_wise">
          <ul>
            {isStateList &&
              isStateList
                ?.filter((item) => item?.vacancies)
                .slice(0, 3)
                .map((item, index) => {
                  return (
                    <>
                      <li>
                        <div className="map_img">
                          <img
                            src={
                              process.env.PUBLIC_URL + "assets/video/map.svg"
                            }
                            alt=""
                          />
                        </div>
                        <div className="map_text">
                          <span>
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "assets/video/map-pin.svg"
                              }
                            />
                            {item.state}
                          </span>
                          <h6 style={{ marginLeft: "22%", width: "100%" }}>
                            {item.vacancies} Vacancies
                          </h6>
                        </div>
                      </li>
                    </>
                  );
                })}
          </ul>
        </div>
      </div>
    </div>
  );
};

export default LocationWiseJob;
