import React, { useState } from "react";
import { toast } from "react-toastify";
import { ReactS3Client, ReactS3ClientThumb } from "../HomeFeed";
import { add_post } from "../../../../api/apiHandler";
import EmojiPicker from "emoji-picker-react";

const UploadPost = (props) => {
  const { post, setPost, myFeeds, setPageToken } = props;
  var newArr = [];
  const [emojiPickerVisible, setEmojiPickerVisible] = useState(false);
  const [files, setFile] = useState([]);
  const [heightWidth, setHeightWidth] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  console.log("post", post, selectedFile);
  const [disablePhotoInput, setDisablePhotoInput] = useState(false);

  const handleEmoji = (e) => {
    setPost(post + e?.emoji);
  };
  const removeImage = (index) => {
    var x = [...files];
    var w = [...heightWidth];
    x.splice(index, 1);
    w.splice(index, 1);
    setFile(x);
    setSelectedFile(x);
    setHeightWidth(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInput(false);
    }
  };

  const handleFileInput = async (e) => {
    let file = e.target.files;

    if (
      Object.values(file)?.some((item) => item?.type.split("/")[0] != "image")
    ) {
      toast.error("Only Images are allowed", {
        toastId: "Upload error message",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false
    } else {
      const newImageDimensions = [];

      const loadImage = (file) => {
        return new Promise((resolve) => {
          const render = new FileReader();
          render.onloadend = () => {
            const image = new Image();
            image.src = render.result;
            image.onload = () => {
              const obj = {
                height: image.height,
                width: image.width,
                file: file,
              };
              newImageDimensions.push(obj);
              resolve(obj);
            };
          };
          render.readAsDataURL(file);
        });
      };

      const processedFiles = await Promise.all(Array.from(file).map(loadImage));

      setHeightWidth([...heightWidth, ...processedFiles]);
      setSelectedFile([...files, ...processedFiles.map((obj) => obj.file)]);
      setFile([...files, ...file]);
    }
  };

  //Add post
  const addPost = (postImage) => {
    console.log("Line 276", postImage);

    console.log("Addd post Payload", {
      description: post,
      post_type: "normal",
      retweet_id: "",
      media: postImage,
      tag_users: [],
      hashtags: [],
      web_link: [],
      shared_feed: {},
      module_type: "post",
    });

    if ((post === "" || post === undefined) && postImage.length === 0) {
      toast.info("Please enter some text before post or upload media", {
        toastId: "postWarning",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      add_post({
        description: post,
        post_type: "normal",
        retweet_id: "",
        media: postImage,
        tag_users: [],
        hashtags: [],
        web_link: [],
        shared_feed: {},
        module_type: "post",
      }).then((response) => {
        console.log("Line 586", response);
        if (response?.code === 1 || response?.code === "1") {
          toast.info(response?.message, {
            toastId: "post-success",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPageToken(1);
          // myFeeds();
          setFile([]);
          setSelectedFile([]);
          setPost("");
          setEmojiPickerVisible(false);
          setDisablePhotoInput(false);
          newArr = [];
        }
      });
      console.log("615 Function end");
      return true;
    }
  };
  const uploadImages = async () => {
    if (selectedFile.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFile.length; j++) {
        let upload;

        upload = await ReactS3Client.uploadFile(selectedFile[j]);
        let uploadThumb = await ReactS3ClientThumb.uploadFile(selectedFile[j]);

        console.log("Line 354", selectedFile[j], heightWidth[j]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFile[j]?.type?.split("/")[0],
          width: heightWidth[j]?.width,
          height: heightWidth[j]?.height,
          thumb: uploadThumb.location.split("/")[6],
        };
        newArr.push(obj);
      }
      addPost(newArr);
    }
  };

  return (
    <div className="row">
      <div className="col-md-12">
        <div className="home_textarea d-flex align-items-center">
          <div className="home_textarea_real w-100 me-3">
            <textarea
              className="form-control"
              placeholder="What’s on your mind"
              id="floatingTextarea"
              value={post}
              onChange={(e) => {
                setPost(e.target.value);
                setEmojiPickerVisible(false);
              }}
            ></textarea>
            <div className="uploade_images">
              {files?.length !== 0 ? (
                files?.map((item, key) => {
                  return (
                    <div className="images_tab_1">
                      <img
                        className="img-fluid"
                        alt=""
                        src={URL.createObjectURL(item)}
                      />
                      <img
                        alt=""
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/x-circle.svg"
                        }
                        className="cross-icon"
                        onClick={() => removeImage(key)}
                      />
                    </div>
                  );
                })
              ) : (
                <></>
              )}
            </div>
            <div className="insert_images align-items-center d-flex">
              <label
                style={
                  disablePhotoInput === true ||
                  files?.length === 4 ||
                  selectedFile?.length === 4 ||
                  heightWidth?.length === 4
                    ? {}
                    : { cursor: "pointer" }
                }
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept=".png, .jpeg, .jpg"
                  id="imageForPosts"
                  multiple
                  onChange={handleFileInput}
                  disabled={
                    disablePhotoInput === true ||
                    files?.length === 4 ||
                    selectedFile?.length === 4 ||
                    heightWidth?.length === 4
                  }
                />
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/gallery.svg"
                  }
                  className="img-fluid"
                  alt="gallery"
                />
              </label>
              <div
                className="ms-2"
                onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
              >
                <label style={{ cursor: "pointer" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
              </div>

              <div className="sania_btn_2 ms-auto mt-3">
                <button
                  disabled={!post.trim() && !selectedFile?.length}
                  onClick={() => {
                    uploadImages();
                  }}
                  className="blue_button d-flex align-items-center font_16 font_500"
                >
                  POST
                </button>
              </div>
            </div>
            {emojiPickerVisible ? (
              <div className="emoji-post-main-div">
                <EmojiPicker onEmojiClick={(e) => handleEmoji(e)} />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default UploadPost;
