import React from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import { changepassword } from '../../api/apiHandler';

export default function NewPassword() {
    const validationSchema = yup.object().shape({
        // currentPassword: yup.string().required("Current Password is required"),
        newPassword: yup.string()
            .required('Password is required').matches("^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$", "Please enter valid Password").min(8, "Password should be at least 8 characters"),
        confirmPassword: yup.string().required("Please enter your password again").oneOf([yup.ref("newPassword"), null], "Your confirm password doesn't match your actual password")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    const navigate = useNavigate();
    const onSubmit=(data)=>{
        const newData = {"newPassword": data.newPassword, "confirmPassword": data.confirmPassword};
        // changepassword({"old_password":newData.newPassword})
    }
    return (
        <>
            <section className="main_signup">
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="font_30 font_600 text-center">Create New Password</h1>
                            <p className="font_20 text-center">Set new password and login again</p>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-xl-8 col-lg-8 col-md-10 col-sm-12">
                                    <div className="login_form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                    <div className="step_1_form">
                                                        <div className="text-center">
                                                            <img src={process.env.PUBLIC_URL+"assets/images/icons/forgot-logo.svg"} className="img-fluid"
                                                                alt="logo" />
                                                        </div>
                                                        <div className="mobile_number_input">
                                                            <h6 className="font_18 font_500">Enter New Password</h6>  <span style={{color: "red"}}>{errors.newPassword?.message}</span>
                                                            <div className="input-group">
                                                                <span className="input-group-text" id="basic-addon1"><img
                                                                    src={process.env.PUBLIC_URL+"assets/images/icons/user-check.svg"} className="img-fluid"
                                                                    alt="password" /></span>
                                                                <input type="password" className="form-control" required=""
                                                                    autoComplete="off" 
                                                                    {...register("newPassword")}    
                                                                />
                                                                    <label htmlFor="password">Password</label>
                                                                    {/* <span className="input-group-text" id="basic-addon1"><img
                                                                        src={process.env.PUBLIC_URL+"assets/images/icons/eye.svg"} className="img-fluid"
                                                                        alt="show" /></span> */}
                                                            </div>
                                                        </div>
                                                        <div className="mobile_number_input">
                                                            <h6 className="font_18 font_500">Enter Confirm Password</h6> <span style={{color: "red"}}>{errors.confirmPassword?.message}</span>
                                                            <div className="input-group">
                                                                <span className="input-group-text" id="basic-addon1"><img
                                                                    src={process.env.PUBLIC_URL+"assets/images/icons/user-check.svg"} className="img-fluid"
                                                                    alt="password" /></span>
                                                                <input type="password" className="form-control" required=""
                                                                    autoComplete="off" 
                                                                    {...register("confirmPassword")}
                                                                />
                                                                    <label htmlFor="password">Confirm Password</label>
                                                            </div>
                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-10">
                                                                <button type="submit"
                                                                    className="signup_blue_btn font_22 font_600 text-center">RESET PASSWORD</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
