import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import { RenderTextWithHashtags } from "../HomeFeed";
import PostLikeComment from "../components/PostLikeComment";
import PostUserDetails from "../components/PostUserDetails";

const ReelsPostType = (props) => {
  const {
    item,
    key,
    likeUnlike,
    singlePost,
    setSinglePost,
    retweet,
    handleShare,
    setPostPermission,
    undoRetweet,
    handleSavePost,
    isSinglePostView = false,
    isDetailPage = false,
    interaction,
  } = props;
  const videoRef = useRef(null);
  const navigate = useNavigate();
  // const [interaction, setInteraction] = useState(false);
  const [muted, setMuted] = useState(false);
  console.log("interaction: ", interaction, "muted", muted);

  const [isVisible, setIsVisible] = useState(false);

  const hideImage = () => {
    setIsVisible(false);
  };
  const toggleMute = () => {
    setMuted(!muted);

    setIsVisible(true);

    if (videoRef.current) {
      videoRef.current.muted = !muted;
    }

    setTimeout(() => {
      hideImage();
    }, 2000);
  };

  // useEffect(() => {
  //   // Inside your useEffect hook
  //   const handleUserInteraction = () => {
  //     console.log("interaction: +++++++++++++++");
  //     // setInteraction(true);
  //   };

  //   // Attach event listener to the document for any user interaction
  //   document.addEventListener("click", handleUserInteraction);

  //   // Cleanup function to remove the event listener
  //   return () => {
  //     document.removeEventListener("click", handleUserInteraction);
  //   };
  // });

  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Adjust the threshold as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current && entry.isIntersecting) {
          // Video is in view, autoplay
          videoRef.current.play();
        } else if (videoRef.current) {
          // Video is not in view, pause
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }
  }, []);

  return (
    <>
      {item.post_type == "reels" ? (
        <div className="col-12 mb-3" key={item?._id}>
          <div className="home_post_main mt-0">
            <div className="top_hader">
              <PostUserDetails
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                item={item}
                muteIcon={true}
                // interaction={interaction}
                // toggleMute={toggleMute}
                // muted={muted}
              />
              <video
                ref={videoRef}
                autoPlay={!interaction}
                muted={!interaction ? "muted" : ""}
                loop={true}
                id="banner"
                controls={false}
                controlsList="nodownload"
                className="video_ply"
                poster={item?.media[0]?.thumb}
                onClick={() => {
                  toggleMute();
                }}
                onDoubleClick={() => navigate(`/post/${item?._id}`)}
              >
                <source src={item?.media[0]?.name} type="video/mp4" />
              </video>

              {/* {
                <img
                  alt=""
                  onClick={() => {
                    toggleMute();
                  }}
                  className="reels_play_btn"
                  src={
                    muted
                      ? process.env.PUBLIC_URL +
                        "/assets/video/icons/volume.svg"
                      : process.env.PUBLIC_URL +
                        "/assets/video/icons/volume_off.svg"
                  }
                />
              } */}
              <img
                className={`reels_play_btn cursor-pointer ${
                  isVisible ? "" : "hide_mute_icon"
                }`}
                src={
                  muted
                    ? process.env.PUBLIC_URL +
                      "/assets/video/icons/volume_off.svg"
                    : process.env.PUBLIC_URL + "/assets/video/icons/volume.svg"
                }
                alt=""
              />
              {/* {interaction ? (
              ) : (
                <img
                  className={`reels_play_btn cursor-pointer ${
                    isVisible ? "" : "hide_mute_icon"
                  }`}
                  onClick={() => {
                    // toggleMute();
                  }}
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/video/icons/volume_off.svg"
                  }
                  alt=""
                />
              )} */}

              <PostLikeComment
                retweet={retweet}
                singlePost={singlePost}
                undoRetweet={undoRetweet}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleShare={handleShare}
                handleSavePost={handleSavePost}
                item={item}
                isDetailPage={isDetailPage}
              />
            </div>
            <div className="post_cpntent">
              {item.description.length > 0 && (
                <p>
                  <b className="me-2">
                    <Link className="text-dark">{item?.name}</Link>
                  </b>{" "}
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
              )}
              <div className="comment_button">
                <Link to={`/post/${item?._id}`} className="btn">
                  <img
                    src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                    alt=""
                    className="me-2"
                  />
                  {item?.comment_count} comments
                </Link>
              </div>
            </div>
          </div>
        </div>
      ) : (
        <div key={item?._id}>Reels Post Other Type</div>
      )}
    </>
  );
};

export default ReelsPostType;
