import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import Header from '../Header';
import { get_people_list } from '../../api/apiHandler';
import Select from 'react-select';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Friends() {
    const [friendList, setFriendList] = useState([]);
    const [selectedFriend, setSelectedFriend] = useState(null);
    var fdArray = [];
    const navigate = useNavigate();
    const validationSchema = yup.object().shape({
        friend: yup.string()
            .required('Friend name is required').matches("[A-Za-z ]", "Friend name is not valid"),
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, formState } = useForm(formOptions);
    const { errors } = formState;
    useEffect(() => {
        if (localStorage.getItem("step") != "3") {
            navigate('/')
        }
    }, [])
    const onSubmit = (data) => {
        if (selectedFriend === null) {
            toast.error("Please select college & stream", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: "find-friends-error"
            });
        } else {
            localStorage.setItem("friend", selectedFriend);
            navigate("/linkhashtags")
        }

    }
    const friendsList = () => {
        get_people_list({ "page_token": "" }).then((response) => {
            console.log("Line 25", response?.data?.friend_list);
            if (response?.code === 1 || response?.code === "1") {
                setFriendList(response?.data?.friend_list)
            }
        })
    }
    friendList?.map((item) => {
        var obj = {
            label: item?.name,
            value: item?.name
        }
        fdArray.push(obj)
    })
    useEffect(() => {
        friendsList();
    }, [])
    return (
        <>
            {
                localStorage.getItem("step") == "3" && (
                    <>
                        <Header />
                        <section className="main_signup">
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="font_30 font_600 text-center signup_heading_border_blue">Sign Up Your <span
                                            className="blue_29">Account</span></h1>
                                        <p className="inter font_18 text-center"><q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt.</q></p>
                                    </div>
                                </div>
                                <div className="form__container">
                                    <div className="row">
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div className="left__container">
                                                <div className="form_vector">
                                                    <img src={process.env.PUBLIC_URL + "assets/images/icons/vector-form.svg"} className="img-fluid" alt="vector" />
                                                </div>
                                                <h6 className="font_26 black_00 font_600">Step 5</h6>
                                                <p className="gray_5a font_16 font_500">Enter Friend's name</p>
                                                <div className="steps d-md-flex">
                                                    <div className="progress__bar__container">
                                                        <ul className="ps-0">
                                                            <li className="active" id="icon1">
                                                                <span>1</span>
                                                            </li>
                                                            <li id="icon2" className="active">
                                                                <span>2</span>
                                                            </li>
                                                            <li id="icon3" className="active">
                                                                <span>3</span>
                                                            </li>
                                                            <li id="icon4" className="active">
                                                                <span>4</span>
                                                            </li>
                                                            <li id="icon5" className="active">
                                                                <span>5</span>
                                                            </li>
                                                            <li id="icon6">
                                                                <span>6</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="side__titles">
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Basic Details</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">OTP Verification</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Select UserName</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Select College</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Find Friends</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 mb-0">Link Hashtags</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 position-relative">
                                            <div className="right_form_1 right__container">
                                                <div id="form5">
                                                    <div className="form_heading">
                                                        <h4 className="font_25 font_600 blue_29">Find your Friends</h4>
                                                        <p className="mb-0"><q>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            do eiusmod tempor incididunt.</q></p>
                                                    </div>
                                                    <form>
                                                        <div className="row justify-content-center w-100 my-auto">
                                                            <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                                <div className="step_1_form">
                                                                    <div className="mobile_number_input">
                                                                        <h6 className="font_18 font_500">Find Your friends</h6> {""} <span style={{ color: "red" }}>{errors.friend?.message}</span>
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" id="basic-addon1"><img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/people.svg"}
                                                                                className="img-fluid" alt="phone" /></span>
                                                                            <Select
                                                                                className="form-control"
                                                                                autoComplete="off"
                                                                                defaultValue={selectedFriend != null ? selectedFriend : null}
                                                                                onChange={setSelectedFriend}
                                                                                options={fdArray}
                                                                                placeholder={"Find Friend"}
                                                                                isSearchable
                                                                                isMulti
                                                                            />
                                                                            {/* <label htmlFor="name">Find Friends</label> */}
                                                                        </div>
                                                                    </div>
                                                                    <Link to="/linkhashtags" className="blue_29 float-end cursor-pointer">Skip</Link>
                                                                    <div className="buttons_form_1 buttons">
                                                                        <Link to="/college-stream" className="font_15 prev__btn d-flex align-items-center"
                                                                        ><img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/prev.svg"} className="img-fluid"
                                                                                alt="icon" />Previous</Link>
                                                                        <button type='button' onClick={onSubmit} className="font_15 nxt__btn blue_button d-flex align-items-center"
                                                                        >Next <img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/next.svg"} className="img-fluid"
                                                                                alt="icon" /> </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </>
                )
            }
        </>
    )
}
{/* <div className="buttons_form_1 buttons">
                                    <a className="font_15 prev__btn d-flex align-items-center"
                                        onClick="prevForm();"><img
                                            src={process.env.PUBLIC_URL + "assets/images/icons/prev.svg"} className="img-fluid"
                                            alt="icon" />Previous</a>
                                    <a className="font_15 nxt__btn blue_button d-flex align-items-center"
                                        onClick="nextForm();">Next <img
                                            src={process.env.PUBLIC_URL + "assets/images/icons/next.svg"} className="img-fluid"
                                            alt="icon" /> </a>
                                </div> */}