import React, { useState } from 'react'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { forgotpassword } from '../../api/apiHandler';

export default function ForgotPassword() {
    const validationSchema = Yup.object().shape({
        email: Yup.string()
            .email('Email address is invalid')
            .required('Email address is required')
            .matches("[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}", "Email is invalid")
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data) => {
        const newData = { "email": data.email };
        // setTimeout(() => {
            // NotificationManager.success("Email has sent to your mail")
        // }, 1000)
        forgotpassword(newData).then((resp) => {
            if(resp.code == 1){
                toast.success(resp.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset();
            }
            else{
                toast.error("Please check your Email address once!!", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
            }
        });
    }
    return (
        <>
            <section className="main_signup">
                <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                /> 
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <h1 className="font_30 font_600 text-center">Forgot Your Password</h1>
                            <p className="font_20 text-center">Enter your email to change password</p>
                            <div className="row justify-content-center mt-5">
                                <div className="col-12 col-xl-8 col-lg-8 col-md-10 col-sm-12">
                                    <div className="login_form">
                                        <form onSubmit={handleSubmit(onSubmit)}>
                                            <div className="row justify-content-center">
                                                <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                    <div className="step_1_form">
                                                        <div className="text-center">
                                                            <img src={process.env.PUBLIC_URL + "assets/images/icons/forgot-logo.svg"} className="img-fluid" alt="logo" />
                                                        </div>
                                                        <div className="mobile_number_input">
                                                            <h6 className="font_18 font_500">Enter Your E-MAIL</h6>
                                                            <span style={{ color: "red" }}>{errors.email?.message}</span>
                                                            <div className="input-group">
                                                                <span className="input-group-text" id="basic-addon1"><img
                                                                    src={process.env.PUBLIC_URL + "assets/images/icons/mail.svg"} className="img-fluid"
                                                                    alt="phone" /></span>
                                                                <input type="email" className="form-control" autoComplete="off"
                                                                    // onChange={(e)=>{setEmail(e.target.value)}}
                                                                    // value={email}
                                                                    {...register('email')}
                                                                />
                                                                <label htmlFor="name">Email</label>
                                                            </div>

                                                        </div>
                                                        <div className="row justify-content-center">
                                                            <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-10">
                                                                <button type="submit"
                                                                    className="signup_blue_btn font_22 font_600"
                                                                    // data-bs-toggle="modal"
                                                                    /*data-bs-target="#exampleModal"*/>CONFIRM</button>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
