import React from 'react'
import HeaderProfile from '../HeaderProfile'
import Header from '../Header'

export default function Education() {
    return (
        <>
            {/* {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile /> : <Header />} */}
            <div className="edit_text">
                <h4 className="font_25 font_600">Education</h4>
                <div className="edit_check_btn mt_66">
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label light_gray_8b font_20" htmlFor="flexCheckDefault">
                            Graduate
                        </label>
                    </div>
                    <div className="form-check">
                        <input className="form-check-input" type="checkbox" value="" id="flexCheckDefault" />
                        <label className="form-check-label light_gray_8b font_20" htmlFor="flexCheckDefault">
                            Masters
                        </label>
                    </div>
                </div>
                <div className="row justify-content-center">
                    <div className="col-12 mt-5">
                        <div className="edit_input mobile_number_input">
                            <h6 className="font_18 font_500">Select College</h6>
                            <div className="input-group">
                                <input type="text" className="form-control" required="" />
                                <label htmlFor="text">Select College</label>
                            </div>
                        </div>
                    </div>
                    <div className="col-12 mt-2">
                        <div className="edit_input mobile_number_input">
                            <h6 className="font_18 font_500">Select Stream</h6>
                            <div className="input-group">
                                <input type="text" className="form-control" required="" />
                                <label htmlFor="text">Select Stream</label>
                            </div>
                        </div>
                    </div>
                </div>
                <div className="mt_66">
                    <div className="row justify-content-center">
                        <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6">
                            <button type="button" className="edit_cancel blue_apply_bg orange_btn font_14 inter font_600">
                                Cancel
                            </button>
                        </div>
                        <div className="col-12 col-xl-5 col-lg-5 col-md-5 col-sm-6 mt-5 mt-sm-0">
                            <button type="button"
                                className="blue_apply_bg orange_btn white_ff font_14 inter">
                                Save All Changes
                            </button>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}
