/* eslint-disable default-case */
import React, { useCallback, useEffect, useMemo, useState } from "react";
import { Link, useNavigate, useLocation } from "react-router-dom";
import HeaderProfile from "../../HeaderProfile";
// import {  CometChatUI } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/components";
// import { CometChat } from "@cometchat-pro/chat";

import {
  user_details,
  get_user_post_list,
  give_like_unlike,
  post_details,
  other_user_details,
  add_post,
  add_friend,
  add_comments,
  linking_in_list,
  linking_out_list,
  other_linking_in_list,
  other_linking_out_list,
  undo_post_retweet,
  delete_post,
  remove_follow,
  remove_friend,
  mutual_friend_list,
  my_document_list,
  public_document,
  get_user_reel_list,
  home_post,
  media_post_list,
  accept_reject_request,
  share_link,
  save_post,
  block_unblock_friend,
} from "../../../api/apiHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import Box from "@mui/material/Box";
import Modal from "@mui/material/Modal";
import S3 from "react-aws-s3";
import Recommendation from "../../../common/Recommendation";
import TrendingTopics from "../../../common/TrendingTopics";
import ReactTimeAgo from "react-time-ago";
import EmojiPicker from "emoji-picker-react";
import { saveAs } from "file-saver";
import InfiniteScroll from "react-infinite-scroll-component";
import { showMessage } from "../../../common/RedirectPathManage";
import NormalPostType from "../HomeFeed/module/NormalPostType";
import RetweetPostType from "../HomeFeed/module/RetweetPostType";
import RetweetWithQuote from "../HomeFeed/module/RetweetWithQuote";
import ReelsPostType from "../HomeFeed/module/ReelsPostType";
import ReelPlayer from "../Reels/components/ReelPlayer";
import ShareLink from "../HomeFeed/components/ShareLink";
import OtherLinkingIn from "./components/OtherLinkingIn";
import OtherLinkingOut from "./components/OtherLinkingOut";
import MyLinkinOut from "./components/MyLinkinOut";
import MyLinkingIn from "./components/MyLinkingIn";
import MutualLinks from "./components/MutualLinks";
import OtherProfileAction from "./components/OtherProfileAction";
import { getTimeDifference } from "../HomeFeed/components/PostUserDetails";
import PostMenu from "../HomeFeed/components/postMenu";

const customStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/image",
};

const configVideo = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/video",
};

const configCommentThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media/thumb",
};

const configComment = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media",
};

const configThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/thumb",
};

const ReactS3Client = new S3(config);
const ReactS3ClientVideo = new S3(configVideo);
const ReactS3ClientComment = new S3(configComment);
const ReactS3ClientCommentThumb = new S3(configCommentThumb);
const ReactS3ClientThumb = new S3(configThumb);

export default function Profile() {
  const location = useLocation();

  const [accountId, setAccountId] = useState(location?.state);

  const [userDetails, setUserDetails] = useState([]);
  const [followedTopics, setFollowedTopics] = useState([]);
  const [otherFollowedTopics, setOtherFollowedTopics] = useState([]);
  const [posts, setPosts] = useState([]);
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });

  const isOwnProfile = useMemo(() => {
    return accountId == localStorage.getItem("_id");
  }, [accountId]);

  // const [postDetail, setPostDetail] = useState([]);
  var date = new Date(userDetails?.dob).toLocaleString("en-US", {
    day: "2-digit",
  });
  var month = new Date(userDetails?.dob).toLocaleString("en-US", {
    month: "long",
  });
  const [singlePost, setSinglePost] = useState([]);
  const [quotePost, setQuotePost] = useState("");
  const [popup, setPopUp] = useState(false);
  const [postPermission, setPostPermission] = useState(false);
  const [commentModal, setCommentModal] = useState();
  const [comment, setComment] = useState("");
  const [requested, setRequested] = useState(false);
  const [linkingInModal, setLinkingInModal] = useState(false);
  const [myLinkingInList, setMyLinkingInList] = useState([]);
  const [linkingOutModal, setLinkingOutModal] = useState(false);
  const [myLinkingOutList, setMyLinkingOutList] = useState([]);
  const [othersLinkingInList, setOthersLinkingInList] = useState([]);
  const [othersLinkingOutList, setOthersLinkingOutList] = useState([]);
  const [files, setFile] = useState([]);
  const [selectedFile, setSelectedFile] = useState([]);
  const [preView, setPreview] = useState([]);
  const [myLinkingInModal, setMyLinkingInModal] = useState(false);
  const [myLinkingOutModal, setMyLinkingOutModal] = useState(false);
  const [filesForRetweetQuote, setFileForRetweetQuote] = useState([]);
  const [selectedFileForRetweetQuote, setSelectedFileForRetweetQuote] =
    useState([]);
  const [filesForComments, setFileForComments] = useState([]);
  const [selectedFileForComments, setSelectedFileForComments] = useState([]);
  const [mutualLinkingInModal, setMutualLinkingInModal] = useState(false);
  const [otherUserDetails, setOtherUserDetails] = useState([]);
  console.log("otherUserDetails:===> ", otherUserDetails);
  const [mutualFriends, setMutualFriends] = useState([]);
  const [othersPosts, setOthersPosts] = useState([]);
  console.log("othersPosts: ", othersPosts);
  const [myDoc, setMyDoc] = useState([]);
  const [otherDoc, setOtherDoc] = useState([]);
  const [myReels, setMyReels] = useState([]);
  const [otherReels, setOtherReels] = useState([]);
  const [showFullLocation, setShowFullLocation] = useState(false);
  const [heightWidthForQuotePost, setHeightWidthForQuotePost] = useState([]);
  const [heightWidthForComments, setHeightWidthForComments] = useState([]);
  const [showFullCaption, setShowFullCaption] = useState(false);
  const [emojiPickerForQuotePost, setEmojiPickerForQuotePost] = useState(false);
  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);
  const [isMedia, setIsMedia] = useState(null);
  const [isOtherMedia, setIsOtherMedia] = useState(null);
  const [isFetching, setIsFetching] = useState(false);
  const [isFetchingReels, setIsFetchingReels] = useState(false);
  const [pageToken, setPageToken] = useState(1);
  const [pageTokenReels, setPageTokenReels] = useState(1);

  const [thumbnailForComments, setThumbnailForComments] = useState("");
  const [thumbnailForQuotePost, setThumbnailForQuotePost] = useState("");
  const [disablePhotoInputForComments, setDisablePhotoInputForComments] =
    useState(false);
  const [disableVideoInputForComments, setDisableVideoInputForComments] =
    useState(false);
  const [disablePhotoInputForQuotePost, setDisablePhotoInputForQuotePost] =
    useState(false);
  const [disableVideoInputForQuotePost, setDisableVideoInputForQuotePost] =
    useState(false);

  const [pageTokenOtherLinkingIn, setPageTokenOtherLinkingIn] = useState(1);
  const [pageTokenOtherLinkingOut, setPageTokenOtherLinkingOut] = useState(1);
  const [pageTokenMyLinkingOut, setPageTokenMyLinkingOut] = useState(1);
  const [pageTokenMyLinkingIn, setPageTokenMyLinkingIn] = useState(1);
  const [pageTokenMutualLinks, setPageTokenMutualLinks] = useState(1);
  const [isFetchingOtherLinkingOut, setIsFetchingOtherLinkingOut] =
    useState(true);
  const [isFetchingOtherLinkingIn, setIsFetchingOtherLinkingIn] =
    useState(true);
  const [isFetchingMyLinkingOut, setIsFetchingMyLinkingOut] = useState(true);
  const [isFetchingMyLinkingIn, setIsFetchingMyLinkingIn] = useState(true);
  const [isFetchingMutualLinks, setIsFetchingMutualList] = useState(true);
  const [otherUserAction, setOhterUserAction] = useState(false);

  const maxHeight = "500px";
  const maxHeightInInt = 500;
  var newArr = [];
  var newPhotos = [];
  var imageDimensions = [];
  const navigate = useNavigate();
  const openModal = () => {
    setPopUp(true);
  };
  const closeModal = () => {
    setPopUp(false);
  };

  function isImageValid(blobOrFile, callback) {
    const image = new Image();
    image.onload = function () {
      // Image dimensions can be checked to ensure it's a valid image
      if (image.width > 0 && image.height > 0) {
        callback(true); // It's a valid image
      } else {
        callback(false); // Invalid image (e.g., not an image or corrupted)
      }
    };
    image.onerror = function () {
      callback(false); // Error loading the image
    };

    // Set the src of the Image element with the Blob URL or Data URL
    const blobURL = URL.createObjectURL(blobOrFile);
    image.src = blobURL;
    console.log("Line 140", image.src);
  }

  const handleFileInput = async (e) => {
    // console.log("Line 64", media)
    // e.preventDefault();
    // const file = e.target.files[0];
    // try {
    //     const upload = await ReactS3Client.uploadFile(file);
    //     setPreview(upload?.location);
    //     console.log(upload?.location);
    //     obj.name = upload?.location?.split("/")[6];
    //     setMedia(obj);
    //     console.log("Line 71", media)
    //     console.log("Line 72", obj)
    //     newPhotos.push(obj);
    //     console.log("Line 74", newPhotos);
    // } catch (e) {
    //     console.log(e);
    // }
    let file = e.target.files;
    console.log("Line 88", file);

    for (let i = 0; i < file.length; i++) {
      console.log(file[i].name);
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        console.log("else");
        setFile([...files, file[i]]);
        console.log(files);
        console.log("Line 104 ---------->", e.target.files[i]);
        console.log("Line 105 ==========>", [...files, e.target.files[i]]);
        setSelectedFile([...files, e.target.files[i]]);
      }
    }
  };

  const handleFileInputForRetweetQuotes = async (e) => {
    setDisableVideoInputForQuotePost(true);
    let file = e.target.files;
    console.log("Line 88", file);
    const render = new FileReader();
    console.log(render);
    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        console.log("Line 127", img.width, img.height);
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);
        console.log(imageDimensions);
        setHeightWidthForQuotePost([
          ...heightWidthForQuotePost,
          imageDimensions,
        ]);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      console.log(file[i].name);
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const img = new Image();
          img.src = render.result;
          console.log("Line 162", img.src);
          img.onload = () => {
            var obj = { height: img.height, width: img.width, file: file[i] };
            imageDimensions.push(obj);
            console.log("Line 139", imageDimensions);

            setHeightWidthForQuotePost([
              ...heightWidthForQuotePost,
              imageDimensions,
            ]);
            setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
            setSelectedFileForRetweetQuote([
              ...filesForRetweetQuote,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
        console.log(filesForRetweetQuote);
        console.log("Line 218 ---------->", e.target.files[i]);
        console.log("Line 219 ==========>", [
          ...filesForRetweetQuote,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForQuotePost(true);
  };

  const handleFileInputForComments = async (e) => {
    setDisableVideoInputForComments(true);
    let file = e.target.files;
    console.log("Line 88", file);
    const render = new FileReader();
    console.log(render);
    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        console.log("Line 127", img.width, img.height);
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);
        console.log(imageDimensions);
        setHeightWidthForComments([...heightWidthForComments, imageDimensions]);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      console.log(file[i].name);
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;
          console.log("Line 133", image.src);
          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);
            console.log("Line 139", imageDimensions);
            setHeightWidthForComments([
              ...heightWidthForComments,
              imageDimensions,
            ]);
            setFileForComments([...filesForComments, file[i]]);
            setSelectedFileForComments([
              ...filesForComments,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFileForComments([...filesForComments, file[i]]);
        console.log(filesForComments);
        console.log("Line 104 ---------->", e.target.files[i]);
        console.log("Line 105 ==========>", [
          ...filesForComments,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForComments(true);
  };

  const handleVideoUploadForComments = (event) => {
    setDisablePhotoInputForComments(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;
        console.log("Line 440", videoElement.src);
        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForComments(imageDimensions);
          setSelectedFileForComments([...filesForComments, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            console.log("Blob created", blob);
            isImageValid(blob, (isValid) => {
              if (isValid) {
                console.log("Line 328: The image Blob is valid.");
              } else {
                console.log("Line 330: The image Blob is not valid.");
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
                console.log("Line 337: The image File is valid.");
              } else {
                console.log("Line 339: The image File is not valid.");
              }
            });
            setThumbnailForComments(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
          console.log("Line 302", thumbnailUrl);
        });
      };
      reader.readAsDataURL(videoFile);
      setFileForComments([...filesForComments, videoFile]);
    }
  };

  const handleVideoUploadForRetweetWithQuotes = (event) => {
    setDisablePhotoInputForQuotePost(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;
        console.log("Line 295", videoElement.src);
        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForQuotePost(imageDimensions);
          setSelectedFileForRetweetQuote([...files, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            console.log("Blob created", blob);
            isImageValid(blob, (isValid) => {
              if (isValid) {
                console.log("Line 328: The image Blob is valid.");
              } else {
                console.log("Line 330: The image Blob is not valid.");
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
                console.log("Line 337: The image File is valid.");
              } else {
                console.log("Line 339: The image File is not valid.");
              }
            });
            setThumbnailForQuotePost(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
          console.log("Line 302", thumbnailUrl);
        });
      };
      reader.readAsDataURL(videoFile);
      setFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
    }
  };

  const removeImageForQuotePost = (index) => {
    console.log(index);
    var x = [...filesForRetweetQuote];
    var w = [...heightWidthForQuotePost];
    console.log("above", x);
    x.splice(index, 1);
    w.splice(index, 1);
    console.log("below", x);
    setFileForRetweetQuote(x);
    setSelectedFileForRetweetQuote(x);
    setHeightWidthForQuotePost(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForQuotePost(false);
      setDisableVideoInputForQuotePost(false);
    }
  };

  const removeImageForComments = (index) => {
    console.log(index);
    var x = [...filesForComments];
    var w = [...heightWidthForComments];
    console.log("above", x);
    x.splice(index, 1);
    w.splice(index, 1);
    console.log("below", x);
    setFileForComments(x);
    setSelectedFileForComments(x);
    setHeightWidthForComments(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForComments(false);
      setDisableVideoInputForComments(false);
    }
  };

  // Upload images for retweet with quotes
  const uploadImagesForRetweetQuotes = async () => {
    if (selectedFileForRetweetQuote?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForRetweetQuote?.length; j++) {
        console.log("Line 265", selectedFileForRetweetQuote[j]);
        let upload;
        let uploadThumb;
        if (selectedFileForRetweetQuote[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFileForRetweetQuote[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForQuotePost
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFileForRetweetQuote[j]
          );
        }
        console.log("Line 425", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForRetweetQuote[j]?.type?.split("/")[0],
          width: heightWidthForQuotePost[j][0]?.width,
          height: heightWidthForQuotePost[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 429", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      retweetWithQuotes(newArr);
      console.log("Line 434", newArr);
    }
    // setMedia(newArr);
  };

  const uploadImagesForComments = async () => {
    if (selectedFileForComments?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForComments?.length; j++) {
        console.log("Line 123", selectedFileForComments[j]);
        let upload;
        let uploadThumb;
        if (selectedFileForComments[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForComments
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
        }
        console.log("Line 105", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForComments[j]?.type?.split("/")[0],
          width: heightWidthForComments[j][0]?.width,
          height: heightWidthForComments[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 107", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      addComments(newArr);
      console.log("Line 112", newArr);
      setFileForComments();
      setSelectedFileForComments();
      setHeightWidthForComments();
    }
    // setMedia(newArr);
  };

  // My Profile API
  const myProfile = useCallback(() => {
    if (isOwnProfile) {
      console.log("My Profile", localStorage?.getItem("username"));
      user_details({
        login_type: "S",
        device_type: "W",
        device_token: "",
        latitude: "",
        longitude: "",
        location: "",
        username: localStorage?.getItem("username"),
      }).then((response) => {
        console.log("My Profile", response);
        if (response.code === "1" || response.code === 1) {
          setUserDetails(response?.data);
          setFollowedTopics(response.data.topics);
        }
      });
    } else {
      if (accountId) {
        other_user_details({ friend_id: accountId }).then((response) => {
          if (response.code === "1" || response.code === 1) {
            // setUserDetails(response.data);
            // setFollowedTopics(response.data.topics);
            setOtherUserDetails(response?.data);
            setOtherFollowedTopics(response?.data?.topics);
          }
        });
      }
    }
  }, [accountId, isOwnProfile]);

  // Other user profile
  // const otherProfile = useCallback(() => {
  //   other_user_details({ friend_id: accountId }).then((response) => {
  //     if (response?.code === "1" || response?.code === 1) {
  //       setOtherUserDetails(response?.data);
  //       setOtherFollowedTopics(response?.data?.topics);
  //     }
  //   });
  // }, [accountId]);

  // Post API

  const myPosts = useCallback(
    (payload) => {
      setIsFetching(true);
      if (accountId?.length) {
        get_user_post_list(payload).then((response) => {
          console.log(
            "Page token",
            payload.page_token,
            accountId,
            "Line 402",
            response
          );
          if (response.code === 1 || response.code === "1") {
            // setPosts(response?.data);
            if (isOwnProfile) {
              if (payload.page_token == 1) {
                setPosts(response?.data);
              } else {
                setPosts((prevPosts) => [...prevPosts, ...response?.data]);
              }
              // setPageToken(payload.page_token + 1);
            } else {
              if (payload.page_token == 1) {
                setOthersPosts(response?.data);
              } else {
                setOthersPosts((prevPosts) => [
                  ...prevPosts,
                  ...response?.data,
                ]);
              }
              // setPageToken(payload.page_token + 1);
            }
          } else if (response.code === 2 || response.code === "2") {
            setIsFetching(false);
            if (payload.page_token == 1) {
              if (isOwnProfile) {
                setPosts([]);
              } else {
                setOthersPosts([]);
              }
            }
          } else {
            setIsFetching(false);
          }
        });
      }
    },
    [accountId, isOwnProfile]
  );

  // const otherPosts = () => {
  //   setIsFetching(true);
  //   console.log("pageToken: ", pageToken);
  //   get_user_post_list({
  //     page_token: pageToken,
  //     other_user_id: accountId,
  //   }).then((response) => {
  //     console.log("Line 416", response);
  //     if (response?.code === 1 || response?.code === "1") {
  //       // setOthersPosts(response?.data);
  //       setOthersPosts((prevPosts) => [...prevPosts, ...response?.data]);
  //       setPageToken(pageToken + 1);
  //     } else if (response?.code === 2 || response?.code === "2") {
  //       setIsFetching(false);
  //     } else {
  //       setIsFetching(false);
  //     }
  //   });
  // };

  // Post Detail
  const postDetail = (postId) => {
    post_details({
      post_id: postId,
    }).then((response) => {
      console.log("Line 113", response.data);
      if (response.code === 1 || response.code === "1") {
        setSinglePost(response.data);
      }
    });
  };

  // Like-Unlike API
  // const likeUnlike = (postId) => {
  //   post_details({
  //     post_id: postId,
  //   }).then((response) => {
  //     if (response?.code === 1 || response?.data === "1") {
  //       console.log("Line 54", response);
  //       const isLike = response?.data?.is_like === 1;
  //       give_like_unlike({
  //         post_id: response?.data?._id,
  //         type: response?.data?.is_like === 1 ? "unlike" : "like",
  //         post_type: response?.data?.post_type,
  //         owner_user_id: response?.data?.user_id,
  //       }).then((res) => {
  //         console.log("Reel like", res);
  //         myReel();
  //         if (res?.code === 1 || res?.code === "1") {
  //           if (isOwnProfile) {
  //             const updatedFeeds = posts?.map((post) => {
  //               if (post?._id === postId) {
  //                 return {
  //                   ...post,
  //                   is_like: isLike ? 0 : 1,
  //                   likes: isLike ? post?.likes - 1 : post?.likes + 1, // Toggle like status
  //                 };
  //               }
  //               return post;
  //             });
  //             setPosts(updatedFeeds);
  //           } else {
  //             const updatedFeeds = othersPosts?.map((post) => {
  //               if (post?._id === postId) {
  //                 return {
  //                   ...post,
  //                   is_like: isLike ? 0 : 1,
  //                   likes: isLike ? post?.likes - 1 : post?.likes + 1, // Toggle like status
  //                 };
  //               }
  //               return post;
  //             });
  //             setOthersPosts(updatedFeeds);
  //           }
  //         }
  //       });
  //     }
  //   });
  // };

  const likeUnlike = useCallback(
    (item) => {
      give_like_unlike({
        post_id: item?._id,
        type: item?.is_like === 1 ? "unlike" : "like",
        post_type: item?.post_type,
        owner_user_id: item?.user_id,
      }).then((res) => {
        if (res.code === 1 || res.code === "1") {
          if (isOwnProfile) {
            const updatedFeeds = myReels.map((post) => {
              if (post?._id === item?._id) {
                return {
                  ...post,
                  is_like: item?.is_like === 1 ? 0 : 1,
                  likes:
                    item?.is_like === 1 ? post?.likes - 1 : post?.likes + 1,
                };
              }
              return post;
            });
            setMyReels(updatedFeeds);
          } else {
            const updatedFeeds = otherReels.map((post) => {
              if (post?._id === item?._id) {
                return {
                  ...post,
                  is_like: item?.is_like === 1 ? 0 : 1,
                  likes:
                    item?.is_like === 1 ? post?.likes - 1 : post?.likes + 1,
                };
              }
              return post;
            });
            setOtherReels(updatedFeeds);
          }
        }
      });
    },
    [isOwnProfile, myReels, otherReels]
  );

  // retweet
  const retweet = ({ _id }) => {
    post_details({
      post_id: _id,
    }).then((response) => {
      console.log("Line 79", response);
      if (response?.code === 1 || response?.code === "1") {
        var req_params = {
          description: response?.data?.description,
          media: response?.data?.media,
          retweet_id: response?.data?._id,
          post_type:
            response?.data?.module_type === "reels" &&
            response?.data?.post_type === "reels"
              ? "reels"
              : "retweet",
          shared_feed: response?.data,
        };
        console.log("Retweet params", req_params);
        add_post({
          description: response?.data?.description,
          media: response?.data?.media,
          retweet_id: response?.data?._id,
          module_type:
            response?.data?.module_type === "reels" ? "reels" : "post",
          post_type: "retweet",
          shared_feed: response?.data,
        }).then((response) => {
          console.log("Line 88 Reel Retweet", response);
          if (response.code === 1 || response.code === "1") {
            toast.info(response.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              toastId: "reel-retweet-success",
            });
            if (isOwnProfile) {
              const updatedFeeds = myReel?.map((post) => {
                if (post?._id === _id) {
                  return {
                    ...post,
                    is_retweet: 1,
                    retweet_count: post?.retweet_count + 1,
                  };
                }
                return post;
              });
              setMyReels(updatedFeeds);
            } else {
              const updatedFeeds = otherReels?.map((post) => {
                if (post?._id === _id) {
                  return {
                    ...post,
                    is_retweet: 1,
                    retweet_count: post?.retweet_count + 1,
                  };
                }
                return post;
              });
              setOtherReels(updatedFeeds);
            }
            // myReel();
            // myPosts();
          }
        });
      }
    });
  };

  // retweet with quotes
  const retweetWithQuotes = (postImages) => {
    add_post({
      description: quotePost,
      media: postImages,
      retweet_id: singlePost._id,
      post_type:
        (quotePost === "" || quotePost === undefined) &&
        postImages.length === 0 &&
        singlePost?.module_type !== "reels"
          ? "retweet"
          : (quotePost === "" || quotePost === undefined) &&
            postImages.length === 0 &&
            singlePost?.module_type === "reels"
          ? "reels"
          : "retweet_with_quote",
      shared_feed: singlePost,
      module_type: singlePost?.module_type === "reels" ? "reels" : "post",
    }).then((res) => {
      console.log("Line 126", res);
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        closeModal();
        setQuotePost("");
        setFileForRetweetQuote();
        setSelectedFileForRetweetQuote();
        setHeightWidthForQuotePost();
        myPosts();
      }
    });
  };

  // Add friend (Linking in request)
  const addFriend = (payload) => {
    add_friend(payload).then((response) => {
      console.log("response: AddFriend", response);
      if (response.code === 1 || response.code === "1") {
        toast.info("Linking request was sent successfully.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        myProfile();
        if (isOwnProfile) {
          const UpdateMyLinkingOutList = myLinkingOutList?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = item?.account_type === "private" ? 0 : 1;
              item.action_id = response.data.action_id;
            }
            return item;
          });

          const updateMyLinkingInList = othersLinkingInList?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = item?.account_type === "private" ? 0 : 1;
              item.action_id = response.data.action_id;
            }
            return item;
          });
          setMyLinkingOutList(UpdateMyLinkingOutList);
          setMyLinkingInList(updateMyLinkingInList);
        } else {
          const UpdateOthersLinkingOutList = othersLinkingOutList?.map(
            (item) => {
              if (item?.user_id == response.data.friend_id) {
                item.has_friend = item?.account_type === "private" ? 0 : 1;
                item.action_id = response.data.action_id;
              }
              return item;
            }
          );

          const updateOthersLinkingInList = othersLinkingInList?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = item?.account_type === "private" ? 0 : 1;
              item.action_id = response.data.action_id;
            }
            return item;
          });
          const updateMutualList = mutualFriends?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = item?.account_type === "private" ? 0 : 1;
              item.action_id = response.data.action_id;
            }
            return item;
          });

          setOthersLinkingOutList(UpdateOthersLinkingOutList);
          setOthersLinkingInList(updateOthersLinkingInList);
          setMutualFriends(updateMutualList);
        }
      }
    });
  };

  const actionFriend = (action, action_id, user_id) => {
    accept_reject_request({
      id: action_id,
      friend_id: user_id,
      type: action,
    }).then((response) => {
      console.log("response: actionFriend", response);
      if (response.code === 1 || response.code === "1") {
        showMessage(response.message);
        myProfile();
        if (isOwnProfile) {
          // linkingInList();
          // linkingOutList();
        } else {
          const UpdateOthersLinkingOutList = othersLinkingOutList?.map(
            (item) => {
              if (item?.user_id == response.data.friend_id) {
                item.has_friend = response.data.type === "reject" ? 3 : 1;
              }
              return item;
            }
          );

          const updateOthersLinkingInList = othersLinkingInList?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = response.data.type === "reject" ? 3 : 1;
            }
            return item;
          });

          setOthersLinkingOutList(UpdateOthersLinkingOutList);
          setOthersLinkingInList(updateOthersLinkingInList);

          // otherLinkingInList();
          // otherLinkingOutList({
          //   page_token: pageTokenOtherLinkingOut,
          //   friend_id: accountId,
          // });
        }
      }
    });
  };

  const blockUser = (id, type) => {
    block_unblock_friend({ friend_id: id, type }).then((response) => {
      console.log("response: block ", response);
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      showMessage(response.message);
      myProfile();
      if (isOwnProfile) {
        // linkingInList();
        // linkingOutList();
      } else {
        const UpdateOthersLinkingOutList = othersLinkingOutList?.map((item) => {
          if (item?.user_id == response.data.friend_id) {
            item.has_friend = response.data.type === "reject" ? 3 : 1;
          }
          return item;
        });

        const updateOthersLinkingInList = othersLinkingInList?.map((item) => {
          if (item?.user_id == response.data.friend_id) {
            item.has_friend = response.data.type === "reject" ? 3 : 1;
          }
          return item;
        });

        setOthersLinkingOutList(UpdateOthersLinkingOutList);
        setOthersLinkingInList(updateOthersLinkingInList);

        // otherLinkingInList();
        // otherLinkingOutList({
        //   page_token: pageTokenOtherLinkingOut,
        //   friend_id: accountId,
        // });
      }
    });
  };

  const removeFriend = (payload) => {
    remove_friend(payload).then((response) => {
      console.log("Remove friend", response);
      if (response.code === 1 || response.code === "1") {
        myProfile();
        if (isOwnProfile) {
          // linkingInList();
        } else {
          // otherLinkingInList();
        }
        // setLinkingInModal(false);
      }
    });
  };

  // Add Comment
  const addComments = (images) => {
    add_comments({
      post_id: singlePost?._id,
      comments_type: "main",
      reply_comment_id: "",
      owner_user_id:
        singlePost.post_type === "retweet"
          ? singlePost.shared_feed.user_id
          : singlePost.user_id,
      media: images,
      hashtags: [],
      description: comment,
    }).then((response) => {
      console.log("Line 258", response);
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (isOwnProfile) {
          const updatedFeeds = posts?.map((post) => {
            if (post?._id === singlePost?._id) {
              return {
                ...post,
                comment_count: post?.comment_count + 1,
              };
            }
            return post;
          });
          setPosts(updatedFeeds);
        } else {
          const updatedFeeds = othersPosts?.map((post) => {
            if (post?._id === singlePost?._id) {
              return {
                ...post,
                comment_count: post?.comment_count + 1,
              };
            }
            return post;
          });
          setOthersPosts(updatedFeeds);
        }
        setCommentModal(false);
        setComment("");
        myPosts();
      }
    });
  };

  // My Linking in list
  const linkingInList = useCallback((payload) => {
    setIsFetchingMyLinkingIn(true);
    linking_in_list(payload).then((response) => {
      console.log("Line 241 My Linking In", response);
      if (response.code === 1 || response.code === "1") {
        if (payload.page_token == 1) {
          setMyLinkingInList(response.data.friend_list);
        } else {
          setMyLinkingInList((prev) => [...prev, ...response.data.friend_list]);
        }
      } else {
        setIsFetchingMyLinkingIn(false);
      }
    });
  }, []);

  // My Linking out list
  const linkingOutList = useCallback((payload) => {
    setIsFetchingMyLinkingOut(true);
    linking_out_list(payload).then((response) => {
      console.log("response: linkingOutList", payload, response);
      if (response.code === 1 || response.code === "1") {
        if (payload.page_token == 1) {
          setMyLinkingOutList(response.data.friend_list);
        } else {
          setMyLinkingOutList((prev) => [
            ...prev,
            ...response.data.friend_list,
          ]);
        }
      } else {
        setIsFetchingMyLinkingOut(false);
      }
    });
  }, []);

  // Other Linking in list
  const otherLinkingInList = useCallback((payload) => {
    if (payload.friend_id?.length) {
      setIsFetchingOtherLinkingIn(true);
      other_linking_in_list(payload).then((response) => {
        console.log("Line 423 Other Linking In", payload.page_token, response);
        if (response.code === 1 || response.code === "1") {
          if (payload.page_token == 1) {
            setOthersLinkingInList(response.data.friend_list);
          } else {
            setOthersLinkingInList((prev) => [
              ...prev,
              ...response.data.friend_list,
            ]);
          }
        } else {
          setIsFetchingOtherLinkingOut(false);
        }
      });
    }
  }, []);

  // Other Linking out list
  const otherLinkingOutList = useCallback((payload) => {
    if (payload.friend_id?.length) {
      setIsFetchingOtherLinkingOut(true);
      other_linking_out_list(payload).then((response) => {
        console.log("Line 255", payload, response);
        if (response.code === 1 || response.code === "1") {
          if (payload.page_token == 1) {
            setOthersLinkingOutList(response.data.friend_list);
          } else {
            setOthersLinkingOutList((prev) => [
              ...prev,
              ...response.data.friend_list,
            ]);
          }
        } else {
          setIsFetchingOtherLinkingOut(false);
        }
      });
    }
  }, []);

  // Undo Retweet
  const undoRetweet = ({ _id }) => {
    undo_post_retweet({ id: _id }).then((response) => {
      // if (response.code === 1 || response.code === "1") {
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          toastId: "reel-undo-success",
        });
        if (isOwnProfile) {
          const updatedFeeds = myReel?.map((post) => {
            if (post?._id === _id) {
              return {
                ...post,
                is_retweet: 0,
                retweet_count: post?.retweet_count - 1,
              };
            }
            return post;
          });
          setMyReels(updatedFeeds);
        } else {
          const updatedFeeds = otherReels?.map((post) => {
            if (post?._id === _id) {
              return {
                ...post,
                is_retweet: 0,
                retweet_count: post?.retweet_count - 1,
              };
            }
            return post;
          });
          setOtherReels(updatedFeeds);
        }
        // myReel();
        // myPosts();
      }
      // }
    });
  };

  // Delete Post
  const deletePost = (postId) => {
    console.log(postId);
    if (localStorage.getItem("name") === singlePost.name) {
      delete_post({
        id: singlePost?._id,
        retweet_id:
          singlePost.post_type === "retweet" ? singlePost.retweet_id : "",
      }).then((response) => {
        console.log("Line 228", response);
        if (response.code === 1) {
          toast.info(response.message, {
            toastId: "Delete Post",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          myPosts();
          mediaPostList();
          setPostPermission(false);
        }
      });
    } else {
      setPostPermission(false);
    }
  };

  // Linking in list main
  const linkingMain = () => {
    if (isOwnProfile) {
      setMyLinkingInModal(true);
      // linkingInList();
    } else {
      otherLinkingInList({
        page_token: pageTokenOtherLinkingIn,
        friend_id: accountId,
      });
    }
  };

  // Link out main
  // const linkOutMain ;

  // Remove friend / unfollow or linking out
  const removeFollow = (payload) => {
    remove_follow(payload).then((response) => {
      console.log("Remove friend+++", response);
      toast.info(response.message, {
        toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      if (response.code === 1 || response.code === "1") {
        myProfile();
        if (isOwnProfile) {
          // linkingInList();
          // linkingOutList();
          const UpdateMyLinkingOutList = myLinkingOutList?.filter(
            (item) => item?.user_id != response.data.friend_id
          );
          const updateMyLinkingInList = myLinkingInList?.filter(
            (item) => item?.user_id != response.data.friend_id
          );
          setMyLinkingOutList(UpdateMyLinkingOutList);
          setMyLinkingInList(updateMyLinkingInList);
        } else {
          const UpdateOthersLinkingOutList = othersLinkingOutList?.map(
            (item) => {
              if (item?.user_id == response.data.friend_id) {
                item.has_friend = 3;
              }
              return item;
            }
          );

          const updateOthersLinkingInList = othersLinkingInList?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = 3;
            }
            return item;
          });
          console.log("mutualFriends");
          const updateMutualList = mutualFriends?.map((item) => {
            if (item?.user_id == response.data.friend_id) {
              item.has_friend = 3;
            }
            return item;
          });
          setOthersLinkingInList(updateOthersLinkingInList);
          setOthersLinkingOutList(UpdateOthersLinkingOutList);
          setMutualFriends(updateMutualList);
        }
      }
    });
  };

  // Remove Linking in or follower
  const removeFollower = (friendId) => {
    remove_friend({ friend_id: friendId }).then((response) => {
      console.log("Remove Follower", response);
      if (response.code === 1 || response.code === "1") {
        myProfile();
        const updatedList = myLinkingInList?.filter(
          (item) => item?.user_id != response.data.friend_id
        );
        setMyLinkingInList(updatedList);
      }
    });
  };

  // const profileMain = useCallback(() => {
  //   // if (isOwnProfile) {
  //   myProfile();
  //   // } else {
  //   //   otherProfile();
  //   // }
  // }, [myProfile]);

  // useEffect(() => {
  //   otherProfile();
  // }, [accountId]);

  const mutualLinks = useCallback((payload) => {
    if (payload?.friend_id?.length) {
      setIsFetchingMutualList(true);
      mutual_friend_list(payload).then((response) => {
        console.log("Mutual Friends", response);
        if (response?.code === 1 || response?.code === "1") {
          // setMutualLinkingInModal(true);
          if (payload.page_token == "1") {
            setMutualFriends(response?.data?.mutual_friend_list);
          } else {
            setMutualFriends((prev) => [
              ...prev,
              ...response?.data?.mutual_friend_list,
            ]);
          }
        } else {
          setIsFetchingMutualList(false);
        }
      });
    }
  }, []);

  // const mediaPostList = () => {
  //   if (isOwnProfile) {
  //     mediaPostList();
  //   } else {
  //     otherMediaPostList();
  //   }
  // };

  const mediaPostList = useCallback(() => {
    if (accountId?.length) {
      media_post_list({ user_id: accountId }).then((response) => {
        if (response?.code === 1 || response?.code === "1") {
          if (isOwnProfile) {
            setIsMedia(response.data);
          } else {
            setIsOtherMedia(response.data);
          }
        }
      });
    }
  }, [accountId, isOwnProfile]);

  // const otherMediaPostList = () => {
  //   media_post_list({ user_id: accountId }).then((response) => {
  //     if (response?.code === 1 || response?.code === "1") {
  //     }
  //   });
  // };

  // Documents

  const myDocs = useCallback(() => {
    my_document_list({
      page_token: "1",
      doc_session: "",
      college_name: "",
      short_by: "L",
      search: "",
      id: "",
    }).then((response) => {
      console.log("Document listing", response);
      if (response?.code === 1 || response?.code === "1") {
        setMyDoc(response?.data);
      }
    });
  }, []);

  const otherUserDocs = useCallback(() => {
    if (accountId?.length) {
      my_document_list({
        page_token: "1",
        doc_session: "",
        college_name: "",
        short_by: "L",
        search: "",
        id: accountId,
      }).then((response) => {
        console.log("Other Documents", response);
        if (response?.code === 1 || response?.code === "1") {
          setOtherDoc(response?.data);
        }
      });
    }
  }, [accountId]);

  const documentsMain = useCallback(() => {
    if (isOwnProfile) {
      myDocs();
    } else {
      otherUserDocs();
    }
  }, [isOwnProfile, myDocs, otherUserDocs]);

  const myReel = useCallback(
    (payload) => {
      if (payload.other_user_id?.length) {
        setIsFetchingReels(true);
        get_user_reel_list(payload).then((response) => {
          console.log("My Reels", payload.page_token, response);
          if (response?.code === 1 || response?.code === "1") {
            if (isOwnProfile) {
              if (payload.page_token == "1") {
                setMyReels(response?.data);
              } else {
                setMyReels((prev) => [...prev, ...response?.data]);
              }
            } else {
              // setIsFetchingReels(false);
              if (payload.page_token == "1") {
                setOtherReels(response?.data);
              } else {
                setOtherReels((prev) => [...prev, ...response?.data]);
              }
            }
          } else {
            setIsFetchingReels(false);
          }
        });
      }
    },
    [isOwnProfile]
  );

  // const otherReel = () => {
  //   get_user_reel_list({
  //     page_token: "1",
  //     other_user_id: accountId,
  //     module_type: "",
  //   }).then((response) => {
  //     console.log("Other Reel==>", response);
  //     if (response?.code === 1 || response?.code === "1") {
  //       setOtherReels(response?.data);
  //     }
  //   });
  // };

  const findWidth = (originalHeight, newHeight, originalWidth) => {
    return (newHeight * originalWidth) / originalHeight;
  };

  const isPortrait = (h, w) => {
    return h > w;
  };

  const handleSavePost = useCallback(
    (item) => {
      save_post({
        post_id: item._id,
        type: item?.is_saved == "N" ? "save" : "remove",
      }).then((response) => {
        console.log("response: ", response);
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const updatedPosts = posts?.map((post) => {
            if (post?._id == item?._id) {
              post.is_saved = item?.is_saved == "N" ? "Y" : "N";
            }
            return post;
          });
          setPosts(updatedPosts);
        }
      });
    },
    [posts]
  );

  const handleShare = useCallback((data) => {
    share_link({
      id: data._id,
      module_type: data.module_type,
      description: data.description || "stulink",
    }).then((response) => {
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  useEffect(() => {
    mutualLinks({ page_token: pageTokenMutualLinks, friend_id: accountId });
  }, [accountId, mutualLinks, pageTokenMutualLinks]);

  useEffect(() => {
    linkingInList({ page_token: pageTokenMyLinkingIn });
  }, [linkingInList, pageTokenMyLinkingIn]);

  useEffect(() => {
    linkingOutList({ page_token: pageTokenMyLinkingOut });
  }, [linkingOutList, pageTokenMyLinkingOut]);

  useEffect(() => {
    otherLinkingInList({
      page_token: pageTokenOtherLinkingIn,
      friend_id: accountId,
    });
  }, [accountId, otherLinkingInList, pageTokenOtherLinkingIn]);

  useEffect(() => {
    otherLinkingOutList({
      page_token: pageTokenOtherLinkingOut,
      friend_id: accountId,
    });
  }, [accountId, otherLinkingOutList, pageTokenOtherLinkingOut]);

  useEffect(() => {
    mediaPostList();
  }, [mediaPostList]);

  useEffect(() => {
    myReel({
      page_token: pageTokenReels,
      other_user_id: accountId,
      module_type: "",
    });
  }, [accountId, myReel, pageTokenReels]);

  useEffect(() => {
    documentsMain();
  }, [documentsMain]);

  useEffect(() => {
    myPosts({
      page_token: pageToken,
      other_user_id: accountId,
    });
  }, [accountId, myPosts, pageToken]);

  useEffect(() => {
    myProfile();
  }, [myProfile]);

  useEffect(() => {
    if (location?.state) {
      console.log('location?.state: useeffect', location?.state);
      setAccountId(location?.state);
      setPageToken(1);
      setPageTokenOtherLinkingOut(1);
      setPageTokenOtherLinkingIn(1);
      setPageTokenMyLinkingIn(1);
      setPageTokenMyLinkingOut(1);
      setPageTokenMutualLinks(1);
      setPageTokenReels(1);
    }
  }, [location?.state]);

  const openChat = () => {
    navigate("/chat-room", {
      state: { uid: accountId },
    });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      <HeaderProfile />
      <OtherProfileAction
        otherUserAction={otherUserAction}
        setOhterUserAction={setOhterUserAction}
        handleShare={handleShare}
        singlePost={singlePost}
        setSinglePost={setSinglePost}
        myProfile={myProfile}
      />
      <section className="main_profile">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />

        {/* Retweet with Quotes */}
        <div
          className="modal fade"
          id="retweetModal"
          tabIndex={-1}
          aria-labelledby="retweet"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body position-relative">
                <button
                  type="button"
                  className="btn-close close_custom"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setQuotePost("");
                    setFileForRetweetQuote([]);
                    setSelectedFileForRetweetQuote([]);
                    setHeightWidthForQuotePost([]);
                  }}
                />
                <div className="comment_main">
                  <div className="user_comment">
                    <div className="user_img_comment">
                      <a href="#">
                        <img src={localStorage?.getItem("profile_image")} />
                      </a>
                    </div>
                    <div className="comment_user_post">
                      <input
                        type="text"
                        name=""
                        placeholder="Add comment"
                        value={quotePost}
                        onChange={(e) => {
                          setQuotePost(e.target.value);
                          setEmojiPickerForQuotePost(false);
                        }}
                      />
                      <div className="uploade_images">
                        {filesForRetweetQuote?.length !== 0 ? (
                          filesForRetweetQuote?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    style={
                                      filesForRetweetQuote !== "" ||
                                      filesForRetweetQuote !== undefined ||
                                      filesForRetweetQuote !== null
                                        ? {}
                                        : { display: "none" }
                                    }
                                    key={key}
                                  >
                                    <img
                                      src={URL.createObjectURL(item)}
                                      style={{
                                        height: "100px",
                                        width: "100%",
                                        display:
                                          filesForRetweetQuote === ""
                                            ? "none"
                                            : "",
                                      }}
                                    />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForQuotePost(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    style={
                                      filesForRetweetQuote !== "" ||
                                      filesForRetweetQuote !== undefined ||
                                      filesForRetweetQuote !== null
                                        ? {}
                                        : { display: "none" }
                                    }
                                    key={key}
                                  >
                                    <video
                                      controls
                                      muted
                                      loop
                                      style={{
                                        width: "120px",
                                        display:
                                          filesForRetweetQuote === ""
                                            ? "none"
                                            : "",
                                      }}
                                    >
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() => {
                                        removeImageForQuotePost(key);
                                      }}
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                      <div className="main_comment_other">
                        <div className="other_user_comment">
                          <span>
                            <a href="#">
                              <img
                                src={singlePost?.profile_image}
                                className="inner_post"
                              />{" "}
                              {singlePost?.name}
                            </a>
                            @{singlePost?.username}{" "}
                            <p>{getTimeDifference(singlePost?.insertdate)}</p>
                          </span>
                          <br />
                          {/* <label>
                                                        {singlePost?.description}
                                                    </label> */}
                        </div>

                        <div className="tetweet_quote_content">
                          {singlePost?.media?.length > 0 ? (
                            singlePost?.media[0]?.type === "video" ? (
                              <div className="tetweet_quote_image">
                                <img
                                  src={singlePost?.media[0]?.thumb}
                                  alt="post"
                                />
                              </div>
                            ) : (
                              <div className="tetweet_quote_image">
                                <img
                                  src={singlePost?.media[0]?.name}
                                  alt="post"
                                />
                              </div>
                            )
                          ) : (
                            <></>
                          )}

                          <div className="tetweet_quote_text">
                            <p className="mb-0 gray_5a font_12">
                              {singlePost?.description}
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <div className="insert_images">
                  <label
                    style={
                      disablePhotoInputForQuotePost === true ||
                      filesForRetweetQuote?.length === 4 ||
                      selectedFileForRetweetQuote?.length === 4 ||
                      heightWidthForQuotePost?.length === 4
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      multiple
                      accept=".png, .jpeg, .jpg"
                      onChange={handleFileInputForRetweetQuotes}
                      disabled={
                        disablePhotoInputForQuotePost === true ||
                        filesForRetweetQuote?.length === 4 ||
                        selectedFileForRetweetQuote?.length === 4 ||
                        heightWidthForQuotePost?.length === 4
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/gallery.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                  <a className="mx-4">
                    <label
                      style={
                        disableVideoInputForQuotePost === true ||
                        filesForRetweetQuote?.length === 1 ||
                        selectedFileForRetweetQuote?.length === 1 ||
                        heightWidthForQuotePost?.length === 1
                          ? {}
                          : { cursor: "pointer" }
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept=".mp4, .avi, .mov, .mkv, .wmv"
                        id="imageForPosts"
                        onChange={handleVideoUploadForRetweetWithQuotes}
                        disabled={
                          disableVideoInputForQuotePost === true ||
                          filesForRetweetQuote?.length === 1 ||
                          selectedFileForRetweetQuote?.length === 1 ||
                          heightWidthForQuotePost?.length === 1
                        }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/video-icon.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  </a>
                  {/* <a href="javascript:void(0)">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid mx-3"
                                            alt="gallery" /> 
                                    </a> */}
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmojiPickerForQuotePost(!emojiPickerForQuotePost)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid mx-3"
                      alt="gallery"
                    />
                  </a>
                </div>
                <button
                  onClick={() => {
                    uploadImagesForRetweetQuotes();
                  }}
                  style={{ cursor: "pointer" }}
                  className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Post
                </button>
              </div>
              {emojiPickerForQuotePost ? (
                <div className="mx-5 mt-0 mb-3">
                  <EmojiPicker
                    onEmojiClick={(e) => setQuotePost(quotePost + e?.emoji)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {/* Add Comment */}
        <div
          className="modal fade"
          id="commentModal"
          tabIndex={-1}
          aria-labelledby="comment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body position-relative">
                <button
                  type="button"
                  className="btn-close close_custom"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setComment("");
                    setFileForComments([]);
                    setSelectedFileForComments([]);
                    setHeightWidthForComments([]);
                  }}
                />
                <div className="comment_main">
                  <div className="user_comment">
                    <div className="comment_user_post">
                      <div className="main_comment_other border_0 comment_line">
                        <div className="other_user_comment">
                          <span>
                            <a href="#">
                              <img
                                src={singlePost?.profile_image}
                                className="inner_post"
                              />{" "}
                              {singlePost?.name}
                            </a>
                            @{singlePost?.username}
                            <p>
                              {singlePost?.insertdate ? (
                                <ReactTimeAgo
                                  date={singlePost?.insertdate}
                                  locale="en-US"
                                />
                              ) : (
                                <></>
                              )}
                            </p>
                          </span>
                        </div>
                        <div className="comment_image ms-5 mt-3 mb-3">
                          {singlePost?.post_type === "retweet" ? (
                            <>
                              <label>
                                {singlePost?.shared_feed?.description}
                              </label>

                              {singlePost?.shared_feed?.media?.length === 0 ? (
                                <></>
                              ) : (
                                <>
                                  {singlePost?.shared_feed?.media?.map(
                                    (item) => {
                                      return item?.name;
                                    }
                                  )}
                                </>
                              )}
                            </>
                          ) : singlePost?.post_type === "retweet_with_quote" ? (
                            <>
                              <label>{singlePost?.description}</label>

                              <div
                                className="tetweet_quote"
                                style={{ marginLeft: "0px" }}
                              >
                                <div className="post_person_mark">
                                  <Link to={"#"} className="post_person_name">
                                    <div className="post_person_image">
                                      <img
                                        src={
                                          singlePost?.shared_feed?.profile_image
                                        }
                                        className="img-fluid"
                                        alt="profile-pic"
                                      />
                                    </div>
                                    <div className="post_person_text">
                                      <h6 className="font_12 font_600 mb-0">
                                        {singlePost?.shared_feed?.name}
                                      </h6>
                                      <span className="light_gray_8b font_12 inter">
                                        @{singlePost?.shared_feed?.username}
                                      </span>
                                    </div>
                                  </Link>
                                  <div className="post_person_date">
                                    <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                      {singlePost?.shared_feed?.insertdate ? (
                                        <ReactTimeAgo
                                          date={
                                            singlePost?.shared_feed?.insertdate
                                          }
                                          locale="en-US"
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className="tetweet_quote_content">
                                  {singlePost?.shared_feed?.media?.length ===
                                  0 ? (
                                    <></>
                                  ) : (
                                    <div className="tetweet_quote_image"></div>
                                  )}
                                  {singlePost?.module_type === "news" ? (
                                    <div className="tetweet_quote_text">
                                      <p
                                        className="mb-0 gray_5a font_12"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            singlePost?.shared_feed
                                              ?.description,
                                        }}
                                      ></p>
                                    </div>
                                  ) : (
                                    <div className="tetweet_quote_text">
                                      <p className="mb-0 gray_5a font_12">
                                        {singlePost?.shared_feed?.description}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : singlePost?.module_type === "news" ? (
                            <label
                              dangerouslySetInnerHTML={{
                                __html: singlePost?.description,
                              }}
                            ></label>
                          ) : (
                            <label>{singlePost?.description}</label>
                          )}

                          <br />
                          {singlePost && singlePost?.media?.length === 0 ? (
                            <></>
                          ) : (
                            <label>
                              {singlePost?.media?.map((item) => {
                                return item?.name;
                              })}
                            </label>
                          )}
                          {/* <div className="commnt_replyr mt-2 mb-2">
                                                        <span className="text-blue_1">
                                                            <a href="#">Commenting to</a>{" "} @{singlePost?.username}
                                                        </span>
                                                    </div> */}
                        </div>
                        <div className="other_user_comment d-flex">
                          <span>
                            <a href="#">
                              <img
                                src={localStorage?.getItem("profile_image")}
                                className="inner_post"
                              />
                            </a>
                          </span>
                          <input
                            type="text"
                            name=""
                            disabled={filesForComments?.length}
                            value={comment}
                            onChange={(e) => {
                              setComment(e?.target?.value);
                              setEmojiPickerForComments(false);
                            }}
                            placeholder={
                              filesForComments?.length
                                ? ""
                                : "Post your comment..!!"
                            }
                          />
                        </div>
                        <div className="uploade_images">
                          {filesForComments?.length !== 0 ? (
                            filesForComments?.map((item, key) => {
                              switch (item.type.split("/")[0]) {
                                case "image": {
                                  console.log("Line 838", item);
                                  return (
                                    <div className="images_tab_1">
                                      <img src={URL.createObjectURL(item)} />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/x-circle.svg"
                                        }
                                        className="cross-icon"
                                        onClick={() =>
                                          removeImageForComments(key)
                                        }
                                      />
                                    </div>
                                  );
                                }
                                case "video": {
                                  return (
                                    <div className="images_tab_1">
                                      <video
                                        controls
                                        muted
                                        loop
                                        style={{ height: "200px" }}
                                      >
                                        <source
                                          src={URL.createObjectURL(item)}
                                        />
                                      </video>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/cross.svg"
                                        }
                                        className="cross-icon"
                                        onClick={() =>
                                          removeImageForComments(key)
                                        }
                                      />
                                    </div>
                                  );
                                }
                              }
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="insert_images align-items-center d-flex m-4">
                {!comment?.length && (
                  <label
                    style={
                      disablePhotoInputForComments === true ||
                      filesForComments?.length === 4 ||
                      selectedFileForComments?.length === 4 ||
                      heightWidthForComments?.length === 4
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".png, .jpeg, .jpg"
                      id="imageForPosts"
                      multiple
                      onChange={handleFileInputForComments}
                      disabled={
                        disablePhotoInputForComments === true ||
                        filesForComments?.length === 4 ||
                        selectedFileForComments?.length === 4 ||
                        heightWidthForComments?.length === 4
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/gallery.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                )}
                {!filesForComments?.length && (
                  <div
                    className="ms-2"
                    // onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
                  >
                    <label style={{ cursor: "pointer" }}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/emoji.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  </div>
                )}

                <div className="sania_btn_2 ms-auto">
                  <button
                    disabled={!comment && selectedFileForComments?.length < 0}
                    onClick={() => {
                      uploadImagesForComments();
                    }}
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    className="blue_button d-flex align-items-center font_16 font_500"
                  >
                    COMMENT
                  </button>
                </div>
              </div>
              {/* <div className="modal-footer d-flex justify-content-between">
                <ul className="post_option">
                  <li>
                    <label
                      style={
                        disablePhotoInputForComments === true ||
                        filesForComments?.length === 4 ||
                        selectedFileForComments?.length === 4 ||
                        heightWidthForComments?.length === 4
                          ? {}
                          : { cursor: "pointer" }
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept=".png, .jpeg, .jpg"
                        id="imageForPosts"
                        multiple
                        onChange={handleFileInputForComments}
                        disabled={
                          disablePhotoInputForComments === true ||
                          filesForComments?.length === 4 ||
                          selectedFileForComments?.length === 4 ||
                          heightWidthForComments?.length === 4
                        }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/gallery.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  </li>
                  <li>
                    <a className="mx-3">
                      <label
                        style={
                          disableVideoInputForComments === true ||
                          filesForComments?.length === 1 ||
                          selectedFileForComments?.length === 1 ||
                          heightWidthForComments?.length === 1
                            ? {}
                            : { cursor: "pointer" }
                        }
                      >
                        <input
                          type="file"
                          style={{ display: "none" }}
                          accept=".mp4, .avi, .mov, .mkv, .wmv"
                          id="imageForPosts"
                          onChange={handleVideoUploadForComments}
                          disabled={
                            disableVideoInputForComments === true ||
                            filesForComments?.length === 1 ||
                            selectedFileForComments?.length === 1 ||
                            heightWidthForComments?.length === 1
                          }
                        />
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/video-icon.svg"
                          }
                          className="img-fluid"
                          alt="gallery"
                        />
                      </label>
                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setEmojiPickerForComments(!emojiPickerForComments)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/emoji.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </a>
                  </li>
                </ul>
                <button
                  disabled={!comment && selectedFileForComments?.length < 0}
                  onClick={() => {
                    uploadImagesForComments();
                  }}
                  className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Comment
                </button>
              </div> */}
              {emojiPickerForComments ? (
                <div className="mx-5 mt-0 mb-3">
                  <EmojiPicker
                    onEmojiClick={(e) => setComment(comment + e?.emoji)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>

        {/* My Linking in list */}
        <MyLinkingIn
          myLinkingInModal={myLinkingInModal}
          setMyLinkingInModal={setMyLinkingInModal}
          myLinkingInList={myLinkingInList}
          removeFollower={removeFollower}
          pageTokenMyLinkingIn={pageTokenMyLinkingIn}
          setPageTokenMyLinkingIn={setPageTokenMyLinkingIn}
          isFetchingMyLinkingIn={isFetchingMyLinkingIn}
        />
        {/* My Linking out list */}

        <MyLinkinOut
          myLinkingOutModal={myLinkingOutModal}
          setMyLinkingOutModal={setMyLinkingOutModal}
          myLinkingOutList={myLinkingOutList}
          removeFollow={removeFollow}
          actionFriend={actionFriend}
          addFriend={addFriend}
          pageTokenMyLinkingOut={pageTokenMyLinkingOut}
          isFetchingMyLinkingOut={isFetchingMyLinkingOut}
          setPageTokenMyLinkingOut={setPageTokenMyLinkingOut}
        />
        {/* Other Linking in list */}

        <OtherLinkingIn
          linkingInModal={linkingInModal}
          setLinkingInModal={setLinkingInModal}
          othersLinkingInList={othersLinkingInList}
          actionFriend={actionFriend}
          // removeFriend={removeFriend}
          removeFollow={removeFollow}
          addFriend={addFriend}
          isFetchingOtherLinkingIn={isFetchingOtherLinkingIn}
          setIsFetchingOtherLinkingIn={setIsFetchingOtherLinkingIn}
          setPageTokenOtherLinkingIn={setPageTokenOtherLinkingIn}
        />

        {/* Other Linking out list */}

        <OtherLinkingOut
          linkingOutModal={linkingOutModal}
          setLinkingOutModal={setLinkingOutModal}
          othersLinkingOutList={othersLinkingOutList}
          actionFriend={actionFriend}
          // removeFriend={removeFriend}
          setPageTokenOtherLinkingOut={setPageTokenOtherLinkingOut}
          pageTokenOtherLinkingOut={pageTokenOtherLinkingOut}
          removeFollow={removeFollow}
          addFriend={addFriend}
          isFetchingOtherLinkingOut={isFetchingOtherLinkingOut}
          setIsFetchingOtherLinkingOut={setIsFetchingOtherLinkingOut}
          otherLinkingOutList={otherLinkingOutList}
        />

        {/* Mutual Links */}

        <MutualLinks
          removeFollower={removeFollower}
          mutualLinkingInModal={mutualLinkingInModal}
          setMutualLinkingInModal={setMutualLinkingInModal}
          mutualFriends={mutualFriends}
          setPageTokenMutualLinks={setPageTokenMutualLinks}
          pageTokenMutualLinks={pageTokenMutualLinks}
          isFetchingMutualLinks={isFetchingMutualLinks}
          actionFriend={actionFriend}
          // removeFriend={removeFriend}
          removeFollow={removeFollow}
          addFriend={addFriend}
        />

        {/* Delete Post */}
        {/* <Modal
          open={postPermission}
          onClose={() => {
            setPostPermission(false);
          }}
          aria-labelledby="modal-modal-title"
          aria-describedby="modal-modal-description"
        >
          <Box sx={customStyles}>
            <ul
              className="requested_user_ul"
              aria-labelledby="dropdownMenuButton1"
            >
              <li>
                <div
                  style={{ cursor: "pointer" }}
                  onClick={() => {
                    console.log(singlePost);
                    deletePost(singlePost._id);
                  }}
                >
                  {" "}
                  Delete Post{" "}
                </div>
              </li>
            </ul>
          </Box>
        </Modal> */}

        <PostMenu
          postPermission={postPermission}
          setPostPermission={setPostPermission}
          singlePost={singlePost}
          feeds={isOwnProfile ? posts : othersPosts}
          setFeeds={isOwnProfile ? setPosts : setOthersPosts}
        />

        <ShareLink
          shareModalOpen={shareModalOpen}
          setShareModalOpen={setShareModalOpen}
        />

        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <div className="user_profile">
                {!isOwnProfile ? (
                  <div className="personal_profile">
                    <div className="personal_profile_image">
                      <img
                        src={
                          otherUserDetails?.profile_image ||
                          "https://stulink.s3.ap-south-1.amazonaws.com/stulink/user_image/default.jpeg"
                        }
                        className="img-fluid"
                        alt="image"
                      />
                    </div>
                    <div className="personal_profile_text">
                      <div className="d-flex justify-content-between  align-items-center">
                        <div>
                          <h3 className="font_25 font_600 line_height_30px">
                            {" "}
                            {otherUserDetails?.name}{" "}
                          </h3>
                          <span className="light_gray_8b font_15 font_400">
                            @{otherUserDetails?.username}
                          </span>
                        </div>
                        <div>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/3-dots-horizontal.svg"
                            }
                            style={{ width: "16px" }}
                            className="cursor-pointer img-fluid"
                            onClick={() => {
                              setOhterUserAction(true);
                              setSinglePost(otherUserDetails);
                            }}
                            alt="college"
                          />
                        </div>
                      </div>
                      <div className="james_connection">
                        <div className="james_connection_user">
                          <div
                            className="james_linked_1 james_linked text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                (otherUserDetails?.account_type === "private" &&
                                  otherUserDetails?.has_friend !== 1) ||
                                otherUserDetails?.linking_in_count === 0
                              ) {
                                otherUserDetails?.linking_in_count &&
                                  setLinkingInModal(false);
                              } else {
                                otherUserDetails?.linking_in_count &&
                                  setLinkingInModal(true);
                                // linkingMain();
                              }
                            }}
                            data-bs-target="#other_friend_linkin"
                            data-bs-toggle="modal"
                          >
                            <p className="font_16 gray_5a">
                              {otherUserDetails?.linking_in_count}
                            </p>
                            <span className="inter">Linking in</span>
                          </div>
                          <div
                            className=" james_linked text-center"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              if (
                                (otherUserDetails?.account_type === "private" &&
                                  otherUserDetails?.has_friend !== 1) ||
                                otherUserDetails?.linking_out_count === 0
                              ) {
                                otherUserDetails?.linking_out_count &&
                                  setLinkingOutModal(false);
                              } else {
                                otherUserDetails?.linking_out_count &&
                                  setLinkingOutModal(true);
                                // linkOutMain();
                              }
                            }}
                            data-bs-target="#other_friend_list"
                            data-bs-toggle="modal"
                          >
                            <p className="font_16 gray_5a">
                              {otherUserDetails?.linking_out_count}
                            </p>
                            <span className="inter">Linking out</span>
                          </div>

                          <div
                            className="james_linked_1 james_linked text-center"
                            style={{ cursor: "pointer", marginRight: "0" }}
                            onClick={() => {
                              if (otherUserDetails?.mutual_count === 0) {
                                setMutualLinkingInModal(false);
                              } else {
                                setMutualLinkingInModal(true);
                                // mutualLinks();
                              }
                            }}
                            data-bs-target="#mutual_links"
                            data-bs-toggle="modal"
                          >
                            <p className="font_16 gray_5a ">
                              {otherUserDetails?.mutual_count}
                            </p>
                            <span className="inter">Mutual Links</span>
                          </div>
                        </div>
                        <div className="james_connection_button">
                          <div
                            className="edit_p_button"
                            style={{ cursor: "pointer" }}
                            onClick={() => {
                              otherUserDetails?.has_friend === 0
                                ? actionFriend(
                                    "reject",
                                    otherUserDetails?.action_id,
                                    otherUserDetails?._id
                                  )
                                : otherUserDetails?.has_friend === 1
                                ? removeFollow({ friend_id: accountId })
                                : otherUserDetails?.has_friend === 2
                                ? blockUser(accountId, "unblock")
                                : addFriend({ friend_id: accountId });
                            }}
                          >
                            {" "}
                            <img
                              src={`${
                                otherUserDetails?.account_type === "private" &&
                                otherUserDetails?.has_friend === 0
                                  ? process.env.PUBLIC_URL +
                                    "/assets/images/icons/request.svg"
                                  : otherUserDetails?.has_friend === 1
                                  ? "/assets/images/icons/linked.svg"
                                  : otherUserDetails?.has_friend === 3
                                  ? "/assets/images/icons/request.svg"
                                  : "/assets/images/icons/request.svg"
                              }`}
                              className="img-fluid me-2 request_button_custom"
                              alt="edit"
                            />
                            {otherUserDetails?.account_type === "private" &&
                            otherUserDetails?.has_friend === 0
                              ? "Requested"
                              : otherUserDetails?.has_friend === 1
                              ? "Linked"
                              : otherUserDetails?.has_friend === 2
                              ? "Blocked"
                              : otherUserDetails?.has_friend === 3
                              ? "Link"
                              : ""}
                          </div>
                          <div
                            className="edit_p_button message_btn_cstm"
                            // style={{
                            //   backgroundColor: "#5C5C5C",
                            //   cursor: "pointer",
                            // }}
                            onClick={() => {
                              openChat(accountId);
                            }}
                          >
                            {" "}
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/Messages.svg"
                              }
                              className="img-fluid mx-2"
                              alt="edit"
                            />
                            DM
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                ) : (
                  <div className="personal_profile">
                    <div className="personal_profile_image">
                      <img
                        src={
                          userDetails?.profile_image ||
                          "https://stulink.s3.ap-south-1.amazonaws.com/stulink/user_image/default.jpeg"
                        }
                        className="img-fluid"
                        alt="image"
                      />
                    </div>
                    <div className="personal_profile_text">
                      <div>
                        <h3 className="font_25 font_600 line_height_30px">
                          {" "}
                          {userDetails?.name}{" "}
                        </h3>
                        <p className="light_gray_8b font_15 font_400">
                          @{userDetails?.username}
                        </p>
                      </div>
                      <div className="james_connection d-flex align-items-center">
                        <div
                          className="james_linked_1 james_linked text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            userDetails?.linking_out_count && linkingMain();
                          }}
                          data-bs-target="#friend_list"
                          data-bs-toggle="modal"
                        >
                          <p className="font_16 gray_5a mb-0">
                            {userDetails?.linking_in_count}
                          </p>
                          <span className="inter">Linking in</span>
                        </div>
                        <div
                          className=" james_linked text-center"
                          style={{ cursor: "pointer" }}
                          onClick={() => {
                            if (isOwnProfile) {
                              userDetails?.linking_out_count &&
                                setMyLinkingOutModal(true);
                            } else {
                              setLinkingOutModal(true);
                            }
                          }}
                          data-bs-target="#friend_link_out"
                          data-bs-toggle="modal"
                        >
                          <p className="font_16 gray_5a mb-0">
                            {userDetails?.linking_out_count}
                          </p>
                          <span className="inter">Linking out</span>
                        </div>
                        <div className="edit_profile_btn">
                          {" "}
                          <Link to="/edit-profile" className="edit_p_button">
                            {" "}
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/noun-edit.svg"
                              }
                              className="img-fluid"
                              alt="edit"
                            />{" "}
                            Edit Profile
                          </Link>{" "}
                        </div>
                      </div>
                    </div>
                  </div>
                )}
              </div>
              <div className="row">
                <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                  <div className="about_profile">
                    <div className="about_profile_heading text-center">
                      <h4 className="font_22 font_600 mb-0">
                        About <span className="blue_29">Profile</span>
                      </h4>
                    </div>
                    <div className="about_profile_content">
                      <div className="about_profile_text">
                        {isOwnProfile ? (
                          userDetails?.college_uni_name === "" &&
                          !localStorage.getItem("college") ? (
                            <></>
                          ) : userDetails?.college_uni_name === "" ? (
                            <p className="font_12 gray_5a inter d-flex align-items-start">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/req_uni.svg"
                                }
                                className="img-fluid me-2 mt-1"
                                alt="college"
                              />
                              {localStorage.getItem("college")}{" "}
                            </p>
                          ) : (
                            <p className="font_12 gray_5a inter d-flex align-items-start">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/req_uni.svg"
                                }
                                className="img-fluid me-2 mt-1"
                                alt="college"
                              />
                              {userDetails?.college_uni_name}{" "}
                            </p>
                          )
                        ) : otherUserDetails?.college_uni_name === "" &&
                          !localStorage.getItem("college") ? (
                          <></>
                        ) : otherUserDetails?.college_uni_name === "" ? (
                          ""
                        ) : (
                          <p className="font_12 gray_5a inter d-flex align-items-start">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/req_uni.svg"
                              }
                              className="img-fluid me-2 mt-1"
                              alt="college"
                            />
                            {otherUserDetails?.college_uni_name !== ""
                              ? otherUserDetails?.college_uni_name
                              : localStorage.getItem("college")}{" "}
                          </p>
                        )}
                        {isOwnProfile ? (
                          userDetails?.dob ===
                            "Invalid Date Invalid Date NaN" ||
                          userDetails?.dob ===
                            "Invalid Date Invalid Date Invalid Date" ||
                          userDetails?.dob === "" ||
                          userDetails?.dob === "NaN Invalid Date NaN" ? (
                            <></>
                          ) : (
                            <p className="font_12 gray_5a inter d-flex align-items-center">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/gift-small.svg"
                                }
                                className="img-fluid me-2"
                                alt="college"
                              />
                              {userDetails?.dob}{" "}
                            </p>
                          )
                        ) : otherUserDetails?.dob ===
                            "Invalid Date Invalid Date NaN" ||
                          otherUserDetails?.dob ===
                            "Invalid Date Invalid Date Invalid Date" ||
                          otherUserDetails?.dob === "" ||
                          otherUserDetails?.dob === "NaN Invalid Date NaN" ? (
                          <></>
                        ) : (
                          <p className="font_12 gray_5a inter d-flex align-items-center">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/gift-small.svg"
                              }
                              className="img-fluid me-2"
                              alt="college"
                            />
                            {otherUserDetails?.dob}{" "}
                          </p>
                        )}
                        {isOwnProfile ? (
                          userDetails.address === "" ? (
                            ""
                          ) : (
                            <p className="font_12 gray_5a inter d-flex align-items-start">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/location-small.svg"
                                }
                                className="img-fluid me-2 mt-1"
                                alt="college"
                              />
                              {showFullLocation
                                ? userDetails?.address
                                : userDetails?.address?.slice(0, 20)}
                              <span
                                style={{ cursor: "pointer" }}
                                onClick={() => {
                                  setShowFullLocation(!showFullLocation);
                                }}
                              >
                                {showFullLocation ? "" : "..."}
                              </span>
                            </p>
                          )
                        ) : otherUserDetails.address === "" ? (
                          ""
                        ) : (
                          <p className="font_12 gray_5a inter d-flex align-items-start">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/location-small.svg"
                              }
                              className="img-fluid me-2 mt-1"
                              alt="college"
                            />
                            {showFullLocation
                              ? otherUserDetails?.address
                              : otherUserDetails?.address?.slice(0, 20)}
                            <span
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                setShowFullLocation(!showFullLocation);
                              }}
                            >
                              {showFullLocation ? "" : "..."}
                            </span>
                          </p>
                        )}
                      </div>
                      {isOwnProfile ? (
                        userDetails?.aboutme === "" ||
                        userDetails?.aboutme === undefined ||
                        userDetails?.aboutme === null ? (
                          ""
                        ) : (
                          <div className="about_profile_text">
                            <h6 className="blue_29 font_15 font_600">Bio</h6>
                            <p className="inter font_12 light_gray_8b">
                              {userDetails?.aboutme?.length > 100
                                ? userDetails?.aboutme?.slice(0, 100) + "..."
                                : userDetails?.aboutme}
                            </p>
                          </div>
                        )
                      ) : otherUserDetails.aboutme === "" ||
                        otherUserDetails.aboutme === undefined ||
                        otherUserDetails.aboutme === null ? (
                        ""
                      ) : (
                        <div className="about_profile_text">
                          <h6 className="blue_29 font_15 font_600">Bio</h6>
                          <p className="inter font_12 light_gray_8b">
                            {otherUserDetails?.aboutme?.length > 100
                              ? otherUserDetails?.aboutme?.slice(0, 100) + "..."
                              : otherUserDetails?.aboutme}
                          </p>
                        </div>
                      )}
                      {isOwnProfile ? (
                        <div className="">
                          <p
                            className="mb-0 inter font_500 font_12 gray_5a  user-email-p"
                            style={{ wordBreak: "break-all" }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/sending-mail.svg"
                              }
                              className="img-fluid me-2"
                              alt="sent"
                            />
                            {userDetails?.name}
                          </p>
                          <p
                            className="mb-0 inter font_500 font_12 gray_5a"
                            style={{ wordBreak: "break-all" }}
                          >
                            {userDetails?.email}
                          </p>
                        </div>
                      ) : (
                        <div className="">
                          <p
                            className="mb-0 inter font_500 font_12 gray_5a"
                            style={{ wordBreak: "break-all" }}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/sending-mail.svg"
                              }
                              className="img-fluid me-2"
                              alt="sent"
                            />
                            {otherUserDetails?.name}
                          </p>
                          <p
                            className="mb-0 inter font_500 font_12 gray_5a"
                            style={{ wordBreak: "break-all" }}
                          >
                            {otherUserDetails?.email}
                          </p>
                        </div>
                      )}
                    </div>
                  </div>

                  {isOwnProfile ? (
                    myLinkingInList?.length > 0 ? (
                      <div className="about_profile">
                        <div className="about_profile_heading">
                          <h4 className="font_15 font_600 mb-0 blue_29">
                            Friends
                          </h4>
                        </div>
                        {myLinkingInList?.slice(0, 3)?.map((item) => {
                          return (
                            <>
                              <div
                                className="mb-3 chandi_admission align-items-center"
                                style={{ cursor: "pointer" }}
                                key={item?._id}
                                onClick={() => {
                                  //   navigate(`/profile/${item?.user_id}`);
                                  navigate("/profile", {
                                    state: item?.user_id,
                                  });
                                  setAccountId(item?.user_id);
                                }}
                              >
                                <div className="james_profile uni_profile_image">
                                  <img
                                    src={item?.profile_image}
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <span>
                                  {item?.name.length < 9
                                    ? item?.name
                                    : item?.name.slice(0, 9) + "..."}
                                </span>
                              </div>
                            </>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )
                  ) : othersLinkingInList?.length > 0 ? (
                    <div className="about_profile">
                      <div className="about_profile_heading">
                        <h4 className="font_15 font_600 mb-0 blue_29">
                          Friends
                        </h4>
                      </div>
                      {othersLinkingInList?.slice(0, 3)?.map((item) => {
                        return (
                          <>
                            <div
                              className="mb-3 chandi_admission align-items-center cursor_pointer"
                              key={item?._id}
                              onClick={() => {
                                // navigate(`/profile/${item?.user_id}`);
                                navigate("/profile", {
                                  state: item?.user_id,
                                });
                                setAccountId(item?.user_id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="james_profile uni_profile_image">
                                <img
                                  src={item?.profile_image}
                                  className="img-fluid"
                                  alt="image"
                                />
                              </div>
                              <span>
                                {item?.name.length < 9
                                  ? item?.name
                                  : item?.name.slice(0, 9) + "..."}
                              </span>
                            </div>
                          </>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}

                  {isOwnProfile ? (
                    followedTopics?.length > 0 ? (
                      <div className="about_profile">
                        <div className="about_profile_heading">
                          <h4 className="font_15 font_600 mb-0 blue_29">
                            Topic Followed
                          </h4>
                        </div>
                        {followedTopics.map((item, key) => {
                          return (
                            <div
                              className="mb-3 chandi_admission align-items-center"
                              key={key}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/right.svg"
                                }
                                className="img-fluid me-2"
                                alt="icon"
                              />
                              <span className="font_12">{item.name}</span>
                            </div>
                          );
                        })}
                      </div>
                    ) : (
                      <></>
                    )
                  ) : otherFollowedTopics?.length > 0 ? (
                    <div className="about_profile">
                      <div className="about_profile_heading">
                        <h4 className="font_15 font_600 mb-0 blue_29">
                          Topic Followed
                        </h4>
                      </div>
                      {otherFollowedTopics.map((item, key) => {
                        return (
                          <div
                            className="mb-3 chandi_admission align-items-center"
                            key={key}
                          >
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/right.svg"
                              }
                              className="img-fluid me-2"
                              alt="icon"
                            />
                            <span className="font_12">{item.name}</span>
                          </div>
                        );
                      })}
                    </div>
                  ) : (
                    <></>
                  )}
                </div>
                <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                  <div className="profile_main_tab">
                    <ul className="nav nav-tabs" id="myTab" role="tablist">
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link inter font_15 active"
                          id="media-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#media"
                          type="button"
                          role="tab"
                          aria-controls="media"
                          aria-selected="true"
                        >
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/media.svg"
                            }
                            className="img-fluid me-1"
                            alt="icon"
                          />{" "}
                          Media
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link inter font_15 "
                          id="post-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#post"
                          type="button"
                          role="tab"
                          aria-controls="post"
                          aria-selected="false"
                        >
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/post.svg"
                            }
                            className="img-fluid me-1"
                            alt="icon"
                          />{" "}
                          Post
                        </button>
                      </li>
                      {/* <li className="nav-item" role="presentation">
                                                <button className="nav-link inter font_15" id="activity-tab" data-bs-toggle="tab"
                                                    data-bs-target="#activity" type="button" role="tab"
                                                    aria-controls="activity" aria-selected="false"> <img src={process.env.PUBLIC_URL+"/assets/images/icons/activity.svg"} className="img-fluid me-1" alt="icon" /> Activity</button>
                                            </li> */}
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link inter font_15"
                          id="reels-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#reels"
                          type="button"
                          role="tab"
                          aria-controls="reels"
                          aria-selected="false"
                        >
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/reels.svg"
                            }
                            className="img-fluid me-1"
                            alt="icon"
                          />{" "}
                          Reels
                        </button>
                      </li>
                      <li className="nav-item" role="presentation">
                        <button
                          className="nav-link inter font_15"
                          id="documents-tab"
                          data-bs-toggle="tab"
                          data-bs-target="#documents"
                          type="button"
                          role="tab"
                          aria-controls="documents"
                          aria-selected="false"
                        >
                          {" "}
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/document.svg"
                            }
                            className="img-fluid me-1"
                            alt="icon"
                          />{" "}
                          Documents
                        </button>
                      </li>
                    </ul>
                    <div className="tab-content" id="myTabContent">
                      <div
                        className="tab-pane fade "
                        id="post"
                        role="tabpanel"
                        aria-labelledby="post-tab"
                      >
                        {console.log(
                          "Line 1739",
                          isOwnProfile ? posts?.length : othersPosts?.length
                        )}
                        <InfiniteScroll
                          dataLength={
                            isOwnProfile ? posts?.length : othersPosts?.length
                          }
                          next={() => {
                            setPageToken(pageToken + 1);
                          }}
                          hasMore={isFetching}
                        >
                          {isOwnProfile ? (
                            posts?.length > 0 ? (
                              posts?.map((item, key) => {
                                return (
                                  <>
                                    {item.post_type == "normal" ? (
                                      <NormalPostType
                                        retweet={retweet}
                                        undoRetweet={undoRetweet}
                                        singlePost={singlePost}
                                        setSinglePost={setSinglePost}
                                        setPostPermission={setPostPermission}
                                        likeUnlike={likeUnlike}
                                        handleShare={handleShare}
                                        handleSavePost={handleSavePost}
                                        item={item}
                                        key={key}
                                      />
                                    ) : item.post_type == "retweet" ||
                                      item.post_type == "retweet_comments" ? (
                                      <RetweetPostType
                                        retweet={retweet}
                                        undoRetweet={undoRetweet}
                                        setPostPermission={setPostPermission}
                                        singlePost={singlePost}
                                        setSinglePost={setSinglePost}
                                        likeUnlike={likeUnlike}
                                        handleShare={handleShare}
                                        handleSavePost={handleSavePost}
                                        item={item}
                                        key={key}
                                      />
                                    ) : item.post_type ==
                                        "retweet_with_quote" ||
                                      item.post_type ==
                                        "retweet_comments_with_quote" ? (
                                      <RetweetWithQuote
                                        retweet={retweet}
                                        undoRetweet={undoRetweet}
                                        setPostPermission={setPostPermission}
                                        singlePost={singlePost}
                                        setSinglePost={setSinglePost}
                                        likeUnlike={likeUnlike}
                                        handleShare={handleShare}
                                        handleSavePost={handleSavePost}
                                        item={item}
                                        key={key}
                                      />
                                    ) : item.post_type == "reels" ? (
                                      <ReelsPostType
                                        retweet={retweet}
                                        undoRetweet={undoRetweet}
                                        setPostPermission={setPostPermission}
                                        singlePost={singlePost}
                                        setSinglePost={setSinglePost}
                                        likeUnlike={likeUnlike}
                                        handleShare={handleShare}
                                        handleSavePost={handleSavePost}
                                        item={item}
                                        key={key}
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </>
                                );
                              })
                            ) : (
                              <h6>No post uploaded</h6>
                            )
                          ) : otherUserDetails?.account_type === "private" &&
                            otherUserDetails?.has_friend !== 1 ? (
                            <h6>
                              This account is private. Linking In to show their
                              posts, media, documents and reels
                            </h6>
                          ) : othersPosts?.length > 0 ? (
                            othersPosts?.map((item, key) => {
                              console.log(
                                "item: ",
                                item.like_count_show,
                                item.description
                              );
                              return (
                                <>
                                  {item.post_type == "normal" ? (
                                    <NormalPostType
                                      retweet={retweet}
                                      undoRetweet={undoRetweet}
                                      singlePost={singlePost}
                                      setSinglePost={setSinglePost}
                                      setPostPermission={setPostPermission}
                                      likeUnlike={likeUnlike}
                                      handleShare={handleShare}
                                      handleSavePost={handleSavePost}
                                      item={item}
                                      key={key}
                                    />
                                  ) : item.post_type == "retweet" ||
                                    item.post_type == "retweet_comments" ? (
                                    <RetweetPostType
                                      retweet={retweet}
                                      undoRetweet={undoRetweet}
                                      setPostPermission={setPostPermission}
                                      singlePost={singlePost}
                                      setSinglePost={setSinglePost}
                                      likeUnlike={likeUnlike}
                                      handleShare={handleShare}
                                      handleSavePost={handleSavePost}
                                      item={item}
                                      key={key}
                                    />
                                  ) : item.post_type == "retweet_with_quote" ||
                                    item.post_type ==
                                      "retweet_comments_with_quote" ? (
                                    <RetweetWithQuote
                                      retweet={retweet}
                                      undoRetweet={undoRetweet}
                                      setPostPermission={setPostPermission}
                                      singlePost={singlePost}
                                      setSinglePost={setSinglePost}
                                      likeUnlike={likeUnlike}
                                      handleShare={handleShare}
                                      handleSavePost={handleSavePost}
                                      item={item}
                                      key={key}
                                    />
                                  ) : item.post_type == "reels" ? (
                                    <ReelsPostType
                                      retweet={retweet}
                                      undoRetweet={undoRetweet}
                                      setPostPermission={setPostPermission}
                                      singlePost={singlePost}
                                      setSinglePost={setSinglePost}
                                      likeUnlike={likeUnlike}
                                      handleShare={handleShare}
                                      handleSavePost={handleSavePost}
                                      item={item}
                                      key={key}
                                    />
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })
                          ) : (
                            <h6>No post uploaded</h6>
                          )}
                        </InfiniteScroll>
                      </div>
                      {/* <div className="tab-pane fade" id="activity" role="tabpanel"
                                                aria-labelledby="activity-tab">...</div> */}
                      <div
                        className="tab-pane fade show"
                        id="documents"
                        role="tabpanel"
                        aria-labelledby="documents-tab"
                      >
                        <div className="about_profile">
                          {isOwnProfile ? (
                            <>
                              <div className="mb-4 d-flex align-items-center justify-content-between">
                                <h5 className="font_20 blue_29 mb-0">Media</h5>
                                <Link
                                  to="/upload-document"
                                  state={accountId}
                                  className="edit_p_button"
                                >
                                  {" "}
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/upload.svg"
                                    }
                                    className="img-fluid"
                                    alt="edit"
                                  />{" "}
                                  Upload
                                </Link>
                              </div>
                              <div className="row">
                                {myDoc?.length > 0 ? (
                                  myDoc?.map((item) => {
                                    return (
                                      <>
                                        <div className="col-6 col-xl-4 mb-4">
                                          {item?.media?.map((i) => {
                                            return (
                                              <>
                                                <a
                                                  onClick={() =>
                                                    navigate(
                                                      `/post/${item?._id}`
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={i?.thumb}
                                                    className="w-100 document_image mb-4 img-fluid"
                                                    alt="document"
                                                    key={i?._id}
                                                    style={{
                                                      height: "250px",
                                                      width: "150px",
                                                    }}
                                                  />
                                                </a>
                                              </>
                                            );
                                          })}
                                          <p className="mb-0 gray_5a font_16 mt-2">
                                            {item?.doc_title?.length < 20
                                              ? item?.doc_title
                                              : item?.doc_title?.slice(0, 20) +
                                                "..."}
                                          </p>
                                          <span className="light_gray_8b">
                                            {item?.doc_degree}{" "}
                                            {item?.doc_session}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <h6>No document uploaded</h6>
                                )}
                              </div>
                            </>
                          ) : otherUserDetails?.account_type === "private" &&
                            otherUserDetails?.has_friend !== 1 ? (
                            <h6>
                              This account is private. Linking In to show their
                              posts, media, documents and reels
                            </h6>
                          ) : (
                            <>
                              <div className="mb-4 d-flex align-items-center justify-content-between">
                                <h5 className="font_20 blue_29 mb-0">Media</h5>
                              </div>
                              <div className="row">
                                {otherDoc?.length > 0 ? (
                                  otherDoc?.map((item) => {
                                    return (
                                      <>
                                        <div className="col-6 col-xl-4 mb-4">
                                          {item?.media?.map((i) => {
                                            return (
                                              <>
                                                <a
                                                  onClick={() =>
                                                    navigate(
                                                      `/post/${item?._id}`
                                                    )
                                                  }
                                                  style={{ cursor: "pointer" }}
                                                >
                                                  <img
                                                    src={i?.thumb}
                                                    className="w-100 document_image mb-4 img-fluid"
                                                    alt="document"
                                                    key={i?._id}
                                                    style={{
                                                      height: "250px",
                                                      width: "150px",
                                                    }}
                                                  />
                                                </a>
                                              </>
                                            );
                                          })}
                                          <p className="mb-0 gray_5a font_16 mt-2">
                                            {item?.doc_title?.length < 20
                                              ? item?.doc_title
                                              : item?.doc_title?.slice(0, 20) +
                                                "..."}
                                          </p>
                                          <span className="light_gray_8b">
                                            {item?.doc_degree}{" "}
                                            {item?.doc_session}
                                          </span>
                                        </div>
                                      </>
                                    );
                                  })
                                ) : (
                                  <h6> No document uploaded </h6>
                                )}
                              </div>
                            </>
                          )}
                        </div>
                      </div>
                      <div
                        className="tab-pane fade show active"
                        id="media"
                        role="tabpanel"
                        aria-labelledby="media-tab"
                      >
                        <div className="about_profile">
                          <h5 className="font_20 blue_29 mb-4">Media</h5>
                          <div className="row">
                            {isOwnProfile ? (
                              isMedia?.length > 0 ? (
                                isMedia?.map((item, index) => {
                                  console.log("{{{{{{{{{{{{{{{{{{{{>>>", item);
                                  return (
                                    <div
                                      className="col-4"
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(`/post/${item?._id}`)
                                      }
                                    >
                                      <img
                                        src={
                                          item.media[0].type == "image"
                                            ? item.media[0].name
                                            : item.media[0].thumb
                                        }
                                        className="w-100 mb-4 img-fluid"
                                        alt="image"
                                        style={{
                                          height: "200px",
                                          objectFit: "cover",
                                        }}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <h6>No media posted</h6>
                              )
                            ) : (otherUserDetails?.account_type === "private" &&
                                otherUserDetails?.has_friend === 1) ||
                              otherUserDetails?.account_type == "public" ? (
                              isOtherMedia?.length > 0 ? (
                                isOtherMedia?.map((item, index) => {
                                  return (
                                    <div
                                      className="col-4"
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(`/post/${item?._id}`)
                                      }
                                    >
                                      <img
                                        src={
                                          item.media[0].type == "image"
                                            ? item.media[0].name
                                            : item.media[0].thumb
                                        }
                                        className="w-100 mb-4 img-fluid"
                                        alt="image"
                                        style={{ height: "200px" }}
                                      />
                                    </div>
                                  );
                                })
                              ) : (
                                <h6>No media posted</h6>
                              )
                            ) : (
                              <h6>
                                Link in this account to see their posts, media,
                                documents and reels
                              </h6>
                            )}
                          </div>
                        </div>
                      </div>
                      <div
                        className="tab-pane fade"
                        id="reels"
                        role="tabpanel"
                        aria-labelledby="reels-tab"
                      >
                        {isOwnProfile ? (
                          myReels?.length > 0 ? (
                            <InfiniteScroll
                              dataLength={myReels?.length}
                              next={() => {
                                setPageTokenReels(pageTokenReels + 1);
                              }}
                              hasMore={isFetchingReels}
                            >
                              <div className="row w-100">
                                {myReels?.map((item, index) => {
                                  console.log("{{{{{{{{{{{{{{{{{{{{>>>", item);
                                  return (
                                    <div
                                      className="col-4 mb-4 position-relative "
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(`/post/${item?._id}`)
                                      }
                                    >
                                      <img
                                        src={item.media[0].thumb}
                                        className="w-100 img-fluid reel-img-height"
                                        alt=""
                                      />
                                      <p className="position-absolute reels_view_details text-white ">
                                        <img
                                          alt=""
                                          className="me-1"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/eye.svg"
                                          }
                                        />
                                        {item?.viewed}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </InfiniteScroll>
                          ) : (
                            <h6>No media posted</h6>
                          )
                        ) : (otherUserDetails?.account_type === "private" &&
                            otherUserDetails?.has_friend === 1) ||
                          otherUserDetails?.account_type == "public" ? (
                          otherReels?.length > 0 ? (
                            <InfiniteScroll
                              dataLength={otherReels?.length}
                              next={() => {
                                console.log("checkkkkkkkkkkkkkkk");
                                setPageTokenReels(pageTokenReels + 1);
                              }}
                              hasMore={isFetchingReels}
                            >
                              <div className="row w-100">
                                {otherReels?.map((item, index) => {
                                  console.log("{{{{{{{{{{{{{{{{{{{{>>>", item);
                                  return (
                                    <div
                                      className="col-4 mb-4 position-relative "
                                      key={index}
                                      style={{ cursor: "pointer" }}
                                      onClick={() =>
                                        navigate(`/post/${item?._id}`)
                                      }
                                    >
                                      <img
                                        src={item.media[0].thumb}
                                        className="w-100 img-fluid reel-img-height"
                                        alt=""
                                      />
                                      <p className="position-absolute reels_view_details text-white ">
                                        <img
                                          className="me-1"
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/eye.svg"
                                          }
                                          alt=""
                                        />
                                        {item?.viewed}
                                      </p>
                                    </div>
                                  );
                                })}
                              </div>
                            </InfiniteScroll>
                          ) : (
                            <h6>No media posted</h6>
                          )
                        ) : (
                          <h6>
                            Link in this account to see their posts, media,
                            documents and reels
                          </h6>
                        )}

                        {/* {isOwnProfile ? (
                          myReels?.length > 0 ? (
                            myReels?.map((item, index) => {
                              return (
                                <>
                                  <ReelPlayer
                                    item={item}
                                    index={index}
                                    likeUnlikeFunc={likeUnlike}
                                    postDetail={postDetail}
                                    retweet={retweet}
                                    undoRetweet={undoRetweet}
                                    setSinglePost={setSinglePost}
                                    handleShare={handleShare}
                                    actionFriend={actionFriend}
                                    RemoveFriend={removeFriend}
                                    addFriend={addFriend}
                                    gridClass={
                                      "col-12 col-xl-8 col-lg-10 col-md-7 col-sm-10 mb-4"
                                    }
                                  />
                                </>
                              );
                            })
                          ) : (
                            <h6>No reels uploaded</h6>
                          )
                        ) : otherUserDetails?.account_type === "public" ||
                          (otherUserDetails?.account_type === "private" &&
                            otherUserDetails?.has_friend === 1) ? (
                          otherReels?.length > 0 ? (
                            otherReels?.map((item, index) => {
                              return (
                                <>
                                  <ReelPlayer
                                    item={item}
                                    index={index}
                                    likeUnlikeFunc={likeUnlike}
                                    postDetail={postDetail}
                                    retweet={retweet}
                                    undoRetweet={undoRetweet}
                                    setSinglePost={setSinglePost}
                                    handleShare={handleShare}
                                    actionFriend={actionFriend}
                                    RemoveFriend={removeFriend}
                                    addFriend={addFriend}
                                    gridClass={
                                      "col-12 col-xl-8 col-lg-10 col-md-7 col-sm-10 mb-4"
                                    }
                                  />
                                  
                                </>
                              );
                            })
                          ) : (
                            <h6>No reels uploaded</h6>
                          )
                        ) : (
                          <h6>
                            {" "}
                            Link in this account to see their posts, media,
                            documents and reels{" "}
                          </h6>
                        )} */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <div className="trending_admission_box mb-5">
                <div className="profile_trending trending_admission_box_heading">
                  <h6 className="font_20 font_600 mb-0">
                    Recommended <span className="blue_29">Profiles</span>
                  </h6>
                </div>
                <Recommendation />
              </div>
              <div className="trending_admission_box">
                <div className="profile_trending trending_admission_box_heading">
                  <h6 className="font_20 font_600 mb-0">
                    Trending <span className="blue_29">Topics</span>
                  </h6>
                </div>
                <TrendingTopics />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
