import React, { useCallback, useEffect, useRef, useState } from "react";
import OwlCarousel from 'react-owl-carousel';
// import 'owl.carousel/dist/assets/owl.carousel.css';
// import 'owl.carousel/dist/assets/owl.theme.default.css';
import { RangeSlider } from "rsuite";
import "rsuite/dist/rsuite.css";
import GridView from "../Views/GridView";
import ListView from "../Views/ListView";
import { Link, NavLink, useNavigate } from "react-router-dom";
// import Header from '../Header';
import HeaderProfile from "../HeaderProfile";
import {
  get_degree_list,
  filter_data,
  get_college_list,
} from "../../api/apiHandler";

export default function Colleges() {
  const selectRef = useRef(null);
  const [activeListType, setActiveListType] = useState(true);
  const [minvalue, setMinValue] = useState(0);
  console.log('minvalue: ', String(minvalue));
  const [maxvalue, setMaxValue] = useState(1000000);
  const [toggleMode, setToggleMode] = useState(false);
  const [degree, setDegrees] = useState([]);
  const [cities, setCities] = useState([]);
  const [states, setStates] = useState([]);
  const [courses, setCourse] = useState([]);
  const [stream, setStream] = useState([]);
  const [collegeCount, setCollegeCount] = useState();
  const [totalCourseCount, setTotalCourseCount] = useState();
  console.log('totalCourseCount: ', totalCourseCount);
  const [filteredState, setFilteredState] = useState([]);
  console.log(filteredState, "filteredState");
  const [filteredCity, setFilteredCity] = useState([]);
  console.log(filteredCity, "filteredCity");
  const [filteredDegree, setFilteredDegree] = useState([]);
  const [filteredCource, setFilteredCource] = useState([]);
  const [filteredStream, setFilteredStream] = useState([]);
  const [filteredTypeOfCollege, setFilteredTypeOfCollege] = useState([]);
  const [filteredProgramType, setFilteredProgramType] = useState([]);
  const [filteredCourceDuration, setFilteredCourceDuration] = useState([]);
  console.log(filteredDegree, "filteredDegree");
  const [filteredData, setFilteredData] = useState([]);
  const [colleges, setColleges] = useState([]);
  console.log('colleges: ', colleges);
  const [sortValue, setSortValue] = useState("L");
  const navigate = useNavigate();
  const [searchedCollege, setSearchedCollege] = useState("");
  // const [scrollY, setScrollY] = useState();
  var [pageToken, setPageToken] = useState(1);
  // const [sortedColleges, setSortedColleges] = useState([]);
  const [showMore, setShowMore] = useState(false);
  const [showMoreCity, setShowMoreCity] = useState(false);
  const [showMoreCource, setShowMoreCource] = useState(false);
  const [showMoreStream, setShowMoreStream] = useState(false);
  const [showMoreDegree, setShowMoreDegree] = useState(false);
  const [showMoreCourceDuration, setShowMoreCourceDuration] = useState(false);
  const typeOfCollege = [{
    _id: 1,
    name: 'Private'
  }, {
    _id: 2,
    name: "Government"
  }
  ]
  const programType = [{
    _id: 1,
    name: 'Full Time'
  }, {
    _id: 2,
    name: "Part Time"
  }, {
    _id: 3,
    name: "Distance"
  }
  ]
  const CourceDuration = [{
    _id: 1,
    name: '1 Year'
  }, {
    _id: 2,
    name: "2 Year"
  }, {
    _id: 3,
    name: "3 Year"
  }, {
    _id: 4,
    name: "4 Year"
  }, {
    _id: 5,
    name: "5 Year"
  }, {
    _id: 6,
    name: "6 Year"
  }
  ]

  // const handleChange = (e) => {
  //   if (filteredState.includes(e.target.value)) {
  //     let temp = filteredState.filter((o) => {
  //       if (o !== e.target.value) {
  //         return o;
  //       }
  //     });
  //     setFilteredState(temp);
  //   } else {
  //     var newData2 = [...filteredState, e.target.value];
  //     setFilteredState(newData2);
  //   }
  // };


  const stateHandleChange = (e) => {
    console.log(filteredState.includes(e.target.value));
    if (filteredState.includes(e.target.value)) {
      let temp = filteredState.filter((o) => {
        if (o !== e.target.value) {
          return o;
        }
      });
      setFilteredState(temp);
      // collegeList();
    } else {
      setFilteredState([...filteredState, e.target.value]);
      // collegeList();
    }
  };

  const cityHandleChange = (e) => {
    if (filteredCity.includes(e?.target?.value)) {
      setFilteredCity(filteredCity.filter((c) => c !== e?.target?.value));
      // collegeList();
    } else {
      setFilteredCity([...filteredCity, e?.target?.value]);
      // collegeList();
    }
  };

  const courceHandleChange = async (e) => {
    if (filteredCource.includes(e?._id)) {
      setFilteredCource(filteredCource.filter((c) => c !== e?._id));
      setFilteredData(filteredData.filter((c) => c !== e?.course_name));
      // collegeList();
    } else {
      setFilteredCource([...filteredCource, e?._id]);
      setFilteredData([...filteredData, e?.course_name]);
      // collegeList();
    }
  };
  const streamHandleChange = async (e) => {
    if (filteredStream.includes(e?._id)) {
      console.log('filteredStream: ', filteredStream);
      setFilteredStream(filteredStream.filter((c) => c !== e?._id));
      setFilteredData(filteredData.filter((c) => c !== e?.name));
      // collegeList();
    } else {
      setFilteredStream([...filteredStream, e?._id]);
      setFilteredData([...filteredData, e?.name]);
      // collegeList();
    }
  };

  // const tyoeOfCollegeHandleChange = (e) => {
  //   if (filteredTypeOfCollege.includes(e.name)) {
  //     let temp = filteredTypeOfCollege.filter((o) => {
  //       if (o !== e.name) {
  //         return o;
  //       }
  //     });
  //     setFilteredTypeOfCollege(temp);
  //   } else {
  //     setFilteredTypeOfCollege([...filteredTypeOfCollege, e.name]);
  //   }
  // };

  // const tyoeOfCollegeHandleChange = async (e) => {
  //   if (filteredTypeOfCollege.includes(e?._id)) {
  //     setFilteredTypeOfCollege(filteredTypeOfCollege.filter((c) => c !== e?._id));
  //     setFilteredData(filteredData.filter((c) => c !== e?.name));
  //   } else {
  //     setFilteredTypeOfCollege([...filteredTypeOfCollege, e?._id]);
  //     setFilteredData([...filteredData, e?.name]);
  //   }
  // };
  const tyoeOfCollegeHandleChange = (e) => {
    if (filteredTypeOfCollege.includes(e?.name)) {
      let temp = filteredTypeOfCollege.filter((o) => {
        if (o !== e?.name) {
          return o;
        }
      });
      setFilteredTypeOfCollege(temp);
      // collegeList();
    } else {
      setFilteredTypeOfCollege([...filteredTypeOfCollege, e?.name]);
      // collegeList();
    }
  };
  // const programTypeHandleChange = async (e) => {
  //   if (filteredProgramType.includes(e?._id)) {
  //     setFilteredProgramType(filteredProgramType.filter((c) => c !== e?._id));
  //     setFilteredData(filteredData.filter((c) => c !== e?.name));
  //   } else {
  //     setFilteredProgramType([...filteredProgramType, e?._id]);
  //     setFilteredData([...filteredData, e?.name]);
  //   }
  // };
  const programTypeHandleChange = (e) => {
    if (filteredProgramType.includes(e?.name)) {
      let temp = filteredProgramType.filter((o) => {
        if (o !== e?.name) {
          return o;
        }
      });
      setFilteredProgramType(temp);
      // collegeList();
    } else {
      setFilteredProgramType([...filteredProgramType, e?.name]);
      // collegeList();
    }
  };

  const courceDurationHandleChange = async (e) => {
    if (filteredCourceDuration.includes(e?._id)) {
      setFilteredCourceDuration(filteredCourceDuration.filter((c) => c !== e?._id));
      setFilteredData(filteredData.filter((c) => c !== e?.name));
    } else {
      setFilteredCourceDuration([...filteredCourceDuration, e?._id]);
      setFilteredData([...filteredData, e?.name]);
    }
  };

  const degreeHandleChange = async (e) => {
    if (filteredDegree.includes(e?._id)) {
      setFilteredDegree(filteredDegree.filter((c) => c !== e?._id));
      setFilteredData(filteredData.filter((c) => c !== e?.name));
      // collegeList();
    } else {
      setFilteredDegree([...filteredDegree, e?._id]);
      setFilteredData([...filteredData, e?.name]);
      // collegeList();
    }
  };

  const collegeList = useCallback(
    (sortingValue) => {
      console.log('sortingValue: ', sortingValue);
      try {
        get_college_list(sortingValue).then((response) => {
          console.log("Line 102", response);
          if (response.code === 1 || response.code === "1") {
            setCollegeCount(response?.data?.total_clg);
            setTotalCourseCount(response?.data?.total_of_course);
            setColleges(response?.data?.colleges_list_result)

            // setColleges((prevCollege) => [
            //   ...prevCollege,
            //   ...response?.data?.colleges_list_result,
            // ]);
          } else if (response.code === 2 || response.code === "2") {
            setCollegeCount(0);
            setColleges([])
            // setColleges((prevCollege) => [
            //   ...prevCollege,
            //   ...response?.data?.colleges_list_result,
            // ]);
          }
        });
      } catch (e) {
        console.log("Line 85", e);
      }
    },
    []
  );

  const HandleReset = () => {
    setFilteredState([])
    setFilteredCity([])
    setFilteredDegree([])
    setMinValue(0)
    setMaxValue(1000000)
    setSortValue("L")
    setFilteredCource([])
    setFilteredStream([])
    setSearchedCollege("")
    setFilteredTypeOfCollege([])
    setFilteredProgramType([])
    setFilteredCourceDuration([])
  }
  useEffect(() => {
    get_degree_list({ page_token: "1" }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        setDegrees(response.data.degree_list_result);
        // collegeList();
      }
    });
    filter_data({}).then((response) => {
      console.log(response, "response");
      if (response.code === 1 || response.code === "1") {
        setCities(response.data.city);
        setStates(response.data.state);
        setCourse(response.data.course_list_result);
        setStream(response.data.stream_list_result);
        // collegeList();
      }
    });
    // collegeList();
  }, [collegeList]);

  useEffect(() => {
    collegeList({
      page_token: pageToken,
      state: filteredState,
      city: filteredCity,
      degree: filteredDegree,
      min_fees: minvalue,
      max_fees: maxvalue,
      short_by: sortValue,
      cource: filteredCource,
      stream: filteredStream,
      search: searchedCollege,
      type: filteredTypeOfCollege,
      program_type: filteredProgramType,
      duration: filteredCourceDuration
    })
  }, [collegeList, filteredCity, filteredCource, filteredCourceDuration, filteredDegree, filteredProgramType, filteredState, filteredStream, filteredTypeOfCollege, maxvalue, minvalue, pageToken, searchedCollege, sortValue])
  // useEffect(() => {
  //     const handleScroll = () => {
  //       setScrollY(window.scrollY);
  //     };
  //     window.addEventListener("scroll", handleScroll);
  //     return () => {
  //       window.removeEventListener("scroll", handleScroll);
  //     };
  //   }, []);

  //   useEffect(()=>{
  //     if(scrollY >=500){
  //         setPageToken(pageToken+1);
  //         collegeList();
  //     }
  //   })

  return (
    <>
      <HeaderProfile />
      <section className="main_edit_profile">
        <div className="container">
          <div className="row">
            <div className="col-12 mb-5 collage_title_section">
              <h1 className><img src="assets/video/orange_banck_icon.svg" className="me-2" />List Of Top Colleges In India </h1>
              <span className="clg_title_sub_text">{collegeCount} {filteredData + " "}{" "}
                {collegeCount === 1 ? " College" : " Colleges"} in India offering {totalCourseCount != undefined && totalCourseCount} courses</span>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div className="row">
                {/* <form> */}
                <div className="col-12 mb-3">
                  <div className="side_bar_widget_left">
                    <div className="widget_1 d-flex justify-content-between">
                      <h6>Found {collegeCount}{collegeCount === 1 ? " College" : " Colleges"}</h6>
                      <button type="reset" className="p-2" onClick={HandleReset} style={{ borderRadius: '20%' }}>reset</button>
                    </div>
                    <div className="widget_2">
                      <div className="search_bar">
                        <input type="text" name className="serch_input clg_serch" placeholder="Search colleges" onChange={(e) => {
                          setSearchedCollege(e.target.value);
                        }} />
                        <button><img src="assets/video/search.svg" /></button>
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>States</h6>
                      <div className="filter_list">
                        <ul>
                          {!showMore
                            ? states?.slice(0, 5)?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefault" + key}
                                      checked={filteredState.includes(item.name)}
                                      onChange={stateHandleChange} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefault" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : states?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefault" + key}
                                      checked={filteredState.includes(item.name)}
                                      onChange={stateHandleChange} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefault" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })}
                        </ul>
                        {showMore ? (
                          <span
                            className="d-flex light_gray_8b font_12 cursor-pointer mt-3"
                            onClick={() => setShowMore(false)}
                          >
                            <u className="ms-auto">SEE LESS</u>
                          </span>
                        ) : (
                          <span
                            className="d-flex light_gray_8b font_12 cursor-pointer mt-3"
                            onClick={() => setShowMore(true)}
                          >
                            <u className="ms-auto">SEE MORE</u>
                          </span>
                        )
                        }
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>City</h6>
                      <div className="filter_list">
                        <ul>
                          {showMoreCity
                            ? cities?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultcity" + key}
                                      checked={filteredCity.includes(item.name)}
                                      onChange={cityHandleChange} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultcity" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : cities?.slice(0, 5)?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultcity" + key}
                                      checked={filteredCity.includes(item.name)}
                                      onChange={cityHandleChange} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultcity" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })}
                        </ul>
                        {showMoreCity ? (
                          <span
                            className="d-flex light_gray_8b font_12 cursor-pointer mt-3"
                            onClick={() => setShowMoreCity(false)}
                          >
                            <u className="ms-auto">SEE LESS</u>
                          </span>

                        ) : (
                          <span
                            className="d-flex light_gray_8b font_12 cursor-pointer mt-3"
                            onClick={() => setShowMoreCity(true)}
                          >
                            <u className="ms-auto">SEE MORE</u>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>stream</h6>
                      <div className="filter_list">
                        <ul>
                          {showMoreStream
                            ? stream?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultStream" + key}
                                      checked={filteredStream.includes(item._id)}
                                      onChange={() => {
                                        streamHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultStream" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : stream?.slice(0, 5)?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultStream" + key}
                                      checked={filteredStream.includes(item._id)}
                                      onChange={() => {
                                        streamHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultStream" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>course</h6>
                      <div className="filter_list">
                        <ul>
                          {showMoreCource
                            ? courses?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.course_name}
                                      id={"flexCheckDefaultcource" + key}
                                      checked={filteredCource.includes(item._id)}
                                      onChange={() => {
                                        courceHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultcource" + key}>
                                      {item.course_name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : courses?.slice(0, 5)?.map((item, key) => {
                              console.log(item);
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.course_name}
                                      id={"flexCheckDefaultcource" + key}
                                      checked={filteredCource.includes(item._id)}
                                      onChange={() => {
                                        courceHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultcource" + key}>
                                      {item.course_name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>type of college</h6>
                      <div className="filter_list">
                        <ul>
                          {showMoreStream
                            ? typeOfCollege?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultTypeOfCollege" + key}
                                      checked={filteredTypeOfCollege.includes(item.name)}
                                      onChange={() => {
                                        tyoeOfCollegeHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultTypeOfCollege" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : typeOfCollege?.slice(0, 5)?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultTypeOfCollege" + key}
                                      checked={filteredTypeOfCollege.includes(item.name)}
                                      onChange={() => {
                                        tyoeOfCollegeHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultTypeOfCollege" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>program type</h6>
                      <div className="filter_list">
                        <ul>
                          {showMoreStream
                            ? programType?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultProgramtype" + key}
                                      checked={filteredProgramType.includes(item.name)}
                                      onChange={() => {
                                        programTypeHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultProgramtype" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : programType?.slice(0, 5)?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultProgramtype" + key}
                                      checked={filteredProgramType.includes(item.name)}
                                      onChange={() => {
                                        programTypeHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultProgramtype" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                      </div>
                    </div>
                    <div className="widget_3">
                      <h6>course duration</h6>
                      <div className="filter_list">
                        <ul>
                          {showMoreCourceDuration
                            ? CourceDuration?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultCourceDuration" + key}
                                      checked={filteredCourceDuration.includes(item._id)}
                                      onChange={() => {
                                        courceDurationHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultCourceDuration" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            }) : CourceDuration?.slice(0, 5)?.map((item, key) => {
                              return (
                                <li key={key}>
                                  <div className="form-check">
                                    <input className="form-check-input" type="checkbox" value={item.name}
                                      id={"flexCheckDefaultCourceDuration" + key}
                                      checked={filteredCourceDuration.includes(item._id)}
                                      onChange={() => {
                                        courceDurationHandleChange(item)
                                      }} />
                                    <label className="form-check-label" htmlFor={"flexCheckDefaultCourceDuration" + key}>
                                      {item.name}
                                    </label>
                                  </div>
                                </li>
                              )
                            })
                          }
                        </ul>
                        {showMoreCourceDuration ? (
                          <span
                            className="d-flex light_gray_8b font_12 cursor-pointer mt-3"
                            onClick={() => setShowMoreCourceDuration(false)}
                          >
                            <u className="ms-auto">SEE LESS</u>
                          </span>

                        ) : (
                          <span
                            className="d-flex light_gray_8b font_12 cursor-pointer mt-3"
                            onClick={() => setShowMoreCourceDuration(true)}
                          >
                            <u className="ms-auto">SEE MORE</u>
                          </span>
                        )}
                      </div>
                    </div>
                    <div className="widget_4">
                      <h6>Price range <span className="float-end value_tag">₹  {minvalue} - {maxvalue}</span></h6>
                      <div className="filter_list pt-3">
                        <RangeSlider
                          defaultValue={[minvalue, maxvalue]}
                          value={[minvalue, maxvalue]}
                          max={1000000}
                          min={0}
                          onChange={(x) => {
                            setMinValue(x[0]);
                            setMaxValue(x[1]);
                          }}
                        />
                      </div>
                    </div>
                  </div>
                </div>
                {/* </form> */}
              </div>
            </div>
            <div className="col-12 col-xl-9 col-lg-9 col-md-8 col-sm-12">
              <div className="row">
                <div className="col-12">
                  <div className="filter_nav">
                    <div className="list_filter">
                      <p> {collegeCount === 0 ? "No Listings Found" : (collegeCount + " Realted Listings Found")}</p>
                    </div>
                    <div className="list_filter_right">
                      {/* <div className="filter_selection form-select" onClick={() => {
                        if (selectRef.current) {
                          selectRef.current.click();
                        }
                      }}>
                        <label>Sort By:</label>
                        <select className="my_select" ref={selectRef} aria-label="Default select example" value={sortValue}
                          onChange={(e) => {
                            setSortValue(e.target.value);
                            // collegeList(e.target.value);
                          }}>
                          <option value="L">Latest</option>
                          <option value="O">Oldest</option>
                        </select>
                      </div> */}
                      <div className="dropdown">
                        <a className="btn dropdown-toggle" href="javascript:void(0)" role="button" id="dropdownMenuLink" data-bs-toggle="dropdown" aria-expanded="false">
                          {sortValue == 'L' ? 'Latest' : 'Oldest'}
                        </a>
                        <ul className="dropdown-menu" aria-labelledby="dropdownMenuLink">
                          <li><a className="dropdown-item" href="javascript:void(0)" onClick={() => { setSortValue('L') }}>Latest</a></li>
                          <li><a className="dropdown-item" href="javascript:void(0)" onClick={() => { setSortValue('O') }}>Oldest</a></li>
                        </ul>
                      </div>

                      <div className="filter_list_grid">
                        {activeListType === true ? (
                          <>
                            <button className="btn-list" onClick={() => {
                              setToggleMode(false);
                              setActiveListType(true);
                            }}><img src="assets/video/filter_list_off.svg" /></button>
                            <button className="btn-grid" onClick={() => {
                              setToggleMode(true);
                              setActiveListType(false);
                            }}><img src="assets/video/grid_off.svg" /></button>
                          </>
                        ) : (
                          <>
                            <button className="btn-list" onClick={() => {
                              setToggleMode(false);
                              setActiveListType(true);
                            }}><img src="assets/video/filter_list.svg" /></button>
                            <button className="btn-grid" onClick={() => {
                              setToggleMode(true);
                              setActiveListType(false);
                            }}><img src="assets/video/grid_on.svg" /></button>
                          </>
                        )}

                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className={`grid-container ${toggleMode === false ? 'list-view' : ''}`}>
                {/* <div className="grid-container list-view"> */}
                <div className="row mt-4">
                  {colleges.length > 0 ? (
                    colleges?.map((item, key) => {
                      console.log("item", item);
                      return (
                        <>
                          <div key={key} className="col-lg-4 col-sm-6 col-12 mb-4 grid_view">
                            <div className="collage_main active">
                              <div className="collage_images">
                                {/* <div className="collage_tag">
                                  <span>Featured collage</span>
                                  <a href="javascript:void(0)" id="heart">
                                    <i className="fa fa-heart-o" aria-hidden="true" />
                                  </a>
                                </div> */}
                                <OwlCarousel className="owl-carousel owl-theme"
                                  items="1"
                                  dots={true}
                                  responsiveclassName={true}
                                  width={100}
                                  // autoWidth={true}
                                  navText={['<i class="fas fa-chevron-left" style="font-size: 24px;"></i>', '<i class="fas fa-chevron-right" style="font-size: 24px;"></i>']}
                                  center={true} loop margin={0} nav>
                                  <div className="item">
                                    <img src={
                                      item?.college_logo === ""
                                        ? "https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                        : item?.college_logo
                                    } alt="College 1" />
                                  </div>
                                </OwlCarousel>
                                {/* <div className="owl-item active" style={{ width: '260.984px' }}><div className="item">
                                  <img src={
                                    item?.college_logo === ""
                                      ? "https://stulink.s3.ap-south-1.amazonaws.com/stulink/post/image/wdTE3sVQ1J64edad7aa602d.png"
                                      : item?.college_logo
                                  } />
                                </div></div> */}
                                <div className="boorom_value_fix">
                                  <label>  ₹{item?.min_fees} - ₹{item?.max_fees}</label>
                                </div>
                              </div>
                              <div className="collage_desc">
                                <div className="collage_title_desk">
                                  <h6>
                                    <Link
                                      to={{
                                        pathname: "/college-info",
                                      }}
                                      state={item._id} // Passing the state object
                                    // onClick={() => {
                                    //   navigate("/college-info")
                                    // }}
                                    >
                                      {item?.name
                                      }
                                    </Link>
                                  </h6>

                                  <p>{item.location}</p>
                                </div>
                                <div className="below_listing">
                                  <ul>
                                    {item?.college_mnts && item?.college_mnts.map((value, index) => {
                                      return (
                                        <li>
                                          <img src={value.icon} height={24} />
                                        </li>
                                      )
                                    })}
                                  </ul>
                                  <a href="javascript:void(0)" className="btn btn-apply" onClick={() => {
                                    navigate(`/apply-now/colleges/${item._id}`, {
                                      state: { logo: item?.college_logo, name: item?.name, path: '/colleges' }
                                    })
                                  }}>apply now</a>
                                </div>
                              </div>
                            </div>
                          </div >
                        </>
                      )
                    })
                  ) : (
                    <div className="col-lg-4 col-sm-6 col-12 mb-4 grid_view">
                      <div className="list_college_one">
                        <div className="row">


                          <h5
                            style={{ cursor: "pointer" }}
                            className="gray_5a inter font_600 font_28 ram_college d-flex justify-content-center p-3"
                          >
                            No Data found
                          </h5></div></div>

                    </div>
                  )}

                </div>
              </div>
            </div>
          </div>
        </div>
      </section >

    </>
  );
}
