import React, { useCallback, useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useLocation } from "react-router-dom";
import dayjs from "dayjs";
import { add_answers, get_answer_list, get_comment_qa_list, give_qa_like_unlike, report_user, share_link, top_contribution } from "../../api/apiHandler";
import ReactTimeAgo from "react-time-ago";
import Topcontributors from "../Topcontributors";
import EmojiPicker from "emoji-picker-react";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ShareLink from "../pages/HomeFeed/components/ShareLink";
export default function QuestionDetail() {
  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);
  const [comment, setComment] = useState("");
  const location = useLocation();
  const [commetList, setCommentList] = useState(null);
  const [commentData, setCommentData] = useState(location.state);
  console.log('commetList: ', commetList);
  const [contributerList, setContributerList] = useState(null);
  const [commentQaId, setCommentQaId] = useState(location?.state?._id);
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });
  useEffect(() => {
    getCommentQAList();
    topContributerList();
  }, []);

  const getCommentQAList = () => {
    get_comment_qa_list({ qa_id: commentData?._id }).then((response) => {
      if (response?.code === 1 || response?.code === "1") {
        setCommentList(response.data.comment_list);
        setCommentData(response.data)
        console.log('response.data.comment_list: ', response.data);
      } else {
        setCommentList("");
      }
    });
  };
  const handleShare = useCallback((data) => {
    share_link({
      id: data._id,
      module_type: 'Q&A',
      description: data?.description || "stulink",
    }).then((response) => {
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);
  const topContributerList = () => {
    top_contribution({}).then((response) => {
      if (response?.code === 1 || response?.code === "1") {
        setContributerList(response?.data);
      }
    });
  };

  const likeQa = (qId) => {
    console.log("click Like Button", qId);
    give_qa_like_unlike({ id: qId, type: "like" }).then((response) => {
      console.log("Like Response", response);
      if (response?.code === 1 || response?.code === "1") {
        getCommentQAList()
      } else {
        getCommentQAList()
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  const dislikeQa = (qId) => {
    give_qa_like_unlike({ id: qId, type: "unlike" }).then((response) => {
      console.log("UnLike Response", response);
      if (response?.code === 1 || response?.code === "1") {
        getCommentQAList()
      } else {
        getCommentQAList()
      }
    });
  };
  const HandleCommentSubmit = () => {
    console.log(comment);
    add_answers({
      qa_id: commentQaId,
      description: comment,
    }).then((response) => {
      if (comment === "") {
        toast.error("Please enter answer", {
          toastId: "answerErrorPost",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      } else {
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            toastId: "answerPost",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setComment("");
          getCommentQAList()
          // answerListing();
        }
        return true;
      }
    });
  }

  const HandleReportUser = (id) => {
    report_user({ id }).then((response) => {
      console.log("response: block ", response);
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  }
  // if (commetList === null) {
  //   return <></>;
  // }
  // if (contributerList == null) {
  //   return <></>;
  // }
  return (
    <>
      {localStorage?.getItem("isSignedUp") ||
        localStorage?.getItem("isLoggedIn") ? (
        <>
          <HeaderProfile />
          <ShareLink
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      ) : (
        <Header />
      )}
      {/* Comment Modal new */}
      <div
        className="modal fade"
        id="commentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setComment("");
                  // setFileForComments([]);
                  // setSelectedFileForComments([]);
                  // setHeightWidthForComments([]);
                }}
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={commentData?.profile_image}
                              className="inner_post"
                            />{" "}
                            {commentData?.name}
                          </a>
                          @{commentData?.username}
                          <p>
                            {commentData?.insertdate ? (
                              <ReactTimeAgo
                                date={commentData?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {commentData?.post_type === "retweet" ? (
                          <>
                            <label>
                              {commentData?.shared_feed?.description}
                            </label>

                            {commentData?.shared_feed?.media?.length ===
                              0 ? (
                              <></>
                            ) : (
                              <>
                                {commentData?.shared_feed?.media?.map(
                                  (item) => {
                                    return item?.name;
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : commentData?.post_type ===
                          "retweet_with_quote" ? (
                          <>
                            <label>{commentData?.description}</label>

                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link to={"#"} className="post_person_name">
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        commentData?.shared_feed
                                          ?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {commentData?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{commentData?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {commentData?.shared_feed?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          commentData?.shared_feed
                                            ?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {commentData?.shared_feed?.media?.length ===
                                  0 ? (
                                  <></>
                                ) : commentData?.shared_feed?.media[0]
                                  ?.type === "image" ? (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.media[0]
                                          ?.name
                                      }
                                      alt="post"
                                    />
                                  </div>
                                ) : (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.media[0]
                                          ?.thumb
                                      }
                                      alt="post"
                                    />
                                  </div>
                                )}
                                {commentData?.module_type === "news" ? (
                                  <div className="tetweet_quote_text">
                                    <p
                                      className="mb-0 gray_5a font_12"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          commentData?.shared_feed
                                            ?.description,
                                      }}
                                    ></p>
                                  </div>
                                ) : (
                                  <div className="tetweet_quote_text">
                                    <p className="mb-0 gray_5a font_12">
                                      {commentData?.shared_feed?.description}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : commentData?.module_type === "news" ? (
                          <label
                            dangerouslySetInnerHTML={{
                              __html: commentData?.description,
                            }}
                          ></label>
                        ) : (
                          <label>{commentData?.description}</label>
                        )}

                        <br />
                        {commentData && commentData?.media?.length === 0 ? (
                          <></>
                        ) : (
                          <label>
                            {commentData?.media?.map((item) => {
                              return item?.name;
                            })}
                          </label>
                        )}
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          value={comment}
                          onChange={(e) => {
                            setComment(e?.target?.value);
                            setEmojiPickerForComments(false);
                          }}
                          placeholder="Post your comment..!!"
                        />
                      </div>
                      {/* <div className="uploade_images">
                        {filesForComments?.length !== 0 ? (
                          filesForComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    onMouseEnter={() =>
                                      handleMouseEnter(key)
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(key)
                                    }
                                  >
                                    <video
                                      autoPlay={false}
                                      loop=""
                                      id={`banner${key}`}
                                      controls="false"
                                      controlslist="nodownload"
                                      style={{ height: "200px" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(item)}
                                      />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <ul className="post_option ms-2 me-auto flex-row">
                <li>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpeg, .jpg"
                    id="imageForPosts"
                    multiple
                  // onChange={handleFileInputForComments}
                  // disabled={
                  //   disablePhotoInputForComments === true ||
                  //   filesForComments?.length === 4 ||
                  //   selectedFileForComments?.length === 4 ||
                  //   heightWidthForComments?.length === 4
                  // }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />

                </li>
                <li>
                  <a className="mx-3">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      // ref={vidRef}
                      accept=".mp4, .avi, .mov, .mkv, .wmv"
                      id="imageForPosts"
                    // onChange={handleVideoUploadForComments}
                    // disabled={
                    //   disableVideoInputForComments === true ||
                    //   filesForComments?.length === 1 ||
                    //   selectedFileForComments?.length === 1 ||
                    //   heightWidthForComments?.length === 1
                    // }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/video-icon.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />

                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmojiPickerForComments(!emojiPickerForComments)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </a>
                </li>
              </ul>
              <button
                disabled={!comment}
                onClick={() => {
                  HandleCommentSubmit();
                  // uploadImagesForComments();
                }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Comment
              </button>
            </div>
            {emojiPickerForComments ? (
              <div className="mx-5 mt-0 mb-2">
                <EmojiPicker
                  onEmojiClick={(e) => setComment(comment + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <section className="home_question_list" style={{ background: "#FCFEFF" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12 mb-3 mb-md-0">
              <div className="row">
                <div className="col-12 mt-3 mb-2 d-flex justify-content-between">
                  <h2 className="qa_title">All Questions</h2>
                  {/* <div className="qa_button">
                                        <div className="filter_selection form-select bg-white new_select_qa">
                                            <label>Sort By:</label>
                                            <select
                                                className="my_select"
                                                aria-label="Default select example"
                                            >
                                                <option>Oldest</option>
                                                <option>Latest</option>
                                                <option>Popular</option>
                                            </select>
                                        </div>
                                    </div> */}
                </div>
                <div className="col-12 mb-3">
                  <div className="comment_section">
                    <div className="comment_1">
                      <div className="aq_user">
                        <img src={commentData?.profile_image} />
                      </div>
                      <div className="aq_title">
                        <h6>
                          {commentData?.name}{" "}
                          <a className="user_name ms-2">
                            @{commentData?.username}{" "}
                          </a>
                        </h6>
                        <div className="sub_option_student">
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/school.svg"
                              }
                              className="me-2"
                            />
                            {commentData?.college_uni_name}
                          </span>
                          <div className="list_of_tag mt-2 mb-2 ms-auto">
                            {commentData?.category?.map((i, index) => {
                              return (
                                <span
                                  className="font-13 tab_option"
                                  key={index}
                                >
                                  {i}
                                </span>
                              );
                            })}
                          </div>
                          <div className="time_text me-3 ms-2">
                            <p className="m-0">
                              {dayjs(commentData?.insertdate).format(
                                "DD MMM HH:mm A"
                              )}
                            </p>
                          </div>
                          <div className="dropdown">
                            <a
                              className="dropdown-toggle"
                              href="#"
                              role="button"
                              id="dropdownMenuLink"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/more_horiz.svg"
                                }
                              />
                            </a>
                            <ul
                              className="dropdown-menu"
                              aria-labelledby="dropdownMenuLink"
                            >
                              <li>
                                <Link
                                  //   to={`/profile/${commentData.user_id}`}
                                  to="/profile"
                                  state={commentData?.user_id}
                                  className="dropdown-item"
                                >
                                  Link <b> @{commentData?.username} </b>
                                </Link>
                              </li>
                              <li>
                                <a className="dropdown-item" href="javascript:void(0)" onClick={() => { HandleReportUser(commentData?._id) }}>
                                  Report User
                                </a>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                    <div className="mid_text">
                      <p>{commentData?.description}</p>
                    </div>
                    <div className="footer_text_qa">
                      <div className="like_dislike d-flex align-items-center">
                        <a type="button" className="like me-3 text-green" onClick={() => likeQa(location?.state?._id)}>
                          <img
                            src={
                              process.env.PUBLIC_URL + "/assets/video/like.svg"
                            }
                            className="me-1"
                          />
                          {commentData?.likes}
                        </a>
                        <a type="button" className="dislke me-3 text-red" onClick={() => { dislikeQa(location?.state?._id) }}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/video/dislike.svg"
                            }
                            className="me-1"
                          />
                          {commentData?.unlikes}
                        </a>
                        <a type="button" className="me-3" data-bs-target="#commentModal"
                          data-bs-toggle="modal" id="comment_show">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/video/comment_brown.svg"
                            }
                            className="me-1"
                          />{" "}
                          {commentData?.comment_count}
                        </a>
                        <a type="button" className="" onClick={() => {
                          handleShare(commentData);
                        }}>
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/video/plane_brown.svg"
                            }
                            className="me-1"
                          />
                          share
                        </a>
                      </div>
                      {/* <div className="drop d-flex align-items-center">
                                                <a href="#" className="me-3">
                                                    50 Views
                                                </a>
                                                <a href="#">14 Reply</a>
                                            </div> */}
                    </div>
                    <div className="show_more_comment">
                      <ul className="parent_list">
                        {commetList != "" && commetList != undefined ? (
                          <>
                            {commetList &&
                              commetList.map((item, index) => {
                                return (
                                  <li key={index}>
                                    <div className="all_comment_listing">
                                      <div className="comment_user">
                                        <img src={item.profile_image} />
                                      </div>
                                      <div className="comment_text_maain">
                                        <div className="grey_section_comment">
                                          <div className="user_time_name_option">
                                            <h6>
                                              <Link
                                                // to={`/profile/${item.user_id}`}
                                                to="/profile"
                                                state={item.user_id}
                                              >
                                                {item.name}
                                              </Link>
                                            </h6>
                                            <span>
                                              <ReactTimeAgo
                                                date={
                                                  item.insertdate
                                                    ? item.insertdate
                                                    : ""
                                                }
                                                locale="en-US"
                                              />
                                            </span>
                                            <div className="dropdown">
                                              <a
                                                className="dropdown-toggle"
                                                href="#"
                                                role="button"
                                                id="dropdownMenuLink"
                                                data-bs-toggle="dropdown"
                                                aria-expanded="false"
                                              >
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/video/more_horiz.svg"
                                                  }
                                                />
                                              </a>
                                              <ul
                                                className="dropdown-menu"
                                                aria-labelledby="dropdownMenuLink"
                                              >
                                                <li>
                                                  <Link
                                                    // to={`/profile/${item.user_id}`}
                                                    to="/profile"
                                                    state={item.user_id}
                                                    className="dropdown-item"
                                                  >
                                                    Link{" "}
                                                    <b> @{item?.username} </b>
                                                  </Link>
                                                </li>
                                                <li>
                                                  <a
                                                    className="dropdown-item"
                                                    href="javascript:void(0)"
                                                    onClick={() => { HandleReportUser(commentData?._id) }}
                                                  >
                                                    Report User
                                                  </a>
                                                </li>
                                              </ul>
                                            </div>
                                          </div>
                                          <p>{item.description}</p>
                                        </div>
                                        {/* <div className="like_dislike d-flex align-items-center ">
                                                                                <a href="#" className="like ">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL+"/assets/video/unfill.svg"}
                                                                                        className="me-1"
                                                                                    />
                                                                                    3
                                                                                </a>
                                                                                <a href="#" className="" id="comment_show">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL+"/assets/video/comment_brown.svg"}
                                                                                        className="me-1"
                                                                                    />{" "}
                                                                                    3
                                                                                </a>
                                                                                <a href="#" className="dislke  ">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL+"/assets/video/arrows-clockwise-light.svg"}
                                                                                        className="me-1"
                                                                                    />
                                                                                    1
                                                                                </a>
                                                                                <a href="#" className="">
                                                                                    <img
                                                                                        src={process.env.PUBLIC_URL+"/assets/video/plane_brown.svg"}
                                                                                        className="me-1"
                                                                                    />
                                                                                    Share 1
                                                                                </a>
                                                                            </div> */}
                                      </div>
                                    </div>
                                  </li>
                                );
                              })}
                          </>
                        ) : (
                          <h4>No comments Found</h4>
                        )}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Topcontributors />
            {/* <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3 mb-md-0">
              <div className="row">
                <div className="col-12 mb-3">
                  <div className="sidebar_widget">
                    <div className="widget_title">
                      <h4>
                        {" "}
                        <span>Top Contributors</span>
                      </h4>
                    </div>
                    <div className="widget_list">
                      <ul>
                        {contributerList &&
                          contributerList.map((item, index) => {
                            return (
                              <li key={index}>
                                <div className="user_img_list">
                                  <img
                                    src={
                                      item.profile_image != undefined
                                        ? item.profile_image
                                        : process.env.PUBLIC_URL +
                                          "/assets/video/user_imag.png"
                                    }
                                  />
                                </div>
                                <div className="user_img_text">
                                  <h6>{item.name}</h6>
                                  <a>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/video/school.svg"
                                      }
                                    />
                                    {item.college_uni_name}
                                  </a>
                                </div>
                                <div className="user_action">
                                  <span>
                                    <img
                                      src="assets/video/up.svg"
                                      className="me-1"
                                    />
                                    {item.count}
                                  </span>
                                  <a>
                                    <img src="assets/video/user.svg" />
                                    Link
                                  </a>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
               
              </div>
            </div> */}
          </div>
        </div>
      </section>
    </>
  );
}
