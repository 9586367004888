import React, { useCallback, useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import {
  get_college_list,
  top_course,
  entrace_exam,
  get_qa_list,
  add_qa,
  give_qa_like_unlike,
  add_answers,
  share_link,
  report_user,
} from "../../api/apiHandler";
import UpdatedColleges from "./UpdatedColleges";
import moment from "moment";
import { useForm } from "react-hook-form";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import ReactTimeAgo from "react-time-ago";
import EmojiPicker from "emoji-picker-react";
import ShareLink from "../pages/HomeFeed/components/ShareLink";
import Select from 'react-select'

export default function CourseOverview() {
  const [comment, setComment] = useState("");
  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);

  const { register, handleSubmit, reset, formState, setValue } = useForm();
  const { errors } = formState;
  const location = useLocation();
  const stateData = location.state;
  console.log('stateData: ', stateData);
  const navigate = useNavigate();
  let sum = 0;
  let noOfRatings = 0;
  let averateRating;
  const [isCollegeData, setIsCollegeData] = useState(null);
  const [questions, setQuestions] = useState([]);
  const [commentData, setCommentData] = useState();
  const [commentQaId, setCommentQaId] = useState("");
  const [isCourseData, setIsCourseData] = useState(null);
  const [isExamData, setIsExamData] = useState(null);
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });
  const options = [
    { value: 'College', label: 'College' },
    { value: 'Jobs', label: 'Jobs' },
    { value: 'Exams', label: 'Exams' },
    { value: 'Results', label: 'Results' },
    { value: 'Education', label: 'Education' }
  ]
  const questionList = (sortValue) => {
    get_qa_list({
      type: "degree",
      college_id: "",
      category: "",
      degree_id: location.state._id,
      short_by: sortValue ? sortValue : 'L',
    }).then((response) => {
      console.log("question list response", response);
      if (response.code === 1 || response.code === "1") {
        setQuestions(response?.data);
      }
    });
  };
  const handleCountryChange = (selectedOptions) => {
    let selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    console.log("selectedOptions: ", selectedOptions);
    setValue("que_category", selectedValues);
  };
  const handleShare = useCallback((data) => {
    share_link({
      id: data._id,
      module_type: 'Q&A',
      description: data?.description || "stulink",
    }).then((response) => {
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  const HandleReportUser = (id) => {
    report_user({ id }).then((response) => {
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  }

  useEffect(() => {
    questionList()
  }, [])
  const likeQa = (qId) => {
    console.log("click Like Button", qId);
    give_qa_like_unlike({ id: qId, type: "like" }).then((response) => {
      console.log("Like Response", response);
      if (response?.code === 1 || response?.code === "1") {
        questionList()
        // questionList(filteredValue, categoryValue);
      } else {
        questionList()
        // questionList(filteredValue, categoryValue);
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  const dislikeQa = (qId) => {
    give_qa_like_unlike({ id: qId, type: "unlike" }).then((response) => {
      console.log("UnLike Response", response);
      if (response?.code === 1 || response?.code === "1") {
        questionList()
        // questionList(filteredValue, categoryValue);
      } else {
        questionList()
        // questionList(filteredValue, categoryValue);
      }
    });
  };
  const getExamData = useCallback(() => {
    entrace_exam({ id: location.state._id }).then((response) => {
      console.log("response: ", response);
      if (response.code == 1) {
        setIsExamData(response.data);
      } else {
        setIsExamData([]);
      }
    });
  }, [location.state._id]);

  const getCollegeData = useCallback(() => {
    get_college_list({ degree: [location.state._id] }).then((response) => {
      if (response.code == 1) {
        setIsCollegeData(response.data.colleges_list_result);
      } else {
        setIsCollegeData([]);
      }
    });
  }, [location.state._id]);

  const getCourseData = useCallback((search) => {
    // let params = {};
    // if(search != undefined && search != ""){
    //     params = {}
    // }
    top_course({}).then((response) => {
      console.log("response:42 ", response);
      if (response.code == 1) {
        setIsCourseData(response.data);
      } else {
        setIsCourseData([]);
      }
    });
  }, []);

  useEffect(() => {
    getCourseData();
  }, [getCourseData]);

  useEffect(() => {
    getExamData();
  }, [getExamData]);

  useEffect(() => {
    getCollegeData();
  }, [getCollegeData]);

  //   if (isCollegeData == null) {
  //     return <></>;
  //   }
  const onSubmit = (data) => {
    console.log('data: ', data, {
      description: data?.question,
      category: data?.que_category,
      type: "degree",
      degree_id: location.state._id,
    });
    add_qa({
      description: data?.question,
      category: data?.que_category,
      type: "degree",
      degree_id: location.state._id,
    }).then((response) => {
      console.log(response);
      if (response.code === 1 || response.code === "1") {
        toast.success(response.message, {
          toastId: "add-question",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        questionList();
        reset();
      }
    });
  }
  const HandleCommentSubmit = () => {
    add_answers({
      qa_id: commentQaId,
      description: comment,
    }).then((response) => {
      if (comment === "") {
        toast.error("Please enter answer", {
          toastId: "answerErrorPost",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      } else {
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            toastId: "answerPost",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setComment("");
          questionList();
          // answerListing();
        }
        return true;
      }
    });
  }
  return (
    <>
      <main className="stulink">
        {localStorage?.getItem("isLoggedIn") ||
          localStorage?.getItem("isSignedUp") ? (
          <>
            <HeaderProfile />
            <ShareLink
              shareModalOpen={shareModalOpen}
              setShareModalOpen={setShareModalOpen}
            />
          </>
        ) : (
          <Header />
        )}
        {/* Comment Modal new */}
        <div
          className="modal fade"
          id="commentModal"
          tabIndex={-1}
          aria-labelledby="comment"
          aria-hidden="true"
        >
          <div className="modal-dialog modal-lg">
            <div className="modal-content">
              <div className="modal-body position-relative">
                <button
                  type="button"
                  className="btn-close close_custom"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  onClick={() => {
                    setComment("");
                    // setFileForComments([]);
                    // setSelectedFileForComments([]);
                    // setHeightWidthForComments([]);
                  }}
                />
                <div className="comment_main">
                  <div className="user_comment">
                    <div className="comment_user_post">
                      <div className="main_comment_other border_0 comment_line">
                        <div className="other_user_comment">
                          <span>
                            <a href="#">
                              <img
                                src={commentData?.profile_image}
                                className="inner_post"
                              />{" "}
                              {commentData?.name}
                            </a>
                            @{commentData?.username}
                            <p>
                              {commentData?.insertdate ? (
                                <ReactTimeAgo
                                  date={commentData?.insertdate}
                                  locale="en-US"
                                />
                              ) : (
                                <></>
                              )}
                            </p>
                          </span>
                        </div>
                        <div className="comment_image ms-5 mt-3 mb-3">
                          {commentData?.post_type === "retweet" ? (
                            <>
                              <label>
                                {commentData?.shared_feed?.description}
                              </label>

                              {commentData?.shared_feed?.media?.length ===
                                0 ? (
                                <></>
                              ) : (
                                <>
                                  {commentData?.shared_feed?.media?.map(
                                    (item) => {
                                      return item?.name;
                                    }
                                  )}
                                </>
                              )}
                            </>
                          ) : commentData?.post_type ===
                            "retweet_with_quote" ? (
                            <>
                              <label>{commentData?.description}</label>

                              <div
                                className="tetweet_quote"
                                style={{ marginLeft: "0px" }}
                              >
                                <div className="post_person_mark">
                                  <Link to={"#"} className="post_person_name">
                                    <div className="post_person_image">
                                      <img
                                        src={
                                          commentData?.shared_feed
                                            ?.profile_image
                                        }
                                        className="img-fluid"
                                        alt="profile-pic"
                                      />
                                    </div>
                                    <div className="post_person_text">
                                      <h6 className="font_12 font_600 mb-0">
                                        {commentData?.shared_feed?.name}
                                      </h6>
                                      <span className="light_gray_8b font_12 inter">
                                        @{commentData?.shared_feed?.username}
                                      </span>
                                    </div>
                                  </Link>
                                  <div className="post_person_date">
                                    <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                      {commentData?.shared_feed?.insertdate ? (
                                        <ReactTimeAgo
                                          date={
                                            commentData?.shared_feed
                                              ?.insertdate
                                          }
                                          locale="en-US"
                                        />
                                      ) : (
                                        <></>
                                      )}
                                    </h5>
                                  </div>
                                </div>
                                <div className="tetweet_quote_content">
                                  {commentData?.shared_feed?.media?.length ===
                                    0 ? (
                                    <></>
                                  ) : commentData?.shared_feed?.media[0]
                                    ?.type === "image" ? (
                                    <div className="tetweet_quote_image">
                                      <img
                                        src={
                                          commentData?.shared_feed?.media[0]
                                            ?.name
                                        }
                                        alt="post"
                                      />
                                    </div>
                                  ) : (
                                    <div className="tetweet_quote_image">
                                      <img
                                        src={
                                          commentData?.shared_feed?.media[0]
                                            ?.thumb
                                        }
                                        alt="post"
                                      />
                                    </div>
                                  )}
                                  {commentData?.module_type === "news" ? (
                                    <div className="tetweet_quote_text">
                                      <p
                                        className="mb-0 gray_5a font_12"
                                        dangerouslySetInnerHTML={{
                                          __html:
                                            commentData?.shared_feed
                                              ?.description,
                                        }}
                                      ></p>
                                    </div>
                                  ) : (
                                    <div className="tetweet_quote_text">
                                      <p className="mb-0 gray_5a font_12">
                                        {commentData?.shared_feed?.description}
                                      </p>
                                    </div>
                                  )}
                                </div>
                              </div>
                            </>
                          ) : commentData?.module_type === "news" ? (
                            <label
                              dangerouslySetInnerHTML={{
                                __html: commentData?.description,
                              }}
                            ></label>
                          ) : (
                            <label>{commentData?.description}</label>
                          )}

                          <br />
                          {commentData && commentData?.media?.length === 0 ? (
                            <></>
                          ) : (
                            <label>
                              {commentData?.media?.map((item) => {
                                return item?.name;
                              })}
                            </label>
                          )}
                        </div>
                        <div className="other_user_comment d-flex">
                          <span>
                            <a href="#">
                              <img
                                src={localStorage?.getItem("profile_image")}
                                className="inner_post"
                              />
                            </a>
                          </span>
                          <input
                            type="text"
                            name=""
                            value={comment}
                            onChange={(e) => {
                              setComment(e?.target?.value);
                              setEmojiPickerForComments(false);
                            }}
                            placeholder="Post your comment..!!"
                          />
                        </div>
                        {/* <div className="uploade_images">
                        {filesForComments?.length !== 0 ? (
                          filesForComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    onMouseEnter={() =>
                                      handleMouseEnter(key)
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(key)
                                    }
                                  >
                                    <video
                                      autoPlay={false}
                                      loop=""
                                      id={`banner${key}`}
                                      controls="false"
                                      controlslist="nodownload"
                                      style={{ height: "200px" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(item)}
                                      />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div> */}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="modal-footer d-flex justify-content-between">
                <ul className="post_option ms-2 me-auto flex-row">
                  <li>
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".png, .jpeg, .jpg"
                      id="imageForPosts"
                      multiple
                    // onChange={handleFileInputForComments}
                    // disabled={
                    //   disablePhotoInputForComments === true ||
                    //   filesForComments?.length === 4 ||
                    //   selectedFileForComments?.length === 4 ||
                    //   heightWidthForComments?.length === 4
                    // }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/gallery.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />

                  </li>
                  <li>
                    <a className="mx-3">
                      <input
                        type="file"
                        style={{ display: "none" }}
                        // ref={vidRef}
                        accept=".mp4, .avi, .mov, .mkv, .wmv"
                        id="imageForPosts"
                      // onChange={handleVideoUploadForComments}
                      // disabled={
                      //   disableVideoInputForComments === true ||
                      //   filesForComments?.length === 1 ||
                      //   selectedFileForComments?.length === 1 ||
                      //   heightWidthForComments?.length === 1
                      // }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/video-icon.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />

                    </a>
                  </li>
                  <li>
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setEmojiPickerForComments(!emojiPickerForComments)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/emoji.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </a>
                  </li>
                </ul>
                <button
                  disabled={!comment}
                  onClick={() => {
                    HandleCommentSubmit();
                    // uploadImagesForComments();
                  }}
                  className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                >
                  Comment
                </button>
              </div>
              {emojiPickerForComments ? (
                <div className="mx-5 mt-0 mb-2">
                  <EmojiPicker
                    onEmojiClick={(e) => setComment(comment + e?.emoji)}
                  />
                </div>
              ) : (
                <></>
              )}
            </div>
          </div>
        </div>
        <section className="one_college">
          <div className="container">
            <div className="row">
              {/* <div className="col-12 col-xl-7 col-lg-7 col-md-9 col-sm-9">
                <div className="college_short_details">
                  <h1 className="font_30 font_700">{stateData.type} ({stateData.course.name})</h1>
                  <div className="d-flex">
                    <div className="images_icon">
                      <img src="/assets/video/exam_icon.svg" alt="" />
                    </div>
                    <div className="images_text ms-2">
                      <h6 className="mb-0">Exam Accepted</h6>
                      <span>{stateData.exam_name}</span>
                    </div>
                  </div>
                </div>
              </div> */}
              <div className="col-12 col-xl-7 col-lg-7 col-md-9 col-sm-9">
                <div className="college_short_details">
                  <h1 className="font_30 font_700">{location.state.name}</h1>
                  <div className="d-flex">
                    <div className="images_icon">
                      <img src={stateData?.icon ? stateData.icon : '/assets/video/exam_icon.svg'} alt="" />
                    </div>
                    <div className="images_text ms-2">
                      <h6 className="mb-0">Exam Accepted</h6>
                      <span>{location.state.exam_name}</span>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-5 col-lg-3 col-md-12 col-sm-12 d-flex align-items-center justify-content-end">
                <button
                  type="button"
                  className="blue_apply_bg orange_btn w-auto"
                  onClick={() =>
                    navigate(`/apply-now/courses/${location.state._id}`, { state: { logo: stateData?.icon ? stateData.icon : '/assets/video/exam_icon.svg', name: location.state.name, path: '/course-overview', pathState: location?.state } })
                  }
                >
                  <img
                    src="/assets/images/icons/apply_now.svg"
                    className="me-2 img-fluid"
                    alt="download"
                  />
                  <span className="white_ff font_16 font_600">Apply Now</span>
                </button>
              </div>
            </div>
          </div>
        </section>
        <section className="college_tabs">
          <div className="college_nav">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <ul
                    className="college_nav_tab nav nav-tabs"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link active"
                        id="Overviews-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Overviews"
                        type="button"
                        role="tab"
                        aria-controls="Overviews"
                        aria-selected="true"
                      >
                        Overview
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="apply-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#apply"
                        type="button"
                        role="tab"
                        aria-controls="apply"
                        aria-selected="false"
                      >
                        {location.state.name}
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="Syllabus-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Syllabus"
                        type="button"
                        role="tab"
                        aria-controls="Syllabus"
                        aria-selected="false"
                      >
                        Top Courses
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="mock-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#mock"
                        type="button"
                        role="tab"
                        aria-controls="mock"
                        aria-selected="false"
                      >
                        Entrance Exam
                      </button>
                    </li>
                    <li className="nav-item" role="presentation">
                      <button
                        className="font_16 nav-link"
                        id="Registration-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#Registration"
                        type="button"
                        role="tab"
                        aria-controls="Registration"
                        aria-selected="false"
                      >
                        Q&A
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="college_nav_content">
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade active show"
                      id="Overviews"
                      role="tabpanel"
                      aria-labelledby="Overviews-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12">
                          <div
                            dangerouslySetInnerHTML={{
                              __html: location.state.overview,
                            }}
                          />
                        </div>
                        <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="apply"
                      role="tabpanel"
                      aria-labelledby="apply-tab"
                    >
                      <div className="college_nav_content">
                        <div className="container">
                          <div className="row">
                            <div className="col-md-12">
                              <div className="row">
                                <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                                  <div className="information_box">
                                    <div className="information_box_heading">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/video/clg_trophy.svg"
                                        }
                                        className="img-fluid"
                                        alt="icon"
                                      />
                                      <h3 className="blue_29 font_500 font_25 mb-0">
                                        List of B.tech Colleges{" "}
                                      </h3>
                                    </div>
                                    <div className="row">
                                      {isCollegeData &&
                                        isCollegeData.map((item, index) => {
                                          return (
                                            <div
                                              className="col-md-12 col-12 mb-4"
                                              key={index}
                                            >
                                              <div className="colge_main">
                                                <div className="cource_collage">
                                                  <div className="rating_log">
                                                    <img
                                                      src={item.college_logo}
                                                      alt=""
                                                    />
                                                    <div className="rating">
                                                      {item.college_rating &&
                                                        item.college_rating.forEach(
                                                          (i) => {
                                                            if (i && i) {
                                                              sum += i.rate;
                                                              noOfRatings++;
                                                            }
                                                            averateRating =
                                                              sum / noOfRatings;
                                                          }
                                                        )}

                                                      {averateRating > 1 &&
                                                        averateRating < 2 ? (
                                                        <>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                        </>
                                                      ) : averateRating > 2 &&
                                                        averateRating < 3 ? (
                                                        <>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                        </>
                                                      ) : averateRating > 3 &&
                                                        averateRating < 4 ? (
                                                        <>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                        </>
                                                      ) : averateRating > 4 &&
                                                        averateRating < 5 ? (
                                                        <>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating-2.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                        </>
                                                      ) : averateRating <= 5 ? (
                                                        <>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                          <span>
                                                            <img
                                                              src="/assets/video/rating.svg"
                                                              alt=""
                                                            />
                                                          </span>
                                                        </>
                                                      ) : (
                                                        <></>
                                                      )}
                                                      <span className="text-white">
                                                        {parseInt(
                                                          averateRating
                                                        )}
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <img
                                                    src={item.banner_image}
                                                    alt=""
                                                  />
                                                </div>
                                                <div className="cource_collage_text">
                                                  <div className="clg_single">
                                                    <h4>{item.name}</h4>
                                                    <span>
                                                      <i className="fa fa-map-marker me-2" />
                                                      {item.location}
                                                    </span>
                                                    <p>
                                                      Approved by: UGC AICTE
                                                    </p>
                                                    <div className="list_single_clg">
                                                      <span>
                                                        <img
                                                          src="/assets/video/green_collage.svg"
                                                          alt=""
                                                        />
                                                        Admission
                                                      </span>
                                                      <span>
                                                        <img
                                                          src="/assets/video/green_graph.svg"
                                                          alt=""
                                                        />
                                                        Cut off
                                                      </span>
                                                      <span>
                                                        <img
                                                          src="/assets/video/green_recruitment.svg"
                                                          alt=""
                                                        />
                                                        Placement
                                                      </span>
                                                      <span>
                                                        <img
                                                          src="/assets/video/green_link.svg"
                                                          alt=""
                                                        />
                                                        Link
                                                      </span>
                                                    </div>
                                                  </div>
                                                  <div className="clg_btn ms-auto">
                                                    <Link
                                                      to={`/apply-now/colleges/${item._id}`}
                                                      className="btn btn-primary mb-2"
                                                      state={{ logo: item.banner_image, name: item.name, path: '/course-overview', pathState: location?.state }}
                                                    >
                                                      <img
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/assets/video/search_file.svg"
                                                        }
                                                        alt=""
                                                      />{" "}
                                                      Apply Now
                                                    </Link>
                                                    <a
                                                      href="#"
                                                      className="btn btn-outline"
                                                    >
                                                      <img
                                                        src={
                                                          process.env
                                                            .PUBLIC_URL +
                                                          "/assets/video/download_brosure.svg"
                                                        }
                                                        alt=""
                                                      />{" "}
                                                      Brochures
                                                    </a>
                                                  </div>
                                                </div>
                                              </div>
                                            </div>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                                <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                                  <UpdatedColleges />
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="Registration"
                      role="tabpanel"
                      aria-labelledby="Registration-tab"
                    >
                      <div className="container">
                        <div className="row">
                          <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12 mb-3 mb-md-0">
                            <div className="information_box">
                              <div className="information_box_heading">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/helpdesk.svg"
                                  }
                                  className="img-fluid"
                                  alt="icon"
                                />
                                <h3 className="blue_29 font_500 font_25 mb-0">
                                  Help Desk & Q&A
                                </h3>
                              </div>
                              <div className="information_box_body">
                                <form onSubmit={handleSubmit(onSubmit)}>
                                  <div className="your_input">
                                    <input
                                      type="text"
                                      className="form-control font_15"
                                      placeholder="Type Your Question"
                                      autoComplete="off"
                                      name="question"
                                      {...register("question", {
                                        required: 'Please enter question',
                                        pattern: {
                                          value: /^\S|.*\S.*$/,
                                          message: 'Spaces are not allowed in the question'
                                        }
                                      })}
                                    />{" "}
                                    {errors && errors?.question && <span style={{ color: "red" }}>{errors?.question?.message}</span>}
                                    <span style={{ color: "red" }}>
                                    </span>
                                  </div>
                                  <div className="your_input">
                                    <Select
                                      placeholder="Select Category"
                                      options={options}
                                      name="que_category"
                                      className="mt-4"
                                      isMulti
                                      {...register("que_category", { required: 'Please Select Category' })}
                                      onChange={handleCountryChange}
                                    />
                                    {/* {errors.que_category && <p>Please select at least one option</p>} */}
                                    {errors && errors?.que_category && <span style={{ color: "red" }}>{errors?.que_category?.message}</span>}
                                    {/* <select
                                      className="form-select"
                                      aria-label="Default select example"
                                      {...register("que_category")}
                                      multiple
                                    >
                                      <option selected>College</option>
                                      <option >Jobs</option>
                                      <option >Exams</option>
                                      <option >Results</option>
                                      <option >Education</option>
                                    </select> */}
                                  </div>
                                  <div className="your_btn">
                                    <button
                                      type="submit"
                                      className="edit_p_button font_14 inter"
                                    >
                                      Submit
                                    </button>
                                    <button
                                      type="reset"
                                      className="your_cancel_btn font_14 inter"
                                    >
                                      Cancel
                                    </button>
                                  </div>
                                </form>
                              </div>
                            </div>
                            <div className="information_box">
                              <div className="unanswer_list">
                                <div className="information_box_heading">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/questions.svg"
                                    }
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_25 mb-0">
                                    Questions |{" "}
                                    <span className="light_gray_8b">
                                      Unanswered
                                    </span>
                                  </h3>
                                </div>
                                <div className="sort_by d-flex align-items-center">
                                  <p className="font_500 font_18 gray_5a mb-0 inter">
                                    SORT BY:
                                  </p>
                                  <div className="sort_dropdown dropdown">
                                    {/* <button
                                                                className="btn dropdown-toggle inter black_14 font_15 font_500"
                                                                type="button" id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                Default Order
                                                            </button>
                                                            <ul className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Fees</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Newest</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Oldest</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Top Rated</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Recommended</a></li>
                                                            </ul> */}
                                    <select
                                      className="form-select mx-1"
                                      style={{ border: "1px solid grey" }}
                                      aria-label="Default select example"
                                    // value={sortValue}
                                    // onChange={(e) => {
                                    //   setSortValue(e.target.value);
                                    //   questionList(e.target.value);
                                    // }}
                                    >
                                      <option value="L" selected>
                                        Latest
                                      </option>
                                      <option value="O">Oldest</option>
                                    </select>
                                  </div>
                                </div>
                              </div>

                              <div className="information_box_body">
                                {/* <div
                              className="tab-pane fade"
                              id="news"
                              role="tabpanel"
                              aria-labelledby="news-tab"
                            > */}
                                {/* <div className="row"> */}
                                {/* <InfiniteScroll
                                  dataLength={questions?.length}
                                  next={questionList}
                                  hasMore={isFetching}
                                > */}
                                {questions?.map((item) => {
                                  return (
                                    <div className="col-12 mb-3" key={item?._id}>
                                      <div className="comment_section">
                                        <div className="comment_1">
                                          <div className="aq_user">
                                            <img src={item?.profile_image} />
                                          </div>
                                          <div className="aq_title">
                                            <h6>
                                              {item?.name}{" "}
                                              <a
                                                href="#"
                                                className="user_name ms-2"
                                              >
                                                @{item?.username}{" "}
                                              </a>
                                            </h6>
                                            <div className="sub_option_student">
                                              {item?.college_uni_name !== "" ||
                                                item?.college_uni_name !== null ? (
                                                <span>
                                                  {item?.college_uni_name && (
                                                    <img
                                                      src={
                                                        process.env.PUBLIC_URL +
                                                        "/assets/video/school.svg"
                                                      }
                                                      className="me-2"
                                                    />
                                                  )}
                                                  {item?.college_uni_name?.length <
                                                    35
                                                    ? item?.college_uni_name
                                                    : item?.college_uni_name?.slice(
                                                      0,
                                                      35
                                                    ) + "..."}
                                                </span>
                                              ) : (
                                                <></>
                                              )}
                                              <div className="list_of_tag mt-2 mb-2 ms-auto">
                                                {item?.category?.map((i) => {
                                                  return (
                                                    <>
                                                      <span className="font-13 tab_option mx-1">
                                                        {i}
                                                      </span>
                                                    </>
                                                  );
                                                })}
                                              </div>
                                              {item?.insertdate ? (
                                                <div className="time_text me-3 ms-2">
                                                  <p className="m-0">
                                                    {/* {dayjs(item?.insertdate).format(
                                                  "DD MMM HH:mm A"
                                                )} */}
                                                  </p>
                                                </div>
                                              ) : (
                                                <></>
                                              )}
                                              <div className="dropdown">
                                                {item?._id ===
                                                  localStorage?.getItem("_id") ? (
                                                  <>
                                                    <a
                                                      className="dropdown-toggle"
                                                      href="#"
                                                      role="button"
                                                      id="dropdownMenuLink"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <img
                                                        src={
                                                          process.env.PUBLIC_URL +
                                                          "/assets/video/more_horiz.svg"
                                                        }
                                                      />
                                                    </a>
                                                    <ul
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuLink"
                                                    >
                                                      <li>
                                                        <a
                                                          className="dropdown-item"
                                                          href="#"
                                                        >
                                                          Delete
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </>
                                                ) : (
                                                  <>
                                                    <a
                                                      className="dropdown-toggle"
                                                      role="button"
                                                      id="dropdownMenuLink"
                                                      data-bs-toggle="dropdown"
                                                      aria-expanded="false"
                                                    >
                                                      <img
                                                        src={
                                                          process.env.PUBLIC_URL +
                                                          "/assets/video/more_horiz.svg"
                                                        }
                                                      />
                                                    </a>
                                                    <ul
                                                      className="dropdown-menu"
                                                      aria-labelledby="dropdownMenuLink"
                                                    >
                                                      <li>
                                                        {/* <Link to={`/profile/${item.user_id}`} className="dropdown-item"> */}
                                                        <Link
                                                          to="/profile"
                                                          state={item.user_id}
                                                          className="dropdown-item"
                                                        >
                                                          Link @{item?.username}
                                                        </Link>
                                                      </li>
                                                      <li>
                                                        <a
                                                          className="dropdown-item"
                                                          href="javascript:void(0)"
                                                          onClick={() => { HandleReportUser(item?._id) }}
                                                        >
                                                          Report User
                                                        </a>
                                                      </li>
                                                    </ul>
                                                  </>
                                                )}
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                        <div
                                          className="mid_text"
                                          onClick={() =>
                                            navigate("/question", { state: item })
                                          }
                                          style={{ cursor: "pointer" }}
                                        >
                                          <p>
                                            {item?.description?.length < 100
                                              ? item?.description
                                              : item?.description?.slice(0, 100) +
                                              "..."}
                                          </p>
                                        </div>
                                        <div className="footer_text_qa">
                                          <div className="like_dislike d-flex align-items-center">
                                            <a
                                              className={`like me-3 ${item?.is_like === 1
                                                ? "text-green"
                                                : ""
                                                }`}
                                              onClick={() => {
                                                likeQa(item?._id);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              <img
                                                src={
                                                  item?.is_like === 1
                                                    ? process.env.PUBLIC_URL +
                                                    "/assets/video/like.svg"
                                                    : process.env.PUBLIC_URL +
                                                    "/assets/video/like-blank.svg"
                                                }
                                                className="me-1"
                                              />
                                              {item?.likes}
                                            </a>
                                            <a
                                              // className={`dislke me-3 ${
                                              //   item?.is_dislike === 1
                                              //     ? "text-red"
                                              //     : ""
                                              // }`}
                                              className={
                                                item?.is_dislike === 1
                                                  ? "dislke me-3 text-red"
                                                  : "dislke me-3"
                                              }
                                              onClick={() => {
                                                dislikeQa(item?._id);
                                              }}
                                              style={{ cursor: "pointer" }}
                                            >
                                              {item?.is_unlike === 1 ? (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/video/dislike.svg"
                                                  }
                                                  className="me-1"
                                                />
                                              ) : (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/video/dislike-blank.png"
                                                  }
                                                  className="me-1"
                                                />
                                              )}

                                              {item?.unlikes}
                                            </a>
                                            <a
                                              type="button"
                                              data-bs-target="#commentModal"
                                              data-bs-toggle="modal"
                                              className="me-3"
                                              onClick={() => { setCommentData(item); setCommentQaId(item._id); }}
                                            >
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/video/comment_brown.svg"
                                                }
                                                className="me-1"
                                              />{" "}
                                              Comment {item?.comment_count}
                                            </a>
                                            <a href="javascript:void(0)" className="" onClick={() => {
                                              handleShare(item);
                                            }}>
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/video/plane_brown.svg"
                                                }
                                                className="me-1"
                                              />
                                              Share
                                            </a>
                                          </div>
                                          {/* <div className="drop d-flex align-items-center">
                                  <a href="#">
                                    {item?.comment_count} Reply
                                  </a>
                                </div> */}
                                        </div>
                                      </div>
                                    </div>
                                    // <div className="question_body" key={item?._id}>
                                    //   <div className="questioner">
                                    //     <div className="questioner_image">
                                    //       <img
                                    //         src={item?.profile_image}
                                    //         className="img-fluid"
                                    //         alt="user"
                                    //       />
                                    //     </div>
                                    //     <div className="questioner_text">
                                    //       <div
                                    //         className="questioner_text_link"
                                    //         onClick={() => {
                                    //           // navigate(`/profile/${item?.user_id}`)
                                    //           navigate("/profile", {
                                    //             state: item?.user_id,
                                    //           });
                                    //         }}
                                    //         style={{ cursor: "pointer" }}
                                    //       >
                                    //         <h6 className="inter font_16 font_600 black_14 mb-0 me-2">
                                    //           -{item?.name}
                                    //         </h6>
                                    //         <span className="font_14 blue_29 d-flex align-items-center">
                                    //           <img
                                    //             src={
                                    //               process.env.PUBLIC_URL +
                                    //               "/assets/images/icons/verified.svg"
                                    //             }
                                    //             className="img-fluid"
                                    //             alt="verified"
                                    //           />
                                    //           Verified
                                    //         </span>
                                    //         <div className="sania_btn ms-auto">
                                    //           <button
                                    //             type="button"
                                    //             className="blue_button d-flex align-items-center font_12 font_500"
                                    //           >
                                    //             <span className="icon-link font_14 me-2 font_12"></span>
                                    //             Link
                                    //           </button>
                                    //         </div>
                                    //       </div>
                                    //       {item?.college_uni_name === "" ||
                                    //         item?.college_uni_name === undefined ||
                                    //         item?.college_uni_name === null ? (
                                    //         <p className="light_gray_8b inter font_14">
                                    //           {" "}
                                    //           <span className="black_14 font_500">
                                    //             {" "}
                                    //             <i>Updated on July 12, 2021</i>{" "}
                                    //           </span>
                                    //         </p>
                                    //       ) : (
                                    //         <p className="light_gray_8b inter font_14">
                                    //           {item?.college_uni_name}
                                    //           <span className="black_14 font_500">
                                    //             | <i>Updated on July 12, 2021</i>{" "}
                                    //           </span>
                                    //         </p>
                                    //       )}

                                    //       <p
                                    //         className="font_500 inter font_18 black_14 mb-0"
                                    //         onClick={() => {
                                    //           navigate(`/question`, {
                                    //             state: item,
                                    //           });
                                    //         }}
                                    //         style={{ cursor: "pointer" }}
                                    //       >
                                    //         {item?.description}
                                    //       </p>
                                    //     </div>
                                    //   </div>
                                    //   <div className="questioner_border"></div>
                                    //   <div className="row mt-3 mt-md-5">
                                    //     <div
                                    //       className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                                    //       onClick={() => {
                                    //         qaLike(item?._id, item?.is_like);
                                    //       }}
                                    //     >
                                    //       <button
                                    //         type="button"
                                    //         className={`questioner_green_btn inter font_500 font_15 ${item?.is_like === 0 ? "" : "active"
                                    //           }`}
                                    //       >
                                    //         <img
                                    //           src={
                                    //             process.env.PUBLIC_URL +
                                    //             "/assets/images/icons/green-like.svg"
                                    //           }
                                    //           className="img-fluid me-2"
                                    //           alt="like"
                                    //         />
                                    //         <span>Like</span>
                                    //       </button>
                                    //     </div>
                                    //     <div
                                    //       className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                                    //       onClick={() => {
                                    //         navigate(`/question/${item?._id}`, {
                                    //           state: { q: item },
                                    //         });
                                    //       }}
                                    //       style={{ cursor: "pointer" }}
                                    //     >
                                    //       <button
                                    //         type="button"
                                    //         className="questioner_green_btn inter font_500 font_15"
                                    //       >
                                    //         <img
                                    //           src={
                                    //             process.env.PUBLIC_URL +
                                    //             "/assets/images/icons/green-comment.svg"
                                    //           }
                                    //           className="img-fluid me-2"
                                    //           alt="like"
                                    //         />
                                    //         <span>Comments</span>
                                    //       </button>
                                    //     </div>
                                    //     <div
                                    //       className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                                    //       onClick={() => {
                                    //         navigate(`/question/${item?._id}`, {
                                    //           state: { q: item },
                                    //         });
                                    //       }}
                                    //       style={{ cursor: "pointer" }}
                                    //     >
                                    //       <button
                                    //         type="button"
                                    //         className="questioner_green_btn inter font_500 font_15"
                                    //       >
                                    //         <span className="answer_digit inter">
                                    //           {item?.comment_count}
                                    //         </span>
                                    //         <span>Answer</span>
                                    //       </button>
                                    //     </div>
                                    //     <div className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                    //       <button
                                    //         type="button"
                                    //         className="questioner_green_btn inter font_500 font_15"
                                    //       >
                                    //         <img
                                    //           src={
                                    //             process.env.PUBLIC_URL +
                                    //             "/assets/images/icons/green-share.svg"
                                    //           }
                                    //           className="img-fluid me-2"
                                    //           alt="like"
                                    //         />
                                    //         <span>Share</span>
                                    //       </button>
                                    //     </div>
                                    //   </div>
                                    // </div>
                                  );
                                })}
                                {/* </InfiniteScroll> */}
                                {/* </div> */}
                                {/* </div> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                            <UpdatedColleges />
                          </div>
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="Syllabus"
                      role="tabpanel"
                      aria-labelledby="Syllabus-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <div className="row">
                            <div className="col-sm-12 col-12 mb-4">
                              <div className="info_shadow">
                                <div className="search_filter w-100">
                                  <div className="search_left">
                                    <div className="search_bar">
                                      <input
                                        type="search"
                                        name=""
                                        placeholder="Search Courses"
                                        onChange={(e) =>
                                          getCourseData(e.target.value, "")
                                        }
                                      />
                                      <button>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/video/search.svg"
                                          }
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="search_right">
                                    <label className="mr-2">SORT BY:</label>
                                    <div className="filter_selection filter_r">
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) =>
                                          getCourseData("", e.target.value)
                                        }
                                      >
                                        <option value="">Default Order</option>
                                        <option value="L">Latest</option>
                                        <option value="P">Popular</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {isCourseData != "" && isCourseData != undefined ? (
                              <div className="col-sm-12 col-12 mb-4">
                                <div className="info_shadow">
                                  <div className="icon_shadow">
                                    <img
                                      src="/assets/video/c_icon.svg"
                                      alt=""
                                    />
                                  </div>
                                  <div className="cource_text_1">
                                    <div className="d-flex justify-content-between w-100">
                                      <h4>Diploma (MECHANICAL ENGINEERING) </h4>
                                      <div className="clg_btn ms-auto flex-row">
                                        <a
                                          href="#"
                                          className="btn btn-primary mb-2 me-2"
                                        >
                                          <img
                                            src="/assets/video/search_file.svg"
                                            alt=""
                                          />{" "}
                                          Apply Now
                                        </a>
                                        <a href="#" className="btn btn-outline">
                                          <img
                                            src="/assets/video/download_brosure.svg"
                                            alt=""
                                          />{" "}
                                          Sample Paper
                                        </a>
                                      </div>
                                    </div>
                                    <ul className="clg_schedule">
                                      <li>
                                        <h6>Exam Date</h6>
                                        <span>Mar 1, 2020</span>{" "}
                                      </li>
                                      <li>
                                        <h6>Application Form Date</h6>
                                        <span>Feb 23, 2020</span>{" "}
                                      </li>
                                      <li>
                                        <h6>Result Date</h6>
                                        <span>Feb 20, 2020</span>{" "}
                                      </li>
                                    </ul>
                                    <div className="cource_btn">
                                      <a
                                        href="#"
                                        className="btn btn-outline me-2"
                                      >
                                        Accepting Colleges
                                      </a>
                                      <a href="#" className="btn btn-outline">
                                        Accepting Form
                                      </a>
                                      <a
                                        href="#"
                                        className="btn btn-outline me-2"
                                      >
                                        Syallbus
                                      </a>
                                      <a href="#" className="btn btn-outline">
                                        Admit Card
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            ) : (
                              <h4>
                                Sorry, Currently we don't have any data for
                                future Courses
                              </h4>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>

                    <div
                      className="tab-pane fade"
                      id="mock"
                      role="tabpanel"
                      aria-labelledby="mock-tab"
                    >
                      <div className="row">
                        <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                          <div className="row">
                            <div className="col-sm-12 col-12 mb-4">
                              <div className="info_shadow">
                                <div className="search_filter w-100">
                                  <div className="search_left">
                                    <div className="search_bar">
                                      <input
                                        type="search"
                                        name=""
                                        placeholder="Search State"
                                        onChange={(e) =>
                                          getExamData(e.target.value, "")
                                        }
                                      />
                                      <button>
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/video/search.svg"
                                          }
                                          alt=""
                                        />
                                      </button>
                                    </div>
                                  </div>
                                  <div className="search_right">
                                    <label className="mr-2">SORT BY:</label>
                                    <div className="filter_selection filter_r">
                                      <select
                                        className="form-select"
                                        aria-label="Default select example"
                                        onChange={(e) =>
                                          getExamData("", e.target.value)
                                        }
                                      >
                                        <option value="">Default Order</option>
                                        <option value="L">Latest</option>
                                        <option value="P">Popular</option>
                                      </select>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            </div>
                          </div>
                          <div className="row">
                            {isExamData != undefined && isExamData != "" ? (
                              <>
                                {isExamData &&
                                  isExamData.map((item, index) => {
                                    return (
                                      <div
                                        className="col-sm-12 col-12 mb-4"
                                        key={index}
                                      >
                                        <div className="info_shadow">
                                          <div className="icon_shadow">
                                            <img src={item.icon} alt="" />
                                          </div>
                                          <div className="cource_text_1">
                                            <div className="d-flex justify-content-between w-100">
                                              <h4>{item.name} </h4>
                                              <div className="clg_btn ms-auto flex-row">
                                                <a
                                                  type="button"
                                                  onClick={() =>
                                                    navigate(
                                                      `/apply-now/courses/${item._id}`,
                                                      { state: { logo: item.icon, name: item.name, path: '/course-overview', pathState: location?.state } }
                                                    )
                                                  }
                                                  className="btn btn-primary mb-2 me-2"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/search_file.svg"
                                                    }
                                                    alt=""
                                                  />{" "}
                                                  Apply Now
                                                </a>
                                                <a
                                                  href={item.sample_paper}
                                                  target="_blank"
                                                  className="btn btn-outline"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/download_brosure.svg"
                                                    }
                                                    alt=""
                                                  />{" "}
                                                  Sample Paper
                                                </a>
                                              </div>
                                            </div>
                                            <ul className="clg_schedule">
                                              <li>
                                                <h6>Exam Date</h6>
                                                <span>
                                                  {moment(
                                                    item.created_at
                                                  ).format("MMM DD, YYYY")}
                                                </span>{" "}
                                              </li>
                                              <li>
                                                <h6>Application Form Date</h6>
                                                <span>
                                                  {moment(
                                                    item.application_form_date
                                                  ).format("MMM DD, YYYY")}
                                                </span>{" "}
                                              </li>
                                              <li>
                                                <h6>Result Date</h6>
                                                <span>
                                                  {moment(
                                                    item.result_date
                                                  ).format("MMM DD, YYYY")}
                                                </span>{" "}
                                              </li>
                                            </ul>
                                            <div className="cource_btn">
                                              {item &&
                                                item.degree.map((i, j) => {
                                                  return (
                                                    <a className="btn btn-outline me-2" key={j}>
                                                      {i.name}
                                                    </a>
                                                  );
                                                })}
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                    );
                                  })}
                              </>
                            ) : (
                              <h4>
                                Sorry, we don't have data for now. we update it
                                soon!!
                              </h4>
                            )}
                          </div>
                        </div>
                        <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                          <UpdatedColleges />
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
