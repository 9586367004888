import React, { useEffect, useState } from 'react'
// import { signup } from '../../api/apiHandler';
// import { v4 as uuid } from 'uuid';
// import { bodyEncryption } from '../../api/apiClient';
import { Link, useNavigate } from 'react-router-dom';
// import { faRegular, faHashtag } from "@fortawesome/free-solid-svg-icons";
// import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { NotificationContainer, NotificationManager } from 'react-notifications';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { add_other_details, get_topic_list } from '../../api/apiHandler';
import Header from '../Header';

export default function Linkhashtag() {
    // window.scrollTo(0, 0);
    // const [signedup, setSignedup] = useState(false);
    const [showTopics, setShowTopics] = useState([]);
    const [hashtagData, setHashtagData] = useState([]);
    const [finalTopics, setFinalTopics] = useState([]);
    const navigate = useNavigate();

    const topicList = () => {
        get_topic_list({}).then((response) => {
            console.log("Line 22", response);
            setShowTopics(response.data);
        })
    }
    useEffect(() => {
        if (localStorage.getItem("step") != "3") {
            navigate('/')
        }
    }, [navigate])
    const manage = () => {
        if (localStorage.getItem("step") === 3 || localStorage.getItem("step") === "3") {
            window.location.href = "/";
        } else {
            toast.warn("Please do signup before processing further", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: "link-hashtag"
            });
            setTimeout(() => {
                navigate("/sign-up");
            }, 3000)
        }
    }
    const addOtherData = () => {
        add_other_details({
            profile_image: "default.jpeg",
            topics: hashtagData
        }).then((response) => {
            console.log("Line 33 addOtherData", response);
            if (response.code === 1 || response.code === "1") {
                setFinalTopics(response.topics);
                localStorage.getItem("profile_image", response?.data?.profile_image);
                window.location.href = "/";
            }
        })
    }
    // const unique_id = uuid();
    // const small_id = unique_id.slice(0, 8);
    // const validationSchema = yup.object().shape({
    //     hashtag: yup.string()
    //         .required('Please select at least one hashtag')
    // });
    // const formOptions = { resolver: yupResolver(validationSchema) };
    // const { register, handleSubmit, formState } = useForm(formOptions);
    // const { errors } = formState;
    // const [checked, setChecked] = useState([]);
    // const [isChecked, setIsChecked] = useState(false);
    const myStyle = { borderColor: "transparent", background: "rgba(41, 171, 226, 0.2)", color: "#29ABE2", cursor: "pointer" }
    // var newData = []
    const handleCheck = (event) => {
        if (hashtagData.includes(event)) {
            let temp = hashtagData.filter((o) => {
                if (o !== event) {
                    return o;
                }
            })
            setHashtagData(temp);
        }
        else {
            var newData2 = [...hashtagData, event];
            setHashtagData(newData2);
            // navigate("/");
        }
    };
    const onSubmit = (e) => {
        e.preventDefault();
        if (hashtagData.length === 0) {
            // NotificationManager.error("Please select at least one hashtag");
            toast.error("Please select at least one hashtag", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: "link-hashtag"
            });
        } else {
            addOtherData()
            // navigate("/");

        }
    }

    useEffect(() => {
        topicList();
    }, [])

    return (
        <>
            {
                localStorage.getItem("step") == "3" && (
                    <>
                        <Header />
                        <section className="main_signup">
                            {/* <NotificationContainer /> */}
                            <ToastContainer
                                position="top-center"
                                autoClose={2000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                            />
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="font_30 font_600 text-center signup_heading_border_blue">Sign Up Your <span
                                            className="blue_29">Account</span></h1>
                                        <p className="inter font_18 text-center"><q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt.</q></p>
                                    </div>
                                </div>
                                <div className="form__container">
                                    <div className="row">
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div className="left__container">
                                                <div className="form_vector">
                                                    <img src={process.env.PUBLIC_URL + "assets/images/icons/vector-form.svg"} className="img-fluid" alt="vector" />
                                                </div>
                                                <h6 className="font_26 black_00 font_600">Step 6</h6>
                                                <p className="gray_5a font_16 font_500">Add Hashtags.</p>
                                                <div className="steps d-md-flex">
                                                    <div className="progress__bar__container">
                                                        <ul className="ps-0">
                                                            <li className="active" id="icon1">
                                                                <span>1</span>
                                                            </li>
                                                            <li id="icon2" className="active">
                                                                <span>2</span>
                                                            </li>
                                                            <li id="icon3" className="active">
                                                                <span>3</span>
                                                            </li>
                                                            <li id="icon4" className="active">
                                                                <span>4</span>
                                                            </li>
                                                            <li id="icon5" className="active">
                                                                <span>5</span>
                                                            </li>
                                                            <li id="icon6" className="active">
                                                                <span>6</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="side__titles">
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Basic Details</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">OTP Verification</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Select UserName</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Select College</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Find Friends</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 mb-0 active">Link Hashtags</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 position-relative">
                                            <div className="right_form_1 right__container">
                                                <div id="form6">
                                                    <div className="form_heading">
                                                        <h4 className="font_25 font_600 blue_29">Link hashtag</h4>
                                                        <p className="mb-0"><q>Link hashtag will show the post based on the selected hashtag</q></p>
                                                    </div>

                                                    <div className="imoji">
                                                        <ion-icon name="phone-portrait"></ion-icon>
                                                    </div>
                                                    <form onSubmit={(e) => onSubmit(e)}>
                                                        <div className="row justify-content-center w-100 my-auto">
                                                            <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                                <div className="step_1_form">
                                                                    <div className="signup_last_tab">
                                                                        <div className="row">
                                                                            <div className="col-md-12">
                                                                                <div className="hashtags_link">
                                                                                    <div className="hashtags_link_one">
                                                                                        {showTopics.slice(0, 4).map((item, key) => {
                                                                                            return (
                                                                                                <div className="link_name" key={item._id} style={hashtagData.includes(item._id) ? myStyle : {}} onClick={() => { handleCheck(item._id) }}>#{item.name}</div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                    <div className="hashtags_link_two">
                                                                                        {showTopics.slice(4, 7).map((item, key) => {
                                                                                            return (
                                                                                                <div className="link_name" key={item._id} style={hashtagData.includes(item._id) ? myStyle : {}} onClick={() => { handleCheck(item._id) }}>#{item.name}</div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                    <div className="hashtags_link_one">
                                                                                        {showTopics.slice(7, 11).map((item, key) => {
                                                                                            return (
                                                                                                <div className="link_name" key={item._id} style={hashtagData.includes(item._id) ? myStyle : {}} onClick={() => { handleCheck(item._id) }}>#{item.name}</div>
                                                                                            )
                                                                                        })}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <a onClick={() => manage()} className="blue_29 float-end cursor-pointer">Skip</a>
                                                                    <div className="buttons_form_1 buttons">
                                                                        <Link to="/friends" className="font_15 prev__btn d-flex align-items-center"
                                                                        ><img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/prev.svg"} className="img-fluid"
                                                                                alt="icon" />Previous</Link>
                                                                        <button type='submit' className="font_15 nxt__btn blue_button d-flex align-items-center"
                                                                        >Next <img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/next.svg"} className="img-fluid"
                                                                                alt="icon" /> </button>
                                                                    </div>

                                                                    {/* <div className="row justify-content-center">
                                                        <div className="col-12 col-xl-8">
                                                            <button type="button"
                                                                className="signup_blue_btn font_22 font_600">Sign
                                                                Up</button>
                                                            <p className="text-center">Do you Have already account ? <span
                                                                    className="blue_29 font_17 font_600">Sign In</span></p>
                                                        </div>
                                                    </div> */}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </>
                )
            }
        </>
    )
}
