import React, { useEffect, useState } from "react";
import { top_contribution, add_friend, remove_follow } from "../api/apiHandler";
import { Link } from "react-router-dom";
import { Tooltip } from "@material-ui/core";
import { showMessage } from "../common/RedirectPathManage";

const Topcontributors = ({ className }) => {
  const [contributerList, setContributerList] = useState(null);

  const topContributerList = () => {
    top_contribution({}).then((response) => {
      if (response?.code === 1 || response?.code === "1") {
        // console.log(response, "response");
        setContributerList(response?.data);
      }
    });
  };

  const RemoveFriend = (id) => {
    remove_follow({ friend_id: id }).then((res) => {
      console.log(res, "response>>>>");
      if (res.code == 1) {
        setContributerList(res?.data);
      }
    });
  };

  const AddFriend = (id) => {
    add_friend({ friend_id: id }).then((res) => {
      if (res.code == 1) {
        showMessage(res.message);
        topContributerList();
      }
    });
  };

  useEffect(() => {
    topContributerList();
  }, []);

  return (
    <div
      className={`col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3 mb-md-0 ${className}`}
    >
      <div className="row">
        <div className="col-12 mb-3">
          <div className="sidebar_widget">
            <div className="widget_title">
              <h4>
                {" "}
                <span>Top Contributors</span>
              </h4>
            </div>
            <div className="widget_list">
              <ul>
                {contributerList &&
                  contributerList.map((item, index) => {
                    return (
                      <li key={index}>
                        <div className="user-img-name-div">
                          <div className="user_img_list">
                            {/* <Link to={`/profile/${item.user_id}`}> */}
                            <Link to="/profile" state={item.user_id}>
                              <img
                                src={
                                  item.profile_image != undefined
                                    ? item.profile_image
                                    : process.env.PUBLIC_URL +
                                      "/assets/video/user_imag.png"
                                }
                              />
                            </Link>
                          </div>
                          <div className="user_img_text">
                            {/* <Link to={`/profile/${item.user_id}`}> */}
                            <Link to="/profile" state={item.user_id}>
                              <Tooltip title={item.name}>
                                <h6>
                                  {item.name != undefined && item.name != ""
                                    ? item.name.slice(0, 10) + "..."
                                    : "--"}
                                </h6>
                              </Tooltip>
                            </Link>
                            <Tooltip title={item.college_uni_name}>
                              <span id={`my-tooltip-${index}`}>
                                <img
                                  className="me-1"
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/video/school.svg"
                                  }
                                />
                                {item.college_uni_name != undefined &&
                                item.college_uni_name != ""
                                  ? item.college_uni_name.slice(0, 12) + "..."
                                  : "--"}
                              </span>
                            </Tooltip>
                            {/* <ReactTooltip
                            id={`my-tooltip-${index}`}
                            place="bottom"
                            content={item.college_uni_name}
                          /> */}
                          </div>
                        </div>
                        <div className="user_action">
                          <span>
                            <img
                              src={
                                process.env.PUBLIC_URL + "/assets/video/up.svg"
                              }
                              className="me-1"
                            />
                            {item.count}
                          </span>
                          {/* <a className="text-white d-flex align-items-center flex-row">
                                                          <img className="me-1" src={ process.env.PUBLIC_URL + "/assets/video/user.svg" }/>
                                                          Link
                                                      </a> */}
                          {localStorage?.getItem("_id") === item?.user_id ? (
                            <></>
                          ) : item?.has_friend === 0 ? (
                            <a
                              className="text-white d-flex align-items-center flex-row"
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/user.svg"
                                }
                                className="me-2"
                              />
                              Req
                            </a>
                          ) : item?.has_friend === 1 ? (
                            <a
                              className="text-white d-flex align-items-center flex-row"
                              style={{ cursor: "pointer" }}
                              onClick={() => {
                                RemoveFriend(item?.id);
                              }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/user.svg"
                                }
                                className="me-2"
                              />
                              Linked
                            </a>
                          ) : item?.has_friend === 2 ? (
                            <></>
                          ) : item?.has_friend === 3 ? (
                            <a
                              className="text-white d-flex align-items-center flex-row"
                              onClick={() => {
                                AddFriend(item?._id);
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/video/user.svg"
                                }
                                className="me-2"
                              />
                              Link
                            </a>
                          ) : (
                            <></>
                          )}
                        </div>
                      </li>
                    );
                  })}
              </ul>
            </div>
          </div>
        </div>
        {/* <div className="col-12 mb-3">
                          <div className="ride_sidebar_widget">
                              <div className="widget_title">
                                  <h4>
                                      <span>Related Questions</span>
                                  </h4>
                              </div>
                              <div className="tab_main tab_custom_list">
                                  <div className="accordion" id="accordionExample">
                                      <div className="accordion-item">
                                          <h2 className="accordion-header " id="headingOne">
                                              <button
                                                  className="accordion-button"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target="#collapseOne"
                                                  aria-expanded="true"
                                                  aria-controls="collapseOne"
                                              >
                                                  Which is the best college in India for B. Tech?
                                              </button>
                                          </h2>
                                          <div
                                              id="collapseOne"
                                              className="accordion-collapse collapse show"
                                              aria-labelledby="headingOne"
                                              data-bs-parent="#accordionExample"
                                          >
                                              <div className="accordion-body">
                                                  <p>Lorem Ipsum is simply dummy text </p>
                                                  <p>Lorem Ipsum is simply dummy text </p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingTwo">
                                              <button
                                                  className="accordion-button collapsed"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target="#collapseTwo"
                                                  aria-expanded="false"
                                                  aria-controls="collapseTwo"
                                              >
                                                  Which is the best college in India for B. Tech?
                                              </button>
                                          </h2>
                                          <div
                                              id="collapseTwo"
                                              className="accordion-collapse collapse"
                                              aria-labelledby="headingTwo"
                                              data-bs-parent="#accordionExample"
                                          >
                                              <div className="accordion-body">
                                                  <p>
                                                      Lorem Ipsum is simply dummy text of the printing and
                                                      typesetting industry. Lorem Ipsum has been the
                                                      industry's standard dummy text
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                      <div className="accordion-item">
                                          <h2 className="accordion-header" id="headingThree">
                                              <button
                                                  className="accordion-button collapsed"
                                                  type="button"
                                                  data-bs-toggle="collapse"
                                                  data-bs-target="#collapseThree"
                                                  aria-expanded="false"
                                                  aria-controls="collapseThree"
                                              >
                                                  Which is the best college in India for B. Tech?
                                              </button>
                                          </h2>
                                          <div
                                              id="collapseThree"
                                              className="accordion-collapse collapse"
                                              aria-labelledby="headingThree"
                                              data-bs-parent="#accordionExample"
                                          >
                                              <div className="accordion-body">
                                                  <p>
                                                      Lorem Ipsum is simply dummy text of the printing and
                                                      typesetting industry. Lorem Ipsum has been the
                                                      industry's standard dummy text
                                                  </p>
                                              </div>
                                          </div>
                                      </div>
                                  </div>
                              </div>
                          </div>
                      </div> */}
      </div>
    </div>
  );
};

export default Topcontributors;
