import React, { useEffect, useState } from "react";
import HeaderProfile from "./HeaderProfile";
import Header from "./Header";
import {
  accept_reject_request,
  get_friend_request_list,
} from "../api/apiHandler";
import Recommendation from "../common/Recommendation";
import { toast } from "react-toastify";

export default function FriendRequestList() {
  const [requestList, setRequestList] = useState([]);
  const friendRequestList = () => {
    get_friend_request_list({ page_token: "1" }).then((response) => {
      console.log("Line 11 friend request list", response);
      if (response.code === 1 || response.code === "1") {
        setRequestList(response.data);
      } else if (response?.code === 2 || response?.code === "2") {
        setRequestList(response.data);
      }
    });
  };
  useEffect(() => {
    friendRequestList();
  }, []);

  const actionFriend = (action, action_id, user_id) => {
    accept_reject_request({
      id: action_id,
      friend_id: user_id,
      type: action,
    }).then((response) => {
      console.log("Accept reject Response", response);
      if (response?.code === 1 || response?.code === "1") {
        toast.success(response.message, {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        friendRequestList();
      }
    });
  };

  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="main_notification">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <h1 className="font_600 font_20 mb-0">
                {" "}
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/find-request-black.svg"
                  }
                  className="img-fluid me-2"
                  alt="notification"
                />
                Find Requests
              </h1>

              <ul className="mathilda_ul">
                {requestList.length > 0 ? (
                  requestList?.map((item) => {
                    return (
                      <li>
                        <div className="mathila_profile" key={item?.user_id}>
                          <div className="mathila_image">
                            <img
                              src={item?.profile_image}
                              className="img-fluid"
                              alt="user"
                            />
                          </div>
                          <div className="mathila_text">
                            <div className="d-flex align-items-center">
                              <p className="inter font_18 mb-0 font_500 me-1 me-sm-2 me-md-3">
                                {item?.name}
                              </p>
                              {/* <span className="font_14 blue_29 d-flex align-items-center"><img
                                                                src={process.env.PUBLIC_URL+"/assets/images/icons/verified.svg"} className="img-fluid"
                                                                alt="verified" />Verified</span> */}
                            </div>
                            <span className="light_gray_8b font_12 inter">
                              @{item?.username}
                            </span>
                            {/* <div className="mathila_location_uni d-flex align-items-center mt-1">
                                                        <div className="yellow_location d-flex align-items-center">
                                                            <img src={process.env.PUBLIC_URL+"/assets/images/icons/location.svg"} className="img-fluid me-1"
                                                                alt="icon" />
                                                            <span className="font_12 inter light_gray_8b">Phagwara</span>
                                                        </div>
                                                        <p
                                                            className="phagwara_text font_12 gray_5a inter d-flex align-items-center mb-0 ms-2 ms-sm-4">
                                                            <img src={process.env.PUBLIC_URL+"/assets/images/icons/university.svg"} className="img-fluid me-2"
                                                                alt="college" />lovely professional university</p>
                                                    </div> */}
                          </div>
                        </div>
                        <div className="mathila_linked ">
                          {item?.has_friend === 1 ? (
                            <div className="link_buttons">
                              <button
                                type="button"
                                className="font_12 link_button_small linked_button blue_button"
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/link-user.svg"
                                  }
                                  className="me-1 img-fluid"
                                  alt="icon"
                                />{" "}
                                Linked
                              </button>
                            </div>
                          ) : (
                            <div className="link_buttons">
                              <button
                                type="button"
                                onClick={() =>
                                  actionFriend(
                                    "accept",
                                    item?.action_id,
                                    item?.user_id
                                  )
                                }
                                className="font_12 link_button_small linked_button blue_button"
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/link-user.svg"
                                  }
                                  className="me-1 img-fluid"
                                  alt="icon"
                                />{" "}
                                Link
                              </button>
                              <button
                                type="button"
                                onClick={() =>
                                  actionFriend(
                                    "reject",
                                    item?.action_id,
                                    item?.user_id
                                  )
                                }
                                className="font_12 cancel_button_small link_button_small blue_button"
                              >
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/link-cancel.svg"
                                  }
                                  className="me-1 img-fluid"
                                  alt="icon"
                                />{" "}
                                Cancel
                              </button>
                            </div>
                          )}
                        </div>
                      </li>
                    );
                  })
                ) : (
                  <li>
                    <h6> No Friend Request Found</h6>
                  </li>
                )}
              </ul>
            </div>
            <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
              <div className="trending_admission_box mb-5">
                <div className="profile_trending trending_admission_box_heading">
                  <h6 className="font_20 font_600 mb-0">
                    Recommended <span className="blue_29">Profiles</span>
                  </h6>
                </div>
                {/* <div className="profile_trending_box_body trending_admission_box_body">
                                <ul className="list-unstyled">
                                    <li>
                                        <div className="chandi_admission justify-content-between">
                                            <div className="cut_off_uni d-flex">
                                                <div className="uni_profile_image">
                                                    <img src={process.env.PUBLIC_URL+"/assets/images/user/dp-1.svg"} className="img-fluid"
                                                        alt="image" />
                                                </div>
                                                <div className="sania_name">
                                                    <h6 className="inter font_400 font_15 mb-0">Alex Khotanras</h6>
                                                    <p className="mb-0 font_10 light_gray_8b">stulink@Alex Khotanras</p>
                                                    <span className="blue_29 font_12 d-flex align-items-baseline"> <img
                                                            src={process.env.PUBLIC_URL+"/assets/images/icons/school-blue.svg"}
                                                            className="me-1 img-fluid" alt="icon" /> Punjab University</span>
                                                </div>
                                            </div>
                                            <div className="sania_btn ms-auto">
                                                <button type="button"
                                                    className="blue_button d-flex align-items-center font_12 font_500">
                                                    <span className="icon-link font_14 me-2 font_12"></span>
                                                    Link
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chandi_admission justify-content-between">
                                            <div className="cut_off_uni d-flex">
                                                <div className="uni_profile_image">
                                                    <img src={process.env.PUBLIC_URL+"/assets/images/user/member-2.svg"} className="img-fluid"
                                                        alt="image" />
                                                </div>
                                                <div className="sania_name">
                                                    <h6 className="inter font_400 font_15 mb-0">Lovepreet Kaur</h6>
                                                    <p className="mb-0 font_10 light_gray_8b">stulink@Alex Khotanras</p>
                                                    <span className="blue_29 font_12 d-flex align-items-baseline"> <img
                                                            src={process.env.PUBLIC_URL+"/assets/images/icons/school-blue.svg"}
                                                            className="me-1 img-fluid" alt="icon" />Chandigarh
                                                        University</span>
                                                </div>
                                            </div>
                                            <div className="sania_btn ms-auto">
                                                <button type="button"
                                                    className="blue_button d-flex align-items-center font_12 font_500">
                                                    <span className="icon-link font_14 me-2 font_12"></span>
                                                    Link
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chandi_admission justify-content-between">
                                            <div className="cut_off_uni d-flex">
                                                <div className="uni_profile_image">
                                                    <img src={process.env.PUBLIC_URL+"/assets/images/user/member-3.svg"} className="img-fluid"
                                                        alt="image" />
                                                </div>
                                                <div className="sania_name">
                                                    <h6 className="inter font_400 font_15 mb-0">Peeti Khotanras</h6>
                                                    <p className="mb-0 font_10 light_gray_8b">stulink@Alex Khotanras</p>
                                                    <span className="blue_29 font_12 d-flex align-items-baseline"> <img
                                                            src={process.env.PUBLIC_URL+"/assets/images/icons/school-blue.svg"}
                                                            className="me-1 img-fluid" alt="icon" />Indian Institute of
                                                        Technology Bombay </span>
                                                </div>
                                            </div>
                                            <div className="sania_btn ms-auto">
                                                <button type="button"
                                                    className="blue_button d-flex align-items-center font_12 font_500">
                                                    <span className="icon-link font_14 me-2 font_12"></span>
                                                    Link
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                    <li>
                                        <div className="chandi_admission justify-content-between">
                                            <div className="cut_off_uni d-flex">
                                                <div className="uni_profile_image">
                                                    <img src={process.env.PUBLIC_URL+"/assets/images/user/member-4.svg"} className="img-fluid"
                                                        alt="image" />
                                                </div>
                                                <div className="sania_name">
                                                    <h6 className="inter font_400 font_15 mb-0">Alex Khotanras</h6>
                                                    <p className="mb-0 font_10 light_gray_8b">stulink@Alex Khotanras</p>
                                                    <span className="blue_29 font_12 d-flex align-items-baseline"> <img
                                                            src={process.env.PUBLIC_URL+"/assets/images/icons/school-blue.svg"}
                                                            className="me-1 img-fluid" alt="icon" />Lovely Professional
                                                        University</span>
                                                </div>
                                            </div>
                                            <div className="sania_btn ms-auto">
                                                <button type="button"
                                                    className="blue_button d-flex align-items-center font_12 font_500">
                                                    <span className="icon-link font_14 me-2 font_12"></span>
                                                    Link
                                                </button>
                                            </div>
                                        </div>
                                    </li>
                                </ul>
                            </div> */}
                <Recommendation />
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
