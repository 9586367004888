import React, { useEffect, useState } from "react";
import { add_friend, friend_suggestion_list } from "../api/apiHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";
import { showMessage } from "./RedirectPathManage";
import { Tooltip } from "@material-ui/core";
import { Link } from "react-router-dom";

export default function Recommendation() {
  const [friendRecommends, setFriendRecommends] = useState([]);
  console.log(friendRecommends, "friendRecommends");
  const addFriend = (friend_id) => {
    add_friend({ friend_id: friend_id }).then((response) => {
      console.log("Line 450", response);
      if (response.code === 1 || response.code === "1") {
        showMessage(response.message);
        recommendedList();
      }
    });
  };
  const recommendedList = () => {
    friend_suggestion_list({}).then((response) => {
      console.log("Recommended Friends", response);
      if (response?.code === 1 || response?.code === "1") {
        setFriendRecommends(response?.data);
      }
    });
  };
  useEffect(() => {
    recommendedList();
  }, []);
  return (
    <>
      <div className="profile_trending_box_body trending_admission_box_body">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <ul className="list-unstyled">
          {friendRecommends?.slice(0, 4)?.map((item) => {
            return (
              <>
                <li>
                  <div
                    className="chandi_admission justify-content-between"
                    key={item?._id}
                  >
                    <Link
                      to={"/profile"}
                      state={item?._id}
                      className="cut_off_uni d-flex text-dark"
                    >
                      <div className="uni_profile_image">
                        <img
                          src={item?.profile_image}
                          className="img-fluid"
                          alt="image"
                        />
                      </div>
                      <div className="sania_name">
                        <Tooltip title={item?.name}>
                          <h6 className="inter font_400 font_15 mb-0">
                            {item?.name.length < 8
                              ? item?.name
                              : item?.name.slice(0, 8) + "..."}
                          </h6>
                        </Tooltip>
                        <p className="mb-0 font_10 light_gray_8b">
                          @{item?.username}
                        </p>
                        {!item?.college_uni_name ? (
                          <></>
                        ) : (
                          <Tooltip title={item?.college_uni_name}>
                            <span className="blue_29 font_12 d-flex align-items-baseline">
                              {" "}
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/school-blue.svg"
                                }
                                className="me-1 img-fluid"
                                alt="icon"
                              />{" "}
                              {item?.college_uni_name.length < 10
                                ? item?.college_uni_name
                                : item?.college_uni_name.slice(0, 10) + "..."}
                            </span>
                          </Tooltip>
                        )}
                      </div>
                    </Link>
                    <div
                      className="sania_btn ms-auto"
                      onClick={() => addFriend(item?._id)}
                    >
                      <button
                        type="button"
                        className="blue_button d-flex align-items-center font_12 font_500"
                      >
                        <span className="icon-link font_14 me-2 font_12"></span>
                        Link
                      </button>
                    </div>
                  </div>
                </li>
              </>
            );
          })}
        </ul>
      </div>
    </>
  );
}
