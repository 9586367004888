import React, { useCallback } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  accept_reject_request,
  add_friend,
  not_interested,
  remove_follow,
  report_post,
  save_post,
} from "../../../../api/apiHandler";
import { toast } from "react-toastify";

const ReelAction = (props) => {
  const {
    reelAction,
    setReelAction,
    singlePost = {},
    handleShare,
    setSinglePost,
    isReel,
    setIsReel,
  } = props;
  console.log("singlePost:====>Reel ", singlePost);

  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const customStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : 400, // Adjust the width for small screens
    maxWidth: "100%",
    bgcolor: "#ffffff",
    boxShadow: 24,
    borderRadius: "10px",
    // p: 2,
  };

  const handleSavePost = useCallback(
    (item) => {
      save_post({
        post_id: item._id,
        type: item?.is_saved == "N" ? "save" : "remove",
      }).then((response) => {
        console.log("response: ", response);
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          // setSinglePost(item?.is_saved == "N" ? "Y" : "N");
          const updatedPosts = isReel?.map((post) => {
            if (post?._id == item?._id) {
              post.is_saved = item?.is_saved == "N" ? "Y" : "N";
            }
            return post;
          });
          setIsReel(updatedPosts);
          setReelAction(false);
        }
      });
    },
    [isReel, setIsReel, setReelAction]
  );

  const actionFriend = (action, action_id, user_id) => {
    accept_reject_request({
      id: action_id,
      friend_id: user_id,
      type: action,
    }).then((response) => {
      console.log("response: actionFriend", response);
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          toastId: "Action",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const UpdateOthersLinkingOutList = isReel?.map((item) => {
          if (item?.user_id == response.data.friend_id) {
            item.has_friend = response.data.type === "reject" ? 3 : 1;
          }
          return item;
        });

        setIsReel(UpdateOthersLinkingOutList);
        setReelAction(false);
      }
    });
  };

  const removeFollow = (payload) => {
    remove_follow(payload).then((response) => {
      console.log("Remove friend+++", response);
      toast.info(response.message, {
        toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      if (response.code === 1 || response.code === "1") {
        const updateList = isReel?.map((item) => {
          if (item?.user_id == response.data.friend_id) {
            item.has_friend = 3;
          }
          return item;
        });

        setIsReel(updateList);
        setReelAction(false);
      }
    });
  };

  const addFriend = (payload) => {
    add_friend(payload).then((response) => {
      console.log("response: AddFriend", response);
      if (response.code === 1 || response.code === "1") {
        toast.info("Linking request was sent successfully.", {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const updateList = isReel?.map((item) => {
          if (item?.user_id == response.data.friend_id) {
            item.has_friend = item?.account_type === "private" ? 0 : 1;
            item.action_id = response.data.action_id;
          }
          return item;
        });

        setIsReel(updateList);
        setReelAction(false);
      }
    });
  };

  const notInterested = (item) => {
    not_interested({ id: item._id, type: "post" }).then((response) => {
      if (response.code == 1) {
        // setFeeds([])
        toast.success(response.message, {
          toastId: "Not interested",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
          setReelAction(false);
      }
    });
  };

  const reportPost = (id) => {
    report_post({ id }).then((response) => {
      console.log("response: block ", response);
      if (response.code == 1) {
        toast.success(response.message, {
          //   toastId: "Delete Post",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setReelAction(false);
      }
    });
  };

  return (
    <Modal
      open={reelAction}
      onClose={() => {
        setSinglePost(false);
        setReelAction(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={customStyles}>
        <ul className="p-0 m-0 post-menu-custom">
          <>
            <li
              className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
              style={{ padding: "13px 0", fontWeight: 500 }}
              onClick={() => {
                handleShare({ ...singlePost, module_type: "reel" });
                setReelAction(false);
              }}
            >
              Share
            </li>
            {singlePost?.is_saved == "N" ? (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  handleSavePost(singlePost);
                }}
              >
                Save
              </li>
            ) : (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  handleSavePost(singlePost);
                }}
              >
                Unsave
              </li>
            )}
            {singlePost?.user_id != localStorage.getItem("_id") && (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  singlePost?.has_friend === 0
                    ? actionFriend(
                        "reject",
                        singlePost?.action_id,
                        singlePost?.user_id
                      )
                    : singlePost?.has_friend === 1
                    ? removeFollow({ friend_id: singlePost?.user_id })
                    : addFriend({ friend_id: singlePost?.user_id });
                }}
              >
                {singlePost?.has_friend === 0
                  ? "Requested"
                  : singlePost?.has_friend === 1
                  ? "Linked"
                  : singlePost?.has_friend === 3
                  ? "Link"
                  : "Link"}
              </li>
            )}
            <li
              className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
              style={{ padding: "13px 0", fontWeight: 500 }}
              onClick={() => {
                notInterested(singlePost);
              }}
            >
              Not Interested
            </li>

            <li
              className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
              style={{ padding: "13px 0", fontWeight: 500 }}
              onClick={() => {
                // reportUser();
                reportPost(singlePost?._id);
              }}
            >
              Report
            </li>
          </>
        </ul>
      </Box>
    </Modal>
  );
};

export default ReelAction;
