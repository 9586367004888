import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import Swal from 'sweetalert2';
import {
  add_muted_restricted_account,
  block_unblock_friend,
  remove_muted_restricted_account,
  report_user,
} from "../../../../api/apiHandler";
import { toast } from "react-toastify";
// import 'react-toastify/dist/ReactToastify.css';


const OtherProfileAction = ({
  otherUserAction,
  setOhterUserAction,
  handleShare,
  myProfile,
  singlePost = {},
}) => {
  console.log("singlePost:================> ", singlePost);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));

  const customStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : 400, // Adjust the width for small screens
    maxWidth: "100%",
    bgcolor: "#ffffff",
    boxShadow: 24,
    borderRadius: "10px",
    // p: 2,
  };

  const blockUser = (id, type) => {
    block_unblock_friend({ friend_id: id, type }).then((response) => {
      console.log("response: block ", response);
      const uniqueToastId = 'success-' + Date.now();
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: false,
        progress: undefined,
        theme: "colored",
        toastId: uniqueToastId
      });
      myProfile();
      setOhterUserAction(false);
    });
  };
  const reportUser = (id) => {
    report_user({ id }).then((response) => {
      console.log("response: block ", response);
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
    myProfile();
    setOhterUserAction(false);
  };

  const muteOrRestrict = (id, type) => {
    add_muted_restricted_account({ other_user_id: id, type }).then(
      (response) => {
        console.log("response: MMMMMMMMMMMMMMM", response);
        if (response.code == 1) {
          toast.success(response.message, {
            toastId: "Delete Comment",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          myProfile();
          setOhterUserAction(false);
        }
      }
    );
  };
  const unmuteOrUnrestrict = (id, type) => {
    remove_muted_restricted_account({ other_user_id: id, type }).then(
      (response) => {
        console.log("response: MMMMMMMMMMMMMMM", response);
        if (response.code == 1) {
          toast.success(response.message, {
            toastId: "Delete Comment",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          myProfile();
          setOhterUserAction(false);
        }
      }
    );
  };

  return (
    <Modal
      open={otherUserAction}
      onClose={() => {
        setOhterUserAction(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={customStyles}>
        <ul className="p-0 m-0 post-menu-custom">
          <>
            <li
              className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
              style={{ padding: "13px 0", fontWeight: 500 }}
              onClick={() => {
                handleShare({ ...singlePost, module_type: "user" });
                setOhterUserAction(false);
              }}
            >
              Share
            </li>
            {singlePost?.has_muted_account ? (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  unmuteOrUnrestrict(singlePost?._id, "muted");
                }}
              >
                Unmute
              </li>
            ) : (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  muteOrRestrict(singlePost?._id, "muted");
                }}
              >
                Mute
              </li>
            )}
            {singlePost?.is_restricted == "y" ? (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  unmuteOrUnrestrict(singlePost?._id, "restricted");
                }}
              >
                Unrestrict
              </li>
            ) : (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  muteOrRestrict(singlePost?._id, "restricted");
                }}
              >
                Restrict
              </li>
            )}
            {singlePost?.has_friend == 2 ? (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  myProfile();
                  setOhterUserAction(false);
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'You are about to unblock this user.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, unblock!'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      blockUser(singlePost?._id, "unblock");
                    }
                  });
                }}
              >
                Unblock
              </li>
            ) : (
              <li
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
                onClick={() => {
                  myProfile();
                  setOhterUserAction(false);
                  Swal.fire({
                    title: 'Are you sure?',
                    text: 'You are about to block this user.',
                    icon: 'warning',
                    showCancelButton: true,
                    confirmButtonColor: '#3085d6',
                    cancelButtonColor: '#d33',
                    confirmButtonText: 'Yes, block!'
                  }).then((result) => {
                    if (result.isConfirmed) {
                      blockUser(singlePost?._id, "block");
                    }
                  });
                }}
              >
                Block
              </li>
            )}
            {/* <ToastContainer /> */}
            <li
              className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
              style={{ padding: "13px 0", fontWeight: 500 }}
              onClick={() => {
                reportUser();
              }}
            >
              Report
            </li>
          </>
        </ul>
      </Box>
    </Modal>
  );
};

export default OtherProfileAction;
