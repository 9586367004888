import React, { useEffect, useState } from 'react'
import { notification_setting_list, other_setting_list, update_notification_setting, update_other_setting } from '../../api/apiHandler';
// import HeaderProfile from '../HeaderProfile'
// import Header from '../Header'

export default function NotificationSettings() {
    const [settingData, setSettingData] = useState();
    const [pauseAll, setPauseAll] = useState();
    const [pauseChecked, setPauseChecked] = useState();
    const [settingId, setSettingId] = useState(0);
    const handleNotification = (id) => {
        setSettingId(id)
    }
    const handleUpdateSettings = async (key, subKey, check) => {
        let request = {
            type: settingId === 1 ? 'notification_post_story_comments' : settingId === 2 ? 'notification_linkingin_linkingout' : settingId === 3 ? 'notification_message_call' : settingId === 4 && 'notification_email'
        }
        if (settingId != 4) {
            let newReq = {}
            await settingData.map((value) => {
                let key = value.key
                let newVal = ''
                value.values.map((subValue) => {
                    if (subValue.is_selected == 1) {
                        newVal = subValue.key
                    }
                })
                newReq[key] = newVal;
            })
            newReq[key] = subKey;
            // console.log('newReq: ', newReq);
            request.field_value = newReq;
            console.log('request: ', request);

        } else if (settingId == 4) {
            request.field_value = { "support_email": check === true ? 'on' : 'off' }
        }
        console.log('response: ', request);
        update_notification_setting(request).then((response) => {
            console.log('response: ', response);
            setSettingData(response.data);
        })
    }

    const HandlePauseAll = (is_selected) => {
        update_other_setting({ type: 'notification_status', field_value: is_selected === false ? 'off' : 'on' }).then((response) => {
            setPauseAll(response.data.notification_status)
        })
    }
    useEffect(() => {
        let request = {}
        if (settingId === 1) {
            request.type = 'notification_post_story_comments'
        } else if (settingId === 2) {
            request.type = 'notification_linkingin_linkingout'
        } else if (settingId === 3) {
            request.type = 'notification_message_call'
        } else if (settingId === 4) {
            request.type = 'notification_email'
        }
        console.log('request: ', request);
        if (request?.type) {
            notification_setting_list(request).then((response) => {
                console.log('response: ', response);
                setSettingData(response.data);
            })
        }
    }, [settingId])

    useEffect(() => {
        other_setting_list({ type: 'notification_status' }).then((response) => {
            console.log('response: ', response);
            setPauseAll(response.data.notification_status)
            setPauseChecked(response.data.notification_status.is_selected)
        })
    }, [])
    return (
        <>
            <div className="tab-pane fade opacity-100" id="v-pills-Notification" role="tabpanel" aria-labelledby="v-pills-Notification-tab">
                <div className="row">
                    <div className="col-lg-5 col-md-6 col-sm-6 col-12">
                        <div className="main_account_setting">
                            <h4>Notifications</h4>
                            <ul className="setting_listing">
                                <li>
                                    <span>Pause All</span>
                                    <div className="form-check form-switch">
                                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" checked={pauseAll && pauseAll?.is_selected === 1} onChange={(e) => { HandlePauseAll(e.target.checked) }} />
                                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault" />
                                    </div>
                                </li>
                                <li className="arrow_right" onClick={() => handleNotification(1)}>
                                    <span>Posts, Stories and Comments</span>
                                </li>
                                <li className="arrow_right" onClick={() => handleNotification(2)}>
                                    <span>Linking in and Linking out</span>
                                </li>
                                <li className="arrow_right" onClick={() => handleNotification(3)}>
                                    <span>Messages</span>
                                </li>
                                {/* <li className="arrow_right">
                                        <span>Live and Video</span>
                                    </li> */}
                                <li className="arrow_right" onClick={() => handleNotification(4)}>
                                    <span>Email Notifications</span>
                                </li>
                            </ul>
                        </div>
                    </div>
                    <div className="col-lg-7 col-md-6 col-sm-6 col-12">
                        {settingData && (
                            <div className="main_account_setting">
                                <h4>{settingId === 1 ? 'Posts, Stories and Comments' : settingId === 2 ? 'Linking in and Linking out' : settingId === 3 ? 'Messages' : settingId === 4 ? 'Email Notifications' : ''}</h4>
                                {
                                    settingData && settingData.length > 0 && settingData.map((value, index) => {
                                        return (
                                            <ul className="setting_option" key={index}>
                                                <li>
                                                    <label>{value.title}</label>
                                                </li>
                                                {settingId === 4 && (
                                                    <li>
                                                        <span>{value.description}</span>
                                                        <div className="form-check form-switch">
                                                            <input className="form-check-input switch_custom" type="checkbox" id="flexSwitchCheckDefault" onClick={(e) => { handleUpdateSettings(value.key, '', e.target.checked) }}
                                                                checked={value.is_selected === 1} />
                                                        </div>
                                                    </li>
                                                    // <>
                                                    //     <li>
                                                    //         <span>Pause All</span>
                                                    //         <div className="form-check form-switch">
                                                    //             <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" />
                                                    //             <label className="form-check-label" htmlFor="flexSwitchCheckDefault" />
                                                    //         </div>
                                                    //     </li>
                                                    // </>
                                                )}
                                                {value.values && value.values.length > 0 && value.values.map((subValue, subIndex) => {
                                                    console.log('subValue: ', subValue);
                                                    return (
                                                        <>
                                                            <li key={subValue.key}>
                                                                <span>{subValue.sub_title}</span>
                                                                <input
                                                                    className="form-check-input"
                                                                    type="radio"
                                                                    name={`radioNoLabel${value.key}`}
                                                                    id={`radioNoLabel1${subIndex}`}
                                                                    defaultValue
                                                                    aria-label="..."
                                                                    checked={subValue.is_selected === 1}
                                                                    onClick={() => handleUpdateSettings(value.key, subValue.key)}
                                                                />
                                                            </li>
                                                        </>
                                                    )
                                                })}
                                            </ul>
                                        )
                                    })
                                }
                            </div>
                        )}
                    </div>
                </div>
            </div>


        </>
    )
}
