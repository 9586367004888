import React, { useEffect, useState } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import OTPInput from "otp-input-react";
// import { useForm } from 'react-hook-form';
// import { yupResolver } from '@hookform/resolvers/yup';
// import * as yup from 'yup';
// import { NotificationManager, NotificationContainer } from "react-notifications";
// import 'react-notifications/lib/notifications.css';
import { send_otp, verify_otp } from "../../api/apiHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";

export default function OTPVerification() {
  const location = useLocation();
  console.log(location);
  const navigate = useNavigate();
  const [OTP, setOTP] = useState("");
  const [time, setTime] = useState(30);
  const [resendOtp, setResendOtp] = useState(location?.state?.otp);
  console.log("time: ", time);
  const newcurrentTime = new Date();
  useEffect(() => {
    if (!localStorage.getItem("phone_number")) {
      navigate("/");
    }
  }, []);
  useEffect(() => {
    countDown();
  }, []);
  useEffect(() => {
    const storedTime = localStorage.getItem("time");
    if (storedTime) {
      const currentTime = Math.floor(
        (new Date() - new Date(storedTime)) / 1000
      );
      console.log("currentTime: ", currentTime);
      setTime(30 - currentTime);
    }
  }, []);
  const countDown = () => {
    const timer = setInterval(() => {
      setTime((prevTime) => {
        if (prevTime > 0) {
          return prevTime - 1;
        } else {
          clearInterval(timer);
          return 0;
        }
      });
    }, 1000);
    return () => clearInterval(timer);
  };
  const verify = () => {
    if (
      localStorage.getItem("step") === 1 ||
      localStorage.getItem("step") === "1"
    ) {
      if (OTP === "") {
        console.log("hii 1");
        toast.error("Please enter your OTP", {
          toastId: "correctOTP",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (
        OTP[0] === undefined ||
        OTP[1] === undefined ||
        OTP[2] === undefined ||
        OTP[3] === undefined
      ) {
        console.log("hii 2");
        toast.error("Please enter complete OTP", {
          toastId: "completeOTP",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else if (parseInt(OTP) !== resendOtp) {
        console.log("hii 3");
        setTimeout(() => {
          toast.error("Please enter correct OTP", {
            toastId: "wrongOTP",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }, 50);
        setOTP("");
      } else {
        console.log("hii 4");
        console.log("Line 21", OTP);

        verify_otp({
          phone_number: localStorage.getItem("phone_number"),
          otp: parseInt(OTP) || OTP,
        }).then((response) => {
          console.log("Line 25", response);
          if (response.code === 1) {
            toast.success(response.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              toastId: "otpVerified",
            });
            setTimeout(() => {
              setOTP("");
              navigate("/username");
            }, 1000);
            localStorage.setItem("step", 2);
          }
        });
      }
    } else {
      toast.warn("Please complete the details of previous page", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        toastId: "otpVerified",
      });
    }
  };

  const resend = () => {
    send_otp({ phone_number: localStorage.getItem("phone_number") }).then(
      (response) => {
        console.log("Line 79", response);
        if (response.code === 1 || response.code === "1") {
          toast.success(
            "OTP resend successufully on your registered mobile number",
            {
              toastId: "resendOTP",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            }
          );
          localStorage.setItem("time", newcurrentTime.toString());
          setResendOtp(response?.data?.otp);
          setTime(30);
          countDown();
        }
      }
    );
  };

  const handleKeyDown = (e) => {
    if (e.key === "Tab") {
      e.preventDefault();
      // Handle tab key press
    }
  };
  useEffect(() => {
    document.addEventListener("keydown", handleKeyDown);
    return () => {
      document.removeEventListener("keydown", handleKeyDown);
    };
  }, []);
  return (
    <>
      {localStorage.getItem("phone_number") && (
        <>
          <Header />
          <section className="main_signup">
            < ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="font_30 font_600 text-center signup_heading_border_blue">
                    Sign Up Your <span className="blue_29">Account</span>
                  </h1>
                  <p className="inter font_18 text-center">
                    <q>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                      sed do eiusmod tempor incididunt.
                    </q>
                  </p>
                </div>
              </div>
              <div className="form__container">
                <div className="row">
                  <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="left__container">
                      <div className="form_vector">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/vector-form.svg"
                          }
                          className="img-fluid"
                          alt="vector"
                        />
                      </div>
                      <h6 className="font_26 black_00 font_600">Step 2</h6>
                      <p className="gray_5a font_16 font_500">
                        Enter OTP to verify your mobile
                      </p>
                      <div className="steps d-md-flex">
                        <div className="progress__bar__container">
                          <ul className="ps-0">
                            <li className="active" id="icon1">
                              <span>1</span>
                            </li>
                            <li id="icon2" className="active">
                              <span>2</span>
                            </li>
                            <li id="icon3">
                              <span>3</span>
                            </li>
                            <li id="icon4">
                              <span>4</span>
                            </li>
                            <li id="icon5">
                              <span>5</span>
                            </li>
                            <li id="icon6">
                              <span>6</span>
                            </li>
                          </ul>
                        </div>
                        <div className="side__titles">
                          <div className="title__name">
                            <p className="font_16 font_500 active">
                              Basic Details
                            </p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500 active">
                              OTP Verification
                            </p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500">Select UserName</p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500">Select College</p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500">Find Friends</p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500 mb-0">
                              Link Hashtags
                            </p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 position-relative">
                    <div className="right_form_1 right__container">
                      <div id="form2">
                        <div className="form_heading">
                          <h4 className="font_25 font_600 blue_29">
                            OTP Verification
                          </h4>
                          <p className="mb-0">
                            <q>
                              Verify your OTP which is sent to your registered
                              mobile number
                            </q>
                          </p>
                        </div>
                        <form>
                          <div className="row justify-content-center w-100 my-auto">
                            <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                              <div className="step_1_form">
                                <div className="mobile_number_input">
                                  <h6 className="font_20 font_500 text-center mb-3">
                                    OTP Verification
                                  </h6>
                                  <p className="light_gray_8b text-center">
                                    Enter the OTP sent to{" "}
                                    <span className="gray_5a font_600">
                                      +91{" "}
                                      {localStorage.getItem("phone_number")
                                        ? localStorage.getItem("phone_number")
                                        : 1234567890}
                                    </span>
                                  </p>
                                  <OTPInput
                                    className="form_otp d-flex justify-content-evenly align-items-center"
                                    value={OTP}
                                    onChange={setOTP}
                                    autoFocus
                                    OTPLength={4}
                                    otpType="number"
                                    inputStyles={{
                                      width: "67px",
                                      height: "58px",
                                      background: "#FFFFFF",
                                      border: "2px solid #29ABE2",
                                      borderRadius: "10px",
                                      color: "rgba(79, 78, 78, 1)",
                                    }}
                                  />
                                  <button
                                    type="button"
                                    className="blue_button d-flex align-items-center font_18 font_500 mb-4 text-center mx-auto"
                                    onClick={verify}
                                  >
                                    VERIFY
                                  </button>
                                  {time != 0 ? (
                                    <p className="light_gray_8b text-center">
                                      Resend code in: 00:{time}{" "}
                                    </p>
                                  ) : (
                                    <p className="light_gray_8b text-center">
                                      Didn’t receive the OTP ?{" "}
                                      <div
                                        style={{ cursor: "pointer" }}
                                        onClick={() => {
                                          resend();
                                        }}
                                        className="gray_5a font_600"
                                      >
                                        Resend
                                      </div>
                                    </p>
                                  )}
                                </div>
                                <div className="buttons_form_1 buttons">
                                  <Link
                                    to="/sign-up"
                                    className="font_15 prev__btn d-flex align-items-center"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/prev.svg"
                                      }
                                      className="img-fluid"
                                      onClick={() =>
                                        localStorage?.setItem("step", 1)
                                      }
                                      alt="icon"
                                    />
                                    Previous
                                  </Link>
                                  {/* <Link to="/username" className="font_15 nxt__btn blue_button d-flex align-items-center"
                                                            >Next <img
                                                                src={process.env.PUBLIC_URL+"assets/images/icons/next.svg"} className="img-fluid"
                                                                alt="icon" /> </Link> */}
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
