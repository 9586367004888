import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { get_stream_exam_list } from "../../api/apiHandler";
import { Link, useNavigate } from "react-router-dom";
import { Tooltip } from "@material-ui/core";

export default function Exams() {
  const navigate = useNavigate();
  const [isExamData, setIsExamData] = useState(null);
  const [singleData, setSingleData] = useState();

  useEffect(() => {
    streamExamListData();
  }, []);

  const streamExamListData = () => {
    get_stream_exam_list({}).then((response) => {
      if (response.code == 1) {
        setIsExamData(response.data);
        console.log('response.data: ', response.data);
        setSingleData(response.data[0]);
      }
    });
  };

  const nextPage = () => {
    navigate({ pathname: "/exam-list" }, { state: singleData });
  };

  if (isExamData == null) {
    return <></>;
  }

  return (
    <>
      <main class="stulink">
        {localStorage.getItem("isSignedUp") ||
        localStorage.getItem("isLoggedIn") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="main_edit_profile">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5 collage_title_section">
                <h1 className="">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/video/assignment.svg"
                    }
                    className="me-2"
                  />
                  Exams Explore by Category{" "}
                </h1>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="left_side_list">
                      <ul>
                        {isExamData &&
                          isExamData.map((item, index) => {
                            return (
                              <li
                                type="button"
                                key={index}
                                onClick={() => setSingleData(item)}
                              >
                                <Tooltip title={item.name}>
                                  <span>
                                    <img
                                      src={item.icon}
                                      style={{ height: "35px", width: "35px" }}
                                    />{" "}
                                    {item.name.length < 9
                                      ? item.name
                                      : item.name.slice(0, 12) + "..."}
                                  </span>
                                </Tooltip>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-9 col-lg-9 col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <div className="right_side_list_gray">
                      <h4>{singleData.name}</h4>
                      <ul>
                        {singleData.exams_list != undefined &&
                        singleData.exams_list != "" ? (
                          <>
                            {singleData &&
                              singleData.exams_list.map((item, index) => {
                                return (
                                  <li key={index} style={{ cursor: "pointer" }}>
                                    <Link to="/exam-details" state={item._id}>
                                      <b>{item.name}</b>
                                      {/* - NMAT by GMAC{" "} */}
                                    </Link>
                                  </li>
                                );
                              })}
                          </>
                        ) : (
                          <h5>
                            Sorry, No Exams are available for this Course!!
                          </h5>
                        )}
                        {/* <li>
                          <b>NMAT</b> - NMAT by GMAC{" "}
                        </li>
                        <li>
                          <b>OJEE</b> - Odisha Joint Entrance Examination
                        </li>
                        <li>
                          <b>UPSEE</b> - Uttar Pradesh State Entrance Exam
                        </li>
                        <li>
                          <b>TANCET</b> - Tamil Nadu Common Entrance Test{" "}
                        </li>
                        <li>
                          <b>ATMA</b> - AIMS Test for Management Admissions{" "}
                        </li>
                        <li>
                          <b>APICET</b> - Andhra Pradesh Integrated Common Entrance Test
                          52 more exams
                        </li> */}
                      </ul>
                      {/* <div
                        type="button"
                        className="below_tag"
                        onClick={() => nextPage()}
                      >
                        <span>View All</span>
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
