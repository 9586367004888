import React, { useCallback, useEffect, useRef, useState } from "react";
import {
  Link,
  Navigate,
  useLocation,
  useNavigate,
  useParams,
  useSearchParams,
} from "react-router-dom";
import {
  logout,
  user_details,
  get_friend_request_list,
  count_notification,
  notification_list,
  main_search,
  auto_suggestion,
  accept_reject_request,
  main_details_search,
} from "../api/apiHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "./Header";
import dayjs from "dayjs";
import ReactTimeAgo from "react-time-ago";
import { showMessage } from "../common/RedirectPathManage";
// import { CometChat } from "@cometchat-pro/chat";
import moment from "moment";
import { CometChatUIKit } from "@cometchat/chat-uikit-react"; //import uikit package
import { CometChat } from "@cometchat/chat-sdk-javascript";
import _ from "lodash";

var advancedFormat = require("dayjs/plugin/advancedFormat");

export default function HeaderProfile() {
  dayjs.extend(advancedFormat);
  const navigate = useNavigate();
  const location = useLocation();
  const [searchParams, setSearchParams] = useSearchParams();

  const searchData = new URLSearchParams(location.search).get("search") || "";
  const fragmentData = location.hash;

  const [user, setUser] = useState(null);
  const [notificationCount, setNotificationCount] = useState(0);
  const [notificationList, setNotificationList] = useState([]);
  console.log("notificationList: ", notificationList);
  const [userSearchData, setSearchUserData] = useState([]);
  const [feedSearchData, setFeedUserData] = useState([]);
  const [isSearch, setIsSearch] = useState("");
  const searchContainerRef = useRef(null);

  const [loggedOut, setLoggedOut] = useState(false);
  const [searchText, setSearchText] = useState(searchData || fragmentData);

  console.log("isSearch: ", isSearch);
  console.log("searchText: ", searchText);
  const handleBodyClick = (event) => {
    if (
      searchContainerRef.current &&
      !searchContainerRef.current.contains(event.target)
    ) {
      setIsSearch(""); // Remove active state
    }
  };

  useEffect(() => {
    document.body.addEventListener("click", handleBodyClick);
    return () => {
      document.body.removeEventListener("click", handleBodyClick);
    };
  }, []);
  var date = dayjs().date();
  var month = new Date().toLocaleString("en-US", { month: "long" });
  var year = new Date().getFullYear();
  const [requestList, setRequestList] = useState([]);
  const [unreadMsgCount, setUnreadMessageCount] = useState(0);
  const [messageList, setMessageList] = useState([]);

  const myStyle = {
    display: "inline-block",
    marginLeft: "15px",
    marginRight: "-35px",
    marginBottom: "-2px",
    borderRadius: "50%",
    height: "10px",
    width: "10px",
    backgroundColor: "green",
    zIndex: "1",
    marginTop: "-35px",
  };

  // Logout
  const logOut = () => {
    logout({}).then((response) => {
      if (response?.code === "1" || response?.code === 1) {
        localStorage.clear();
        toast.success("Logged out successfully", {
          position: "top-center",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setLoggedOut(true);

        CometChatUIKit.logout();
        window.location.href = "/";
      }
    });
  };

  // Profile
  const userProfile = () => {
    user_details({}).then((response) => {
      if (response?.code === "1") {
        setUser(response.data);
      }
    });
  };

  useEffect(() => {
    userProfile();
    if (loggedOut === true) {
      navigate("/");
    }
    getFriendRequestList();
    countNotification();
    notificationData();
  }, []);

  const countNotification = () => {
    count_notification({}).then((response) => {
      //
      if (response.code == 1) {
        setNotificationCount(response.data);
      } else {
        setNotificationCount(0);
      }
    });
  };

  const notificationData = () => {
    notification_list({ type: "top" }).then((response) => {
      if (response.code == 1) {
        setNotificationList(response.data);
      } else {
        setNotificationList("");
      }
    });
  };

  const getFriendRequestList = () => {
    get_friend_request_list({ page_token: "1" }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        console.log("get_friend_request_listresponse.data: ", response.data);
        setRequestList(response.data);
      }
    });
  };

  const navigateToUsersChat = (data) => {
    // localStorage.setItem("showChat", "USERS");
    const key = data?.conversationType == "user" ? "uid" : "guid";
    navigate("/chat-room", { state: { [key]: data?.conversationWith } });
  };

  const recommendedSearch = (data, check = "") => {
    console.log("data: search", data);
    if (data == "view") {
      setIsSearch("123");
      if (searchText.length && check != "auto") {
        mainSearch(searchText);
      } else {
        auto_suggestion({}).then((resp) => {
          if (resp.code == 1) {
            if (localStorage.getItem("recent_search")) {
              setSearchUserData(
                _.uniqBy(
                  [
                    ...resp.data.feed_list,
                    ...JSON.parse(localStorage.getItem("recent_search")),
                  ],
                  "_id"
                )
              );
            } else {
              setSearchUserData(resp.data.feed_list);
            }
            setFeedUserData(resp.data.topics);
          } else {
            setSearchUserData([]);
            setFeedUserData([]);
          }
        });
      }
    } else {
      setIsSearch("");
    }
  };

  const mainSearch = (data) => {
    if (data.length) {
      setSearchText(data);
      main_search({ search: data?.replace("@", "") }).then((resp) => {
        if (resp.code == 1) {
          setSearchUserData(
            resp.data.search_userData?.map((item) => {
              return { ...item, user_id: item._id };
            })
          );
          setFeedUserData(resp.data.feed_list);
        } else {
          setSearchUserData([]);
          setFeedUserData([]);
        }
      });
    } else {
      setSearchText("");
      recommendedSearch("view", "auto");
    }
  };

  const closeFunction = () => {
    setIsSearch("");
    setSearchText("");
    setSearchParams({ search: "" });
    localStorage.setItem("recent_search", []);
    if (location.pathname === "/home-search") {
      navigate("/");
    }
  };

  const actionFriend = (action, action_id, user_id) => {
    accept_reject_request({
      id: action_id,
      friend_id: user_id,
      type: action,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        showMessage(response.message);
        getFriendRequestList();
      }
    });
  };

  const handleSearchResult = (searchData) => {
    setSearchText(searchData);
    navigate(`/home-search?search=${searchData}`);
  };

  const getUnreadMessage = useCallback(() => {
    let limit = 30;
    let conversationsRequest = new CometChat.ConversationsRequestBuilder()
      .setLimit(limit)
      .build();

    conversationsRequest.fetchNext().then(
      (conversationList) => {
        const unreadConvorsation = conversationList?.filter(
          (itm) => itm.unreadMessageCount
        );
        setMessageList(unreadConvorsation);
      },
      (error) => {}
    );
    CometChat.getUnreadMessageCount().then((count) => {
      const totalCount =
        Object.values(count.users).reduce((sum, count) => sum + count, 0) +
        Object.values(count.groups).reduce((sum, count) => sum + count, 0);

      setUnreadMessageCount(totalCount);
    });
  }, []);

  useEffect(() => {
    getUnreadMessage();
  }, [getUnreadMessage]);

  // useEffect(())

  CometChat.addMessageListener(
    localStorage.getItem("_id"),
    new CometChat.MessageListener({
      onTextMessageReceived: (textMessage) => {
        getUnreadMessage();
      },
      onMediaMessageReceived: (mediaMessage) => {
        getUnreadMessage();
      },
      onCustomMessageReceived: (customMessage) => {
        getUnreadMessage();
      },
      onMessagesRead: (messageReceipt) => {
        getUnreadMessage();
      },
    })
  );

  const handleKeyPress = (event) => {
    if (event.key === "Enter") {
      // Trigger your action here
      handleSearchResult(searchText);
    }
  };

  if (user === null) return <></>;
  return (
    <>
      {loggedOut === true ? (
        <Navigate to="/">
          <Header />
        </Navigate>
      ) : (
        <></>
      )}
      <header className="main_header">
        <ToastContainer />
        <div className="first_nav d-none d-md-block">
          <div className="container-fluid">
            <div className="first_nav_text d-flex justify-content-between align-items-center">
              <p className=" font_12 mb-0"></p>
              <div className="footer_links">
                <Link to="/privacy-policy" className="font_12">
                  Privacy Policy
                </Link>
                <Link to="/terms-of-use" className="font_12 ">
                  Terms and Conditions
                </Link>
                <Link
                  to={
                    localStorage.getItem("isLoggedIn") ||
                    localStorage.getItem("isSignedUp")
                      ? "/support-us"
                      : "/login"
                  }
                  className="font_12"
                >
                  Support Us
                </Link>
              </div>
            </div>
          </div>
        </div>
        <div className="main_nav">
          <div className="middle_nav">
            <div className="container-fluid">
              <div className="row align-items-center">
                <div className="col-xl-3 col-lg-3 col-md-3 col-sm-3 col-4">
                  <Link to="/" className="header_logo">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/stuLink-logo.png"
                      }
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="col-xl-6 col-lg-5 col-md-9 col-sm-9 col-8">
                  <div
                    className="header_input input-group"
                    onClick={() =>
                      recommendedSearch(isSearch == "123" ? "" : "view")
                    }
                  >
                    <input
                      type="text"
                      className="form-control"
                      value={searchText}
                      placeholder="SEARCH"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                      onChange={(e) => mainSearch(e.target.value)}
                      onKeyDown={handleKeyPress}
                      ref={searchContainerRef}
                    />
                    {/* <Link
                      className="input-group-text"
                      id="basic-addon1"
                      to={
                        localStorage.getItem("isSignedUp") ||
                        localStorage.getItem("isLoggedIn")
                          ? "/search"
                          : "/login"
                      }
                    > */}
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/search.svg"
                      }
                      className="img-fluid cursor-pointer"
                      alt="search"
                      onClick={() => {
                        handleSearchResult(searchText);
                      }}
                    />
                    {/* </Link> */}
                    {isSearch != "" ? (
                      <div className="search_hide_div active">
                        <h4>
                          Recent Searches{" "}
                          <a type="button" onClick={() => closeFunction()}>
                            Clear
                          </a>
                        </h4>
                        <div className="user_name_with_img">
                          <ul className="row mx-0 ps-0">
                            {userSearchData &&
                              userSearchData.map((item, index) => {
                                return (
                                  <li
                                    className="col-lg-2 col-md-3 col-sm-4 col-6 mb-3 cursor-pointer"
                                    key={index}
                                  >
                                    <div
                                      className="user_search"
                                      onClick={() => {
                                        if (
                                          localStorage.getItem("recent_search")
                                        ) {
                                          localStorage.setItem(
                                            "recent_search",
                                            JSON.stringify(
                                              _.uniqBy(
                                                [
                                                  item,
                                                  ...JSON.parse(
                                                    localStorage.getItem(
                                                      "recent_search"
                                                    )
                                                  ),
                                                ],
                                                "_id"
                                              )
                                            )
                                          );
                                        } else {
                                          localStorage.setItem(
                                            "recent_search",
                                            JSON.stringify([item])
                                          );
                                        }
                                        setIsSearch("");
                                        setSearchText("");
                                        navigate("/profile", {
                                          state: item?.user_id,
                                        });
                                      }}
                                    >
                                      <div className="user_s_img">
                                        <img src={item.profile_image} />
                                      </div>
                                      <h5>{item.name}</h5>
                                      <span>@{item.username}</span>
                                    </div>
                                  </li>
                                );
                              })}
                          </ul>
                        </div>
                        <div className="clg_name">
                          <ul className="search_text">
                            {/* <li>
                                                            <a href="#">
                                                            {" "}
                                                            <b>College</b> Near me{" "}
                                                            </a>
                                                        </li> */}
                            {searchText.length > 0 && (
                              <li
                                className="d-flex"
                                onClick={() => {
                                  handleSearchResult(searchText);
                                }}
                              >
                                {searchText}
                                <img
                                  className="ms-auto"
                                  alt=""
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/arrow-up-left.svg"
                                  }
                                />
                              </li>
                            )}
                            {feedSearchData != undefined &&
                            feedSearchData != "" ? (
                              <>
                                {feedSearchData.length > 0 &&
                                  feedSearchData.map((item, index) => {
                                    return (
                                      <li
                                        key={index}
                                        className="d-flex cursor-pointer"
                                        onClick={() => {
                                          handleSearchResult(
                                            item.tag || item.name
                                          );
                                        }}
                                      >
                                        {/* <a href="#"> */}{" "}
                                        {item.name != "" &&
                                        item.name != undefined
                                          ? item.name
                                          : item.tag}{" "}
                                        {/* </a> */}
                                        <img
                                          className="ms-auto"
                                          alt=""
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/arrow-up-left.svg"
                                          }
                                        />
                                      </li>
                                    );
                                  })}
                              </>
                            ) : (
                              <></>
                            )}
                          </ul>
                        </div>
                        {/* <div className="collage_list_img">
                          <ul className="clg_list">
                            <li>
                              <div className="clg_img_search">
                                <img src="assets/video/clg-2.png" />
                              </div>
                              <div className="clg_text">
                                <h5>
                                  <a href="#">Chandigarh College</a>
                                </h5>
                                <span>@Chdcol1234</span>
                              </div>
                            </li>
                            <li>
                              <div className="clg_img_search">
                                <img src="assets/video/clg-2.png" />
                              </div>
                              <div className="clg_text">
                                <h5>
                                  <a href="#">Chandigarh College</a>
                                </h5>
                                <span>@Chdcol1234</span>
                              </div>
                            </li>
                            <li>
                              <div className="clg_img_search">
                                <img src="assets/video/clg-2.png" />
                              </div>
                              <div className="clg_text">
                                <h5>
                                  <a href="#">Chandigarh College</a>
                                </h5>
                                <span>@Chdcol1234</span>
                              </div>
                            </li>
                            <li>
                              <div className="clg_img_search">
                                <img src="assets/video/clg-2.png" />
                              </div>
                              <div className="clg_text">
                                <h5>
                                  <a href="#">Chandigarh College</a>
                                </h5>
                                <span>@Chdcol1234</span>
                              </div>
                            </li>
                            <li>
                              <div className="clg_img_search">
                                <img src="assets/video/clg-2.png" />
                              </div>
                              <div className="clg_text">
                                <h5>
                                  <a href="#">Chandigarh College</a>
                                </h5>
                                <span>@Chdcol1234</span>
                              </div>
                            </li>
                            <li>
                              <div className="clg_img_search">
                                <img src="assets/video/clg-2.png" />
                              </div>
                              <div className="clg_text">
                                <h5>
                                  <a href="#">Chandigarh College</a>
                                </h5>
                                <span>@Chdcol1234</span>
                              </div>
                            </li>
                          </ul>
                        </div> */}
                      </div>
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className="col-12 col-xl-3 col-lg-4 col-md-3 col-sm-12">
                  <ul className="profile_notification list-unstyled d-flex align-items-center justify-content-between mb-0">
                    <li className="profile_dp d-none d-lg-block">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle={
                            requestList?.length > 0 ? "dropdown" : ""
                          }
                          aria-expanded="false"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/request.svg"
                            }
                            className="img-fluid"
                            alt="request"
                            style={{ height: "100%" }}
                          />
                        </button>
                        <ul
                          className="requested_user_ul dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="up_icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/dropup-arrow.svg"
                              }
                              className="img-fluid"
                              alt="arrow"
                            />
                          </li>
                          <li className="li_setting_padding text-end">
                            <Link to="" className="font_10">
                              Settings
                            </Link>
                          </li>
                          {requestList?.length > 0 ? (
                            requestList?.map((item) => {
                              return (
                                <>
                                  <li
                                    className="dropdown-item"
                                    key={item?.user_id}
                                  >
                                    <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                      <div className="cut_off_uni d-flex align-items-start">
                                        <div className="req_dp uni_profile_image">
                                          <img
                                            src={item?.profile_image}
                                            className="img-fluid"
                                            alt="image"
                                          />
                                        </div>
                                        <div className="req_name">
                                          <h6 className="font_400 font_12 mb-0">
                                            {item?.map}
                                          </h6>
                                          <span className="light_gray_8b font_10">
                                            @{item?.username}
                                          </span>
                                          {item?.college_uni_name === "" ? (
                                            <></>
                                          ) : (
                                            <p className="mb-0 font_10 gray_5a inter d-flex align-items-center">
                                              {/* <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/req_uni.svg"
                                                }
                                                className="img-fluid me-1"
                                                alt="college"
                                              /> */}
                                              {item?.college_uni_name?.slice(
                                                0,
                                                15
                                              )}
                                            </p>
                                          )}
                                        </div>
                                      </div>
                                      <div className="uni_profile_text ms-2">
                                        <button
                                          type="button"
                                          className="req_link_btn ms-auto "
                                          onClick={() => {
                                            actionFriend(
                                              "accept",
                                              item?.action_id,
                                              item?.user_id
                                            );
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/req_user.svg"
                                            }
                                            className="img-fluid"
                                            alt="user"
                                          />
                                          Link
                                        </button>
                                      </div>
                                    </div>
                                  </li>
                                </>
                              );
                            })
                          ) : (
                            <></>
                          )}
                          <li>
                            <Link
                              to="/request-list"
                              className="view_notification"
                            >
                              View All
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div
                        className={`${
                          requestList?.length > 0 ? "req_number" : "req_number"
                        } `}
                      >
                        {requestList?.length === 0 ? "0" : requestList?.length}
                      </div>
                    </li>
                    <li className="profile_dp d-none d-lg-block">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/bell.svg"
                            }
                            style={{
                              height: "100% !important",
                              position: "fixed !important",
                            }}
                            className="img-fluid"
                            alt="bell"
                          />
                        </button>
                        <ul
                          className="requested_user_ul dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                          style={{ cursor: "pointer" }}
                        >
                          <li className="up_icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/dropup-arrow.svg"
                              }
                              className="img-fluid"
                              alt="arrow"
                            />
                          </li>
                          <li className="li_setting_padding d-flex align-items-center justify-content-between">
                            <span className="font_10">Notifications</span>
                            {/* <span className="font_10">Settings</span> */}
                          </li>
                          {notificationList != undefined &&
                          notificationList != "" ? (
                            <>
                              {notificationList &&
                                notificationList
                                  ?.slice(0, 5)
                                  .map((item, index) => {
                                    return (
                                      <li
                                        className="three_item dropdown-item"
                                        key={index}
                                      >
                                        <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                          <div
                                            className="cut_off_uni d-flex align-items-start"
                                            onClick={() => {
                                              if (
                                                item?.type == "CommentsLiked" ||
                                                item?.type == "PostCommented"
                                              ) {
                                                navigate(
                                                  "/post/" + item?.action_id
                                                );
                                              } else if (
                                                item?.type == "AcceptFriend" ||
                                                item.type == "user_linking"
                                              ) {
                                                navigate("/profile", {
                                                  state: item.sender_id,
                                                });
                                              }
                                            }}
                                          >
                                            <div className="req_dp uni_profile_image">
                                              <img
                                                src={item.profile_image}
                                                className="img-fluid"
                                                alt="image"
                                              />
                                            </div>
                                            <div className="req_name">
                                              <h6 className="font_400 font_10 mb-1">
                                                <span className="font_600">
                                                  {item.sender_name}
                                                </span>{" "}
                                                {item.message.substr(
                                                  item.message.indexOf(" ") + 1
                                                )}
                                                {/* <span className="blue_29 font_600">profile status.</span> */}
                                              </h6>
                                              <p className="mb-0 font_10 gray_5a inter d-flex align-items-center">
                                                <img
                                                  src={
                                                    item.type == "PostCommented"
                                                      ? process.env.PUBLIC_URL +
                                                        "/assets/images/icons/comment-blue.svg"
                                                      : item.type == "PostLiked"
                                                      ? process.env.PUBLIC_URL +
                                                        "/assets/images/icons/like-small.svg"
                                                      : process.env.PUBLIC_URL +
                                                        "/assets/images/icons/blue-user-small.svg"
                                                  }
                                                  className="img-fluid me-1"
                                                  alt="college"
                                                />
                                                <ReactTimeAgo
                                                  date={
                                                    item.insertdate
                                                      ? item.insertdate
                                                      : ""
                                                  }
                                                  locale="en-US"
                                                />
                                              </p>
                                            </div>
                                          </div>
                                        </div>
                                      </li>
                                    );
                                  })}
                            </>
                          ) : (
                            <li className="three_item dropdown-item">
                              <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                <h6 className="font_400 font_12 mb-1">
                                  No new notification available!!
                                </h6>
                              </div>
                            </li>
                          )}
                          <li>
                            <Link
                              to="/notifications-list"
                              className="view_notification"
                            >
                              View All
                            </Link>
                          </li>
                        </ul>
                      </div>
                      <div className="req_number">{notificationCount}</div>
                    </li>
                    <li className="profile_dp d-none d-lg-block">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/chat.svg"
                            }
                            style={{
                              height: "100% !important",
                              position: "fixed !important",
                            }}
                            className="img-fluid"
                            alt="chat"
                          />
                        </button>
                        <ul
                          className="requested_user_ul dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                          style={{ cursor: "pointer" }}
                        >
                          <li className="up_icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/dropup-arrow.svg"
                              }
                              className="img-fluid"
                              alt="arrow"
                            />
                          </li>
                          <li className="li_setting_padding d-flex align-items-center justify-content-between">
                            <Link to="" className="font_10">
                              Messages
                            </Link>
                            {/* <Link to="" className="font_10">
                              Settings
                            </Link> */}
                          </li>
                          {messageList.length ? (
                            <>
                              {messageList?.slice(0, 5)?.map((item) => {
                                return (
                                  <>
                                    <li
                                      className="three_item dropdown-item cursor-pointer"
                                      onClick={() => {
                                        navigateToUsersChat(item);
                                      }}
                                    >
                                      <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                        <div className="cut_off_uni d-flex align-items-start">
                                          <div className="req_dp uni_profile_image">
                                            <img
                                              src={
                                                item.conversationWith?.icon ||
                                                item?.conversationWith?.avatar
                                              }
                                              className="img-fluid"
                                              alt={
                                                item?.conversationWith?.icon ||
                                                item?.conversationWith?.avatar
                                              }
                                            />
                                          </div>
                                          <div className="req_name">
                                            <h6 className="font_600 font_10 mb-1">
                                              {item.conversationWith.name}
                                            </h6>
                                            <p className="mb-0 font_8 gray_5a inter ell_two_line text-truncate ">
                                              {item?.lastMessage?.type == "file"
                                                ? "File Attachment"
                                                : item?.lastMessage?.text
                                                    ?.length > 40
                                                ? item?.lastMessage?.text?.substring(
                                                    0,
                                                    40
                                                  ) + "..."
                                                : item?.lastMessage?.text}
                                            </p>
                                          </div>
                                        </div>
                                        <div className="uni_profile_text ms-2 text-end">
                                          <p className="light_gray_8b font_10 mb-0">
                                            {/* 10:30PM */}
                                            {moment
                                              .unix(item.lastMessage.sentAt)
                                              .format("hh:mm a")}
                                          </p>
                                          <span className="message_no">
                                            {item.unreadMessageCount}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  </>
                                );
                              })}
                            </>
                          ) : (
                            <li className="three_item dropdown-item">
                              <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                <h6 className="font_400 font_12 mb-1">
                                  No new notification available!!
                                </h6>
                              </div>
                            </li>
                          )}
                          <li>
                            <button
                              onClick={() =>
                                navigateToUsersChat(messageList[0])
                              }
                              to="/chat-model"
                              className="view_notification"
                            >
                              View All
                            </button>
                          </li>
                          {/* <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                              <div className="cut_off_uni d-flex align-items-start">
                                <div className="req_dp uni_profile_image">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/user/request-2.svg"
                                    }
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <div className="req_name">
                                  <h6 className="font_600 font_10 mb-1">
                                    Mathilda Brinker
                                  </h6>
                                  <p className="mb-0 font_8 gray_5a inter ell_two_line">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="uni_profile_text ms-2 text-end">
                                <p className="light_gray_8b font_10 mb-0">
                                  10:30PM
                                </p>
                                <span className="message_no">2</span>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                              <div className="cut_off_uni d-flex align-items-start">
                                <div className="req_dp uni_profile_image">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/user/request-3.svg"
                                    }
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <div className="req_name">
                                  <h6 className="font_600 font_10 mb-1">
                                    Mathilda Brinker
                                  </h6>
                                  <p className="mb-0 font_8 gray_5a inter ell_two_line">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="uni_profile_text ms-2 text-end">
                                <p className="light_gray_8b font_10 mb-0">
                                  10:30PM
                                </p>
                                <span className="message_no">2</span>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                              <div className="cut_off_uni d-flex align-items-start">
                                <div className="req_dp uni_profile_image">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/user/request-4.svg"
                                    }
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <div className="req_name">
                                  <h6 className="font_600 font_10 mb-1">
                                    Mathilda Brinker
                                  </h6>
                                  <p className="mb-0 font_8 gray_5a inter ell_two_line">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="uni_profile_text ms-2 text-end">
                                <p className="light_gray_8b font_10 mb-0">
                                  10:30PM
                                </p>
                                <span className="message_no">2</span>
                              </div>
                            </div>
                          </li> */}
                        </ul>
                      </div>
                      <div className="req_number">{unreadMsgCount}</div>
                    </li>
                    <li className="d-none d-lg-block">
                      <div className="dropdown">
                        <button
                          className="btn dropdown-toggle d-flex align-items-center"
                          type="button"
                          id="dropdownMenuButton1"
                          data-bs-toggle="dropdown"
                          aria-expanded="false"
                        >
                          <div
                            style={
                              user.status === "Active"
                                ? myStyle
                                : user.status === "Away"
                                ? (myStyle.backgroundColor = "yellow")
                                : user.status === "Offline"
                                ? (myStyle.backgroundColor = "grey")
                                : myStyle
                            }
                          ></div>
                          <div className="profile_pic">
                            <div className="profile_image">
                              <img
                                src={
                                  !localStorage.getItem("profile_image")
                                    ? user.profile_image
                                    : localStorage.getItem("profile_image")
                                }
                                style={{
                                  height: "100% !important",
                                  position: "fixed !important",
                                }}
                                className="img-fluid"
                                alt="chat"
                              />
                            </div>
                          </div>
                          <span className="white_ff">
                            {user?.name?.split(" ")[0]}
                          </span>
                        </button>
                        <ul
                          className="profile_ul requested_user_ul dropdown-menu"
                          aria-labelledby="dropdownMenuButton1"
                        >
                          <li className="up_icon">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/dropup-arrow.svg"
                              }
                              className="img-fluid"
                              alt="arrow"
                            />
                          </li>
                          <li className="li_setting_padding">
                            <Link to="" className="font_10">
                              Profile Settings
                            </Link>
                          </li>
                          {/* <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                              <div className="online_image">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/online.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="image"
                                />
                              </div>
                              <div
                                className="req_name"
                                style={{ cursor: "pointer" }}
                              >
                                <h6 className="font_600 font_14 mb-0">
                                  Online
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                              <div className="online_image">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/away.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="image"
                                />
                              </div>
                              <div
                                className="req_name"
                                style={{ cursor: "pointer" }}
                              >
                                <h6 className="font_600 font_14 mb-0">Away</h6>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                              <div className="online_image">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/offline.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="image"
                                />
                              </div>
                              <div
                                className="req_name"
                                style={{ cursor: "pointer" }}
                              >
                                <h6 className="font_600 font_14 mb-0">
                                  Offline
                                </h6>
                              </div>
                            </div>
                          </li> 
                          <li>
                            <hr className="dropdown-divider mt-2" />
                          </li>*/}
                          <li className="three_item dropdown-item mt-2">
                            <div
                              className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0"
                              onClick={() => {
                                // navigate(`/profile/${user._id}`);
                                navigate("/profile", {
                                  state: user._id,
                                });
                              }}
                              style={{ cursor: "pointer" }}
                            >
                              <div className="online_image">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/Account-Settings.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="image"
                                />
                              </div>
                              <div className="req_name">
                                <h6 className="light_gray_8b font_400 font_15 mb-0">
                                  My Profile
                                </h6>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item mt-2">
                            <Link
                              to="/edit-profile"
                              className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0"
                            >
                              <div className="online_image">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/edit-profile.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="image"
                                />
                              </div>
                              <div className="req_name">
                                <h6 className="light_gray_8b font_400 font_15 mb-0">
                                  Edit Profile
                                </h6>
                              </div>
                            </Link>
                          </li>
                          {/* <li className="three_item dropdown-item mt-3">
                                                        <Link to="/notification-settings" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/notification.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Notification</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Messages.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Messages</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/change-password" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Password.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Password</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/education" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Education.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Education</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/account-settings" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Account-Settings.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Account Settings</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/privacy" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Privacy.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Privacy</h6>
                                                            </div>
                                                        </Link>
                                                    </li> */}
                          <li className="three_item dropdown-item mt-3 mb-4">
                            <Link
                              onClick={logOut}
                              className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0"
                            >
                              <div className="online_image">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/Logout.svg"
                                  }
                                  className="me-2 img-fluid"
                                  alt="image"
                                />
                              </div>
                              <div className="req_name">
                                <h6 className="light_gray_8b font_400 font_15 mb-0 red_ff">
                                  Logout
                                </h6>
                              </div>
                            </Link>
                          </li>
                        </ul>
                      </div>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className="last_nav">
            <div className="container">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/home.svg"
                    }
                    className="img-fluid"
                    alt="home"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="main_navbar_ul  navbar-nav ms-auto justify-content-around w-100">
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/reels"
                            : "/login"
                        }
                      >
                        Reels
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/colleges"
                            : "/"
                        }
                      >
                        Colleges
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/exams"
                            : "/login"
                        }
                      >
                        Exam
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/courses"
                            : "/login"
                        }
                      >
                        Courses
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/jobs"
                            : "/login"
                        }
                      >
                        Jobs
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/news"
                            : "/login"
                        }
                      >
                        News
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/group-chat-model"
                            : "/login"
                        }
                      >
                        Chat Room
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/members"
                            : "/login"
                        }
                      >
                        Members
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        className="nav-link white_ff font_16"
                        to={
                          localStorage.getItem("isSignedUp") ||
                          localStorage.getItem("isLoggedIn")
                            ? "/help-center"
                            : "/login"
                        }
                      >
                        Help Center
                      </Link>
                    </li>
                    {localStorage.getItem("isSignedUp") ||
                    localStorage.getItem("isLoggedIn") ? (
                      <>
                        {/* <div> */}
                        <li className="profile_dp nav-item d-lg-none">
                          <div className="dropdown header-icon-responsive">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle={
                                requestList?.length > 0 ? "dropdown" : ""
                              }
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/request.svg"
                                }
                                className="img-fluid"
                                alt="request"
                                style={{ height: "100%" }}
                              />
                            </button>
                            <ul
                              className="requested_user_ul dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="up_icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/dropup-arrow.svg"
                                  }
                                  className="img-fluid"
                                  alt="arrow"
                                />
                              </li>
                              <li className="li_setting_padding text-end">
                                <Link to="" className="font_10">
                                  Settings
                                </Link>
                              </li>
                              {requestList?.length > 0 ? (
                                requestList?.map((item) => {
                                  return (
                                    <>
                                      <li
                                        className="dropdown-item"
                                        key={item?.user_id}
                                      >
                                        <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                          <div className="cut_off_uni d-flex align-items-start">
                                            <div className="req_dp uni_profile_image">
                                              <img
                                                src={item?.profile_image}
                                                className="img-fluid"
                                                alt="image"
                                              />
                                            </div>
                                            <div className="req_name">
                                              <h6 className="font_400 font_12 mb-0">
                                                {item?.map}
                                              </h6>
                                              <span className="light_gray_8b font_10">
                                                @{item?.username}
                                              </span>
                                              {item?.college_uni_name === "" ? (
                                                <></>
                                              ) : (
                                                <p className="mb-0 font_10 gray_5a inter d-flex align-items-center">
                                                  {/* <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/images/icons/req_uni.svg"
                                                    }
                                                    className="img-fluid me-1"
                                                    alt="college"
                                                  /> */}
                                                  {item?.college_uni_name?.slice(
                                                    0,
                                                    15
                                                  )}
                                                </p>
                                              )}
                                            </div>
                                          </div>
                                          <div className="uni_profile_text ms-2">
                                            <button
                                              type="button"
                                              className="req_link_btn"
                                              onClick={() => {
                                                actionFriend(
                                                  "accept",
                                                  item?.action_id,
                                                  item?.user_id
                                                );
                                              }}
                                            >
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/req_user.svg"
                                                }
                                                className="img-fluid"
                                                alt="user"
                                              />
                                              Link
                                            </button>
                                          </div>
                                        </div>
                                      </li>
                                    </>
                                  );
                                })
                              ) : (
                                <></>
                              )}
                              <li>
                                <Link
                                  to="/request-list"
                                  className="view_notification"
                                >
                                  View All
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div
                            className={`${
                              requestList?.length > 0
                                ? "req_number"
                                : "req_number"
                            } `}
                          >
                            {requestList?.length === 0
                              ? "0"
                              : requestList?.length}
                          </div>
                        </li>
                        <li className="profile_dp nav-item d-lg-none">
                          <div className="dropdown  header-icon-responsive">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/bell.svg"
                                }
                                style={{
                                  height: "100% !important",
                                  position: "fixed !important",
                                }}
                                className="img-fluid"
                                alt="bell"
                              />
                            </button>
                            <ul
                              className="requested_user_ul dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                              style={{ cursor: "pointer" }}
                            >
                              <li className="up_icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/dropup-arrow.svg"
                                  }
                                  className="img-fluid"
                                  alt="arrow"
                                />
                              </li>
                              <li className="li_setting_padding d-flex align-items-center justify-content-between">
                                <span className="font_10">Notifications</span>
                                {/* <span className="font_10">Settings</span> */}
                              </li>
                              {notificationList != undefined &&
                              notificationList != "" ? (
                                <>
                                  {notificationList &&
                                    notificationList
                                      ?.slice(0, 5)
                                      ?.map((item, index) => {
                                        return (
                                          <li
                                            className="three_item dropdown-item"
                                            key={index}
                                          >
                                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                              <div className="cut_off_uni d-flex align-items-start">
                                                <div className="req_dp uni_profile_image">
                                                  <img
                                                    src={item.profile_image}
                                                    className="img-fluid"
                                                    alt="image"
                                                  />
                                                </div>
                                                <div className="req_name">
                                                  <h6 className="font_400 font_10 mb-1">
                                                    <span className="font_600">
                                                      {item.sender_name}
                                                    </span>{" "}
                                                    {item.message.substr(
                                                      item.message.indexOf(
                                                        " "
                                                      ) + 1
                                                    )}
                                                    {/* <span className="blue_29 font_600">profile status.</span> */}
                                                  </h6>
                                                  <p className="mb-0 font_10 gray_5a inter d-flex align-items-center">
                                                    <img
                                                      src={
                                                        item.type ==
                                                        "PostCommented"
                                                          ? process.env
                                                              .PUBLIC_URL +
                                                            "/assets/images/icons/comment-blue.svg"
                                                          : item.type ==
                                                            "PostLiked"
                                                          ? process.env
                                                              .PUBLIC_URL +
                                                            "/assets/images/icons/like-small.svg"
                                                          : process.env
                                                              .PUBLIC_URL +
                                                            "/assets/images/icons/blue-user-small.svg"
                                                      }
                                                      className="img-fluid me-1"
                                                      alt="college"
                                                    />
                                                    <ReactTimeAgo
                                                      date={
                                                        item.insertdate
                                                          ? item.insertdate
                                                          : ""
                                                      }
                                                      locale="en-US"
                                                    />
                                                  </p>
                                                </div>
                                              </div>
                                            </div>
                                          </li>
                                        );
                                      })}
                                </>
                              ) : (
                                <li className="three_item dropdown-item">
                                  <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                    <h6 className="font_400 font_12 mb-1">
                                      No new notification available!!
                                    </h6>
                                  </div>
                                </li>
                              )}
                              <li>
                                <Link
                                  to="/notifications-list"
                                  className="view_notification"
                                >
                                  View All
                                </Link>
                              </li>
                            </ul>
                          </div>
                          <div className="req_number">{notificationCount}</div>
                        </li>
                        <li className="profile_dp nav-item d-lg-none">
                          <div className="dropdown header-icon-responsive">
                            <button
                              className="btn dropdown-toggle"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/chat.svg"
                                }
                                style={{
                                  height: "100% !important",
                                  position: "fixed !important",
                                }}
                                className="img-fluid"
                                alt="chat"
                              />
                            </button>
                            <ul
                              className="requested_user_ul dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                              style={{ cursor: "pointer" }}
                            >
                              <li className="up_icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/dropup-arrow.svg"
                                  }
                                  className="img-fluid"
                                  alt="arrow"
                                />
                              </li>
                              <li className="li_setting_padding d-flex align-items-center justify-content-between">
                                <Link to="" className="font_10">
                                  Messages
                                </Link>
                                {/* <Link to="" className="font_10">
                                  Settings
                                </Link> */}
                              </li>
                              {messageList.length ? (
                                <>
                                  {messageList?.slice(0, 5)?.map((item) => {
                                    return (
                                      <>
                                        {/* <li className="three_item dropdown-item"> */}
                                        <li
                                          className="three_item dropdown-item cursor-pointer"
                                          onClick={() => {
                                            navigateToUsersChat(item);
                                          }}
                                        >
                                          <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                            <div className="cut_off_uni d-flex align-items-start">
                                              <div className="req_dp uni_profile_image">
                                                <img
                                                  src={
                                                    item.conversationWith
                                                      ?.icon ||
                                                    item?.conversationWith
                                                      ?.avatar
                                                  }
                                                  className="img-fluid"
                                                  alt={
                                                    item?.conversationWith
                                                      ?.icon ||
                                                    item?.conversationWith
                                                      ?.avatar
                                                  }
                                                />
                                              </div>
                                              <div className="req_name">
                                                <h6 className="font_600 font_10 mb-1">
                                                  {item.conversationWith.name}
                                                </h6>
                                                <p className="mb-0 font_8 gray_5a inter ell_two_line text-truncate ">
                                                  {item?.lastMessage?.type ==
                                                  "file"
                                                    ? "File Attachment"
                                                    : item?.lastMessage?.text
                                                        ?.length > 40
                                                    ? item?.lastMessage?.text?.substring(
                                                        0,
                                                        40
                                                      ) + "..."
                                                    : item?.lastMessage?.text}
                                                </p>
                                              </div>
                                            </div>
                                            <div className="uni_profile_text ms-2 text-end">
                                              <p className="light_gray_8b font_10 mb-0">
                                                {/* 10:30PM */}
                                                {moment
                                                  .unix(item.lastMessage.sentAt)
                                                  .format("hh:mm a")}
                                              </p>
                                              <span className="message_no">
                                                {item.unreadMessageCount}
                                              </span>
                                            </div>
                                          </div>
                                        </li>
                                      </>
                                    );
                                  })}
                                </>
                              ) : (
                                <li className="three_item dropdown-item">
                                  <div className="req_chandi chandi_admission justify-content-between align-items-start">
                                    <h6 className="font_400 font_12 mb-1">
                                      No new notification available!!
                                    </h6>
                                  </div>
                                </li>
                              )}
                              <li>
                                <button
                                  onClick={() =>
                                    navigateToUsersChat(messageList[0])
                                  }
                                  to="/chat-model"
                                  className="view_notification"
                                >
                                  View All
                                </button>
                              </li>
                              {/* <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                              <div className="cut_off_uni d-flex align-items-start">
                                <div className="req_dp uni_profile_image">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/user/request-2.svg"
                                    }
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <div className="req_name">
                                  <h6 className="font_600 font_10 mb-1">
                                    Mathilda Brinker
                                  </h6>
                                  <p className="mb-0 font_8 gray_5a inter ell_two_line">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="uni_profile_text ms-2 text-end">
                                <p className="light_gray_8b font_10 mb-0">
                                  10:30PM
                                </p>
                                <span className="message_no">2</span>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                              <div className="cut_off_uni d-flex align-items-start">
                                <div className="req_dp uni_profile_image">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/user/request-3.svg"
                                    }
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <div className="req_name">
                                  <h6 className="font_600 font_10 mb-1">
                                    Mathilda Brinker
                                  </h6>
                                  <p className="mb-0 font_8 gray_5a inter ell_two_line">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="uni_profile_text ms-2 text-end">
                                <p className="light_gray_8b font_10 mb-0">
                                  10:30PM
                                </p>
                                <span className="message_no">2</span>
                              </div>
                            </div>
                          </li>
                          <li className="three_item dropdown-item">
                            <div className="req_chandi chandi_admission justify-content-between align-items-start">
                              <div className="cut_off_uni d-flex align-items-start">
                                <div className="req_dp uni_profile_image">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/user/request-4.svg"
                                    }
                                    className="img-fluid"
                                    alt="image"
                                  />
                                </div>
                                <div className="req_name">
                                  <h6 className="font_600 font_10 mb-1">
                                    Mathilda Brinker
                                  </h6>
                                  <p className="mb-0 font_8 gray_5a inter ell_two_line">
                                    Lorem ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing ipsum dolor sit amet, consec
                                    adipiscing{" "}
                                  </p>
                                </div>
                              </div>
                              <div className="uni_profile_text ms-2 text-end">
                                <p className="light_gray_8b font_10 mb-0">
                                  10:30PM
                                </p>
                                <span className="message_no">2</span>
                              </div>
                            </div>
                          </li> */}
                            </ul>
                          </div>
                          <div className="req_number">{unreadMsgCount}</div>
                        </li>
                        <li className="nav-item d-lg-none">
                          <div className="dropdown">
                            <button
                              className="btn dropdown-toggle d-flex align-items-center"
                              type="button"
                              id="dropdownMenuButton1"
                              data-bs-toggle="dropdown"
                              aria-expanded="false"
                            >
                              <div
                                style={
                                  user.status === "Active"
                                    ? myStyle
                                    : user.status === "Away"
                                    ? (myStyle.backgroundColor = "yellow")
                                    : user.status === "Offline"
                                    ? (myStyle.backgroundColor = "grey")
                                    : myStyle
                                }
                              ></div>
                              <div className="profile_pic">
                                <div className="profile_image">
                                  <img
                                    src={
                                      !localStorage.getItem("profile_image")
                                        ? user.profile_image
                                        : localStorage.getItem("profile_image")
                                    }
                                    style={{
                                      height: "100% !important",
                                      position: "fixed !important",
                                    }}
                                    className="img-fluid"
                                    alt="chat"
                                  />
                                </div>
                              </div>
                              <span className="white_ff">
                                {user?.name?.split(" ")[0]}
                              </span>
                            </button>
                            <ul
                              className="profile_ul requested_user_ul dropdown-menu"
                              aria-labelledby="dropdownMenuButton1"
                            >
                              <li className="up_icon">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/dropup-arrow.svg"
                                  }
                                  className="img-fluid"
                                  alt="arrow"
                                />
                              </li>
                              <li className="li_setting_padding">
                                <Link to="" className="font_10">
                                  Profile Settings
                                </Link>
                              </li>
                              {/* <li className="three_item dropdown-item">
                                <div className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                  <div className="online_image">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/online.svg"
                                      }
                                      className="me-2 img-fluid"
                                      alt="image"
                                    />
                                  </div>
                                  <div
                                    className="req_name"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h6 className="font_600 font_14 mb-0">
                                      Online
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li className="three_item dropdown-item">
                                <div className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                  <div className="online_image">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/away.svg"
                                      }
                                      className="me-2 img-fluid"
                                      alt="image"
                                    />
                                  </div>
                                  <div
                                    className="req_name"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h6 className="font_600 font_14 mb-0">
                                      Away
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li className="three_item dropdown-item">
                                <div className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                  <div className="online_image">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/offline.svg"
                                      }
                                      className="me-2 img-fluid"
                                      alt="image"
                                    />
                                  </div>
                                  <div
                                    className="req_name"
                                    style={{ cursor: "pointer" }}
                                  >
                                    <h6 className="font_600 font_14 mb-0">
                                      Offline
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <hr className="dropdown-divider mt-2" />
                              </li> */}
                              <li className="three_item dropdown-item mt-2">
                                <div
                                  className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0"
                                  onClick={() => {
                                    // navigate(`/profile/${user._id}`);
                                    navigate("/profile", {
                                      state: user._id,
                                    });
                                  }}
                                  style={{ cursor: "pointer" }}
                                >
                                  <div className="online_image">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/Account-Settings.svg"
                                      }
                                      className="me-2 img-fluid"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="req_name">
                                    <h6 className="light_gray_8b font_400 font_15 mb-0">
                                      My Profile
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li className="three_item dropdown-item mt-2">
                                <Link
                                  to="/edit-profile"
                                  className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0"
                                >
                                  <div className="online_image">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/edit-profile.svg"
                                      }
                                      className="me-2 img-fluid"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="req_name">
                                    <h6 className="light_gray_8b font_400 font_15 mb-0">
                                      Edit Profile
                                    </h6>
                                  </div>
                                </Link>
                              </li>
                              {/* <li className="three_item dropdown-item mt-3">
                                                        <Link to="/notification-settings" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/notification.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Notification</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Messages.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Messages</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/change-password" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Password.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Password</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/education" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Education.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Education</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/account-settings" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Account-Settings.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Account Settings</h6>
                                                            </div>
                                                        </Link>
                                                    </li>
                                                    <li className="three_item dropdown-item mt-3">
                                                        <Link to="/privacy" className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0">
                                                            <div className="online_image">
                                                                <img src={process.env.PUBLIC_URL+"/assets/images/icons/Privacy.svg"} className="me-2 img-fluid"
                                                                    alt="image" />
                                                            </div>
                                                            <div className="req_name">
                                                                <h6 className="light_gray_8b font_400 font_15 mb-0">Privacy</h6>
                                                            </div>
                                                        </Link>
                                                    </li> */}
                              <li className="three_item dropdown-item mt-3 mb-4">
                                <Link
                                  onClick={logOut}
                                  className="req_chandi chandi_admission justify-content-between align-items-center border-0 pt-2 p-0"
                                >
                                  <div className="online_image">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/Logout.svg"
                                      }
                                      className="me-2 img-fluid"
                                      alt="image"
                                    />
                                  </div>
                                  <div className="req_name">
                                    <h6 className="light_gray_8b font_400 font_15 mb-0 red_ff">
                                      Logout
                                    </h6>
                                  </div>
                                </Link>
                              </li>
                            </ul>
                          </div>
                        </li>
                        {/* </div> */}
                      </>
                    ) : (
                      <>
                        <li className="nav-item d-lg-none">
                          <Link to="/login" className="login_button">
                            <span className="icon-login"></span>Log In
                          </Link>
                        </li>
                        <li className="nav-item d-lg-none">
                          <Link to="/sign-up" className="login_button">
                            <span className="icon-signup"></span>Sign Up
                          </Link>
                        </li>
                      </>
                    )}
                  </ul>
                </div>
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  );
}
