import React, { useEffect, useState } from "react";
import { get_topic_list } from "../api/apiHandler";
import ReactReadMoreReadLess from "react-read-more-read-less";
import { Link } from "react-router-dom";
import { Toolbar, Tooltip } from "@mui/material";

export default function TrendingTopics() {
  const [topicList, setTopicList] = useState([]);
  const [isReadMore, setIsReadMore] = useState(true);
  useEffect(() => {
    get_topic_list({}).then((response) => {
      console.log("Trending topics", response);
      if (response?.code === 1 || response?.code === "1") {
        setTopicList(response?.data);
      }
    });
  }, []);
  return (
    <>
      {topicList?.slice(0, 4).map((item) => {
        return item?.status === "Active" ? (
          <div
            className="latest_news_list"
            key={item?._id}
            style={{ wordBreak: "break-all" }}
          >
            <div className="latest_news_list_hash">
              <img
                src={process.env.PUBLIC_URL + "/assets/images/icons/hash.svg"}
                className="img-fluid"
                alt="hash"
              />
            </div>
            <div className="latest_news_list_name">
              <Link
                to={
                  localStorage?.getItem("isLoggedIn") ||
                  localStorage?.getItem("isSignedUp")
                    ? {
                        pathname: "/home-search",
                        search: `search=${item?.name}`,
                      }
                    : "/login"
                }
                
                className="light_gray_8b"
              >
                <Tooltip title={item?.name}>
                  <h6 className="black_14 font_500 font_18 text-break ">{item?.name}</h6>
                </Tooltip>
                {item?.description?.length < 50
                  ? item?.description
                  : item?.description?.slice(0, 50) + "..."}{" "}
              </Link>
              {/* {item?.description?.length  > 50 ? <span className="light_gray_8b">...</span> : <></>} */}
            </div>
          </div>
        ) : (
          <></>
        );
      })}
    </>
  );
}
