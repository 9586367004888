import React, { useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useLocation } from "react-router-dom";
import moment from "moment";

export default function ExamList() {
  const location = useLocation();
  const [isData, setIsData] = useState(location.state);

  if (isData == null) {
    <></>;
  }
  return (
    <>
      <main class="stulink">
        {localStorage.getItem("isSignedUp") ||
        localStorage.getItem("isLoggedIn") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="main_edit_profile">
          <div className="container">
            <div className="row">
              <div className="col-12 mb-5 collage_title_section">
                <h1 className="">
                  <img
                    src={
                      isData.icon != "" && isData.icon != undefined
                        ? isData.icon
                        : process.env.PUBLIC_URL +
                          "/assets/video/orange_banck_icon.svg"
                    }
                    style={{ height: "50px", width: "50px" }}
                    className="me-2"
                  />
                  {/* List Of Top {isData.name} Colleges In India{" "} */}
                  {isData.name} Exams {moment().format("YYYY")}, Dates,
                  Application Form & Alerts
                </h1>
                {/* <span className="clg_title_sub_text">
                  4567 Arts Colleges in India offering 31348 courses
                </span> */}
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="side_bar_widget_left">
                      <div className="widget_1">
                        <h6>Found 221 Exams</h6>
                      </div>
                      <div className="widget_2">
                        <div className="search_bar">
                          <input
                            type="text"
                            name=""
                            className="serch_input clg_serch"
                            placeholder="Search colleges"
                          />
                          <button>
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/search.svg"
                              }
                            />
                          </button>
                        </div>
                      </div>
                      <div className="widget_3">
                        <h6>EXAMINATION TYPE</h6>
                        <div className="filter_list">
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  national Wise
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  State Wise
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Rajasthan
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="widget_3">
                        <h6>APPLICATION &amp; EXAM STATUS</h6>
                        <div className="filter_list">
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Upcoming Application Form Date
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Upcoming Exams
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Application Form in Process
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Exams In Process
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="widget_3">
                        <h6>APPLICATION MODE</h6>
                        <div className="filter_list">
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Online
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Offline
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  Online &amp; Offline
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                      <div className="widget_3">
                        <h6>OTHERS</h6>
                        <div className="filter_list">
                          <ul>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  With Practice Paper
                                </label>
                              </div>
                            </li>
                            <li>
                              <div className="form-check">
                                <input
                                  className="form-check-input"
                                  type="checkbox"
                                  defaultValue=""
                                  id="flexCheckDefault"
                                />
                                <label
                                  className="form-check-label"
                                  htmlFor="flexCheckDefault"
                                >
                                  With Expert Tips
                                </label>
                              </div>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-9 col-lg-9 col-md-8 col-sm-12">
                <div className="row">
                  <div className="col-12">
                    <div className="filter_nav">
                      <div className="filter_span">
                        <span>MCA (43)</span>
                        <span>BCA (23)</span>
                        <span>ATMA (12)</span>
                        <span>NMAT (34)</span>
                        <span>MCA (43)</span>
                        <span>MCA (43)</span>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="row mt-3">
                  {isData.exams_list != undefined && isData.exams_list != "" ? (
                    <>
                      {isData &&
                        isData.exams_list.map((item, index) => {
                          return (
                            <Link to="/exam-details" state={item._id}>
                              <div div className="col-md-12 mb-4" key={index}>
                                <div className="exam_main">
                                  <div className="exam_detail_img_list">
                                    <div className="exam_icon">
                                      <img
                                        src={
                                          item.logo != "" &&
                                          item.logo != undefined
                                            ? item.logo
                                            : process.env.PUBLIC_URL +
                                              "/assets/video/exam_logo.png"
                                        }
                                      />
                                    </div>
                                    <div className="exam_text">
                                      <h6>{item.name} </h6>
                                    </div>
                                    <div className="exam_tag">
                                      <span>
                                        {item.application_form_date !=
                                          undefined &&
                                        item.application_form_date != ""
                                          ? moment(
                                              item.application_form_date
                                            ).format("DD-MM-YYYY")
                                          : "Pending Yet"}{" "}
                                        <br />
                                        application form
                                      </span>
                                      <span>
                                        {item.exam_date != undefined &&
                                        item.exam_date != ""
                                          ? moment(item.exam_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "Pending Yet"}{" "}
                                        <br />
                                        examination
                                      </span>
                                      <span>
                                        {item.result_date != undefined &&
                                        item.result_date != ""
                                          ? moment(item.result_date).format(
                                              "DD-MM-YYYY"
                                            )
                                          : "Pending Yet"}{" "}
                                        <br />
                                        result announce
                                      </span>
                                    </div>
                                  </div>
                                  <div className="exam_desc">
                                    <p>
                                      {item.overview != undefined &&
                                      item.overview != "" ? (
                                        <div
                                          dangerouslySetInnerHTML={{
                                            __html: item.overview,
                                          }}
                                        />
                                      ) : (
                                        "There is updated soon!!"
                                      )}
                                    </p>
                                    <div className="exam_tag_list">
                                      <span>Written Test</span>
                                      <span>Online Test</span>
                                    </div>
                                  </div>
                                  <div className="bootom_list">
                                    <ul>
                                      <li>APPLICATION</li>
                                      <li>PROCESS</li>
                                      <li>CUTOFF</li>
                                      <li>EXAM PATTERN</li>
                                      <li>PRACTICE PAPERS</li>
                                      <li>RESULTS</li>
                                    </ul>
                                    <Link
                                      to={`/apply-now/exams/${item._id}`}
                                      type="button"
                                      className="apply_now"
                                    >
                                      {" "}
                                      apply now{" "}
                                    </Link>
                                  </div>
                                </div>
                              </div>
                            </Link>
                          );
                        })}
                    </>
                  ) : (
                    <div div className="col-md-12 mb-4">
                      <div className="exam_main">
                        <div className="exam_detail_img_list">
                          <h1>
                            We don't have Exams for this Stream, Visit us Soon
                            for more Info.
                          </h1>
                        </div>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
