import React, { useEffect, useState } from 'react'
import HeaderProfile from '../HeaderProfile'
import Header from '../Header'
import { get_privacy_setting_list, other_setting_list, update_privacy_setting } from '../../api/apiHandler'

export default function AccountPrivacy() {
    const [checkType, setCheckType] = useState(false)
    useEffect(() => {
        OtherSettingList();
    }, [])
    const OtherSettingList = () => {
        other_setting_list({ type: "account_type" }).then((response) => {
            console.log('response: ', response);
            if (response.code == "1") {
                setCheckType(response.data.account_type.is_selected == 0 ? false : true)
            }
        })
    }
    const HandleUpdate = (data) => {
        console.log('data: ', data);
        update_privacy_setting({ "field_value": data == true ? "private" : "public", "type": "account_type" }).then((response) => {
            if (response.code == "1") {
                OtherSettingList();
            }
        })
    }
    return (
        <>
            {/* {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile /> : <Header />} */}
            <div className="edit_text">
                <div className='d-flex justify-content-between'>
                    <h4 className="font_25 font_600 mb-4">Privacy</h4>
                    <div className="form-check form-switch mt-3">
                        <input className="form-check-input" type="checkbox" id="flexSwitchCheckDefault" onChange={(e) => HandleUpdate(e.target.checked)} checked={checkType} />
                        <label className="form-check-label" htmlFor="flexSwitchCheckDefault" />
                    </div>
                </div>
                {/* <h3 className="font_20 gray_5a font_600">Privacy & data</h3> */}
                <p className="font_20 light_gray_8b">When your account is public, your profile and posts can be seen by anyone, on or off Instagram, even if they don't have an Instagram account.</p>
                <p className="font_20 light_gray_8b">
                    When your account is private, only the followers that you approve can see what y ou share, including your photos or videos on hashtag and location pages, and your followers and following lists.
                    {/* <a href="javascript:void(0)" className="blue_29">Learn more</a> */}
                </p>
                {/* <h3 className="font_20 gray_5a font_600 mt-5">Search Privacy</h3> */}
                {/* <div className="privacy_switch d-flex justify-content-between">
                    <p className="font_20 light_gray_8b">Hide your profile from search engine (Ex.google) <a href="javascript:void(0)" className="blue_29">Learn more</a></p> */}
                {/* <label className="switch3 switch3-checked" >
                        <input type="checkbox" checked={checkType} onClick={(e) => {
                            HandleUpdate(e.target.checked)
                        }} />
                        <div></div>
                    </label> */}
                {/* </div> */}
                {/* <h4 className="font_25 font_600 mb-4 mt_66">Personalization</h4>
                <div className="privacy_switch d-flex justify-content-between">
                    <p className="font_20 light_gray_8b">use sites you visit to improve which recommendation and ads you see. <a href="javascript:void(0)" className="blue_29">Learn more</a></p>
                    <label className="switch3 switch3-checked" >
                        <input type="checkbox" checked />
                        <div></div>
                    </label>
                </div>
                <div className="privacy_switch d-flex justify-content-between">
                    <p className="font_20 light_gray_8b">use sites you visit to improve which recommendation and ads you see. <a href="javascript:void(0)" className="blue_29">Learn more</a></p>
                    <label className="switch3 switch3-checked" >
                        <input type="checkbox" checked />
                        <div></div>
                    </label>
                </div> */}
            </div>
        </>
    )
}
