import React, { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { get_single_stories_media } from "../../../api/apiHandler";

const Redirection = () => {
  const navigate = useNavigate();

  useEffect(() => {
    // Get the current URL
    const url = window.location.href;

    const params = new URLSearchParams(url.split("?")[1]);
    // Extract post_id and module_type
    const queryParams = JSON.parse(params.get("id"));
    console.log("queryParams: ", queryParams);

    // Do something with the extracted values
    // console.log("post_id:", JSON.parse(queryParams));

    if (
      queryParams.module_type == "post" ||
      queryParams.module_type == "document" ||
      queryParams.module_type == "news" ||
      queryParams.module_type == "reels"
    ) {
      navigate(`/post/${queryParams.post_id}`);
    } else if (queryParams.module_type == "user") {
      navigate("/profile", { state: { id: queryParams.post_id } });
    } else if (queryParams.module_type == "Q&A") {
      navigate("/question", { state: { _id: queryParams.post_id } });
    } else if (queryParams.module_type == "comments") {
      navigate("/comments", {
        state: { comment: { _id: queryParams.post_id } },
      });
    } else if (queryParams.module_type == "story") {
      get_single_stories_media({ stories_id: queryParams.post_id }).then(
        (response) => {
          if (response.code == 1) {
            navigate(`/stories/${response.data._id}/${queryParams.post_id}`);
          }
        }
      );
    }
  }, [navigate]);

  return <></>;
};

export default Redirection;
