import React from 'react'

export default function TrendingAdmission({ tredingAdmissions, HandleAdmissionClickFun, HandlePlacementClickFun }) {
    console.log('tredingAdmissions: ', tredingAdmissions);
    const showData = false;
    return (
        <>
            {
                showData === true && tredingAdmissions && tredingAdmissions.length > 0 && (
                    <div className="trending_admission_box">
                        <div className="trending_admission_box_heading">
                            <img src={process.env.PUBLIC_URL + "/assets/images/icons/trending-up.svg"}
                                className="img-fluid me-2" alt="icon" />
                            <h6 className="white_ff font_16 font_500 mb-0">Trending Admissions</h6>
                        </div>
                        <div className="trending_admission_box_body">
                            <ul className="list-unstyled">
                                {tredingAdmissions.map((value, index) => {
                                    return (
                                        <li key={index}>
                                            <div className="chandi_admission">
                                                <div className="uni_profile_image">
                                                    <img src={value.college_logo}
                                                        className="img-fluid" alt="image" />
                                                </div>
                                                <div className="uni_profile_text">
                                                    <h6 className="inter font_400 font_15 mb-0">{value.name}</h6>
                                                    <div
                                                        className="yellow_location d-flex align-items-center">
                                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/map-yellow.svg"}
                                                            className="img-fluid me-1" alt="icon" />
                                                        <span
                                                            className="font_10 inter yellow_ff">Chandigarh</span>
                                                    </div>
                                                    <span className="gray_5a inter font_12">
                                                        <span onClick={() => HandleAdmissionClickFun()}>Admission </span>|
                                                        <span onClick={() => HandlePlacementClickFun()}>Placement</span>
                                                    </span>
                                                </div>
                                            </div>
                                            <span className="blue_29 font_12 inter">Bachelor of technology ,
                                                Master of technology </span>
                                            <p className="font_10 mb-0">Total fees: 5.5lakh | 4 Years | On
                                                Campus
                                                | Fulltime</p>
                                        </li>
                                    )
                                })}
                                {/* <li>
                            <div className="chandi_admission">
                                <div className="uni_profile_image">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/user/college-profile-2.svg"}
                                        className="img-fluid" alt="image" />
                                </div>
                                <div className="uni_profile_text">
                                    <h6 className="inter font_400 font_15 mb-0">Lovely
                                        Professional University</h6>
                                    <div
                                        className="yellow_location d-flex align-items-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/map-yellow.svg"}
                                            className="img-fluid me-1" alt="icon" />
                                        <span
                                            className="font_10 inter yellow_ff">Chandigarh</span>
                                    </div>
                                    <span className="gray_5a inter font_12">Admission |
                                        Placement</span>
                                </div>
                            </div>
                            <span className="blue_29 font_12 inter">B.A , BBA , BCA , B.Com
                                , B.sc IT , B.sc </span>
                            <p className="font_10 mb-0">3 Years | On Campus | Fulltime</p>
                        </li>
                        <li>
                            <div className="chandi_admission">
                                <div className="uni_profile_image">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/user/college-profile-3.svg"}
                                        className="img-fluid" alt="image" />
                                </div>
                                <div className="uni_profile_text">
                                    <h6 className="inter font_400 font_15 mb-0">Indian
                                        Institute of Technology Bombay </h6>
                                    <div
                                        className="yellow_location d-flex align-items-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/map-yellow.svg"}
                                            className="img-fluid me-1" alt="icon" />
                                        <span
                                            className="font_10 inter yellow_ff">Chandigarh</span>
                                    </div>
                                    <span className="gray_5a inter font_12">Admission |
                                        Placement</span>
                                </div>
                            </div>
                            <span className="blue_29 font_12 inter">Bachelor of technology
                            </span>
                            <p className="font_10 mb-0">Total fees: 4.5lakh | 4 Years | On
                                Campus
                                | Fulltime</p>
                        </li>
                        <li>
                            <div className="chandi_admission">
                                <div className="uni_profile_image">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/user/college-profile-4.svg"}
                                        className="img-fluid" alt="image" />
                                </div>
                                <div className="uni_profile_text">
                                    <h6 className="inter font_400 font_15 mb-0">Adesh
                                        University</h6>
                                    <div
                                        className="yellow_location d-flex align-items-center">
                                        <img src={process.env.PUBLIC_URL + "/assets/images/icons/map-yellow.svg"}
                                            className="img-fluid me-1" alt="icon" />
                                        <span
                                            className="font_10 inter yellow_ff">Chandigarh</span>
                                    </div>
                                    <span className="gray_5a inter font_12">Admission |
                                        Placement</span>
                                </div>
                            </div>
                            <span className="blue_29 font_12 inter">Bachelor of technology ,
                                Master of technology</span>
                            <p className="font_10 mb-0">Total fees: 6.5lakh | 4 Years | On
                                Campus
                                | Fulltime</p>
                        </li> */}
                            </ul>
                        </div>
                    </div>
                )
            }
        </>
    )
}
