import React, { useCallback, useEffect, useState } from "react";
import Header from "../Header";
import HeaderProfile from "../HeaderProfile";
import {
  get_qa_category_list,
  add_qa,
  get_qa_list,
  give_qa_like_unlike,
  // top_contribution,
  add_friend,
  add_answers,
  share_link,
  report_user,
} from "../../api/apiHandler";
import dayjs from "dayjs";
import { Link, useNavigate } from "react-router-dom";
import InfiniteScroll from "react-infinite-scroll-component";
import Topcontributors from "../Topcontributors";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { useForm } from "react-hook-form";
import EmojiPicker from "emoji-picker-react";
import ReactTimeAgo from "react-time-ago";
import ShareLink from "../pages/HomeFeed/components/ShareLink";
import Select from 'react-select'

export default function HelpCenter() {
  const { register, handleSubmit, reset, formState, setValue } = useForm();
  const { errors } = formState;
  console.log("errors: ", errors);
  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);
  const [comment, setComment] = useState("");
  const abc = [];
  const [questions, setQuestions] = useState([]);
  const [threeQuestions, setThreeQuestions] = useState([]);
  const [qaCategory, setQaCategory] = useState([]);
  const [filteredValue, setFilteredValue] = useState("L");
  const [pageToken, setPageToken] = useState(1);
  const [isFetching, setIsFetching] = useState(true);
  const [categoryValue, setCategoryValue] = useState("All");
  const [commentQaId, setCommentQaId] = useState(null);
  const [commentData, setCommentData] = useState();
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });
  // const [contributerList, setContributerList] = useState(null);
  const navigate = useNavigate();
  // <option></option>
  const options = [
    { value: 'College', label: 'College' },
    { value: 'Jobs', label: 'Jobs' },
    { value: 'Exams', label: 'Exams' },
    { value: 'Results', label: 'Results' },
    { value: 'Education', label: 'Education' }
  ]

  const handleShare = useCallback((data) => {
    share_link({
      id: data._id,
      module_type: "Q&A",
      description: data?.description || "stulink",
    }).then((response) => {
      console.log("response: ", response);
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  useEffect(() => {
    questionList();
    categoryList();
    // topContributerList();
    threeQuestion();
  }, []);

  const questionList = (categoryList, categoryValue) => {
    // console.log(categoryList, "categoryList");
    // console.log(categoryValue, "categoryValue");
    var params = {
      type: "normal",
      page_token: 1,
      short_by: categoryList != undefined ? categoryList : "",
      category: categoryValue != undefined ? categoryValue : "",
    };
    // console.log("Line 20", params);
    get_qa_list(params).then((response) => {
      console.log("QA New", response);
      if (response?.code === 1 || response?.code === "1") {
        // setQuestions((prevFeeds) =>[...prevFeeds, ...response?.data]);
        setQuestions(response?.data);
        setPageToken(pageToken + 1);
      } else if (response?.code === 2 || response?.code === "2") {
        setQuestions([]);
        setIsFetching(false);
      } else {
        setIsFetching(false);
      }
    });
  };

  const threeQuestion = () => {
    get_qa_list({ type: "normal", page_token: 1, short_by: "L" }).then(
      (response) => {
        if (response?.code === 1 || response?.code === "1") {
          // setQuestions((prevFeeds) =>[...prevFeeds, ...response?.data]);
          setThreeQuestions(response?.data);
          // response.data
        } else {
          setThreeQuestions([]);
        }
      }
    );
  };

  const categoryList = () => {
    get_qa_category_list({}).then((response) => {
      if (response?.code === 1 || response?.code === "1") {
        setQaCategory(response?.data);
      }
    });
  };
  const HandleReportUser = (id) => {
    report_user({ id }).then((response) => {
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
  };
  // const topContributerList = () => {
  //   top_contribution({}).then((response) => {
  //     if (response?.code === 1 || response?.code === "1") {
  //       setContributerList(response?.data);
  //     }
  //   });
  // };

  const likeQa = (qId) => {
    console.log("click Like Button", qId);
    give_qa_like_unlike({ id: qId, type: "like" })
      .then((response) => {
        console.log("Like Response", response);
        if (response?.code === 1 || response?.code === "1") {
          questionList(filteredValue, categoryValue);
        } else {
          questionList(filteredValue, categoryValue);
        }
      })
      .catch((error) => {
        console.error(error);
      });
  };
  const dislikeQa = (qId) => {
    give_qa_like_unlike({ id: qId, type: "unlike" }).then((response) => {
      console.log("UnLike Response", response);
      if (response?.code === 1 || response?.code === "1") {
        questionList(filteredValue, categoryValue);
      } else {
        questionList(filteredValue, categoryValue);
      }
    });
  };
  const onSubmit = (data) => {
    // console.log('data: ', data);
    add_qa({
      description: data?.question,
      category: data?.que_category,
      type: "normal",
      // college_id: params,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        toast.success(response.message, {
          toastId: "add-question",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        questionList();
        reset();
      }
    });
  };
  const handleCountryChange = (selectedOptions) => {
    let selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    console.log("selectedOptions: ", selectedOptions);
    setValue("que_category", selectedValues);
  };
  console.log('commentQaId: ', commentQaId);

  const HandleCommentSubmit = () => {
    console.log(comment);
    add_answers({
      qa_id: commentQaId,
      description: comment,
    }).then((response) => {
      if (comment === "") {
        toast.error("Please enter answer", {
          toastId: "answerErrorPost",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      } else {
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            toastId: "answerPost",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setComment("");
          questionList(filteredValue, categoryValue);
          // getCommentQAList()
          // answerListing();
        }
        return true;
      }
    });
  };
  // if (contributerList == null) {
  //   return <h1></h1>;
  // }
  return (
    <>
      {localStorage.getItem("isSignedUp") ||
        localStorage.getItem("isLoggedIn") ? (
        <>
          <HeaderProfile />
          <ShareLink
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      ) : (
        <Header />
      )}
      {/* Comment Modal new */}
      <div
        className="modal fade"
        id="commentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setComment("");
                  // setFileForComments([]);
                  // setSelectedFileForComments([]);
                  // setHeightWidthForComments([]);
                }}
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={commentData?.profile_image}
                              className="inner_post"
                            />{" "}
                            {commentData?.name}
                          </a>
                          @{commentData?.username}
                          <p>
                            {commentData?.insertdate ? (
                              <ReactTimeAgo
                                date={commentData?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {commentData?.post_type === "retweet" ? (
                          <>
                            <label>
                              {commentData?.shared_feed?.description}
                            </label>

                            {commentData?.shared_feed?.media?.length === 0 ? (
                              <></>
                            ) : (
                              <>
                                {commentData?.shared_feed?.media?.map(
                                  (item) => {
                                    return item?.name;
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : commentData?.post_type === "retweet_with_quote" ? (
                          <>
                            <label>{commentData?.description}</label>

                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link to={"#"} className="post_person_name">
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {commentData?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{commentData?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {commentData?.shared_feed?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          commentData?.shared_feed?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {commentData?.shared_feed?.media?.length ===
                                  0 ? (
                                  <></>
                                ) : commentData?.shared_feed?.media[0]?.type ===
                                  "image" ? (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.media[0]?.name
                                      }
                                      alt="post"
                                    />
                                  </div>
                                ) : (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.media[0]
                                          ?.thumb
                                      }
                                      alt="post"
                                    />
                                  </div>
                                )}
                                {commentData?.module_type === "news" ? (
                                  <div className="tetweet_quote_text">
                                    <p
                                      className="mb-0 gray_5a font_12"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          commentData?.shared_feed?.description,
                                      }}
                                    ></p>
                                  </div>
                                ) : (
                                  <div className="tetweet_quote_text">
                                    <p className="mb-0 gray_5a font_12">
                                      {commentData?.shared_feed?.description}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : commentData?.module_type === "news" ? (
                          <label
                            dangerouslySetInnerHTML={{
                              __html: commentData?.description,
                            }}
                          ></label>
                        ) : (
                          <label>{commentData?.description}</label>
                        )}

                        <br />
                        {commentData && commentData?.media?.length === 0 ? (
                          <></>
                        ) : (
                          <label>
                            {commentData?.media?.map((item) => {
                              return item?.name;
                            })}
                          </label>
                        )}
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          value={comment}
                          onChange={(e) => {
                            setComment(e?.target?.value);
                            setEmojiPickerForComments(false);
                          }}
                          placeholder="Post your comment..!!"
                        />
                      </div>
                      {/* <div className="uploade_images">
                        {filesForComments?.length !== 0 ? (
                          filesForComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    onMouseEnter={() =>
                                      handleMouseEnter(key)
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(key)
                                    }
                                  >
                                    <video
                                      autoPlay={false}
                                      loop=""
                                      id={`banner${key}`}
                                      controls="false"
                                      controlslist="nodownload"
                                      style={{ height: "200px" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(item)}
                                      />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <ul className="post_option ms-2 me-auto flex-row">
                <li>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpeg, .jpg"
                    id="imageForPosts"
                    multiple
                  // onChange={handleFileInputForComments}
                  // disabled={
                  //   disablePhotoInputForComments === true ||
                  //   filesForComments?.length === 4 ||
                  //   selectedFileForComments?.length === 4 ||
                  //   heightWidthForComments?.length === 4
                  // }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </li>
                <li>
                  <a className="mx-3">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      // ref={vidRef}
                      accept=".mp4, .avi, .mov, .mkv, .wmv"
                      id="imageForPosts"
                    // onChange={handleVideoUploadForComments}
                    // disabled={
                    //   disableVideoInputForComments === true ||
                    //   filesForComments?.length === 1 ||
                    //   selectedFileForComments?.length === 1 ||
                    //   heightWidthForComments?.length === 1
                    // }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/video-icon.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmojiPickerForComments(!emojiPickerForComments)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </a>
                </li>
              </ul>
              <button
                disabled={!comment}
                onClick={() => {
                  HandleCommentSubmit();
                  // uploadImagesForComments();
                }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Comment
              </button>
            </div>
            {emojiPickerForComments ? (
              <div className="mx-5 mt-0 mb-2">
                <EmojiPicker
                  onEmojiClick={(e) => setComment(comment + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <section className="home_question_list" style={{ background: "#FCFEFF" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12 mb-3 mb-md-0">
              <div className="information_box">
                <div className="information_box_heading">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/helpdesk.svg"
                    }
                    className="img-fluid"
                    alt="icon"
                  />
                  <h3 className="blue_29 font_500 font_25 mb-0">
                    Help Desk & Q&A
                  </h3>
                </div>
                <div className="information_box_body">
                  <form onSubmit={handleSubmit(onSubmit)}>
                    <div className="your_input">
                      <input
                        type="text"
                        className="form-control font_15"
                        placeholder="Type Your Question"
                        autoComplete="off"
                        name="question"
                        {...register("question", {
                          required: "Please enter question",
                          pattern: {
                            value: /^\S|.*\S.*$/,
                            message: "Spaces are not allowed in the question",
                          },
                        })}
                      />{" "}
                      {errors && errors?.question && (
                        <span style={{ color: "red" }}>
                          {errors?.question?.message}
                        </span>
                      )}
                      <span style={{ color: "red" }}></span>
                    </div>
                    <div className="your_input">
                      <Select
                        placeholder="Select Category"
                        options={options}
                        name="que_category"
                        className="mt-4"
                        isMulti
                        {...register("que_category", { required: 'Please Select Category' })}
                        onChange={handleCountryChange}
                      />
                      {/* {errors.que_category && <p>Please select at least one option</p>} */}
                      {errors && errors?.que_category && <span style={{ color: "red" }}>{errors?.que_category?.message}</span>}

                      {/* <select
                        className="form-select"
                        aria-label="Default select example"
                        {...register("que_category")}
                        multiple
                      >
                        <option selected>College</option>
                        <option >Jobs</option>
                        <option >Exams</option>
                        <option >Results</option>
                        <option >Education</option>
                      </select> */}
                    </div>
                    <div className="your_btn">
                      <button
                        type="submit"
                        className="edit_p_button font_14 inter"
                      >
                        Submit
                      </button>
                      <button
                        type="reset"
                        className="your_cancel_btn font_14 inter"
                      >
                        Cancel
                      </button>
                    </div>
                  </form>
                </div>
              </div>
              <div className="row">
                <div className="col-12 mb-2 d-flex justify-content-between my-flex-wrap">
                  <h2 className="qa_title">All Questions</h2>
                  <div className="qa_button d-flex align-items-center">
                    {/* <div className="filter_selection form-select bg-white new_select_qa d-flex"> */}
                    <p className="font_500 font_18 gray_5a mb-0 inter text-center">
                      <label>Sort By:</label>
                    </p>
                    <div className="sort_dropdown dropdown">
                      <select
                        className="form-select mx-1"
                        style={{ border: "1px solid grey" }}
                        aria-label="Default select example"
                        value={filteredValue}
                        onChange={(e) => {
                          const sortingOption = e.target.value;
                          console.log("Line 78", sortingOption);
                          setFilteredValue(sortingOption);
                          setQuestions([]);
                          setPageToken(0);
                          setIsFetching(true);
                          questionList(sortingOption, "All");
                        }}
                      >
                        <option value="L">Latest</option>
                        <option value="O">Oldest</option>
                      </select>
                    </div>
                    {/* </div> */}
                  </div>
                </div>
                <div className="col-12">
                  <ul
                    className="nav nav-tabs qa_tab_custom"
                    id="myTab"
                    role="tablist"
                  >
                    <li className="nav-item" role="presentation">
                      <button
                        className="nav-link active"
                        id="all-tab"
                        data-bs-toggle="tab"
                        data-bs-target="#all"
                        type="button"
                        role="tab"
                        aria-controls="all"
                        aria-selected="true"
                        onClick={() => {
                          questionList("L", "All");
                          setCategoryValue("All");
                        }}
                      >
                        All
                      </button>
                    </li>
                    {qaCategory?.map((item) => {
                      return (
                        <>
                          <li
                            className="nav-item"
                            role="presentation"
                            key={item?._id}
                          >
                            <button
                              className="nav-link"
                              id="all-tab"
                              data-bs-toggle="tab"
                              data-bs-target="#all"
                              type="button"
                              role="tab"
                              aria-controls="all"
                              aria-selected={categoryValue === item?.name}
                              onClick={() => {
                                questionList("L", item?.name);
                                setCategoryValue(item?.name);
                              }}
                            >
                              {item?.name}
                            </button>
                          </li>
                        </>
                      );
                    })}
                  </ul>
                  <div className="tab-content" id="myTabContent">
                    <div
                      className="tab-pane fade show active"
                      id="all"
                      role="tabpanel"
                      aria-labelledby="all-tab"
                    >
                      <div className="row">
                        <InfiniteScroll
                          dataLength={questions?.length}
                          next={questionList}
                          hasMore={isFetching}
                        >
                          {questions?.map((item) => {
                            return (
                              <>
                                <div className="col-12 mb-3" key={item?._id}>
                                  <div className="comment_section">
                                    <div className="comment_1">
                                      <div className="aq_user">
                                        <img src={item?.profile_image} />
                                      </div>
                                      <div className="aq_title">
                                        <div>
                                          <h6>{item?.name} </h6>
                                          <a
                                            href="#"
                                            className="user_name user_name_custom ms-2"
                                          >
                                            @{item?.username}{" "}
                                          </a>
                                        </div>
                                        <div className="sub_option_student">
                                          {item?.college_uni_name !== "" ||
                                            item?.college_uni_name !== null ? (
                                            <span>
                                              {item?.college_uni_name && (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/video/school.svg"
                                                  }
                                                  className="me-2"
                                                />
                                              )}
                                              {item?.college_uni_name?.length <
                                                35
                                                ? item?.college_uni_name
                                                : item?.college_uni_name?.slice(
                                                  0,
                                                  35
                                                ) + "..."}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="list_of_tag mt-2 mb-2 ms-auto">
                                            {item?.category?.map((i) => {
                                              return (
                                                <>
                                                  <span className="font-13 tab_option">
                                                    {i}
                                                  </span>
                                                </>
                                              );
                                            })}
                                          </div>
                                          {item?.insertdate ? (
                                            <div className="time_text me-3 ms-2">
                                              <p className="m-0">
                                                {dayjs(item?.insertdate).format(
                                                  "DD MMM HH:mm A"
                                                )}
                                              </p>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="dropdown">
                                            {item?._id ===
                                              localStorage?.getItem("_id") ? (
                                              <>
                                                <a
                                                  className="dropdown-toggle"
                                                  href="#"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/more_horiz.svg"
                                                    }
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </>
                                            ) : (
                                              <>
                                                <a
                                                  className="dropdown-toggle"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/more_horiz.svg"
                                                    }
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <li>
                                                    {/* <Link to={`/profile/${item.user_id}`} className="dropdown-item"> */}
                                                    <Link
                                                      to="/profile"
                                                      state={item.user_id}
                                                      className="dropdown-item"
                                                    >
                                                      Link @{item?.username}
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        HandleReportUser(
                                                          item?._id
                                                        );
                                                      }}
                                                    >
                                                      Report User
                                                    </a>
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="mid_text"
                                      onClick={() =>
                                        navigate("/question", { state: item })
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <p>
                                        {item?.description?.length < 100
                                          ? item?.description
                                          : item?.description?.slice(0, 100) +
                                          "..."}
                                      </p>
                                    </div>
                                    <div className="footer_text_qa">
                                      <div className="like_dislike d-flex align-items-center">
                                        <a
                                          className={`like like-a ${item?.is_like === 1
                                              ? "text-green"
                                              : ""
                                            }`}
                                          onClick={() => {
                                            likeQa(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={
                                              item?.is_like === 1
                                                ? process.env.PUBLIC_URL +
                                                "/assets/video/like.svg"
                                                : process.env.PUBLIC_URL +
                                                "/assets/video/like-blank.svg"
                                            }
                                            className="me-1"
                                          />
                                          {item?.likes}
                                        </a>
                                        <a
                                          // className={`dislke me-3 ${
                                          //   item?.is_dislike === 1
                                          //     ? "text-red"
                                          //     : ""
                                          // }`}
                                          className={
                                            item?.is_dislike === 1
                                              ? "dislke dislke-a text-red"
                                              : "dislke dislke-a"
                                          }
                                          onClick={() => {
                                            dislikeQa(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item?.is_unlike === 1 ? (
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/dislike.svg"
                                              }
                                              className="me-1"
                                            />
                                          ) : (
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/dislike-blank.png"
                                              }
                                              className="me-1"
                                            />
                                          )}

                                          {item?.unlikes}
                                        </a>
                                        <a
                                          type="button"
                                          data-bs-target="#commentModal"
                                          data-bs-toggle="modal"
                                          onClick={
                                            () => {
                                              setCommentData(item);
                                              setCommentQaId(item._id);
                                            }
                                            //   navigate(
                                            //     { pathname: "/question" },
                                            //     { state: item }
                                            //   )
                                          }
                                          className="comment-a"
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/comment_brown.svg"
                                            }
                                            className="me-1"
                                          />{" "}
                                          Comment {item?.comment_count}
                                        </a>
                                        <a
                                          href="javascript:void(0)"
                                          className="share-a"
                                          onClick={() => {
                                            handleShare(item);
                                          }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/plane_brown.svg"
                                            }
                                            className="me-1"
                                          />
                                          Share
                                        </a>
                                      </div>
                                      {/* <div className="drop d-flex align-items-center">
                                        <a href="#">
                                          {item?.comment_count} Reply
                                        </a>
                                      </div> */}
                                    </div>
                                  </div>
                                </div>
                              </>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                    <div
                      className="tab-pane fade"
                      id="news"
                      role="tabpanel"
                      aria-labelledby="news-tab"
                    >
                      <div className="row">
                        <InfiniteScroll
                          dataLength={questions?.length}
                          next={questionList}
                          hasMore={isFetching}
                        >
                          {questions?.map((item) => {
                            return item?.category?.includes("College") ? (
                              <>
                                <div className="col-12 mb-3" key={item?._id}>
                                  <div className="comment_section">
                                    <div className="comment_1">
                                      <div className="aq_user">
                                        <img src={item?.profile_image} />
                                      </div>
                                      <div className="aq_title">
                                        <h6>
                                          {item?.name}{" "}
                                          <a className="user_name ms-2">
                                            @{item?.username}{" "}
                                          </a>
                                        </h6>
                                        <div className="sub_option_student">
                                          {item?.college_uni_name !== "" ||
                                            item?.college_uni_name !== null ? (
                                            <span>
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/video/school.svg"
                                                }
                                                className="me-2"
                                              />
                                              {item?.college_uni_name?.length <
                                                35
                                                ? item?.college_uni_name
                                                : item?.college_uni_name?.slice(
                                                  0,
                                                  35
                                                ) + "..."}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="list_of_tag mt-2 mb-2 ms-auto">
                                            {item?.category?.map((i) => {
                                              return (
                                                <>
                                                  <span className="font-13 tab_option mx-1">
                                                    {i}
                                                  </span>
                                                </>
                                              );
                                            })}
                                          </div>
                                          {item?.insertdate ? (
                                            <div className="time_text me-3 ms-2">
                                              <p className="m-0">
                                                {dayjs(item?.insertdate).format(
                                                  "DD MMM HH:mm A"
                                                )}
                                              </p>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="dropdown">
                                            {item?._id ===
                                              localStorage?.getItem("_id") ? (
                                              <>
                                                <a
                                                  className="dropdown-toggle"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/more_horiz.svg"
                                                    }
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </>
                                            ) : (
                                              <>
                                                <a
                                                  className="dropdown-toggle"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/more_horiz.svg"
                                                    }
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <li>
                                                    <Link
                                                      //   to={`/profile/${item.user_id}`}
                                                      to="/profile"
                                                      state={item.user_id}
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      Link @{item?.username}
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="javascript:void(0)"
                                                      onClick={() => {
                                                        HandleReportUser(
                                                          item?._id
                                                        );
                                                      }}
                                                    >
                                                      Report User
                                                    </a>
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="mid_text"
                                      onClick={() =>
                                        navigate(`/question`, {
                                          state: { _id: item?._id },
                                        })
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <p>
                                        {item?.description?.length < 100
                                          ? item?.description
                                          : item?.description?.slice(0, 100) +
                                          "..."}
                                      </p>
                                    </div>
                                    <div className="footer_text_qa">
                                      <div className="like_dislike d-flex align-items-center">
                                        <a
                                          className={`like me-3 ${item?.is_like === 1
                                              ? "text-green"
                                              : ""
                                            }`}
                                          onClick={() => {
                                            likeQa(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={
                                              item?.is_like === 1
                                                ? process.env.PUBLIC_URL +
                                                "/assets/video/like.svg"
                                                : process.env.PUBLIC_URL +
                                                "/assets/video/like-blank.svg"
                                            }
                                            className="me-1"
                                          />
                                          {item?.likes}
                                        </a>
                                        <a
                                          className={`dislke me-3 ${item?.is_dislike === 1
                                              ? "text-red"
                                              : ""
                                            }`}
                                          onClick={() => {
                                            dislikeQa(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={
                                              item?.is_unlike === 1
                                                ? process.env.PUBLIC_URL +
                                                "/assets/video/dislike.svg"
                                                : process.env.PUBLIC_URL +
                                                "/assets/video/dislike-blank.png"
                                            }
                                            className="me-1"
                                          />
                                          {item?.unlikes}
                                        </a>
                                        <a className="me-3">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/comment_brown.svg"
                                            }
                                            className="me-1"
                                          />{" "}
                                          Comment {item?.comment_count}
                                        </a>
                                        <a className="">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/plane_brown.svg"
                                            }
                                            className="me-1"
                                          />
                                          Share
                                        </a>
                                      </div>
                                      <div className="drop d-flex align-items-center">
                                        <a>{item?.comment_count} Reply</a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ) : (
                              <h6 className="blue_29">No data found</h6>
                            );
                          })}
                        </InfiniteScroll>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <Topcontributors />
          </div>
        </div>
      </section>
    </>
  );
}
