import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { detail_type_jobs, job_details } from "../../api/apiHandler";
import moment from "moment";
import { Tooltip } from "@material-ui/core";
import LocationWiseJob from "../side-components/LocationWiseJob";

export default function JobList() {
  const navigate = useNavigate();
  const location = useLocation();
  const [isStateList, setIsStateList] = useState(null);
  console.log(isStateList, "isStateList");
  const [isQualificationList, setIsQualificationList] = useState(null);
  const [orderData, setOrderData] = useState(null);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);

  useEffect(() => {
    detailTypeJobData();
    JobDetailData();
  }, []);

  const detailTypeJobData = () => {
    detail_type_jobs({}).then((response) => {
      if (response.code == 1) {
        setIsStateList(response.data[0].job_state_cities_result);
        setIsQualificationList(response.data[1].designation_wise_result);
      }
    });
  };

  const JobDetailData = (data) => {
    let params;
    if (data != undefined && data != "") {
      params = {
        data_id: location.state.id,
        sort: data,
      };
    } else {
      params = {
        data_id: location.state.id,
      };
    }

    console.log('params: ', params);
    // data_id
    job_details(params).then((response) => {
      if (response.code == 1) {
        console.log('response.code: ', response.code);
        setOrderData(response.data);
      } else {
        setOrderData("");
      }
    });
  };

  if (isQualificationList == null) {
    return <></>;
  }
  if (isStateList == null) {
    return <></>;
  }
  if (orderData == null) {
    return <></>;
  }
  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
        localStorage?.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <main className="stulink">
        <section className="home_question_list ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="manger_detail">
                  <h5>
                    <img src="assets/video/black_manger.svg" className="me-3" />
                    {location.state.name} Jobs
                  </h5>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_question_list section_shdow">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-5">
                    <div className="search_filter">
                      <div className="search_left">
                        <label className="text-lowercase">
                          Showing {orderData.length} results
                        </label>
                      </div>
                      <div className="search_right">
                        <label className="mr-2">SORT BY:</label>
                        <div className="filter_selection filter_r">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => JobDetailData(e.target.value)}
                          >
                            <option value="">Default Order</option>
                            <option value="L">Latest</option>
                            <option value="P">Popular</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {orderData != undefined && orderData != "" ? (
                    <>
                      {orderData &&
                        orderData
                          .slice(
                            page * rowsPerPage,
                            page * rowsPerPage + rowsPerPage
                          )
                          .map((item, index) => {
                            return (
                              <div
                                className="col-xxl- col-lg-6 col-md-6 col-sm-6 col-12 mb-4"
                                key={index}
                              >
                                <div className="job_main_1">
                                  <div className="job_logo">
                                    <img
                                      src={
                                        item.image_video != undefined &&
                                          item.image_video != ""
                                          ? `${item.image_video}`
                                          : process.env.PUBLIC_URL +
                                          "/assets/video/logo_1.svg"
                                      }
                                    />
                                  </div>
                                  <div className="job_detail_width_desc">
                                    <Tooltip title={item.title}>
                                      <h4>
                                        <Link
                                          to="/job-details"
                                          state={item._id}
                                        >
                                          {item.title.length < 25
                                            ? item.title
                                            : item.title.slice(0, 25) +
                                            "..."}{" "}
                                        </Link>
                                        <Link
                                          to="/job-details"
                                          state={item._id}
                                        >
                                          <img src="assets/video/join_link.svg" />
                                        </Link>
                                      </h4>
                                    </Tooltip>
                                    <p>
                                      {/* 347 Senior Manager Risk, Manager Risk &amp; Other Post */}
                                      {item.qualification_detail[0].name}
                                    </p>
                                    <div className="job_detail_list">
                                      <label>
                                        <img src="assets/video/job_suitcase.svg" />
                                        {item.designation_detail[0].name}
                                      </label>
                                      <label>
                                        <img src="assets/video/access_time.svg" />
                                        {moment(item.created_at).format(
                                          "DD-MM-YYYY"
                                        )}
                                      </label>
                                      <label>
                                        <img src="assets/video/job-pin.svg" />
                                        {item.state != undefined ||
                                          item.country != undefined
                                          ? item.city + ", " + item.state
                                          : "All India"}
                                      </label>
                                    </div>
                                    <div className="bottom_list">
                                      <a
                                        type="button"
                                        className="btn_appl_now"
                                        onClick={() =>
                                          navigate(
                                            `/apply-now/jobs/${item._id}`,
                                            {
                                              state: {
                                                logo: item.image_video != undefined &&
                                                  item.image_video != ""
                                                  ? `${item.image_video}`
                                                  : process.env.PUBLIC_URL +
                                                  "/assets/video/logo_1.svg",
                                                name: item.title,
                                                path: '/job-list',
                                                pathState: location?.state
                                              }
                                            }
                                          )
                                        }
                                      >
                                        Apply Now
                                      </a>
                                    </div>
                                  </div>
                                </div>
                              </div>
                            );
                          })}
                    </>
                  ) : (
                    <>
                      <h3>No data available</h3>
                    </>
                  )}
                  {orderData.length < rowsPerPage ? (
                    <></>
                  ) : (
                    <>
                      {orderData.length > rowsPerPage ? (
                        <div
                          type="button"
                          className="col-12 text-center"
                          onClick={() => setRowsPerPage(rowsPerPage + 10)}
                        >
                          <span className="text-theme">
                            See More{" "}
                            <img
                              src="assets/video/caret.svg"
                              className="ms-1"
                            />
                          </span>
                        </div>
                      ) : (
                        <></>
                      )}
                    </>
                  )}
                </div>
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  {/* <div className="col-12 mb-3">
                    <div className="job_wise_detail">
                      <div className="job_wise_list_1">
                        <h4>
                          <img src="assets/video/job.svg" />
                          Location-wise Jobs
                        </h4>
                      </div>
                      <div className="list_job_wise">
                        <ul>
                          {isStateList &&
                            isStateList.slice(0, 3).map((item, index) => {
                              return (
                                <>
                                  {item?.vacancies != 0 ? (
                                    <li>
                                      <div className="map_img">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "assets/video/map.svg"
                                          }
                                        />
                                      </div>
                                      <div className="map_text">
                                        <span>
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "assets/video/map-pin.svg"
                                            }
                                          />
                                          {item.state}
                                        </span>
                                        <h6>{item.vacancies} Vacancies</h6>
                                      </div>
                                    </li>
                                  ) : (
                                    <></>
                                  )}
                                </>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div> */}
                  <LocationWiseJob />
                  <div className="col-12">
                    <div className="job_wise_detail">
                      <div className="job_wise_list_1">
                        <h4>
                          <img src="assets/video/job.svg" />
                          Qualification-wise jobs
                        </h4>
                      </div>
                      <div className="list_job_wise">
                        <ul>
                          {isQualificationList &&
                            isQualificationList.map((item, index) => {
                              return (
                                <li>
                                  <div className="map_img">
                                    <img src="assets/video/edu_cap.svg" />
                                  </div>
                                  <div className="map_text">
                                    <h6 className="mb-0">{item.name}</h6>
                                    <p className="mb-0">
                                      {item.vacancies} Vacancies
                                    </p>
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/video/map-pin.svg"
                                        }
                                        className="me-2"
                                      />
                                      All India
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
