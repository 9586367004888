import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useNavigate } from "react-router-dom";
import {
  home_state_jobs,
  random_job_data,
  qualification_list,
  designations_list,
  search_job,
} from "../../api/apiHandler";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Tooltip } from "@material-ui/core";

export default function Jobs() {
  const navigate = useNavigate();
  const [isStateList, setIsStateList] = useState(null);
  console.log("isStateList: ", isStateList);
  const [isQualificationList, setIsQualificationList] = useState(null);
  const [isDesignationList, setIsDesignationListList] = useState(null);
  const [isTopDesignationList, setIsTopDesignationList] = useState(null);
  console.log("isTopDesignationList: ", isTopDesignationList);
  const [isRandomQualificationListList, setIsRandomQualificationListList] =
    useState(null);
  const [isSearchData, setIsSearchData] = useState("");
  console.log("isSearchData: ", isSearchData);
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(5);
  const [Qpage, setQPage] = useState(0);
  const [QrowsPerPage, setQRowsPerPage] = useState(8);

  useEffect(() => {
    stateAPIData();
    qualificationAPIData();
    designationAPIData();
    randomAPIData();
  }, []);

  const stateAPIData = (SearchData, SortData) => {
    var params = {};
    if (SearchData != undefined && SearchData != "") {
      params = {
        search: SearchData,
      };
    } else {
      params = {
        sort: SortData,
      };
    }

    home_state_jobs(params).then((response) => {
      if (response.code == 1) {
        setIsStateList(response.data.job_state_cities_result);
      } else {
        setIsStateList([]);
      }
    });
  };

  const qualificationAPIData = (SearchData, SortData) => {
    var params = {};
    if (SearchData != undefined && SearchData != "") {
      params = {
        search: SearchData,
      };
    } else {
      params = {
        sort: SortData,
      };
    }

    qualification_list(params).then((response) => {
      if (response.code == 1) {
        setIsQualificationList(response.data);
      } else {
        setIsQualificationList([]);
      }
    });
  };

  const designationAPIData = (SearchData, SortData) => {
    var params = { page_token: 2 };
    if (SearchData != undefined && SearchData != "") {
      params = {
        search: SearchData,
      };
    } else {
      params = {
        sort: SortData,
      };
    }

    designations_list(params).then((response) => {
      if (response.code == 1) {
        setIsDesignationListList(response.data);
      } else {
        setIsDesignationListList([]);
      }
    });
  };

  const randomAPIData = () => {
    random_job_data({}).then((response) => {
      console.log("News Detail", response);
      if (response.code == 1) {
        setIsTopDesignationList(response.data.designations_list_result);
        setIsRandomQualificationListList(
          response.data.qualification_list_result
        );
      }
    });
  };

  const validationSchema = yup.object().shape({
    search: yup.string(),
    qualification_id: yup.string(),
    city: yup.string(),
  });

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState } = useForm(formOptions);
  const { errors } = formState;

  const onSubmit = (data) => {
    if (data.search == "" && data.qualification_id == "" && data.city == "") {
      toast.warn("Please Fill any of One field!!");
    } else {
      console.log("data: ", data);
      search_job(data).then((response) => {
        console.log("-------????-------->>>", response);
        if (response.code == 1) {
          setIsSearchData(response.data);
          reset();
        } else {
          setIsSearchData("");
          toast.info("Sorry, we can't find anything regarding this Info.");
        }
      });
    }
  };

  if (isStateList === null) {
    return <></>;
  }
  if (isQualificationList === null) {
    return <></>;
  }
  if (isDesignationList === null) {
    return <></>;
  }
  if (isTopDesignationList === null) {
    return <></>;
  }
  if (isRandomQualificationListList === null) {
    return <></>;
  }
  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
      localStorage?.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <main className="stulink">
        <ToastContainer />
        <section className="home_question_list section_1">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12">
                    <h1 className="main_section_title">
                      <img
                        src="assets/video/search.svg"
                        className="mr-2 me-2"
                      />
                      SEARCH OVER 10000 JOBS
                    </h1>
                  </div>
                  <div className="col-12">
                    <div className="white_section_1">
                      <form onSubmit={handleSubmit(onSubmit)}>
                        <div className="row">
                          <div className="col-md-4 col-sm-6 col-12 mb-3">
                            <div className="form-group">
                              <input
                                type="text"
                                name=""
                                className="grey_input"
                                placeholder="Enter your Designation"
                                {...register("search")}
                              />
                              <span style={{ color: "red" }}>
                                {errors.search?.message}
                              </span>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-12 mb-3">
                            <div className="form-group input_select">
                              <img src="assets/video/down.svg" />
                              <select
                                className="custom-select grey_input"
                                {...register("qualification_id")}
                              >
                                <option value="">Choose Qualification</option>
                                {isQualificationList &&
                                  isQualificationList.map((item, index) => {
                                    return (
                                      <option value={item._id}>
                                        {item.name}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-12 mb-3">
                            <div className="form-group input_select">
                              <img src="assets/video/down.svg" />
                              <select
                                className="custom-select grey_input"
                                {...register("city")}
                              >
                                <option value="">Choose State</option>
                                {isStateList &&
                                  isStateList.map((item, index) => {
                                    return (
                                      <option value={item.state}>
                                        {item.state}
                                      </option>
                                    );
                                  })}
                              </select>
                            </div>
                          </div>
                          <div className="col-md-4 col-sm-6 col-12 mb-3"></div>
                          <div className="col-md-4 col-sm-12 col-12 d-flex justify-content-center  align-content-center ">
                            {" "}
                            <button type="submit" className="btn btn-theme w-auto">
                              Search Job
                            </button>{" "}
                          </div>
                        </div>
                      </form>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        {isSearchData != undefined && isSearchData != "" ? (
          <section className="home_question_list section_3">
            <div className="container">
              <div className="row">
                <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                  <div className="row">
                    <div className="col-12 mb-3">
                      <h1 className="main_section_title">Filter jobs</h1>
                      <div className="row">
                        {isSearchData &&
                          isSearchData.map((item, index) => {
                            return (
                              <Link
                                to="/job-details"
                                className="col-md-3 col-sm-6 col-12 mb-3"
                                key={index}
                                state={item._id}
                              >
                                <div className="job_wise">
                                  {/* <label className="left_side">Featured</label> */}
                                  {/* <label className="right_side">Full Time</label> */}
                                  <div className="box_icon mb-2">
                                    <img src="assets/video/cap.svg" />
                                  </div>
                                  <div className="text-center">
                                    <h6>{item.title}</h6>
                                    <span>
                                      {item.vacancies != undefined &&
                                      item.vacancies != ""
                                        ? item.vacancies
                                        : "0"}{" "}
                                      Vacancies
                                    </span>
                                    <p className="mt-4">
                                      <img src="assets/video/map-pin.svg" />{" "}
                                      {item.state}
                                    </p>
                                  </div>
                                </div>
                              </Link>
                            );
                          })}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        ) : (
          <></>
        )}

        <section className="home_question_list section_2">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12">
                    <h1 className="main_section_title">
                      <img
                        src="assets/video/section_2.svg"
                        className="mr-2 me-2"
                      />
                      Newest Openings
                    </h1>
                  </div>
                  {isTopDesignationList &&
                    isTopDesignationList.map((item, index) => {
                      return (
                        <div className="col-md-4 col-sm-6 col-12 mb-3">
                          <div className="box_blue">
                            <label>Newest Openings</label>
                            <div className="box_icon">
                              <img src="assets/video/surface.svg" />
                            </div>
                            <div className="box_text">
                              <h6>{item.name} Jobs</h6>
                              <span>{item.vacancies} Vacancies</span>
                              <p>
                                <img src="assets/video/map-pin.svg" /> All India
                              </p>
                              <Link
                                to="/job-list"
                                state={{ name: item.name, id: item._id }}
                              >
                                VIEW DETAIL
                              </Link>
                            </div>
                          </div>
                        </div>
                      );
                    })}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_question_list section_3">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h1 className="main_section_title">
                      <img
                        src="assets/video/start_op.png"
                        className="mr-2 me-2"
                      />
                      Most Popular Jobs
                    </h1>
                  </div>
                  <div className="col-12">
                    <div className="list_jobs">
                      <ul>
                        {isRandomQualificationListList &&
                          isRandomQualificationListList.map((item, index) => {
                            return (
                              <li>
                                <div className="jobs_list_main">
                                  <img src="assets/video/icons/icon-1.svg" />
                                  <h5>{item.name}</h5>
                                  <span>{item.vacancies} Vacancies</span>
                                  <Link
                                    to="/job-list"
                                    state={{ name: item.name, id: item._id }}
                                  >
                                    VIEW DETAIL
                                  </Link>
                                </div>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_question_list section_4">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h1 className="main_section_title">
                      <img
                        src={process.env.PUBLIC_URL + "/assets/video/group.png"}
                        className="mr-2 me-2"
                      />
                      State Jobs
                    </h1>
                  </div>
                  <div className="col-12 mb-5">
                    <div className="search_filter">
                      <div className="search_left">
                        <label>Search</label>
                        <div className="search_bar">
                          <input
                            type="search"
                            name=""
                            placeholder="Search State"
                            onChange={(e) => stateAPIData(e.target.value, "")}
                          />
                          <button>
                            <img src="assets/video/search.svg" />
                          </button>
                        </div>
                      </div>
                      <div className="search_right">
                        <label className="mr-2">SORT BY:</label>
                        <div className="filter_selection filter_r">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) => stateAPIData("", e.target.value)}
                          >
                            <option value="">Default Order</option>
                            <option value="L">Latest</option>
                            <option value="P">Popular</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12">
                    <div className="list_cities">
                      <ul>
                        {isStateList &&
                          isStateList.map((item, index) => {
                            return (
                              <li key={index}>
                                <Link
                                  to="/job-list"
                                  state={{ name: item.state, id: item._id }}
                                >
                                  <Tooltip title={item.state}>
                                    <h5>
                                      {item.state.length < 15
                                        ? item.state
                                        : item.state.slice(0, 15) + "..."}
                                    </h5>
                                  </Tooltip>
                                  <span>({item.vacancies} Jobs)</span>
                                </Link>
                              </li>
                            );
                          })}
                      </ul>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_question_list section_5">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h1 className="main_section_title">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/video/qualification.png"
                        }
                        className="mr-2 me-2"
                      />
                      Qualification-wise jobs
                    </h1>
                  </div>
                  <div className="col-12 mb-5">
                    <div className="search_filter">
                      <div className="search_left">
                        <label>Search</label>
                        <div className="search_bar">
                          <input
                            type="search"
                            name=""
                            placeholder="Search Qualification"
                            onChange={(e) =>
                              qualificationAPIData(e.target.value, "")
                            }
                          />
                          <button>
                            <img src="assets/video/search.svg" />
                          </button>
                        </div>
                      </div>
                      <div className="search_right">
                        <label className="mr-2">SORT BY:</label>
                        <div className="filter_selection filter_r">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) =>
                              qualificationAPIData("", e.target.value)
                            }
                          >
                            <option value="">Default Order</option>
                            <option value="L">Latest</option>
                            <option value="P">Popular</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>

                  {isQualificationList &&
                    isQualificationList
                      .slice(
                        Qpage * QrowsPerPage,
                        Qpage * QrowsPerPage + QrowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <div className="col-md-3 col-sm-6 col-12 mb-3">
                            <div className="job_wise">
                              {/* <label className="left_side">Featured</label> */}
                              {/* <label className="right_side">Full Time</label> */}
                              <div className="box_icon mb-2">
                                <img src="assets/video/cap.svg" />
                              </div>
                              <div className="box_text">
                                <h6>{item.name}</h6>
                                <span>{item.vacancies} Vacancies</span>
                                <p className="mt-4">
                                  <img src="assets/video/map-pin.svg" /> All
                                  India
                                </p>
                                <Link
                                  to="/job-list"
                                  state={{ name: item.name, id: item._id }}
                                >
                                  VIEW DETAIL
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  {isQualificationList.length <= QrowsPerPage ? (
                    <></>
                  ) : (
                    <div
                      type="button"
                      class="col-12 text-center"
                      onClick={() => setQRowsPerPage(QrowsPerPage + 8)}
                    >
                      <span class="text-theme">
                        See More{" "}
                        <img src="assets/video/caret.svg" class="ms-1" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_question_list section_6">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-3">
                    <h1 className="main_section_title">
                      <img
                        src="assets/video/section_2.svg"
                        className="mr-2 me-2"
                      />
                      Designation-wise jobs
                    </h1>
                  </div>
                  <div className="col-12 mb-5">
                    <div className="search_filter">
                      <div className="search_left">
                        <label>Search</label>
                        <div className="search_bar">
                          <input
                            type="search"
                            name=""
                            placeholder="Search Designation"
                            onChange={(e) =>
                              designationAPIData(e.target.value, "")
                            }
                          />
                          <button>
                            <img src="assets/video/search.svg" />
                          </button>
                        </div>
                      </div>
                      <div className="search_right">
                        <label className="mr-2">SORT BY:</label>
                        <div className="filter_selection filter_r">
                          <select
                            className="form-select"
                            aria-label="Default select example"
                            onChange={(e) =>
                              designationAPIData("", e.target.value)
                            }
                          >
                            <option value="">Default Order</option>
                            <option value="L">Latest</option>
                            <option value="P">Popular</option>
                          </select>
                        </div>
                      </div>
                    </div>
                  </div>
                  {isDesignationList &&
                    isDesignationList
                      .slice(
                        page * rowsPerPage,
                        page * rowsPerPage + rowsPerPage
                      )
                      .map((item, index) => {
                        return (
                          <div
                            className="col-md-12 col-sm-12 col-12 mb-3"
                            key={index}
                          >
                            <div className="sorted_listed active">
                              <div className="left_info">
                                <div className="sort_icon">
                                  <img src="assets/video/manager.png" />
                                </div>
                                <div className="sort_text">
                                  <h5>
                                    {item.name} Jobs
                                    {/* <label>Full Time</label> */}
                                  </h5>
                                  <span>{item.vacancies} Vacancies</span>
                                  <div className="tag">
                                    {item.job_name.map((item1, index1) => {
                                      return <span key={index1}>{item1}</span>;
                                    })}
                                  </div>
                                  <p className="mt-3">
                                    <img
                                      src="assets/video/map-pin.svg"
                                      className="me-1"
                                    />{" "}
                                    All India
                                  </p>
                                </div>
                              </div>
                              <div className="right_button">
                                <Link
                                  to="/job-list"
                                  state={{ name: item.name, id: item._id }}
                                  className="btn btn-detail"
                                >
                                  View Detail
                                </Link>
                              </div>
                            </div>
                          </div>
                        );
                      })}
                  {/* {console.log("----------------------------->>>", isDesignationList.length > rowsPerPage, "====", isDesignationList.length, "------>0", rowsPerPage)} */}
                  {isDesignationList.length <= rowsPerPage ? (
                    <></>
                  ) : (
                    <div
                      type="button"
                      class="col-12 text-center"
                      onClick={() => setRowsPerPage(rowsPerPage + 5)}
                    >
                      <span class="text-theme">
                        See More{" "}
                        <img src="assets/video/caret.svg" class="ms-1" />
                      </span>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
