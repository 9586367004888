// import React from "react";
// import SocialLogin from "react-social-login";

// const SocialButton = ({ triggerLogin, ...props }) => (
//   <button onClick={triggerLogin} {...props}>
//     Social Login
//   </button>
// );

// const SocialLoginButton = SocialLogin(SocialButton);

// const SocialLoginComponent = () => {
//   const handleSocialLogin = (user) => {
//     console.log(user);
//     // Handle the social login response
//   };

//   return (
//     <div>
//       <h2>Social Login</h2>
//       <SocialLoginButton
//         provider="facebook"
//         appId={783571483137799}
//         onLoginSuccess={handleSocialLogin}
//       />
//       <SocialLoginButton
//         provider="google"
//         appId="844845104372-h8htjngp1os1tb79nksc54dq7tko4r8n.apps.googleusercontent.com"
//         onLoginSuccess={handleSocialLogin}
//       />
//     </div>
//   );
// };

// export default SocialLoginComponent;

import React, { useCallback, useEffect, useState } from "react";
import {
  FacebookLoginButton,   
  GoogleLoginButton,
} from "react-social-login-buttons";
import { LoginSocialFacebook, LoginSocialGoogle } from "reactjs-social-login";
// import { check_unique } from "../../api/apiHandler";
// import { toast } from "react-toastify";

const SocialLogin = (props) => {
  const { onSuccess } = props;
  //   const [provider, setProvider] = useState("");
  //   const [profile, setProfile] = useState(null);
  //   console.log("provider: ", provider);
  //   console.log("profile: ", profile);

  const onLoginStart = useCallback(() => {
    // alert("login start");
  }, []);

  const handleLoginSuccess = useCallback(
    (provider, data) => {
      onSuccess(provider, data);
    },
    [onSuccess]
  );

  return (
    <>
      <div className="row">
        <LoginSocialGoogle
          typeResponse="accessToken"
          client_id={
            process.env.REACT_APP_GG_APP_ID ||
            "556639325530-dhqlsa05s19nt5o3hflmoiq0qd7ea0ut.apps.googleusercontent.com"
          }
          scope="openid profile email"
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            handleLoginSuccess(provider, data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
          className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"
        >
          {/* <div class="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"> */}
          <a class="login_with inter font_17">
            <img
              src="assets/images/icons/Google-Icon.svg"
              class="me-2"
              alt="google"
            />
            Sign in with Google
          </a>
          {/* </div> */}
          {/* <GoogleLoginButton /> */}
        </LoginSocialGoogle>
        <LoginSocialFacebook
          appId={process.env.REACT_APP_FB_APP_ID || 783571483137799}
          fieldsProfile={
            "id,name,email,birthday,gender,location,friends,relationship_status"
          }
          version="v12.0"
          scope="public_profile,email,user_friends,user_about_me"
          onLoginStart={onLoginStart}
          onResolve={({ provider, data }) => {
            // setProvider(provider);
            // setProfile(data);
            handleLoginSuccess(provider, data);
          }}
          onReject={(err) => {
            console.log(err);
          }}
          className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6"
        >
          <div class="">
            <a class="login_with inter font_17">
              <img
                src="assets/images/icons/Facebook-Icon.svg"
                class="me-2"
                alt="facebook"
              />{" "}
              Sign in with Facebook
            </a>
          </div>
        </LoginSocialFacebook>
      </div>
    </>
  );
};

export default SocialLogin;
