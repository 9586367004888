import React from 'react'
import HeaderProfile from '../HeaderProfile'
import Header from '../Header'

export default function AccountSettings() {
    return (
        <>  
            {/* {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile /> : <Header />} */}
            <div class="edit_text">
                <h4 class="font_25 font_600">Account Settings</h4>
                <div class="edit_radio_btn">
                    <div class="edit_check_heading">
                        <h5 class="gray_5a font_600 font_22">Do you want to be your account private ?</h5>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label light_gray_8b font_20" for="flexRadioDefault1">
                                EveryOne
                            </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label light_gray_8b font_20" for="flexRadioDefault1">
                                Only Me
                            </label>
                    </div>
                </div>
                <div class="edit_radio_btn">
                    <div class="edit_check_heading">
                        <h5 class="gray_5a font_600 font_22">Who Can Friends You?</h5>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label light_gray_8b font_20" for="flexRadioDefault1">
                                EveryOne
                            </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                            <label class="form-check-label light_gray_8b font_20" for="flexRadioDefault1">
                                Only Me
                            </label>
                    </div>
                </div>
                <div class="edit_radio_btn">
                    <div class="edit_check_heading">
                        <h5 class="gray_5a font_600 font_22">Who Can View your Posts</h5>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label light_gray_8b font_20" for="flexRadioDefault1">
                            Friends Only
                        </label>
                    </div>
                    <div class="form-check">
                        <input class="form-check-input" type="radio" name="flexRadioDefault" id="flexRadioDefault1" />
                        <label class="form-check-label light_gray_8b font_20" for="flexRadioDefault1">
                            EveryOne
                        </label>
                    </div>
                </div>
                <div class="account_list">
                    <div class="account_list_btn d-flex align-items-center justify-content-between">
                        <span class="gray_5a font_600 font_22">List of Contacts you have Blocked</span>
                        <button type="button"
                            class="blue_apply_bg orange_btn white_ff font_14 inter">
                            Block List
                        </button>
                    </div>
                    <div class="account_list_btn d-flex align-items-center justify-content-between">
                        <span class="gray_5a font_600 font_22">Recently Deleted Posts</span>
                        <button type="button"
                            class="blue_apply_bg orange_btn white_ff font_14 inter">
                            Deleted Posts
                        </button>
                    </div>
                </div>
                <div class="account_change">
                    <h4 class="font_25 font_600 mb-4">Account Changes</h4>
                    <div class="account_list_btn d-flex align-items-center justify-content-between">
                        <span class="light_gray_8b font_600 font_20">Hide Your Posts and profile</span>
                        <button type="button"
                            class="apply_black_btn font_18">
                            Deactivate Account
                        </button>
                    </div>
                    <div class="account_list_btn d-flex align-items-center justify-content-between">
                        <span class="light_gray_8b font_600 font_20">Delete your account and data</span>
                        <button type="button"
                            class="apply_black_btn font_18">
                            Delete Account
                        </button>
                    </div>
                </div>
            </div>
        </>
    )
}
