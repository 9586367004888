import React, { useEffect } from "react";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { signup } from "../../api/apiHandler";
import { v4 as uuid } from "uuid";
// import { NotificationManager } from 'react-notifications';
import { bodyEncryption } from "../../api/apiClient";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import { CometChat } from "@cometchat/chat-sdk-javascript";

export default function Username() {
  const location = useLocation();
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  // const usernamePattern = "^[a-z0-9_.]*$";
  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .required("Please Enter Username")
      .matches("^[a-z0-9_.]*$", "Please enter valid username")
      .min(2)
      .max(20),
    // .required("Please Enter Username")
    // .matches(/^[a-zA-Z0-9_.-@!#$%^&*]*$/, "Please enter valid username")
    // .min(2, "Username must be at least 2 characters")
    // .max(20, "Username must be at most 20 characters"),
    name: yup
      .string()
      .required("Please Enter Name")
      .matches("^[A-Za-z ]{3,32}$", "Please enter valid name")
      .min(3)
      .max(32),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const navigate = useNavigate();
  const authKey = process.env.REACT_APP_CHAT_AUTH_KEY;
  useEffect(() => {
    if (localStorage.getItem("step") != "2") {
      navigate('/')
    }
  }, [])
  const onSubmit = (data) => {
    window.scrollTo(0, 0);
    console.log('data: ', data);
    if (localStorage.getItem("step") == 2) {
      const payload = {
        username: data.username,
        name: data.name,
        login_type:
          location?.state?.provider === "google"
            ? "G"
            : location?.state?.provider === "facebook"
              ? "f"
              : "S",
        social_id:
          location?.state?.provider === "facebook"
            ? location?.state?.userID
            : location?.state?.sub,
        email: localStorage.getItem("email"),
        device_type: "W",
        device_token: small_id,
      };

      if (!location?.state?.provider) {
        payload.phone_number = localStorage.getItem("phone_number");
        payload.password = localStorage.getItem("password");
      }

      signup(payload).then((response) => {
        console.log("Line 34", response);
        if (response.code === 0) {
          setTimeout(() => {
            // NotificationManager.error(response.message);
            toast.error(response.message, {
              position: "top-center",
              autoClose: 1000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
          }, 1000);
        } else if (response.code === 1) {
          // setTimeout(() => {
          // NotificationManager.success(response.message);
          // toast.success(response.message, {
          //     position: "top-center",
          //     autoClose: 2000,
          //     hideProgressBar: true,
          //     closeOnClick: true,
          //     pauseOnHover: true,
          //     draggable: true,
          //     progress: undefined,
          //     theme: "colored",
          // });
          // }, 1000);
          var user = new CometChat.User(response.data._id);

          user.setName(response.data.name);
          user.setAvatar(response.data.profile_image);

          CometChat.createUser(user, authKey).then(
            (user) => {
              console.log("user created", user);
            },
            (error) => {
              console.log("error", error);
            }
          );
          navigate("/college-stream");
          localStorage.setItem("token", bodyEncryption(response.data.token));
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("isSignedUp", true);
          localStorage.setItem("login_type", response.data.login_type);
          localStorage.setItem("_id", response.data._id);
          localStorage.setItem(
            "notification_status",
            response.data.notification_status
          );
          localStorage.setItem("status", response.data.status);
          localStorage.setItem("profile_status", response.data.profile_status);
          localStorage.setItem("profile_image", response.data.profile_image);
          localStorage.setItem("step", 3);
        }
      });
    } else {
      toast.warn("Please fill the details of previous pages", {
        position: "top-center",
        autoClose: 1000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        toastId: "previous-page-details",
      });
    }
  };
  return (
    <>
      {localStorage.getItem("step") == "2" && (
        <>
          <Header />
          <section className="main_signup">
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />
            <div className="container">
              <div className="row">
                <div className="col-md-12">
                  <h1 className="font_30 font_600 text-center signup_heading_border_blue">
                    Sign Up Your <span className="blue_29">Account</span>
                  </h1>
                  <p className="inter font_18 text-center">
                    <q>
                      Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                      do eiusmod tempor incididunt.
                    </q>
                  </p>
                </div>
              </div>
              <div className="form__container">
                <div className="row">
                  <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                    <div className="left__container">
                      <div className="form_vector">
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "assets/images/icons/vector-form.svg"
                          }
                          className="img-fluid"
                          alt="vector"
                        />
                      </div>
                      <h6 className="font_26 black_00 font_600">Step 3</h6>
                      <p className="gray_5a font_16 font_500">
                        Enter your Username
                      </p>
                      <div className="steps d-md-flex">
                        <div className="progress__bar__container">
                          <ul className="ps-0">
                            <li className="active" id="icon1">
                              <span>1</span>
                            </li>
                            <li id="icon2" className="active">
                              <span>2</span>
                            </li>
                            <li id="icon3" className="active">
                              <span>3</span>
                            </li>
                            <li id="icon4">
                              <span>4</span>
                            </li>
                            <li id="icon5">
                              <span>5</span>
                            </li>
                            <li id="icon6">
                              <span>6</span>
                            </li>
                          </ul>
                        </div>
                        <div className="side__titles">
                          <div className="title__name">
                            <p className="font_16 font_500 active">Basic Details</p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500 active">
                              OTP Verification
                            </p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500 active">
                              Select UserName
                            </p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500">Select College</p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500">Find Friends</p>
                          </div>
                          <div className="title__name">
                            <p className="font_16 font_500 mb-0">Link Hashtags</p>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 position-relative">
                    <div className="right_form_1 right__container">
                      <div id="form3">
                        <div className="form_heading">
                          <h4 className="font_25 font_600 blue_29">
                            Select Username
                          </h4>
                          <p className="mb-0">
                            <q>Select your username</q>
                          </p>
                        </div>
                        <form method="post" onSubmit={handleSubmit(onSubmit)}>
                          <div className="row justify-content-center w-100 my-auto">
                            <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                              <div className="step_1_form">
                                <div className="mobile_number_input">
                                  <h6 className="font_18 font_500">Select Name</h6>{" "}
                                  <span style={{ color: "red" }}>
                                    {errors.name?.message}
                                  </span>
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/username.svg"
                                        }
                                        className="img-fluid"
                                        alt="phone"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      required=""
                                      // autoComplete="off"
                                      defaultValue={
                                        localStorage.getItem("name")
                                          ? localStorage.getItem("name")
                                          : location?.state?.name
                                            ? location?.state?.name
                                            : ""
                                      }
                                      {...register("name", {
                                        value: localStorage.getItem("name")
                                          ? localStorage.getItem("name")
                                          : location?.state?.name
                                            ? location?.state?.name
                                            : "",
                                      })}
                                    />
                                    <label htmlFor="name">Name</label>
                                  </div>
                                </div>
                                <div className="mobile_number_input">
                                  <h6 className="font_18 font_500">
                                    Select Username
                                  </h6>{" "}
                                  <span style={{ color: "red" }}>
                                    {errors.username?.message}
                                  </span>
                                  <div className="input-group">
                                    <span
                                      className="input-group-text"
                                      id="basic-addon1"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "assets/images/icons/username.svg"
                                        }
                                        className="img-fluid"
                                        alt="phone"
                                      />
                                    </span>
                                    <input
                                      type="text"
                                      className="form-control"
                                      required=""
                                      // autoComplete="off"
                                      defaultValue={localStorage.getItem(
                                        "username"
                                      )}
                                      {...register("username")}
                                    />
                                    <label htmlFor="name">Username</label>
                                  </div>
                                </div>
                                <div className="buttons_form_1 buttons">
                                  <Link
                                    to="/sign-up"
                                    state={location.state}
                                    className="font_15 prev__btn d-flex align-items-center"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/prev.svg"
                                      }
                                      className="img-fluid"
                                      alt="icon"
                                    />
                                    Previous
                                  </Link>
                                  <button
                                    type="submit"
                                    className="font_15  blue_button d-flex align-items-center"
                                  >
                                    Next{" "}
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/next.svg"
                                      }
                                      className="img-fluid"
                                      alt="icon"
                                    />{" "}
                                  </button>
                                </div>
                              </div>
                            </div>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      )}
    </>
  );
}
