import React, { useCallback, useEffect, useRef, useState } from "react";
import HeaderProfile from "../../HeaderProfile";
import Header from "../../Header";
import {
  add_comments,
  add_friend,
  add_post,
  get_saved_post,
  give_like_unlike,
  home_post,
  post_details,
  remove_follow,
  save_post,
  share_link,
  stories_list,
  undo_post_retweet,
} from "../../../api/apiHandler";

import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import S3 from "react-aws-s3";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";

import "react-responsive-carousel/lib/styles/carousel.min.css";
import TrendingTopics from "../../../common/TrendingTopics";
import Recommendation from "../../../common/Recommendation";
import ReactTimeAgo from "react-time-ago";
import EmojiPicker from "emoji-picker-react";
import { saveAs } from "file-saver";
import InfiniteScroll from "react-infinite-scroll-component";

import { showMessage } from "../../../common/RedirectPathManage";

// import { getTimeDifference } from "./components/PostUserDetails";
import ShareLink from "../HomeFeed/components/ShareLink";
import PostMenu from "../HomeFeed/components/postMenu";
import UploadPost from "../HomeFeed/components/UploadPost";
import NormalPostType from "../HomeFeed/module/NormalPostType";
import RetweetPostType from "../HomeFeed/module/RetweetPostType";
import RetweetWithQuote from "../HomeFeed/module/RetweetWithQuote";
import ReelsPostType from "../HomeFeed/module/ReelsPostType";
import { getTimeDifference } from "../HomeFeed/components/PostUserDetails";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/image",
};

const configVideo = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/video",
};

const configThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/thumb",
};

const configComment = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media",
};

const configCommentThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media/thumb",
};

export const ReactS3Client = new S3(config);
export const ReactS3ClientVideo = new S3(configVideo);
export const ReactS3ClientComment = new S3(configComment);
export const ReactS3ClientThumb = new S3(configThumb);
export const ReactS3ClientCommentThumb = new S3(configCommentThumb);
const options = {
  items: 1,
  loop: true,
  margin: 10,
  autoplay: true,
};

// export const renderTextWithHashtags = ({
//   text,
//   renderHtml = false,
//   showInDetails = false,
// }) => {
//   let setSeeMore;

//   if (renderHtml) {
//     const tempElement = document.createElement("div");
//     tempElement.innerHTML = text;
//     console.log("tempElement: ", tempElement.textContent.slice(0, 150));
//     setSeeMore = !showInDetails && tempElement.textContent?.length > 150;
//     text = setSeeMore ? tempElement.textContent.slice(0, 150) : text;
//   } else {
//     setSeeMore = !showInDetails && text.length > 150;
//     text = setSeeMore ? text.slice(0, 150) : text;
//   }

//   if (showInDetails && renderHtml) {
//     return (
//       <React.Fragment>
//         <p dangerouslySetInnerHTML={{ __html: text }}></p>
//       </React.Fragment>
//     );
//   }

//   const segments = text
//     ?.trim()
//     ?.split(/(#[^\s]+|\S+)/)
//     .filter(Boolean);

//   return setSeeMore && !showInDetails
//     ? [
//         ...segments.map((segment, index) => {
//           if (segment.startsWith("#")) {
//             return (
//               <React.Fragment key={index}>
//                 <a href={`${segment}`}>{segment}</a>
//               </React.Fragment>
//             );
//           } else {
//             return <React.Fragment key={index}>{segment}</React.Fragment>;
//           }
//         }),
//         <React.Fragment>
//           <button className="ms-2">see more</button>{" "}
//         </React.Fragment>,
//       ]
//     : segments.map((segment, index) => {
//         if (segment.startsWith("#")) {
//           return (
//             <React.Fragment key={index}>
//               <a href={`${segment}`}>{segment}</a>
//             </React.Fragment>
//           );
//         } else {
//           return <React.Fragment key={index}>{segment}</React.Fragment>;
//         }
//       });
// };

export default function SaveFeed({ interaction }) {
  var newArr = [];
  const [feeds, setFeeds] = useState([]);
  console.log("feeds:==================> ", feeds);
  const [singlePost, setSinglePost] = useState([]);
  const [post, setPost] = useState("");
  const [quotePost, setQuotePost] = useState("");
  const [postPermission, setPostPermission] = useState(false);

  const [comment, setComment] = useState("");
  console.log("comment: ", comment);

  const [preView, setPreview] = useState([]);

  const navigate = useNavigate();
  const [filesForRetweetQuote, setFileForRetweetQuote] = useState([]);
  const [selectedFileForRetweetQuote, setSelectedFileForRetweetQuote] =
    useState([]);
  const [filesForComments, setFileForComments] = useState([]);
  const [selectedFileForComments, setSelectedFileForComments] = useState([]);
  const [pageToken, setPageToken] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [showModal, setShowModal] = useState(false);

  const [heightWidthForQuotePost, setHeightWidthForQuotePost] = useState([]);
  const [heightWidthForComments, setHeightWidthForComments] = useState([]);

  const [width, setWidth] = useState();

  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);
  const [emojiPickerForQuotePost, setEmojiPickerForQuotePost] = useState(false);

  const [thumbnailForComments, setThumbnailForComments] = useState("");
  const [thumbnailForQuotePost, setThumbnailForQuotePost] = useState("");

  const [disablePhotoInputForComments, setDisablePhotoInputForComments] =
    useState(false);
  const [disableVideoInputForComments, setDisableVideoInputForComments] =
    useState(false);
  const [disablePhotoInputForQuotePost, setDisablePhotoInputForQuotePost] =
    useState(false);
  const [disableVideoInputForQuotePost, setDisableVideoInputForQuotePost] =
    useState(false);
  const [storyList, setStoryList] = useState([]);
  console.log("storyList: =========+++++++==>", storyList);
  var imageDimensions = [];
  const maxHeight = "500px";
  const maxHeightInInt = 500;
  const vidRef = useRef();
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });

  const location = useLocation();
  var newPhotos = [];

  function isImageValid(blobOrFile, callback) {
    const image = new Image();
    image.onload = function () {
      if (image.width > 0 && image.height > 0) {
        callback(true);
      } else {
        callback(false);
      }
    };
    image.onerror = function () {
      callback(false);
    };

    const blobURL = URL.createObjectURL(blobOrFile);
    image.src = blobURL;
  }

  const handleMouseEnter = (id) => {
    var v = document.getElementById(`banner${id}`);

    v.play();
  };

  const handleMouseLeave = (id) => {
    var v = document.getElementById(`banner${id}`);

    v.pause();
  };

  const handleFileInputForRetweetQuotes = async (e) => {
    setDisableVideoInputForQuotePost(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForQuotePost([
          ...heightWidthForQuotePost,
          imageDimensions,
        ]);
      };
      render.readAsDataURL(file[0]);
    };
    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };

            imageDimensions.push(obj);

            setWidth(imageDimensions?.width);
            setHeightWidthForQuotePost([
              ...heightWidthForQuotePost,
              imageDimensions,
            ]);
            setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
            setSelectedFileForRetweetQuote([
              ...filesForRetweetQuote,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);

        console.log("Line 219 ==========>", [
          ...filesForRetweetQuote,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForQuotePost(true);
  };

  const handleFileInputForComments = async (e) => {
    console.log("HEEEEEEEEEEEEEEEEEEEEEEEEEEEEREEEEEEEEEEEEE");
    // setDisableVideoInputForComments(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForComments([...heightWidthForComments, imageDimensions]);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);

            setWidth(imageDimensions?.width);
            setHeightWidthForComments([
              ...heightWidthForComments,
              imageDimensions,
            ]);
            setFileForComments([...filesForComments, file[i]]);
            setSelectedFileForComments([
              ...filesForComments,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFileForComments([...filesForComments, file[i]]);

        console.log("Line 105 ==========>", [
          ...filesForComments,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForComments(true);
  };

  const uploadImagesForRetweetQuotes = async () => {
    if (selectedFileForRetweetQuote?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForRetweetQuote?.length; j++) {
        let upload;
        let uploadThumb;
        if (selectedFileForRetweetQuote[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFileForRetweetQuote[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForQuotePost
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFileForRetweetQuote[j]
          );
        }

        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForRetweetQuote[j]?.type?.split("/")[0],
          width: heightWidthForQuotePost[j][0]?.width,
          height: heightWidthForQuotePost[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        newArr.push(obj);
      }

      retweetWithQuotes(newArr);
    }
  };

  const handleVideoUploadForComments = (event) => {
    setDisablePhotoInputForComments(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForComments([
            ...heightWidthForComments,
            imageDimensions,
          ]);
          setSelectedFileForComments([...filesForComments, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForComments(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFileForComments([...filesForComments, videoFile]);
    }
  };

  const handleVideoUploadForRetweetWithQuotes = (event) => {
    setDisablePhotoInputForQuotePost(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForQuotePost(imageDimensions);
          setSelectedFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForQuotePost(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
    }
  };

  const uploadImagesForComments = async () => {
    console.log("selectedFileForComments: ", selectedFileForComments);
    if (selectedFileForComments?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForComments?.length; j++) {
        let upload;
        let uploadThumb;
        if (selectedFileForComments[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForComments
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
        }

        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForComments[j]?.type?.split("/")[0],
          width: heightWidthForComments[j][0]?.width,
          height: heightWidthForComments[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        newArr.push(obj);
      }

      addComments(newArr);
    }
  };

  const myFeeds = useCallback((payload) => {
    setIsFetching(true);
    get_saved_post(payload).then((response) => {
      console.log("FEEEEEEDDDSS", response);
      if (response.code === 1 || response.code === "1") {
        if (payload.page_token == 1) {
          setFeeds(response?.data);
        } else {
          setFeeds((prevFeeds) => [...prevFeeds, ...response?.data]);
        }
      } else {
        setIsFetching(false);
      }
    });
  }, []);

  useEffect(() => {
    stories_list({}).then((response) => {
      if (response.code === 1 || response.code === "1") {
        setStoryList(response.data);
      } else {
      }
    });
  }, []);

  const handleRedirectToStory = useCallback(
    (id, media) => {
      navigate(`/stories/${id}/${media}`);
    },
    [navigate]
  );

  const likeUnlike = useCallback(
    (item) => {
      give_like_unlike({
        post_id: item?._id,
        type: item?.is_like === 1 ? "unlike" : "like",
        post_type: item?.post_type,
        owner_user_id: item?.user_id,
      }).then((res) => {
        if (res.code === 1 || res.code === "1") {
          const updatedFeeds = feeds.map((post) => {
            if (post?._id === item?._id) {
              return {
                ...post,
                is_like: item?.is_like === 1 ? 0 : 1,
                likes: item?.is_like === 1 ? post?.likes - 1 : post?.likes + 1,
              };
            }
            return post;
          });
          setFeeds(updatedFeeds);
        }
      });
    },
    [feeds]
  );

  const retweet = useCallback(
    ({ _id }) => {
      console.log("_id: ", _id);
      post_details({
        post_id: _id,
      }).then((response) => {
        console.log("response:++++653 ", response);
        if (response?.code === 1 || response?.code === "1") {
          add_post({
            description: response?.data?.description,
            media: [],
            retweet_id: response?.data?._id,
            module_type: response?.data?.module_type,
            post_type: "retweet",
            shared_feed: response?.data,
          }).then((res) => {
            if (res?.code === 1 || res?.code === "1") {
              toast.info(res?.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              const updatedFeeds = feeds.map((post) => {
                if (post?._id === _id) {
                  return {
                    ...post,
                    is_retweet: 1,
                    retweet_count: post?.retweet_count + 1,
                  };
                }
                return post;
              });
              setPageToken(1);
              setFeeds(updatedFeeds);
              myFeeds();
            }
          });
        }
      });
    },
    [feeds, myFeeds]
  );

  const postDetail = (postId) => {
    setSinglePost();
    post_details({
      post_id: postId,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        setSinglePost(response?.data);
      }
    });
  };

  const retweetWithQuotes = (posts) => {
    console.log("payloaddddddddddddddddd=========>", {
      description: quotePost,
      media: posts,
      post_type:
        (quotePost === "" || quotePost === undefined) && posts.length === 0
          ? "retweet"
          : "retweet_with_quote",
      shared_feed: singlePost,
      tag_users: [],
      hashtags: [],
      web_link: [],
      module_type: singlePost?.module_type,
    });
    add_post({
      description: quotePost,
      media: posts,
      post_type:
        (quotePost === "" || quotePost === undefined) && posts.length === 0
          ? "retweet"
          : "retweet_with_quote",
      shared_feed: singlePost,
      tag_users: [],
      hashtags: [],
      web_link: [],
      module_type: singlePost?.module_type,
    }).then((res) => {
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const updatedFeeds = feeds.map((post) => {
          if (post?._id === singlePost?._id) {
            return {
              ...post,
              is_retweet: 1,
              retweet_count: post?.retweet_count + 1,
            };
          }
          return post;
        });
        setFeeds(updatedFeeds);
        myFeeds();
        setFileForRetweetQuote();
        setSelectedFileForRetweetQuote();
        setHeightWidthForQuotePost();
        setQuotePost("");

        setDisablePhotoInputForQuotePost(false);
        setDisableVideoInputForQuotePost(false);
      }
    });
  };

  const addComments = (postImages) => {
    if ((comment === "" || comment === undefined) && postImages.length === 0) {
      toast.info("Please enter some text before post or upload media", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      var output_string = comment?.trim();
      console.log("{payyyyyyyyypost_id: singlePost?._id,      });", {
        post_id: singlePost?._id,
        comments_type: singlePost?.shared_feed?.comment_type,
        reply_comment_id:
          singlePost?.post_type === "retweet" ||
            singlePost?.post_type === "retweet_comments"
            ? singlePost?.retweet_id
            : "",
        owner_user_id:
          singlePost?.post_type === "retweet"
            ? singlePost?.shared_feed?.user_id
            : singlePost?.user_id,
        media: postImages,
        hashtags: [],
        description: output_string,
      });

      const x = {
        comments_type: "main",
        description: "Har har mahadev",
        hashtags: [],
        module_type: "post",
        owner_user_id: "65cb2f5f6d7d9985b14267ad",
        post_id: "65f1a6691a996aed1a27712d",
        tag_users: [],
      };
      let newReq = {
        post_id:
          singlePost?.post_type == "retweet"
            ? singlePost?.retweet_id
            : singlePost?._id,
        comments_type: singlePost?.shared_feed?.comment_type || "main",
        // comments_type: singlePost?.post_type == "retweet" ? 'sub' : "main",
        // reply_comment_id: singlePost?.retweet_id,
        owner_user_id:
          singlePost?.post_type === "retweet"
            ? singlePost?.shared_feed?.user_id
            : singlePost?.user_id,
        media: postImages,
        hashtags: [],
        description: output_string,
      };
      if (singlePost?.post_type == "retweet") {
        newReq.module_type = singlePost?.module_type;
      } else {
        newReq.reply_comment_id = singlePost?.retweet_id;
      }
      // module_type: "post"
      console.log("newReq: ", newReq);
      add_comments(newReq).then((response) => {
        if (response.code === 1 || response.code === "1") {
          toast.info(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const updatedFeeds = feeds?.map((post) => {
            if (post?._id === singlePost?._id) {
              return {
                ...post,
                comment_count: post?.comment_count + 1,
              };
            }
            return post;
          });
          setFeeds(updatedFeeds);
          myFeeds();
          setFileForComments([]);
          setComment("");
          setSelectedFileForComments();
          setHeightWidthForComments();
          setEmojiPickerForComments(false);
          setDisablePhotoInputForComments(false);
          setDisableVideoInputForComments(false);
        }
      });
    }
  };

  const undoRetweet = useCallback(
    ({ _id }) => {
      console.log("_id:++++ ", _id);
      undo_post_retweet({ id: _id }).then((response) => {
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const updatedPosts = feeds?.map((post) => {
            if (post?._id == _id) {
              post.is_retweet = 0;
              post.retweet_count = post.retweet_count - 1;
            }
            return post;
          });
          setFeeds(updatedPosts);
        }
      });
    },
    [feeds]
  );
  const handleSavePost = useCallback(
    (item) => {
      save_post({
        post_id: item._id,
        type: item?.is_saved == "N" ? "save" : "remove",
      }).then((response) => {
        console.log("response: ", response);
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          const updatedPosts = feeds?.map((post) => {
            if (post?._id == item?._id) {
              post.is_saved = item?.is_saved == "N" ? "Y" : "N";
            }
            return post;
          });
          setFeeds(updatedPosts);
        }
      });
    },
    [feeds]
  );

  const removeImageForQuotePost = (index) => {
    var x = [...filesForRetweetQuote];
    var w = [...heightWidthForQuotePost];

    x.splice(index, 1);
    w.splice(index, 1);

    setFileForRetweetQuote(x);
    setSelectedFileForRetweetQuote(x);
    setHeightWidthForQuotePost(w);

    document.getElementById("fileInputForQuote").value = null;

    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForQuotePost(false);
      setDisableVideoInputForQuotePost(false);
    }
  };

  const removeImageForComments = (index) => {
    var x = [...filesForComments];
    var w = [...heightWidthForComments];

    x.splice(index, 1);
    w.splice(index, 1);

    setFileForComments(x);
    setSelectedFileForComments(x);
    setHeightWidthForComments(w);
    document.getElementById("imageForPosts").value = null;
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForComments(false);
      setDisableVideoInputForComments(false);
    }
  };

  const addFriend = (friend_id) => {
    add_friend({ friend_id: friend_id }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        showMessage(response.message);
      }
    });
  };

  const RemoveFriend = (id) => {
    remove_follow({ friend_id: id }).then((res) => {
      if (res.code == 1) {
        showMessage(res.message);
      }
    });
  };

  useEffect(() => {
    myFeeds({ page_token: pageToken });
  }, [myFeeds, pageToken]);

  const findWidth = (originalHeight, newHeight, originalWidth) => {
    return (newHeight * originalWidth) / originalHeight;
  };

  const isPortrait = (h, w) => {
    return h > w;
  };
  const HandleSetFeeds = (data) => {
    setFeeds(data)
  }
  const handleShare = useCallback((data) => {
    console.log("data: ", data);

    const tempDiv = document.createElement("div");
    tempDiv.innerHTML = data?.description;

    const text = tempDiv.innerText || tempDiv.textContent;
    const first150Chars = text.substring(0, 150);

    share_link({
      id: data._id,
      module_type: data.module_type,
      description:
        data.module_type == "news"
          ? first150Chars
          : data.description || "stulink",
    }).then((response) => {
      console.log("response: ", response);
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (location?.state?.errorMessage?.length) {
      toast.error(location?.state?.errorMessage);
      window.history.replaceState({}, "Title");
    }
  }, [location]);

  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
        localStorage.getItem("isSignedUp") ? (
        <>
          <HeaderProfile />
          <ShareLink
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
          <PostMenu
            postPermission={postPermission}
            setPostPermission={setPostPermission}
            singlePost={singlePost}
            feeds={feeds}
            setFeeds={setFeeds}
            HandleSetFeeds={HandleSetFeeds}
          />
          {/* Comment Modal new */}
          <div
            className="modal fade"
            id="commentModal"
            tabIndex={-1}
            aria-labelledby="comment"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-body position-relative">
                  <button
                    type="button"
                    className="btn-close close_custom"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setComment("");
                      setFileForComments([]);
                      setSelectedFileForComments([]);
                      setHeightWidthForComments([]);
                    }}
                  />
                  <div className="comment_main">
                    <div className="user_comment">
                      <div className="comment_user_post">
                        <div className="main_comment_other border_0 comment_line">
                          <div className="other_user_comment">
                            <span>
                              <a>
                                <img
                                  src={singlePost?.profile_image}
                                  className="inner_post"
                                />{" "}
                              </a>
                              <div className="user-name-link">
                                {singlePost?.name}
                                <p>@{singlePost?.username}</p>
                              </div>
                              <p>
                                {singlePost?.insertdate ? (
                                  <ReactTimeAgo
                                    date={singlePost?.insertdate}
                                    locale="en-US"
                                  />
                                ) : (
                                  <></>
                                )}
                              </p>
                            </span>
                          </div>
                          {/* <div className="comment_image ms-5 mt-3 mb-3">
                            <label></label>
                          </div> */}
                          <div className="comment_image ms-5 mt-3 mb-3">
                            {singlePost?.post_type === "retweet" ? (
                              <>
                                <label>
                                  {singlePost?.shared_feed?.description}
                                </label>

                                {singlePost?.shared_feed?.media?.length ===
                                  0 ? (
                                  <></>
                                ) : (
                                  <>
                                    {/* {singlePost?.shared_feed?.media?.map(
                                      (item) => {
                                        return item?.name;
                                      }
                                    )} */}
                                  </>
                                )}
                              </>
                            ) : singlePost?.post_type === "retweet_comments" ? (
                              <>
                                <label>
                                  {singlePost?.shared_feed?.description}
                                </label>

                                {singlePost?.shared_feed?.media?.length ===
                                  0 ? (
                                  <></>
                                ) : (
                                  <>
                                    {singlePost?.shared_feed?.media?.map(
                                      (item) => {
                                        return item?.name;
                                      }
                                    )}
                                  </>
                                )}
                              </>
                            ) : singlePost?.post_type ===
                              "retweet_with_quote" ? (
                              <>
                                <label>{singlePost?.description}</label>

                                <div
                                  className="tetweet_quote"
                                  style={{ marginLeft: "0px" }}
                                >
                                  <div className="post_person_mark">
                                    <Link to={"#"} className="post_person_name">
                                      <div className="post_person_image">
                                        <img
                                          src={
                                            singlePost?.shared_feed
                                              ?.profile_image
                                          }
                                          className="img-fluid"
                                          alt="profile-pic"
                                        />
                                      </div>
                                      <div className="post_person_text">
                                        <h6 className="font_12 font_600 mb-0">
                                          {singlePost?.shared_feed?.name}
                                        </h6>
                                        <span className="light_gray_8b font_12 inter">
                                          @{singlePost?.shared_feed?.username}
                                        </span>
                                      </div>
                                    </Link>
                                    <div className="post_person_date">
                                      <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                        {singlePost?.shared_feed?.insertdate ? (
                                          <ReactTimeAgo
                                            date={
                                              singlePost?.shared_feed
                                                ?.insertdate
                                            }
                                            locale="en-US"
                                          />
                                        ) : (
                                          <></>
                                        )}
                                      </h5>
                                    </div>
                                  </div>
                                  <div className="tetweet_quote_content">
                                    {singlePost?.shared_feed?.media?.length ===
                                      0 ? (
                                      <></>
                                    ) : singlePost?.shared_feed?.media[0]
                                      ?.type === "image" ? (
                                      <div className="tetweet_quote_image">
                                        <img
                                          src={
                                            singlePost?.shared_feed?.media[0]
                                              ?.name
                                          }
                                          alt="post"
                                        />
                                      </div>
                                    ) : (
                                      <div className="tetweet_quote_image">
                                        <img
                                          src={
                                            singlePost?.shared_feed?.media[0]
                                              ?.thumb
                                          }
                                          alt="post"
                                        />
                                      </div>
                                    )}
                                    {singlePost?.module_type === "news" ? (
                                      <div className="tetweet_quote_text">
                                        <p
                                          className="mb-0 gray_5a font_12"
                                          dangerouslySetInnerHTML={{
                                            __html:
                                              singlePost?.shared_feed
                                                ?.description,
                                          }}
                                        ></p>
                                      </div>
                                    ) : (
                                      <div className="tetweet_quote_text">
                                        <p className="mb-0 gray_5a font_12">
                                          {singlePost?.shared_feed?.description}
                                        </p>
                                      </div>
                                    )}
                                  </div>
                                </div>
                              </>
                            ) : singlePost?.module_type === "news" ? (
                              <label
                                dangerouslySetInnerHTML={{
                                  __html: singlePost?.description,
                                }}
                              ></label>
                            ) : (
                              <label>{singlePost?.description}</label>
                            )}

                            <br />
                            {singlePost && singlePost?.media?.length === 0 ? (
                              <></>
                            ) : (
                              <label>
                                {/* {singlePost?.media?.map((item) => {
                                  return item?.name;
                                })} */}
                              </label>
                            )}
                          </div>
                          <div className="other_user_comment d-flex">
                            <span>
                              <a href="#">
                                <img
                                  src={localStorage?.getItem("profile_image")}
                                  className="inner_post"
                                />
                              </a>
                            </span>
                            <input
                              type="text"
                              name=""
                              disabled={filesForComments?.length}
                              value={comment}
                              onChange={(e) => {
                                // if (e?.target?.value !== "" && e?.target?.value.trim() != "") {
                                //   console.log('e?.target?.value: ', e?.target?.value);
                                setComment(e?.target?.value);
                                setEmojiPickerForComments(false);
                                // }
                              }}
                              placeholder={
                                filesForComments?.length
                                  ? ""
                                  : "Post your comment..!!"
                              }
                            />
                          </div>
                          <div className="uploade_images">
                            {filesForComments?.length !== 0 ? (
                              filesForComments?.map((item, key) => {
                                switch (item.type.split("/")[0]) {
                                  case "image": {
                                    return (
                                      <div className="images_tab_1">
                                        <img src={URL.createObjectURL(item)} />
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/x-circle.svg"
                                          }
                                          className="cross-icon"
                                          onClick={() => {
                                            removeImageForComments(key);
                                          }}
                                        />
                                      </div>
                                    );
                                  }
                                  case "video": {
                                    return (
                                      <div
                                        className="images_tab_1"
                                        onMouseEnter={() =>
                                          handleMouseEnter(key)
                                        }
                                        onMouseLeave={() =>
                                          handleMouseLeave(key)
                                        }
                                      >
                                        <video
                                          autoPlay={false}
                                          loop=""
                                          id={`banner${key}`}
                                          controls="false"
                                          controlslist="nodownload"
                                          style={{ height: "200px" }}
                                        >
                                          <source
                                            src={URL.createObjectURL(item)}
                                          />
                                        </video>
                                        {/* <video
                                          controls
                                          muted
                                          loop
                                          style={{ height: "200px" }}
                                        >
                                          <source
                                            src={URL.createObjectURL(item)}
                                          />
                                        </video> */}
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/x-circle.svg"
                                          }
                                          className="cross-icon"
                                          onClick={() =>
                                            removeImageForComments(key)
                                          }
                                        />
                                      </div>
                                    );
                                  }
                                }
                              })
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className="insert_images align-items-center d-flex m-4">
                  {!comment?.length && (
                    <label
                      style={
                        disablePhotoInputForComments === true ||
                          filesForComments?.length === 4 ||
                          selectedFileForComments?.length === 4 ||
                          heightWidthForComments?.length === 4
                          ? {}
                          : { cursor: "pointer" }
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        accept=".png, .jpeg, .jpg"
                        id="imageForPosts"
                        multiple
                        // onChange={handleFileInputForComments}
                        onChange={(e) => {
                          handleFileInputForComments(e);
                        }}
                        disabled={
                          disablePhotoInputForComments === true ||
                          filesForComments?.length === 4 ||
                          selectedFileForComments?.length === 4 ||
                          heightWidthForComments?.length === 4
                        }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/gallery.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  )}
                  <div
                    className="ms-2"
                    onClick={() =>
                      setEmojiPickerForComments(!emojiPickerForComments)
                    }
                  >
                    <label style={{ cursor: "pointer" }}>
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/emoji.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                  </div>

                  <div className="sania_btn_2 ms-auto">
                    <button
                      disabled={!comment.trim() && !filesForComments?.length}
                      onClick={() => {
                        uploadImagesForComments();
                      }}
                      data-bs-dismiss="modal"
                      aria-label="Close"
                      className="blue_button d-flex align-items-center font_16 font_500"
                    >
                      COMMENT
                    </button>
                  </div>
                </div>
                {emojiPickerForComments ? (
                  <div className="mx-5 mt-0 mb-2 emoji-main-div">
                    <EmojiPicker
                      onEmojiClick={(e) => setComment(comment + e?.emoji)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          {/* Delete Post */}

          {/* New Retweet with quotes modal */}
          <div
            className="modal fade"
            id="retweetModal"
            tabIndex={-1}
            aria-labelledby="retweet"
            aria-hidden="true"
          >
            <div className="modal-dialog modal-lg">
              <div className="modal-content">
                <div className="modal-header">
                  <h5 className="modal-title" id="exampleModalLabel">
                    Viralled Post
                  </h5>
                  <button
                    type="button"
                    className="btn-close close_custom"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setQuotePost("");
                      setFileForRetweetQuote([]);
                      setSelectedFileForRetweetQuote([]);
                      setHeightWidthForQuotePost([]);
                    }}
                  />
                </div>
                <div className="modal-body position-relative">
                  {/* <button
                    type="button"
                    className="btn-close close_custom"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                    onClick={() => {
                      setQuotePost("");
                      setFileForRetweetQuote([]);
                      setSelectedFileForRetweetQuote([]);
                      setHeightWidthForQuotePost([]);
                    }}
                  /> */}
                  <div className="comment_main">
                    <div className="user_comment">
                      <div className="user_img_comment">
                        <a href="#">
                          <img src={localStorage?.getItem("profile_image")} />
                        </a>
                        <p>{localStorage?.getItem("name")}</p>
                      </div>
                      <div className="comment_user_post">
                        <input
                          type="text"
                          name=""
                          placeholder="Add comment"
                          value={quotePost}
                          onChange={(e) => {
                            setQuotePost(e.target.value);
                            setEmojiPickerForQuotePost(false);
                          }}
                        />
                        <div className="uploade_images w-100">
                          {filesForRetweetQuote?.length !== 0 ? (
                            filesForRetweetQuote?.map((item, key) => {
                              switch (item.type.split("/")[0]) {
                                case "image": {
                                  return (
                                    <div
                                      className="images_tab_1 images_tab_custom"
                                      style={
                                        filesForRetweetQuote !== "" ||
                                          filesForRetweetQuote !== undefined ||
                                          filesForRetweetQuote !== null
                                          ? {}
                                          : { display: "none" }
                                      }
                                      key={key}
                                    >
                                      <img
                                        src={URL.createObjectURL(item)}
                                        style={{
                                          // height: "100px",
                                          // width: "100%",
                                          display:
                                            filesForRetweetQuote === ""
                                              ? "none"
                                              : "",
                                        }}
                                      />
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/x-circle.svg"
                                        }
                                        className="cross-icon"
                                        onClick={() => {
                                          removeImageForQuotePost(key);
                                        }}
                                      />
                                    </div>
                                  );
                                }
                                case "video": {
                                  return (
                                    <div
                                      className="images_tab_1"
                                      onMouseEnter={() => handleMouseEnter(key)}
                                      onMouseLeave={() => handleMouseLeave(key)}
                                      style={
                                        filesForRetweetQuote !== "" ||
                                          filesForRetweetQuote !== undefined ||
                                          filesForRetweetQuote !== null
                                          ? {}
                                          : { display: "none" }
                                      }
                                      key={key}
                                    >
                                      <video
                                        autoPlay={false}
                                        loop=""
                                        id={`banner${key}`}
                                        controls="false"
                                        controlslist="nodownload"
                                        style={{
                                          width: "120px",
                                          display:
                                            filesForRetweetQuote === ""
                                              ? "none"
                                              : "",
                                        }}
                                      >
                                        <source
                                          src={URL.createObjectURL(item)}
                                        />
                                      </video>
                                      {/* <video
                                        controls
                                        muted
                                        loop
                                       style={{
                                          width: "120px",
                                          display:
                                            filesForRetweetQuote === ""
                                              ? "none"
                                              : "",
                                        }}
                                      >
                                        <source
                                          src={URL.createObjectURL(item)}
                                        />
                                      </video> */}
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/x-circle.svg"
                                        }
                                        className="cross-icon"
                                        onClick={() => {
                                          removeImageForQuotePost(key);
                                        }}
                                      />
                                    </div>
                                  );
                                }
                              }
                            })
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className="main_comment_other">
                          <div className="other_user_comment">
                            <span>
                              <a style={{ display: "flex", gap: "10px" }}>
                                <img
                                  src={singlePost?.profile_image}
                                  className="inner_post"
                                />{" "}
                                <div className="hlink-a">
                                  <p className="user-main-name-p">
                                    {singlePost?.name}
                                  </p>
                                  <p>@{singlePost?.username}</p>
                                </div>
                              </a>
                              <p>{getTimeDifference(singlePost?.insertdate)}</p>
                            </span>
                            <br />
                            {/* <label>
                                                                {singlePost?.description}
                                                            </label> */}
                          </div>
                          <div className="tetweet_quote_content">
                            {singlePost?.media?.length > 0 ? (
                              singlePost?.media[0]?.type !== "image" ? (
                                <div className="tetweet_quote_image12121">
                                  <img
                                    src={singlePost?.media[0]?.name}
                                    alt="post"
                                  />
                                </div>
                              ) : (
                                <div className="tetweet_quote_image">
                                  <img
                                    src={singlePost?.media[0]?.only_name}
                                    alt="post"
                                  />
                                </div>
                              )
                            ) : (
                              <></>
                            )}
                            {singlePost?.module_type === "news" ? (
                              <div className="tetweet_quote_text">
                                <p
                                  className="mb-0 gray_5a font_12"
                                  dangerouslySetInnerHTML={{
                                    __html: singlePost?.description,
                                  }}
                                ></p>
                              </div>
                            ) : (
                              <div className="tetweet_quote_text">
                                <p className="mb-0 gray_5a font_12">
                                  {singlePost?.description}
                                </p>
                              </div>
                            )}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="modal-footer d-flex justify-content-between">
                  <div className="insert_images">
                    <label
                      style={
                        disablePhotoInputForQuotePost === true ||
                          filesForRetweetQuote?.length === 4 ||
                          selectedFileForRetweetQuote?.length === 4 ||
                          heightWidthForQuotePost === 4
                          ? {}
                          : { cursor: "pointer" }
                      }
                    >
                      <input
                        type="file"
                        style={{ display: "none" }}
                        multiple
                        id="fileInputForQuote"
                        accept=".png, .jpeg, .jpg"
                        onChange={handleFileInputForRetweetQuotes}
                        disabled={
                          disablePhotoInputForQuotePost === true ||
                          filesForRetweetQuote?.length === 4 ||
                          selectedFileForRetweetQuote?.length === 4 ||
                          heightWidthForQuotePost === 4
                        }
                      />
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/gallery.svg"
                        }
                        className="img-fluid"
                        alt="gallery"
                      />
                    </label>
                    {/* <a href="javascript:void(0)">
                                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid mx-3"
                                                    alt="gallery" />
                                            </a> */}
                    <a
                      style={{ cursor: "pointer" }}
                      onClick={() =>
                        setEmojiPickerForQuotePost(!emojiPickerForQuotePost)
                      }
                    >
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/emoji.svg"
                        }
                        className="img-fluid mx-3"
                        alt="gallery"
                      />
                    </a>
                  </div>
                  <button
                    disabled={
                      !quotePost.trim() && !filesForRetweetQuote?.length
                    }
                    onClick={() => {
                      uploadImagesForRetweetQuotes();
                    }}
                    style={{
                      cursor:
                        !quotePost.trim() && !filesForRetweetQuote?.length
                          ? ""
                          : "pointer",
                    }}
                    className="reels_btn post_btn"
                    data-bs-dismiss="modal"
                    aria-label="Close"
                  >
                    Post
                  </button>
                </div>
                {emojiPickerForQuotePost ? (
                  <div className="mx-5 mt-0 mb-3">
                    <EmojiPicker
                      onEmojiClick={(e) => setQuotePost(quotePost + e?.emoji)}
                    />
                  </div>
                ) : (
                  <></>
                )}
              </div>
            </div>
          </div>

          <section className="home">
            <ToastContainer
              position="top-center"
              autoClose={2000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
              pauseOnHover
              theme="colored"
            />

            <div className="container mt-5">
              <div className="row">
                <div className="col-12 col-xl-6 mx-auto col-lg-7 col-md-12 col-sm-12">
                  <div
                    className="d-flex"
                    style={{
                      boxShadow: " 0px 8px 30px 0px #0000001A",
                      padding: "28px 15px",
                      marginBottom: "40px",
                    }}
                  >
                    <p
                      style={{
                        marginLeft: "26px",
                        color: "#000000",
                        fontFamily: "Poppins",
                        fontSize: "18px",
                        fontWeight: 600,
                        lineHeight: "27px",
                        letterSpacing: "0px",
                      }}
                    >
                      Saved Post
                    </p>
                  </div>
                  <div className="row">
                    <InfiniteScroll
                      dataLength={feeds?.length}
                      next={() => {
                        setPageToken(pageToken + 1);
                      }}
                      hasMore={isFetching}
                    >
                      {console.log("feeds: ", feeds)}
                      {feeds?.length ? (
                        feeds?.map((item, key) => {
                          return (
                            <>
                              {item.post_type == "normal" ? (
                                <NormalPostType
                                  retweet={retweet}
                                  undoRetweet={undoRetweet}
                                  singlePost={singlePost}
                                  setSinglePost={setSinglePost}
                                  setPostPermission={setPostPermission}
                                  likeUnlike={likeUnlike}
                                  handleShare={handleShare}
                                  handleSavePost={handleSavePost}
                                  item={item}
                                  key={key}
                                />
                              ) : item.post_type == "retweet" ||
                                item.post_type == "retweet_comments" ? (
                                <RetweetPostType
                                  retweet={retweet}
                                  undoRetweet={undoRetweet}
                                  setPostPermission={setPostPermission}
                                  singlePost={singlePost}
                                  setSinglePost={setSinglePost}
                                  likeUnlike={likeUnlike}
                                  handleShare={handleShare}
                                  handleSavePost={handleSavePost}
                                  item={item}
                                  key={key}
                                />
                              ) : item.post_type == "retweet_with_quote" ||
                                item.post_type ==
                                "retweet_comments_with_quote" ? (
                                <RetweetWithQuote
                                  retweet={retweet}
                                  undoRetweet={undoRetweet}
                                  setPostPermission={setPostPermission}
                                  singlePost={singlePost}
                                  setSinglePost={setSinglePost}
                                  likeUnlike={likeUnlike}
                                  handleShare={handleShare}
                                  handleSavePost={handleSavePost}
                                  item={item}
                                  key={key}
                                />
                              ) : item.post_type == "reels" ? (
                                <ReelsPostType
                                  retweet={retweet}
                                  undoRetweet={undoRetweet}
                                  setPostPermission={setPostPermission}
                                  singlePost={singlePost}
                                  setSinglePost={setSinglePost}
                                  likeUnlike={likeUnlike}
                                  handleShare={handleShare}
                                  handleSavePost={handleSavePost}
                                  item={item}
                                  key={key}
                                  interaction={interaction}
                                />
                              ) : (
                                <></>
                              )}
                            </>
                          );
                        })
                      ) : (
                        <p className="text-center fw-bold fs-6">No Data Found</p>
                      )}
                    </InfiniteScroll>
                  </div>
                </div>
                <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                  <div className="trending_admission_box mb-5">
                    <div className="profile_trending trending_admission_box_heading">
                      <h6 className="font_20 font_600 mb-0">
                        Recommended <span className="blue_29">Profiles</span>
                      </h6>
                    </div>
                    <Recommendation />
                  </div>
                  <div className="trending_admission_box">
                    <div className="profile_trending trending_admission_box_heading">
                      <h6 className="font_20 font_600 mb-0">
                        Trending <span className="blue_29">Topics</span>
                      </h6>
                    </div>
                    <TrendingTopics />
                  </div>
                </div>
              </div>
            </div>
          </section>
        </>
      ) : (
        <Header />
      )}
    </>
  );
}
