import React, { useEffect, useRef, useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { RenderTextWithHashtags } from "../HomeFeed";
import ReactTimeAgo from "react-time-ago";
import PostLikeComment from "../components/PostLikeComment";
import PostUserDetails from "../components/PostUserDetails";

const RetweetWithQuote = (props) => {
  const {
    item,
    key,
    setSinglePost,
    singlePost,
    retweet,
    handleShare,
    setPostPermission,
    likeUnlike,
    undoRetweet,
    handleSavePost,
    isRetweet = false,
    mainItem,
    // isComment = false,
    isDetailPage = false,
    interaction,
  } = props;

  const navigate = useNavigate();
  const isSubComment = item?.shared_feed?.comment_type == "sub";
  console.log("isSubComment: ", isSubComment);
  const videoRef = useRef(null);
  const [muted, setMuted] = useState(false);
  const [isVisible, setIsVisible] = useState(false);

  const hideImage = () => {
    setIsVisible(false);
  };
  const toggleMute = () => {
    setMuted(!muted);

    setIsVisible(true);

    if (videoRef.current) {
      videoRef.current.muted = !muted;
    }

    setTimeout(() => {
      hideImage();
    }, 2000);
  };
  const isPortrait = (h, w) => {
    return h > w;
  };
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Adjust the threshold as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (videoRef.current && entry.isIntersecting) {
          // Video is in view, autoplay
          videoRef.current.play();
        } else if (videoRef.current) {
          // Video is not in view, pause
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }
  }, []);

  return (
    <>
      {item?.shared_feed?.module_type == "document" ? (
        <div className="col-12 mb-3" key={item?._id}>
          {isRetweet && (
            <div className="repost_gray">
              <div className="repost_gray_iamge">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/post-repost-white.svg"
                  }
                  className="img-fluid"
                  alt="repost"
                />
              </div>
              <p className="mb-0 gray_5a font_12 font_500">
                {" "}
                {item?.name === localStorage?.getItem("name")
                  ? "You"
                  : item?.name}{" "}
                {item?.retweet_count > 1 ? "and others" : ""}{" "}
                <span className="blue_29">Viral</span> this
              </p>
            </div>
          )}
          <div className="home_post_main content_orient repost_post">
            <div className="top_hader">
              <PostUserDetails
                setPostPermission={setPostPermission}
                setSinglePost={setSinglePost}
                item={item}
              />
              <div
                className="content_base post_cpntent"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/post/${item?._id}`);
                }}
              >
                <p className="text-break">
                  {item?.media?.length > 0 && (
                    <img
                      src={item?.media?.[0]?.name}
                      className="re_tweet_img"
                    />
                  )}
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
                <div className="inner_post_retweet">
                  <div className="top_hader">
                    <PostUserDetails
                      setPostPermission={setPostPermission}
                      setSinglePost={setSinglePost}
                      item={item?.shared_feed}
                      disableMenu={true}
                    />
                    <div
                      className="content_base post_cpntent"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isSubComment) {
                          navigate(`/comments`, {
                            state: { comment: item?.shared_feed },
                          });
                        } else {
                          navigate(`/post/${item?.shared_feed?._id}`);
                        }
                      }}
                    >
                      <div className="reposed_img_video">
                        <img
                          src={item?.shared_feed?.media?.[0]?.thumb}
                          alt=""
                        />
                        {/* <a href="#">
                          <img src="assets/images/play-circle-fill.svg" />
                        </a> */}
                      </div>
                      <p className="text-break">
                        <RenderTextWithHashtags
                          text={item?.description || ""}
                          showInDetails={isDetailPage}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleShare={handleShare}
                item={isRetweet ? mainItem : item}
                handleSavePost={handleSavePost}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      ) : item?.shared_feed?.module_type == "news" ? (
        <div className="col-12 mb-3" key={item?._id}>
          {isRetweet && (
            <div className="repost_gray">
              <div className="repost_gray_iamge">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/post-repost-white.svg"
                  }
                  className="img-fluid"
                  alt="repost"
                />
              </div>
              <p className="mb-0 gray_5a font_12 font_500">
                {" "}
                {item?.name === localStorage?.getItem("name")
                  ? "You"
                  : item?.name}{" "}
                {item?.retweet_count > 1 ? "and others" : ""}{" "}
                <span className="blue_29">Viral</span> this
              </p>
            </div>
          )}
          <div className="home_post_main content_orient repost_post">
            <div className="top_hader">
              <PostUserDetails
                setPostPermission={setPostPermission}
                setSinglePost={setSinglePost}
                item={item}
              />
              <div
                className="content_base post_cpntent"
                onClick={(e) => {
                  e.stopPropagation();
                  navigate(`/post/${item?._id}`);
                }}
              >
                <p className="text-break">
                  {item?.media?.length > 0 && (
                    <img
                      src={item?.media?.[0]?.name}
                      className="re_tweet_img"
                    />
                  )}
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
                <div className="inner_post_retweet">
                  <div className="top_hader">
                    <PostUserDetails
                      setPostPermission={setPostPermission}
                      setSinglePost={setSinglePost}
                      item={item?.shared_feed}
                      disableMenu={true}
                    />
                    <div
                      className="content_base post_cpntent"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/post/${item?.shared_feed?._id}`);
                      }}
                    >
                      <div className="reposed_img_video">
                        <img
                          src={item?.shared_feed?.media?.[0]?.thumb}
                          alt=""
                        />
                        {/* <a href="#">
                          <img src="assets/images/play-circle-fill.svg" />
                        </a> */}
                      </div>
                      <p>{item?.shared_feed?.title}</p>
                    </div>
                  </div>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleShare={handleShare}
                item={item}
                handleSavePost={handleSavePost}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      ) : item?.shared_feed?.media.length === 0 ? (
        <div className="col-12 mb-3" key={item?._id}>
          {isRetweet && (
            <div className="repost_gray">
              <div className="repost_gray_iamge">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/post-repost-white.svg"
                  }
                  className="img-fluid"
                  alt="repost"
                />
              </div>
              <p className="mb-0 gray_5a font_12 font_500">
                {" "}
                {item?.name === localStorage?.getItem("name")
                  ? "You"
                  : item?.name}{" "}
                {item?.retweet_count > 1 ? "and others" : ""}{" "}
                <span className="blue_29">Viral</span> this
              </p>
            </div>
          )}
          <div className="home_post_main content_orient repost_post">
            <div className="top_hader">
              <PostUserDetails
                setPostPermission={setPostPermission}
                setSinglePost={setSinglePost}
                item={item}
              />
              <div
                className="content_base post_cpntent"
                onClick={(e) => {
                  e.stopPropagation();
                  // if (isSubComment) {
                  //   navigate(`/comments`, {
                  //     state: { comment: item?.shared_feed },
                  //   });
                  // } else {
                  navigate(`/post/${item?._id}`);
                  // }
                }}
              >
                {item?.media?.length > 0 && (
                  <img src={item?.media?.[0]?.name} className="re_tweet_img" />
                )}
                <p className="text-break">
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
                <div className="inner_post_retweet">
                  <div className="top_hader">
                    <PostUserDetails
                      setPostPermission={setPostPermission}
                      setSinglePost={setSinglePost}
                      item={item?.shared_feed}
                      disableMenu={true}
                    />
                    <div
                      className="content_base post_cpntent"
                      onClick={(e) => {
                        e.stopPropagation();
                        if (isSubComment) {
                          navigate(`/comments`, {
                            state: { comment: item?.shared_feed },
                          });
                        } else {
                          navigate(`/post/${item?.shared_feed?._id}`);
                        }
                      }}
                    >
                      {/* <div className="reposed_img_video">
                        <img
                          src="assets/images/page-image/about-college.svg"
                          className
                        />
                        <a href="#">
                          <img src="assets/images/play-circle-fill.svg" />
                        </a>
                      </div> */}
                      <p className="text-break">
                        <RenderTextWithHashtags
                          text={item?.description || ""}
                          showInDetails={isDetailPage}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                handleShare={handleShare}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleSavePost={handleSavePost}
                item={item}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      ) : item?.shared_feed?.media.length == 1 ? (
        item?.shared_feed?.media[0].type == "video" ? (
          <div className="col-12 mb-3" key={item?._id}>
            {isRetweet && (
              <div className="repost_gray">
                <div className="repost_gray_iamge">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/post-repost-white.svg"
                    }
                    className="img-fluid"
                    alt="repost"
                  />
                </div>
                <p className="mb-0 gray_5a font_12 font_500">
                  {" "}
                  {item?.name === localStorage?.getItem("name")
                    ? "You"
                    : item?.name}{" "}
                  {item?.retweet_count > 1 ? "and others" : ""}{" "}
                  <span className="blue_29">Viral</span> this
                </p>
              </div>
            )}
            <div className="home_post_main content_orient repost_post">
              <div className="top_hader">
                <PostUserDetails
                  setPostPermission={setPostPermission}
                  setSinglePost={setSinglePost}
                  item={item}
                />
                <div
                  className="content_base post_cpntent"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/post/${item?._id}`);
                  }}
                >
                  <p
                    className="text-break d-flex justify-content-center bg-black position-relative "
                    style={{
                      height:
                        item?.media?.length > 0 &&
                        isPortrait(
                          item?.media?.[0]?.height,
                          item?.media?.[0]?.width
                        )
                          ? "400px"
                          : "auto",
                    }}
                  >
                    {item?.media?.length > 0 &&
                      (item?.media?.[0]?.type == "video" ? (
                        <>
                          <video
                            className="bg-black w-100"
                            ref={videoRef}
                            autoPlay={!interaction}
                            muted={!interaction ? "muted" : ""}
                            loop={true}
                            id="banner"
                            controls={false}
                            controlsList="nodownload"
                            poster={item?.media[0]?.thumb}
                            onClick={(e) => {
                              e.stopPropagation();
                              toggleMute();
                            }}
                            // onDoubleClick={() => navigate(`/post/${item?._id}`)}
                          >
                            <source
                              src={item?.media[0]?.name}
                              type="video/mp4"
                            />
                          </video>
                          <img
                            className={`reels_play_btn position-absolute absolute cursor-pointer ${
                              isVisible ? "" : "hide_mute_icon"
                            }`}
                            src={
                              muted
                                ? process.env.PUBLIC_URL +
                                  "/assets/video/icons/volume_off.svg"
                                : process.env.PUBLIC_URL +
                                  "/assets/video/icons/volume.svg"
                            }
                            alt=""
                          />
                        </>
                      ) : (
                        <img
                          src={item?.media?.[0]?.name}
                          className="re_tweet_img h-auto "
                        />
                      ))}
                  </p>
                  <p className="text-break">
                    <b className="me-2">
                      <Link
                        to={"/profile"}
                        state={item?.user_id}
                        className="text-dark"
                      >
                        {item?.username}
                      </Link>
                    </b>
                    <RenderTextWithHashtags
                      text={item?.description || ""}
                      showInDetails={isDetailPage}
                    />
                  </p>
                  <div className="inner_post_retweet">
                    <div className="top_hader">
                      <PostUserDetails
                        setPostPermission={setPostPermission}
                        setSinglePost={setSinglePost}
                        item={item?.shared_feed}
                        disableMenu={true}
                      />
                      <div
                        className="content_base post_cpntent"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/post/${item?.shared_feed?._id}`);
                        }}
                      >
                        <div className="reposed_img_video">
                          <img
                            src={item?.shared_feed?.media?.[0]?.thumb}
                            alt=""
                          />
                          <a href="#">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/play-circle-fill.svg"
                              }
                              alt={
                                process.env.PUBLIC_URL +
                                "/assets/images/play-circle-fill.svg"
                              }
                            />
                          </a>
                        </div>
                        <p className="text-break">
                          <RenderTextWithHashtags
                            text={item?.description || ""}
                            showInDetails={isDetailPage}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <PostLikeComment
                  retweet={retweet}
                  undoRetweet={undoRetweet}
                  singlePost={singlePost}
                  setSinglePost={setSinglePost}
                  likeUnlike={likeUnlike}
                  handleShare={handleShare}
                  handleSavePost={handleSavePost}
                  item={item}
                  isDetailPage={isDetailPage}
                />
              </div>
            </div>
          </div>
        ) : (
          <div className="col-12 mb-3" key={item?._id}>
            {isRetweet && (
              <div className="repost_gray">
                <div className="repost_gray_iamge">
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/post-repost-white.svg"
                    }
                    className="img-fluid"
                    alt="repost"
                  />
                </div>
                <p className="mb-0 gray_5a font_12 font_500">
                  {" "}
                  {item?.name === localStorage?.getItem("name")
                    ? "You"
                    : item?.name}{" "}
                  {item?.retweet_count > 1 ? "and others" : ""}{" "}
                  <span className="blue_29">Viral</span> this
                </p>
              </div>
            )}
            <div className="home_post_main content_orient repost_post">
              <div className="top_hader">
                <PostUserDetails
                  setPostPermission={setPostPermission}
                  setSinglePost={setSinglePost}
                  item={item}
                />
                <div
                  className="content_base post_cpntent"
                  onClick={(e) => {
                    e.stopPropagation();
                    navigate(`/post/${item?._id}`);
                  }}
                >
                  {item?.media?.length > 0 && (
                    <img
                      src={item?.media?.[0]?.name}
                      className="re_tweet_img"
                    />
                  )}
                  <p className="text-break">
                    <b className="me-2">
                      <Link
                        to={"/profile"}
                        state={item?.user_id}
                        className="text-dark me-2"
                      >
                        {item?.name}
                      </Link>
                    </b>
                    <RenderTextWithHashtags
                      text={item?.description || ""}
                      showInDetails={isDetailPage}
                    />
                  </p>
                  <div className="inner_post_retweet">
                    <div className="top_hader">
                      <PostUserDetails
                        setPostPermission={setPostPermission}
                        setSinglePost={setSinglePost}
                        item={item?.shared_feed}
                        disableMenu={true}
                      />
                      <div
                        className="content_base post_cpntent"
                        onClick={(e) => {
                          e.stopPropagation();
                          navigate(`/post/${item?.shared_feed?._id}`);
                        }}
                      >
                        <div className="reposed_img_video">
                          <img
                            src={item?.shared_feed?.media?.[0]?.name}
                            alt=""
                          />
                          {/* <a href="#">
                          <img src="assets/images/play-circle-fill.svg" />
                        </a> */}
                        </div>
                        <p className="text-break">
                          <RenderTextWithHashtags
                            text={item?.description || ""}
                            showInDetails={isDetailPage}
                          />
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
                <PostLikeComment
                  retweet={retweet}
                  undoRetweet={undoRetweet}
                  singlePost={singlePost}
                  setSinglePost={setSinglePost}
                  likeUnlike={likeUnlike}
                  handleShare={handleShare}
                  handleSavePost={handleSavePost}
                  item={item}
                  isDetailPage={isDetailPage}
                />
              </div>
            </div>
          </div>
        )
      ) : (
        <div className="col-12 mb-3" key={item?._id}>
          {isRetweet && (
            <div className="repost_gray">
              <div className="repost_gray_iamge">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/post-repost-white.svg"
                  }
                  className="img-fluid"
                  alt="repost"
                />
              </div>
              <p className="mb-0 gray_5a font_12 font_500">
                {" "}
                {item?.name === localStorage?.getItem("name")
                  ? "You"
                  : item?.name}{" "}
                {item?.retweet_count > 1 ? "and others" : ""}{" "}
                <span className="blue_29">Viral</span> this
              </p>
            </div>
          )}
          <div className="home_post_main content_orient repost_post">
            <div className="top_hader">
              <PostUserDetails
                setPostPermission={setPostPermission}
                setSinglePost={setSinglePost}
                item={item}
              />
              <div className="content_base post_cpntent">
                <image
                  src={item?.shared_feed?.media[0].name}
                  className="re_tweet_img"
                />
                <p
                  className="w-100 text-break"
                  onClick={() => {
                    navigate(`/post/${item?._id}`);
                  }}
                >
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
                <div className="inner_post_retweet">
                  <div className="top_hader">
                    <PostUserDetails
                      setPostPermission={setPostPermission}
                      setSinglePost={setSinglePost}
                      item={item?.shared_feed}
                      disableMenu={true}
                    />
                    <div
                      className="content_base post_cpntent"
                      onClick={(e) => {
                        e.stopPropagation();
                        navigate(`/post/${item?.shared_feed?._id}`);
                      }}
                    >
                      <div className="reposed_img_video">
                        <img src={item?.shared_feed?.media?.[0]?.name} alt="" />
                        {/* <a href="#">
                          <img src="assets/images/play-circle-fill.svg" />
                        </a> */}
                      </div>
                      <p className="text-break">
                        <RenderTextWithHashtags
                          text={item?.description || ""}
                          showInDetails={isDetailPage}
                        />
                      </p>
                    </div>
                  </div>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleShare={handleShare}
                handleSavePost={handleSavePost}
                item={item}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      )}
    </>
  );
};

export default RetweetWithQuote;
