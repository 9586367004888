import React, { useEffect, useState } from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { CometChat } from "@cometchat/chat-sdk-javascript";
// import { toast } from "react-toastify";
import { share_post } from "../../../../api/apiHandler";

const ShareLink = ({ shareModalOpen, setShareModalOpen }) => {
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  // const loggedInUser = localStorage.getItem("_id");
  const [usersList, setUsersList] = useState([]);
  console.log("usersList:=> ", usersList);
  const [userSearch, setUserSearch] = useState("");
  const customStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : 400, // Adjust the width for small screens
    maxWidth: "100%",
    bgcolor: "#ffffff",
    boxShadow: 24,
    borderRadius: "10px",
    // p: 2,
  };

  const sendMessage = (uid) => {
    let receiverID = uid;
    let messageText = shareModalOpen.share_link;
    let receiverType = CometChat.RECEIVER_TYPE.USER;
    let textMessage = new CometChat.TextMessage(
      receiverID,
      messageText,
      receiverType
    );

    CometChat.sendMessage(textMessage).then(
      (message) => {
        // console.log("Message sent successfully:", message);
        setUsersList(
          usersList?.map((val) => {
            if (uid == val.uid) {
              val.is_send = true;
            }
            return val;
          })
        );
        share_post({ id: shareModalOpen.id }).then((res) => {
          // setShareModalOpen({ open: false, share_link: "", id: "" });
        });
      },
      (error) => {
        console.log("Message sending failed with error:", error);
      }
    );
  };

  const handleCopyClick = async () => {
    try {
      await navigator.clipboard.writeText(shareModalOpen.share_link);
      console.log("Text copied to clipboard:", shareModalOpen.share_link);
      share_post({ id: shareModalOpen.id }).then((res) => {
        setShareModalOpen({ open: false, share_link: "", id: "" });
      });
    } catch (err) {
      console.error("Unable to copy text to clipboard:", err);
    }
  };

  useEffect(() => {
    let limit = 100;
    //   let searchKeyword = "super";
    let usersRequest;

    if (userSearch.length) {
      usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .setSearchKeyword(userSearch)
        .build();
    } else {
      usersRequest = new CometChat.UsersRequestBuilder()
        .setLimit(limit)
        .build();
    }

    usersRequest.fetchNext().then(
      (userList) => {
        setUsersList(
          userList?.map((item) => {
            return { ...item, is_send: false };
          })
        );
      },
      (error) => {
        console.log("User list fetching failed with error:", error);
      }
    );
  }, [userSearch]);

  return (
    <Modal
      open={shareModalOpen.open}
      onClose={() => {
        setShareModalOpen({ open: false, share_link: "", id: "" });
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
      key={shareModalOpen?.id}
    >
      <Box sx={customStyles}>
        <ul className="p-0 m-0">
          <>
            <li
              className="text-center text-secondary"
              style={{
                padding: "13px 10px",
                fontWeight: 500,
                cursor: "pointer",
                fontSize: "18px",
              }}
              onClick={() => {
                handleCopyClick();
              }}
            >
              Copy Link
            </li>
            <li className="d-flex align-items-center justify-content-between px-3">
              <hr style={{ width: "40%", borderTop: "2px solid black", margin:"10px 0" }}></hr>
              <p>or</p>
              <hr style={{ width: "40%", borderTop: "2px solid black" , margin:"10px 0"}}></hr>
            </li>
            <li
              className="border-bottom  border-secondary text-center text-secondary"
              style={{ padding: "0 13px 10px", fontWeight: 500 }}
            >
              {/* <input
                className="form-control px-1 mb-2"
                value={userSearch}
                onChange={(e) => {
                  setUserSearch(e.target.value);
                }}
              ></input> */}
              <div class="form-group has-search">
                <span class="fa fa-search form-control-feedback"></span>
                <input
                  type="text"
                  class="form-control pe-5 text-overflow-ellipsis"
                  placeholder="Search"
                  value={userSearch}
                  onChange={(e) => {
                    setUserSearch(e.target.value);
                  }}
                />
              </div>
              <div
                style={{ height: "250px", width: "100%", overflowY: "auto" }}
              >
                <div class="people-nearby">
                  {/* <div class="nearby-user"> */}
                  {usersList.length > 0 &&
                    usersList.map((item, index) => {
                      return (
                        <>
                          <div
                            class="row w-100 m-0 border-bottom border-bg-secondary d-flex align-items-center py-1"
                            key={item?.uid + "_" + index}
                          >
                            <div class="col-md-3 col-sm-3 col-3 pe-0">
                              <img
                                src={item.avatar}
                                alt="user"
                                class="profile-photo-lg img-fluid rounded-circle "
                                style={{
                                  width: "50px",
                                  height: "50px",
                                  objectFit: "cover",
                                }}
                              />
                            </div>
                            <div class="col-md-6 col-sm-6 col-6 px-0">
                              <p>
                                {/* <a href="#" class="profile-link"> */}
                                {item.name}
                                {/* </a> */}
                              </p>
                            </div>
                            <div class="col-md-3 col-sm-3 col-3">
                              <div className="sania_btn">
                                {item?.is_send ? (
                                  <button
                                    type="button"
                                    className="blue_button d-flex align-items-center font_12 font_500 bg-transparent"
                                    style={{ color: "#29ABE2" }}
                                  >
                                    {/* <span className="icon-link font_14 me-2 font_12"></span> */}
                                    Sent
                                  </button>
                                ) : (
                                  <button
                                    type="button"
                                    className="blue_button d-flex align-items-center font_12 font_500"
                                    onClick={() => {
                                      sendMessage(item.uid);
                                    }}
                                  >
                                    {/* <span className="icon-link font_14 me-2 font_12"></span> */}
                                    Send
                                  </button>
                                )}
                              </div>
                            </div>
                          </div>
                        </>
                      );
                    })}
                  {/* </div> */}
                </div>
              </div>
            </li>
          </>
        </ul>
      </Box>
    </Modal>
  );
};

export default ShareLink;
