import React, { useCallback, useEffect, useState } from "react";
import { Helmet, HelmetProvider } from "react-helmet-async";
// import SignUp from '../Signupforms/SignUp';
// import Username from '../Signupforms/Username';
// import Collegestream from '../Signupforms/Collegestream';
// import Friends from '../Signupforms/Friends';
// import Linkhashtag from '../Signupforms/Linkhashtag';
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { NotificationManager, NotificationContainer } from "react-notifications";
import "react-notifications/lib/notifications.css";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { check_unique, login, send_otp } from "../../api/apiHandler";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import HeaderProfile from "../HeaderProfile";
// import { GoogleLogin } from "react-google-login";
// import FacebookLogin from "react-facebook-login";
// import SocialButton from "../SocialButton";
import SocialLogin from "../Signupforms/SocialLoginComponent";
import { bodyEncryption } from "../../api/apiClient";
// import { showMessage } from '../../common/RedirectPathManage';
// import { bodyDecryption } from '../../api/apiClient';

export default function Signup() {
  const location = useLocation();
  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const mobileNumberPattern = "^\\d{10}$";
  const passwordPattern =
    "^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,}$";
  const emailPattern = "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}";
  const validationSchema = yup.object().shape({
    email: yup
      .string()
      .required("Please enter email address")
      .email("Please enter valid email")
      .matches(emailPattern, "Email is invalid"),
    password:
      !location?.state?.provider &&
      yup
        .string()
        .required("Please enter password")
        .matches(
          passwordPattern,
          "Password must contain at least eight characters, one capital letter, one small letter, one number and one special character"
        )
        .min(8, "Password must contain at leat 8 characters"),
    mobileNumber:
      !location?.state?.provider &&
      yup
        .string()
        .required("Please enter mobile number")
        .matches(mobileNumberPattern, "Mobile number is invalid"),
    confirmPassword: yup.string().when("password", {
      is: (password) => !!password,
      then: () =>
        yup
          .string()
          .test(
            "is-same",
            "Your confirm password doesn't match your actual password",
            function (closeTime) {
              const { password } = this.parent;
              console.log("closeTime: ", closeTime);
              console.log("ppppp", password, closeTime);
              if (password && closeTime) {
                // const openMoment = moment(password);
                // const closeMoment = moment(closeTime);

                // const differenceInHours = closeMoment.diff(
                //   openMoment,
                //   "hours"
                // );
                return password == closeTime;
              }

              return true; // If either open_time or close_time is not provided, skip the validation.
            }
          )
          .required("Please enter confirm password"),
      // .oneOf(
      //   [yup.ref("password"), null],
      //   "Your confirm password doesn't match your actual password"
      // )
    }),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  console.log("errors: ", Object.keys(errors).length);
  const unique_id = uuid();
  const small_id = unique_id.slice(0, 8);
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const currentTime = new Date();

  // var [step, setStep] = useState(0);
  // var step = 0;
  // const [goNext, setGoNext] = useState(false);
  const navigate = useNavigate();
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  const toggleConfirmPasswordVisibility = () => {
    setShowConfirmPassword(!showConfirmPassword);
  };
  // const responseFacebook = (response) => {
  //   if (response.id) {
  //     const userData = {
  //       name: response.name,
  //       email: response.email,
  //       // Add any other relevant user data
  //     };

  //     // Call your signup API endpoint with the userData
  //     // Example: fetch('your_signup_api_url', { method: 'POST', body: JSON.stringify(userData) })
  //     // .then(response => response.json())
  //     // .then(data => console.log(data))
  //     // .catch(error => console.error('Error:', error));
  //   }
  // };

  // const responseGoogle = (response) => {
  //   if (response.profileObj) {
  //     console.log(response, "Google");
  //     const userData = {
  //       name: response.profileObj.name,
  //       email: response.profileObj.email,
  //       // Add any other relevant user data
  //     };

  //     // Call your signup API endpoint with the userData
  //     // Example: fetch('your_signup_api_url', { method: 'POST', body: JSON.stringify(userData) })
  //     // .then(response => response.json())
  //     // .then(data => console.log(data))
  //     // .catch(error => console.error('Error:', error));
  //   }
  // };

  // const handleSocialLogin = (user) => {
  //   console.log(user);
  // };

  // const handleSocialLoginFailure = (err) => {
  //   console.error(err);
  // };

  console.log("location.state", localStorage.getItem("email"), location?.state);

  const onSubmit = (data) => {
    const newData = {
      mobileNumber: data.mobileNumber,
      email: data.email,
      password: data.password,
      confirmPassword: data.confirmPassword,
      login_type: "S",
      device_type: "W",
      device_token: small_id,
    };

    // Check Unique API Call

    const payload = {
      email: data.email,
      login_type:
        location?.state?.provider === "google"
          ? "G"
          : location?.state?.provider === "facebook"
          ? "f"
          : "S",
    };

    if (location?.state?.provider) {
      payload.social_id =
        location?.state?.provider === "facebook"
          ? location?.state?.userID
          : location?.state?.sub;
    } else {
      payload.phone_number = data.mobileNumber;
    }

    check_unique(payload).then((response) => {
      console.log("line 63", response);
      if (response?.code === "0" || response?.code === 0) {
        // NotificationManager.error(response.message);

        toast.error(response?.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          toastId: "existing-user",
        });
        return false;
      } else if (response.code === "1") {
        localStorage.setItem("email", data.email);

        // Send OTP API call
        if (location?.state?.provider) {
          localStorage.setItem("step", 2);
          navigate("/username", {
            state: location.state,
          });
        } else {
          localStorage.setItem("phone_number", data.mobileNumber);
          localStorage.setItem("password", data.password);
          localStorage.setItem("step", 1);
          send_otp({
            phone_number: data.mobileNumber,
          }).then((response) => {
            console.log("Line 108", response);
            if (response.code === 1) {
              // NotificationManager.success(response.message);
              toast.success(response.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
              });
              localStorage.setItem("phone_number", newData.mobileNumber);
              localStorage.setItem("time", currentTime.toString());
              setTimeout(() => {
                navigate("/otp-verification", {
                  state: { otp: response?.data?.otp },
                });
              }, 1000);
            }
          });
        }
      }
    });
  };

  const handleLoginSuccess = useCallback(
    (provider, data) => {
      console.log("data: ", data);
      console.log("provider:====> ", provider, data);
      setProvider(provider);
      setProfile(data);
      const payload = {
        email: data.email,
        login_type:
          provider === "google" ? "G" : provider === "facebook" ? "f" : "S",
      };

      if (provider) {
        payload.social_id =
          data?.provider === "facebook" ? data?.userID : data?.sub;
      } else {
        payload.phone_number = data.mobileNumber;
      }

      check_unique(payload).then((response) => {
        console.log("provider response: ", response);
        if (response.code == 0) {
          const newData = {
            social_id: provider === "facebook" ? data.userID : data.sub,
            login_type: provider === "facebook" ? "F" : "G",
            device_type: "W",
            device_token: "0",
            latitude: 23.033863,
            longitude: 72.585022,
            location_name: "Ahmedabad",
          };

          login(newData).then((response) => {
            console.log("Line 38", response);

            if (response.code === "1") {
              toast.success(response.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: "login",
              });
              localStorage.setItem(
                "token",
                bodyEncryption(response.data.token)
              );
              localStorage.setItem("username", response.data.username);
              localStorage.setItem("isLoggedIn", true);
              localStorage.setItem("name", response.data.name);
              localStorage.setItem("account_type", response.data.account_type);
              localStorage.setItem("status", response.data.status);
              localStorage.setItem(
                "profile_image",
                response?.data?.profile_image
              );
              localStorage.setItem("email", response?.data?.email);
              localStorage.setItem("_id", response?.data?._id);
              window.location.href = "/";
            } else if (response.code == "11") {
              navigate("/sign-up", { state: { provider: provider, ...data } });
            } else if (response.code === 0) {
              toast.error(response.message, {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: "login-error",
              });
            }
          });
        } else {
          navigate("/sign-up", {
            state: { provider: provider, ...data },
          });
          window.location.reload(true);
        }
      });
    },
    [navigate]
  );

  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="main_signup">
        {/* <NotificationContainer /> */}
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-12">
              <h1 className="font_30 font_600 text-center signup_heading_border_blue">
                Sign Up Your <span className="blue_29">Account</span>
              </h1>
              <p className="inter font_18 text-center">
                <q>
                  Welcome to our platform! Please fill out the form below to
                  create your account.
                </q>
              </p>
            </div>
          </div>
          <div className="form__container">
            <div className="row">
              <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                <div className="left__container">
                  <div className="form_vector">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "assets/images/icons/vector-form.svg"
                      }
                      className="img-fluid"
                      alt="vector"
                    />
                  </div>
                  <h6 className="font_26 black_00 font_600">Step 1</h6>
                  <p className="gray_5a font_16 font_500">
                    Enter your personal number Details.
                  </p>
                  <div className="steps d-md-flex">
                    <div className="progress__bar__container">
                      <ul className="ps-0">
                        <li className="active" id="icon1">
                          <span>1</span>
                        </li>
                        <li id="icon2">
                          <span>2</span>
                        </li>
                        <li id="icon3">
                          <span>3</span>
                        </li>
                        <li id="icon4">
                          <span>4</span>
                        </li>
                        <li id="icon5">
                          <span>5</span>
                        </li>
                        <li id="icon6">
                          <span>6</span>
                        </li>
                      </ul>
                    </div>
                    <div className="side__titles">
                      <div className="title__name">
                        <p className="font_16 font_500 active">Basic Details</p>
                      </div>
                      <div className="title__name">
                        <p className="font_16 font_500">OTP Verification</p>
                      </div>
                      <div className="title__name">
                        <p className="font_16 font_500">Select UserName</p>
                      </div>
                      <div className="title__name">
                        <p className="font_16 font_500">Select College</p>
                      </div>
                      <div className="title__name">
                        <p className="font_16 font_500">Find Friends</p>
                      </div>
                      <div className="title__name">
                        <p className="font_16 font_500 mb-0">Link Hashtags</p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
              <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 position-relative">
                <div className="right_form_1 right__container">
                  <div id="form1">
                    <div className="form_heading">
                      <h4 className="font_25 font_600 blue_29">
                        Enter your personal Information
                      </h4>
                      <p className="mb-0">
                        <q>
                          Welcome to our platform! Please fill out the form
                          below to create your account.
                        </q>
                      </p>
                    </div>
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row justify-content-center w-100 my-auto mt-0">
                        <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                          <div className="step_1_form">
                            {!location?.state?.provider && (
                              <div className="mobile_number_input">
                                <h6 className="font_18 font_500">
                                  Enter Mobile Number
                                </h6>{" "}
                                <span style={{ color: "red" }}>
                                  {errors.mobileNumber?.message}
                                </span>
                                <div className="input-group">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/smartphone.svg"
                                      }
                                      className="img-fluid"
                                      alt="phone"
                                    />
                                  </span>
                                  <input
                                    type="text"
                                    className="form-control"
                                    required=""
                                    maxLength={10}
                                    autoComplete="off"
                                    defaultValue={
                                      localStorage.getItem("phone_number")
                                        ? localStorage.getItem("phone_number")
                                        : ""
                                    }
                                    {...register("mobileNumber")}
                                  />
                                  <label htmlFor="name">Mobile Number</label>
                                </div>
                              </div>
                            )}

                            <div className="mobile_number_input">
                              <h6 className="font_18 font_500">Enter E-MAIL</h6>{" "}
                              <span style={{ color: "red" }}>
                                {errors.email?.message}
                              </span>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/images/icons/mail.svg"
                                    }
                                    className="img-fluid"
                                    alt="phone"
                                  />
                                </span>
                                <input
                                  type="text"
                                  className="form-control"
                                  required=""
                                  // autoComplete="off"
                                  defaultValue={
                                    location?.state?.email
                                      ? location?.state?.email
                                      : localStorage.getItem("email")
                                      ? localStorage.getItem("email")
                                      : // : location?.state?.email
                                        // ? location?.state?.email
                                        ""
                                  }
                                  readOnly={location?.state?.email}
                                  {...register("email", {
                                    disabled: location?.state?.email,

                                    value: localStorage.getItem("email")
                                      ? localStorage.getItem("email")
                                      : location?.state?.email
                                      ? location?.state?.email
                                      : "",
                                  })}
                                />
                                <label htmlFor="email">E-Mail</label>
                              </div>
                            </div>
                            {!location?.state?.provider && (
                              <div className="mobile_number_input">
                                <h6 className="font_18 font_500">
                                  Enter password
                                </h6>{" "}
                                <span style={{ color: "red" }}>
                                  {errors.password?.message}
                                </span>
                                <div className="input-group">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/user-check.svg"
                                      }
                                      className="img-fluid"
                                      alt="phone"
                                    />
                                  </span>
                                  <input
                                    type={showPassword ? "text" : "password"}
                                    className="form-control"
                                    required=""
                                    autoComplete="off"
                                    {...register("password")}
                                  />
                                  <label htmlFor="name">Password</label>
                                  <span
                                    className="input-group-text eye-icon"
                                    onClick={togglePasswordVisibility}
                                  >
                                    {showPassword ? (
                                      <i className="fas fa-eye-slash"></i>
                                    ) : (
                                      <i className="fas fa-eye"></i>
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            {!location?.state?.provider && (
                              <div className="mobile_number_input">
                                <h6 className="font_18 font_500">
                                  Enter Confirm password
                                </h6>{" "}
                                <span style={{ color: "red" }}>
                                  {errors.confirmPassword?.message}
                                </span>
                                <div className="input-group">
                                  <span
                                    className="input-group-text"
                                    id="basic-addon1"
                                  >
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "assets/images/icons/user-check.svg"
                                      }
                                      className="img-fluid"
                                      alt="phone"
                                    />
                                  </span>
                                  <input
                                    type={
                                      showConfirmPassword ? "text" : "password"
                                    }
                                    className="form-control"
                                    required=""
                                    autoComplete="off"
                                    {...register("confirmPassword")}
                                  />
                                  <label htmlFor="name">Confirm password</label>
                                  <span
                                    className="input-group-text eye-icon"
                                    onClick={toggleConfirmPasswordVisibility}
                                  >
                                    {showConfirmPassword ? (
                                      <i className="fas fa-eye-slash"></i>
                                    ) : (
                                      <i className="fas fa-eye"></i>
                                    )}
                                  </span>
                                </div>
                              </div>
                            )}
                            <div className="buttons_form_1 buttons justify-content-end ">
                              {/* <button className="font_15 prev__btn d-flex align-items-center disabled"
                                                             disabled={step === 0}><img
                                                                src={process.env.PUBLIC_URL+"assets/images/icons/prev.svg"} className="img-fluid"
                                                                alt="icon" />Previous</button> */}
                              <button
                                type="submit"
                                className="font_15 nxt__btn blue_button d-flex align-items-center"
                              >
                                Next{" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/icons/next.svg"
                                  }
                                  className="img-fluid"
                                  alt="icon"
                                />{" "}
                              </button>
                            </div>
                            {!location?.state?.provider && (
                              <p className="text-center inter font_15 font_500 gray_5a">
                                OR
                              </p>
                            )}
                          </div>
                          {!location?.state?.provider && (
                            <SocialLogin
                              provider={provider}
                              profile={profile}
                              onSuccess={handleLoginSuccess}
                            />
                          )}

                          {/* <div className="row">
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                              <Link to="/" className="login_with inter font_17">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/icons/Google-Icon.svg"
                                  }
                                  className="me-2"
                                  alt="google"
                                />{" "}
                                Sign in with Google
                              </Link>
                            </div>
                            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-6">
                              <Link to="/" className="login_with inter font_17">
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "assets/images/icons/Facebook-Icon.svg"
                                  }
                                  className="me-2"
                                  alt="facebook"
                                />{" "}
                                Sign in with Facebook
                              </Link>
                            </div>
                          </div> */}
                        </div>
                      </div>
                    </form>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
