import React, { useCallback, useEffect, useState } from "react";
import { Link, useNavigate, Navigate } from "react-router-dom";
import { login } from "../../api/apiHandler";
import { bodyEncryption } from "../../api/apiClient";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
// import { NotificationManager, NotificationContainer } from "react-notifications";
// import 'react-notifications/lib/notifications.css';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Header from "../Header";
import HeaderProfile from "../HeaderProfile";
import SocialLoginComponent from "./SocialLoginComponent";
// import SocialButton from "../SocialButton";

// import { useHistory } from "react-router-dom";

export default function Login() {
  window.scrollTo(0, 0);
  const navigate = useNavigate();
  const [showPassword, setShowPassword] = useState(false);
  // const history = useHistory();
  // const [loginData, setLoginData] = useState({username: "", password: ""});
  const validationSchema = yup.object().shape({
    username: yup
      .string()
      .required("Please enter Username")
      .matches("^[a-z0-9_.]*$", "Please enter valid username"),
    password: yup.string().required("Please enter password"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  const [provider, setProvider] = useState("");
  const [profile, setProfile] = useState(null);
  const togglePasswordVisibility = () => {
    setShowPassword(!showPassword);
  };
  // const handleSocialLogin = (user) => {
  //   console.log(user);
  // };

  // const handleSocialLoginFailure = (err) => {
  //   console.error("err",err);
  // };

  const onSubmit = (data) => {
    const newData = {
      username: data.username,
      password: data.password,
      login_type: "S",
      device_type: "W",
      device_token: "0",
      latitude: 23.033863,
      longitude: 72.585022,
      location_name: "Ahmedabad",
    };

    login(newData).then((response) => {
      console.log("Line 38", response);
      if (response.code === "1") {
        toast.success(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          toastId: "login",
        });
        localStorage.setItem("token", bodyEncryption(response.data.token));
        localStorage.setItem("username", response.data.username);
        localStorage.setItem("isLoggedIn", true);
        localStorage.setItem("name", response.data.name);
        localStorage.setItem("account_type", response.data.account_type);
        localStorage.setItem("status", response.data.status);
        localStorage.setItem("profile_image", response?.data?.profile_image);
        localStorage.setItem("email", response?.data?.email);
        localStorage.setItem("_id", response?.data?._id);
        window.location.href = "/";
      } else if (response.code === 0) {
        toast.error(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          toastId: "login-error",
        });
      } else {
        toast.error(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          toastId: "login-error",
        });

      }
    });
  };

  const handleLoginSuccess = useCallback(
    (provider, data) => {
      setProvider(provider);
      setProfile(data);

      const newData = {
        social_id: provider === "facebook" ? data.userID : data.sub,
        login_type: provider === "facebook" ? "F" : "G",
        device_type: "W",
        device_token: "0",
        latitude: 23.033863,
        longitude: 72.585022,
        location_name: "Ahmedabad",
      };

      login(newData).then((response) => {
        console.log("Line 38", response);

        if (response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "login",
          });
          localStorage.setItem("token", bodyEncryption(response.data.token));
          localStorage.setItem("username", response.data.username);
          localStorage.setItem("isLoggedIn", true);
          localStorage.setItem("name", response.data.name);
          localStorage.setItem("account_type", response.data.account_type);
          localStorage.setItem("status", response.data.status);
          localStorage.setItem("profile_image", response?.data?.profile_image);
          localStorage.setItem("email", response?.data?.email);
          localStorage.setItem("_id", response?.data?._id);
          window.location.href = "/";
        } else if (response.code == "11") {
          navigate("/sign-up", { state: { provider: provider, ...data } });
        } else if (response.code === 0) {
          toast.error(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "login-error",
          });
        }
      });
    },
    [navigate]
  );

  console.log("provider: ", provider);
  console.log("profile: ", profile);

  useEffect(() => {
    if (
      localStorage.getItem("isLoggedIn") === true ||
      localStorage.getItem("isSignedup") === true
    ) {
      navigate("/");
    }
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <>
      {(localStorage.getItem("isLoggedIn") ||
        localStorage.getItem("isSignedUp")) &&
      localStorage.getItem("step", 3) ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}

      <section className="main_signup">
        <div className="container">
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar={false}
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div className="row">
            <div className="col-md-12">
              <h1 className="already-head font_30 font_600 text-center">
                Already Registerd Login Your Account
              </h1>
              <p className="font_20 text-center">
                Are You New?{" "}
                <Link to="/sign-up" className="blue_29 font_700">
                  <u>Sign Up Here</u>
                </Link>
              </p>
              <div className="row justify-content-center mt-4 mt-sm-5 ">
                <div className="col-12 col-xl-8 col-lg-8 col-md-10 col-sm-12">
                  <div className="login_form">
                    <form onSubmit={handleSubmit(onSubmit)}>
                      <div className="row justify-content-center">
                        <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                          <div className="step_1_form">
                            <div className="mobile_number_input">
                              <h6 className="font_18 font_500">
                                Enter Your Username
                              </h6>{" "}
                              <span style={{ color: "red" }}>
                                {errors.username?.message}
                              </span>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/images/icons/mail.svg"
                                    }
                                    className="img-fluid"
                                    alt="phone"
                                  />
                                </span>
                                {/* {console.log("localStorage.getItem('username')", localStorage.getItem('username'))} */}
                                <input
                                  type="text"
                                  id="username"
                                  className="form-control"
                                  required=""
                                  // defaultValue={localStorage.getItem('username') !== undefined ? localStorage.getItem('username') : loginData.username}
                                  autoComplete="off"
                                  // onChange={(e)=>{
                                  //     setLoginData({...loginData, username: e.target.value})
                                  // }}
                                  // value={loginData.username}
                                  {...register("username")}
                                />
                                <label htmlFor="name">Username</label>
                              </div>
                            </div>
                            <div className="mobile_number_input">
                              <h6 className="font_18 font_500">
                                Enter password
                              </h6>{" "}
                              <span style={{ color: "red" }}>
                                {errors.password?.message}
                              </span>
                              <div className="input-group">
                                <span
                                  className="input-group-text"
                                  id="basic-addon1"
                                >
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "assets/images/icons/user-check.svg"
                                    }
                                    className="img-fluid"
                                    alt="phone"
                                  />
                                </span>
                                <input
                                  type={showPassword ? "text" : "password"}
                                  id="password"
                                  className="form-control"
                                  required=""
                                  // defaultValue={localStorage.getItem('password') !== undefined ? localStorage.getItem('password') : loginData.password}
                                  autoComplete="off"
                                  // onChange={(e)=>{
                                  //     setLoginData({...loginData, password: e.target.value})
                                  // }}
                                  // value = {loginData.password}
                                  {...register("password")}
                                />
                                <label htmlFor="name">Password</label>
                                <span
                                  className="input-group-text eye-icon"
                                  onClick={togglePasswordVisibility}
                                >
                                  {showPassword ? (
                                    <i className="fas fa-eye-slash"></i>
                                  ) : (
                                    <i className="fas fa-eye"></i>
                                  )}
                                </span>
                              </div>
                            </div>
                            <div className="d-flex justify-content-between align-items-center">
                              {/* {localStorage.getItem('username') !== undefined && localStorage.getItem('password') !== undefined ?
                                                                <div className="form-check">
                                                                    <input className="form-check-input" type="checkbox"
                                                                        id="flexCheckDefault"
                                                                        onClick={() => rememberMeFunction()}
                                                                        defaultChecked="checked"
                                                                    />
                                                                    <label className="form-check-label gray_5a"
                                                                        htmlFor="flexCheckDefault">
                                                                        Remember me
                                                                    </label>
                                                                </div> 
                                                            :
                                                                <div className="form-check">
                                                                <input className="form-check-input" type="checkbox"
                                                                    id="flexCheckDefault"
                                                                    onClick={() => rememberMeFunction()}
                                                                />
                                                                <label className="form-check-label gray_5a"
                                                                    htmlFor="flexCheckDefault">
                                                                    Remember me
                                                                </label>
                                                            </div>
                                                            } */}
                              <Link
                                to="/forgot-password"
                                className="inter gray_5a"
                                style={{ marginLeft: "80%" }}
                              >
                                Forgot Password?
                              </Link>
                            </div>
                            <div className="row justify-content-center">
                              <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-10">
                                <button
                                  type="submit" /*onClick={notify}*/
                                  className="signup_blue_btn font_22 font_600"
                                >
                                  LOGIN
                                </button>
                                <p className="text-center inter font_15 font_500 gray_5a">
                                  OR
                                </p>
                              </div>
                            </div>
                            <SocialLoginComponent
                              provider={provider}
                              profile={profile}
                              onSuccess={handleLoginSuccess}
                            />
                          </div>
                        </div>
                      </div>
                    </form>
                    {/* <div>
                      <SocialButton
                        provider="google"
                        appId="556639325530-dhqlsa05s19nt5o3hflmoiq0qd7ea0ut.apps.googleusercontent.com"
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        // getInstance={this.setNodeRef.bind(this, "google")}
                        key={"google"}
                        // scope={
                        //   "https://www.googleapis.com/auth/user.gender.read"
                        // }
                        style={{
                          
                          backgroundColor: "#4285f4" ,
                          color: "#fff" ,
                          padding: "10px 20px",
                          border: "none" ,
                          
                        }}
                      >
                        Login with Google
                      </SocialButton>
                    </div>
                    <div>
                      <SocialButton
                        provider="facebook"
                        appId={783571483137799}
                        onLoginSuccess={handleSocialLogin}
                        onLoginFailure={handleSocialLoginFailure}
                        style={{
                          backgroundColor: "#3b5998" ,
                          color: "#fff" ,
                          padding: "10px 20px" ,
                          border: "none" ,
                          
                        }}
                      >
                        Login with Facebook
                      </SocialButton>
                    </div> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
