import React, { useCallback, useEffect, useState } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import {
  add_comments,
  add_post,
  delete_comment,
  delete_post,
  get_comment_list,
  get_reel_comment_list,
  give_comments_like_unlike,
  give_like_unlike,
  post_details,
  save_post,
  share_link,
  undo_post_retweet,
} from "../../api/apiHandler";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import S3 from "react-aws-s3";
import ReactTimeAgo from "react-time-ago";
import EmojiPicker from "emoji-picker-react";
import { saveAs } from "file-saver";
import InfiniteScroll from "react-infinite-scroll-component";
import PostMenu from "../pages/HomeFeed/components/postMenu";
import NormalPostType from "../pages/HomeFeed/module/NormalPostType";
import RetweetPostType from "../pages/HomeFeed/module/RetweetPostType";
import RetweetWithQuote from "../pages/HomeFeed/module/RetweetWithQuote";
import ReelsPostType from "../pages/HomeFeed/module/ReelsPostType";
import Comments from "./components/Comments";
import ShareLink from "../pages/HomeFeed/components/ShareLink";
import _ from "lodash";
import { getTimeDifference } from "../pages/HomeFeed/components/PostUserDetails";
import ReelComments from "./components/ReelComments";

const customStyles = {
  position: "absolute",
  top: "50%",
  left: "50%",
  transform: "translate(-50%, -50%)",
  width: 700,
  bgcolor: "background.paper",
  boxShadow: 24,
  p: 4,
};

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/image",
};

const configVideo = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/video",
};

const configComment = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media",
};

const configThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/thumb",
};

const configCommentThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media/thumb",
};

const ReactS3Client = new S3(config);
const ReactS3ClientVideo = new S3(configVideo);
const ReactS3ClientComment = new S3(configComment);
const ReactS3ClientThumb = new S3(configThumb);
const ReactS3ClientCommentThumb = new S3(configCommentThumb);

export default function PostComment({ interaction }) {
  const params = useParams();
  console.log(params, "params");
  const [specificPost, setSpecificPost] = useState(null);
  console.log("specificPost: ", specificPost);
  const [commentList, setCommentList] = useState([]);
  console.log("commentList:++++++++++ ", commentList);
  const [quoteComment, setQuoteComment] = useState("");
  const [specificComment, setSpecificComment] = useState();
  const [quotePost, setQuotePost] = useState("");
  const [singlePost, setSinglePost] = useState([]);
  const [postCommentModal, setPostCommentModal] = useState(false);
  const [postComment, setPostComment] = useState("");
  const navigate = useNavigate();
  const [selectedFile, setSelectedFile] = useState([]);
  const [preView, setPreview] = useState([]);
  var newPhotos = [];
  var newArr = [];
  const [pageToken, setPageToken] = useState(1);
  const [isFetching, setIsFetching] = useState(false);
  const [postPermission, setPostPermission] = useState(false);
  const [postPermissionComment, setPostPermissionComment] = useState(false);
  const [commentPermission, setCommentPermission] = useState(false);
  const [filesForRetweetQuote, setFileForRetweetQuote] = useState([]);
  const [selectedFileForRetweetQuote, setSelectedFileForRetweetQuote] =
    useState([]);
  const [filesForComments, setFileForComments] = useState([]);
  console.log("filesForComments: ", filesForComments);
  const [selectedFileForComments, setSelectedFileForComments] = useState([]);
  const [filesForCommentRetweetQuote, setfilesForCommentRetweetQuote] =
    useState([]);
  const [
    selectedFileForCommentRetweetQuote,
    setSelectedFileForCommentRetweetQuote,
  ] = useState([]);
  const [singleComment, setSingleComment] = useState();
  // console.log('singleComment: ', singleComment);
  const [postSubComment, setPostSubComment] = useState("");
  const [heightWidthForPostComments, setHeightWidthForPostComments] = useState(
    []
  );
  const [heightWidthForQuotePost, setHeightWidthForQuotePost] = useState([]);
  const [filesForSubComments, setFilesForSubComments] = useState([]);
  const [selectedFilesForSubComments, setSelectedFilesForSubComments] =
    useState([]);
  const [heightWidthForSubComments, setHeightWidthForSubComments] = useState(
    []
  );
  const [heightWidthForCommentQuotes, setHeightWidthForCommentQuotes] =
    useState([]);
  const [emojiPickerForQuotePost, setEmojiPickerForQuotePost] = useState(false);
  const [emojiPickerForPostComment, setEmojiPickerForPostComment] =
    useState(false);
  const [emojiPickerForQuoteComment, setEmojiPickerForQuoteComment] =
    useState(false);
  const [emojiPickerForSubComment, setEmojiPickerForSubComment] =
    useState(false);
  const [
    disablePhotoInputForPostComments,
    setDisablePhotoInputForPostComments,
  ] = useState(false);
  const [
    disableVideoInputForPostComments,
    setDisableVideoInputForPostComments,
  ] = useState(false);
  const [disablePhotoInputForQuotePost, setDisablePhotoInputForQuotePost] =
    useState(false);
  const [disableVideoInputForQuotePost, setDisableVideoInputForQuotePost] =
    useState(false);
  const [disablePhotoInputForSubComments, setDisablePhotoInputForSubComments] =
    useState(false);
  const [disableVideoInputForSubComments, setDisableVideoInputForSubComments] =
    useState(false);
  const [
    disablePhotoInputForCommentQuote,
    setDisablePhotoInputForCommentQuote,
  ] = useState(false);
  const [
    disableVideoInputForCommentQuote,
    setDisableVideoInputForCommentQuote,
  ] = useState(false);
  const [thumbnailForPostComments, setThumbnailForPostComments] = useState("");
  const [thumbnailForQuotePost, setThumbnailForQuotePost] = useState("");
  const [thumbnailForSubComments, setThumbnailForSubComments] = useState("");
  const [thumbnailForCommentQuotes, setThumbnailForCommentQuotes] =
    useState("");
  var imageDimensions = [];
  const location = useLocation();
  const maxHeight = "500px";
  const maxHeightInInt = 500;

  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });

  function isImageValid(blobOrFile, callback) {
    const image = new Image();
    image.onload = function () {
      // Image dimensions can be checked to ensure it's a valid image
      if (image.width > 0 && image.height > 0) {
        callback(true); // It's a valid image
      } else {
        callback(false); // Invalid image (e.g., not an image or corrupted)
      }
    };
    image.onerror = function () {
      callback(false); // Error loading the image
    };

    // Set the src of the Image element with the Blob URL or Data URL
    const blobURL = URL.createObjectURL(blobOrFile);
    image.src = blobURL;
    console.log("Line 147", image.src);
  }

  const specificPostDetail = useCallback(() => {
    try {
      post_details({
        post_id: params?.id != undefined && params?.id != "" ? params?.id : "",
      }).then((response) => {
        console.log("Post**********************", response);
        if (response.code === 1 || response.code === "1") {
          setSpecificPost(response?.data);
        } else {
        }
      });
    } catch (e) {
      console.log(e);
    }
  }, [params?.id]);

  const getCommentList = useCallback((payload) => {
    setIsFetching(true);
    try {
      get_comment_list(payload).then((response) => {
        console.log("___________________________Comments List", response);
        if (response?.code === 1 || response?.code === "1") {
          if (response?.message === "Comment list not found.") {
            setIsFetching(false);
          } else {
            if (payload.pageToken == 1) {
              setCommentList(response?.data?.comment_list);
            } else {
              setCommentList((prev) => [
                ...prev,
                ...response?.data?.comment_list,
              ]);
            }
          }
        } else if (response?.code === 2 || response?.code === "2") {
          setIsFetching(false);
        } else {
          setIsFetching(false);
        }
      });
    } catch (e) {
      console.log("Errro", e);
    }
  }, []);

  const getReelCommentList = useCallback((payload) => {
    setIsFetching(true);
    try {
      get_reel_comment_list(payload).then((response) => {
        console.log("___________________________Comments List", response);
        if (response?.code === 1 || response?.code === "1") {
          if (response?.message === "Comment list not found.") {
            setIsFetching(false);
          } else {
            if (payload.pageToken == 1) {
              setCommentList(response?.data);
            } else {
              setCommentList((prev) => [...prev, ...response?.data]);
            }
          }
        } else if (response?.code === 2 || response?.code === "2") {
          setIsFetching(false);
        } else {
          setIsFetching(false);
        }
      });
    } catch (e) {
      console.log("Errro", e);
    }
  }, []);

  // Preview while commenting in main post
  // const handleFileInputForPostComments = async (e) => {
  //   setDisableVideoInputForPostComments(true);
  //   let file = e.target.files;
  //   console.log('file:==========> ', file);
  //   const render = new FileReader();
  //   console.log(render);
  //   render.onloadend = () => {
  //     var img = new Image();
  //     img.src = render.result;
  //     img.onload = () => {
  //       var obj = { height: img.height, width: img.width };
  //       imageDimensions.push(obj);
  //       setHeightWidthForPostComments(...imageDimensions, imageDimensions);
  //     };
  //     render.readAsDataURL(file[0]);
  //   };

  //   if (
  //     Object.values(file)?.some((item) => item?.type.split("/")[0] != "image")
  //   ) {
  //     toast.error("Only Images are allowed", {
  //       toastId: "Upload error message",
  //       position: "top-center",
  //       autoClose: 2000,
  //       hideProgressBar: true,
  //       closeOnClick: true,
  //       pauseOnHover: true,
  //       draggable: true,
  //       progress: undefined,
  //       theme: "colored",
  //     });
  //   } else {
  //     for (let i = 0; i < file.length; i++) {
  //       const render = new FileReader();
  //       render.onloadend = () => {
  //         const image = new Image();
  //         image.src = render.result;
  //         console.log("Line 133", image.src);
  //         image.onload = () => {
  //           var obj = {
  //             height: image.height,
  //             width: image.width,
  //             file: file[i],
  //           };
  //           imageDimensions.push(obj);
  //           setHeightWidthForPostComments(imageDimensions);
  //           setFileForComments([...filesForComments, file[i]]);
  //           setSelectedFileForComments([
  //             ...filesForComments,
  //             e.target.files[i],
  //           ]);
  //         };
  //       };
  //       render.readAsDataURL(file[i]);
  //       setFileForComments([...filesForComments, file[i]]);
  //     }
  //   }

  //   setDisableVideoInputForPostComments(true);
  // };

  const handleFileInputForPostComments = async (e) => {
    setDisableVideoInputForPostComments(true);
    let file = e.target.files;
    const newImageDimensions = [];

    for (let i = 0; i < file.length; i++) {
      const render = new FileReader();
      render.onloadend = () => {
        const image = new Image();
        image.src = render.result;
        image.onload = () => {
          var obj = {
            height: image.height,
            width: image.width,
            file: file[i],
          };
          newImageDimensions.push(obj);
          setHeightWidthForPostComments([...heightWidthForPostComments, obj]);
          setFileForComments([...filesForComments, file[i]]);
          setSelectedFileForComments([
            ...selectedFileForComments,
            e.target.files[i],
          ]);
        };
      };
      render.readAsDataURL(file[i]);
      setFileForComments([...filesForComments, file[i]]);
    }

    setDisableVideoInputForPostComments(true);
  };

  // const handleVideoUploadForComments = (event) => {
  //   setDisablePhotoInputForPostComments(true);
  //   const videoFile = event.target.files[0];
  //   if (videoFile) {
  //     const reader = new FileReader();
  //     reader.onload = function (event) {
  //       const videoElement = document.createElement("video");
  //       videoElement.src = event.target.result;
  //       console.log("Line 440", videoElement.src);
  //       videoElement.addEventListener("canplay", function () {
  //         console.log(
  //           "Video dimensions:",
  //           videoElement.videoWidth,
  //           videoElement.videoHeight
  //         );
  //         var obj = {
  //           height: videoElement.videoHeight,
  //           width: videoElement.videoWidth,
  //         };
  //         imageDimensions.push(obj);
  //         setHeightWidthForPostComments([
  //           ...heightWidthForPostComments,
  //           imageDimensions,
  //         ]);
  //         setSelectedFileForComments([...filesForComments, videoFile]);
  //         const canvas = document.createElement("canvas");
  //         canvas.width = videoElement.videoWidth;
  //         canvas.height = videoElement.videoHeight;
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  //         canvas.toBlob(function (blob) {
  //           console.log("Blob created", blob);
  //           isImageValid(blob, (isValid) => {
  //             if (isValid) {
  //               console.log("Line 328: The image Blob is valid.");
  //             } else {
  //               console.log("Line 330: The image Blob is not valid.");
  //             }
  //           });
  //           const thumbnailFile = new File([blob], "thumbnail.jpeg", {
  //             type: "image/jpeg",
  //           });

  //           isImageValid(thumbnailFile, (isValid) => {
  //             if (isValid) {
  //               console.log("Line 337: The image File is valid.");
  //             } else {
  //               console.log("Line 339: The image File is not valid.");
  //             }
  //           });
  //           setThumbnailForPostComments(thumbnailFile);
  //         }, "image/jpeg");
  //         const thumbnailUrl = canvas.toDataURL("image/jpeg");
  //         console.log("Line 302", thumbnailUrl);
  //       });
  //     };
  //     reader.readAsDataURL(videoFile);
  //     setFileForComments([...filesForComments, videoFile]);
  //   }
  // };

  // const handleVideoUploadForRetweetWithQuotes = (event) => {
  //   setDisablePhotoInputForQuotePost(true);
  //   const videoFile = event.target.files[0];
  //   if (videoFile) {
  //     const reader = new FileReader();
  //     reader.onload = function (event) {
  //       const videoElement = document.createElement("video");
  //       videoElement.src = event.target.result;
  //       console.log("Line 295", videoElement.src);
  //       videoElement.addEventListener("canplay", function () {
  //         console.log(
  //           "Video dimensions:",
  //           videoElement.videoWidth,
  //           videoElement.videoHeight
  //         );
  //         var obj = {
  //           height: videoElement.videoHeight,
  //           width: videoElement.videoWidth,
  //         };
  //         imageDimensions.push(obj);
  //         setHeightWidthForQuotePost(imageDimensions);
  //         setSelectedFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
  //         const canvas = document.createElement("canvas");
  //         canvas.width = videoElement.videoWidth;
  //         canvas.height = videoElement.videoHeight;
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  //         canvas.toBlob(function (blob) {
  //           console.log("Blob created", blob);
  //           isImageValid(blob, (isValid) => {
  //             if (isValid) {
  //               console.log("Line 328: The image Blob is valid.");
  //             } else {
  //               console.log("Line 330: The image Blob is not valid.");
  //             }
  //           });
  //           const thumbnailFile = new File([blob], "thumbnail.jpeg", {
  //             type: "image/jpeg",
  //           });

  //           isImageValid(thumbnailFile, (isValid) => {
  //             if (isValid) {
  //               console.log("Line 337: The image File is valid.");
  //             } else {
  //               console.log("Line 339: The image File is not valid.");
  //             }
  //           });
  //           setThumbnailForQuotePost(thumbnailFile);
  //         }, "image/jpeg");
  //         const thumbnailUrl = canvas.toDataURL("image/jpeg");
  //         console.log("Line 302", thumbnailUrl);
  //       });
  //     };
  //     reader.readAsDataURL(videoFile);
  //     setFileForRetweetQuote([...filesForRetweetQuote, videoFile]);
  //   }
  // };

  // const handleVideoUploadForSubComments = (event) => {
  //   setDisablePhotoInputForSubComments(true);
  //   const videoFile = event.target.files[0];
  //   if (videoFile) {
  //     const reader = new FileReader();
  //     reader.onload = function (event) {
  //       const videoElement = document.createElement("video");
  //       videoElement.src = event.target.result;
  //       console.log("Line 440", videoElement.src);
  //       videoElement.addEventListener("canplay", function () {
  //         console.log(
  //           "Video dimensions:",
  //           videoElement.videoWidth,
  //           videoElement.videoHeight
  //         );
  //         var obj = {
  //           height: videoElement.videoHeight,
  //           width: videoElement.videoWidth,
  //         };
  //         imageDimensions.push(obj);
  //         setHeightWidthForSubComments([
  //           ...heightWidthForSubComments,
  //           imageDimensions,
  //         ]);
  //         setSelectedFilesForSubComments([...filesForSubComments, videoFile]);
  //         const canvas = document.createElement("canvas");
  //         canvas.width = videoElement.videoWidth;
  //         canvas.height = videoElement.videoHeight;
  //         const ctx = canvas.getContext("2d");
  //         ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
  //         canvas.toBlob(function (blob) {
  //           console.log("Blob created", blob);
  //           isImageValid(blob, (isValid) => {
  //             if (isValid) {
  //               console.log("Line 328: The image Blob is valid.");
  //             } else {
  //               console.log("Line 330: The image Blob is not valid.");
  //             }
  //           });
  //           const thumbnailFile = new File([blob], "thumbnail.jpeg", {
  //             type: "image/jpeg",
  //           });

  //           isImageValid(thumbnailFile, (isValid) => {
  //             if (isValid) {
  //               console.log("Line 337: The image File is valid.");
  //             } else {
  //               console.log("Line 339: The image File is not valid.");
  //             }
  //           });
  //           setThumbnailForSubComments(thumbnailFile);
  //         }, "image/jpeg");
  //         const thumbnailUrl = canvas.toDataURL("image/jpeg");
  //         console.log("Line 302", thumbnailUrl);
  //       });
  //     };
  //     reader.readAsDataURL(videoFile);
  //     setFilesForSubComments([...filesForSubComments, videoFile]);
  //   }
  // };

  const handleFileInputForRetweetQuotes = async (e) => {
    setDisableVideoInputForQuotePost(true);
    let file = e.target.files;
    console.log("Line 88", file);
    const render = new FileReader();
    console.log(render);
    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        console.log("Line 127", img.width, img.height);
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);
        console.log(imageDimensions);
        setHeightWidthForQuotePost(...imageDimensions, imageDimensions);
      };
      render.readAsDataURL(file[0]);
    };
    for (let i = 0; i < file.length; i++) {
      console.log(file[i].name);
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;
          console.log("Line 133", image.src);
          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            console.log("obj", obj);
            imageDimensions.push(obj);
            console.log("Line 139", imageDimensions);
            setHeightWidthForQuotePost(imageDimensions);
            setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
            setSelectedFileForRetweetQuote([
              ...filesForRetweetQuote,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
        console.log(filesForRetweetQuote);
        console.log("Line 218 ---------->", e.target.files[i]);
        console.log("Line 219 ==========>", [
          ...filesForRetweetQuote,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForQuotePost(true);
  };

  const handleFileInputForCommentRetweetQuotes = async (e) => {
    setDisableVideoInputForQuotePost(true);
    let file = e.target.files;
    console.log("Line 88", file);
    const render = new FileReader();
    console.log(render);
    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        console.log("Line 127", img.width, img.height);
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);
        console.log(imageDimensions);
        setHeightWidthForCommentQuotes(...imageDimensions, imageDimensions);
      };
      render.readAsDataURL(file[0]);
    };
    for (let i = 0; i < file.length; i++) {
      console.log(file[i].name);
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;
          console.log("Line 133", image.src);
          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            console.log("obj", obj);
            imageDimensions.push(obj);
            console.log("Line 139", imageDimensions);
            setHeightWidthForCommentQuotes(imageDimensions);
            setfilesForCommentRetweetQuote([
              ...filesForCommentRetweetQuote,
              file[i],
            ]);
            setSelectedFileForCommentRetweetQuote([
              ...filesForCommentRetweetQuote,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setfilesForCommentRetweetQuote([...filesForRetweetQuote, file[i]]);
        console.log(filesForCommentRetweetQuote);
        console.log("Line 218 ---------->", e.target.files[i]);
        console.log("Line 219 ==========>", [
          ...filesForCommentRetweetQuote,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForQuotePost(true);
  };

  const handleVideoUploadForCommentRetweetQuotes = (event) => {
    setDisablePhotoInputForCommentQuote(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;
        console.log("Line 295", videoElement.src);
        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForCommentQuotes(imageDimensions);
          setSelectedFileForCommentRetweetQuote([
            ...filesForCommentRetweetQuote,
            videoFile,
          ]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            console.log("Blob created", blob);
            isImageValid(blob, (isValid) => {
              if (isValid) {
                console.log("Line 328: The image Blob is valid.");
              } else {
                console.log("Line 330: The image Blob is not valid.");
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
                console.log("Line 337: The image File is valid.");
              } else {
                console.log("Line 339: The image File is not valid.");
              }
            });
            setThumbnailForCommentQuotes(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
          console.log("Line 302", thumbnailUrl);
        });
      };
      reader.readAsDataURL(videoFile);
      setfilesForCommentRetweetQuote([
        ...filesForCommentRetweetQuote,
        videoFile,
      ]);
    }
  };

  const handleFileInputForSubComments = async (e) => {
    setDisableVideoInputForSubComments(true);
    let file = e.target.files;
    console.log("Line 88", file);
    const render = new FileReader();
    console.log(render);
    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        console.log("Line 127", img.width, img.height);
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);
        console.log(imageDimensions);
        setHeightWidthForSubComments(...imageDimensions, imageDimensions);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      console.log(file[i].name);
      if (
        file[i].type.split("/")[0] === "video" &&
        file?.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;
          console.log("Line 133", image.src);
          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);
            console.log("Line 139", imageDimensions);
            setHeightWidthForSubComments(imageDimensions);
            setFilesForSubComments([...filesForSubComments, file[i]]);
            setSelectedFilesForSubComments([
              ...filesForSubComments,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFilesForSubComments([...filesForSubComments, file[i]]);
        console.log(filesForSubComments);
        console.log("Line 104 ---------->", e.target.files[i]);
        console.log("Line 105 ==========>", [
          ...filesForSubComments,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForSubComments(true);
  };

  // Post Retweet with quotes with media
  const uploadImagesForRetweetQuotes = async () => {
    if (selectedFileForRetweetQuote?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForRetweetQuote?.length; j++) {
        console.log("Line 123", selectedFile[j]);
        let upload;
        let uploadThumb;
        if (selectedFileForRetweetQuote[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFileForRetweetQuote[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForQuotePost
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFileForRetweetQuote[j]
          );
        }
        console.log("Line 105", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForRetweetQuote[j]?.type?.split("/")[0],
          width: heightWidthForQuotePost[j][0]?.width,
          height: heightWidthForQuotePost[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 107", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      retweetWithQuotes(newArr);
      console.log("Line 118", newArr);
    }
    // setMedia(newArr);
  };

  // Comment retweet with quotes with media
  const uploadImagesForCommentRetweetQuotes = async () => {
    if (selectedFileForCommentRetweetQuote?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForCommentRetweetQuote?.length; j++) {
        console.log("Line 379", selectedFileForCommentRetweetQuote[j]);
        let upload;
        let uploadThumb;
        console.log("Line 381", ReactS3Client);
        if (
          selectedFileForCommentRetweetQuote[j]?.type?.split("/")[0] === "video"
        ) {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFileForCommentRetweetQuote[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForCommentQuotes
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFileForCommentRetweetQuote[j]
          );
        }
        console.log("Line 387", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForCommentRetweetQuote[j]?.type?.split("/")[0],
          width: heightWidthForCommentQuotes[j][0]?.width,
          height: heightWidthForCommentQuotes[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 107", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      retweetCommentWithQuotes(newArr);
      console.log("Line 118", newArr);
    }
    // setMedia(newArr);
  };

  // Media in comment
  const uploadImagesInComments = async () => {
    if (selectedFileForComments?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFileForComments?.length; j++) {
        console.log("Line 123", selectedFileForComments[j]);
        let upload;
        let uploadThumb;
        if (selectedFileForComments[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForPostComments
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFileForComments[j]
          );
        }
        console.log("Line 105", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFileForComments[j]?.type?.split("/")[0],
          width: heightWidthForPostComments[j][0]?.width,
          height: heightWidthForPostComments[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 107", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      addComments(newArr);
      console.log("Line 118", newArr);
    }
    // setMedia(newArr);
  };

  const uploadImagesForSubComments = async () => {
    console.log("Inside uploadImagesForComments");
    if (selectedFilesForSubComments?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFilesForSubComments?.length; j++) {
        console.log("Line 455", selectedFilesForSubComments[j]);
        let upload;
        let uploadThumb;
        if (selectedFilesForSubComments[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFilesForSubComments[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForSubComments
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFilesForSubComments[j]
          );
        }
        console.log("Line 105", upload.location);
        setPreview([...preView, upload.location]);
        newPhotos.push(upload.location.split("/")[6]);
        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFilesForSubComments[j]?.type?.split("/")[0],
          width: heightWidthForSubComments[j][0]?.width,
          height: heightWidthForSubComments[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };
        console.log("Line 107", obj);
        newArr.push(obj);
      }
      // setMedia(newArr);
      addSubComment(newArr);
      console.log("Line 112", newArr);
    }
  };

  // Comment Like unlike
  const commentLikeUnlike = (item, isReelSubComment) => {
    console.log("$$$$$$$$$$$$", item);
    give_comments_like_unlike({
      owner_user_id: item.owner_user_id,
      type: item.is_like === 0 ? "like" : "unlike",
      comments_id: item?._id,
      post_id: item?.post_id,
    }).then((response) => {
      console.log("Like comment", response);
      if (response.code === 1 || response.code === "1") {
        specificPostDetail();
        const updatedCommentList = commentList?.map((comment) => {
          if (comment?._id === item?._id) {
            console.log(
              "comment?._id === item?._id: ",
              comment?._id === item?._id
            );

            const newLikes = item.is_like
              ? comment?.likes - 1
              : comment?.likes + 1;
            const newIsLike = item.is_like ? 0 : 1;
            return {
              ...comment,
              is_like: newIsLike,
              likes: newLikes,
            };
          } else {
            if (comment?._id === isReelSubComment) {
              console.log("comment:ddsdsdsddsddsds ", comment, item);

              const reply_data = comment?.reply_data?.map((sub) => {
                if (sub._id == item?._id) {
                  const newLikes = item.is_like
                    ? sub?.likes - 1
                    : sub?.likes + 1;
                  const newIsLike = item.is_like ? 0 : 1;
                  console.log("newLikes: ", newLikes);
                  console.log("newLikes+++++++++++++: ", newIsLike);
                  return {
                    ...sub,
                    is_like: newIsLike,
                    likes: newLikes,
                  };
                }
                return sub;
              });
              return {
                ...comment,
                reply_data,
              };
            }
          }
          console.log("comment:==========>  ", comment);
          return comment;
        });
        setCommentList(updatedCommentList);
        // getCommentList();
      }
    });
  };

  //Post Like unlike
  const likeUnlike = (post) => {
    post_details({
      post_id: post?._id,
    }).then((response) => {
      console.log("Post like unlike", response);
      if (response.code === 1 || response.data === "1") {
        give_like_unlike({
          post_id: response.data._id,
          type: response.data.is_like === 1 ? "unlike" : "like",
          post_type: response.data.post_type,
          owner_user_id: response.data.user_id,
        }).then((res) => {
          if (res.code === 1 || res.code === "1") {
            specificPostDetail();
            getCommentList();
          }
        });
      }
    });
  };

  // Post retweet
  const retweet = (postId) => {
    post_details({
      post_id: postId,
    }).then((response) => {
      console.log("Line 79", response.data);
      if (response?.code === 1 || response.code === "1") {
        add_post({
          description: response?.data?.description,
          media: [],
          retweet_id: response?.data?._id,
          post_type: "retweet",
          shared_feed: response?.data,
          module_type:
            response?.data?.module_type === "reels" ? "reels" : "post",
        }).then((response) => {
          console.log("Line 88", response);
          if (response.code === 1 || response.code === "1") {
            toast.info(response.message, {
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
              toastId: "postDetailRepost",
            });
            specificPostDetail();
            getCommentList();
          }
        });
      }
    });
  };

  // Post retweet with Quotes
  const retweetWithQuotes = (postImage) => {
    add_post({
      description: quotePost,
      media: postImage,
      retweet_id: specificPost._id,
      post_type:
        (quotePost === "" || quotePost === undefined) && postImage.length === 0
          ? "retweet"
          : "retweet_with_quote",
      shared_feed: specificPost,
      module_type: specificPost?.module_type === "reels" ? "reels" : "post",
    }).then((res) => {
      console.log("Line 126", res);
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
          toastId: "post-detail-quote-success",
        });
        specificPostDetail();
        getCommentList();
        setFileForRetweetQuote();
        setSelectedFileForRetweetQuote();
        setHeightWidthForQuotePost();
        setEmojiPickerForQuotePost(false);
        setDisablePhotoInputForQuotePost(false);
        setDisableVideoInputForQuotePost(false);
      }
    });
  };

  // Comment retweet
  const retweetComment = (comment) => {
    console.log("Line 605", comment);
    add_post({
      description: comment?.description,
      media: [],
      retweet_id: comment?._id,
      post_type: "retweet_comments",
      shared_feed: comment,
      module_type: comment?.module_type === "reels" ? "reels" : "post",
    }).then((response) => {
      console.log("Comment retweeted", response);
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        specificPostDetail();
        const updatedComments = commentList?.map((comm) => {
          if (comm?._id === comment?._id) {
            return {
              ...comm,
              is_retweet: 1,
              retweet_count: comm?.retweet_count + 1, // Toggle like status
            };
          }
          return comm;
        });
        setCommentList(updatedComments);
      }
    });
  };

  // Comment retweet with quotes
  const retweetCommentWithQuotes = (images) => {
    add_post({
      description: quoteComment,
      media: images,
      retweet_id: singleComment?._id,
      post_type:
        (quoteComment === "" || quoteComment === undefined) &&
        images.length == 0
          ? "retweet_comments"
          : "retweet_with_quote",
      shared_feed: singleComment,
      module_type: "post",
    }).then((res) => {
      console.log("Comment with quotes", res);
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        const updatedComments = commentList?.map((post) => {
          if (post?._id === singleComment?._id) {
            return {
              ...post,
              retweet_count: post?.retweet_count + 1,
              is_retweet: 1,
            };
          }
          return post;
        });
        setCommentList(updatedComments);
        // getCommentList();
        setfilesForCommentRetweetQuote();
        setSelectedFileForCommentRetweetQuote();
        setHeightWidthForCommentQuotes();
        setEmojiPickerForQuoteComment(false);
        setDisablePhotoInputForCommentQuote(false);
        setDisableVideoInputForCommentQuote(false);
      }
    });
  };

  // Post Comment
  const addComments = (images) => {
    console.log(images);
    if (
      (postComment === "" || postComment === undefined) &&
      images.length == 0
    ) {
      toast.info("Please enter some text before post or upload media", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
        toastId: "post-comment",
      });
      return false;
    } else {
      // let newReq = {
      //   post_id: specificPost?._id,
      //   comments_type: "main",
      //   reply_comment_id: "",
      //   owner_user_id:
      //     specificPost?.post_type === "retweet"
      //       ? specificPost?.shared_feed?.user_id
      //       : specificPost?.user_id,
      //   media: images,
      //   hashtags: [],
      //   description: postComment,
      // }
      let newReq = {
        post_id:
          specificPost?.post_type == "retweet"
            ? specificPost?.retweet_id
            : specificPost?._id,
        comments_type: specificPost?.shared_feed?.comment_type || "main",
        // comments_type: specificPost?.post_type == "retweet" ? 'sub' : "main",
        // reply_comment_id: specificPost?.retweet_id,
        owner_user_id:
          specificPost?.post_type === "retweet"
            ? specificPost?.shared_feed?.user_id
            : specificPost?.user_id,
        media: images,
        hashtags: [],
        description: postComment,
      };
      if (specificPost?.post_type == "retweet") {
        newReq.module_type = specificPost?.module_type;
      } else {
        newReq.reply_comment_id = specificPost?.retweet_id;
      }
      add_comments(newReq).then((response) => {
        console.log("Line 258", response);
        if (response.code === 1 || response.code === "1") {
          toast.info(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "post-detail-post-comment-success",
          });
          // const updatedFeeds = commentList?.map((post) => {
          //   if (post?._id === specificPost?._id) {
          //     return {
          //       ...post,
          //       comment_count: post?.comment_count + 1,
          //     };
          //   }
          //   return post;
          // });
          setCommentList((prev) => [response?.data, ...prev]);
          // getCommentList();
          setPostCommentModal(false);
          setPostComment("");
          specificPostDetail();
          setFileForComments();
          setSelectedFileForComments();
          setHeightWidthForPostComments();
          setEmojiPickerForPostComment(false);
          setDisablePhotoInputForPostComments(false);
          setDisableVideoInputForPostComments(false);
        }
      });
    }
  };

  // Delete Post
  const deletePost = (postId) => {
    if (localStorage.getItem("name") === specificPost.name) {
      delete_post({
        id: postId,
        retweet_id:
          specificPost.post_type === "retweet" ? specificPost.retweet_id : "",
      }).then((response) => {
        console.log("Line 228", response);
        if (response.code === 1) {
          toast.info(response.message, {
            toastId: "Delete Post",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setPostPermission(false);
          specificPostDetail();
          getCommentList();
        }
      });
    } else {
      setPostPermission(false);
    }
  };

  // Delete Comment
  const deleteComment = (postId, mainComment = "") => {
    delete_comment({ id: postId }).then((response) => {
      console.log("Line 523", response);
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          toastId: "Delete Comment",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });

        const updatedComments = mainComment?.length
          ? commentList?.map((comment) => {
              if (comment._id == mainComment) {
                comment.reply_data = comment.reply_data.filter(
                  (itm) => itm._id != postId
                );
              }
              return comment;
            })
          : commentList?.filter((comment) => comment?._id != postId);
        setCommentList(updatedComments);
        // setCommentPermission(false);
        // specificPostDetail();
        // getCommentList();
      }
    });
  };

  //Undo retweet post
  const undoRetweet = (postId) => {
    console.log(postId);
    undo_post_retweet({ id: postId }).then((response) => {
      console.log("undo reel", response);
      if (response.code === 1 || response.code === "1") {
        specificPostDetail();
        getCommentList();
      }
    });
  };

  //Undo retweet comment
  const undoRetweetComment = (commentId) => {
    console.log(commentId);
    undo_post_retweet({ id: commentId }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        specificPostDetail();
        const updatedComments = commentList?.map((comment) => {
          if (comment?._id === commentId) {
            return {
              ...comment,
              is_retweet: 0,
              retweet_count: comment?.retweet_count - 1,
            };
          }
          return comment;
        });
        setCommentList(updatedComments);
      }
    });
  };

  // sub comment
  const addSubComment = (images) => {
    if (
      (postSubComment === "" || postSubComment === undefined) &&
      images?.length < 0
    ) {
      toast.info("Please enter some text before post or upload media", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      let newReq = {
        post_id: params?.id != undefined && params?.id != "" ? params?.id : "",
        comments_type: "sub",
        reply_comment_id: singleComment?._id,
        owner_user_id: singleComment?.owner_user_id,
        media: images,
        hashtags: [],
        description: postSubComment,
        module_type: specificPost?.module_type,
      };

      add_comments(newReq).then((response) => {
        console.log("Line 761", response);
        if (response.code === 1 || response.code === "1") {
          toast.info(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "sub-comment-success",
          });
          const updatedComments = commentList?.map((comment) => {
            if (comment?._id === singleComment?._id) {
              if (
                specificPost?.post_type == "reels" &&
                comment?._id === singleComment?._id
              ) {
                comment?.reply_data?.length > 0
                  ? (comment.reply_data = [
                      response.data,
                      ...comment.reply_data,
                    ])
                  : (comment.reply_data = [response.data]);
              }

              return {
                ...comment,
                comment_count: comment?.comment_count + 1,
              };
            } else {
            }
            return comment;
          });
          setCommentList(updatedComments);
          setPostSubComment("");
          setPostComment("");
          setFilesForSubComments();
          setSelectedFilesForSubComments();
          setHeightWidthForSubComments();
          setEmojiPickerForSubComment(false);
          setDisablePhotoInputForSubComments(false);
          setDisableVideoInputForSubComments(false);
        }
      });
    }
  };

  // Remove image for post comments
  // console.log("filesForComments: ===> ", filesForComments);
  // console.log("filesForComments: ========>", heightWidthForPostComments);

  // const removeImageForComments = (index) => {
  //   var x = [...filesForComments];
  //   var w = [...heightWidthForPostComments];
  //   setFileForComments(x?.filter((val, ind) => ind != index));
  //   setSelectedFileForComments(x?.filter((val, ind) => ind != index));
  //   setHeightWidthForPostComments(w?.filter((val, ind) => ind != index));

  //   if (x?.length === 0 || w?.length === 0) {
  //     setDisablePhotoInputForPostComments(false);
  //     setDisableVideoInputForPostComments(false);
  //   }
  // };

  const removeImageForComments = (index) => {
    const updatedFilesForComments = [...filesForComments];
    updatedFilesForComments.splice(index, 1);

    const updatedHeightWidthForPostComments = [...heightWidthForPostComments];
    updatedHeightWidthForPostComments.splice(index, 1);

    setFileForComments(updatedFilesForComments);
    setSelectedFileForComments(updatedFilesForComments);
    setHeightWidthForPostComments(updatedHeightWidthForPostComments);

    // Reset the file input field value
    document.getElementById("fileInput").value = null;

    if (
      updatedFilesForComments.length === 0 ||
      updatedHeightWidthForPostComments.length === 0
    ) {
      setDisablePhotoInputForPostComments(false);
      setDisableVideoInputForPostComments(false);
    }
  };

  const removeImageForQuotePost = (index) => {
    console.log(index);
    var x = [...filesForRetweetQuote];
    var w = [...heightWidthForQuotePost];
    console.log("above", x);
    x.splice(index, 1);
    w.splice(index, 1);
    console.log("below", x);
    setFileForRetweetQuote(x);
    setSelectedFileForRetweetQuote(x);
    setHeightWidthForQuotePost(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForQuotePost(false);
      setDisableVideoInputForQuotePost(false);
    }
  };

  const removeImageForSubComments = (index) => {
    console.log(index);
    var x = [...filesForSubComments];
    var w = [...heightWidthForSubComments];
    console.log("above", x);
    x.splice(index, 1);
    w.splice(index, 1);
    console.log("below", x);
    setFilesForSubComments(x);
    setSelectedFilesForSubComments(x);
    setHeightWidthForSubComments(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForSubComments(false);
      setDisableVideoInputForSubComments(false);
    }
  };

  const removeImageForCommentQuote = (index) => {
    console.log(index);
    var x = [...filesForCommentRetweetQuote];
    var w = [...heightWidthForCommentQuotes];
    console.log("above", x);
    x.splice(index, 1);
    w.splice(index, 1);
    console.log("below", x);
    setfilesForCommentRetweetQuote(x);
    setSelectedFileForCommentRetweetQuote(x);
    setHeightWidthForCommentQuotes(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForCommentQuote(false);
      setDisableVideoInputForCommentQuote(false);
    }
  };

  const findWidth = (originalHeight, newHeight, originalWidth) => {
    return (newHeight * originalWidth) / originalHeight;
  };

  const isPortrait = (h, w) => {
    return h > w;
  };
  const handleSavePost = useCallback(
    (item) => {
      save_post({
        post_id: item._id,
        type: item?.is_saved == "N" ? "save" : "remove",
      }).then((response) => {
        console.log("response: ", response);
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          specificPostDetail();
          // const updatedPosts = specificPost?.map((post) => {
          //   if (post?._id == item?._id) {
          // specificPost.is_saved = item?.is_saved == "N" ? "Y" : "N";
          //   }
          //   return post;
          // });
          // setSpecificPost(specificPost);
        }
      });
    },
    [specificPost]
  );
  const handleShare = useCallback((data) => {
    console.log("==================================================");
    share_link({
      id: data._id,
      module_type: data.module_type,
      description: data.description || "stulink",
    }).then((response) => {
      console.log(
        "==================================================: ",
        response
      );
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (specificPost?._id && specificPost?.post_type != "reels") {
      getCommentList({
        post_id: params?.id != undefined && params?.id != "" ? params?.id : "",
        comments_type: "main",
        page_token: pageToken,
        post_type: specificPost?.post_type,
      });
    }
    if (specificPost?._id && specificPost?.post_type == "reels") {
      getReelCommentList({
        post_id: params?.id != undefined && params?.id != "" ? params?.id : "",
        // comments_type: "main",
        page_token: pageToken,
        // post_type: specificPost?.post_type,
      });
    }
  }, [
    getCommentList,
    getReelCommentList,
    pageToken,
    params?.id,
    specificPost?._id,
    specificPost?.post_type,
  ]);

  // useEffect

  useEffect(() => {
    specificPostDetail();
  }, [specificPostDetail]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  // if(commentList.comment_list === undefined || commentList.comment_list === null) return <></>
  if (params?.id === undefined && params?.id === "") {
    return <></>;
  }
  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <ShareLink
        shareModalOpen={shareModalOpen}
        setShareModalOpen={setShareModalOpen}
      />
      {/* <HeaderProfile /> */}

      {/* comment retweet with quotes */}
      <div
        className="modal fade"
        id="retweetCommentModal"
        tabIndex={-1}
        aria-labelledby="retweet"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="user_img_comment">
                    <a href="#">
                      <img src={localStorage?.getItem("profile_image")} />
                    </a>
                  </div>
                  <div className="comment_user_post">
                    <input
                      type="text"
                      name=""
                      placeholder="Add comment"
                      value={quoteComment}
                      onChange={(e) => {
                        setQuoteComment(e?.target?.value);
                        setEmojiPickerForQuoteComment(false);
                      }}
                    />
                    <div className="uploade_images">
                      {filesForCommentRetweetQuote?.length !== 0 ? (
                        filesForCommentRetweetQuote?.map((item, key) => {
                          switch (item.type.split("/")[0]) {
                            case "image": {
                              return (
                                <div
                                  className="images_tab_1"
                                  style={
                                    filesForRetweetQuote !== "" ||
                                    filesForRetweetQuote !== undefined ||
                                    filesForRetweetQuote !== null
                                      ? {}
                                      : { display: "none" }
                                  }
                                  key={key}
                                >
                                  <img
                                    src={URL.createObjectURL(item)}
                                    style={{
                                      height: "100px",
                                      width: "100%",
                                      display:
                                        filesForRetweetQuote === ""
                                          ? "none"
                                          : "",
                                    }}
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() =>
                                      removeImageForCommentQuote(key)
                                    }
                                  />
                                </div>
                              );
                            }
                            case "video": {
                              return (
                                <div
                                  className="images_tab_1"
                                  style={
                                    filesForRetweetQuote !== "" ||
                                    filesForRetweetQuote !== undefined ||
                                    filesForRetweetQuote !== null
                                      ? {}
                                      : { display: "none" }
                                  }
                                  key={key}
                                >
                                  <video
                                    controls
                                    muted
                                    loop
                                    style={{
                                      width: "200px",
                                      height: "100px",
                                      display:
                                        filesForRetweetQuote === ""
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <source src={URL.createObjectURL(item)} />
                                  </video>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() =>
                                      removeImageForCommentQuote(key)
                                    }
                                  />
                                </div>
                              );
                            }
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="main_comment_other">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={singleComment?.profile_image}
                              className="inner_post"
                            />{" "}
                            {singleComment?.name}
                          </a>
                          @{singleComment?.username}{" "}
                          <p>{getTimeDifference(singlePost?.insertdate)}</p>
                        </span>
                        <br />
                      </div>
                      <div className="tetweet_quote_content">
                        {singleComment?.media?.length > 0 ? (
                          singleComment?.media[0]?.type === "video" ? (
                            <div className="tetweet_quote_image">
                              <img
                                src={singleComment?.media[0]?.thumb}
                                alt="post"
                              />
                            </div>
                          ) : (
                            <div className="tetweet_quote_image">
                              <img
                                src={singleComment?.media[0]?.name}
                                alt="post"
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                        <div className="tetweet_quote_text">
                          <p className="mb-0 gray_5a font_12 text-break ">
                            {singleComment?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="insert_images">
                <label
                  style={
                    disablePhotoInputForCommentQuote === true ||
                    filesForCommentRetweetQuote?.length === 4 ||
                    selectedFileForCommentRetweetQuote?.length === 4 ||
                    heightWidthForCommentQuotes?.length === 4
                      ? {}
                      : { cursor: "pointer" }
                  }
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileInputForCommentRetweetQuotes}
                    disabled={
                      disablePhotoInputForCommentQuote === true ||
                      filesForCommentRetweetQuote?.length === 4 ||
                      selectedFileForCommentRetweetQuote?.length === 4 ||
                      heightWidthForCommentQuotes?.length === 4
                    }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
                <a className="mx-4">
                  <label
                    style={
                      disableVideoInputForCommentQuote === true ||
                      filesForCommentRetweetQuote?.length === 1 ||
                      selectedFileForCommentRetweetQuote?.length === 1 ||
                      heightWidthForCommentQuotes?.length === 1
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mp4, .avi, .mov, .mkv, .wmv"
                      id="imageForPosts"
                      onChange={handleVideoUploadForCommentRetweetQuotes}
                      disabled={
                        disableVideoInputForCommentQuote === true ||
                        filesForCommentRetweetQuote?.length === 1 ||
                        selectedFileForCommentRetweetQuote?.length === 1 ||
                        heightWidthForCommentQuotes?.length === 1
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/video-icon.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                </a>
                {/* <a href="javascript:void(0)">
                                    <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid mx-3"
                                        alt="gallery" />
                                </a> */}
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setEmojiPickerForQuoteComment(!emojiPickerForQuoteComment)
                  }
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid mx-3"
                    alt="gallery"
                  />
                </a>
              </div>
              <button
                onClick={() => {
                  uploadImagesForCommentRetweetQuotes();
                }}
                style={{ cursor: "pointer" }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </div>
            {emojiPickerForQuoteComment ? (
              <>
                <div className="mx-5 mt-0 mb-3">
                  <EmojiPicker
                    onEmojiClick={(e) =>
                      setQuoteComment(quoteComment + e?.emoji)
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* post retweet with quotes */}
      <div
        className="modal fade"
        id="retweetModal"
        tabIndex={-1}
        aria-labelledby="retweet"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Viralled Post
              </h5>
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
            </div>
            <div className="modal-body position-relative">
              {/* <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
              /> */}
              <div className="comment_main">
                <div className="user_comment">
                  <div className="user_img_comment">
                    <a href="#">
                      <img src={localStorage?.getItem("profile_image")} />
                    </a>
                    <p>{localStorage?.getItem("name")}</p>
                  </div>
                  <div className="comment_user_post">
                    <input
                      type="text"
                      name=""
                      placeholder="Add comment"
                      value={quotePost}
                      onChange={(e) => {
                        setQuotePost(e.target.value);
                        setEmojiPickerForQuotePost(false);
                      }}
                    />
                    <div className="uploade_images">
                      {filesForRetweetQuote?.length !== 0 ? (
                        filesForRetweetQuote?.map((item, key) => {
                          switch (item.type.split("/")[0]) {
                            case "image": {
                              return (
                                <div
                                  className="images_tab_1"
                                  style={
                                    filesForRetweetQuote !== "" ||
                                    filesForRetweetQuote !== undefined ||
                                    filesForRetweetQuote !== null
                                      ? {}
                                      : { display: "none" }
                                  }
                                  key={key}
                                >
                                  <img
                                    src={URL.createObjectURL(item)}
                                    style={{
                                      // height: "100px",
                                      // width: "100%",
                                      display:
                                        filesForRetweetQuote === ""
                                          ? "none"
                                          : "",
                                    }}
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/x-circle.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() => removeImageForQuotePost(key)}
                                  />
                                </div>
                              );
                            }
                            case "video": {
                              return (
                                <div
                                  className="images_tab_1"
                                  style={
                                    filesForRetweetQuote !== "" ||
                                    filesForRetweetQuote !== undefined ||
                                    filesForRetweetQuote !== null
                                      ? {}
                                      : { display: "none" }
                                  }
                                  key={key}
                                >
                                  <video
                                    controls
                                    muted
                                    loop
                                    style={{
                                      height: "100px",
                                      display:
                                        filesForRetweetQuote === ""
                                          ? "none"
                                          : "",
                                    }}
                                  >
                                    <source src={URL.createObjectURL(item)} />
                                  </video>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/x-circle.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() => {
                                      removeImageForQuotePost(key);
                                    }}
                                  />
                                </div>
                              );
                            }
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="main_comment_other">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={specificPost?.profile_image}
                              className="inner_post"
                            />{" "}
                            {specificPost?.name}
                          </a>
                          @{specificPost?.username}{" "}
                          <p>{getTimeDifference(specificPost?.insertdate)}</p>
                        </span>
                        <br />
                      </div>
                      <div className="tetweet_quote_content">
                        {specificPost?.module_type === "post" ? (
                          specificPost?.media?.length > 0 ? (
                            specificPost?.media[0]?.type === "video" ? (
                              <div className="tetweet_quote_image">
                                <img
                                  src={specificPost?.media[0]?.thumb}
                                  alt="post"
                                />
                              </div>
                            ) : (
                              <div className="tetweet_quote_image">
                                <img
                                  src={specificPost?.media[0]?.name}
                                  alt="post"
                                />
                              </div>
                            )
                          ) : (
                            <></>
                          )
                        ) : (
                          <></>
                        )}
                        {specificPost?.module_type === "document" ? (
                          <div className="tetweet_quote_image">
                            <img
                              src={specificPost?.media[0]?.thumb}
                              alt="post"
                            />
                          </div>
                        ) : specificPost?.module_type === "news" ? (
                          specificPost?.media[0]?.type !== "image" ? (
                            <div className="tetweet_quote_image">
                              <img
                                src={specificPost?.media[0]?.thumb}
                                alt="post"
                              />
                            </div>
                          ) : (
                            <div className="tetweet_quote_image">
                              <img
                                src={specificPost?.media[0]?.name}
                                alt="post"
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                        {specificPost?.module_type === "document" ? (
                          <div className="tetweet_quote_text">
                            <p class="mb-0 gray_5a font_12 text-break ">
                              {specificPost?.doc_title}
                            </p>
                          </div>
                        ) : specificPost?.module_type === "news" ? (
                          <div className="tetweet_quote_text">
                            <p
                              class="mb-0 gray_5a font_12 text-break "
                              dangerouslySetInnerHTML={{
                                __html: specificPost?.description,
                              }}
                            ></p>
                          </div>
                        ) : (
                          <div className="tetweet_quote_text">
                            <p class="mb-0 gray_5a font_12 text-break ">
                              {specificPost?.description}
                            </p>
                          </div>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="insert_images">
                <label
                  style={
                    disablePhotoInputForQuotePost === true ||
                    filesForRetweetQuote?.length === 4 ||
                    selectedFileForRetweetQuote?.length === 4 ||
                    heightWidthForQuotePost?.length === 4
                      ? {}
                      : { cursor: "pointer" }
                  }
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileInputForRetweetQuotes}
                    disabled={
                      disablePhotoInputForQuotePost === true ||
                      filesForRetweetQuote?.length === 4 ||
                      selectedFileForRetweetQuote?.length === 4 ||
                      heightWidthForQuotePost?.length === 4
                    }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setEmojiPickerForQuotePost(!emojiPickerForQuotePost)
                  }
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid mx-3"
                    alt="gallery"
                  />
                </a>
              </div>
              <button
                disabled={!quotePost.trim() && !filesForRetweetQuote.length}
                onClick={() => {
                  uploadImagesForRetweetQuotes();
                }}
                style={{
                  cursor:
                    !quotePost.trim() && !filesForRetweetQuote.length
                      ? ""
                      : "pointer",
                }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </div>
            {emojiPickerForQuotePost ? (
              <div className="mx-5 mt-0 mb-3">
                <EmojiPicker
                  onEmojiClick={(e) => setQuotePost(quotePost + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Post Comment */}
      <div
        className="modal fade"
        id="commentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={specificPost?.profile_image}
                              className="inner_post"
                            />{" "}
                            {specificPost?.name}
                          </a>
                          @{specificPost?.username}
                          <p>
                            {specificPost?.insertdate ? (
                              <ReactTimeAgo
                                date={specificPost?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {specificPost?.module_type === "document" ? (
                          <>
                            <label>{specificPost?.doc_title} </label>
                          </>
                        ) : (
                          <></>
                        )}
                        {specificPost?.module_type === "document" ? (
                          <>
                            <label>{specificPost?.media[0]?.name}</label>
                          </>
                        ) : (
                          <></>
                        )}
                        {specificPost?.post_type === "retweet" ? (
                          <label>
                            {specificPost?.shared_feed?.description}
                          </label>
                        ) : specificPost?.post_type === "retweet_with_quote" ? (
                          <>
                            <label>{specificPost?.description}</label>
                            {specificPost?.media?.length === 0 ? (
                              <></>
                            ) : (
                              <label>
                                {specificPost?.media?.map((i) => {
                                  return <>{i?.name}</>;
                                })}
                              </label>
                            )}
                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link
                                  to={`/${specificPost?.shared_feed?.user_id}`}
                                  className="post_person_name"
                                >
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        specificPost?.shared_feed?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {specificPost?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{specificPost?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {specificPost?.shared_feed?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          specificPost?.shared_feed?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {specificPost?.shared_feed?.media?.length ===
                                0 ? (
                                  <></>
                                ) : (
                                  <div className="tetweet_quote_image">
                                    {specificPost?.shared_feed?.media[0]
                                      ?.type == "video" ? (
                                      <img
                                        src={
                                          specificPost?.shared_feed?.media[0]
                                            ?.thumb
                                        }
                                        alt="post"
                                      />
                                    ) : (
                                      <img
                                        src={
                                          specificPost?.shared_feed?.media[0]
                                            ?.name
                                        }
                                        alt="post"
                                      />
                                    )}
                                  </div>
                                )}
                                <div className="tetweet_quote_text">
                                  <p className="mb-0 gray_5a font_12 text-break ">
                                    {specificPost?.shared_feed?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : specificPost?.module_type === "document" ? (
                          <label>{specificPost?.doc_title}</label>
                        ) : specificPost?.module_type === "news" ? (
                          <>
                            <label
                              dangerouslySetInnerHTML={{
                                __html: specificPost?.description,
                              }}
                            ></label>
                            <label>{specificPost?.media[0]?.name}</label>
                          </>
                        ) : (
                          <>
                            {specificPost?.media?.length > 0 && (
                              <label>{specificPost?.media[0]?.name}</label>
                            )}
                            <label>{specificPost?.description}</label>
                          </>
                        )}

                        <br />
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          disabled={filesForComments?.length !== 0}
                          value={postComment}
                          onChange={(e) => {
                            setPostComment(e?.target?.value);
                            setEmojiPickerForPostComment(false);
                          }}
                          placeholder="Post your comment..!!"
                        />
                      </div>
                      <div className="uploade_images">
                        {filesForComments?.length !== 0 ? (
                          filesForComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                console.log("Line 838", item);
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/x-circle.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div className="images_tab_1">
                                    <video
                                      controls
                                      muted
                                      loop
                                      style={{ height: "200px" }}
                                    >
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/x-circle.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="insert_images">
                {postComment.length == 0 && (
                  <label
                    style={
                      disablePhotoInputForPostComments === true ||
                      filesForComments?.length === 4 ||
                      selectedFileForComments?.length === 4 ||
                      heightWidthForPostComments?.length === 4 ||
                      postComment.length
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      multiple
                      id="fileInput"
                      accept=".jpg, .jpeg, .png"
                      onChange={handleFileInputForPostComments}
                      disabled={
                        disablePhotoInputForPostComments === true ||
                        filesForComments?.length === 4 ||
                        selectedFileForComments?.length === 4 ||
                        heightWidthForPostComments?.length === 4 ||
                        postComment?.length
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/gallery.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                )}
                {!filesForComments?.length && (
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      // setEmojiPickerForQuotePost(!emojiPickerForQuotePost)
                      setEmojiPickerForPostComment(!emojiPickerForPostComment)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid mx-3"
                      alt="gallery"
                    />
                  </a>
                )}
              </div>
              <button
                disabled={!postComment.trim() && !filesForComments?.length}
                onClick={() => {
                  uploadImagesInComments();
                }}
                // style={{ cursor: "pointer" }}
                style={{
                  cursor:
                    !postComment.trim() && !filesForComments?.length
                      ? ""
                      : "pointer",
                }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                COMMENT
              </button>
            </div>
            {emojiPickerForPostComment ? (
              <div className="mx-5 mt-0 mb-2">
                <EmojiPicker
                  onEmojiClick={(e) => setPostComment(postComment + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Sub Commment */}
      <div
        className="modal fade"
        id="subCommentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={singleComment?.profile_image}
                              className="inner_post"
                            />{" "}
                            {singleComment?.name}
                          </a>
                          @{singleComment?.username}
                          <p>
                            {singleComment?.insertdate ? (
                              <ReactTimeAgo
                                date={singleComment?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {singleComment?.post_type === "retweet" ? (
                          <label>
                            {singleComment?.shared_feed?.description}
                          </label>
                        ) : singleComment?.post_type ===
                          "retweet_with_quote" ? (
                          <>
                            <label>{singleComment?.description}</label>

                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link
                                  to={`/${singleComment?.shared_feed?.user_id}`}
                                  className="post_person_name"
                                >
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        singleComment?.shared_feed
                                          ?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {singleComment?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{singleComment?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {singleComment?.shared_feed?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          singleComment?.shared_feed?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {singleComment?.shared_feed?.media?.length ===
                                0 ? (
                                  <></>
                                ) : (
                                  <div className="tetweet_quote_image"></div>
                                )}
                                <div className="tetweet_quote_text">
                                  <p className="mb-0 gray_5a font_12 text-break ">
                                    {singleComment?.shared_feed?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <label>{singleComment?.description}</label>
                        )}

                        <br />
                        {singleComment?.media?.length === 0 ? (
                          <></>
                        ) : (
                          <label>
                            {singleComment?.media?.map((i) => {
                              return <>{i?.name} </>;
                            })}
                          </label>
                        )}
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          value={postSubComment}
                          onChange={(e) => {
                            setPostSubComment(e?.target?.value);
                            setEmojiPickerForSubComment(false);
                          }}
                          placeholder="Post your comment..!!"
                        />
                      </div>
                      <div className="uploade_images">
                        {filesForSubComments?.length !== 0 ? (
                          filesForSubComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForSubComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div className="images_tab_1">
                                    <video
                                      controls
                                      muted
                                      loop
                                      style={{ height: "200px" }}
                                    >
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForSubComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="insert_images align-items-center d-flex m-4">
              {postComment.length == 0 && (
                <label
                  style={
                    disablePhotoInputForSubComments === true ||
                    filesForSubComments?.length === 4 ||
                    selectedFilesForSubComments?.length === 4 ||
                    heightWidthForSubComments?.length === 4
                      ? {}
                      : { cursor: "pointer" }
                  }
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpeg, .jpg"
                    id="imageForPosts"
                    onChange={handleFileInputForSubComments}
                    disabled={
                      disablePhotoInputForSubComments === true ||
                      filesForSubComments?.length === 4 ||
                      selectedFilesForSubComments?.length === 4 ||
                      heightWidthForSubComments?.length === 4
                    }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
              )}
              <div
                className="ms-2"
                // onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
              >
                <label style={{ cursor: "pointer" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
              </div>

              <div className="sania_btn_2 ms-auto">
                <button
                  disabled={
                    !postSubComment.trim() && !filesForSubComments?.length
                  }
                  // disabled={true}
                  onClick={() => {
                    uploadImagesForSubComments();
                  }}
                  // className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="blue_button d-flex align-items-center font_16 font_500"
                >
                  COMMENT
                </button>
              </div>
            </div>
            {emojiPickerForSubComment ? (
              <div className="mx-5 mb-3 mt-0">
                <EmojiPicker
                  onEmojiClick={(e) =>
                    setPostSubComment(postSubComment + e?.emoji)
                  }
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Delete Post */}

      <PostMenu
        postPermission={postPermission}
        setPostPermission={setPostPermission}
        singlePost={specificPost}
        isRedirect={true}
        // feeds={feeds}
        // setFeeds={setFeeds}
      />

      {/* Below is for comment */}
      <PostMenu
        postPermission={postPermissionComment}
        setPostPermission={setPostPermissionComment}
        singlePost={singlePost}
        commentList={commentList}
        setCommentList={setCommentList}
        // isRedirect={true}
        isComment={true}
        // feeds={feeds}
        // setFeeds={setFeeds}
      />

      {/* Delete Comment */}
      <Modal
        open={commentPermission}
        onClose={() => {
          setCommentPermission(false);
        }}
        aria-labelledby="modal-modal-title"
        aria-describedby="modal-modal-description"
      >
        <Box sx={customStyles}>
          <ul
            className="requested_user_ul"
            aria-labelledby="dropdownMenuButton1"
          >
            <li>
              <div
                style={{ cursor: "pointer" }}
                onClick={() => {
                  // deleteComment(specificComment);
                }}
              >
                {" "}
                Delete Comment{" "}
              </div>
            </li>
          </ul>
        </Box>
      </Modal>

      <section className="post_detail my-3">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 col-lg-7 col-md-7 col-sm-10 mx-auto ">
              <>
                {specificPost?.post_type == "normal" ? (
                  <NormalPostType
                    retweet={retweet}
                    undoRetweet={undoRetweet}
                    // singlePost={singlePost}
                    setSinglePost={() => {}}
                    setPostPermission={setPostPermission}
                    likeUnlike={likeUnlike}
                    handleShare={handleShare}
                    item={specificPost}
                    deleteComment={deleteComment}
                    handleSavePost={handleSavePost}
                    isDetailPage={true}
                    // key={key}
                  />
                ) : specificPost?.post_type == "retweet" ||
                  specificPost?.post_type == "retweet_comments" ? (
                  <RetweetPostType
                    retweet={retweet}
                    undoRetweet={undoRetweet}
                    setPostPermission={setPostPermission}
                    // singlePost={singlePost}
                    setSinglePost={() => {}}
                    likeUnlike={likeUnlike}
                    handleShare={handleShare}
                    item={specificPost}
                    deleteComment={deleteComment}
                    handleSavePost={handleSavePost}
                    isDetailPage={true}

                    // key={key}
                  />
                ) : specificPost?.post_type == "retweet_with_quote" ||
                  specificPost?.post_type == "retweet_comments_with_quote" ? (
                  <RetweetWithQuote
                    retweet={retweet}
                    undoRetweet={undoRetweet}
                    setPostPermission={setPostPermission}
                    // singlePost={singlePost}
                    setSinglePost={() => {}}
                    likeUnlike={likeUnlike}
                    handleShare={handleShare}
                    item={specificPost}
                    deleteComment={deleteComment}
                    handleSavePost={handleSavePost}
                    isDetailPage={true}
                    // key={key}
                  />
                ) : specificPost?.post_type == "reels" ? (
                  <ReelsPostType
                    retweet={retweet}
                    undoRetweet={undoRetweet}
                    setPostPermission={setPostPermission}
                    // singlePost={singlePost}
                    setSinglePost={() => {}}
                    likeUnlike={likeUnlike}
                    handleShare={handleShare}
                    item={specificPost}
                    isSinglePostView={true}
                    deleteComment={deleteComment}
                    isDetailPage={true}
                    handleSavePost={handleSavePost}
                    interaction={interaction}
                    // key={key}
                  />
                ) : (
                  <>
                    <div className="all_comment_listing">
                      <div className="comment_text_maain">
                        <div className="grey_section_comment">
                          <div className="user_time_name_option"></div>
                          <p>This content was deleted by the Content author</p>
                        </div>
                      </div>
                    </div>
                  </>
                )}
              </>
              <div className="all_comment_list">
                <ul className="comment_scroll">
                  <InfiniteScroll
                    dataLength={commentList?.length}
                    next={() => {
                      setPageToken(pageToken + 1);
                    }}
                    hasMore={isFetching}
                    scrollableTarget="comment_list"
                    // initialScrollY={0}
                    // scrollThreshold="500px"
                    // inverse={true}
                  >
                    {commentList?.map((item) => {
                      return (
                        <>
                          {specificPost?.post_type == "reels" ? (
                            <ReelComments
                              retweet={retweetComment}
                              deleteComment={deleteComment}
                              undoRetweet={undoRetweet}
                              setSingleSubComment={setSingleComment}
                              likeUnlike={commentLikeUnlike}
                              item={item}
                              setPostPermission={setPostPermissionComment}
                              setSinglePost={setSinglePost}
                            />
                          ) : (
                            <Comments
                              retweet={retweetComment}
                              undoRetweet={undoRetweet}
                              setSingleSubComment={setSingleComment}
                              likeUnlike={commentLikeUnlike}
                              item={item}
                              setPostPermission={setPostPermissionComment}
                              setSinglePost={setSinglePost}
                            />
                          )}
                        </>
                      );
                    })}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
