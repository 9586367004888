import React, { useCallback, useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { useNavigate } from "react-router-dom";
import { CometChat } from "@cometchat/chat-sdk-javascript";
// import { CometChat } from "@cometchat-pro/chat";
// import { GroupListManager } from "../../cometchat-pro-react-ui-kit/CometChatWorkspace/src/components/Groups/CometChatGroupList/controller";

export default function GroupList() {
  const [data, setData] = useState(null);
  const navigate = useNavigate();
  const authKey = process.env.REACT_APP_CHAT_AUTH_KEY;
  const uid = localStorage?.getItem("_id");
  // useEffect(() => {
  //   const groupListManager = new GroupListManager();

  //   if (authKey && uid) {
  //     groupListManager.fetchNextGroups().then(
  //       async (groupList) => {
  //         console.log("groupList: ", groupList);
  //         const groupWithMembers = await Promise.all(
  //           groupList?.map(async (item) => {
  //             if (item.hasJoined) {
  //               let groupMemberRequest =
  //                 new CometChat.GroupMembersRequestBuilder(item.guid)
  //                   .setLimit(5)
  //                   .build();
  //               item.memebers = await groupMemberRequest.fetchNext();
  //             }
  //             return item;
  //           })
  //         );
  //         setData(groupWithMembers);
  //       },
  //       (error) => {
  //         // Handle errors
  //         console.error("Group List Error:", error);
  //       }
  //     );
  //   }
  // }, [authKey, uid]);

  useEffect(() => {
    let limit = 30;
    let groupsRequest = new CometChat.GroupsRequestBuilder()
      .setLimit(limit)
      .build();
    groupsRequest.fetchNext().then(
      // (groupList) => {
      //   console.log("Groups list fetched successfully", groupList);
      async (groupList) => {
        console.log("groupList: ", groupList);
        const groupWithMembers = await Promise.all(
          groupList?.map(async (item) => {
            if (item.hasJoined) {
              let groupMemberRequest = new CometChat.GroupMembersRequestBuilder(
                item.guid
              )
                .setLimit(5)
                .build();
              item.memebers = await groupMemberRequest.fetchNext();
            }
            return item;
          })
        );
        setData(groupWithMembers);
        // }
      },
      (error) => {
        console.log("Groups list fetching failed with error", error);
      }
    );
  }, []);

  const navigateToGroupChat = useCallback(
    (item) => {
      !item.hasJoined && CometChat.joinGroup(item.guid);
      navigate({ pathname: "/chat-room" }, { state: { guid: item } });
    },
    [navigate]
  );
  const leaveGroup = useCallback(
    (item) => {
      navigate({ pathname: "/chat-room" }, { state: item });
      CometChat.leaveGroup(item?.guid).then(
        (hasLeft) => {
          console.log("Group left successfully:", hasLeft);
        },
        (error) => {
          console.log("Group leaving failed with exception:", error);
        }
      );
    },
    [navigate]
  );

  if (data == null) {
    return <></>;
  }
  console.log("data: ", data);
  return (
    <>
      <main class="stulink">
        {localStorage?.getItem("isLoggedIn") ||
        localStorage?.getItem("isSignedup") ? (
          <HeaderProfile />
        ) : (
          <Header />
        )}
        <section className="one_college">
          <div className="container change_size">
            <div className="row">
              <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12 mb-3">
                <div className="message_title">
                  <h5>ROOMS</h5>
                </div>
              </div>
            </div>
            <div className="row">
              {data &&
                data.map((item, index) => {
                  return (
                    <div
                      className="col-xl-4 col-lg-6 col-md-6 col-sm-6 col-12 mb-4"
                      key={index}
                    >
                      <div className="group_main">
                        <div className="group_header">
                          <div
                            className="group_logo"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigateToGroupChat(item)}
                          >
                            <img alt="" className="w-100" src={item?.icon} />
                          </div>
                          <div
                            className="group_text"
                            style={{ cursor: "pointer" }}
                            onClick={() => navigateToGroupChat(item)}
                          >
                            <span>{item.membersCount} memebers</span>
                            <h4>{item.name}</h4>
                          </div>
                          <div className="group_span">
                            <span>Available</span>
                            {item.hasJoined ? (
                              <button
                                onClick={() => leaveGroup(item)}
                                className="btn btn-danger"
                              >
                                Leave
                              </button>
                            ) : (
                              <button
                                onClick={() => navigateToGroupChat(item)}
                                className="btn btn-primary"
                              >
                                Join
                              </button>
                            )}
                          </div>
                        </div>
                        {item.hasJoined && (
                          <div className="group_iser_list">
                            <ul>
                              {item?.memebers?.length < 4 ? (
                                item?.memebers?.map((val) => {
                                  return (
                                    <li>
                                      <div
                                        className="user_div_chat_list online-user"
                                        title={val.name}
                                      >
                                        <img
                                          src={val?.avatar}
                                          alt=""
                                          className="rounded-circle"
                                        />
                                        <span className="onlinegrup" />
                                      </div>
                                    </li>
                                  );
                                })
                              ) : (
                                <>
                                  {item?.memebers?.slice(0, 4)?.map((val) => {
                                    return (
                                      <li>
                                        <div
                                          className="user_div_chat_list online-user"
                                          title={val.name}
                                        >
                                          <img
                                            src={val?.avatar}
                                            alt=""
                                            className="rounded-circle"
                                          />
                                          <span className="onlinegrup" />
                                        </div>
                                      </li>
                                    );
                                  })}
                                  <li>
                                    <div className="user_div_chat_list number_of_4">
                                      <span className="">
                                        +{item?.membersCount - 4}
                                      </span>
                                    </div>
                                  </li>
                                </>
                              )}

                              {/* <li>
                              <div className="user_div_chat_list online-user">
                                <img src="assets/images/profile/profile-img.png" />
                                <span className="onlinegrup" />
                              </div>
                            </li>
                            <li>
                              <div className="user_div_chat_list away">
                                <img src="assets/images/profile/profile-img.png" />
                                <span className="onlinegrup" />
                              </div>
                            </li>
                            <li>
                              <div className="user_div_chat_list offline">
                                <img src="assets/images/profile/profile-img.png" />
                                <span className="onlinegrup" />
                              </div>
                            </li>
                            <li>
                              <div className="user_div_chat_list number_of_4">
                                <span className="">+4</span>
                              </div>
                            </li> */}
                            </ul>
                          </div>
                        )}
                      </div>
                    </div>
                  );
                })}
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
