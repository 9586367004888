import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import {
  single_job_detail,
  detail_type_jobs,
  // faq_list,
} from "../../api/apiHandler";
// import moment from "moment";
import { useLocation, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import LocationWiseJob from "../side-components/LocationWiseJob";

export default function JobDetails() {
  const navigate = useNavigate();
  const location = useLocation();
  const [jobData, setJobData] = useState(null);
  console.log("jobData: ", jobData);
  // const [isStateList, setIsStateList] = useState(null);
  // console.log("isStateList: ", isStateList);
  const [isQualificationList, setIsQualificationList] = useState(null);
  console.log("isQualificationList: ", isQualificationList);
  // const [isFAQList, setIsFAQList] = useState(null);

  useEffect(() => {
    single_job_detail({ id: location.state }).then((response) => {
      if (response.code == 1) {
        setJobData(response.data);
      }
    });

    detail_type_jobs({}).then((response) => {
      if (response.code == 1) {
        // setIsStateList(response.data[0].job_state_cities_result);
        setIsQualificationList(response.data[1].designation_wise_result);
      }
    });

    // faq_list({}).then((response) => {
    //     console.log("0dgkhdfskilgfdhsjdbghd2d65etgdhsuf23", response)
    //     if(response.code == 1){
    //         setIsFAQList(response.data)
    //     }
    //     else{
    //         setIsFAQList("")
    //     }
    // });
  }, [location.state]);

  if (jobData == null) {
    return <></>;
  }
  if (isQualificationList == null) {
    return <></>;
  }
  // if (isStateList == null) {
  //   return <></>;
  // }
  // if (isFAQList == null){return <></>}

  // const currentDate = moment();
  // const daysDifference = currentDate.diff(companyData.created_at, 'days');
  const htmlString = jobData.about;
  const htmlDescString = jobData.description;

  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
        localStorage?.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <main className="stulink">
        <section className="home_question_list ">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="manger_detail mb-3">
                  <div className="company_logo_detail">
                    <div className="c_logo">
                      <img src={jobData.image_video} alt="" />
                    </div>
                    <div className="c_text_desc">
                      <h1>{jobData.title}</h1>
                      <p>{jobData.designation}</p>
                      <p>{jobData.location}</p>
                      <span>Apply Online</span>
                      <p>
                        <b className="pe-1">
                          {jobData.min_year
                            ? jobData.min_year + " Years - "
                            : ""}
                          {jobData.max_year + " Years "}
                        </b>
                        Experience required
                      </p>
                      <p>
                        Posted{" "}
                        <ReactTimeAgo
                          date={jobData.created_at ? jobData.created_at : ""}
                          locale="en-US"
                        />
                      </p>
                    </div>
                  </div>
                  <div className="right_s_option">
                    <div className="right_link_job">
                      <button
                        className=" blue_apply_bg orange_btn white_ff font_14 inter font_600"
                        onClick={() =>
                          navigate(`/apply-now/jobs/${jobData._id}`, { state: { logo: jobData.image_video, name: jobData.title, path: '/job-details', pathState: location?.state } })
                        }
                      >
                        <img src="assets/video/apply.svg" alt="" /> Apply Now
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <section className="home_question_list section_shdow">
          <div className="container">
            <div className="row">
              <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3 mb-md-0">
                {/* <div className="row">
                                <div className="col-12 mb-3">
                                    <div className="job_wise_detail">
                                    <div className="job_nav">
                                        <h4>
                                        <img
                                            src="assets/video/navigation.svg"
                                            className="me-2"
                                        />
                                        Jobs <span>Navigation</span>
                                        </h4>
                                    </div>
                                    <div className="list_job_wise_1">
                                        <ul>
                                        <li>
                                            <img src="assets/video/dates.svg" />
                                            Important Dates
                                        </li>
                                        <li>
                                            <img src="assets/video/money.svg" />
                                            Application fee
                                        </li>
                                        <li>
                                            <img src="assets/video/dates.svg" />
                                            Age Limit
                                        </li>
                                        <li>
                                            <img src="assets/video/money.svg" />
                                            Qualification
                                        </li>
                                        <li>
                                            <img src="assets/video/dates.svg" />
                                            Vacancy Details
                                        </li>
                                        <li>
                                            <img src="assets/video/money.svg" />
                                            How to Apply
                                        </li>
                                        </ul>
                                    </div>
                                    </div>
                                </div>
                                </div> */}
              </div>
              <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  <div className="col-12 mb-3">
                    <div className="main-detail_job_sceen">
                      <h4>
                        {" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/video/age_limit.svg"
                          }
                          className="me-2"
                          alt=""
                        />{" "}
                        Description{" "}
                      </h4>
                      {jobData.description != "" ? (
                        <div
                          dangerouslySetInnerHTML={{ __html: htmlDescString }}
                        />
                      ) : (
                        "There is no more Details related to Company"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="main-detail_job_sceen">
                    <h4>
                      {" "}
                      <img
                        src="assets/video/important_dates.svg"
                        className="me-2"
                        alt=""
                      />{" "}
                      About
                    </h4>
                    <div className="icon-main">
                      {jobData.about != "" ? (
                        <div dangerouslySetInnerHTML={{ __html: htmlString }} />
                      ) : (
                        "There is no more Details related to Company"
                      )}
                    </div>
                  </div>
                </div>
                <div className="col-12 mb-3">
                  <div className="main-detail_job_sceen">
                    <h4>
                      {" "}
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/video/application_fees.svg"
                        }
                        alt=""
                        className="me-2"
                      />{" "}
                      More Details{" "}
                    </h4>
                    <div className="icon-main">
                      <ul>
                        <li>
                          <p>Salary </p>
                          <a href="#" className="btn_appl_now">
                            {jobData.min_salary} to{" "}
                            {jobData.max_salary != ""
                              ? jobData.max_salary
                              : "As per Knowledge"}
                          </a>
                        </li>
                        <li>
                          <p>Location </p>
                          <span className="btn_appl_now">
                            {jobData.location != ""
                              ? jobData.location
                              : `${jobData.city}, ${jobData.state}`}
                          </span>
                        </li>
                      </ul>
                    </div>
                  </div>
                </div>
                {/* <div className="col-12 mb-3">
                                        <div className="main-detail_job_sceen">
                                            <h4> <img src={process.env.PUBLIC_URL + "/assets/video/age_limit.svg"} className="me-2" /> FAQs </h4>
                                            <div className="accordion bottom_accordian" id="accordionExample">
                                                {isFAQList && isFAQList.map((item, index) => {
                                                    return(
                                                        <div className="accordion-item">
                                                            <h2 className="accordion-header" id="headingOne">
                                                            <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                                {index+1}. {item.question}
                                                            </button>
                                                            </h2>
                                                            <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                                <div className="accordion-body">
                                                                    {item.answer}
                                                                </div>
                                                            </div>
                                                        </div>
                                                    )
                                                })}
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingOne">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseOne" aria-expanded="false" aria-controls="collapseOne">
                                                        1. Does IIT have management quota?
                                                    </button>
                                                    </h2>
                                                    <div id="collapseOne" className="accordion-collapse collapse" aria-labelledby="headingOne" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            No, there is no management quota at IIT. Students are selected either on the basis of their performance in entrance exams or through direct admission based on their qualifying marks.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingTwo">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseTwo" aria-expanded="false" aria-controls="collapseTwo">
                                                    2.  Which extracurricular activities are provided for students at IIT?
                                                    </button>
                                                    </h2>
                                                    <div id="collapseTwo" className="accordion-collapse collapse" aria-labelledby="headingTwo" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            No, there is no management quota at IIT. Students are selected either on the basis of their performance in entrance exams or through direct admission based on their qualifying marks.
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="accordion-item">
                                                    <h2 className="accordion-header" id="headingThree">
                                                    <button className="accordion-button collapsed" type="button" data-bs-toggle="collapse" data-bs-target="#collapseThree" aria-expanded="false" aria-controls="collapseThree">
                                                    3.  What are the rankings of IIT?
                                                    </button>
                                                    </h2>
                                                    <div id="collapseThree" className="accordion-collapse collapse" aria-labelledby="headingThree" data-bs-parent="#accordionExample">
                                                        <div className="accordion-body">
                                                            No, there is no management quota at IIT. Students are selected either on the basis of their performance in entrance exams or through direct admission based on their qualifying marks.
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
              </div>
              <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12 mb-3 mb-md-0">
                <div className="row">
                  {/* <div className="col-12 mb-3">
                                        <div className="job_wise_detail">
                                            <div className="job_wise_list_1">
                                                <h4>
                                                <img src="assets/video/job.svg" />
                                                Location-wise Jobs
                                                </h4>
                                            </div>
                                            <div className="list_job_wise">
                                                <ul>
                                                    {isStateList && isStateList.map((item, index) => {
                                                        return(
                                                            <li>
                                                                <div className="map_img">
                                                                    <img src={ process.env.PUBLIC_URL + "/assets/video/map.svg" } />
                                                                </div>
                                                                <div className="map_text">
                                                                    <span>
                                                                        <img src={ process.env.PUBLIC_URL + "/assets/video/map-pin.svg" } />
                                                                        {item.state}
                                                                    </span>
                                                                    <h6>{item.vacancies} Vacancies</h6>
                                                                </div>
                                                            </li>
                                                        )
                                                    })}
                                                </ul>
                                            </div>
                                        </div>
                                    </div> */}
                  <LocationWiseJob />

                  <div className="col-12">
                    <div className="job_wise_detail">
                      <div className="job_wise_list_1">
                        <h4>
                          <img src="assets/video/job.svg" />
                          Qualification-wise jobs
                        </h4>
                      </div>
                      <div className="list_job_wise">
                        <ul>
                          {isQualificationList &&
                            isQualificationList.map((item, index) => {
                              return (
                                <li>
                                  <div className="map_img">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/video/edu_cap.svg"
                                      }
                                    />
                                  </div>
                                  <div className="map_text">
                                    <h6 className="mb-0">{item.name}</h6>
                                    <p className="mb-0">
                                      {item.vacancies} Vacancies
                                    </p>
                                    <span>
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/video/map-pin.svg"
                                        }
                                        className="me-2"
                                      />
                                      All India
                                    </span>
                                  </div>
                                </li>
                              );
                            })}
                        </ul>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
      </main>
    </>
  );
}
