import React, { useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { post_details } from "../../api/apiHandler";
import { useParams } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
// import BestColleges from "../../common/BestColleges";
import BestCourses from "../../common/BestCourses";

export default function NewsDetails() {
  const [newsDetail, setNewsDetail] = useState();
  const params = useParams();
  useEffect(() => {
    post_details({ post_id: params?.id }).then((response) => {
      console.log("News Detail", response);
      if (response?.code === 1 || response?.code === "1") {
        setNewsDetail(response?.data);
      }
    });
  }, [params?.id]);
  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
      localStorage?.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="main_news">
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <div className="main_box_ahdow">
                <div className="row">
                  <div className="col-md-12 col-12 mb-3">
                    <div className="news_main flex-column border-0 p-0">
                      {newsDetail?.media?.map((item) => {
                        return (
                          <>
                            <div className="news_img_detail" key={item?._id}>
                              <img src={item?.name} alt="" />
                            </div>
                          </>
                        );
                      })}
                      <div className="new_detail mt-3">
                        <h5 className="">{newsDetail?.title}</h5>
                        <div className="news_option my-2">
                          <span className="blue_text me-3">
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/male.svg"
                              }
                            />
                            By {newsDetail?.name}
                          </span>
                          <span>
                            <img
                              alt=""
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/video/planner.svg"
                              }
                            />
                            {newsDetail?.insertdate ? (
                              <ReactTimeAgo
                                date={newsDetail?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}{" "}
                          </span>
                        </div>
                        <div
                        className="ck-content"
                          dangerouslySetInnerHTML={{
                            __html: newsDetail?.description,
                          }}
                        ></div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            {/*---------side bar news --------*/}
            <BestCourses />
          </div>
        </div>
      </section>
    </>
  );
}
