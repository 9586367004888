import React from "react";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import OwlCarousel from "react-owl-carousel";
import { RenderTextWithHashtags } from "../HomeFeed";
import PostLikeComment from "../components/PostLikeComment";
import PostUserDetails from "../components/PostUserDetails";

const NormalPostType = (props) => {
  const {
    item,
    key,
    likeUnlike,
    setSinglePost,
    singlePost,
    retweet,
    handleShare,
    setPostPermission,
    undoRetweet,
    isComment = false,
    handleSavePost,
    isDetailPage = false,
  } = props;
  const navigate = useNavigate();
  console.log("item: ", item);

  const options = {
    items: 1, // Number of items to display in the carousel
    loop: true,
    margin: 10,
    autoplay: false,
  };

  const findWidth = (originalHeight, newHeight, originalWidth) => {
    return (newHeight * originalWidth) / originalHeight;
  };

  const isPortrait = (h, w) => {
    return h > w;
  };
  return (
    <>
      {item?.module_type == "document" ? (
        <div className="col-12 mb-3" key={item?._id}>
          {console.log("item: ", item)}
          <div className="home_post_main content_orient">
            <div className="top_hader">
              <PostUserDetails
                setPostPermission={setPostPermission}
                setSinglePost={setSinglePost}
                item={item}
              />
              <div
                class="content_base post_cpntent new_post_content"
                onClick={(e) => {
                  e.stopPropagation();
                  isComment
                    ? navigate(`/comments`, {
                        state: { comment: item },
                      })
                    : navigate(`/post/${item?._id}`);
                }}
              >
                <p className="text-break ">
                  {item?.description?.length > 0 && (
                    <b className="me-2">
                      <Link
                        to={"/profile"}
                        state={item?.user_id}
                        className="text-dark"
                      >
                        {item?.name}
                      </Link>
                    </b>
                  )}
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                  {/* {RenderTextWithHashtags({
                    text:,
                    showInDetails: isDetailPage,
                  })} */}
                </p>
                <div
                  className="post_document"
                  style={{
                    cursor: "pointer",
                    marginTop: "0px",
                    marginBottom: "15px",
                  }}
                  onClick={(e) => {
                    e.stopPropagation();
                    isComment
                      ? navigate(`/comments`, {
                          state: { comment: item },
                        })
                      : navigate(`/post/${item?._id}`);
                  }}
                >
                  <div className="row">
                    <div className="col-9">
                      {item?.doc_title?.length > 0 ? (
                        <h5 className="font_500 font_16 black_14">
                          {item?.doc_title}{" "}
                        </h5>
                      ) : (
                        <></>
                      )}
                      <div className="post_document_english">
                        <p
                          className="font_12 gray_5a inter d-flex align-items-center mb-0"
                          onClick={(e) => {
                            e.stopPropagation();
                            console.log("Hello 123");
                            // navigate('/college-info', { state: item.college_id })
                            navigate("/colleges");
                          }}
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/university.svg"
                            }
                            className="img-fluid me-2 width-20px"
                            alt="college"
                          />
                          {item?.doc_college_name}
                        </p>
                        <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/small-calendar.svg"
                            }
                            className="img-fluid me-2"
                            alt="college"
                          />
                          {item?.doc_session}
                        </p>
                      </div>
                      <div className="post_document_english">
                        {item?.doc_degree?.length > 0 ? (
                          <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/degree-cap.svg"
                              }
                              className="img-fluid me-2"
                              alt="college"
                            />
                            {item?.doc_degree}
                          </p>
                        ) : (
                          <></>
                        )}
                        <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/english-book.svg"
                            }
                            className="img-fluid me-2"
                            alt="college"
                          />
                          {item?.doc_subject}
                        </p>
                      </div>
                      <button
                        style={{ cursor: "pointer" }}
                        target="_blank"
                        download={true}
                        className="edit_p_button d-inline-block download_btn"
                        onClick={() => {
                          // saveAs(
                          //   item?.media[0]?.name,
                          //   `${item?.media[0]?.name?.split("/")[6]}`
                          // );
                        }}
                      >
                        {" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/download-white.svg"
                          }
                          className="img-fluid "
                          alt="edit"
                          // onClick={(e) => {
                          //   e.stopPropagation();
                          //   navigate(item?.media[0]?.name);
                          // }}
                        />{" "}
                        <a
                          className=""
                          href={item?.media[0]?.name}
                          target="_blank"
                        >
                          Download
                        </a>
                      </button>
                    </div>
                    <div className="col-3">
                      <a
                        href={item?.media[0]?.thumb}
                        target="_blank"
                        className="post_document_image"
                        rel="noreferrer"
                      >
                        <img
                          src={item?.media[0]?.thumb}
                          className="img-fluid"
                          alt="document"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                      className="me-2"
                      alt=""
                    />
                    {item?.comment_count} comments
                  </a>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleShare={handleShare}
                handleSavePost={handleSavePost}
                item={item}
                isComment={isComment}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      ) : item?.module_type == "news" ? (
        <div className="col-12 mb-3" key={item?._id}>
          <div
            className={`home_post_main mt-0 ${
              !item?.media?.length ? "content_orient" : ""
            }`}
          >
            <div className="top_hader">
              <PostUserDetails
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                item={item}
              />
              {item?.media?.length === 1 ? (
                item?.media?.map((i, k) => {
                  return (
                    <img
                      src={i?.name}
                      onClick={() => {
                        isComment
                          ? navigate(`/comments`, {
                              state: { comment: item },
                            })
                          : navigate(`/post/${item?._id}`);
                      }}
                      className="post_img"
                      key={i?.name}
                      alt=""
                    />
                  );
                })
              ) : item?.media?.length > 1 ? (
                <>
                  <div className="owl_slider">
                    <OwlCarousel
                      className="owl-carousel owl-theme owl_post"
                      center={true}
                      onClick={() => {
                        isComment
                          ? navigate(`/comments`, {
                              state: { comment: item },
                            })
                          : navigate(`/post/${item?._id}`);
                      }}
                      {...options}
                    >
                      {item?.media?.map((i, k) => {
                        return (
                          <div className="item" key={i?.name + k}>
                            <img
                              src={i?.name}
                              alt={i?.name}
                              className="post_img"
                            />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </>
              ) : (
                <></>
              )}
              <PostLikeComment
                retweet={retweet}
                singlePost={singlePost}
                undoRetweet={undoRetweet}
                handleShare={handleShare}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                item={item}
                handleSavePost={handleSavePost}
                disableTags={false}
                isComment={isComment}
                isDetailPage={isDetailPage}
              />
            </div>
            {item.media?.length > 0 && (
              <div
                className="post_cpntent"
                onClick={() => {
                  isComment
                    ? navigate(`/comments`, {
                        state: { comment: item },
                        replace: true,
                      })
                    : navigate(`/post/${item?._id}`);
                }}
              >
                {item?.description?.length > 0 && (
                  <p>
                    <b className="me-2">
                      <Link
                        to={"/profile"}
                        state={item?.user_id}
                        className="text-dark"
                      >
                        {item?.name}
                      </Link>
                    </b>
                    <RenderTextWithHashtags
                      text={item?.description || ""}
                      renderHtml={true}
                      showInDetails={isDetailPage}
                    />
                    {/* {RenderTextWithHashtags({
                      text: item?.description || "",
                      renderHtml: true,
                      showInDetails: isDetailPage,
                    })} */}
                  </p>
                )}
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                      alt=""
                      className="me-2"
                    />
                    {item?.comment_count} comments
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      ) : !item.media?.length ? (
        <div className="col-12 mb-3" key={item?._id}>
          <div className="home_post_main content_orient">
            <div className="top_hader">
              <PostUserDetails
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                item={item}
              />

              <div
                class="content_base post_cpntent"
                onClick={() => {
                  isComment
                    ? navigate(`/comments`, {
                        state: { comment: item },
                      })
                    : navigate(`/post/${item?._id}`);
                }}
              >
                <p className="text-break ">
                  {/* <b className="me-2">
                    <Link
                      to={"/profile"}
                      state={item?.user_id}
                      className="text-dark"
                    >
                      {item?.name}
                    </Link>
                  </b> */}
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                      className="me-2"
                      alt=""
                    />
                    {item?.comment_count} comments
                  </a>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                handleShare={handleShare}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleSavePost={handleSavePost}
                item={item}
                isComment={isComment}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      ) : item.media?.length == 1 ? (
        item.media[0].type == "video" ? (
          <div key={item?._id}>"Video View"</div>
        ) : (
          <div className="col-12 mb-3" key={item?._id}>
            <div
              className={`home_post_main mt-0 ${
                !item?.media?.length ? "content_orient" : ""
              } ${
                isPortrait(item?.media?.[0].height, item?.media?.[0].width)
                  ? "only_images"
                  : ""
              } `}
            >
              <div className="top_hader">
                <PostUserDetails
                  setSinglePost={setSinglePost}
                  setPostPermission={setPostPermission}
                  item={item}
                  isComment={isComment}
                />
                {item?.media?.length === 1 ? (
                  item?.media?.map((i, k) => {
                    return (
                      <img
                        key={i?.name + k}
                        src={i?.name}
                        onClick={() => {
                          isComment
                            ? navigate(`/comments`, {
                                state: { comment: item },
                              })
                            : navigate(`/post/${item?._id}`);
                        }}
                        className="post_img"
                        alt=""
                      />
                    );
                  })
                ) : item?.media?.length > 1 ? (
                  <>
                    <div className="owl_slider">
                      <OwlCarousel
                        className="owl-carousel owl-theme owl_post"
                        center={true}
                        onClick={() => {
                          isComment
                            ? navigate(`/comments`, {
                                state: { comment: item },
                              })
                            : navigate(`/post/${item?._id}`);
                        }}
                        {...options}
                      >
                        {item?.media?.map((i, k) => {
                          return (
                            <div className="item" key={i?.name + k}>
                              <img
                                key={i?.name + k}
                                src={i?.name}
                                alt={i?.name}
                                className="post_img"
                              />
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  </>
                ) : (
                  <></>
                )}
                <PostLikeComment
                  retweet={retweet}
                  singlePost={singlePost}
                  undoRetweet={undoRetweet}
                  handleShare={handleShare}
                  setSinglePost={setSinglePost}
                  likeUnlike={likeUnlike}
                  handleSavePost={handleSavePost}
                  item={item}
                  isComment={isComment}
                  isDetailPage={isDetailPage}
                />
              </div>
              {item.media?.length > 0 && (
                <div
                  className="post_cpntent"
                  onClick={() => {
                    isComment
                      ? navigate(`/comments`, {
                          state: { comment: item },
                          replace: true,
                        })
                      : navigate(`/post/${item?._id}`);
                  }}
                >
                  {item?.description?.length > 0 && (
                    <p>
                      <b className="me-2">
                        <Link
                          to={"/profile"}
                          state={item?.user_id}
                          className="text-dark"
                        >
                          {item?.name}
                        </Link>
                      </b>
                      <RenderTextWithHashtags
                        text={item?.description || ""}
                        showInDetails={isDetailPage}
                      />
                    </p>
                  )}
                  <div className="comment_button">
                    <a href="#" className="btn">
                      <img
                        src={
                          process.env.PUBLIC_URL + "assets/images/cooment.svg"
                        }
                        alt=""
                        className="me-2"
                      />
                      {item?.comment_count} comments
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <div className="col-12 mb-3" key={item?._id}>
          <div
            className={`home_post_main mt-0 ${
              !item?.media?.length ? "content_orient" : ""
            } ${
              item?.media?.length &&
              item?.media?.some((val) => isPortrait(val?.height, val?.width))
                ? "only_images"
                : ""
            } `}
          >
            <div className="top_hader">
              <PostUserDetails
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                item={item}
              />
              {item?.media?.length === 1 ? (
                item?.media?.map((i, k) => {
                  return (
                    <img
                      key={i?.name + k}
                      src={i?.name}
                      className="post_img"
                      alt=""
                    />
                  );
                })
              ) : item?.media?.length > 1 ? (
                <>
                  <div className="owl_slider">
                    <OwlCarousel
                      className="owl-carousel owl-theme owl_post"
                      center={true}
                      {...options}
                    >
                      {item?.media?.map((i, k) => {
                        return (
                          <div
                            className="item"
                            onClick={() => {
                              isComment
                                ? navigate(`/comments`, {
                                    state: { comment: item },
                                  })
                                : navigate(`/post/${item?._id}`);
                            }}
                            key={i?.name + k}
                          >
                            <img
                              key={i?.name + k}
                              src={i?.name}
                              alt={i?.name}
                              className="post_img"
                            />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </>
              ) : (
                <>
                  <div
                    class="content_base post_cpntent"
                    onClick={() => {
                      isComment
                        ? navigate(`/comments`, {
                            state: { comment: item },
                          })
                        : navigate(`/post/${item?._id}`);
                    }}
                  >
                    <p className="text-break ">
                      <b className="me-2">
                        <Link
                          to={"/profile"}
                          state={item?.user_id}
                          className="text-dark"
                        >
                          {item?.name}
                        </Link>
                      </b>
                      <RenderTextWithHashtags
                        text={item?.description || ""}
                        showInDetails={isDetailPage}
                      />
                    </p>
                    <div className="comment_button">
                      <a href="#" className="btn">
                        <img
                          src={
                            process.env.PUBLIC_URL + "assets/images/cooment.svg"
                          }
                          className="me-2"
                          alt=""
                        />
                        {item?.comment_count} comments
                      </a>
                    </div>
                  </div>
                </>
              )}
              <PostLikeComment
                retweet={retweet}
                singlePost={singlePost}
                undoRetweet={undoRetweet}
                handleShare={handleShare}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleSavePost={handleSavePost}
                item={item}
                isComment={isComment}
                isDetailPage={isDetailPage}
              />
              {/* <div className="list_option">
                <ul>
                  <li>
                    <a href="#">
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "assets/images/heart.svg"}
                      />
                      {item?.likes}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "assets/images/chat.svg"}
                      />
                      {item?.comment_count}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "assets/images/viral.svg"}
                      />
                      {item.retweet_count}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        alt=""
                        src={
                          process.env.PUBLIC_URL + "assets/images/send-2.svg"
                        }
                      />
                      {item?.share_count?.length}
                    </a>
                  </li>
                  <li>
                    <a href="#">
                      <img
                        alt=""
                        src={process.env.PUBLIC_URL + "assets/images/save.svg"}
                      />
                    </a>
                  </li>
                </ul>
              </div> */}
            </div>
            {item?.media?.length > 0 && (
              <div className="post_cpntent">
                {item?.description?.length > 0 && (
                  <p>
                    <b className="me-2">
                      <Link
                        to={"/profile"}
                        state={item?.user_id}
                        className="text-dark"
                      >
                        {item?.username}
                      </Link>
                    </b>
                    <RenderTextWithHashtags
                      text={item?.description || ""}
                      showInDetails={isDetailPage}
                    />
                  </p>
                )}
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/cooment.svg"
                      }
                      alt=""
                      className="me-2"
                    />
                    {item?.comment_count} comments
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default NormalPostType;
