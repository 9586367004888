import React, { useEffect, useState } from "react";
import HeaderProfile from "../components/HeaderProfile";
import Header from "../components/Header";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { apply_now } from "../api/apiHandler";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";
export default function ApplyNow() {
  const params = useParams();
  const navigate = useNavigate();
  const { type } = params;
  const location = useLocation();
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [isSuggestion, setIsSuggestion] = useState(false);
  console.log("isSuggestion: ", isSuggestion);
  const StateData = location.state;
  console.log('StateData: ', StateData);
  const [address, setAddress] = useState();
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results);
    setAddress(value);
    setCoordinates(ll);
  };

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .required("Please Enter Name")
      .trim()
      .matches("^[A-Za-z ]+$", "Please enter valid name")
      .min(2, "Name should be more than two characters")
      .max(32, "Name shoul not be less than 32 characters"),
    // email: yup
    //   .string()
    //   .required("Please Enter Email")
    //   .matches(
    //     "[A-Z0-9a-z._%+-]+@[A-Za-z0-9.-]+\\.[A-Za-z]{2,}",
    //     "Please enter valid email"
    //   ),
    email: yup
      .string()
      .required("Please Enter Email")
      .matches(
        /^[A-Za-z0-9._%+-]+@[A-Za-z0-9.-]+\.[A-Za-z]{2,}$/,
        "Please enter a valid email"
      ),
    mobile: yup
      .string()
      .required("Please Enter Mobile number")
      .matches("^\\d{10}$", "Please enter valid mobile number"),
    city: yup
      .string()
      .required("Please Enter City Name")
      // .matches("^[A-Za-z ]+$", "Please enter valid City Name")
      .min(2, "City name should be more than two characters"),
    tou: yup.boolean().oneOf([true], "Please agree with terms of use"),
  });
  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue, getValues } =
    useForm(formOptions);
  console.log("getValues: ", getValues("city"));
  useEffect(() => {
    setValue("city", address); // Assuming 'city' is the name of your input field
  }, [address, setValue]);
  const { errors } = formState;
  console.log('errors: ', errors);
  const onSubmit = (data) => {
    apply_now({
      name: data?.name,
      email: data?.email,
      mobile_number: data?.mobile,
      city: data?.city,
      type: params?.type,
      action_id: params?.id,
    }).then((response) => {
      console.log("Apply Now", response);
      if (response?.code === 1 || response?.code === "1") {
        toast.success(response?.message, {
          position: "top-center",
          toastId: "applyNowSuccess",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        reset();
        if (StateData?.path) {
          if (StateData?.pathState) {
            navigate(StateData?.path, { state: StateData?.pathState })
          } else {
            navigate(StateData?.path)
          }
        }
      }
    });
  };
  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
        localStorage.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      {StateData && (
        <section className="one_college pb-0 ">
          <div
            className="container d-flex"
            style={{ justifyContent: "center" }}
          >
            <div>
              <div
                className="college_logo"
                style={{ width: "120px", margin: "0 auto" }}
              >
                <img
                  src={
                    StateData?.logo
                      ? StateData?.logo
                      : process.env.PUBLIC_URL +
                      "/assets/images/icons/jee-main.svg"
                  }
                  className="img-fluid m-auto"
                  alt="college-logo"
                />
              </div>
              <div className="college_short_details">
                <h1 className="font_30 font_700">{StateData?.name}</h1>
              </div>
            </div>
          </div>
        </section>
      )}
      <form className="eng-form" onSubmit={handleSubmit(onSubmit)}>
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="container">
          <div className="row">
            <div className="col-md-8 m-auto" >
              <div className="row">
                <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                  <div className="edit_input mobile_number_input">
                    <h6 className="font_18 font_500">Enter Your Name</h6>
                    <div className="input-group">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/user-check.svg"
                        }
                        className="img-fluid mx-3"
                        alt="phone"
                      />
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        defaultValue={localStorage.getItem("name")}
                        {...register("name")}
                        autoComplete="off"
                      />
                    </div>
                    <div style={{ color: "red" }}>{errors?.name?.message}</div>
                  </div>
                </div>
                <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                  <div className="edit_input mobile_number_input">
                    <h6 className="font_18 font_500">Enter Your Email</h6>
                    <div className="input-group">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/mail.svg"
                        }
                        className="img-fluid mx-3"
                        alt="phone"
                      />
                      <input
                        type="email"
                        className="form-control text-overflow-ellipsis"
                        required=""
                        defaultValue={localStorage.getItem("email")}
                        {...register("email")}
                        autoComplete="off"
                      />
                    </div>
                    <div style={{ color: "red" }}>{errors?.email?.message}</div>
                  </div>
                </div>
                <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                  <div className="edit_input mobile_number_input">
                    <h6 className="font_18 font_500">Enter Your Mobile No.</h6>
                    <div className="input-group">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/smartphone.svg"
                        }
                        className="img-fluid mx-3"
                        alt="phone"
                      />
                      <input
                        type="text"
                        className="form-control"
                        required=""
                        maxLength={10}
                        defaultValue={
                          localStorage.getItem("phone_number")
                            ? localStorage.getItem("phone_number")
                            : ""
                        }
                        {...register("mobile")}
                        autoComplete="off"
                      />
                    </div>
                    <div style={{ color: "red" }}>
                      {errors?.mobile?.message}
                    </div>
                  </div>
                </div>
                <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                  <div className="edit_input mobile_number_input">
                    <h6 className="font_18 font_500">City You Live in</h6>
                    <div className="input-group">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/location-small.svg"
                        }
                        className="img-fluid mx-3"
                        alt="location"
                      />
                      {/* <input
                        type="text"
                        className="form-control"
                        required=""
                        {...register("city")}
                        autoComplete="off"
                        maxLength={10}
                      /> */}
                      <PlacesAutocomplete
                        value={address}
                        onChange={(address) => {
                          setAddress(address);
                        }}
                        onClick={handleSelect}
                        className="my-autocomplete-input"
                      >
                        {({
                          getInputProps,
                          suggestions,
                          getSuggestionItemProps,
                          loading,
                        }) => (
                          <div className="w-100 position-relative">
                            <input
                              className="form-control"
                              type="text"
                              required=""
                              value={address}
                              {...register("city")}
                              autoComplete="off"
                              // maxLength={10}
                              {...getInputProps({
                                placeholder: "Location",
                              })}
                            />
                            <div
                              className={`suggestions-container w-100 p-2  ${isSuggestion === true && "border rounded"
                                }`}
                            >
                              {address?.trim() === "" ? (
                                ""
                              ) : loading ? (
                                <div className="text-secondary blue_29">
                                  Loading...
                                </div>
                              ) : null}
                              {suggestions.length > 0
                                ? setIsSuggestion(true)
                                : setIsSuggestion(false)}
                              {console.log("address: ", address)}
                              {suggestions.map((suggestion, key) => {
                                const style = {
                                  backgroundColor: suggestion.active
                                    ? "#9DDCDE"
                                    : "#fff",
                                  textAlign: "left",
                                  cursor: "pointer",
                                  width: "100%",
                                };

                                return (
                                  <div
                                    className="suggestion-item"
                                    key={key}
                                    {...getSuggestionItemProps(suggestion, {
                                      style,
                                    })}
                                  >
                                    {suggestion.description}12313
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        )}
                      </PlacesAutocomplete>
                    </div>
                    <span style={{ color: "red" }}>
                      {!getValues('city') && errors?.city?.message}
                      {/* {getValues('city') && getValues('city').length < 3 && errors?.city?.message} */}
                    </span>
                  </div>
                </div>
                {/* <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12"> */}
                <div className="form-check">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    value=""
                    id="flexCheckDefault"
                    {...register("tou")}
                  />
                  <label
                    className="form-check-label gray_5a"
                    htmlFor="flexCheckDefault"
                  >
                    By submitting this form you accept and agree to out terms of
                    use
                  </label>
                </div>
                <span style={{ color: "red" }}>{errors?.tou?.message}</span>
                {/* </div> */}
                {/* <div className="col-12 col-xr-4"> */}
                <div className="submit-form-div">
                  <button
                    type="submit"
                    className="signup_blue_btn font_22 font_600 submit-form-btn"
                  >
                    Submit
                  </button>
                </div>
                {/* </div> */}
              </div>
            </div>
          </div>
        </div>
      </form>
    </>
  );
}
