import React, { useEffect, useState } from 'react'
import Friends from './Friends'
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as Yup from 'yup';
import { Link, useNavigate } from 'react-router-dom';
import Header from '../Header';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Helmet } from 'react-helmet';
import Select2 from 'react-select2-wrapper';
import 'react-select2-wrapper/css/select2.css';
import 'react-select2-wrapper/css/select2.min.css';
import Select from 'react-select';
import { edit_profile, get_stream_list, profile_colleges } from '../../api/apiHandler';
import _ from 'lodash';

export default function Collegestream() {
    const [selectedCollege, setSelectedCollege] = useState(localStorage?.getItem("college"));
    const [selectedStream, setSelectedStream] = useState(null);
    const [selectedOption, setSelectedOption] = useState(null);
    const [collegeList, setCollegeList] = useState([])
    const [streamList, setStreamList] = useState([]);
    let uniqueArray = []
    var idsArray = [];
    var streamArray = [];
    const validationSchema = Yup.object().shape({
        college: Yup.string().required('College name is required'),
        stream: Yup.string().required("Stream is required")
    });
    const navigate = useNavigate();
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, setValue, clearErrors, watch, formState } = useForm(formOptions);
    useEffect(() => {
        if (localStorage.getItem("step") != "3") {
            navigate('/')
        }
    }, [])
    const { errors } = formState;
    const onSubmit = async (data) => {
        const newData = { college: data?.college, stream: data?.stream }
        console.log(selectedOption, selectedStream);
        if (localStorage.getItem("step") === 3 || localStorage.getItem("step") === "3") {
            if (selectedOption != null && selectedStream !== null) {
                localStorage.setItem("college", selectedOption?.value);
                localStorage.setItem("stream", selectedStream?.value);
                const newReq = {
                    email: localStorage.getItem("email"),
                    username: localStorage.getItem("username"),
                    college_uni_name: selectedOption?.value,
                };
                edit_profile(newReq).then((response) => {
                    if (response.code === "1" || response.code === 1) {
                        navigate("/friends")
                    }
                });
            } else {
                toast.error("Please select college & stream", {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                    toastId: "college-stream-error"
                });
            }
        } else {
            toast.error("Please complete signup", {
                position: "top-center",
                autoClose: 2000,
                hideProgressBar: true,
                closeOnClick: true,
                pauseOnHover: true,
                draggable: true,
                progress: undefined,
                theme: "colored",
                toastId: "college-stream"
            });
        }

    }
    const handler = () => {
        profile_colleges({ "page_token": "" }).then((response) => {
            if (response?.code === 1 || response?.code === "1") {
                setCollegeList(response?.data)
            }
        })
        get_stream_list({ "page_token": "" }).then((response) => {
            console.log("78", response);
            if (response?.code === 1 || response?.code === "1") {
                setStreamList(response?.data?.colleges_list_result);
            }
        })
    }
    console.log(collegeList);
    collegeList?.map((item) => {
        var obj = {
            label: item?.name,
            value: item?.name
        }
        idsArray.push(obj)
    })
    streamList?.map((item) => {
        console.log("93", item);
        var obj = {
            label: item?.name,
            value: item?.name
        }
        streamArray.push(obj)
    })
    useEffect(() => {
        handler();
    }, [])
    return (
        <>
            {
                localStorage.getItem("step") == "3" && (
                    <>
                        <Header />
                        <section className="main_signup">
                            <ToastContainer
                                position="top-center"
                                autoClose={2000}
                                hideProgressBar
                                newestOnTop={false}
                                closeOnClick
                                rtl={false}
                                pauseOnFocusLoss
                                draggable
                                pauseOnHover
                                theme="colored"
                            />
                            <div className="container">
                                <div className="row">
                                    <div className="col-md-12">
                                        <h1 className="font_30 font_600 text-center signup_heading_border_blue">Sign Up Your <span
                                            className="blue_29">Account</span></h1>
                                        <p className="inter font_18 text-center"><q>Lorem ipsum dolor sit amet, consectetur adipiscing elit,
                                            sed do eiusmod tempor incididunt.</q></p>
                                    </div>
                                </div>
                                <div className="form__container">
                                    <div className="row">
                                        <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                            <div className="left__container">
                                                <div className="form_vector">
                                                    <img src={process.env.PUBLIC_URL + "assets/images/icons/vector-form.svg"} className="img-fluid" alt="vector" />
                                                </div>
                                                <h6 className="font_26 black_00 font_600">Step 4</h6>
                                                <p className="gray_5a font_16 font_500">Enter your College and Stream</p>
                                                <div className="steps d-md-flex">
                                                    <div className="progress__bar__container">
                                                        <ul className="ps-0">
                                                            <li className="active" id="icon1">
                                                                <span>1</span>
                                                            </li>
                                                            <li id="icon2" className="active">
                                                                <span>2</span>
                                                            </li>
                                                            <li id="icon3" className="active">
                                                                <span>3</span>
                                                            </li>
                                                            <li id="icon4" className="active">
                                                                <span>4</span>
                                                            </li>
                                                            <li id="icon5">
                                                                <span>5</span>
                                                            </li>
                                                            <li id="icon6">
                                                                <span>6</span>
                                                            </li>
                                                        </ul>
                                                    </div>
                                                    <div className="side__titles">
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Basic Details</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">OTP Verification</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Select UserName</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 active">Select College</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500">Find Friends</p>
                                                        </div>
                                                        <div className="title__name">
                                                            <p className="font_16 font_500 mb-0">Link Hashtags</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12 position-relative">
                                            <div className="right_form_1 right__container">
                                                <div id="form4">
                                                    <div className="form_heading">
                                                        <h4 className="font_25 font_600 blue_29">Select College and Stream</h4>
                                                        <p className="mb-0"><q>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed
                                                            do eiusmod tempor incididunt.</q></p>
                                                    </div>
                                                    <form>
                                                        <div className="row justify-content-center w-100 my-auto">
                                                            <div className="col-12 col-xl-10 col-lg-10 col-md-10 col-sm-12">
                                                                <div className="step_1_form">
                                                                    <div className="mobile_number_input">
                                                                        <h6 className="font_18 font_500">Select College</h6> {""} <span style={{ color: "red" }}>{errors.college?.message}</span>
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" id="basic-addon1"><img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/college.svg"}
                                                                                className="img-fluid" alt="phone" /></span>
                                                                            <Select
                                                                                className="form-control"

                                                                                // required
                                                                                autoComplete="off"
                                                                                // value={selectedOption}
                                                                                // onChange={(o) => { 
                                                                                //     setValue("college", o?.value, { shouldValidate: true }); 
                                                                                //     setSelectedCollege(o); 

                                                                                // }} 
                                                                                defaultValue={selectedOption != null ? selectedOption : null}
                                                                                onChange={setSelectedOption}
                                                                                options={idsArray}
                                                                                placeholder={"Select College"}
                                                                                isSearchable
                                                                            />
                                                                            <input type="hidden" className="form-control" name='college' {...register("college")} />

                                                                            {/* <label htmlFor="name">College</label> */}
                                                                        </div>
                                                                    </div>

                                                                    <div className="mobile_number_input">
                                                                        <h6 className="font_18 font_500">Select Stream</h6> {""} <span style={{ color: "red" }}>{errors.stream?.message}</span>
                                                                        <div className="input-group">
                                                                            <span className="input-group-text" id="basic-addon1"><img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/stream.svg"}
                                                                                className="img-fluid" alt="phone" /></span>
                                                                            <Select
                                                                                className="form-control"
                                                                                // required
                                                                                autoComplete="off"
                                                                                defaultValue={selectedStream != null ? selectedStream : null}
                                                                                // value={selectedStream}
                                                                                onChange={setSelectedStream}
                                                                                placeholder={"Select Stream"}
                                                                                isSearchable
                                                                                options={streamArray}
                                                                            />
                                                                            <input type="hidden" className="form-control" name='college' {...register("stream")} />
                                                                            {/* <label htmlFor="name">Stream</label> */}
                                                                        </div>
                                                                    </div>
                                                                    <Link to="/friends" className="blue_29 float-end cursor-pointer">Skip</Link>
                                                                    <div className="buttons_form_1 buttons">
                                                                        {/* <Link to="/username" className="font_15 prev__btn d-flex align-items-center"
                                                            ><img
                                                                src={process.env.PUBLIC_URL+"assets/images/icons/prev.svg"} className="img-fluid"
                                                                alt="icon" />Previous</Link> */}
                                                                        <button type="button" onClick={onSubmit} className="font_15 nxt__btn blue_button d-flex align-items-center"
                                                                        >Next <img
                                                                                src={process.env.PUBLIC_URL + "assets/images/icons/next.svg"} className="img-fluid"
                                                                                alt="icon" /> </button>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>

                        </section>
                    </>
                )
            }
        </>
    )
}
{/* <div className="buttons_form_1 buttons">
                                    <a className="font_15 prev__btn d-flex align-items-center"
                                        onClick="prevForm();"><img
                                            src={process.env.PUBLIC_URL + "assets/images/icons/prev.svg"} className="img-fluid"
                                            alt="icon" />Previous</a>
                                    <a className="font_15 nxt__btn blue_button d-flex align-items-center"
                                        onClick="nextForm();">Next <img
                                            src={process.env.PUBLIC_URL + "assets/images/icons/next.svg"} className="img-fluid"
                                            alt="icon" /> </a>
                                </div> */}