import React, { useEffect, useState } from "react";
import { Swiper, SwiperSlide } from "swiper/react";

import "swiper/css";
import "swiper/css/pagination";

import StoryComponent from "./StoryComponent";
import { stories_list } from "../../api/apiHandler";
import { useNavigate, useParams } from "react-router-dom";
import { ToastContainer } from "react-toastify";

export default function ViewStoryList({ interaction = false }) {
  const navigate = useNavigate();
  const [centeredIndex, setCenteredIndex] = useState(0);
  const [storyList, setStoryList] = useState([]);
  const { id, media } = useParams();

  const handleSlideChange = (swiper) => {
    const activeIndex = swiper.activeIndex;
    setCenteredIndex(activeIndex);
  };

  useEffect(() => {
    stories_list({}).then((response) => {
      if (response.code === 1 || response.code === "1") {
        const filterData = response.data || [];
        setStoryList(filterData);
        const active = filterData.findIndex((x) => x._id == id);
        console.log("active: ", active);
        setCenteredIndex(active);
        console.log("Line 424 Page token changed", response.data);
      } else {
        // setIsFetching(false);
        // navigate("/");
      }
    });
  }, [id]);

  return (
    <>
      <ToastContainer
        position="top-center"
        autoClose={2000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="colored"
      />
      <div className="position-relative ">
        <button
          className="text-white position-absolute story_close_btn"
          onClick={() => {
            navigate("/");
          }}
        >
          {" "}
          <img
            className="me-2"
            style={{
              filter:
                "brightness(0) saturate(100%) invert(100%) sepia(9%) saturate(0%) hue-rotate(248deg) brightness(110%) contrast(101%)",
            }}
            src={
              process.env.PUBLIC_URL +
              "/assets/images/icons/x-not-interested.svg"
            }
            alt=""
          />{" "}
          close
        </button>
        {storyList.length > 0 && (
          <Swiper
            slidesPerView={1}
            initialSlide={centeredIndex}
            spaceBetween={10}
            centeredSlides={true}
            className="mySwiper"
            onSlideChange={handleSlideChange}
            wrapperTag="div"
            // loop
            breakpoints={{
              768: {
                slidesPerView: 1,
                spaceBetween: 20,
              },
              1024: {
                slidesPerView: 3,
                spaceBetween: 20,
              },
            }}
          >
            {storyList.map((userStory, index) => (
              <SwiperSlide key={index} className="custom-slide">
                <StoryComponent
                  interaction={interaction}
                  user={{
                    profile_image: userStory.profile_image,
                    name: userStory.username,
                    _id: userStory.user_id,
                  }}
                  stories={userStory.media}
                  index={index}
                  storyList={storyList}
                  isCentered={centeredIndex === index}
                  isLast={centeredIndex === storyList.length - 1}
                />
              </SwiperSlide>
            ))}
          </Swiper>
        )}
      </div>
    </>
  );
}
