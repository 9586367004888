import React, { useEffect, useRef, useState } from "react";
import { RenderTextWithHashtags } from "../HomeFeed";
import { Link, useNavigate } from "react-router-dom";
import ReactTimeAgo from "react-time-ago";
import OwlCarousel from "react-owl-carousel";
import PostLikeComment from "../components/PostLikeComment";
import PostUserDetails from "../components/PostUserDetails";
import RetweetWithQuote from "./RetweetWithQuote";

const RetweetPostType = (props) => {
  const {
    item,
    key,
    likeUnlike,
    singlePost,
    setSinglePost,
    retweet,
    handleShare,
    setPostPermission,
    undoRetweet,
    handleSavePost,
    isDetailPage = false,
  } = props;
  const navigate = useNavigate();
  const options = {
    items: 1, // Number of items to display in the carousel
    loop: true,
    margin: 10,
    autoplay: false,
  };

  const isRetweetComment =
    item?.post_type == "retweet" || item?.post_type == "retweet_comments";

  // background: #FB0000;

  const videoRef = useRef(null);
  // const navigate = useNavigate();
  const [interaction, setInteraction] = useState(false);
  useEffect(() => {
    // Inside your useEffect hook
    const handleUserInteraction = () => {
      setInteraction(true);
    };

    // Attach event listener to the document for any user interaction
    document.addEventListener("click", handleUserInteraction);

    // Cleanup function to remove the event listener
    return () => {
      document.removeEventListener("click", handleUserInteraction);
    };
  });
  useEffect(() => {
    const options = {
      root: null,
      rootMargin: "0px",
      threshold: 0.8, // Adjust the threshold as needed
    };

    const handleIntersection = (entries) => {
      entries.forEach((entry) => {
        if (entry.isIntersecting) {
          // Video is in view, autoplay
          videoRef.current.play();
        } else {
          // Video is not in view, pause
          videoRef.current.pause();
        }
      });
    };

    const observer = new IntersectionObserver(handleIntersection, options);
    const videoElement = videoRef.current;

    if (videoElement) {
      observer.observe(videoElement);

      return () => {
        observer.unobserve(videoElement);
      };
    }
  }, []);

  return (
    <>
      {item?.shared_feed.post_type == "retweet_with_quote" ? (
        <RetweetWithQuote
          isRetweet={true}
          retweet={retweet}
          undoRetweet={undoRetweet}
          setPostPermission={setPostPermission}
          singlePost={singlePost}
          setSinglePost={setSinglePost}
          likeUnlike={likeUnlike}
          handleShare={handleShare}
          handleSavePost={handleSavePost}
          key={key}
          mainItem={item}
          item={item?.shared_feed}
        />
      ) : item?.shared_feed.module_type == "document" ? (
        <div className="col-12 mb-3" key={item?._id}>
          <div className="repost_gray">
            <div className="repost_gray_iamge">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/post-repost-white.svg"
                }
                className="img-fluid"
                alt="repost"
              />
            </div>
            <p className="mb-0 gray_5a font_12 font_500">
              {" "}
              {item?.name === localStorage?.getItem("name")
                ? "You"
                : item?.name}{" "}
              {item?.retweet_count > 1 ? "and others" : ""}{" "}
              <span className="blue_29">Viral</span> this
            </p>
          </div>
          <div className="home_post_main content_orient">
            <div className="top_hader">
              <PostUserDetails
                setPostPermission={setPostPermission}
                setSinglePost={setSinglePost}
                item={item?.shared_feed}
                mainItem={item}
                isRetweetComment={isRetweetComment}
              />
              <div
                class="content_base post_cpntent"
                onClick={() => {
                  navigate(`/post/${item?._id}`);
                }}
              >
                {item?.shared_feed?.description?.length > 0 && (
                  <p>
                    <b className="me-2">
                      <Link className="text-dark">
                        {item?.shared_feed?.name}
                      </Link>
                    </b>{" "}
                    <RenderTextWithHashtags
                      text={item?.description || ""}
                      showInDetails={isDetailPage}
                    />
                  </p>
                )}
                <div
                  className="post_document"
                  style={{
                    cursor: "pointer",
                    marginTop: "0px",
                    marginBottom: "15px",
                  }}
                  onClick={() => {
                    navigate(`/post/${item?.shared_feed?._id}`);
                  }}
                >
                  <div className="row">
                    <div className="col-9">
                      {item?.shared_feed?.doc_title?.length > 0 ? (
                        <h5 className="font_500 font_16 black_14">
                          {item?.shared_feed?.doc_title}{" "}
                        </h5>
                      ) : (
                        <></>
                      )}
                      <div className="post_document_english">
                        <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/university.svg"
                            }
                            className="img-fluid me-2"
                            alt="college"
                          />
                          {item?.shared_feed?.doc_college_name}
                        </p>
                        <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/small-calendar.svg"
                            }
                            className="img-fluid me-2"
                            alt="college"
                          />
                          {item?.shared_feed?.doc_session}
                        </p>
                      </div>
                      <div className="post_document_english">
                        {item?.shared_feed?.doc_degree?.length > 0 ? (
                          <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/degree-cap.svg"
                              }
                              className="img-fluid me-2"
                              alt="college"
                            />
                            {item?.shared_feed?.doc_degree}
                          </p>
                        ) : (
                          <></>
                        )}
                        <p className="font_12 gray_5a inter d-flex align-items-center mb-0">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/english-book.svg"
                            }
                            className="img-fluid me-2"
                            alt="college"
                          />
                          {item?.shared_feed?.doc_subject}
                        </p>
                      </div>
                      <button
                        style={{ cursor: "pointer" }}
                        target="_blank"
                        download={true}
                        className="edit_p_button d-inline-block"
                        onClick={() => {
                          // saveAs(
                          //   item?.media[0]?.name,
                          //   `${item?.media[0]?.name?.split("/")[6]}`
                          // );
                        }}
                      >
                        {" "}
                        <img
                          src={
                            process.env.PUBLIC_URL +
                            "/assets/images/icons/download-white.svg"
                          }
                          className="img-fluid"
                          alt="edit"
                        />{" "}
                        Download
                      </button>
                    </div>
                    <div className="col-3">
                      <a
                        href={item?.shared_feed?.media[0]?.thumb}
                        target="_blank"
                        className="post_document_image"
                        rel="noreferrer"
                      >
                        <img
                          src={item?.shared_feed?.media[0]?.thumb}
                          className="img-fluid"
                          alt="document"
                        />
                      </a>
                    </div>
                  </div>
                </div>
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                      className="me-2"
                      alt=""
                    />
                    {item?.comment_count} comments
                  </a>
                </div>
              </div>
              <PostLikeComment
                retweet={retweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleShare={handleShare}
                item={item}
                handleSavePost={handleSavePost}
                isDetailPage={isDetailPage}
                // isComment={isComment}
              />
            </div>
          </div>
        </div>
      ) : item?.shared_feed.module_type == "reels" ? (
        <div className="col-12 mb-3" key={item?._id}>
          <div className="repost_gray">
            <div className="repost_gray_iamge">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/post-repost-white.svg"
                }
                className="img-fluid"
                alt="repost"
              />
            </div>
            <p className="mb-0 gray_5a font_12 font_500">
              {" "}
              {item?.name === localStorage?.getItem("name")
                ? "You"
                : item?.name}{" "}
              {item?.retweet_count > 1 ? "and others" : ""}{" "}
              <span className="blue_29">Viral</span> this
            </p>
          </div>
          <div className="home_post_main mt-0">
            <div className="top_hader">
              <PostUserDetails
                item={item.shared_feed}
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                mainItem={item}
                isRetweetComment={isRetweetComment}
              />

              <video
                ref={videoRef}
                autoPlay={false}
                loop={true}
                id="banner"
                controls="false"
                controlslist="nodownload"
                className="video_ply"
                poster={item?.shared_feed?.media[0]?.thumb}
                onClick={() => navigate(`/post/${item?._id}`)}
              >
                <source
                  src={item?.shared_feed?.media[0]?.name}
                  type="video/mp4"
                />
              </video>
              {!interaction && (
                <img
                  onClick={() => videoRef.current.play()}
                  className="reels_play_btn"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/play-circle-fill.svg"
                  }
                />
              )}
              <PostLikeComment
                handleShare={handleShare}
                undoRetweet={undoRetweet}
                retweet={retweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                item={item}
                handleSavePost={handleSavePost}
                isDetailPage={isDetailPage}
              />
            </div>
            <div
              className="post_cpntent"
              onClick={() => navigate(`/post/${item?._id}`)}
            >
              {item.description.length > 0 && (
                <p>
                  <b className="me-2">
                    <Link className="text-dark">{item?.name}</Link>
                  </b>{" "}
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
              )}
              <div className="comment_button">
                <a className="btn">
                  <img
                    src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                    alt=""
                    className="me-2"
                  />
                  {item?.comment_count} comments
                </a>
              </div>
            </div>
          </div>
        </div>
      ) : !item.shared_feed.media.length ? (
        <div className="col-12 mb-3" key={item?._id}>
          <div className="repost_gray">
            <div className="repost_gray_iamge">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/post-repost-white.svg"
                }
                className="img-fluid"
                alt="repost"
              />
            </div>
            <p className="mb-0 gray_5a font_12 font_500">
              {" "}
              {item?.name === localStorage?.getItem("name")
                ? "You"
                : item?.name}{" "}
              {item?.retweet_count > 1 ? "and others" : ""}{" "}
              <span className="blue_29">Viral</span> this
            </p>
          </div>
          <div className="home_post_main content_orient">
            <div className="top_hader">
              <PostUserDetails
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                item={item.shared_feed}
                mainItem={item}
                isRetweetComment={isRetweetComment}
              />

              <div
                class="content_base post_cpntent"
                onClick={() => {
                  navigate(`/post/${item?._id}`);
                }}
              >
                <p>
                  <RenderTextWithHashtags
                    text={item?.description || ""}
                    showInDetails={isDetailPage}
                  />
                </p>
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                      className="me-2"
                      alt=""
                    />
                    {/* {item?.shared_feed?.comment_count} */}
                    {item?.comment_count + " "}
                    comments
                  </a>
                </div>
              </div>
              <PostLikeComment
                handleShare={handleShare}
                retweet={retweet}
                undoRetweet={undoRetweet}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleSavePost={handleSavePost}
                item={item}
                isDetailPage={isDetailPage}
              />
            </div>
          </div>
        </div>
      ) : item.shared_feed.media.length == 1 ? (
        item.shared_feed.media[0].type == "video" ? (
          <div key={item?._id}>"Video Retweet View"</div>
        ) : (
          <div className="col-12 mb-3" key={item?._id}>
            <div className="repost_gray">
              <div className="repost_gray_iamge">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/post-repost-white.svg"
                  }
                  className="img-fluid"
                  alt="repost"
                />
              </div>
              <p className="mb-0 gray_5a font_12 font_500">
                {" "}
                {item?.name === localStorage?.getItem("name")
                  ? "You"
                  : item?.name}{" "}
                {item?.retweet_count > 1 ? "and others" : ""}{" "}
                <span className="blue_29">Viral</span> this
              </p>
            </div>
            <div className={`home_post_main mt-0`}>
              <div className="top_hader">
                <PostUserDetails
                  setSinglePost={setSinglePost}
                  setPostPermission={setPostPermission}
                  item={item.shared_feed}
                  mainItem={item}
                  isRetweetComment={isRetweetComment}
                />
                {item?.shared_feed?.media?.length === 1 ? (
                  item?.shared_feed?.media?.map((i, k) => {
                    return (
                      <img
                        onClick={() => navigate(`/post/${item?._id}`)}
                        src={i?.name}
                        className="post_img"
                        alt=""
                      />
                    );
                  })
                ) : item?.shared_feed?.media?.length > 1 ? (
                  <>
                    <div className="owl_slider">
                      <OwlCarousel
                        className="owl-carousel owl-theme owl_post"
                        center={true}
                        onClick={() => navigate(`/post/${item?._id}`)}
                        {...options}
                      >
                        {item?.shared_feed?.media?.map((i, k) => {
                          return (
                            <div className="item">
                              <img
                                key={i?.name + k}
                                src={i?.name}
                                alt={i?.name}
                                className="post_img"
                              />
                            </div>
                          );
                        })}
                      </OwlCarousel>
                    </div>
                  </>
                ) : (
                  <></>
                )}

                <PostLikeComment
                  handleShare={handleShare}
                  retweet={retweet}
                  singlePost={singlePost}
                  undoRetweet={undoRetweet}
                  setSinglePost={setSinglePost}
                  likeUnlike={likeUnlike}
                  handleSavePost={handleSavePost}
                  item={item}
                  isDetailPage={isDetailPage}
                />
              </div>
              {item.media.length > 0 && (
                <div
                  className="post_cpntent"
                  onClick={() => navigate(`/post/${item?._id}`)}
                >
                  {item.description.length > 0 && (
                    <p>
                      <b className="me-2">
                        <Link className="text-dark">{item?.name}</Link>
                      </b>{" "}
                      <RenderTextWithHashtags
                        text={item?.description || ""}
                        showInDetails={isDetailPage}
                      />
                    </p>
                  )}
                  <div className="comment_button">
                    <a href="#" className="btn">
                      <img
                        src={
                          process.env.PUBLIC_URL + "assets/images/cooment.svg"
                        }
                        alt=""
                        className="me-2"
                      />
                      {item?.comment_count} comments
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>
        )
      ) : (
        <div className="col-12 mb-3" key={item?._id}>
          <div className="repost_gray">
            <div className="repost_gray_iamge">
              <img
                src={
                  process.env.PUBLIC_URL +
                  "/assets/images/icons/post-repost-white.svg"
                }
                className="img-fluid"
                alt="repost"
              />
            </div>
            <p className="mb-0 gray_5a font_12 font_500">
              {" "}
              {item?.name === localStorage?.getItem("name")
                ? "You"
                : item?.name}{" "}
              {item?.retweet_count > 1 ? "and others" : ""}{" "}
              <span className="blue_29">Viral</span> this
            </p>
          </div>
          <div className={`home_post_main mt-0`}>
            <div className="top_hader">
              <PostUserDetails
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                item={item.shared_feed}
                mainItem={item}
                isRetweetComment={isRetweetComment}
              />
              {item?.shared_feed?.media?.length === 1 ? (
                item?.shared_feed?.media?.map((i, k) => {
                  return (
                    <img
                      onClick={() => navigate(`/post/${item?._id}`)}
                      src={i?.name}
                      className="post_img"
                      alt=""
                    />
                  );
                })
              ) : item?.shared_feed?.media?.length > 1 ? (
                <>
                  <div className="owl_slider">
                    <OwlCarousel
                      className="owl-carousel owl-theme owl_post"
                      center={true}
                      onClick={() => navigate(`/post/${item?._id}`)}
                      {...options}
                    >
                      {item?.shared_feed?.media?.map((i, k) => {
                        return (
                          <div className="item">
                            <img
                              key={i?.name + k}
                              src={i?.name}
                              alt={i?.name}
                              className="post_img"
                            />
                          </div>
                        );
                      })}
                    </OwlCarousel>
                  </div>
                </>
              ) : (
                <></>
              )}

              <PostLikeComment
                handleShare={handleShare}
                retweet={retweet}
                singlePost={singlePost}
                undoRetweet={undoRetweet}
                setSinglePost={setSinglePost}
                likeUnlike={likeUnlike}
                handleSavePost={handleSavePost}
                item={item}
                isDetailPage={isDetailPage}
              />
            </div>
            {item.media.length > 0 && (
              <div
                className="post_cpntent"
                onClick={() => navigate(`/post/${item?._id}`)}
              >
                {item.description.length > 0 && (
                  <p>
                    <b className="me-2">
                      <Link className="text-dark">{item?.name}</Link>
                    </b>{" "}
                    <RenderTextWithHashtags
                      text={item?.description || ""}
                      showInDetails={isDetailPage}
                    />
                  </p>
                )}
                <div className="comment_button">
                  <a href="#" className="btn">
                    <img
                      src={process.env.PUBLIC_URL + "assets/images/cooment.svg"}
                      alt=""
                      className="me-2"
                    />
                    {item?.comment_count} comments
                  </a>
                </div>
              </div>
            )}
          </div>
        </div>
      )}
    </>
  );
};

export default RetweetPostType;
