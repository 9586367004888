import React from "react";
import Modal from "@mui/material/Modal";
import Box from "@mui/material/Box";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import {
  add_friend,
  block_unblock_friend,
  delete_comment,
  delete_post,
  not_interested,
  remove_follow,
  report_post,
  report_user,
} from "../../../../api/apiHandler";
import { toast } from "react-toastify";
import { useNavigate } from "react-router-dom";
import { showMessage } from "../../../../common/RedirectPathManage";

const PostMenu = ({
  postPermission = false,
  setPostPermission,
  feeds,
  singlePost = {},
  setFeeds,
  isRedirect = false,
  isComment = false,
  commentList,
  setCommentList,
  HandleSetFeeds,
}) => {
  console.log("feeds: ", feeds);
  console.log("singlePost: ", singlePost);
  const theme = useTheme();
  const isSmallScreen = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();
  const loggedInUser = localStorage.getItem("_id");

  const customStyles = {
    position: "absolute",
    top: "50%",
    left: "50%",
    transform: "translate(-50%, -50%)",
    width: isSmallScreen ? "90%" : 400, // Adjust the width for small screens
    maxWidth: "100%",
    bgcolor: "#ffffff",
    boxShadow: 24,
    borderRadius: "10px",
    // p: 2,
  };

  const deletePost = () => {
    // console.log(postId);
    if (loggedInUser == singlePost?.user_id) {
      if (isComment) {
        delete_comment({ id: singlePost?._id }).then((response) => {
          console.log("Line 523", response);
          if (response.code === 1 || response.code === "1") {
            toast.info(response.message, {
              toastId: "Delete Comment",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            const updateList = commentList?.map((item) => {
              if (item?._id == singlePost?._id) {
                item.status = "Deleted";
              }
              return item;
            });
            setCommentList(updateList);
            setPostPermission(false);
          }
        });
      } else {
        delete_post({
          id: singlePost._id,
          retweet_id:
            singlePost.post_type === "retweet" ? singlePost.retweet_id : "",
        }).then((response) => {
          console.log("Line 228 Line 228 Line 228", response);
          if (response.code === 1) {
            toast.info(response.message, {
              toastId: "Delete Post",
              position: "top-center",
              autoClose: 2000,
              hideProgressBar: true,
              closeOnClick: true,
              pauseOnHover: true,
              draggable: true,
              progress: undefined,
              theme: "colored",
            });
            if (isRedirect) {
              navigate("/");
            } else {
              const updatedPosts = feeds?.filter(
                (post) => post?._id !== singlePost._id
              );
              console.log(updatedPosts);
              HandleSetFeeds(updatedPosts);
              // setFeeds(updatedPosts);
            }
            setPostPermission(false);
          }
        });
      }
    } else {
      setPostPermission(false);
    }
  };

  const notInterested = (type) => {
    not_interested({ id: singlePost._id, type: type }).then((response) => {
      if (response.code == 1) {
        // setFeeds([])
        toast.info(response.message, {
          //   toastId: "Delete Post",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        if (isRedirect) {
          navigate("/");
        } else {
          const updatedPosts = feeds?.filter(
            (post) => post?._id !== singlePost._id
          );
          HandleSetFeeds(updatedPosts);
          // setFeeds(updatedPosts);
        }
        setPostPermission(false);
      }
    });
  };

  const addFrnd = (id) => {
    add_friend({ friend_id: id }).then((res) => {
      console.log("res: ", res);
      if (res.code == 1) {
        toast.success(res.message, {
          //   toastId: "Delete Post",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setPostPermission(false);
      }
    });
  };

  const RemoveFriend = (id) => {
    remove_follow({ friend_id: id }).then((res) => {
      if (res.code == 1) {
        toast.success(res.message, {
          //   toastId: "Delete Post",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setPostPermission(false);
      }
    });
  };

  const blockUser = (id) => {
    block_unblock_friend({ friend_id: id, type: "block" }).then((response) => {
      console.log("response: block ", response);
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      const updatedPosts = feeds?.filter(
        (post) => post?._id !== singlePost._id
      );
      // setFeeds(updatedPosts);
      HandleSetFeeds(updatedPosts);
      setPostPermission(false);
    });
  };
  const reportUser = (id) => {
    report_user({ id }).then((response) => {
      console.log("response: block ", response);
      toast.success(response.message, {
        //   toastId: "Delete Post",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    });
    const updatedPosts = feeds?.filter((post) => post?._id !== singlePost._id);
    // setFeeds(updatedPosts);
    HandleSetFeeds(updatedPosts);
    setPostPermission(false);
  };

  const reportPost = (id) => {
    report_post({ id }).then((response) => {
      console.log("response: block ", response);
      if (response.code == 1) {
        console.log("feeds: ", feeds, singlePost);
        toast.success(response.message, {
          //   toastId: "Delete Post",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setPostPermission(false);
      }
    });
  };
  console.log("singlePost: ", singlePost);

  return (
    <Modal
      open={postPermission}
      onClose={() => {
        setPostPermission(false);
      }}
      aria-labelledby="modal-modal-title"
      aria-describedby="modal-modal-description"
    >
      <Box sx={customStyles}>
        <ul className="p-0 m-0 post-menu-custom">
          {loggedInUser == singlePost?.user_id ? (
            <li
              className="text-center text-secondary cursor-pointer"
              style={{ padding: "13px 0", fontWeight: 500 }}
              onClick={() => {
                deletePost();
              }}
            >
              Delete
            </li>
          ) : (
            <>
              {!isComment && (
                <li
                  className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                  style={{
                    padding: "13px 0",
                    fontWeight: 500,
                    cursor: "pointer",
                  }}
                  onClick={() => {
                    notInterested("user");
                  }}
                >
                  <img
                    className="me-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/not_interested.svg"
                    }
                    alt=""
                  />{" "}
                  Not Interested in {singlePost?.name}
                </li>
              )}
              {!isComment && (
                <li
                  className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                  style={{ padding: "13px 0", fontWeight: 500 }}
                  onClick={() => {
                    notInterested("post");
                  }}
                >
                  <img
                    className="me-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/x-not-interested.svg"
                    }
                    alt=""
                  />
                  Not Interested in this Post
                </li>
              )}
              {singlePost?.has_friend == "1" ? (
                <li
                  onClick={() => {
                    RemoveFriend(singlePost?.user_id);
                  }}
                  className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                  style={{ padding: "13px 0", fontWeight: 500 }}
                >
                  <img
                    className="me-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/link-new.svg"
                    }
                    alt=""
                  />
                  Linked @{singlePost?.username}
                </li>
              ) : singlePost?.has_friend == "3" ? (
                <li
                  onClick={() => {
                    addFrnd(singlePost?.user_id);
                  }}
                  className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                  style={{ padding: "13px 0", fontWeight: 500 }}
                >
                  <img
                    className="me-2"
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/link-new.svg"
                    }
                    alt=""
                  />
                  link @{singlePost?.username}
                </li>
              ) : (
                <></>
              )}
              <li
                onClick={() => {
                  blockUser(singlePost?.user_id);
                }}
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
              >
                <img
                  className="me-2"
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/block.svg"
                  }
                  alt=""
                />
                Block @{singlePost?.username}
              </li>
              <li
                onClick={() => {
                  reportUser(singlePost?.user_id);
                }}
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
              >
                <img
                  className="me-2"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/report-user.svg"
                  }
                  alt=""
                />
                Report User
              </li>
              <li
                onClick={() => {
                  reportPost(singlePost?._id);
                }}
                className="border-bottom  border-secondary text-center text-secondary cursor-pointer"
                style={{ padding: "13px 0", fontWeight: 500 }}
              >
                <img
                  className="me-2"
                  src={
                    process.env.PUBLIC_URL +
                    "/assets/images/icons/report-user.svg"
                  }
                  alt=""
                />
                Report Post
              </li>
            </>
          )}
        </ul>
      </Box>
    </Modal>
  );
};

export default PostMenu;
