import React, { useEffect, useRef, useState } from 'react'
import HeaderProfile from './HeaderProfile'
import Header from './Header'
import { add_post, document_category, document_subject, get_degree_list, profile_colleges } from '../api/apiHandler'
import { useForm } from 'react-hook-form';
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import { Document, Page, pdfjs } from 'react-pdf';
import { ReactS3Client, ReactS3ClientThumb } from './pages/HomeFeed/HomeFeed';
import { useLocation, useNavigate } from 'react-router';
pdfjs.GlobalWorkerOptions.workerSrc = `//cdnjs.cloudflare.com/ajax/libs/pdf.js/${pdfjs.version}/pdf.worker.js`;

function UploadDocument() {
    const navigate = useNavigate();
    const location = useLocation();
    const [collegeName, setCollegeName] = useState();
    console.log('collegeName: ', collegeName);
    const [degreeName, setDegreeName] = useState();
    const [categoryName, setCategoryName] = useState();
    const [subjectName, setSubjectName] = useState();
    const [imagePreview, setImagePreview] = useState([]);
    const [heightWidthForQuotePost, setHeightWidthForQuotePost] = useState([]);
    const currentYear = new Date().getFullYear();
    const fileRef = useRef();
    const canvasRef = useRef(null);
    const [thumb, setThumb] = useState(null);
    console.log('thumb: ', thumb);
    let imageDimensions = [];
    const [pdf, setPdf] = useState(null);
    const [fileObj, setFileObj] = useState(null);
    const [newdata, setNewData] = useState();
    const [isShow, setIsShow] = useState(false);
    console.log('isShow: ', isShow);
    const { register, handleSubmit, formState: { errors }, reset, setValue } = useForm();
    console.log('errors: ', errors);
    const trimValidation = (value) => {
        // Trim the value
        const trimmedValue = value.trim();

        // Update the form value with the trimmed one
        setValue('title', trimmedValue);

        // Check if the trimmed value is empty
        if (trimmedValue === '') {
            return 'Please Enter Title';
        }

        // If the value is not empty, return true to indicate valid input
        return true;
    };

    useEffect(() => {
        profile_colleges({ page_token: 1 }).then((response) => {
            if (response.code === 1 || response.code === "1") {
                setCollegeName(response.data)
            }
        })
        get_degree_list({ page_token: 1 }).then((response) => {
            if (response.code === 1 || response.code === "1") {
                setDegreeName(response?.data?.degree_list_result)
            }
        })
        document_category({ page_token: 1 }).then((response) => {
            if (response.code === 1 || response.code === "1") {
                setCategoryName(response?.data)
            }
        })
        document_subject({ page_token: 1 }).then((response) => {
            if (response.code === 1 || response.code === "1") {
                console.log('response: ', response);
                setSubjectName(response?.data)
            }
        })
    }, [])

    // const handleImagePreview = (e) => {
    //     // const file = e.target.files[0];
    //     let file = e.target.files[0];
    //     console.log("hee", file);
    //     if (file) {
    //         setImagePreview(file)
    //         if (file.type.startsWith('image/')) {
    //             console.log('img: ');
    //             // let file = e.target.files;
    //             const render = new FileReader();
    //             render.onloadend = () => {
    //                 var img = new Image();
    //                 img.src = render.result;
    //                 console.log("img.src ", img);
    //                 img.onload = () => {
    //                     var obj = { height: img.height, width: img.width };
    //                     console.log('obj: ', obj);
    //                     // imageDimensions.push(obj);

    //                     // setHeightWidthForQuotePost([
    //                     //     ...heightWidthForQuotePost,
    //                     //     imageDimensions,
    //                     // ]);
    //                     // setImagePreview(render.result);

    //                 };
    //                 render.readAsDataURL(file);
    //             };
    //             // const reader = new FileReader();
    //             // reader.onloadend = () => {
    //             //     setImagePreview(reader.result);
    //             // };
    //             // reader.readAsDataURL(file);
    //             // render.onloadend = () => {
    //             //     var img = new Image();
    //             //     img.src = render.result;
    //             //     img.onload = () => {
    //             //         var obj = { height: img.height, width: img.width };
    //             //         imageDimensions.push(obj);

    //             //         setHeightWidthForQuotePost([
    //             //             ...heightWidthForQuotePost,
    //             //             imageDimensions,
    //             //         ]);
    //             //     };
    //             //     render.readAsDataURL(file[0]);
    //             // };
    //         }
    //         else if (file.type === 'application/pdf') {
    //             // Handle PDF preview
    //             setImagePreview(URL.createObjectURL(file));
    //         }
    //         else {
    //             setImagePreview(null);
    //         }
    //     } else {
    //         setImagePreview(null);
    //     }
    // };
    const getPDFDimensions = async (pdfUrl) => {
        return new Promise((resolve, reject) => {
            let pdfWidth, pdfHeight;
            pdfjs.getDocument(pdfUrl).promise.then((pdf) => {
                pdf.getPage(1).then((page) => {
                    const viewport = page.getViewport({ scale: 1 });
                    pdfWidth = viewport.width;
                    pdfHeight = viewport.height;
                    resolve({ width: pdfWidth, height: pdfHeight });
                });
            }).catch(error => {
                reject(error);
            });
        });
    };

    const handleImagePreview = async (e) => {
        // setDisableVideoInputForQuotePost(true);
        let file = e.target.files;
        setValue('upload_image', file)
        console.log('file: ', file);
        const render = new FileReader();

        render.onloadend = () => {
            var img = new Image();
            img.src = render.result;
            img.onload = () => {
                var obj = { height: img.height, width: img.width };
                imageDimensions.push(obj);

                setHeightWidthForQuotePost([
                    ...heightWidthForQuotePost,
                    imageDimensions,
                ]);
            };
            render.readAsDataURL(file[0]);
        };
        for (let i = 0; i < file.length; i++) {
            console.log('file[i].type: ', file[i].type);
            if (file[i].type === 'application/pdf') {
                setImagePreview([]);
                // const file2 = e.target.files[0];
                // const reader = new FileReader();

                // console.log('reader: ', reader);
                // reader.onloadend = () => {
                //     setPdf(reader.result);
                // };
                // reader.readAsArrayBuffer(file2);
                const reader = new FileReader();

                reader.onloadend = async () => {
                    const typedarray = new Uint8Array(reader.result);
                    const blob = new Blob([typedarray], { type: 'application/pdf' });
                    const pdfUrl = URL.createObjectURL(blob);

                    // Render the PDF and get its dimensions
                    const pdfDimensions = await getPDFDimensions(pdfUrl);
                    setPdf(reader.result);
                    // pdfDimensions.file = file[i];
                    setFileObj(pdfDimensions)
                    setIsShow(true);
                };

                reader.readAsArrayBuffer(file[i]);
            }
            else if (file[i].type.startsWith('image/')) {
                setThumb(null);
                setPdf(null)
                const render = new FileReader();
                render.onloadend = () => {
                    const image = new Image();
                    image.src = render.result;

                    image.onload = () => {
                        var obj = {
                            height: image.height,
                            width: image.width,
                            file: file[i],
                        };

                        console.log('obj: ', obj);
                        setFileObj(obj);
                        imageDimensions.push(obj);
                        // setWidth(imageDimensions?.width);
                        setHeightWidthForQuotePost([
                            // ...heightWidthForQuotePost,
                            imageDimensions
                        ]);
                        // setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);
                        setImagePreview([
                            // ...imagePreview,
                            e.target.files[i]
                        ]);
                        setIsShow(true);
                    };
                };
                render.readAsDataURL(file[i]);
                // setFileForRetweetQuote([...filesForRetweetQuote, file[i]]);

                // console.log("Line 219 ==========>", [
                //     ...filesForRetweetQuote,
                //     e.target.files[i],
                // ]);
            } else {
                setThumb(null);
                setPdf(null)
                setImagePreview([]);
                setIsShow(false)
            }

        }
        // setDisableVideoInputForQuotePost(true);
    };


    const formSubmit = async data => {
        console.log('data: ', data);
        let upload = '';
        let uploadThumb = '';
        console.log('fileObj: ', fileObj);
        if (fileObj?.file) {
            upload = await ReactS3Client.uploadFile(fileObj?.file);
            uploadThumb = await ReactS3ClientThumb.uploadFile(fileObj?.file);
        } else {
            upload = await ReactS3Client.uploadFile(newdata);
            uploadThumb = await ReactS3ClientThumb.uploadFile(newdata);
        }
        var obj = {
            name: upload.location.split("/")[6],
            only_name: "",
            only_thumb: "",
            type: 'document',
            width: fileObj?.width,
            height: fileObj?.height,
            thumb: uploadThumb.location.split("/")[6],
        };
        let request = {
            doc_title: data.title,
            doc_college_name: data.college_name,
            doc_category: data.category,
            doc_degree: data.degree,
            doc_subject: data.subject,
            doc_session: data.sessionYear,
            media: [obj],
            module_type: 'document',
            post_type: "normal",
        }
        console.log('obj: ', obj);
        console.log('request: ', request);
        add_post(request).then((res) => {
            console.log("Line 494", res);
            if (res?.code === 1 || res?.code === "1") {
                toast.info(res?.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                navigate('/profile', { state: location?.state })
            }
        });

    }
    return (
        <>
            {localStorage.getItem("isSignedUp") ||
                localStorage.getItem("isLoggedIn") ? (
                <>
                    <HeaderProfile />
                </>
            ) : (
                <Header />
            )}
            <section className="main_edit_profile">
                <div className="container">
                    <div className="row">
                        <div className="col-12 col-xl-8 col-lg-9 col-md-10 col-sm-12 mx-auto">
                            <div className="edit_text upload_form_document">
                                <form method="post" onSubmit={handleSubmit(formSubmit)}>
                                    <div className="row">
                                        <div className="col-md-12">
                                            <div className="row">
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <div className="upload_text_header">
                                                        <h3>Upload Document</h3>
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <div className="upload_form">
                                                        <div className="input-group apply_form">
                                                            <input type="text" className="form-control" name='title' placeholder="Title" {...register("title", {
                                                                required: 'Please Enter Title',
                                                                pattern: {
                                                                    value: /^(?!\s)(.*\S)(?<!\s)$/,
                                                                    message: 'Please Enter Title'
                                                                }
                                                            })} />
                                                        </div>
                                                    </div>
                                                    {errors.title && <small style={{ color: 'red' }}>{errors.title.message}</small>}
                                                </div>
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <div className="input-group apply_form">
                                                        <select className="form-control m-0" name='college_name' style={{ paddingLeft: '15px' }} {...register("college_name", { required: 'Please Enter College Name' })} >
                                                            <option value="" selected disabled>College Name</option>
                                                            {collegeName && collegeName.length > 0 && collegeName.map((value, index) => (
                                                                <option value={value.name} key={index}>{value.name}</option>
                                                            ))}
                                                        </select>
                                                    </div>
                                                    {errors.college_name && <small style={{ color: 'red' }}>{errors.college_name.message}</small>}
                                                </div>
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    {/* <div className="upload_form"> */}
                                                    <div className="input-group apply_form">
                                                        <select className="form-control m-0" name='category' style={{ paddingLeft: '15px' }} {...register("category", { required: 'Please Enter Category' })}>
                                                            <option value="" selected disabled>Category</option>
                                                            {categoryName && categoryName.length > 0 && categoryName.map((value, index) => (
                                                                <option value={value.name} key={index}>{value.name}</option>
                                                            ))}
                                                        </select>
                                                        {/* </div> */}
                                                    </div>
                                                    {errors.category && <small style={{ color: 'red' }}>{errors.category.message}</small>}
                                                </div>
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <div className="upload_form">
                                                        <div className="input-group apply_form">
                                                            <select className="form-control m-0" name='degree' style={{ paddingLeft: '15px' }} {...register("degree", { required: 'Please Enter Degree' })}>
                                                                <option value="" selected disabled>Degree</option>
                                                                {degreeName && degreeName.length > 0 && degreeName.map((value, index) => (
                                                                    <option value={value.name} key={index}>{value.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {errors.degree && <small style={{ color: 'red' }}>{errors.degree.message}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <div className="upload_form">
                                                        <div className="input-group apply_form">
                                                            <select className="form-control m-0" name='subject' style={{ paddingLeft: '15px' }} {...register("subject", { required: 'Please Enter Subject' })}>
                                                                <option value="" selected disabled>Subject</option>
                                                                {subjectName && subjectName.length > 0 && subjectName.map((value, index) => (
                                                                    <option value={value.name} key={index}>{value.name}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {errors.subject && <small style={{ color: 'red' }}>{errors.subject.message}</small>}
                                                    </div>
                                                </div>
                                                <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12 mb-3">
                                                    <div className="upload_form">
                                                        <div className="input-group apply_form">
                                                            <select className="form-control m-0" name="sessionYear" {...register("sessionYear", { required: 'Please Select Year' })} style={{ paddingLeft: '15px' }}>
                                                                <option value="" selected disabled>Select Year</option>
                                                                {Array.from({ length: currentYear - 2000 }, (_, i) => (
                                                                    <option key={i} value={`${2000 + i}-${2001 + i}`}>{`${2000 + i}-${2001 + i}`}</option>
                                                                ))}
                                                            </select>
                                                        </div>
                                                        {errors.sessionYear && <small style={{ color: 'red' }}>{errors.sessionYear.message}</small>}
                                                    </div>
                                                </div>

                                                <div className="col-12"
                                                    style={{ display: isShow === false ? 'block' : 'none' }}
                                                >
                                                    <div className="attchment_upload">
                                                        <div className="upload_flex">
                                                            <div className="dotted_flex">
                                                                <img src="assets/images/plus-circle-fill.svg" />
                                                                <input
                                                                    type="file"
                                                                    name="upload_image"
                                                                    accept=".jpg,.pdf,.png"
                                                                    {...register("upload_image", {
                                                                        required: () => fileRef?.current && fileRef?.current.files && fileRef.current?.files?.length > 0 || 'Please Select Upload Image',
                                                                        validate: value => {
                                                                            if (value) {
                                                                                const fileType = value[0]?.type;
                                                                                const allowedExtensions = ["image/jpeg", "application/pdf", "image/png"];
                                                                                if (!allowedExtensions.includes(fileType)) {
                                                                                    return 'Please select a valid file type: .jpg, .pdf, or .png';
                                                                                }
                                                                            }
                                                                            return true;
                                                                        }
                                                                    })}
                                                                    ref={fileRef}
                                                                    onChange={(e) => {
                                                                        handleImagePreview(e);
                                                                        if (fileRef?.current && fileRef?.current?.files && fileRef?.current?.files?.length > 0) {

                                                                        } else {
                                                                            setThumb(null);
                                                                            setPdf(null)
                                                                            setImagePreview([]);
                                                                            setIsShow(false)
                                                                        }
                                                                    }}
                                                                />

                                                            </div>
                                                            <div className="dotted_text">
                                                                <h6>Attch Files</h6>
                                                                <span>you can upload file .jpg .pdf .png</span>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                {/* {imagePreview && ( */}
                                                <div className="col-12" onClick={() => fileRef.current.click()}>
                                                    {imagePreview[0] && <img src={URL.createObjectURL(imagePreview[0])} alt="Preview" style={{ width: '-webkit-fill-available' }} />}
                                                </div>
                                                {/* )} */}
                                                {thumb ? <img src={thumb} alt="pdf preview" className="rounded overflow-hidden" onClick={() => {
                                                    fileRef.current.click()
                                                    setThumb(null);
                                                    setPdf(null);
                                                }} /> : pdf && (
                                                    <Document file={{ data: pdf }}>
                                                        <Page onRenderSuccess={() => {
                                                            canvasRef?.current?.toBlob((blob) => setThumb(URL.createObjectURL(blob)));
                                                            canvasRef?.current?.toBlob((blob) => {
                                                                // resolve(
                                                                setNewData(new File(
                                                                    [blob],
                                                                    `${[...Array(10)]
                                                                        .map(() =>
                                                                            String.fromCharCode(
                                                                                Math.floor(Math.random() * 26) +
                                                                                (Math.random() > 0.5 ? 65 : 97)
                                                                            )
                                                                        )
                                                                        .join("")}.jpeg`,
                                                                    { type: "image/jpeg" }
                                                                ))
                                                                // );
                                                            }, "image/jpeg", 1.0);
                                                        }}
                                                            height={1000}
                                                            canvasRef={canvasRef}
                                                            className="rounded overflow-hidden shadow-lg"
                                                            renderTextLayer={false}
                                                            pageNumber={1}
                                                        />
                                                    </Document>
                                                )}
                                                {errors.upload_image && <small style={{ color: 'red' }}>{errors.upload_image.message}</small>}
                                                {errors?.upload_image?.message == "" && <small style={{ color: 'red' }}>Please Select Image or Pdf</small>}
                                                <div className="col-12 mt-4 text-center">
                                                    <button type="submit" className="btn btn-theme w-auto"> Next </button>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </section>

        </>
    )
}

export default UploadDocument