import React from "react";
import { Link } from "react-router-dom";

const TempHome = () => {
  return (
    <>
      <header className="main_header">
        <div className="main_nav">
          <div className="middle_nav">
            <div className="container">
              <div className="row align-items-center">
                <div className="col-12 col-xl-3 col-lg-3 col-md-3 col-sm-12">
                  <Link to="/" className="header_logo">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/stuLink-logo.png"
                      }
                      className="img-fluid"
                      alt="logo"
                    />
                  </Link>
                </div>
                <div className="col-12 col-xl-6 col-lg-6 col-md-9 col-sm-12">
                  {/* <div className="header_input input-group">
                    <input
                      type="text"
                      className="form-control"
                      placeholder="SEARCH"
                      aria-label="Username"
                      aria-describedby="basic-addon1"
                    />
                    <Link
                      className="input-group-text"
                      id="basic-addon1"
                      to={
                        localStorage.getItem("isSignedUp") ||
                        localStorage.getItem("isLoggedIn")
                          ? "/search"
                          : "/"
                      }
                    >
                      <img
                        src="assets/images/icons/search.svg"
                        className="img-fluid cursor-pointer"
                        alt="search"
                      />
                    </Link>
                  </div> */}
                </div>

                <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
                  {/* <div className="row">
                    <div className="col-6 d-none d-lg-block">
                      <Link to="/login" className="login_button">
                        <span className="icon-login"></span>Log In
                      </Link>
                    </div>
                    <div className="col-6 d-none d-lg-block">
                      <Link to="/sign-up" className="login_button">
                        <span className="icon-signup"></span>Sign Up
                      </Link>
                    </div>
                  </div> */}
                </div>
              </div>
            </div>
          </div>
          {/* <div className="last_nav">
            <div className="container">
              <nav className="navbar navbar-expand-lg">
                <Link className="navbar-brand" to="/">
                  <img
                    src={
                      process.env.PUBLIC_URL + "assets/images/icons/home.svg"
                    }
                    className="img-fluid"
                    alt="home"
                  />
                </Link>
                <button
                  className="navbar-toggler"
                  type="button"
                  data-bs-toggle="collapse"
                  data-bs-target="#navbarSupportedContent"
                  aria-controls="navbarSupportedContent"
                  aria-expanded="false"
                  aria-label="Toggle navigation"
                >
                  <span className="navbar-toggler-icon"></span>
                </button>
                <div
                  className="collapse navbar-collapse"
                  id="navbarSupportedContent"
                >
                  <ul className="navbar-nav ms-auto justify-content-around w-100">
                    <li className="nav-item">
                      <Link
                        to="/terms-of-use"
                        className="nav-link white_ff font_16"
                      >
                        Teams and Conditions
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link
                        to="/privacy-policy"
                        className="nav-link white_ff font_16"
                      >
                        Privacy Policy
                      </Link>
                    </li>
                    <li className="nav-item">
                      <Link to={"/login"} className="nav-link white_ff font_16">
                        Support Us
                      </Link>
                    </li>
                  </ul>
                </div>
              </nav>
            </div>
          </div> */}
        </div>
      </header>

      <section className="dream_college" style={{ padding: "80px 0 110px 0" }}>
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <Link to={"/home"}>
                <p className="welcome_heading blue_29 font_500 font_20">
                  WELCOME TO STULINK
                </p>
              </Link>
              <h1 className="inter black_00 font_55 font_800">
                Connecting 
                <span className="blue_29">Students!</span>
              </h1>
              <div className="main_heading_text">
                <span className="heading_text gray_5a">
                  STULINK, A student exclusive social media app aiming to
                  connect students to assist with collaboration & networking.
                </span>
              </div>
              <div className="row mt-5 ">
                <div className="col-12 d-flex justify-content-center">
                  <div className="features_image">
                    <h4 className="footer_features_heading text-center mb-3">
                      Download App
                    </h4>
                    <ul className="d-flex">
                      <li className="me-3">
                        <a
                          href="https://apps.apple.com/in/app/stulink/id6470642364"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/app-store.svg"
                            }
                            className="app_store img-fluid"
                            alt="apple-store"
                          />
                        </a>
                      </li>
                      <li>
                        <a
                          href="https://play.google.com/store/apps/details?id=com.stulink.app"
                          target="_blank"
                          rel="noreferrer"
                        >
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/play-store.svg"
                            }
                            className="app_store img-fluid"
                            alt="google-play"
                          />
                        </a>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>

            <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
              <div className="clg_find_img">
                <img
                  src={
                    process.env.PUBLIC_URL +
                    "assets/images/page-image/college-find.svg"
                  }
                  className="img-fluid"
                  alt="image"
                />
              </div>
            </div>
          </div>
        </div>
      </section>
      {/* <div className="container">
        <div className="row mb-5">
          <div className="col-12 d-flex justify-content-center">
            <div className="features_image footer_features">
              <h4 className="footer_features_heading text-center mb-3">
                Download App
              </h4>
              <ul className="d-flex">
                <li className="me-3">
                  <Link to="#">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/app-store.svg"
                      }
                      className="app_store img-fluid"
                      alt="apple-store"
                    />
                  </Link>
                </li>
                <li>
                  <Link to="#">
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/play-store.svg"
                      }
                      className="app_store img-fluid"
                      alt="google-play"
                    />
                  </Link>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </div> */}
    </>
  );
};

export default TempHome;
