import moment from "moment";
import React from "react";
import { Link, useNavigate } from "react-router-dom";

export const getTimeDifference = (dateString) => {
  // Parse the date string using Moment.js
  const date = moment(dateString);

  // Get the current time using Moment.js
  const now = moment();

  // Calculate the difference in hours
  const hoursDifference = now.diff(date, "hours");

  if (hoursDifference < 24) {
    console.log("hoursDifference: ", hoursDifference);
    // If the difference is less than 24 hours, return the difference in hours
    if (hoursDifference < 1) {
      if (now.diff(date, "minute") === 0) {
        return "Just now";
      }
      return now.diff(date, "minute") + " Minutes ago";
    } else {
      return hoursDifference + " hours ago";
    }
  } else {
    // If the difference is 24 hours or more, return the formatted date
    return date.format("DD MMM, hh:mm A");
  }
};

// import buildFormatter from "react-time-ago/formatters/buildFormatter";
const PostUserDetails = (props) => {
  const {
    item,
    setPostPermission,
    setSinglePost,
    disableMenu = false,
    muteIcon = false,
    interaction,
    toggleMute,
    muted,
    mainItem = {},
    isRetweetComment = false,
  } = props;
  const navigate = useNavigate();
  return (
    <div className="home_post_user ">
      <Link to={"/profile"} state={item?.user_id} className="post_main">
        <div className="post_img_user">
          <img
            src={
              item?.profile_image ||
              process.env.PUBLIC_URL + "/assets/images/user/image.png"
            }
            alt={item?.profile_image}
          />
        </div>
      </Link>
      <div className="user_name cursor-pointer">
        {/* <Link to={"/profile"} state={item?.user_id}> */}
        <h5
          onClick={() => {
            navigate("/profile", {
              state: item?.user_id,
            });
          }}
        >
          {item.name}
          {item?.is_role_verified == "approved" ? (
            <img
              src={process.env.PUBLIC_URL + "assets/images/verify.svg"}
              alt=""
            />
          ) : (
            ""
          )}
        </h5>
        <span
          onClick={() => {
            navigate("/profile", {
              state: item?.user_id,
            });
          }}
        >
          @{item?.username}
        </span>
        {/* </Link> */}
        <p onClick={() => {}}>{item?.location}</p>
      </div>
      <div className="post_option">
        {!disableMenu && (
          <a>
            <img
              onClick={() => {
                setSinglePost(isRetweetComment ? mainItem : item);
                setPostPermission(true);
              }}
              src={process.env.PUBLIC_URL + "/assets/images/more.svg"}
              alt=""
            />
          </a>
        )}
        <span>{item?.insertdate && getTimeDifference(item?.insertdate)}</span>
        {/* {muteIcon ? (
          interaction ? (
            <img
              onClick={toggleMute}
              className="video_mute cursor-pointer"
              src={
                muted
                  ? process.env.PUBLIC_URL +
                    "/assets/video/icons/volume_off.svg"
                  : process.env.PUBLIC_URL + "/assets/video/icons/volume.svg"
              }
              alt=""
            />
          ) : (
            <img
              className="video_mute"
              src={
                process.env.PUBLIC_URL + "/assets/video/icons/volume_off.svg"
              }
              alt=""
            />
          )
        ) : null} */}
      </div>
    </div>
  );
};

export default PostUserDetails;
