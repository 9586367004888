import React, { useEffect, useState } from 'react'
import { Link } from 'react-router-dom'
import { faq_list } from '../api/apiHandler';

export default function Faq() {
  const [faqList, setFaqList] = useState();
  useEffect(() => {
    faq_list({ page_token: 1 }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        console.log('response: ', response);
        setFaqList(response.data);
      }
    })
  }, [])
  return (
    <>
      <div className="information_box">
        <div className="information_box_heading justify-content-center">
          <img src={process.env.PUBLIC_URL + "/assets/images/icons/faq.svg"} className="img-fluid" alt="icon" />
          <h3 className="blue_29 font_500 font_25 mb-0">FAQs</h3>
        </div>
        <div className="information_box_body ps-0">
          <div className="accordion accordion-flush" id="accordionFlushExample">
            {faqList && faqList?.length > 0 && faqList?.map((value, index) => (
              <div className="accordion-item" key={index}>
                <h2 className="accordion-header" id={`flush-heading${index}`}>
                  <button className="accordion-button collapsed font_16" type="button"
                    data-bs-toggle="collapse"
                    data-bs-target={`#flush-collapse${index}`} aria-expanded="false"
                    aria-controls={`flush-collapse${index}`}>
                    {index + 1}. {value.question}
                  </button>
                </h2>
                <div id={`flush-collapse${index}`} className="accordion-collapse collapse"
                  aria-labelledby={`flush-heading${index}`}
                  data-bs-parent="#accordionFlushExample">
                  <div className="accordion-body">{value.answer}</div>
                </div>
              </div>
            ))}
          </div>
          {/* <div className="text-center">
            <Link to="#" className="blue_29">View More<img
              src={process.env.PUBLIC_URL+"/assets/images/icons/down-blue-arrow.svg"}
              className="img-fluid ms-2" alt="icon" /></Link>
          </div> */}
        </div>
      </div>
    </>
  )
}
