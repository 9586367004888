import React, { useCallback, useEffect, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import { Link, useLocation, useNavigate } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import S3 from "react-aws-s3";
import ReactTimeAgo from "react-time-ago";
import EmojiPicker from "emoji-picker-react";
import OwlCarousel from "react-owl-carousel";
import "owl.carousel/dist/assets/owl.carousel.css";
import "owl.carousel/dist/assets/owl.theme.default.css";
import {
  add_comments,
  add_post,
  get_comment_list,
  give_comments_like_unlike,
  share_link,
  undo_post_retweet,
} from "../../api/apiHandler";
import InfiniteScroll from "react-infinite-scroll-component";
import NormalPostType from "../pages/HomeFeed/module/NormalPostType";
import ShareLink from "../pages/HomeFeed/components/ShareLink";
import Comments from "./components/Comments";
import PostMenu from "../pages/HomeFeed/components/postMenu";
import { getTimeDifference } from "../pages/HomeFeed/components/PostUserDetails";

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/image",
};

const configVideo = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/video",
};

const configComment = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media",
};

const configCommentThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/post_comment_media/thumb",
};

const configThumb = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/post/thumb",
};

const ReactS3Client = new S3(config);
const ReactS3ClientVideo = new S3(configVideo);
const ReactS3ClientComment = new S3(configComment);
const ReactS3ClientCommentThumb = new S3(configCommentThumb);
const ReactS3ClientThumb = new S3(configThumb);

export default function SubComments() {
  var location = useLocation();
  // const navigate = useNavigate();
  // const maxHeight = "500px";
  // const maxHeightInInt = 500;
  const [mainComment, setMainComment] = useState({});
  console.log("mainComment: ", mainComment);

  const [subCommentList, setSubCommentsList] = useState([]);
  console.log("subCommentList: ", subCommentList);
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });

  const [postComment, setPostComment] = useState("");
  const [emojiPickerForComment, setEmojiPickerForComment] = useState(false);
  const [filesForMainComment, setFilesForMainComment] = useState([]);
  const [selectedFilesForMainComment, setSelectedFilesForMainComment] =
    useState([]);
  const [heightWidthForMainComment, setHeightWidthForMainComment] = useState(
    []
  );
  const [commentQuote, setCommentQuote] = useState("");
  const [filesForCommentQuotePost, setFilesForCommentQuotePost] = useState([]);
  const [
    selectedFilesForCommentQuotePost,
    setSelectedFilesForCommentQuotePost,
  ] = useState([]);
  const [heightWidthForCommentQuotePost, setHeightWidthForCommentQuotePost] =
    useState([]);
  const [emojiPickerForCommentQuote, setEmojiPickerForCommentQuote] =
    useState(false);
  const [singleSubComment, setSingleSubComment] = useState();
  const [subComment, setSubComment] = useState("");
  const [filesForSubComments, setFilesForSubComments] = useState([]);
  const [selectedFilesForSubComments, setSelectedFilesForSubComments] =
    useState([]);
  const [heightWidthForSubComments, setHeightWidthForSubComments] = useState(
    []
  );
  const [emojiPickerForSubComments, setEmojiPickerForSubComments] =
    useState(false);
  const [subCommentWithQuotes, setSubCommentWithQuotes] = useState("");
  const [filesForSubCommentWithQuotes, setFilesForSubCommentWithQuotes] =
    useState([]);
  const [
    selectedFilesForSubCommentWithQuotes,
    setSelectedFilesForSubCommentWithQuotes,
  ] = useState([]);
  const [
    heightWidthForSubCommentsWithQuotes,
    setHeightWidthForSubCommentsWithQuotes,
  ] = useState([]);
  const [
    emojiPickerForSubCommentsWithQuotes,
    setEmojiPickerForSubCommentsWithQuotes,
  ] = useState(false);
  const [pageToken, setPageToken] = useState(1);
  const [resetPageToken, setResetPageToken] = useState(false);

  const [isFetching, setIsFetching] = useState(false);
  const [disablePhotoInputForMainComment, setDisablePhotoInputForMainComment] =
    useState(false);
  const [disableVideoInputForMainComment, setDisableVideoInputForMainComment] =
    useState(false);
  const [thumbnailForMainComment, setThumbnailForMainComment] = useState("");
  const [
    disablePhotoInputForCommentQuotePost,
    setDisablePhotoInputForCommentQuotePost,
  ] = useState(false);
  const [
    disableVideoInputForCommentQuotePost,
    setDisableVideoInputForCommentQuotePost,
  ] = useState(false);
  const [thumbnailForCommentQuotePost, setThumbnailForCommentQuotePost] =
    useState("");
  const [disablePhotoInputForSubComments, setDisablePhotoInputForSubComments] =
    useState(false);
  const [disableVideoInputForSubComments, setDisableVideoInputForSubComments] =
    useState(false);
  const [thumbnailForSubComments, setThumbnailForSubComments] = useState("");
  const [
    disablePhotoInputForSubCommentsWithQuotes,
    setDisablePhotoInputForSubCommentsWithQuotes,
  ] = useState(false);
  const [
    disableVideoInputForSubCommentsWithQuotes,
    setDisableVideoInputForSubCommentsWithQuotes,
  ] = useState(false);
  const [
    thumbnailForSubCommentWithQuotes,
    setThumbnailForSubCommentWithQuotes,
  ] = useState(false);
  var imageDimensions = [];
  var newArr = [];

  function isImageValid(blobOrFile, callback) {
    const image = new Image();
    image.onload = function () {
      if (image.width > 0 && image.height > 0) {
        callback(true);
      } else {
        callback(false);
      }
    };
    image.onerror = function () {
      callback(false);
    };

    const blobURL = URL.createObjectURL(blobOrFile);
    image.src = blobURL;
  }

  const [postPermission, setPostPermission] = useState(false);
  const [singlePost, setSinglePost] = useState([]);

  const getCommentListForSubComments = useCallback(
    (payload_) => {
      setIsFetching(true);

      try {
        get_comment_list(payload_).then((response) => {
          console.log("payload_ response: ", response);
          if (response?.code === 1 || response?.code === "1") {
            if (
              response?.code === 1 &&
              response?.message === "Comment list not found."
            ) {
              setIsFetching(false);
              // setSubCommentsList([]);
              if (payload_.page_token == 1) {
                setSubCommentsList([]);
              }
            } else {
              if (filesForSubComments?.length > 0) {
                setSubCommentsList(response?.data?.comment_list);
              } else if (payload_.page_token == 1) {
                console.log(
                  "payload_.page_token == 1: ",
                  payload_.page_token == 1,
                  response?.data?.comment_list
                );
                setSubCommentsList(response?.data?.comment_list);
              } else {
                setSubCommentsList((prevComments) => [
                  ...prevComments,
                  ...response?.data?.comment_list,
                ]);
              }
            }
          } else if (response?.code === 2 || response?.code === "2") {
            setIsFetching(false);
          } else {
            setIsFetching(false);
          }
        });
      } catch (e) {}
    },
    [filesForSubComments?.length]
  );

  const mainCommentLikeUnlike = () => {
    const isLike = mainComment?.is_like === 0 ? "like" : "unlike";
    const likeCount =
      isLike === "like" ? mainComment?.likes + 1 : mainComment?.likes - 1;
    give_comments_like_unlike({
      owner_user_id: mainComment?.owner_user_id,
      type: isLike,
      comments_id: mainComment?._id,
      post_id: mainComment?.post_id,
    }).then((response) => {
      console.log("response: ", response);
      if (response.code === 1 || response.code === "1") {
        setMainComment({
          ...mainComment,
          is_like: isLike === "like" ? 1 : 0,
          likes: likeCount,
        });
      }
    });
  };

  const handleFileInputForMainComment = (e) => {
    setDisableVideoInputForMainComment(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForMainComment(...imageDimensions, imageDimensions);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);

            setHeightWidthForMainComment([
              ...heightWidthForMainComment,
              imageDimensions,
            ]);
            setFilesForMainComment([...filesForMainComment, file[i]]);
            setSelectedFilesForMainComment([
              ...filesForMainComment,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFilesForMainComment([...filesForMainComment, file[i]]);

        console.log("Line 136 ==========>", [
          ...filesForMainComment,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForMainComment(true);
  };

  const handleVideoUploadForMainComment = (event) => {
    setDisablePhotoInputForMainComment(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForMainComment([
            ...heightWidthForMainComment,
            imageDimensions,
          ]);
          setSelectedFilesForMainComment([...filesForMainComment, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForMainComment(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFilesForMainComment([...filesForMainComment, videoFile]);
    }
  };

  const handleVideoUploadForRetweetWithQuotes = (event) => {
    setDisablePhotoInputForCommentQuotePost(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForCommentQuotePost(imageDimensions);
          setSelectedFilesForCommentQuotePost([
            ...filesForCommentQuotePost,
            videoFile,
          ]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForCommentQuotePost(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFilesForCommentQuotePost([...filesForCommentQuotePost, videoFile]);
    }
  };

  const handleVideoUploadForSubComments = (event) => {
    setDisablePhotoInputForSubComments(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForSubComments([
            ...heightWidthForSubComments,
            imageDimensions,
          ]);
          setSelectedFilesForSubComments([...filesForSubComments, videoFile]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForSubComments(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFilesForSubComments([...filesForSubComments, videoFile]);
    }
  };

  const handleVideoUploadForSubCommentsWithQuotes = (event) => {
    setDisablePhotoInputForSubCommentsWithQuotes(true);
    const videoFile = event.target.files[0];
    if (videoFile) {
      const reader = new FileReader();
      reader.onload = function (event) {
        const videoElement = document.createElement("video");
        videoElement.src = event.target.result;

        videoElement.addEventListener("canplay", function () {
          console.log(
            "Video dimensions:",
            videoElement.videoWidth,
            videoElement.videoHeight
          );
          var obj = {
            height: videoElement.videoHeight,
            width: videoElement.videoWidth,
          };
          imageDimensions.push(obj);
          setHeightWidthForSubCommentsWithQuotes(imageDimensions);
          setSelectedFilesForSubCommentWithQuotes([
            ...filesForSubCommentWithQuotes,
            videoFile,
          ]);
          const canvas = document.createElement("canvas");
          canvas.width = videoElement.videoWidth;
          canvas.height = videoElement.videoHeight;
          const ctx = canvas.getContext("2d");
          ctx.drawImage(videoElement, 0, 0, canvas.width, canvas.height);
          canvas.toBlob(function (blob) {
            isImageValid(blob, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            const thumbnailFile = new File([blob], "thumbnail.jpeg", {
              type: "image/jpeg",
            });

            isImageValid(thumbnailFile, (isValid) => {
              if (isValid) {
              } else {
              }
            });
            setThumbnailForSubCommentWithQuotes(thumbnailFile);
          }, "image/jpeg");
          const thumbnailUrl = canvas.toDataURL("image/jpeg");
        });
      };
      reader.readAsDataURL(videoFile);
      setFilesForSubCommentWithQuotes([
        ...filesForSubCommentWithQuotes,
        videoFile,
      ]);
    }
  };

  const uploadImagesForMainComment = async () => {
    if (selectedFilesForMainComment?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFilesForMainComment?.length; j++) {
        let upload;
        let uploadThumb;
        if (selectedFilesForMainComment[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFilesForMainComment[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForMainComment
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFilesForMainComment[j]
          );
        }

        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFilesForMainComment[j]?.type?.split("/")[0],
          width: heightWidthForMainComment[j][0]?.width,
          height: heightWidthForMainComment[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        newArr.push(obj);
      }

      addCommentToMainComment(newArr);
    }
  };

  const handleFileInputForCommentQuotePost = (e) => {
    setDisableVideoInputForCommentQuotePost(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForCommentQuotePost(...imageDimensions, imageDimensions);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);

            setHeightWidthForCommentQuotePost([
              ...heightWidthForCommentQuotePost,
              imageDimensions,
            ]);
            setFilesForCommentQuotePost([...filesForCommentQuotePost, file[i]]);
            setSelectedFilesForCommentQuotePost([
              ...filesForCommentQuotePost,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFilesForMainComment([...filesForCommentQuotePost, file[i]]);

        console.log("Line 136 ==========>", [
          ...filesForCommentQuotePost,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForCommentQuotePost(true);
  };

  const uploadImagesForCommentQuotePost = async () => {
    if (selectedFilesForCommentQuotePost?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFilesForCommentQuotePost?.length; j++) {
        let upload;
        let uploadThumb;
        if (
          selectedFilesForCommentQuotePost[j]?.type?.split("/")[0] === "video"
        ) {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFilesForCommentQuotePost[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForCommentQuotePost
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFilesForCommentQuotePost[j]
          );
        }

        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFilesForCommentQuotePost[j]?.type?.split("/")[0],
          width: heightWidthForCommentQuotePost[j][0]?.width,
          height: heightWidthForCommentQuotePost[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        newArr.push(obj);
      }

      mainCommentWithQuotePost(newArr);
    }
  };

  const handleFileInputForSubComments = (e) => {
    setDisableVideoInputForSubComments(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForSubComments(...imageDimensions, imageDimensions);
      };
      render.readAsDataURL(file[0]);
    };

    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);

            setHeightWidthForSubComments(imageDimensions);
            setFilesForSubComments([...filesForSubComments, file[i]]);
            setSelectedFilesForSubComments([
              ...filesForSubComments,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFilesForSubComments([...filesForSubComments, file[i]]);

        console.log("Line 136 ==========>", [
          ...filesForSubComments,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForSubComments(true);
  };

  const uploadImagesForSubComments = async () => {
    if (selectedFilesForSubComments?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFilesForSubComments?.length; j++) {
        let upload;
        let uploadThumb;
        if (selectedFilesForSubComments[j]?.type?.split("/")[0] === "video") {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFilesForSubComments[j]
          );
          uploadThumb = await ReactS3ClientCommentThumb.uploadFile(
            thumbnailForSubComments
          );
        } else {
          upload = await ReactS3ClientComment.uploadFile(
            selectedFilesForSubComments[j]
          );
        }

        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFilesForSubComments[j]?.type?.split("/")[0],
          width: heightWidthForSubComments[j][0]?.width,
          height: heightWidthForSubComments[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        newArr.push(obj);
      }

      addSubComment(newArr);
    }
  };

  const handleFileInputForSubCommentQuotePost = (e) => {
    setDisableVideoInputForSubCommentsWithQuotes(true);
    let file = e.target.files;

    const render = new FileReader();

    render.onloadend = () => {
      var img = new Image();
      img.src = render.result;
      img.onload = () => {
        var obj = { height: img.height, width: img.width };
        imageDimensions.push(obj);

        setHeightWidthForSubCommentsWithQuotes(
          ...imageDimensions,
          imageDimensions
        );
      };
      render.readAsDataURL(file[0]);
    };
    for (let i = 0; i < file.length; i++) {
      if (
        file[i].type.split("/")[0] === "video" &&
        file.length > 1 &&
        file.duration > 30
      ) {
        alert("You can either upload only one video or more than one image");
        return false;
      } else {
        const render = new FileReader();
        render.onloadend = () => {
          const image = new Image();
          image.src = render.result;

          image.onload = () => {
            var obj = {
              height: image.height,
              width: image.width,
              file: file[i],
            };
            imageDimensions.push(obj);

            setHeightWidthForSubCommentsWithQuotes([
              ...heightWidthForSubCommentsWithQuotes,
              imageDimensions,
            ]);
            setFilesForSubCommentWithQuotes([
              ...filesForSubCommentWithQuotes,
              file[i],
            ]);
            setSelectedFilesForSubCommentWithQuotes([
              ...filesForSubCommentWithQuotes,
              e.target.files[i],
            ]);
          };
        };
        render.readAsDataURL(file[i]);
        setFilesForSubCommentWithQuotes([
          ...filesForSubCommentWithQuotes,
          file[i],
        ]);

        console.log("Line 136 ==========>", [
          ...filesForSubCommentWithQuotes,
          e.target.files[i],
        ]);
      }
    }
    setDisableVideoInputForSubCommentsWithQuotes(true);
  };

  const uploadImagesForSubCommentQuotePost = async () => {
    if (selectedFilesForSubCommentWithQuotes?.length > 4) {
      toast.info("You can't upload more than 4 media", {
        toastId: "imageLength",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      for (var j = 0; j < selectedFilesForSubCommentWithQuotes?.length; j++) {
        let upload;
        let uploadThumb;
        if (
          selectedFilesForSubCommentWithQuotes[j]?.type?.split("/")[0] ===
          "video"
        ) {
          upload = await ReactS3ClientVideo.uploadFile(
            selectedFilesForSubCommentWithQuotes[j]
          );
          uploadThumb = await ReactS3ClientThumb.uploadFile(
            thumbnailForSubCommentWithQuotes
          );
        } else {
          upload = await ReactS3Client.uploadFile(
            selectedFilesForSubCommentWithQuotes[j]
          );
        }

        var obj = {
          name: upload.location.split("/")[6],
          type: selectedFilesForSubCommentWithQuotes[j]?.type?.split("/")[0],
          width: heightWidthForSubCommentsWithQuotes[j][0]?.width,
          height: heightWidthForSubCommentsWithQuotes[j][0]?.height,
          thumb:
            uploadThumb !== undefined
              ? uploadThumb?.location?.split("/")[6]
              : "",
        };

        newArr.push(obj);
      }

      subCommentWithQuotePost(newArr);
    }
  };

  const addCommentToMainComment = (images) => {
    if (
      (postComment === "" || postComment === undefined) &&
      images?.length < 0
    ) {
      toast.info("Please enter some text before post or upload media", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      add_comments({
        post_id: mainComment?.post_id,
        comments_type: "sub",
        reply_comment_id: mainComment?._id,
        owner_user_id: mainComment?.owner_user_id,
        media: images,
        hashtags: [],
        description: postComment,
      }).then((response) => {
        console.log("response:=========>965 ", response);
        if (response.code === 1 || response.code === "1") {
          toast.info(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "sub-comment-success",
          });
          const newComment = mainComment?.comment_count + 1;
          setMainComment({
            ...mainComment,
            comment_count: newComment,
          });
          const updatedFeeds = subCommentList?.map((post) => {
            if (post?._id === mainComment?._id) {
              return {
                ...post,
                comment_count: post?.comment_count + 1,
              };
            }
            return post;
          });
          setSubCommentsList((prev) => [response.data, ...prev]);
          getCommentListForSubComments();
          setPostComment("");
          setFilesForMainComment();
          setSelectedFilesForMainComment();
          setHeightWidthForMainComment();
          setEmojiPickerForComment(false);
          setDisablePhotoInputForMainComment(false);
          setDisableVideoInputForMainComment(false);
        }
      });
    }
  };

  const retweetMainComment = (comment) => {
    const isRetweet = mainComment?.is_retweet;
    const retweetCount = mainComment?.retweet_count + 1;
    add_post({
      description: comment?.description,
      media: [],
      retweet_id: comment?._id,
      post_type: "retweet_comments",
      shared_feed: comment,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setMainComment({
          ...mainComment,
          is_retweet: 1,
          retweet_count: retweetCount,
        });
      }
    });
  };

  const undoRetweetMainComment = (comment) => {
    const isRetweet = mainComment?.is_retweet;
    const retweetCount = mainComment?.retweet_count - 1;
    undo_post_retweet({ id: comment?._id }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        setMainComment({
          ...mainComment,
          is_retweet: 0,
          retweet_count: retweetCount,
        });
      }
    });
  };

  const mainCommentWithQuotePost = (images) => {
    const retweetCount = mainComment?.retweet_count + 1;
    add_post({
      description: commentQuote,
      media: images,
      retweet_id: mainComment?._id,
      post_type:
        (commentQuote === "" || commentQuote === undefined) &&
        images.length == 0
          ? "retweet_comments"
          : "retweet_with_quote",
      shared_feed: mainComment,
      module_type: "post",
    }).then((res) => {
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setMainComment({
          ...mainComment,
          is_retweet: 1,
          retweet_count: retweetCount,
        });
        setCommentQuote("");
        getCommentListForSubComments();
        setFilesForCommentQuotePost();
        setSelectedFilesForCommentQuotePost();
        setHeightWidthForCommentQuotePost();
        setEmojiPickerForCommentQuote(false);
        setDisablePhotoInputForCommentQuotePost(false);
        setDisableVideoInputForCommentQuotePost(false);
      }
    });
  };

  console.log("subCommentWithQuotes: ", subCommentWithQuotes);
  const subCommentWithQuotePost = (images) => {
    const retweetCount = mainComment?.retweet_count + 1;
    var params = {
      description: subCommentWithQuotes,
      media: images,
      // retweet_id: singleSubComment?._id,
      post_type:
        (subCommentWithQuotes === "" || subCommentWithQuotes === undefined) &&
        images.length == 0
          ? "retweet_comments"
          : "retweet_with_quote",
      shared_feed: singleSubComment,
      module_type: "post",
    };

    console.log("params: ", params);
    add_post(params).then((res) => {
      if (res.code === 1 || res.code === "1") {
        toast.info(res.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        setMainComment({
          ...mainComment,
          is_retweet: 1,
          retweet_count: retweetCount,
        });
        const updatedCommentList = subCommentList?.map((comment) => {
          if (comment?._id === singleSubComment?._id) {
            return {
              ...comment,
              is_retweet: 1,
              retweet_count: comment?.retweet_count + 1,
            };
          }
          return comment;
        });
        setSubCommentWithQuotes("");
        setSubCommentsList(updatedCommentList);
        getCommentListForSubComments();
        setFilesForCommentQuotePost();
        setSelectedFilesForCommentQuotePost();
        setHeightWidthForCommentQuotePost();
        setEmojiPickerForCommentQuote(false);
      }
    });
  };

  const removeImagesForMainComment = (index) => {
    var x = [...filesForMainComment];
    var w = [...heightWidthForMainComment];

    x.splice(index, 1);
    w.splice(index, 1);

    setFilesForMainComment(x);
    setSelectedFilesForMainComment(x);
    setHeightWidthForMainComment(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForMainComment(false);
      setDisableVideoInputForMainComment(false);
    }
  };

  const removeImageForCommentQuote = (index) => {
    var x = [...filesForCommentQuotePost];
    var w = [...heightWidthForCommentQuotePost];

    x.splice(index, 1);
    w.splice(index, 1);

    setFilesForCommentQuotePost(x);
    setSelectedFilesForCommentQuotePost(x);
    setHeightWidthForCommentQuotePost(w);
  };

  const removeImagesForSubComments = (index) => {
    var x = [...filesForSubComments];
    var w = [...heightWidthForSubComments];

    x.splice(index, 1);
    w.splice(index, 1);

    setFilesForSubComments(x);
    setSelectedFilesForSubComments(x);
    setHeightWidthForSubComments(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForSubComments(false);
      setDisableVideoInputForSubComments(false);
    }
  };

  const removeImagesForSubCommentsWithQuotes = (index) => {
    var x = [...filesForSubCommentWithQuotes];
    var w = [...heightWidthForSubCommentsWithQuotes];

    x.splice(index, 1);
    w.splice(index, 1);

    setFilesForSubCommentWithQuotes(x);
    setSelectedFilesForSubCommentWithQuotes(x);
    setHeightWidthForSubCommentsWithQuotes(w);
    if (x?.length === 0 || w?.length === 0) {
      setDisablePhotoInputForSubCommentsWithQuotes(false);
      setDisableVideoInputForSubCommentsWithQuotes(false);
    }
  };

  const likeUnlikeSubComments = (item) => {
    console.log("item:======================>>>> ", item);
    give_comments_like_unlike({
      owner_user_id: item.owner_user_id,
      type: item.is_like === 0 ? "like" : "unlike",
      comments_id: item?._id,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        const updatedCommentList = subCommentList?.map((comment) => {
          if (comment?._id === item?._id) {
            const newLikes = item.is_like
              ? comment?.likes - 1
              : comment?.likes + 1;
            const newIsLike = item.is_like ? 0 : 1;
            return {
              ...comment,
              is_like: newIsLike,
              likes: newLikes,
            };
          }
          return comment;
        });
        setSubCommentsList(updatedCommentList);
      }
    });
  };

  const addSubComment = (images) => {
    if ((subComment === "" || subComment === undefined) && images?.length < 0) {
      toast.info("Please enter some text before post or upload media", {
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      add_comments({
        post_id: mainComment?.post_id,
        comments_type: "sub",
        reply_comment_id: singleSubComment?._id,
        owner_user_id: singleSubComment?.owner_user_id,
        media: images,
        hashtags: [],
        description: subComment,
      }).then((response) => {
        if (response.code === 1 || response.code === "1") {
          toast.info(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
            toastId: "sub-comment-success",
          });
          const updatedCommentList = subCommentList?.map((comment) => {
            if (comment?._id === singleSubComment?._id) {
              return {
                ...comment,
                comment_count: comment?.comment_count + 1,
              };
            }
            return comment;
          });
          setSubCommentsList(updatedCommentList);
          setSubComment("");
          getCommentListForSubComments();
          setFilesForSubComments();
          setSelectedFilesForSubComments();
          setHeightWidthForSubComments();
          setEmojiPickerForSubComments(false);
        }
      });
    }
  };

  const retweetSubComment = (subComment) => {
    add_post({
      description: subComment?.description,
      media: [],
      retweet_id: subComment?._id,
      post_type: "retweet_comments",
      shared_feed: subComment,
    }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        toast.info(response.message, {
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
      const updatedCommentList = subCommentList?.map((comment) => {
        if (comment?._id === subComment?._id) {
          return {
            ...comment,
            is_retweet: 1,
            retweet_count: comment?.retweet_count + 1,
          };
        }
        return comment;
      });
      setSubCommentsList(updatedCommentList);
    });
  };

  const undoRetweetSubComment = (commentId) => {
    undo_post_retweet({ id: commentId }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        const updatedCommentList = subCommentList?.map((comment) => {
          if (comment?._id === commentId) {
            return {
              ...comment,
              retweet_count: comment?.retweet_count - 1,
              is_retweet: 0,
            };
          }
          return comment;
        });
        setSubCommentsList(updatedCommentList);
      }
    });
  };

  // const findWidth = (originalHeight, newHeight, originalWidth) => {
  //   return (newHeight * originalWidth) / originalHeight;
  // };

  // const isPortrait = (h, w) => {
  //   return h > w;
  // };

  const handleShare = useCallback((data) => {
    console.log("data:+++ ", data);
    share_link({
      id: data._id,
      module_type: "post",
      description: data.description || "stulink",
    }).then((response) => {
      console.log("data:+++ ", response);
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  useEffect(() => {
    if (mainComment?._id && pageToken && mainComment?.post_id) {
      getCommentListForSubComments({
        reply_comment_id: mainComment?._id,
        comments_type: "sub",
        page_token: pageToken,
        post_id: mainComment?.post_id,
        // resetPageToken: resetPageToken,
      });
    }
  }, [
    getCommentListForSubComments,
    mainComment?._id,
    mainComment?.post_id,
    pageToken,
    // resetPageToken,
  ]);

  useEffect(() => {
    if (location?.state?.comment?._id) {
      setMainComment([]);
      get_comment_list({
        reply_comment_id: location?.state?.comment?._id,
        comments_type: "main",
        post_id: location?.state?.comment?.post_id,
      }).then((response) => {
        console.log("response:==================> ", response);
        if (response.code == 1) {
          setMainComment(response.data);
        }
      });
      setSubComment([]);
      // setMainComment(location?.state?.comment);
      setPageToken(1);
      setResetPageToken((prev) => !prev);
    }
  }, [location?.state?.comment?._id, location?.state?.comment?.post_id]);

  return (
    <>
      {localStorage?.getItem("isLoggedIn") ||
      localStorage?.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}

      <ShareLink
        shareModalOpen={shareModalOpen}
        setShareModalOpen={setShareModalOpen}
      />

      {/* Add comment to main commment */}
      <div
        className="modal fade"
        id="commentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setPostComment("");
                  setFilesForMainComment();
                  setSelectedFilesForMainComment();
                  setHeightWidthForMainComment();
                }}
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={mainComment?.profile_image}
                              className="inner_post"
                            />{" "}
                            {mainComment?.name}
                          </a>
                          @{mainComment?.username}
                          <p>
                            {mainComment?.insertdate ? (
                              <ReactTimeAgo
                                date={mainComment?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {mainComment?.post_type === "retweet" ? (
                          <label>{mainComment?.shared_feed?.description}</label>
                        ) : mainComment?.post_type === "retweet_with_quote" ? (
                          <>
                            <label>{mainComment?.description}</label>

                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link
                                  to={`/${mainComment?.shared_feed?.user_id}`}
                                  className="post_person_name"
                                >
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        mainComment?.shared_feed?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {mainComment?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{mainComment?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {mainComment?.shared_feed?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          mainComment?.shared_feed?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {mainComment?.shared_feed?.media?.length ===
                                0 ? (
                                  <></>
                                ) : (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        mainComment?.shared_feed?.media[0]?.name
                                      }
                                      alt="post"
                                    />
                                  </div>
                                )}
                                <div className="tetweet_quote_text">
                                  <p className="mb-0 gray_5a font_12 text-break ">
                                    {mainComment?.shared_feed?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <label>{mainComment?.description}</label>
                        )}

                        <br />
                        {mainComment?.media?.length === 0 ? (
                          <></>
                        ) : (
                          <label>
                            {mainComment?.media?.map((i) => {
                              return <>{i?.name}</>;
                            })}
                          </label>
                        )}
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          disabled={filesForMainComment?.length}
                          value={postComment}
                          onChange={(e) => {
                            setPostComment(e?.target?.value);
                            setEmojiPickerForComment(false);
                          }}
                          placeholder={
                            !filesForMainComment?.length &&
                            "Post your comment..!!"
                          }
                        />
                      </div>
                      <div className="uploade_images">
                        {filesForMainComment?.length !== 0 ? (
                          filesForMainComment?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/x-circle.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImagesForMainComment(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div className="images_tab_1">
                                    <video
                                      controls
                                      muted
                                      loop
                                      style={{ height: "200px" }}
                                    >
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImagesForMainComment(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="insert_images align-items-center d-flex m-4">
              {postComment.length == 0 && (
                <label
                  style={
                    disablePhotoInputForMainComment === true ||
                    filesForMainComment?.length === 4 ||
                    selectedFilesForMainComment?.length === 4 ||
                    heightWidthForMainComment?.length === 4
                      ? {}
                      : { cursor: "pointer" }
                  }
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpeg, .jpg"
                    id="imageForPosts"
                    multiple
                    onChange={handleFileInputForMainComment}
                    disabled={
                      disablePhotoInputForMainComment === true ||
                      filesForMainComment?.length === 4 ||
                      selectedFilesForMainComment?.length === 4 ||
                      heightWidthForMainComment?.length === 4
                    }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
              )}
              <div
                className="ms-2"
                // onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
              >
                <label style={{ cursor: "pointer" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
              </div>

              <div className="sania_btn_2 ms-auto">
                <button
                  // disabled={!comment && selectedFileForComments?.length < 0}
                  onClick={() => {
                    uploadImagesForMainComment();
                  }}
                  // className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="blue_button d-flex align-items-center font_16 font_500"
                >
                  COMMENT
                </button>
              </div>
            </div>
            {/* 
            <div className="modal-footer d-flex justify-content-between">
              <ul className="post_option">
                <li>
                  <label
                    style={
                      disablePhotoInputForMainComment === true ||
                      filesForMainComment?.length === 4 ||
                      selectedFilesForMainComment?.length === 4 ||
                      heightWidthForMainComment?.length === 4
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      id="imageForPosts"
                      multiple
                      accept=".png, .jpg, .jpeg"
                      onChange={handleFileInputForMainComment}
                      disabled={
                        disablePhotoInputForMainComment === true ||
                        filesForMainComment?.length === 4 ||
                        selectedFilesForMainComment?.length === 4 ||
                        heightWidthForMainComment?.length === 4
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/gallery.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmojiPickerForComment(!emojiPickerForComment)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </a>
                </li>
              </ul>
              <button
                onClick={() => {
                  uploadImagesForMainComment();
                }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Comment
              </button>
            </div> */}
            {emojiPickerForComment ? (
              <div className="mx-5 mb-3 mt-0">
                <EmojiPicker
                  onEmojiClick={(e) => setPostComment(postComment + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Add sub comments */}
      <div
        className="modal fade"
        id="subCommentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setSubComment("");
                  setFilesForSubComments();
                  setSelectedFilesForSubComments();
                  setHeightWidthForSubComments();
                }}
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={singleSubComment?.profile_image}
                              className="inner_post"
                            />{" "}
                            {singleSubComment?.name}
                          </a>
                          @{singleSubComment?.username}
                          <p>
                            {singleSubComment?.insertdate ? (
                              <ReactTimeAgo
                                date={singleSubComment?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {singleSubComment?.post_type === "retweet" ? (
                          <label className="text-break">
                            {singleSubComment?.shared_feed?.description}
                          </label>
                        ) : singleSubComment?.post_type ===
                          "retweet_with_quote" ? (
                          <>
                            <label className="text-break">
                              {singleSubComment?.description}
                            </label>

                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link
                                  to={`/${singleSubComment?.shared_feed?.user_id}`}
                                  className="post_person_name"
                                >
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        singleSubComment?.shared_feed
                                          ?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {singleSubComment?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{singleSubComment?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {singleSubComment?.shared_feed
                                      ?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          singleSubComment?.shared_feed
                                            ?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {singleSubComment?.shared_feed?.media
                                  ?.length === 0 ? (
                                  <></>
                                ) : (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        singleSubComment?.shared_feed?.media[0]
                                          ?.name
                                      }
                                      alt="post"
                                    />
                                  </div>
                                )}
                                <div className="tetweet_quote_text">
                                  <p className="mb-0 gray_5a font_12 text-break ">
                                    {singleSubComment?.shared_feed?.description}
                                  </p>
                                </div>
                              </div>
                            </div>
                          </>
                        ) : (
                          <label>{singleSubComment?.description}</label>
                        )}

                        <br />
                        {singleSubComment?.media?.length === 0 ? (
                          <></>
                        ) : (
                          <label>
                            {singleSubComment?.media?.map((i) => {
                              return <>{i?.name}</>;
                            })}
                          </label>
                        )}
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          value={subComment}
                          onChange={(e) => {
                            setSubComment(e?.target?.value);
                            setEmojiPickerForComment(false);
                          }}
                          placeholder="Post your comment..!!"
                        />
                      </div>
                      <div className="uploade_images">
                        {filesForSubComments?.length !== 0 ? (
                          filesForSubComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImagesForSubComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div className="images_tab_1">
                                    <video
                                      controls
                                      muted
                                      loop
                                      style={{ height: "200px" }}
                                    >
                                      <source src={URL.createObjectURL(item)} />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImagesForSubComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="insert_images align-items-center d-flex m-4">
              <label
                style={
                  disablePhotoInputForSubComments === true ||
                  filesForSubComments?.length === 4 ||
                  selectedFilesForSubComments?.length === 4 ||
                  heightWidthForSubComments?.length === 4
                    ? {}
                    : { cursor: "pointer" }
                }
              >
                <input
                  type="file"
                  style={{ display: "none" }}
                  accept=".png, .jpeg, .jpg"
                  id="imageForPosts"
                  multiple
                  onChange={handleFileInputForSubComments}
                  disabled={
                    disablePhotoInputForSubComments === true ||
                    filesForSubComments?.length === 4 ||
                    selectedFilesForSubComments?.length === 4 ||
                    heightWidthForSubComments?.length === 4
                  }
                />
                <img
                  src={
                    process.env.PUBLIC_URL + "/assets/images/icons/gallery.svg"
                  }
                  className="img-fluid"
                  alt="gallery"
                />
              </label>
              <div
                className="ms-2"
                // onClick={() => setEmojiPickerVisible(!emojiPickerVisible)}
              >
                <label style={{ cursor: "pointer" }}>
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
              </div>

              <div className="sania_btn_2 ms-auto">
                <button
                  // disabled={!comment && selectedFileForComments?.length < 0}
                  onClick={() => {
                    uploadImagesForSubComments();
                  }}
                  // className="reels_btn post_btn"
                  data-bs-dismiss="modal"
                  aria-label="Close"
                  className="blue_button d-flex align-items-center font_16 font_500"
                >
                  COMMENT
                </button>
              </div>
            </div>
            {emojiPickerForSubComments ? (
              <div className="mx-5 mb-3 mt-0">
                <EmojiPicker
                  onEmojiClick={(e) => setSubComment(subComment + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Comment with quote post */}
      <div
        className="modal fade"
        id="retweetCommentModal"
        tabIndex={-1}
        aria-labelledby="retweet"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setCommentQuote("");
                  setFilesForSubCommentWithQuotes();
                  setSelectedFilesForSubCommentWithQuotes();
                  setHeightWidthForCommentQuotePost();
                }}
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="user_img_comment">
                    <a href="#">
                      <img src={localStorage?.getItem("profile_image")} />
                    </a>
                  </div>
                  <div className="comment_user_post">
                    <input
                      type="text"
                      name=""
                      placeholder="Add comment"
                      value={commentQuote}
                      onChange={(e) => {
                        setCommentQuote(e?.target?.value);
                        setEmojiPickerForCommentQuote(false);
                      }}
                    />
                    <div className="uploade_images">
                      {filesForCommentQuotePost?.length !== 0 ? (
                        filesForCommentQuotePost?.map((item, key) => {
                          switch (item.type.split("/")[0]) {
                            case "image": {
                              return (
                                <div
                                  className="images_tab_1"
                                  style={
                                    filesForCommentQuotePost !== "" ||
                                    filesForCommentQuotePost !== undefined ||
                                    filesForCommentQuotePost !== null
                                      ? {}
                                      : { display: "none" }
                                  }
                                  key={key}
                                >
                                  <img
                                    src={URL.createObjectURL(item)}
                                    style={{
                                      height: "100px",
                                      width: "100%",
                                      display:
                                        filesForCommentQuotePost === ""
                                          ? "none"
                                          : "",
                                    }}
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() =>
                                      removeImageForCommentQuote(key)
                                    }
                                  />
                                </div>
                              );
                            }
                            case "video": {
                              return (
                                <div className="images_tab_1" key={key}>
                                  <video
                                    controls
                                    muted
                                    loop
                                    style={{ width: "120px" }}
                                  >
                                    <source src={URL.createObjectURL(item)} />
                                  </video>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() => {
                                      removeImageForCommentQuote(key);
                                    }}
                                  />
                                </div>
                              );
                            }
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="main_comment_other">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={mainComment?.profile_image}
                              className="inner_post"
                            />{" "}
                            {mainComment?.name}
                          </a>
                          @{mainComment?.username}{" "}
                          <p>{getTimeDifference(mainComment?.insertdate)}</p>
                        </span>
                        <br />
                      </div>
                      <div className="tetweet_quote_content">
                        {mainComment?.media?.length > 0 ? (
                          mainComment?.media[0]?.type === "video" ? (
                            <div className="tetweet_quote_image">
                              <img
                                src={mainComment?.media[0]?.thumb}
                                alt="post"
                              />
                            </div>
                          ) : (
                            <div className="tetweet_quote_image">
                              <img
                                src={mainComment?.media[0]?.name}
                                alt="post"
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                        <div className="tetweet_quote_text">
                          <p className="mb-0 gray_5a font_12 text-break ">
                            {mainComment?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="insert_images">
                <label
                  style={
                    disablePhotoInputForCommentQuotePost === true ||
                    filesForCommentQuotePost?.length === 4 ||
                    selectedFilesForCommentQuotePost?.length === 4 ||
                    heightWidthForCommentQuotePost?.length === 4
                      ? {}
                      : { cursor: "pointer" }
                  }
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileInputForCommentQuotePost}
                    disabled={
                      disablePhotoInputForCommentQuotePost === true ||
                      filesForCommentQuotePost?.length === 4 ||
                      selectedFilesForCommentQuotePost?.length === 4 ||
                      heightWidthForCommentQuotePost?.length === 4
                    }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
                {/* <a className="mx-4">
                  <label
                    style={
                      disableVideoInputForCommentQuotePost === true ||
                      filesForCommentQuotePost?.length === 1 ||
                      selectedFilesForCommentQuotePost?.length === 1 ||
                      heightWidthForCommentQuotePost?.length === 1
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mp4, .avi, .mov, .mkv, .wmv"
                      id="imageForPosts"
                      onChange={handleVideoUploadForRetweetWithQuotes}
                      disabled={
                        disableVideoInputForCommentQuotePost === true ||
                        filesForCommentQuotePost?.length === 1 ||
                        selectedFilesForCommentQuotePost?.length === 1 ||
                        heightWidthForCommentQuotePost?.length === 1
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/video-icon.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                </a> */}
                {/* <a href="javascript:void(0)">
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid mx-3"
                                    alt="gallery" />
                            </a> */}
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setEmojiPickerForCommentQuote(!emojiPickerForComment)
                  }
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid mx-3"
                    alt="gallery"
                  />
                </a>
              </div>
              <button
                onClick={() => {
                  uploadImagesForCommentQuotePost();
                }}
                style={{ cursor: "pointer" }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </div>
            {emojiPickerForCommentQuote ? (
              <>
                <div className="mx-5 mt-0 mb-3">
                  <EmojiPicker
                    onEmojiClick={(e) =>
                      setCommentQuote(commentQuote + e?.emoji)
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      {/* Sub comment with quote post */}
      <div
        className="modal fade"
        id="retweetCommentModalForSubComments"
        tabIndex={-1}
        aria-labelledby="retweet"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="user_img_comment">
                    <a href="#">
                      <img src={localStorage?.getItem("profile_image")} />
                    </a>
                  </div>
                  <div className="comment_user_post">
                    <input
                      type="text"
                      name=""
                      placeholder="Add comment"
                      value={subCommentWithQuotes}
                      onChange={(e) => {
                        setSubCommentWithQuotes(e?.target?.value);
                        setEmojiPickerForSubCommentsWithQuotes(false);
                      }}
                    />
                    <div className="uploade_images">
                      {filesForSubCommentWithQuotes?.length !== 0 ? (
                        filesForSubCommentWithQuotes?.map((item, key) => {
                          switch (item.type.split("/")[0]) {
                            case "image": {
                              return (
                                <div
                                  className="images_tab_1"
                                  style={
                                    filesForSubCommentWithQuotes !== "" ||
                                    filesForSubCommentWithQuotes !==
                                      undefined ||
                                    filesForSubCommentWithQuotes !== null
                                      ? {}
                                      : { display: "none" }
                                  }
                                  key={key}
                                >
                                  <img
                                    src={URL.createObjectURL(item)}
                                    style={{
                                      height: "100px",
                                      width: "100%",
                                      display:
                                        filesForSubCommentWithQuotes === ""
                                          ? "none"
                                          : "",
                                    }}
                                  />
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() =>
                                      removeImagesForSubCommentsWithQuotes(key)
                                    }
                                  />
                                </div>
                              );
                            }
                            case "video": {
                              return (
                                <div className="images_tab_1" key={key}>
                                  <video
                                    controls
                                    muted
                                    loop
                                    style={{ width: "120px" }}
                                  >
                                    <source src={URL.createObjectURL(item)} />
                                  </video>
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/cross.svg"
                                    }
                                    className="cross-icon"
                                    onClick={() => {
                                      removeImagesForSubCommentsWithQuotes(key);
                                    }}
                                  />
                                </div>
                              );
                            }
                          }
                        })
                      ) : (
                        <></>
                      )}
                    </div>
                    <div className="main_comment_other">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={singleSubComment?.profile_image}
                              className="inner_post"
                            />{" "}
                            {singleSubComment?.name}
                          </a>
                          @{singleSubComment?.username}{" "}
                          <p>
                            {getTimeDifference(singleSubComment?.insertdate)}
                          </p>
                        </span>
                        <br />
                      </div>
                      <div className="tetweet_quote_content">
                        {singleSubComment?.media?.length > 0 ? (
                          singleSubComment?.media[0]?.type === "video" ? (
                            <div className="tetweet_quote_image">
                              <img
                                src={singleSubComment?.media[0]?.thumb}
                                alt="post"
                              />
                            </div>
                          ) : (
                            <div className="tetweet_quote_image">
                              <img
                                src={singleSubComment?.media[0]?.name}
                                alt="post"
                              />
                            </div>
                          )
                        ) : (
                          <></>
                        )}
                        <div className="tetweet_quote_text">
                          <p className="mb-0 gray_5a font_12 text-break ">
                            {singleSubComment?.description}
                          </p>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <div className="insert_images">
                <label
                  style={
                    disablePhotoInputForSubCommentsWithQuotes === true ||
                    filesForSubCommentWithQuotes?.length === 4 ||
                    selectedFilesForSubCommentWithQuotes?.length === 4 ||
                    heightWidthForSubCommentsWithQuotes?.length === 4
                      ? {}
                      : { cursor: "pointer" }
                  }
                >
                  <input
                    type="file"
                    style={{ display: "none" }}
                    multiple
                    accept=".jpg, .jpeg, .png"
                    onChange={handleFileInputForSubCommentQuotePost}
                    disabled={
                      disablePhotoInputForSubCommentsWithQuotes === true ||
                      filesForSubCommentWithQuotes?.length === 4 ||
                      selectedFilesForSubCommentWithQuotes?.length === 4 ||
                      heightWidthForSubCommentsWithQuotes?.length === 4
                    }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />
                </label>
                <a className="mx-4">
                  <label
                    style={
                      disableVideoInputForSubCommentsWithQuotes === true ||
                      filesForSubCommentWithQuotes?.length === 1 ||
                      selectedFilesForSubCommentWithQuotes?.length === 1 ||
                      heightWidthForSubCommentsWithQuotes?.length === 1
                        ? {}
                        : { cursor: "pointer" }
                    }
                  >
                    <input
                      type="file"
                      style={{ display: "none" }}
                      accept=".mp4, .avi, .mov, .mkv, .wmv"
                      id="imageForPosts"
                      onChange={handleVideoUploadForSubCommentsWithQuotes}
                      disabled={
                        disableVideoInputForSubCommentsWithQuotes === true ||
                        filesForSubCommentWithQuotes?.length === 1 ||
                        selectedFilesForSubCommentWithQuotes?.length === 1 ||
                        heightWidthForSubCommentsWithQuotes?.length === 1
                      }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/video-icon.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </label>
                </a>
                {/* <a href="javascript:void(0)">
                                <img src={process.env.PUBLIC_URL + "/assets/images/icons/room-location.svg"} className="img-fluid mx-3"
                                    alt="gallery" />
                            </a> */}
                <a
                  style={{ cursor: "pointer" }}
                  onClick={() =>
                    setEmojiPickerForSubCommentsWithQuotes(
                      !emojiPickerForSubCommentsWithQuotes
                    )
                  }
                >
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/emoji.svg"
                    }
                    className="img-fluid mx-3"
                    alt="gallery"
                  />
                </a>
              </div>
              <button
                onClick={() => {
                  uploadImagesForSubCommentQuotePost();
                }}
                style={{ cursor: "pointer" }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Post
              </button>
            </div>
            {emojiPickerForSubCommentsWithQuotes ? (
              <>
                <div className="mx-5 mt-0 mb-3">
                  <EmojiPicker
                    onEmojiClick={(e) =>
                      setSubCommentWithQuotes(subCommentWithQuotes + e?.emoji)
                    }
                  />
                </div>
              </>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>

      <PostMenu
        postPermission={postPermission}
        setPostPermission={setPostPermission}
        singlePost={singlePost}
        isComment={true}
        // feeds={feeds}
        setFeeds={() => {}}
      />

      <section className="post_detail my-3">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar={false}
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="container">
          <div className="row">
            <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
              <NormalPostType
                retweet={retweetMainComment}
                undoRetweet={undoRetweetMainComment}
                singlePost={singlePost}
                setSinglePost={setSinglePost}
                setPostPermission={setPostPermission}
                likeUnlike={mainCommentLikeUnlike}
                handleShare={handleShare}
                item={mainComment}
                isComment={true}
                isDetailPage={true}
                // key={key}
              />

              <div className="all_comment_list">
                <ul className="comment_scroll">
                  <InfiniteScroll
                    dataLength={subCommentList?.length}
                    next={() => {
                      // if (resetPageToken === true) {
                      //   setPageToken(1);
                      //   setResetPageToken(false);
                      // } else {
                      setPageToken((prevPageToken) => prevPageToken + 1);
                      // }
                    }}
                    hasMore={isFetching}
                    scrollableTarget="comment_list"
                  >
                    {subCommentList?.map((item) => {
                      return (
                        <>
                          <Comments
                            retweet={retweetSubComment}
                            undoRetweet={undoRetweetSubComment}
                            setSingleSubComment={setSingleSubComment}
                            likeUnlike={likeUnlikeSubComments}
                            item={item}
                            setPostPermission={setPostPermission}
                            setSinglePost={setSinglePost}
                          />
                        </>
                      );
                    })}
                  </InfiniteScroll>
                </ul>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
