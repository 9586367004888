import React, { useEffect, useState } from 'react'
import Rating from '@mui/material/Rating';

function StudentsAlsoVisited({ studentAlsoVisitedData }) {
    const [finalRating, setFinalRating] = useState([])
    useEffect(() => {
        if (studentAlsoVisitedData?.colleges_list_result && studentAlsoVisitedData.colleges_list_result.length > 0) {
            // studentAlsoVisitedData?.colleges_list_result.map((value, index) => {
            //     let total_rating = value.college_rating.reduce((acc, item) => acc + item.rate, 0) / value.college_rating.length;
            //     console.log('total_rating: ', total_rating);
            // })
            const calculatedRatings = studentAlsoVisitedData.colleges_list_result.map((value, index) => {
                const total_rating = value.college_rating.reduce((acc, item) => acc + item.rate, 0) / value.college_rating.length;
                return total_rating;
            });
            setFinalRating(calculatedRatings)
            console.log('calculatedRatings: ', calculatedRatings);
        }
    }, [studentAlsoVisitedData])
    return (
        <>
            {
                studentAlsoVisitedData?.colleges_list_result && studentAlsoVisitedData.colleges_list_result.length > 0 && (
                    <div className="trending_admission_box">

                        <div className="trending_admission_box_heading">
                            <img
                                src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/graduated.svg"
                                }
                                className="img-fluid me-2"
                                alt="icon"
                            />
                            <h6 className="white_ff font_16 font_500 mb-0">
                                Students Also Visited
                            </h6>
                        </div>
                        <div className="trending_admission_box_body">
                            <ul className="list-unstyled">
                                {
                                    studentAlsoVisitedData?.colleges_list_result.slice(0, 4).map((value, index) => (
                                        <li key={index}>
                                            {
                                                // console.log('index: ', index)
                                                // console.log(finalRating[0], finalRating)
                                            }
                                            <div className="chandi_admission">
                                                <div className="uni_profile_image">
                                                    <img
                                                        src={value.college_logo}
                                                        className="img-fluid"
                                                        alt="image"
                                                    />
                                                </div>
                                                <div className="uni_profile_text">
                                                    <h6 className="inter font_400 font_15">
                                                        {value.name}
                                                    </h6>
                                                    <div className="also_star d-flex align-items-center justify-content-between">
                                                        <div className="yellow_location d-flex align-items-center">
                                                            <img
                                                                src={
                                                                    process.env.PUBLIC_URL +
                                                                    "/assets/images/icons/college-location.svg"
                                                                }
                                                                className="img-fluid me-1"
                                                                alt="icon"
                                                            />
                                                            <span className="font_12 inter light_gray_8b">
                                                                {value.location}
                                                            </span>
                                                        </div>
                                                    </div>
                                                    <div className="d-flex align-items-center">
                                                        <Rating value={finalRating.length > 0 && finalRating[index]} precision={finalRating[index] % 1 !== 0 && 0.5} readOnly />
                                                        <span className="font_10 black_00 inter text-center ms-2">
                                                            ({finalRating.length > 0 && finalRating[index]})
                                                        </span>
                                                    </div>
                                                </div>
                                            </div>
                                        </li>
                                    ))
                                }
                            </ul>
                        </div>
                    </div>
                )
            }
        </>
    )
}

export default StudentsAlsoVisited