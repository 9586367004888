import React from 'react';
import HeaderProfile from './HeaderProfile';
import Header from './Header';
import { support_request } from '../api/apiHandler';
import { useForm } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import * as yup from 'yup';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

export default function Supportus() {
    const validationSchema = yup.object().shape({
        support_message: yup.string().required('Support Message is required')
    });
    const formOptions = { resolver: yupResolver(validationSchema) };
    const { register, handleSubmit, reset, formState } = useForm(formOptions);
    const { errors } = formState;
    const onSubmit = (data)=>{
        let newData = {"support_message": data.support_message};
        support_request(newData).then((response)=>{
            // console.log("Line 21", response);
            if(response.code === 1 || response.code === "1"){
                toast.success(response.message, {
                    position: "top-center",
                    autoClose: 2000,
                    hideProgressBar: true,
                    closeOnClick: true,
                    pauseOnHover: true,
                    draggable: true,
                    progress: undefined,
                    theme: "colored",
                });
                reset();
            }
        })
    }
    return (
        <>
            {localStorage.getItem("isLoggedIn") || localStorage.getItem("isSignedUp") ? <HeaderProfile /> : <Header />}
            <section className="contact_us_page terms_use">
            <ToastContainer
                    position="top-center"
                    autoClose={2000}
                    hideProgressBar
                    newestOnTop={false}
                    closeOnClick
                    rtl={false}
                    pauseOnFocusLoss
                    draggable
                    pauseOnHover
                    theme="colored"
                />
                <div className="container">
                    <div className="row">
                        <div className="col-md-12">
                            <div className="information_box">
                                <div className="information_box_heading">
                                    <h3 className="blue_29 font_500 font_25 mb-0">Support Requests</h3>
                                </div>
                                <div className="information_box_body">
                                    <h4 className="gray_5a font_22 font_600">Submit Your Support Request</h4>
                                    <h5 className="light_gray_8b font_20 font_500 mb-5">Detail of Request</h5>
                                    <form className="row" onSubmit={handleSubmit(onSubmit)}>
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="mobile_number_input">
                                                <h6 className="font_18 font_500">Enter Your Name</h6>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><img src={process.env.PUBLIC_URL+"/assets/images/icons/username.svg"} className="img-fluid" alt="phone" /></span>
                                                    <input type="text" className="form-control" required="" autoComplete="off" defaultValue={localStorage.getItem("name")} />
                                                        <label htmlFor="name">Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12">
                                            <div className="mobile_number_input">
                                                <h6 className="font_18 font_500">Enter Your User Name</h6>
                                                <div className="input-group">
                                                    <span className="input-group-text" id="basic-addon1"><img src={process.env.PUBLIC_URL+"/assets/images/icons/mail.svg"} className="img-fluid" alt="phone" /></span>
                                                    <input type="text" className="form-control" required="" autoComplete="off" defaultValue={localStorage.getItem("username")} />
                                                        <label htmlFor="name">User Name</label>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="col-12">
                                            <div className="mobile_number_input">
                                                <h6 className="font_18 font_500">Enter your message</h6> <span style={{color: "red"}}>{errors.support_message?.message}</span>
                                                <div className="input-group form-floating">
                                                    <textarea className="textarea_placeholder form-control" {...register("support_message")} id="floatingTextarea2" style={{height: "150px"}} placeholder='Enter your message'></textarea>
                                                </div>
                                            </div>
                                        </div>
                                        <div className='col-12'>
                                            <button type="submit" className="blue_button d-flex align-items-center justify-content-center font_18 font_500 mb-4 text-center w-100" style={{maxWidth: "inherit"}}>SUBMIT</button>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </section>
        </>
    )
}
