import React, { useCallback, useEffect, useRef, useState } from "react";
import HeaderProfile from "../HeaderProfile";
import Header from "../Header";
import {
  user_details,
  edit_profile,
  logout,
  profile_colleges,
  // check_unique,
} from "../../api/apiHandler";
import { useParams, useNavigate, Link } from "react-router-dom";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import S3 from "react-aws-s3";
import ChangePassword from "../ProfileDropdown/ChangePassword";
import Education from "../ProfileDropdown/Education";
import AccountSettings from "../ProfileDropdown/AccountSettings";
import AccountPrivacy from "../ProfileDropdown/AccountPrivacy";
import NotificationSettings from "../ProfileDropdown/NotificationSettings";
import PlacesAutocomplete, {
  geocodeByAddress,
  getLatLng,
} from "react-places-autocomplete";

// import moment from "moment";
// import DatePicker from "react-date-picker";
import "react-date-picker/dist/DatePicker.css";
import dayjs from "dayjs";
import { CometChat } from "@cometchat/chat-sdk-javascript"; //import sdk package
import { CometChatUIKit } from "@cometchat/chat-uikit-react"; //import uikit package

window.Buffer = window.Buffer || require("buffer").Buffer;

const config = {
  bucketName: process.env.REACT_APP_S3_BUCKET_NAME,
  region: process.env.REACT_APP_S3_BUCKET_REGION,
  accessKeyId: process.env.REACT_APP_S3_BUCKET_KEY,
  secretAccessKey: process.env.REACT_APP_S3_BUCKET_SECRET_ACCESS_KEY,
  dirName: "stulink/user_image",
};

const ReactS3Client = new S3(config);

export default function EditProfile() {
  // const inputRef = useRef(null);
  const navigate = useNavigate();
  const [image, setImage] = useState();
  const [coordinates, setCoordinates] = useState({ lat: null, lng: null });
  const [selectedUser, setSelectedUser] = useState();
  console.log('', selectedUser);
  // const [finalLocation, setFinalLocation] = useState({});
  const [edit, isEdit] = useState(false);
  const [address, setAddress] = useState();
  const [value, setValue] = useState();
  const [page_token, setPageToken] = useState(1);
  const { params } = useParams();

  const validationSchema = yup.object().shape({
    name: yup
      .string()
      .notRequired("Please enter name")
      .matches("^[A-Za-z ]{3,32}$", "Please provide valid name"),
    username: yup
      .string()
      .notRequired("Please enter username")
      .matches("^[a-z0-9_.]*$", "Please enter valid username")
      .min(2)
      .max(20),
  });
  const formOptions = {
    resolver: yupResolver(validationSchema),
    // defaultValues: {
    //   college_uni_name: selectedUser?.college_uni_name || "",
    // },
  };
  const { register, handleSubmit, formState } = useForm(formOptions);
  const { errors } = formState;
  console.log("errors: ", errors);
  const [checkDob, setCheckDob] = useState();
  const [preview, setPreview] = useState(
    localStorage?.getItem("profile_image")
  );
  const [collegeName, setCollegeName] = useState([]);

  // var finalDob;
  const handleSelect = async (value) => {
    const results = await geocodeByAddress(value);
    const ll = await getLatLng(results);
    setAddress(value);
    setCoordinates(ll);
  };

  const userData = useCallback(() => {
    user_details({ params }).then((response) => {
      console.log("response: ", response);
      if (response?.code === 1 || response?.code === "1") {
        setSelectedUser(response?.data);
        setAddress(response?.data?.address);
        setImage(localStorage?.getItem("profile_image"));
        setValue(response?.data?.dob);
        setCheckDob(response?.data?.dob);
        // console.log("Line 136", dayjs(checkDob).format("DD/MM/YYYY"));
      }
    });
  }, [params]);
  // Image Upload

  const handleFileInput = async (e) => {
    e.preventDefault();
    const file = e.target.files[0];
    console.log("file: ", file);

    isEdit(true);
    // try {
    if (file?.type.startsWith("image/")) {
      const upload = await ReactS3Client.uploadFile(file);
      setImage(upload.location);
      setPreview(upload?.location);
      console.log(upload.location.split("/")[5]);
    } else {
      // console.log("HHHEHEHEHHEHHEEHHE");
      toast.error("Please upload only image", {
        toastId: "Image Error",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
    }
    // localStorage.setItem("profile_image", upload.location.split("/")[5]);
    // }
    // catch (e) {
    //   // console.log(e);
    // }
  };

  // Edit Profile API
  const onSubmit = (data) => {
    const newData = {
      email: selectedUser?.email,
      username: data?.username,
      name: data?.name,
      aboutme: data?.aboutme,
      address: address,
      profile_image: image.split("/")[5],
      dob: checkDob
        ? new Date(checkDob).getDate() +
          " " +
          new Date(checkDob).toLocaleString([], { month: "long" }) +
          " " +
          new Date(checkDob).getFullYear()
        : "",
      college_uni_name: data?.college_uni_name,
      gender: data?.gender,
    };
    if (newData?.name === "") {
      console.log("Inside name");
      toast.error("Please provide your name", {
        toastId: "nameError",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else if (newData?.username === "") {
      console.log("Inside username");
      toast.error("Please provide your username", {
        toastId: "usernameError",
        position: "top-center",
        autoClose: 2000,
        hideProgressBar: true,
        closeOnClick: true,
        pauseOnHover: true,
        draggable: true,
        progress: undefined,
        theme: "colored",
      });
      return false;
    } else {
      console.log("Inside else");
      // check_unique({
      //     "login_type":"S",
      //     "phone_number":newData?.mobileNumber,
      //     "email":localStorage.getItem("email"),
      //     "username":newData?.username
      // }).then((response)=>{
      //     console.log("Line 156 response", response);
      // })
      edit_profile(newData).then((response) => {
        console.log("Inside else location", newData);
        console.log("Edit Profile", response);
        if (response.code === "1" || response.code === 1) {
          isEdit(true);
          toast.success(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });

          // var user = new CometChat.User(response.data._id);

          // user.setName(response.data.name);
          // user.setAvatar(response.data.profile_image);

          // CometChat.updateUser(user, authKey).then(
          //   (user) => {
          //     console.log("user updated", user);
          //   },
          //   (error) => {
          //     console.log("error", error);
          //   }
          // );

          var user = new CometChat.User(response.data._id);
          user.setName(response.data.name);
          user.setAvatar(response.data.profile_image);
          CometChatUIKit.updateUser(user)
            .then((user) => {
              console.log("user updated", user);
            })
            .catch(console.log);
          localStorage.setItem("profile_image", response.data.profile_image);
          // navigate(`/profile/${selectedUser?._id}`);
          setTimeout(() => {
            navigate("/profile", {
              state: selectedUser?._id,
            });
          }, 1000);
          // userData();
        } else {
          toast.error(response.message, {
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
        }
      });
    }
  };

  // Logout API
  const logOut = () => {
    logout({}).then((response) => {
      if (response.code === 1 || response.code === "1") {
        localStorage.clear();
        CometChatUIKit.logout();
        window.location.href = "/";
      }
    });
  };

  useEffect(() => {
    userData();
    // initMapScript().then(()=> {initAutoComplete()});
  }, [userData]);

  useEffect(() => {
    profile_colleges({ page_token: page_token }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        setCollegeName((item) => [...item, ...response.data]);
        setPageToken((prev) => prev + 1);
      } else {
      }
    });
  }, [page_token]);

  if (selectedUser === undefined) {
    return <></>;
  }

  return (
    <>
      {localStorage.getItem("isLoggedIn") ||
      localStorage.getItem("isSignedUp") ? (
        <HeaderProfile />
      ) : (
        <Header />
      )}
      <section className="main_edit_profile">
        <div className="container">
          <ToastContainer
            position="top-center"
            autoClose={2000}
            hideProgressBar
            newestOnTop={false}
            closeOnClick
            rtl={false}
            pauseOnFocusLoss
            draggable
            pauseOnHover
            theme="colored"
          />
          <div className="row">
            <div className="col-12 col-xl-3 col-lg-3 col-md-4 col-sm-12">
              <div
                className="edit_nav nav flex-column nav-pills me-3"
                id="v-pills-tab"
                role="tablist"
                aria-orientation="vertical"
              >
                <button
                  className="nav-link font_14 black_00 active justify-content-between"
                  id="v-pills-Profile-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Profile"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Profile"
                  aria-selected="true"
                >
                  Edit Profile{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL + "/assets/images/icons/edit.svg"
                    }
                    className="img-fluid"
                    alt="edit"
                  />
                </button>
                <button
                  className="nav-link font_14 black_00 justify-content-between"
                  id="v-pills-Notification-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Notification"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Notification"
                  aria-selected="false"
                >
                  Notification{" "}
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/edit-notification.svg"
                    }
                    className="img-fluid"
                    alt="notification"
                  />
                </button>
                <button
                  className="nav-link font_14 black_00 justify-content-between"
                  id="v-pills-Password-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Password"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Password"
                  aria-selected="false"
                >
                  Password
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/edit-password.svg"
                    }
                    className="img-fluid"
                    alt="password"
                  />
                </button>
                {/* <button
                  className="nav-link font_14 black_00 justify-content-between"
                  id="v-pills-Education-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Education"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Education"
                  aria-selected="false"
                >
                  Education
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/edit-education.svg"
                    }
                    className="img-fluid"
                    alt="education"
                  />
                </button> */}
                {/* <button
                  className="nav-link font_14 black_00 justify-content-between"
                  id="v-pills-Account-Settings-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Account-Settings"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Account-Settings"
                  aria-selected="false"
                >
                  Account Settings
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/edit-setting.svg"
                    }
                    className="img-fluid"
                    alt="setting"
                  />
                </button> */}
                <button
                  className="nav-link font_14 black_00 justify-content-between"
                  id="v-pills-Privacy-tab"
                  data-bs-toggle="pill"
                  data-bs-target="#v-pills-Privacy"
                  type="button"
                  role="tab"
                  aria-controls="v-pills-Privacy"
                  aria-selected="false"
                >
                  Privacy
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/edit-privacy.svg"
                    }
                    className="img-fluid"
                    alt="privacy"
                  />
                </button>
                <button
                  className="nav-link font_14 black_00 justify-content-between"
                  type="button"
                  role="tab"
                  onClick={() => {
                    navigate("/saved-post");
                  }}
                >
                  Save Post
                  <img
                    style={{ width: "8%" }}
                    src={
                      process.env.PUBLIC_URL + "/assets/images/save_black.svg"
                    }
                    className="img-fluid"
                    alt="privacy"
                  />
                </button>
                <button
                  className="nav-link font_14 black_00 justify-content-between"
                  onClick={logOut}
                >
                  Logout
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/logout-black.svg"
                    }
                    className="img-fluid"
                    alt="logout"
                  />
                </button>
              </div>
            </div>
            <div className="col-12 col-xl-9 col-lg-9 col-md-8 col-sm-12">
              <div className="tab-content" id="v-pills-tabContent">
                <div
                  className="tab-pane fade show active"
                  id="v-pills-Profile"
                  role="tabpanel"
                  aria-labelledby="v-pills-Profile-tab"
                >
                  <div className="edit_text">
                    <h4 className="font_25 font_600">Edit Profile</h4>
                    <span className="gray_5a font_400 font_18">
                      People on Stulink will get to know you with the info below
                    </span>
                    <div className="edit_profile_pic">
                      <div className="mx-auto personal_profile_image position-relative overflow-visible">
                        <img
                          src={
                            edit === true
                              ? preview
                              : localStorage.getItem("profile_image")
                          }
                          className="img-fluid"
                          alt=""
                          style={{ borderRadius: "12px" }}
                          defaultValue={localStorage.getItem("profile_image")}
                        />
                        <label className="edit_pencil">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/edit-pencil.svg"
                            }
                            className="img-fluid"
                            alt="pencil"
                          />
                          <input
                            type="file"
                            onChange={(e) => handleFileInput(e)}
                            style={{ display: "none" }}
                            accept="image/jpeg, image/png, image/jpg"
                          />
                        </label>
                      </div>
                    </div>
                    <div className="row">
                      <div className="col-md-12">
                        <form className="row" onSubmit={handleSubmit(onSubmit)}>
                          <div className="col-12 col-xl-12 col-lg-12 col-md-12 col-sm-12">
                            <div
                              className="edit_input mobile_number_input"
                              style={{ width: "100%" }}
                            >
                              <h6 className="font_18 font_500">Name</h6>{" "}
                              <span style={{ color: "red" }}>
                                {selectedUser?.name !== "" ||
                                selectedUser?.name !== undefined
                                  ? errors.name?.message
                                  : ""}
                              </span>
                              <div className="input-group">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  {...register("name")}
                                  className="form-control"
                                  defaultValue={
                                    selectedUser?.name !== "" ||
                                    selectedUser?.name !== undefined
                                      ? selectedUser?.name
                                      : ""
                                  }
                                  required=""
                                />
                                <label htmlFor="name">Name</label>
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                            <div className="edit_input mobile_number_input">
                              <h6 className="font_18 font_500">User Name</h6>{" "}
                              <span style={{ color: "red" }}>
                                {selectedUser?.username !== "" ||
                                selectedUser?.username !== undefined
                                  ? errors.username?.message
                                  : ""}
                              </span>
                              <div className="input-group">
                                <input
                                  autoComplete="off"
                                  type="text"
                                  {...register("username")}
                                  className="form-control"
                                  defaultValue={
                                    selectedUser?.username !== "" &&
                                    selectedUser?.username !== undefined
                                      ? selectedUser?.username
                                      : ""
                                  }
                                  required=""
                                />
                                <label htmlFor="name">User Name</label>
                              </div>
                            </div>
                          </div>

                          <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                            <div className="edit_input mobile_number_input">
                              <h6 className="font_18 font_500">Birth Date</h6>
                              <div className="input-group">
                                <input
                                  type="date"
                                  className="form-control"
                                  style={{ color: "black" }}
                                  required=""
                                  value={dayjs(checkDob).format("YYYY-MM-DD")}
                                  onChange={(e) => {
                                    setCheckDob(e.target.value);
                                  }}
                                  // defaultValue={
                                  //   selectedUser?.dob ===
                                  //     "Invalid Date Invalid Date NaN" ||
                                  //     selectedUser?.dob ===
                                  //     "Invalid Date Invalid Date Invalid Date" ||
                                  //     selectedUser?.dob === "" ||
                                  //     selectedUser?.dob ===
                                  //     "NaN Invalid Date NaN" ||
                                  //     selectedUser?.dob === "NaN NaN Nan"
                                  //     ? ""
                                  //     : dayjs(
                                  //       selectedUser?.dob?.split(" ")
                                  //     ).format("DD/MM/YYYY")
                                  // }
                                  max={new Date().toISOString().split("T")[0]}
                                />
                                <label for="name">Your Birthday</label>
                              </div>
                            </div>
                          </div>
                          {/* <div className="col-12 col-xl-6 cl-lg-6 col-md-6 col-sm-12">
                            <div className="edit_input mobile_number_input">
                              <h6 className="font_18 font_500">
                                Mobile Number
                              </h6>
                              <div className="input-group">
                                <input
                                  type="text"
                                  autoComplete="off"
                                  className="form-control"
                                  {...register("mobileNumber", {
                                    required: "Mobile number is required",
                                    pattern: {
                                      value: /^[0-9]{10}$/,
                                      message: "Invalid mobile number"
                                    }
                                  })}
                                  defaultValue={
                                    selectedUser?.phone !== "" &&
                                      selectedUser?.phone !== undefined
                                      ? selectedUser?.phone
                                      : ""
                                  }
                                  required=""
                                  maxLength={10}
                                />
                                <label htmlFor="name">Phone No.</label>
                              </div>
                              <span style={{ color: "red" }}>
                                {errors.mobileNumber?.message}
                              </span>
                            </div>
                          </div> */}
                          <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12">
                            <div className="edit_input mobile_number_input">
                              <h6 className="font_18 font_500">Location</h6>
                              <div className="input-group">
                                <PlacesAutocomplete
                                  value={address}
                                  onChange={(address) => {
                                    setAddress(address);
                                  }}
                                  onClick={handleSelect}
                                  className="my-autocomplete-input"
                                >
                                  {({
                                    getInputProps,
                                    suggestions,
                                    getSuggestionItemProps,
                                    loading,
                                  }) => (
                                    <div className="w-100">
                                      <input
                                        className="form-control"
                                        type="text"
                                        id="address"
                                        // aria-expanded={true}
                                        {...getInputProps({
                                          placeholder: "Location",
                                        })}
                                        style={{ width: "1000px !important" }}
                                        defaultValue={selectedUser?.address}
                                      />
                                      <div>
                                        {address?.trim() === "" ? (
                                          ""
                                        ) : loading ? (
                                          <div className="text-secondary blue_29">
                                            Loading...
                                          </div>
                                        ) : null}
                                        {suggestions.map((suggestion, key) => {
                                          const style = {
                                            backgroundColor: suggestion.active
                                              ? "#9DDCDE"
                                              : "#fff",
                                            textAlign: "left",
                                            cursor: "pointer",
                                            width: "inherit",
                                          };

                                          return (
                                            <div
                                              className="form-control"
                                              key={key}
                                              {...getSuggestionItemProps(
                                                suggestion,
                                                { style }
                                              )}
                                            >
                                              {suggestion.description}
                                            </div>
                                          );
                                        })}
                                      </div>
                                    </div>
                                  )}
                                </PlacesAutocomplete>

                                {/* <label htmlFor="name">Location</label> */}
                              </div>
                            </div>
                          </div>
                          <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12">
                            {collegeName?.length > 0 && (
                              <div className="edit_input mobile_number_input">
                                <h6 className="font_18 font_500">
                                  College / University
                                </h6>
                                {/* <div className="input-group"> */}
                                <select
                                  className="custom-select input-group grey_input "
                                  onChange={
                                    (e) => {}
                                    // setCategoryData(e.target.value)
                                  }
                                  {...register("college_uni_name")}
                                  defaultValue={
                                    selectedUser?.college_uni_name || ""
                                  }
                                  style={{ padding: "15px 20px" }}
                                >
                                  <option value={""}>Select College</option>
                                  {collegeName?.map((item) => {
                                    return (
                                      <option value={item?.name}>
                                        {item?.name}
                                      </option>
                                    );
                                  })}
                                </select>

                                {/* <label htmlFor="name">Location</label> */}
                                {/* </div> */}
                              </div>
                            )}
                          </div>
                          <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12">
                            <div className="edit_input mobile_number_input">
                              <h6 className="font_18 font_500">Gender</h6>
                              {/* <div className="input-group"> */}
                              <select
                                className="custom-select input-group grey_input "
                                {...register("gender")}
                                defaultValue={
                                  selectedUser?.gender !== "" &&
                                  selectedUser?.gender !== undefined
                                    ? selectedUser?.gender
                                    : ""
                                }
                                style={{ padding: "15px 20px" }}
                              >
                                <option value={""}>Select Gender</option>
                                <option value={"Male"}>Male</option>
                                <option value={"Female"}>Female</option>
                                <option value={"Prefer No To Say"}>
                                  Prefer No To Say
                                </option>
                              </select>

                              {/* <label htmlFor="name">Location</label> */}
                              {/* </div> */}
                            </div>
                          </div>

                          <div className="col-12 col-xl-12 cl-lg-12 col-md-12 col-sm-12">
                            <div className="textedit_input edit_input mobile_number_input">
                              <h6 className="font_18 font_500">
                                About Your Bio
                              </h6>
                              <div className="input-group">
                                <textarea
                                  type="text"
                                  autoComplete="off"
                                  {...register("aboutme")}
                                  className="form-control"
                                  defaultValue={selectedUser?.aboutme}
                                  required=""
                                  style={{ height: "150px", marginTop: "20px" }}
                                ></textarea>
                                <label htmlFor="name">Description</label>
                              </div>
                            </div>
                          </div>

                          {/* <div className="col-12 col-xl-4 cl-lg-4 col-md-4 col-sm-4">
                                                        <div className="edit_input mobile_number_input">
                                                            <h6 className="font_18 font_500">State</h6>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" autoComplete='off' {...register("state")} required="" />
                                                                <label htmlFor="name">State</label>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-xl-4 cl-lg-4 col-md-4 col-sm-4">
                                                        <div className="edit_input mobile_number_input">
                                                            <h6 className="font_18 font_500">Country</h6>
                                                            <div className="input-group">
                                                                <input type="text" className="form-control" autoComplete='off' {...register("country")} required="" />
                                                                <label htmlFor="name">Country</label>
                                                            </div>
                                                        </div>
                                                    </div> */}
                          <div
                            className="col-6 mt-0 mt-lg-5"
                            onClick={() => {
                              //   navigate(`/profile/${selectedUser?._id}`);
                              navigate("/profile", {
                                state: selectedUser?._id,
                              });
                            }}
                          >
                            <button
                              type="button"
                              className="edit_cancel blue_apply_bg orange_btn font_14 inter font_600"
                              style={{ textAlign: "center", display: "block" }}
                            >
                              Cancel
                            </button>
                          </div>
                          <div className="col-6 mt-0 mt-lg-5">
                            <button
                              type="submit"
                              className="blue_apply_bg orange_btn white_ff font_14 inter font_600"
                            >
                              Save All Changes
                            </button>
                          </div>
                        </form>
                      </div>
                    </div>
                  </div>
                </div>
                {/* Notification */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Notification"
                  role="tabpanel"
                  aria-labelledby="v-pills-Notification-tab"
                >
                  <NotificationSettings />
                </div>
                {/* Messages */}
                <div
                  className="tab-pane fade"
                  id="v-pills-messages"
                  role="tabpanel"
                  aria-labelledby="v-pills-messages-tab"
                >
                  ...
                </div>
                {/* Change Password */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Password"
                  role="tabpanel"
                  aria-labelledby="v-pills-Password-tab"
                >
                  <ChangePassword />
                </div>
                {/* Education */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Education"
                  role="tabpanel"
                  aria-labelledby="v-pills-Education-tab"
                >
                  <Education />
                </div>
                {/* Account Settings */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Account-Settings"
                  role="tabpanel"
                  aria-labelledby="v-pills-Account-Settings-tab"
                >
                  <AccountSettings />
                </div>
                {/* Account Privacy */}
                <div
                  className="tab-pane fade"
                  id="v-pills-Privacy"
                  role="tabpanel"
                  aria-labelledby="v-pills-Privacy-tab"
                >
                  <AccountPrivacy />
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
