import React, { useEffect, useState } from "react";
import { get_college_list } from "../../api/apiHandler";
import { useNavigate } from "react-router-dom";

export default function UpdatedColleges() {
  const [collegeList, setCollegeList] = useState([]);
  const navigate = useNavigate();
  useEffect(() => {
    get_college_list({}).then((response) => {
      console.log('response: ', response);
      if (response.code == 1) {
        setCollegeList(response.data.colleges_list_result);
      } else {
        setCollegeList([]);
      }
    });
  }, []);

  return (
    <>
      <div className="trending_admission_box mb-5">
        <div className="trending_admission_box_heading">
          <img
            src="assets/images/icons/other-colleges.svg"
            className="img-fluid me-2"
            alt="icon"
          />
          <h6 className="white_ff font_16 font_500 mb-0">Other Colleges</h6>
        </div>
        <div className="trending_admission_box_body">
          <ul className="list-unstyled">
            {collegeList &&
              collegeList.map((item, index) => {
                return (
                  <li key={index}>
                    <div className="chandi_admission">
                      <div className="uni_profile_image">
                        <img
                          src={item.college_logo}
                          className="img-fluid"
                          alt={item.college_logo}
                        />
                      </div>
                      <div className="uni_profile_text">
                        <h6 className="inter font_400 font_15 mb-0">
                          {item.name}
                        </h6>
                        <div className="yellow_location d-flex align-items-center">
                          <img
                            src="assets/images/icons/map-yellow.svg"
                            className="img-fluid me-1"
                            alt="icon"
                          />
                          <span className="font_10 inter yellow_ff">
                            {item.location}
                          </span>
                        </div>
                        <span className="gray_5a inter font_12">
                          <span onClick={() => { navigate('/college-info', { state: { id: item._id, type: 'Admission' } }) }}>Admission</span> |
                          <span onClick={() => { navigate('/college-info', { state: { id: item._id, type: 'Placement' } }) }}> Placement</span>
                        </span>
                      </div>
                    </div>
                    <span className="blue_29 font_12 inter">
                      {/* Bachelor of technology , Master of technology */}
                    </span>
                    <p className="font_10 mb-0 mt-1">
                      Total fees: {item.max_fees} | 4 Years | On Campus |
                      Fulltime
                    </p>
                  </li>
                );
              })}
          </ul>
        </div>
      </div>
    </>
  );
}
