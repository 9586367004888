import React, { useCallback, useEffect, useRef, useState } from "react";
import HeaderProfile from "./HeaderProfile";
import Header from "./Header";
import InfiniteScroll from "react-infinite-scroll-component";
import ReactTimeAgo from "react-time-ago";
import {
  add_answers,
  add_comments,
  add_qa,
  college_details,
  courses_list,
  gallery,
  get_answer_list,
  get_category_list,
  get_college_list,
  get_degree_list,
  get_home_trending_college,
  get_qa_list,
  get_topic_list,
  give_qa_like_unlike,
  home_post,
  share_link,
} from "../api/apiHandler";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Rating } from "@mui/material";
import TrendingAdmission from "./TrendingAdmission";
import StudentsAlsoVisited from "./StudentsAlsoVisited";
import { useForm } from "react-hook-form";
import { yupResolver } from "@hookform/resolvers/yup";
import * as yup from "yup";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import Faq from "./Faq";
import CollegeRating from "./CollegeRating";
import StudentReview from "./StudentReview";
import { Helmet } from "react-helmet";
import UpdatedColleges from "./courses/UpdatedColleges";
import EmojiPicker from "emoji-picker-react";
import ShareLink from "./pages/HomeFeed/components/ShareLink";
import Select from 'react-select'

export default function Collegeinfo() {
  const [emojiPickerForComments, setEmojiPickerForComments] = useState(false);
  const [isFetching, setIsFetching] = useState(true);

  const [collegeDetail, setCollegeDetail] = useState();
  console.log('collegeDetail: ', collegeDetail);
  const [courseList, setCourseList] = useState([]);
  const [collegeGallery, setCollegeGallery] = useState([]);
  const [degrees, setDegrees] = useState([]);
  const [questions, setQuestions] = useState([]);
  console.log('questions: ', questions);
  const [topicList, setTopicList] = useState([]);
  const [commentData, setCommentData] = useState();
  const [sortValue, setSortValue] = useState();
  const [tredingAdmissions, setTredingAdmissions] = useState();
  const [studentAlsoVisitedData, setStudentAlsoVisitedData] = useState();
  const [comment, setComment] = useState("");
  const [totalRating, setTotalRating] = useState();
  const [newsData, setNewsData] = useState("");
  console.log('newsData: ', newsData, newsData.length);
  console.log('comment: ', comment);
  const [commentQaId, setCommentQaId] = useState("");
  console.log('commentQaId: ', commentQaId);
  const [totalFees, setTotalFees] = useState();

  const AdmissionRef = useRef(null);
  const PlacementRef = useRef(null);
  const CoursesRef = useRef(null);
  const CutOffRef = useRef(null);
  const GalleryRef = useRef(null);
  const location = useLocation();
  const [shareModalOpen, setShareModalOpen] = useState({
    open: false,
    share_link: "",
    id: "",
  });
  const HandleAdmissionClickFun = () => {
    if (AdmissionRef.current) {
      AdmissionRef.current.dispatchEvent(new MouseEvent("click"));
    }
  }
  const HandlePlacementClickFun = () => {
    if (PlacementRef.current) {
      PlacementRef.current.dispatchEvent(new MouseEvent("click"));
    }
  }
  const HandleCourseClickFun = () => {
    if (CoursesRef.current) {
      CoursesRef.current.dispatchEvent(new MouseEvent("click"));
    }
  }
  const HandleCutoffClickFun = () => {
    if (CutOffRef.current) {
      CutOffRef.current.dispatchEvent(new MouseEvent("click"));
    }
  }
  const HandleGalleryClickFun = () => {
    window.scrollTo(0, 0);
    if (GalleryRef.current) {
      GalleryRef.current.dispatchEvent(new MouseEvent("click"));
    }
  }

  let params = location?.state;
  if (location?.state?.id) {
    params = location?.state?.id
    if (location?.state?.type == 'Admission') {
      HandleAdmissionClickFun();
    } else if (location?.state?.type == 'Placement') {
      HandlePlacementClickFun();
    } else if (location?.state?.type == 'Course') {
      HandleCourseClickFun();
    } else if (location?.state?.type == 'Cutoff') {
      HandleCutoffClickFun();
    }
  } else {
    params = location?.state
  }
  const navigate = useNavigate();
  var avg = 0;
  var total = 0;
  console.log("Line 30 Location", params);

  const validationSchema = yup.object().shape({
    question: yup.string().required("Please enter question").max(160)
      .matches(/^\S|.*\S.*$/, 'Spaces are not allowed in the question'),
    que_category: yup.array().required("Please Select Category").min(1, "Please Select Category")
  });

  const handleShare = useCallback((data) => {
    share_link({
      id: data._id,
      module_type: 'Q&A',
      description: data?.description || "stulink",
    }).then((response) => {
      console.log('response: ', response);
      if (response.code == "1") {
        setShareModalOpen({
          open: true,
          share_link: response.data,
          id: data._id,
        });
      }
    });
  }, []);

  const formOptions = { resolver: yupResolver(validationSchema) };
  const { register, handleSubmit, reset, formState, setValue } = useForm(formOptions);
  const { errors } = formState;
  console.log('errors: ', errors);

  const questionList = (sortValue) => {
    get_qa_list({
      type: "colleges",
      college_id: params,
      category: "College",
      degree_id: "",
      short_by: sortValue,
    }).then((response) => {
      console.log("question list response", response);
      if (response.code === 1 || response.code === "1") {
        setQuestions(response?.data);
      }
    });
  };

  const qaLike = (id, isLike) => {
    give_qa_like_unlike({
      id: id,
      type: isLike === 0 ? "like" : "unlike",
    }).then((response) => {
      console.log("Like response", response);
      if (response.code === 1 || response.code === "1") {
        questionList();
      }
    });
  };
  const options = [
    { value: 'College', label: 'College' },
    { value: 'Jobs', label: 'Jobs' },
    { value: 'Exams', label: 'Exams' },
    { value: 'Results', label: 'Results' },
    { value: 'Education', label: 'Education' }
  ]
  const handleCountryChange = (selectedOptions) => {
    let selectedValues = selectedOptions ? selectedOptions.map(option => option.value) : [];
    console.log("selectedOptions: ", selectedOptions);
    setValue("que_category", selectedValues);
  };
  const onSubmit = (data) => {
    add_qa({
      description: data?.question,
      category: data?.que_category,
      type: "colleges",
      college_id: params,
    }).then((response) => {
      console.log(response);
      if (response.code === 1 || response.code === "1") {
        toast.success(response.message, {
          toastId: "add-question",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        questionList();
        reset();
      }
    });
  };

  const album = (type) => {
    gallery({ page_token: "1", id: params, album_type: type }).then(
      (response) => {
        console.log("Line 29 Gallery", response);
        if (response.code === 1 || response.code === "1") {
          setCollegeGallery(response?.data);
        }
      }
    );
  };
  const likeQa = (qId) => {
    console.log("click Like Button", qId);
    give_qa_like_unlike({ id: qId, type: "like" }).then((response) => {
      console.log("Like Response", response);
      if (response?.code === 1 || response?.code === "1") {
        questionList()
        // questionList(filteredValue, categoryValue);
      } else {
        questionList()
        // questionList(filteredValue, categoryValue);
      }
    }).catch((error) => {
      console.error(error);
    });
  };
  const dislikeQa = (qId) => {
    give_qa_like_unlike({ id: qId, type: "unlike" }).then((response) => {
      console.log("UnLike Response", response);
      if (response?.code === 1 || response?.code === "1") {
        questionList()
        // questionList(filteredValue, categoryValue);
      } else {
        questionList()
        // questionList(filteredValue, categoryValue);
      }
    });
  };
  useEffect(() => {
    college_details({ page_token: "1", id: params }).then((response) => {
      console.log("Line 17 College Detail", response);
      if (response.code === 1 || response.code === "1") {
        setCollegeDetail(response?.data[0]);
      }
    });
    courses_list({ page_token: "1", id: params }).then((response) => {
      console.log("Line 23 Courses", response);
      if (response.code === 1 || response.code === "1") {
        setCourseList(response?.data);
      }
    });
    album();
    get_degree_list({ page_token: "1" }).then((response) => {
      console.log("Line 37 Degrees", response);
      if (response.code === 1 || response.code === "1") {
        setDegrees(response?.data?.degree_list_result);
      }
    });
    home_post({ module_type: "news", college_id: params }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        console.log('response: ', response);
        setNewsData(response.data);
      }
    })
    get_college_list({ page_token: 1 }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        console.log('response: ', response);
        setStudentAlsoVisitedData(response.data)
      }
    })
    get_topic_list({ page_token: 1 }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        console.log('response: ', response);
        setTopicList(response.data)
        // setStudentAlsoVisitedData(response.data)
      }
    })
    get_home_trending_college({ page_token: 1 }).then((response) => {
      if (response.code === 1 || response.code === "1") {
        console.log('response: ', response);
        setTredingAdmissions(response.data)
      }
    })
    questionList();
  }, []);

  useEffect(() => {
    if (collegeDetail && collegeDetail?.college_rating.length > 0) {
      const total_rating = collegeDetail.college_rating.reduce((acc, item) => acc + item.rate, 0) / collegeDetail.college_rating.length;
      setTotalRating(total_rating);
    }
  }, [collegeDetail])
  const HandleCommentSubmit = () => {
    console.log(comment);
    add_answers({
      qa_id: commentQaId,
      description: comment,
    }).then((response) => {
      if (comment === "") {
        toast.error("Please enter answer", {
          toastId: "answerErrorPost",
          position: "top-center",
          autoClose: 2000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
        return false;
      } else {
        if (response.code === 1 || response.code === "1") {
          toast.success(response.message, {
            toastId: "answerPost",
            position: "top-center",
            autoClose: 2000,
            hideProgressBar: true,
            closeOnClick: true,
            pauseOnHover: true,
            draggable: true,
            progress: undefined,
            theme: "colored",
          });
          setComment("");
          questionList();
          // answerListing();
        }
        return true;
      }
    });
  }
  return (
    <>
      {localStorage.getItem("isSignedUp") ||
        localStorage.getItem("isLoggedIn") ? (
        <>
          <HeaderProfile />
          <ShareLink
            shareModalOpen={shareModalOpen}
            setShareModalOpen={setShareModalOpen}
          />
        </>
      ) : (
        <Header />
      )}
      {/* Comment Modal new */}
      <div
        className="modal fade"
        id="commentModal"
        tabIndex={-1}
        aria-labelledby="comment"
        aria-hidden="true"
      >
        <div className="modal-dialog modal-lg">
          <div className="modal-content">
            <div className="modal-body position-relative">
              <button
                type="button"
                className="btn-close close_custom"
                data-bs-dismiss="modal"
                aria-label="Close"
                onClick={() => {
                  setComment("");
                  // setFileForComments([]);
                  // setSelectedFileForComments([]);
                  // setHeightWidthForComments([]);
                }}
              />
              <div className="comment_main">
                <div className="user_comment">
                  <div className="comment_user_post">
                    <div className="main_comment_other border_0 comment_line">
                      <div className="other_user_comment">
                        <span>
                          <a href="#">
                            <img
                              src={commentData?.profile_image}
                              className="inner_post"
                            />{" "}
                            {commentData?.name}
                          </a>
                          @{commentData?.username}
                          <p>
                            {commentData?.insertdate ? (
                              <ReactTimeAgo
                                date={commentData?.insertdate}
                                locale="en-US"
                              />
                            ) : (
                              <></>
                            )}
                          </p>
                        </span>
                      </div>
                      <div className="comment_image ms-5 mt-3 mb-3">
                        {commentData?.post_type === "retweet" ? (
                          <>
                            <label>
                              {commentData?.shared_feed?.description}
                            </label>

                            {commentData?.shared_feed?.media?.length ===
                              0 ? (
                              <></>
                            ) : (
                              <>
                                {commentData?.shared_feed?.media?.map(
                                  (item) => {
                                    return item?.name;
                                  }
                                )}
                              </>
                            )}
                          </>
                        ) : commentData?.post_type ===
                          "retweet_with_quote" ? (
                          <>
                            <label>{commentData?.description}</label>

                            <div
                              className="tetweet_quote"
                              style={{ marginLeft: "0px" }}
                            >
                              <div className="post_person_mark">
                                <Link to={"#"} className="post_person_name">
                                  <div className="post_person_image">
                                    <img
                                      src={
                                        commentData?.shared_feed
                                          ?.profile_image
                                      }
                                      className="img-fluid"
                                      alt="profile-pic"
                                    />
                                  </div>
                                  <div className="post_person_text">
                                    <h6 className="font_12 font_600 mb-0">
                                      {commentData?.shared_feed?.name}
                                    </h6>
                                    <span className="light_gray_8b font_12 inter">
                                      @{commentData?.shared_feed?.username}
                                    </span>
                                  </div>
                                </Link>
                                <div className="post_person_date">
                                  <h5 className="font_10 black_0e inter d-flex align-items-center justify-content-end">
                                    {commentData?.shared_feed?.insertdate ? (
                                      <ReactTimeAgo
                                        date={
                                          commentData?.shared_feed
                                            ?.insertdate
                                        }
                                        locale="en-US"
                                      />
                                    ) : (
                                      <></>
                                    )}
                                  </h5>
                                </div>
                              </div>
                              <div className="tetweet_quote_content">
                                {commentData?.shared_feed?.media?.length ===
                                  0 ? (
                                  <></>
                                ) : commentData?.shared_feed?.media[0]
                                  ?.type === "image" ? (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.media[0]
                                          ?.name
                                      }
                                      alt="post"
                                    />
                                  </div>
                                ) : (
                                  <div className="tetweet_quote_image">
                                    <img
                                      src={
                                        commentData?.shared_feed?.media[0]
                                          ?.thumb
                                      }
                                      alt="post"
                                    />
                                  </div>
                                )}
                                {commentData?.module_type === "news" ? (
                                  <div className="tetweet_quote_text">
                                    <p
                                      className="mb-0 gray_5a font_12"
                                      dangerouslySetInnerHTML={{
                                        __html:
                                          commentData?.shared_feed
                                            ?.description,
                                      }}
                                    ></p>
                                  </div>
                                ) : (
                                  <div className="tetweet_quote_text">
                                    <p className="mb-0 gray_5a font_12">
                                      {commentData?.shared_feed?.description}
                                    </p>
                                  </div>
                                )}
                              </div>
                            </div>
                          </>
                        ) : commentData?.module_type === "news" ? (
                          <label
                            dangerouslySetInnerHTML={{
                              __html: commentData?.description,
                            }}
                          ></label>
                        ) : (
                          <label>{commentData?.description}</label>
                        )}

                        <br />
                        {commentData && commentData?.media?.length === 0 ? (
                          <></>
                        ) : (
                          <label>
                            {commentData?.media?.map((item) => {
                              return item?.name;
                            })}
                          </label>
                        )}
                      </div>
                      <div className="other_user_comment d-flex">
                        <span>
                          <a href="#">
                            <img
                              src={localStorage?.getItem("profile_image")}
                              className="inner_post"
                            />
                          </a>
                        </span>
                        <input
                          type="text"
                          name=""
                          value={comment}
                          onChange={(e) => {
                            setComment(e?.target?.value);
                            setEmojiPickerForComments(false);
                          }}
                          placeholder="Post your comment..!!"
                        />
                      </div>
                      {/* <div className="uploade_images">
                        {filesForComments?.length !== 0 ? (
                          filesForComments?.map((item, key) => {
                            switch (item.type.split("/")[0]) {
                              case "image": {
                                return (
                                  <div className="images_tab_1">
                                    <img src={URL.createObjectURL(item)} />
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                              case "video": {
                                return (
                                  <div
                                    className="images_tab_1"
                                    onMouseEnter={() =>
                                      handleMouseEnter(key)
                                    }
                                    onMouseLeave={() =>
                                      handleMouseLeave(key)
                                    }
                                  >
                                    <video
                                      autoPlay={false}
                                      loop=""
                                      id={`banner${key}`}
                                      controls="false"
                                      controlslist="nodownload"
                                      style={{ height: "200px" }}
                                    >
                                      <source
                                        src={URL.createObjectURL(item)}
                                      />
                                    </video>
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/cross.svg"
                                      }
                                      className="cross-icon"
                                      onClick={() =>
                                        removeImageForComments(key)
                                      }
                                    />
                                  </div>
                                );
                              }
                            }
                          })
                        ) : (
                          <></>
                        )}
                      </div> */}
                    </div>
                  </div>
                </div>
              </div>
            </div>
            <div className="modal-footer d-flex justify-content-between">
              <ul className="post_option ms-2 me-auto flex-row">
                <li>
                  <input
                    type="file"
                    style={{ display: "none" }}
                    accept=".png, .jpeg, .jpg"
                    id="imageForPosts"
                    multiple
                  // onChange={handleFileInputForComments}
                  // disabled={
                  //   disablePhotoInputForComments === true ||
                  //   filesForComments?.length === 4 ||
                  //   selectedFileForComments?.length === 4 ||
                  //   heightWidthForComments?.length === 4
                  // }
                  />
                  <img
                    src={
                      process.env.PUBLIC_URL +
                      "/assets/images/icons/gallery.svg"
                    }
                    className="img-fluid"
                    alt="gallery"
                  />

                </li>
                <li>
                  <a className="mx-3">
                    <input
                      type="file"
                      style={{ display: "none" }}
                      // ref={vidRef}
                      accept=".mp4, .avi, .mov, .mkv, .wmv"
                      id="imageForPosts"
                    // onChange={handleVideoUploadForComments}
                    // disabled={
                    //   disableVideoInputForComments === true ||
                    //   filesForComments?.length === 1 ||
                    //   selectedFileForComments?.length === 1 ||
                    //   heightWidthForComments?.length === 1
                    // }
                    />
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/video-icon.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />

                  </a>
                </li>
                <li>
                  <a
                    style={{ cursor: "pointer" }}
                    onClick={() =>
                      setEmojiPickerForComments(!emojiPickerForComments)
                    }
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/emoji.svg"
                      }
                      className="img-fluid"
                      alt="gallery"
                    />
                  </a>
                </li>
              </ul>
              <button
                disabled={!comment}
                onClick={() => {
                  HandleCommentSubmit();
                  // uploadImagesForComments();
                }}
                className="reels_btn post_btn"
                data-bs-dismiss="modal"
                aria-label="Close"
              >
                Comment
              </button>
            </div>
            {emojiPickerForComments ? (
              <div className="mx-5 mt-0 mb-2">
                <EmojiPicker
                  onEmojiClick={(e) => setComment(comment + e?.emoji)}
                />
              </div>
            ) : (
              <></>
            )}
          </div>
        </div>
      </div>
      <section className="one_college">
        <ToastContainer
          position="top-center"
          autoClose={2000}
          hideProgressBar
          newestOnTop={false}
          closeOnClick
          rtl={false}
          pauseOnFocusLoss
          draggable
          pauseOnHover
          theme="colored"
        />
        <div className="container">
          <div className="row align-items-center">
            <div className="col-12 col-xl-2 col-lg-2 col-md-3 col-sm-3">
              <div className="college_logo">
                <img
                  src={collegeDetail?.college_log}
                  className="img-fluid"
                  style={{ height: "inherit", width: "inherit" }}
                  alt="college-logo"
                />
              </div>
            </div>
            <div className="col-12 col-xl-6 col-lg-6 col-md-9 col-sm-9">
              <div className="college_short_details">
                <h1 className="font_30 font_700">{collegeDetail?.name}</h1>
                <div className="college_location">
                  <div className="college_location_address">
                    <div className="location_review">
                      <div className="location_review_1">
                        <div className="college_location_icon">
                          <img
                            src="/assets/images/icons/college-location.svg"
                            className="img-fluid"
                            alt="location"
                          />
                        </div>
                        <div className="college_location_name">
                          <span className="light_gray_8b">
                            {collegeDetail?.location}
                          </span>
                        </div>
                      </div>
                      <div className="location_review_2">
                        <div className="college_ratting d-flex align-items-center">
                          {collegeDetail?.college_rating?.map((i) => {
                            avg +=
                              i?.rate / collegeDetail?.college_rating?.length;
                          })}
                          <Rating value={avg} precision={avg % 1 !== 0 ? 0.5 : 1} readOnly />
                          <span className="font_12 black_00 ms-2">
                            ({avg}) Review
                          </span>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
                <div className="uni_type">
                  <div className="estd">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/estd.svg"
                      }
                      className="img-fluid"
                      alt="icon"
                    />
                    <span className="font_14 ms-1">
                      ESTD {collegeDetail?.estd_year}
                    </span>
                  </div>
                  <div className="estd private_university">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/uni.svg"
                      }
                      className="img-fluid"
                      alt="icon"
                    />
                    <span className="font_14 ms-1">
                      {collegeDetail?.type} University
                    </span>
                  </div>
                  <div className="estd">
                    <img
                      src={
                        process.env.PUBLIC_URL + "/assets/images/icons/save.svg"
                      }
                      className="img-fluid"
                      alt="icon"
                    />
                    <span className="font_14 ms-1">
                      NAAC Rating - {collegeDetail?.naac_rating}
                    </span>
                  </div>
                </div>
              </div>
            </div>
            <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
              <div className="row justify-content-end">
                <div className="col-6">
                  {/* <a
                    href="javascript:void(0)"
                    className="earth_red compare_space d-flex align-items-center justify-content-end"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/Globe-Earth.svg"
                      }
                      className="img-fluid"
                      alt="globe"
                    />
                    <span className="font_20 red_ff">Link</span>
                  </a> */}
                  {/* <a
                    href={collegeDetail?.broucher}
                    target="_blank"
                    className="brochures_button"
                  >
                    <button type="button" className="orange_btn">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/download-white.svg"
                        }
                        className="me-2 img-fluid"
                        alt="download"
                      />
                      <span className="white_ff font_16 font_600">
                        Brochures
                      </span>
                    </button>
                  </a> */}
                </div>
                <div className="col-6">
                  {/* <a
                    href="javascript:void(0)"
                    className="earth_red compare_space d-flex align-items-center justify-content-end"
                  >
                    <img
                      src={
                        process.env.PUBLIC_URL +
                        "/assets/images/icons/compare.svg"
                      }
                      className="img-fluid me-1"
                      alt="globe"
                    />
                    <span className="font_20 blue_29">Compare</span>
                  </a> */}
                  <Link
                    to={`/apply-now/college/${params}`}
                    state={{ logo: collegeDetail?.college_log, name: collegeDetail?.name, path: '/college-info', pathState: location?.state }}
                    className="brochures_button"
                  >
                    <button type="button" className="blue_apply_bg orange_btn">
                      <img
                        src={
                          process.env.PUBLIC_URL +
                          "/assets/images/icons/apply_now.svg"
                        }
                        className="me-2 img-fluid"
                        alt="download"
                      />
                      <span className="white_ff font_16 font_600">
                        Apply Now
                      </span>
                    </button>
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className="college-tabs">
        <div className="college_nav">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <ul
                  className="college_nav_tab nav nav-tabs"
                  id="myTab"
                  role="tablist"
                >
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link active"
                      id="information-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#information"
                      type="button"
                      role="tab"
                      aria-controls="information"
                      aria-selected="true"
                    >
                      Information
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      ref={CoursesRef}
                      id="courses-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#courses"
                      type="button"
                      role="tab"
                      aria-controls="courses"
                      aria-selected="false"
                    >
                      Courses
                    </button>
                  </li>
                  <li className="nav-item" role="presentation" >
                    <button
                      className={`font_16 nav-link`}
                      ref={AdmissionRef}
                      id="admissions-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#admissions"
                      type="button"
                      role="tab"
                      aria-controls="admissions"
                      aria-selected="false"
                    >
                      Admissions
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      ref={CutOffRef}
                      id="cut-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#cut"
                      type="button"
                      role="tab"
                      aria-controls="cut"
                      aria-selected="false"
                    >
                      Cut Off
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      ref={GalleryRef}
                      id="gallery-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#gallery"
                      type="button"
                      role="tab"
                      aria-controls="gallery"
                      aria-selected="false"
                    >
                      Gallery
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="news-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#news"
                      type="button"
                      role="tab"
                      aria-controls="news"
                      aria-selected="false"
                    >
                      News
                    </button>
                  </li>
                  {/* <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="ranking-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#ranking"
                      type="button"
                      role="tab"
                      aria-controls="ranking"
                      aria-selected="false"
                    >
                      Ranking
                    </button>
                  </li> */}
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="scholarship-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#scholarship"
                      type="button"
                      role="tab"
                      aria-controls="scholarship"
                      aria-selected="false"
                    >
                      Scholarship
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      ref={PlacementRef}
                      id="placements-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#placements"
                      type="button"
                      role="tab"
                      aria-controls="placements"
                      aria-selected="false"
                    >
                      Placements
                    </button>
                  </li>
                  <li className="nav-item" role="presentation">
                    <button
                      className="font_16 nav-link"
                      id="qa-tab"
                      data-bs-toggle="tab"
                      data-bs-target="#qa"
                      type="button"
                      role="tab"
                      aria-controls="qa"
                      aria-selected="false"
                    >
                      Q&A
                    </button>
                  </li>
                </ul>
              </div>
            </div>
          </div>
        </div>
        <div className="college_nav_content">
          <div className="container">
            <div className="row">
              <div className="col-md-12">
                <div className="tab-content" id="myTabContent">
                  <div
                    className="tab-pane fade show active "
                    id="information"
                    role="tabpanel"
                    aria-labelledby="information-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/rating.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              College Rating
                            </h3>
                          </div>
                          <div className="row">
                            <div className="college_rating_cirle rounded-image col-4">
                              <div className="rounded-image_2 text-center d-flex justify-content-center align-items-center">{totalRating}</div>
                            </div>
                            <div class="col-8 d-flex justify-content-center align-items-center">
                              <div className="text-center college-rating-font">
                                Overall Rating College
                                <br></br>
                                {totalRating && (
                                  // <Rating value={4.2} readOnly />
                                  <Rating
                                    value={totalRating}
                                    precision={totalRating % 1 !== 0 ? 0.5 : 1}
                                  />
                                )}
                              </div>
                            </div>
                          </div>
                          <div className="row mt-4">
                            {
                              collegeDetail && collegeDetail?.college_rating.length > 0 && collegeDetail?.college_rating.map((value, index) => (
                                <div className="col-6" style={{ fontWeight: 500, fontSize: 'large' }}>
                                  {value.rating_type_name + "                          "}
                                  <Rating className="mt-2" value={value.rate} precision={totalRating % 1 !== 0 ? 0.5 : 1} readOnly />
                                </div>
                              ))
                            }
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/about-college.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              About College
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <div className="row">
                              <div className="col-12 col-xl-4 col-lg-4 col-md-12 col-sm-12">
                                <div className="about_clg_img">
                                  <img
                                    src={collegeDetail?.college_log}
                                    className="img-fluid w-100"
                                    alt="image"
                                  />
                                </div>
                              </div>
                              <div className="col-12 col-xl-8 col-lg-8 col-md-12 col-sm-12 mt-3 mt-lg-0">
                                <p
                                  className="black_14 font_18 inter ck-content"
                                  dangerouslySetInnerHTML={{
                                    __html: collegeDetail?.information,
                                  }}
                                >
                                  { }
                                </p>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/top-course.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Top Courses & Fees
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <div className="row">
                              {courseList?.map((item, key) => {
                                var totalFees = 0;
                                item?.fees?.map(
                                  (subItem) =>
                                  (totalFees =
                                    totalFees + parseInt(subItem?.fees))
                                );
                                return (
                                  <div
                                    className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12"
                                    key={key}
                                  >
                                    <div className="topcourse_box">
                                      <h6 className="font_400 inter font_18 green_1d">
                                        {item?.name}
                                      </h6>
                                      <p className="mb-0 gray_5a font_12 font_500 inter">
                                        Annual Fee:{" "}
                                        <span className="font_400 blue_29">
                                          INR{" "}
                                          {`${totalFees >= 100000
                                            ? (totalFees / 100000).toFixed(
                                              2
                                            ) + " lacs"
                                            : totalFees
                                            }`}
                                        </span>{" "}
                                        | Total Seats:{" "}
                                        <span className="font_400 blue_29">
                                          {item?.no_of_seat} Seats
                                        </span>
                                      </p>
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/image-gallery.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Image Gallery
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <div className="row justify-content-center">
                              {collegeGallery?.slice(0, 3).map((item, key) => {
                                return (
                                  <div
                                    className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6"
                                    key={key}
                                  >
                                    <div className="image_gallery">
                                      {item?.type === "image" ? (
                                        <img
                                          src={item?.image}
                                          className="img-fluid"
                                          alt=""
                                        />
                                      ) : (
                                        <video controls autoPlay muted loop>
                                          <source src={item?.image} />
                                        </video>
                                      )}
                                    </div>
                                  </div>
                                );
                              })}
                            </div>
                            <div className="text-end">
                              <a href="javascript:void(0)" className="blue_29" onClick={() => { HandleGalleryClickFun() }}>
                                View All Images{" "}
                                <img
                                  src={
                                    process.env.PUBLIC_URL +
                                    "/assets/images/icons/right-blue-arrow.svg"
                                  }
                                  className="img-fluid ms-2"
                                  alt="icon"
                                />
                              </a>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                        <br />
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="courses"
                    role="tabpanel"
                    aria-labelledby="courses-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <div className="programmes">
                          <div className="programmes_heading">
                            <h5 className="mb-0 font_600 font_25 white_ff">
                              Programmes
                            </h5>
                          </div>

                          {degrees?.map((item) => {
                            return (
                              <div className="programmes_field" key={item?._id}>
                                <span className="font_16 black_14">
                                  {item?.name}
                                </span>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                      <div className="col-12 col-xl-6 col-lg-6 col-md-12 col-sm-12">
                        <div className="information_box_heading">
                          <img
                            src={
                              process.env.PUBLIC_URL +
                              "/assets/images/icons/courses.svg"
                            }
                            className="img-fluid"
                            alt="icon"
                          />
                          <h3 className="blue_29 font_500 font_25 mb-0">
                            Courses
                          </h3>
                        </div>
                        {courseList?.map((item) => {
                          return (
                            <div className="information_box" key={item?._id}>
                              <div className="information_box_body ps-0">
                                <div className="regular_heading d-flex justify-content-between align-items-center">
                                  <h5 className="mb-0 orange_ffa font_18 font_500 d-flex align-items-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/book-open.svg"
                                      }
                                      className="img-fluid me-2"
                                      alt="icon"
                                    />{" "}
                                    {item?.name}
                                  </h5>
                                  <span className="font_500 font_10 inter green_1d">
                                    {item?.duration} Years | Regular
                                  </span>
                                </div>
                                <div className="row">
                                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <p className="mb-0 black_00 font_12 font_500 d-flex align-items-center">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/Check-File.svg"
                                        }
                                        className="img-fluid me-2"
                                        alt="icon"
                                      />
                                      EXAM | {item?.exam_name}
                                    </p>
                                  </div>
                                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <p className="mb-0 black_00 font_12 font_500 d-flex align-items-center">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/Graduation-Scroll.svg"
                                        }
                                        className="img-fluid me-2"
                                        alt="icon"
                                      />
                                      Lateral Entry | Available
                                    </p>
                                  </div>
                                  <div className="col-12 col-xl-6 col-lg-6 col-md-6 col-sm-12 mb-3">
                                    <p className="mb-0 black_00 font_12 font_500 d-flex align-items-center">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/eligibility-check.svg"
                                        }
                                        className="img-fluid me-2"
                                        alt="icon"
                                      />
                                      Eligibility Criteria |{" "}
                                      {item?.criteria_eligibility}
                                    </p>
                                  </div>
                                </div>
                                <div className="information_box_heading mt-2">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/fees.svg"
                                    }
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_18 mb-0">
                                    Fees
                                  </h3>
                                </div>
                                <div className="table-responsive">
                                  <table className="table table-borderless">
                                    <thead>
                                      <tr className="wise_heading_bg">
                                        <th className="white_ff font_500">
                                          Year wise
                                        </th>
                                        {item?.fees?.map((duration, key) => {
                                          return (
                                            <th
                                              className="text-center white_ff font_500"
                                              key={key}
                                            >
                                              {duration?.duration}
                                            </th>
                                          );
                                        })}
                                      </tr>
                                    </thead>
                                    <tbody>
                                      <tr className="wise_body_bg">
                                        <td className="font_12">Total fees</td>
                                        {item?.fees?.map((fee, key) => {
                                          return (
                                            <td
                                              className="text-center font_12"
                                              key={key}
                                            >
                                              {fee?.fees}
                                            </td>
                                          );
                                        })}
                                      </tr>
                                    </tbody>
                                  </table>
                                </div>
                                {/* <div className="text-end">
                                                                        <a href="javascript:void(0)" className="green_1d font_12">View Full
                                                                            Fee Details<img
                                                                                src={process.env.PUBLIC_URL+"/assets/images/icons/green-right-arrow.svg"}
                                                                                className="img-fluid ms-2" alt="icon" /></a>
                                                                    </div> */}
                                <div className="information_box_heading mt-2">
                                  <img
                                    src={
                                      process.env.PUBLIC_URL +
                                      "/assets/images/icons/available-courses.svg"
                                    }
                                    className="img-fluid"
                                    alt="icon"
                                  />
                                  <h3 className="blue_29 font_500 font_18 mb-0">
                                    Available Courses
                                  </h3>
                                </div>
                                <div className="available_courses">
                                  <div className="row">
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                      {item?.degree_courses
                                        ?.slice(0, 3)
                                        ?.map((i, key) => {
                                          return (
                                            <p
                                              className="black_14 font_12 mb-3"
                                              key={key}
                                            >
                                              {i?.course_name}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/orange-right-arrow.svg"
                                                }
                                                className="img-fluid m1-2"
                                                alt="icon"
                                              />
                                            </p>
                                          );
                                        })}
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                      {item?.degree_courses
                                        ?.slice(3, 6)
                                        ?.map((i, key) => {
                                          return (
                                            <p
                                              className="black_14 font_12 mb-3"
                                              key={key}
                                            >
                                              {i?.course_name}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/orange-right-arrow.svg"
                                                }
                                                className="img-fluid m1-2"
                                                alt="icon"
                                              />
                                            </p>
                                          );
                                        })}
                                    </div>
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-6 col-sm-6">
                                      {item?.degree_courses
                                        ?.slice(6)
                                        ?.map((i, key) => {
                                          return (
                                            <p
                                              className="black_14 font_12 mb-3"
                                              key={key}
                                            >
                                              {i?.course_name}
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/orange-right-arrow.svg"
                                                }
                                                className="img-fluid m1-2"
                                                alt="icon"
                                              />
                                            </p>
                                          );
                                        })}
                                    </div>
                                  </div>
                                </div>
                                <div className="row justify-content-center">
                                  <Link
                                    to={`/apply-now/courses/${params}`}
                                    state={{ logo: item.icon, name: item.name, path: '/college-info', pathState: location?.state }}
                                    className="col-6 col-xl-4 col-lg-6 col-md-6 col-sm-6"
                                  >
                                    <button
                                      type="button"
                                      className="blue_apply_bg orange_btn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/apply_now.svg"
                                        }
                                        className="me-2 img-fluid"
                                        alt="download"
                                      />
                                      <span className="white_ff font_14 inter font_600">
                                        Apply Now
                                      </span>
                                    </button>
                                  </Link>
                                  <a
                                    href={item?.broucher}
                                    target="_blank"
                                    className="col-6 col-xl-4 col-lg-6 col-md-6 col-sm-6"
                                  >
                                    <button
                                      type="button"
                                      className="brochure_black blue_apply_bg orange_btn"
                                    >
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/icons/download-black.svg"
                                        }
                                        className="me-2 img-fluid"
                                        alt="download"
                                      />
                                      <span className="black_00 font_14 font_600 inter">
                                        Brochures
                                      </span>
                                    </button>
                                  </a>
                                </div>
                              </div>
                            </div>
                          );
                        })}
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                        <br />
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />
                      </div>
                    </div>
                  </div>

                  <div
                    className={`tab-pane fade show`}
                    id="admissions"
                    role="tabpanel"
                    aria-labelledby="admissions-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div
                          className="information_box"
                          dangerouslySetInnerHTML={{
                            // __html: collegeDetail?.admission_details,
                            __html: collegeDetail?.admission == 'Y' && collegeDetail?.admission_details,
                          }}
                        ></div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                        <br />
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="cut"
                    role="tabpanel"
                    aria-labelledby="cut-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div
                          className="information_box"
                          dangerouslySetInnerHTML={{
                            __html: collegeDetail?.cutoff == 'Y' ? collegeDetail?.cutoff_details : '',
                          }}
                        ></div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        {/* <UpdatedColleges /> */}
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />

                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="gallery"
                    role="tabpanel"
                    aria-labelledby="gallery-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/album.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Album
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <nav className="album_tab">
                              <div
                                className="nav nav-tabs"
                                id="nav-tab"
                                role="tablist"
                              >
                                <button
                                  onClick={() => {
                                    album("");
                                  }}
                                  className="nav-link text-uppercase font_16 font_500 gray_5a  active"
                                  id="nav-all-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-all"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-all"
                                  aria-selected="true"
                                >
                                  all
                                </button>
                                <button
                                  onClick={() => {
                                    album("Campus");
                                  }}
                                  className="nav-link text-uppercase font_16 font_500 gray_5a "
                                  id="nav-campus-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-campus"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-campus"
                                  aria-selected="false"
                                >
                                  campus
                                </button>
                                <button
                                  onClick={() => {
                                    album("Facility");
                                  }}
                                  className="nav-link text-uppercase font_16 font_500 gray_5a "
                                  id="nav-facility-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-facility"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-facility"
                                  aria-selected="false"
                                >
                                  facility
                                </button>
                                <button
                                  onClick={() => {
                                    album("Events");
                                  }}
                                  className="nav-link text-uppercase font_16 font_500 gray_5a "
                                  id="nav-events-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-events"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-events"
                                  aria-selected="false"
                                >
                                  events
                                </button>
                                <button
                                  onClick={() => {
                                    album("Hostel");
                                  }}
                                  className="nav-link text-uppercase font_16 font_500 gray_5a "
                                  id="nav-hostel-tab"
                                  data-bs-toggle="tab"
                                  data-bs-target="#nav-hostel"
                                  type="button"
                                  role="tab"
                                  aria-controls="nav-hostel"
                                  aria-selected="false"
                                >
                                  hostel
                                </button>
                              </div>
                            </nav>
                            <div className="tab-content" id="nav-tabContent">
                              <div
                                className="tab-pane fade show active"
                                id="nav-all"
                                role="tabpanel"
                                aria-labelledby="nav-all-tab"
                              >
                                <div className="row">
                                  {collegeGallery?.map((item) => {
                                    return item?.type === "image" ? (
                                      <div
                                        className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-6 mt-lg-3"
                                        key={item?._id}
                                      >
                                        <div className="content h-100">
                                          <div className="content-overlay"></div>
                                          <img
                                            className="content-image h-100"
                                            src={item?.image}
                                            alt="image"
                                          />
                                          <div className="content-details fadeIn-left">
                                            <h6 className="font_600 font_16">
                                              College <br />
                                              Campus
                                            </h6>
                                            <p className="mb-0 font_10 inter font_500">
                                              {item?.album_type}
                                            </p>
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/black-right-arrow.svg"
                                              }
                                              alt="arrow"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </div>
                              </div>
                              <div
                                className="tab-pane fade show"
                                id="nav-campus"
                                role="tabpanel"
                                aria-labelledby="nav-campus-tab"
                              >
                                <div className="row">
                                  {collegeGallery?.map((item) => {
                                    return item?.type === "image" &&
                                      item?.album_type === "Campus" ? (
                                      <div
                                        className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-6 mt-lg-3"
                                        key={item?._id}
                                      >
                                        <div className="content h-100">
                                          <div className="content-overlay"></div>
                                          <img
                                            className="content-image h-100"
                                            src={item?.image}
                                            alt="image"
                                          />
                                          <div className="content-details fadeIn-left">
                                            <h6 className="font_600 font_16">
                                              College <br />
                                              Campus
                                            </h6>
                                            <p className="mb-0 font_10 inter font_500">
                                              {item?.album_type}
                                            </p>
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/black-right-arrow.svg"
                                              }
                                              alt="arrow"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </div>
                              </div>
                              <div
                                className="tab-pane fade show"
                                id="nav-facility"
                                role="tabpanel"
                                aria-labelledby="nav-facility-tab"
                              >
                                <div className="row">
                                  {collegeGallery?.map((item) => {
                                    return item?.type === "image" &&
                                      item?.album_type === "Facility" ? (
                                      <div
                                        className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-6 mt-lg-3"
                                        key={item?._id}
                                      >
                                        <div className="content h-100">
                                          <div className="content-overlay"></div>
                                          <img
                                            className="content-image h-100"
                                            src={item?.image}
                                            alt="image"
                                          />
                                          <div className="content-details fadeIn-left">
                                            <h6 className="font_600 font_16">
                                              College <br />
                                              Campus
                                            </h6>
                                            <p className="mb-0 font_10 inter font_500">
                                              {item?.album_type}
                                            </p>
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/black-right-arrow.svg"
                                              }
                                              alt="arrow"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </div>
                              </div>
                              <div
                                className="tab-pane fade show"
                                id="nav-events"
                                role="tabpanel"
                                aria-labelledby="nav-events-tab"
                              >
                                <div className="row">
                                  {collegeGallery?.map((item) => {
                                    return item?.type === "image" &&
                                      item?.album_type === "Events" ? (
                                      <div
                                        className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-6 mt-lg-3"
                                        key={item?._id}
                                      >
                                        <div className="content h-100">
                                          <div className="content-overlay"></div>
                                          <img
                                            className="content-image h-100"
                                            src={item?.image}
                                            alt="image"
                                          />
                                          <div className="content-details fadeIn-left">
                                            <h6 className="font_600 font_16">
                                              College <br />
                                              Campus
                                            </h6>
                                            <p className="mb-0 font_10 inter font_500">
                                              {item?.album_type}
                                            </p>
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/black-right-arrow.svg"
                                              }
                                              alt="arrow"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </div>
                              </div>
                              <div
                                className="tab-pane fade show"
                                id="nav-hostel"
                                role="tabpanel"
                                aria-labelledby="nav-hostel-tab"
                              >
                                <div className="row">
                                  {collegeGallery?.map((item) => {
                                    return item?.type === "image" &&
                                      item?.album_type === "Hostel" ? (
                                      <div
                                        className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-6 mt-lg-3"
                                        key={item?._id}
                                      >
                                        <div className="content h-100">
                                          <div className="content-overlay"></div>
                                          <img
                                            className="content-image h-100"
                                            src={item?.image}
                                            alt="image"
                                          />
                                          <div className="content-details fadeIn-left">
                                            <h6 className="font_600 font_16">
                                              College <br />
                                              Campus
                                            </h6>
                                            <p className="mb-0 font_10 inter font_500">
                                              {item?.album_type}
                                            </p>
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/black-right-arrow.svg"
                                              }
                                              alt="arrow"
                                            />
                                          </div>
                                        </div>
                                      </div>
                                    ) : (
                                      <></>
                                    );
                                  })}
                                </div>
                              </div>
                            </div>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/videos.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Videos
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <div
                              className="tab-pane fade show active"
                              id="nav-all"
                              role="tabpanel"
                              aria-labelledby="nav-all-tab"
                            >
                              <div className="row justify-content-center">
                                {collegeGallery?.map((item) => {
                                  return item?.type === "video" ? (
                                    <>
                                      <div
                                        className="college_video"
                                        style={{ width: "100%" }}
                                      >
                                        <div className="video_image">
                                          {/* <img src={process.env.PUBLIC_URL+"/assets/images/page-image/video-1.svg"}
                                                                                            className="img-fluid" alt="" /> */}
                                          <video
                                            controls
                                            autoPlay
                                            muted
                                            loop
                                            poster={item?.thumb}
                                            key={item?._id}
                                          >
                                            <source
                                              src={item?.image}
                                              style={{ width: "100%" }}
                                            />
                                          </video>
                                        </div>
                                        <div className="video_event_name">
                                          <div className="video_event_name_1">
                                            <div className="video_event_name_image">
                                              <img
                                                src={
                                                  process.env.PUBLIC_URL +
                                                  "/assets/images/icons/college-event.svg"
                                                }
                                                className="img-fluid"
                                                alt="icon"
                                              />
                                            </div>
                                            <div className="video_event_name_text">
                                              <h6 className="mb-0 white_ff font_18 font_600">
                                                {item?.album_type}
                                              </h6>
                                              <span className="white_ff font_12 font_500">
                                                {new Date(
                                                  item?.created_at
                                                ).toLocaleString([], {
                                                  day: "2-digit",
                                                })}
                                                -
                                                {new Date(
                                                  item?.created_at
                                                ).toLocaleString([], {
                                                  month: "2-digit",
                                                })}
                                                -
                                                {new Date(
                                                  item?.created_at
                                                ).getFullYear()}
                                              </span>
                                            </div>
                                          </div>
                                        </div>
                                        <div className="play_thumbnail"></div>
                                      </div>
                                    </>
                                  ) : (
                                    <></>
                                  );
                                })}
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">

                        {studentAlsoVisitedData && studentAlsoVisitedData?.colleges_list_result.length > 0 && (
                          <div className="trending_admission_box mb-5">
                            <div className="trending_admission_box_heading">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/cutoff-school.svg"
                                }
                                className="img-fluid me-2"
                                alt="icon"
                              />
                              <h6 className="white_ff font_15 font_500 mb-0">
                                Recently Updated Colleges
                              </h6>
                            </div>
                            <div className="trending_admission_box_body">
                              <ul className="list-unstyled">
                                {studentAlsoVisitedData?.colleges_list_result.slice(0, 4).map((value, index) => (
                                  <li key={index}>
                                    <div className="chandi_admission">
                                      <div className="uni_profile_image">
                                        <img
                                          src={value.college_logo}
                                          className="img-fluid"
                                          alt="image"
                                        />
                                      </div>
                                      <div className="uni_profile_text">
                                        <h6 className="inter font_400 font_15 mb-0">
                                          {value.name}
                                        </h6>
                                        <div className="yellow_location d-flex align-items-center">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/map-yellow.svg"
                                            }
                                            className="img-fluid me-1"
                                            alt="icon"
                                          />
                                          <span className="font_10 inter yellow_ff">
                                            {value.location}
                                          </span>
                                        </div>
                                      </div>
                                    </div>
                                    <span className="gray_5a inter font_12">
                                      <span onClick={() => { HandleAdmissionClickFun() }}>Admission</span>|
                                      <span onClick={() => { HandlePlacementClickFun() }}>Placement</span>|
                                      <span onClick={() => { HandleCourseClickFun() }}>Courses</span>|
                                      <span onClick={() => { HandleCutoffClickFun() }}>CutOff</span>
                                    </span>
                                  </li>
                                ))}
                              </ul>
                            </div>
                          </div>
                        )}
                        {/* <div className="trending_admission_box">
                          <div className="trending_admission_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/new-member.svg"
                              }
                              className="img-fluid me-2"
                              alt="icon"
                            />
                            <h6 className="white_ff font_16 font_500 mb-0">
                              Newly Joined member
                            </h6>
                          </div>
                          <div className="trending_admission_box_body">
                            <ul className="list-unstyled">
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="cut_off_uni d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/member-1.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="sania_name">
                                      <h6 className="inter font_400 font_15 mb-0">
                                        Sania Sharma
                                      </h6>
                                      <span className="blue_29 font_12 d-flex align-items-baseline">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/school-blue.svg"
                                          }
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        Punjab University
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sania_btn ms-auto">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_14 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="cut_off_uni d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/member-2.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="sania_name">
                                      <h6 className="inter font_400 font_15 mb-0">
                                        Lovepreet Kaur
                                      </h6>
                                      <span className="blue_29 font_12 d-flex align-items-baseline">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/school-blue.svg"
                                          }
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        Chandigarh University
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sania_btn ms-auto">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_14 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="cut_off_uni d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/member-3.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="sania_name">
                                      <h6 className="inter font_400 font_15 mb-0">
                                        Sukhpreet Singh
                                      </h6>
                                      <span className="blue_29 font_12 d-flex align-items-baseline">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/school-blue.svg"
                                          }
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        Indian Institute of Technology Bombay{" "}
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sania_btn ms-auto">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_14 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="cut_off_uni d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/member-4.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="sania_name">
                                      <h6 className="inter font_400 font_15 mb-0">
                                        Dewansh
                                      </h6>
                                      <span className="blue_29 font_12 d-flex align-items-baseline">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/school-blue.svg"
                                          }
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        Lovely Professional University
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sania_btn ms-auto">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_14 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="cut_off_uni d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/member-5.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="sania_name">
                                      <h6 className="inter font_400 font_15 mb-0">
                                        Rahul Gupta
                                      </h6>
                                      <span className="blue_29 font_12 d-flex align-items-baseline">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/school-blue.svg"
                                          }
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        Adesh University
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sania_btn ms-auto">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_14 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="cut_off_uni d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/member-6.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <div className="sania_name">
                                      <h6 className="inter font_400 font_15 mb-0">
                                        Ajay Kumar{" "}
                                      </h6>
                                      <span className="blue_29 font_12 d-flex align-items-baseline">
                                        <img
                                          src={
                                            process.env.PUBLIC_URL +
                                            "/assets/images/icons/school-blue.svg"
                                          }
                                          className="me-1 img-fluid"
                                          alt="icon"
                                        />
                                        Punjab University
                                      </span>
                                    </div>
                                  </div>
                                  <div className="sania_btn ms-auto">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_14 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div> */}
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="news"
                    role="tabpanel"
                    aria-labelledby="news-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/latest-news.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Latest News
                            </h3>
                          </div>
                          <div className="information_box_body">
                            {newsData && newsData.length > 0 && newsData.map((value, index) => (
                              <>
                                <div className="bitsat">
                                  <div className="row">
                                    <div className="col-12 col-xl-4 col-lg-4 col-md-4 col-sm-12">
                                      <div className="bitsat_image news_img">
                                        <img
                                          src={
                                            value.media[0].name
                                          }
                                          className="img-fluid"
                                          alt="image"
                                        />
                                      </div>
                                    </div>
                                    <div className="col-12 col-xl-8 col-lg-8 col-md-8 col-sm-12">
                                      <div className="bitsat_text">
                                        <h6 className="font_18 font_600 black_14">
                                          {value.title}
                                        </h6>
                                        <p className="font_12 font_600">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/ramdas.svg"
                                            }
                                            className="img-fluid"
                                            alt="man"
                                          />{" "}
                                          <span className="blue_29">
                                            {value.username}
                                          </span>
                                          <span className="gray_5a">
                                            |{" "}
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/images/icons/Planner.svg"
                                              }
                                              className="img-fluid"
                                              alt="planner"
                                            />{" "}
                                          </span>
                                          {value?.insertdate ? (
                                            <ReactTimeAgo
                                              date={value?.insertdate}
                                              locale="en-US"
                                            />
                                          ) : (
                                            <></>
                                          )}{" "}
                                        </p>
                                        <p className="light_gray_8b inter font_14">
                                          {/* Lorem ipsum dolor sit amet, consectetuer
                                          adipiscing elit. Aenean commodo ligula
                                          eget dolor. Aenean massa. Cum sociis
                                          natoque penatibus et magnis dis parturient
                                          montes . */}
                                          <p
                                            className="inter font_18 black_14"
                                            dangerouslySetInnerHTML={{
                                              __html: value.description,
                                            }}
                                          ></p>
                                        </p>
                                        <a
                                          href="#"
                                          className="yellow_ff inter font_500 d-flex align-items-center"
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/share.svg"
                                            }
                                            className="img-fluid me-2"
                                            alt="share"
                                          />
                                          Share
                                        </a>
                                      </div>
                                    </div>
                                  </div>
                                </div>
                              </>
                            ))}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        {topicList && topicList?.length > 0 && (
                          <div className="trending_admission_box mb-5">
                            <div className="trending_admission_box_heading">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/trending-up.svg"
                                }
                                className="img-fluid me-2"
                                alt="icon"
                              />
                              <h6 className="white_ff font_15 font_500 mb-0">
                                Trending Topics
                              </h6>
                            </div>
                            <div className="trending_admission_box_body">
                              <ul className="list-unstyled">
                                {
                                  topicList.map((value, index) => (
                                    <li>
                                      <div className="chandi_admission align-items-baseline">
                                        <div className="has_image">
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/images/icons/hash.svg"
                                            }
                                            className="img-fluid"
                                            alt="image"
                                          />
                                        </div>
                                        <div className="uni_profile_text">
                                          <h6 className="inter font_400 font_16 mb-0">
                                            {value.name}
                                          </h6>
                                          <span className="light_gray_8b font_10">
                                            {value.description}{" "}
                                          </span>
                                        </div>
                                      </div>
                                    </li>
                                  ))
                                }
                              </ul>
                            </div>
                          </div>
                        )}
                        <div className="trending_admission_box">
                          <div className="trending_admission_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/new-member.svg"
                              }
                              className="img-fluid me-2"
                              alt="icon"
                            />
                            <h6 className="white_ff font_16 font_500 mb-0">
                              Topic to follow
                            </h6>
                          </div>
                          <div className="trending_admission_box_body">
                            <ul className="list-unstyled">
                              <li>
                                <div className="cut_off_uni d-flex align-items-center justify-content-between">
                                  <h6 className="inter font_400 font_14 black_14 mb-0">
                                    Educational Multimedia
                                  </h6>
                                  <div className="sania_btn">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_16 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="cut_off_uni d-flex align-items-center justify-content-between">
                                  <h6 className="inter font_400 font_14 black_14 mb-0">
                                    Tutoring & Mentorin
                                  </h6>
                                  <div className="sania_btn">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_16 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="cut_off_uni d-flex align-items-center justify-content-between">
                                  <h6 className="inter font_400 font_14 black_14 mb-0">
                                    Health & Sports Education
                                  </h6>
                                  <div className="sania_btn">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_16 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="cut_off_uni d-flex align-items-center justify-content-between">
                                  <h6 className="inter font_400 font_14 black_14 mb-0">
                                    Cognitive Strategies
                                  </h6>
                                  <div className="sania_btn">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_16 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="cut_off_uni d-flex align-items-center justify-content-between">
                                  <h6 className="inter font_400 font_14 black_14 mb-0">
                                    Economics in Education
                                  </h6>
                                  <div className="sania_btn">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_16 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="cut_off_uni d-flex align-items-center justify-content-between">
                                  <h6 className="inter font_400 font_14 black_14 mb-0">
                                    Parental Involvement
                                  </h6>
                                  <div className="sania_btn">
                                    <button
                                      type="button"
                                      className="blue_button d-flex align-items-center font_12 font_500"
                                    >
                                      <span className="icon-link font_16 me-2 font_12"></span>
                                      Link
                                    </button>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>

                  {/* <div
                    className="tab-pane fade show"
                    id="ranking"
                    role="tabpanel"
                    aria-labelledby="ranking-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/rank-analysis.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Rank Analysis
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <p
                              className="inter font_18 black_14"
                              dangerouslySetInnerHTML={{
                                __html: collegeDetail?.ranking == 'Y' ? collegeDetail?.ranking_details : '',
                              }}
                            ></p>
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <div className="trending_admission_box mb-5">
                          <div className="trending_admission_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/bar-chart.svg"
                              }
                              className="img-fluid me-2"
                              alt="icon"
                            />
                            <h6 className="white_ff font_16 font_500 mb-0">
                              Top Ranked Colleges
                            </h6>
                          </div>
                          <div className="trending_admission_box_body">
                            <ul className="list-unstyled">
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="uni_profile_text_ranking me-2 d-flex align-items-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/hash-1.svg"
                                      }
                                      className="img-fluid me-2"
                                      alt="hash"
                                    />
                                    <p className="mb-0 blue_29 font_18 font_500">
                                      1
                                    </p>
                                  </div>
                                  <div className="cut_off_uni_ranking d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/college-profile-1.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <h6 className="inter font_400 font_15 mb-0">
                                      Chandigarh University
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="uni_profile_text_ranking me-2 d-flex align-items-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/hash-1.svg"
                                      }
                                      className="img-fluid me-2"
                                      alt="hash"
                                    />
                                    <p className="mb-0 blue_29 font_18 font_500">
                                      2
                                    </p>
                                  </div>
                                  <div className="cut_off_uni_ranking d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/college-profile-2.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <h6 className="inter font_400 font_15 mb-0">
                                      Lovely Professional University
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="uni_profile_text_ranking me-2 d-flex align-items-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/hash-1.svg"
                                      }
                                      className="img-fluid me-2"
                                      alt="hash"
                                    />
                                    <p className="mb-0 blue_29 font_18 font_500">
                                      3
                                    </p>
                                  </div>
                                  <div className="cut_off_uni_ranking d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/college-profile-3.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <h6 className="inter font_400 font_15 mb-0">
                                      Indian Institute of Technology Bombay
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="uni_profile_text_ranking me-2 d-flex align-items-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/hash-1.svg"
                                      }
                                      className="img-fluid me-2"
                                      alt="hash"
                                    />
                                    <p className="mb-0 blue_29 font_18 font_500">
                                      4
                                    </p>
                                  </div>
                                  <div className="cut_off_uni_ranking d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/college-profile-3.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <h6 className="inter font_400 font_15 mb-0">
                                      Adesh University
                                    </h6>
                                  </div>
                                </div>
                              </li>
                              <li>
                                <div className="chandi_admission justify-content-between align-items-center">
                                  <div className="uni_profile_text_ranking me-2 d-flex align-items-center">
                                    <img
                                      src={
                                        process.env.PUBLIC_URL +
                                        "/assets/images/icons/hash-1.svg"
                                      }
                                      className="img-fluid me-2"
                                      alt="hash"
                                    />
                                    <p className="mb-0 blue_29 font_18 font_500">
                                      5
                                    </p>
                                  </div>
                                  <div className="cut_off_uni_ranking d-flex align-items-center">
                                    <div className="uni_profile_image">
                                      <img
                                        src={
                                          process.env.PUBLIC_URL +
                                          "/assets/images/user/college-profile-4.svg"
                                        }
                                        className="img-fluid"
                                        alt="image"
                                      />
                                    </div>
                                    <h6 className="inter font_400 font_15 mb-0">
                                      Indian Institute of Technology Kanpur{" "}
                                    </h6>
                                  </div>
                                </div>
                              </li>
                            </ul>
                          </div>
                        </div>
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                      </div>
                    </div>
                  </div> */}

                  <div
                    className="tab-pane fade show"
                    id="scholarship"
                    role="tabpanel"
                    aria-labelledby="scholarship-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div
                          className="information_box"
                          dangerouslySetInnerHTML={{
                            __html: collegeDetail?.scolarship == 'Y' ? collegeDetail?.scolarship_details : '',
                          }}
                        ></div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                        <br />
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="placements"
                    role="tabpanel"
                    aria-labelledby="placements-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div
                          className="information_box"
                          dangerouslySetInnerHTML={{
                            __html: collegeDetail?.placement == 'Y' ? collegeDetail?.placement_details : '',
                          }}
                        ></div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                        <br />
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />
                      </div>
                    </div>
                  </div>

                  <div
                    className="tab-pane fade show"
                    id="qa"
                    role="tabpanel"
                    aria-labelledby="qa-tab"
                  >
                    <div className="row">
                      <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                        <div className="information_box">
                          <div className="information_box_heading">
                            <img
                              src={
                                process.env.PUBLIC_URL +
                                "/assets/images/icons/helpdesk.svg"
                              }
                              className="img-fluid"
                              alt="icon"
                            />
                            <h3 className="blue_29 font_500 font_25 mb-0">
                              Help Desk & Q&A
                            </h3>
                          </div>
                          <div className="information_box_body">
                            <form onSubmit={handleSubmit(onSubmit)}>
                              {/* <div className="your_character">
                                                            <h6 className="mb-0 green_1d font_18 font_500">Your Question
                                                            </h6>
                                                            <span className="font_15 font_400 light_gray_8b">Characters
                                                                0/160</span>
                                                        </div> */}
                              <div className="your_input">
                                <input
                                  type="text"
                                  className="form-control font_15"
                                  placeholder="Type Your Question"
                                  autoComplete="off"
                                  {...register("question", {
                                    pattern: {
                                      value: /^(?!.*\s{2})\S+(?:\s\S+)*$/,
                                      message: "Please avoid consecutive spaces and multiple spaces"
                                    }
                                  })}
                                />{" "}
                                <span style={{ color: "red" }}>
                                  {errors?.question?.message}
                                </span>
                              </div>

                              <div className="your_input">
                                <Select
                                  placeholder="Select Category"
                                  options={options}
                                  name="que_category"
                                  className="mt-4"
                                  isMulti
                                  {...register("que_category", { required: 'Please Select Category' })}
                                  onChange={handleCountryChange}
                                />
                                {errors && errors?.que_category && <span style={{ color: "red" }}>{errors?.que_category?.message}</span>}
                                {/* <select
                                  className="form-select"
                                  aria-label="Default select example"
                                  {...register("que_category")}
                                  multiple
                                >
                                  <option selected>College</option>
                                  <option >Jobs</option>
                                  <option >Exams</option>
                                  <option >Results</option>
                                  <option >Education</option>
                                </select> */}
                              </div>
                              <div className="your_btn">
                                <button
                                  type="submit"
                                  className="edit_p_button font_14 inter"
                                >
                                  Submit
                                </button>
                                <button
                                  type="reset"
                                  className="your_cancel_btn font_14 inter"
                                  onClick={() => { reset() }}
                                >
                                  Cancel
                                </button>
                              </div>
                            </form>
                          </div>
                        </div>
                        <div className="information_box">
                          <div className="unanswer_list">
                            <div className="information_box_heading">
                              <img
                                src={
                                  process.env.PUBLIC_URL +
                                  "/assets/images/icons/questions.svg"
                                }
                                className="img-fluid"
                                alt="icon"
                              />
                              <h3 className="blue_29 font_500 font_25 mb-0">
                                Questions |{" "}
                                <span className="light_gray_8b">
                                  Unanswered
                                </span>
                              </h3>
                            </div>
                            <div className="sort_by d-flex align-items-center">
                              <p className="font_500 font_18 gray_5a mb-0 inter">
                                SORT BY:
                              </p>
                              <div className="sort_dropdown dropdown">
                                {/* <button
                                                                className="btn dropdown-toggle inter black_14 font_15 font_500"
                                                                type="button" id="dropdownMenuButton1"
                                                                data-bs-toggle="dropdown" aria-expanded="false">
                                                                Default Order
                                                            </button>
                                                            <ul className="dropdown-menu"
                                                                aria-labelledby="dropdownMenuButton1">
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Fees</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Newest</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Oldest</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Top Rated</a></li>
                                                                <li><a className="dropdown-item inter black_14 font_15 font_500"
                                                                        href="#">Recommended</a></li>
                                                            </ul> */}
                                <select
                                  className="form-select mx-1"
                                  style={{ border: "1px solid grey" }}
                                  aria-label="Default select example"
                                  value={sortValue}
                                  onChange={(e) => {
                                    setSortValue(e.target.value);
                                    questionList(e.target.value);
                                  }}
                                >
                                  <option value="L" selected>
                                    Latest
                                  </option>
                                  <option value="O">Oldest</option>
                                </select>
                              </div>
                            </div>
                          </div>

                          <div className="information_box_body">
                            {/* <div
                              className="tab-pane fade"
                              id="news"
                              role="tabpanel"
                              aria-labelledby="news-tab"
                            > */}
                            {/* <div className="row"> */}
                            {/* <InfiniteScroll
                                  dataLength={questions?.length}
                                  next={questionList}
                                  hasMore={isFetching}
                                > */}
                            {questions?.map((item) => {
                              return (
                                <div className="col-12 mb-3" key={item?._id}>
                                  <div className="comment_section">
                                    <div className="comment_1">
                                      <div className="aq_user">
                                        <img src={item?.profile_image} />
                                      </div>
                                      <div className="aq_title">
                                        <h6>
                                          {item?.name}{" "}
                                          <a
                                            href="#"
                                            className="user_name ms-2"
                                          >
                                            @{item?.username}{" "}
                                          </a>
                                        </h6>
                                        <div className="sub_option_student">
                                          {item?.college_uni_name !== "" ||
                                            item?.college_uni_name !== null ? (
                                            <span>
                                              {item?.college_uni_name && (
                                                <img
                                                  src={
                                                    process.env.PUBLIC_URL +
                                                    "/assets/video/school.svg"
                                                  }
                                                  className="me-2"
                                                />
                                              )}
                                              {item?.college_uni_name?.length <
                                                35
                                                ? item?.college_uni_name
                                                : item?.college_uni_name?.slice(
                                                  0,
                                                  35
                                                ) + "..."}
                                            </span>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="list_of_tag mt-2 mb-2 ms-auto">
                                            {item?.category?.map((i) => {
                                              return (
                                                <>
                                                  <span className="font-13 tab_option mx-1">
                                                    {i}
                                                  </span>
                                                </>
                                              );
                                            })}
                                          </div>
                                          {item?.insertdate ? (
                                            <div className="time_text me-3 ms-2">
                                              <p className="m-0">
                                                {/* {dayjs(item?.insertdate).format(
                                                  "DD MMM HH:mm A"
                                                )} */}
                                              </p>
                                            </div>
                                          ) : (
                                            <></>
                                          )}
                                          <div className="dropdown">
                                            {item?._id ===
                                              localStorage?.getItem("_id") ? (
                                              <>
                                                <a
                                                  className="dropdown-toggle"
                                                  href="#"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/more_horiz.svg"
                                                    }
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      Delete
                                                    </a>
                                                  </li>
                                                </ul>
                                              </>
                                            ) : (
                                              <>
                                                <a
                                                  className="dropdown-toggle"
                                                  role="button"
                                                  id="dropdownMenuLink"
                                                  data-bs-toggle="dropdown"
                                                  aria-expanded="false"
                                                >
                                                  <img
                                                    src={
                                                      process.env.PUBLIC_URL +
                                                      "/assets/video/more_horiz.svg"
                                                    }
                                                  />
                                                </a>
                                                <ul
                                                  className="dropdown-menu"
                                                  aria-labelledby="dropdownMenuLink"
                                                >
                                                  <li>
                                                    {/* <Link to={`/profile/${item.user_id}`} className="dropdown-item"> */}
                                                    <Link
                                                      to="/profile"
                                                      state={item.user_id}
                                                      className="dropdown-item"
                                                    >
                                                      Link @{item?.username}
                                                    </Link>
                                                  </li>
                                                  <li>
                                                    <a
                                                      className="dropdown-item"
                                                      href="#"
                                                    >
                                                      Report User
                                                    </a>
                                                  </li>
                                                </ul>
                                              </>
                                            )}
                                          </div>
                                        </div>
                                      </div>
                                    </div>
                                    <div
                                      className="mid_text"
                                      onClick={() =>
                                        navigate("/question", { state: item })
                                      }
                                      style={{ cursor: "pointer" }}
                                    >
                                      <p>
                                        {item?.description?.length < 100
                                          ? item?.description
                                          : item?.description?.slice(0, 100) +
                                          "..."}
                                      </p>
                                    </div>
                                    <div className="footer_text_qa">
                                      <div className="like_dislike d-flex align-items-center">
                                        <a
                                          className={`like me-3 ${item?.is_like === 1
                                            ? "text-green"
                                            : ""
                                            }`}
                                          onClick={() => {
                                            likeQa(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          <img
                                            src={
                                              item?.is_like === 1
                                                ? process.env.PUBLIC_URL +
                                                "/assets/video/like.svg"
                                                : process.env.PUBLIC_URL +
                                                "/assets/video/like-blank.svg"
                                            }
                                            className="me-1"
                                          />
                                          {item?.likes}
                                        </a>
                                        <a
                                          // className={`dislke me-3 ${
                                          //   item?.is_dislike === 1
                                          //     ? "text-red"
                                          //     : ""
                                          // }`}
                                          className={
                                            item?.is_dislike === 1
                                              ? "dislke me-3 text-red"
                                              : "dislke me-3"
                                          }
                                          onClick={() => {
                                            dislikeQa(item?._id);
                                          }}
                                          style={{ cursor: "pointer" }}
                                        >
                                          {item?.is_unlike === 1 ? (
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/dislike.svg"
                                              }
                                              className="me-1"
                                            />
                                          ) : (
                                            <img
                                              src={
                                                process.env.PUBLIC_URL +
                                                "/assets/video/dislike-blank.png"
                                              }
                                              className="me-1"
                                            />
                                          )}

                                          {item?.unlikes}
                                        </a>
                                        <a
                                          type="button"
                                          data-bs-target="#commentModal"
                                          data-bs-toggle="modal"
                                          className="me-3"
                                          onClick={() => { setCommentData(item); setCommentQaId(item._id); }}
                                        >
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/comment_brown.svg"
                                            }
                                            className="me-1"
                                          />{" "}
                                          Comment {item?.comment_count}
                                        </a>
                                        <a href="javascript:void(0)" className="" onClick={() => {
                                          handleShare(item);
                                        }}>
                                          <img
                                            src={
                                              process.env.PUBLIC_URL +
                                              "/assets/video/plane_brown.svg"
                                            }
                                            className="me-1"
                                          />
                                          Share
                                        </a>
                                      </div>
                                      {/* <div className="drop d-flex align-items-center">
                                  <a href="#">
                                    {item?.comment_count} Reply
                                  </a>
                                </div> */}
                                    </div>
                                  </div>
                                </div>
                                // <div className="question_body" key={item?._id}>
                                //   <div className="questioner">
                                //     <div className="questioner_image">
                                //       <img
                                //         src={item?.profile_image}
                                //         className="img-fluid"
                                //         alt="user"
                                //       />
                                //     </div>
                                //     <div className="questioner_text">
                                //       <div
                                //         className="questioner_text_link"
                                //         onClick={() => {
                                //           // navigate(`/profile/${item?.user_id}`)
                                //           navigate("/profile", {
                                //             state: item?.user_id,
                                //           });
                                //         }}
                                //         style={{ cursor: "pointer" }}
                                //       >
                                //         <h6 className="inter font_16 font_600 black_14 mb-0 me-2">
                                //           -{item?.name}
                                //         </h6>
                                //         <span className="font_14 blue_29 d-flex align-items-center">
                                //           <img
                                //             src={
                                //               process.env.PUBLIC_URL +
                                //               "/assets/images/icons/verified.svg"
                                //             }
                                //             className="img-fluid"
                                //             alt="verified"
                                //           />
                                //           Verified
                                //         </span>
                                //         <div className="sania_btn ms-auto">
                                //           <button
                                //             type="button"
                                //             className="blue_button d-flex align-items-center font_12 font_500"
                                //           >
                                //             <span className="icon-link font_14 me-2 font_12"></span>
                                //             Link
                                //           </button>
                                //         </div>
                                //       </div>
                                //       {item?.college_uni_name === "" ||
                                //         item?.college_uni_name === undefined ||
                                //         item?.college_uni_name === null ? (
                                //         <p className="light_gray_8b inter font_14">
                                //           {" "}
                                //           <span className="black_14 font_500">
                                //             {" "}
                                //             <i>Updated on July 12, 2021</i>{" "}
                                //           </span>
                                //         </p>
                                //       ) : (
                                //         <p className="light_gray_8b inter font_14">
                                //           {item?.college_uni_name}
                                //           <span className="black_14 font_500">
                                //             | <i>Updated on July 12, 2021</i>{" "}
                                //           </span>
                                //         </p>
                                //       )}

                                //       <p
                                //         className="font_500 inter font_18 black_14 mb-0"
                                //         onClick={() => {
                                //           navigate(`/question`, {
                                //             state: item,
                                //           });
                                //         }}
                                //         style={{ cursor: "pointer" }}
                                //       >
                                //         {item?.description}
                                //       </p>
                                //     </div>
                                //   </div>
                                //   <div className="questioner_border"></div>
                                //   <div className="row mt-3 mt-md-5">
                                //     <div
                                //       className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                                //       onClick={() => {
                                //         qaLike(item?._id, item?.is_like);
                                //       }}
                                //     >
                                //       <button
                                //         type="button"
                                //         className={`questioner_green_btn inter font_500 font_15 ${item?.is_like === 0 ? "" : "active"
                                //           }`}
                                //       >
                                //         <img
                                //           src={
                                //             process.env.PUBLIC_URL +
                                //             "/assets/images/icons/green-like.svg"
                                //           }
                                //           className="img-fluid me-2"
                                //           alt="like"
                                //         />
                                //         <span>Like</span>
                                //       </button>
                                //     </div>
                                //     <div
                                //       className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                                //       onClick={() => {
                                //         navigate(`/question/${item?._id}`, {
                                //           state: { q: item },
                                //         });
                                //       }}
                                //       style={{ cursor: "pointer" }}
                                //     >
                                //       <button
                                //         type="button"
                                //         className="questioner_green_btn inter font_500 font_15"
                                //       >
                                //         <img
                                //           src={
                                //             process.env.PUBLIC_URL +
                                //             "/assets/images/icons/green-comment.svg"
                                //           }
                                //           className="img-fluid me-2"
                                //           alt="like"
                                //         />
                                //         <span>Comments</span>
                                //       </button>
                                //     </div>
                                //     <div
                                //       className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6"
                                //       onClick={() => {
                                //         navigate(`/question/${item?._id}`, {
                                //           state: { q: item },
                                //         });
                                //       }}
                                //       style={{ cursor: "pointer" }}
                                //     >
                                //       <button
                                //         type="button"
                                //         className="questioner_green_btn inter font_500 font_15"
                                //       >
                                //         <span className="answer_digit inter">
                                //           {item?.comment_count}
                                //         </span>
                                //         <span>Answer</span>
                                //       </button>
                                //     </div>
                                //     <div className="col-6 col-xl-3 col-lg-3 col-md-3 col-sm-6">
                                //       <button
                                //         type="button"
                                //         className="questioner_green_btn inter font_500 font_15"
                                //       >
                                //         <img
                                //           src={
                                //             process.env.PUBLIC_URL +
                                //             "/assets/images/icons/green-share.svg"
                                //           }
                                //           className="img-fluid me-2"
                                //           alt="like"
                                //         />
                                //         <span>Share</span>
                                //       </button>
                                //     </div>
                                //   </div>
                                // </div>
                              );
                            })}
                            {/* </InfiniteScroll> */}
                            {/* </div> */}
                            {/* </div> */}
                          </div>
                        </div>
                      </div>
                      <div className="col-12 col-xl-3 col-lg-3 col-md-12 col-sm-12">
                        <TrendingAdmission tredingAdmissions={tredingAdmissions} HandleAdmissionClickFun={HandleAdmissionClickFun} HandlePlacementClickFun={HandlePlacementClickFun} />
                        <br />
                        <StudentsAlsoVisited studentAlsoVisitedData={studentAlsoVisitedData} />
                      </div>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="col-12 col-xl-9 col-lg-9 col-md-12 col-sm-12">
                    <Faq />
                    <br />
                    {/* <CollegeRating /> */}
                    <br />
                    {/* <StudentReview /> */}
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </>
  );
}
